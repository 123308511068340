import { Flex } from "@chakra-ui/react"
import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import ErrorBoundary from "@pathwright/ui/src/components/error/ErrorBoundary"
import Link from "@pathwright/ui/src/components/link/Link"
import withTranslate, {
  useTranslate
} from "@pathwright/ui/src/components/lng/withTranslate"
import BulletSeparation from "@pathwright/ui/src/components/ui/BulletSeparation"
import Text from "@pathwright/ui/src/components/ui/Text"
import { SECONDARY_GRAY } from "@pathwright/ui/src/components/utils/colors"
import path from "path-browserify"
import PropTypes from "prop-types"
import styled from "styled-components"
import UserCompletionsNeedingReview from "../../../completion/review/UserCompletionsNeedingReview"
import ProfileCard from "../../../profile/ProfileCard"
import { ShareDumb } from "../../../share/Share"
import { useMentorGroupListContext } from "./MentorGroupListContext"
import MentorGroupMemberControls from "./MentorGroupMemberControls"

const Item = styled.li`
  background: ${(p) => (p.$isRevoked ? "whitesmoke" : "transparent")};
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  padding: 0 10px;
  .Overlay {
    z-index: 9001;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const Col = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 150px;
  white-space: nowrap;
  overflow: hidden;
  &:last-child {
    text-align: right;
  }
  @media (max-width: 480px) {
    display: none;
  }
`

const RowCol = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  h5,
  p,
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const BackgroundLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0px; /* why 0px instead of -10px is necessary and works, I don't know */
  right: -10px;
  bottom: 0;
  ${Item}:hover & {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const Cover = styled.img`
  border-radius: 4px;
  height: 30px;
  width: 40px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
`

export const MentorGroupMemberListItemAdd = () => {
  const { mentorGroup } = useMentorGroupListContext()
  const { t } = useTranslate()
  const tPrefix = "mentor_group.members.list"

  return (
    <ShareDumb
      context={{ type: "group", typeId: mentorGroup.id }}
      displayType="modal"
    >
      {({ renderTrigger }) =>
        renderTrigger(({ onToggle }) => (
          <Item onClick={() => onToggle()}>
            <RowCol>
              <Flex
                h="30px"
                w="30px"
                bg="#f5f5f5"
                mr="10px"
                borderRadius="50%"
                alignItems="center"
                justifyContent="center"
              >
                <Pathicon
                  icon="avatar-plus"
                  size="24px"
                  style={{ color: SECONDARY_GRAY }}
                />
              </Flex>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Text.H5 style={{ color: SECONDARY_GRAY }}>
                  {t(`${tPrefix}.add`)}{" "}
                  <span>{t(`${tPrefix}.members`)}...</span>
                </Text.H5>
              </div>
            </RowCol>
          </Item>
        ))
      }
    </ShareDumb>
  )
}

const MentorGroupMemberListItem = (props) => {
  const {
    mentorGroup,
    membership,
    permissions,
    groupSubscription,
    search,
    t,
    tc
  } = props
  const isRevoked = !!membership.access_revoked_time

  return (
    <ErrorBoundary>
      <Item key={membership.id} $isRevoked={isRevoked}>
        <BackgroundLink
          to={{
            pathname: path.join(
              window.location.pathname,
              `/member/${membership.user.id}/`
            ),
            card: {
              component: ProfileCard,
              props: {
                userId: membership.user.id
              }
            }
          }}
        />
        <RowCol>
          <Avatar
            user={membership.user}
            size="30px"
            style={{ marginRight: 10 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div style={{ overflow: "hidden" }}>
              <Text.H5>{membership.user.full_name}</Text.H5>
              <BulletSeparation>
                {isRevoked && <Text.Meta>{tc("Access revoked")}</Text.Meta>}
                <Text.Meta>{membership.user.profile.location}</Text.Meta>
              </BulletSeparation>
            </div>
          </div>
        </RowCol>
        {/* <Col
          style={{
            display: "block",
            flexBasis: "auto",
            paddingRight: 10
          }}
        >
          <Stack
            items={membership.user.registrations}
            itemOffset={-30}
            itemWidth={40}
            hoverable
            style={{ overflow: "hidden", borderRadius: 4 }}
          >
            {({ item, itemWidth }) => (
              <Link
                to={path.join(
                  window.location.pathname,
                  `/member/${membership.user.id}/${item.path.id}/`
                )}
              >
                <div
                  style={{
                    position: "relative",
                    height: 30,
                    width: itemWidth
                  }}
                >
                  <Cover src={item.resource.image} />
                  {item.path && (
                    <ProgressBar
                      height={2}
                      fillColor="lightgreen"
                      backColor="#222"
                      progress={item.path.progress}
                      style={{
                        position: "absolute",
                        left: 5,
                        right: 5,
                        bottom: 5,
                        width: "calc(100% - 10px)",
                        boxShadow: "0 0 1px 0 black"
                      }}
                    />
                  )}
                </div>
              </Link>
            )}
          </Stack>
        </Col> */}
        {/* <Col>
          <Text.Body>
            {membership.user.registrations[0] &&
              moment(
                membership.user.registrations[0].last_activity_time
              ).fromNow()}
          </Text.Body>
        </Col> */}
        <Col style={{ flexBasis: 50, overflow: "visible" }}>
          <UserCompletionsNeedingReview userId={membership.user.id} />
        </Col>
        <MentorGroupMemberControls
          mentorGroup={mentorGroup}
          groupSubscription={groupSubscription}
          permissions={permissions}
          membership={membership}
          search={search}
        />
      </Item>
    </ErrorBoundary>
  )
}

MentorGroupMemberListItem.propTypes = {
  membership: PropTypes.object.isRequired
}

export default withTranslate(MentorGroupMemberListItem)
