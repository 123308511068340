import "./GateButton.css"
import React from "react"
import PropTypes from "prop-types"
import Text from "../ui/Text"
import Pathicon from "../pathicon/Pathicon"

const GateButton = ({ icon, line1, line2, onClick, to, children }) => {
  return (
    <div className="GateButton" onClick={onClick}>
      <div className="GateButton__content">
        {icon && (
          <div className="GateButton__icon">
            <Pathicon icon={icon} />
          </div>
        )}
        <div className="GateButton__text">
          {line1 && <Text.H3 emphasis="secondary">{line1}</Text.H3>}
          {line2 && <Text.Body>{line2}</Text.Body>}
        </div>
        {!!onClick && (
          <div className="GateButton__arrow">
            <Pathicon icon="chevron-right" />
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

GateButton.displayName = "GateButton"

GateButton.propTypes = {
  icon: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default GateButton
