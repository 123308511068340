import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  LightMode,
  Link,
  Spacer,
  Spinner,
  VStack
} from "@chakra-ui/react"
import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import { Field, Form, Formik } from "formik"
import { z } from "zod"
import { toFormikValidate } from "zod-formik-adapter"
import { SubdomainDocument } from "../../api/generated"
import ChakraTooltip from "../../components/ChakraTooltip"
import { getApolloClient } from "../../pathwright/PathwrightClient"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { PresetThemeKey } from "../../theme/presets"
import SpaceThemeOptions from "./SpaceThemeOptions"

const existingSubdomainErrorMessage =
  "Sorry, that url already exists. Please choose another."

const subdomainQuery = async (subdomain: string) => {
  const result = await getApolloClient().query({
    query: SubdomainDocument,
    variables: { subdomain }
  })

  return result
}

const spaceSchema = z.object({
  name: z.string().trim().min(2).max(30),
  subdomain: z
    .string()
    .trim()
    .min(2)
    .max(50)
    .regex(/^[0-9A-Z]+$/i, "Use letters and numbers only.")
    // Note: using transform instead of refine since transform will only run when previous
    // validators pass while refine will always run.
    // Validate that the subdomain does not already exist.
    .transform(async (subdomain, ctx) => {
      const result = await subdomainQuery(subdomain)

      if (result) {
        const { data } = result
        if (data.subdomain) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: existingSubdomainErrorMessage
          })

          return z.NEVER
        }
      }
      return subdomain
    }),
  theme: z.union([
    z.literal("default"),
    z.literal("minimal_modern"),
    z.literal("friendly"),
    z.literal("creative_organic"),
    z.literal("classic_academic")
  ]) satisfies z.Schema<PresetThemeKey>
})

export type SpaceConfig = z.infer<typeof spaceSchema>

const validateSpace = toFormikValidate(spaceSchema)

type SpaceCreateFormProps = {
  initialValues?: SpaceConfig
  onChangeTheme: (theme: PresetThemeKey) => void
  onComplete: (spaceConfig: SpaceConfig) => void
  onClickSignIn: () => void
  onClickSelectSpace: () => void
}

function SpaceCreateForm({
  initialValues: initialValuesProp,
  onChangeTheme,
  onComplete,
  onClickSignIn,
  onClickSelectSpace
}: SpaceCreateFormProps) {
  const { me } = usePathwrightContext()
  const defaultInitialValues: SpaceConfig = {
    name: "",
    subdomain: "",
    theme: "default"
  }
  const initialValues = initialValuesProp || defaultInitialValues

  return (
    <VStack
      m={2}
      p={4}
      // Make space for info icons on small screens.
      maxW="calc(100% - 30px)"
      spacing={4}
      w="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        ".chakra-accordion": {
          width: "100%"
        }
      }}
    >
      <Heading
        as="h1"
        size="xl"
        fontFamily="theme.heading"
        pos="relative"
        textAlign="center"
      >
        Create a<br />
        Learning Space
        <ChakraTooltip
          title="Your Learning Space is your own, branded place to design 
            and share learning Paths with anyone (e.g., your class, 
            community, team members, customers, partners, ...)"
        >
          <Pathicon
            icon="info-circle"
            pos="absolute"
            right="-5px"
            top="50%"
            transform="translate(100%, -50%)"
            opacity=".5"
          />
        </ChakraTooltip>
      </Heading>

      <Formik
        initialValues={initialValues}
        // Parsing values on submit in order to apply any transforms.
        onSubmit={async (values) => {
          onComplete(await spaceSchema.parseAsync(values))
        }}
        validateOnMount={!!initialValuesProp}
        validate={validateSpace}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            <VStack spacing={2}>
              <FormControl
                isInvalid={
                  !!form.errors.name &&
                  form.touched.name &&
                  form.values.name !== form.initialValues.name
                }
              >
                <FormLabel>Space Name</FormLabel>
                <Field
                  name="name"
                  type="text"
                  as={Input}
                  autoComplete="off"
                  placeholder="Learning space name"
                  borderRadius="40px"
                />
                <FormErrorMessage>
                  <HStack>
                    <Pathicon icon="x" size="1em" />
                    <span>{form.errors.name}</span>
                  </HStack>
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  !form.isValidating &&
                  (form.errors.subdomain === existingSubdomainErrorMessage ||
                    (!!form.errors.subdomain &&
                      form.touched.subdomain &&
                      form.values.subdomain !== form.initialValues.subdomain))
                }
              >
                <FormLabel>URL</FormLabel>
                <InputGroup size="md">
                  <Flex w="100%" pos="relative">
                    <Field
                      name="subdomain"
                      type="text"
                      as={Input}
                      autoComplete="off"
                      placeholder="Subdomain"
                      borderRadius="40px 0px 0px 40px"
                    />
                    {form.isValidating && (
                      // || (!form.errors.subdomain &&
                      //   !throttledSubdomains.includes(
                      //     form.values.subdomain
                      //   ))
                      <LightMode>
                        <InputRightElement>
                          <Spinner size="sm" />
                        </InputRightElement>
                      </LightMode>
                    )}
                  </Flex>
                  <InputRightAddon borderRadius="0px 40px 40px 0px">
                    .pathwright.com
                    <ChakraTooltip
                      title={
                        <span>
                          You can change your URL later and optionally{" "}
                          <Link
                            textDecor="underline"
                            href="https://help.pathwright.com/en/articles/446542-set-up-a-custom-domain"
                          >
                            setup a custom domain
                          </Link>
                          .
                        </span>
                      }
                    >
                      <Pathicon
                        icon="info-circle"
                        pos="absolute"
                        right="-5px"
                        top="50%"
                        transform="translate(100%, -50%)"
                        opacity=".5"
                      />
                    </ChakraTooltip>
                  </InputRightAddon>
                </InputGroup>
                <FormErrorMessage>
                  <HStack>
                    <Pathicon icon="x" size="1em" />
                    <span>{form.errors.subdomain}</span>
                  </HStack>
                </FormErrorMessage>
              </FormControl>

              <FormControl
                display="inline-flex"
                alignSelf="flex-start"
                w="unset"
                pos="relative"
                mt={6}
              >
                <Field
                  name="theme"
                  type="checkbox"
                  as={SpaceThemeOptions}
                  onChange={(value: PresetThemeKey) => {
                    onChangeTheme(value)
                    form.setFieldValue("theme", value, true)
                  }}
                />
                <ChakraTooltip title="After picking a default theme, you can customize your brand font, colors, and more anytime once you've created your Learning Space.">
                  <Pathicon
                    icon="info-circle"
                    pos="absolute"
                    right="-5px"
                    top="50%"
                    transform="translate(100%, -50%)"
                    opacity=".5"
                  />
                </ChakraTooltip>
              </FormControl>

              <LightMode>
                <Button
                  borderRadius="50px"
                  colorScheme="brand"
                  size="lg"
                  type="submit"
                  width="full"
                  isLoading={form.isSubmitting && form.isValid}
                  isDisabled={
                    form.isValidating ||
                    form.isSubmitting ||
                    !form.isValid ||
                    (!form.dirty && !form.validateOnMount)
                  }
                  mt={4}
                >
                  Continue
                </Button>
              </LightMode>
            </VStack>
          </Form>
        )}
      </Formik>

      {me ? (
        <Button
          variant="link"
          display="inline"
          alignSelf="center"
          onClick={onClickSelectSpace}
        >
          Already have a Learning Space? Select one.
        </Button>
      ) : (
        <Button
          variant="link"
          display="inline"
          alignSelf="center"
          onClick={onClickSignIn}
        >
          Already have a Learning Space? Sign in.
        </Button>
      )}

      <Spacer my={2} />
    </VStack>
  )
}

export default SpaceCreateForm
