import gql from "graphql-tag"

export default gql`
  mutation($username: String!, $password: String!, $invite_token: String) {
    getToken(
      username: $username
      password: $password
      invite_token: $invite_token
    ) {
      token
      user_id
      created
    }
  }
`
