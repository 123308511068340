import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import View from "@pathwright/ui/src/components/ui/View"
import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import AssignResourceCohort from "./AssignResourceCohort"

const StyledList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    height: 90px;
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

const AssignResourceCohortList = ({
  resourceCohortMap,
  updateAssign,
  assigneeName,
  resources,
  cohorts,
  role
}) => (
  <View paddingTop>
    <StyledList>
      {isEmpty(resourceCohortMap) ? (
        <BlankSlate
          icons={null}
          heading="Nothing added yet... "
          body={`Search the Library to find something to add to ${assigneeName}'s path.`}
        />
      ) : (
        Object.keys(resourceCohortMap).map(id => (
          <li key={id}>
            <AssignResourceCohort
              resourceId={parseInt(id)}
              cohortId={resourceCohortMap[id]}
              cohort={cohorts.find(
                cohort => cohort.id === resourceCohortMap[id]
              )}
              role={role}
              onSelectCohort={(resource, cohort) =>
                updateAssign({
                  type: "add",
                  resource,
                  cohort
                })
              }
              onUnselectCohort={cohort =>
                updateAssign({
                  type: "remove",
                  cohort
                })
              }
              onUnselectResource={resource =>
                updateAssign({
                  type: "remove",
                  resource
                })
              }
            />
          </li>
        ))
      )}
    </StyledList>
  </View>
)

AssignResourceCohortList.displayName = "AssignResourceCohortList"

AssignResourceCohortList.propTypes = {
  resourceCohortMap: PropTypes.object.isRequired,
  // updaters
  updateAssign: PropTypes.func.isRequired
}

export default AssignResourceCohortList
