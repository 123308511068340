// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let PasswordReset
export default PasswordReset = class PasswordReset extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    return this.urlRoot(`/user/start-password-retrieval/`)
  }

  defaults() {
    return { username_or_email: "" }
  }
}
