import "./Lesson.css"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import classnames from "classnames"
import Link from "../link/Link"
import Image from "../image/Image"
import Text from "../ui/Text"
import IconButton from "../button/IconButton"

// component for consistent style/layout
// allows usage for different contexts or purposes

const StyledContainer = styled.div``

const StyledIconButton = styled(IconButton)`
  visibility: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;

  ${StyledContainer}:hover & {
    visibility: visible;
  }
`

const Lesson = ({
  to,
  toSettings,
  meta,
  displayPrice,
  layout,
  lesson,
  children,
  onClick,
  className
}) => {
  return (
    <StyledContainer
      className={classnames(
        "Lesson",
        {
          "Lesson--static": !to && !onClick
        },
        className
      )}
      onClick={onClick}
    >
      <div className="Lesson__cover">
        <Image src={lesson.image} role="presentation" />
      </div>
      <div className="Lesson__info">
        <div className="Lesson__info_wrapper">
          <Text.H4 brand className="Lesson__name">
            {lesson.name}
          </Text.H4>
          {meta && <h5 className="Lesson__meta">{meta}</h5>}
        </div>
        {displayPrice && <h5 className="Lesson__price">{displayPrice}</h5>}
        {children}
      </div>
      {to && <Link className="Lesson__link" to={to} />}
      {toSettings && <StyledIconButton to={toSettings} icon="gear" />}
    </StyledContainer>
  )
}

Lesson.displayName = "Lesson"

export default Lesson
