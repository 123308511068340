import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import useClipboard from "../../clipboard/useClipboard"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canAdministrate } from "../../user/permissions"
import { RESOURCE_TYPE_COLLECTION } from "../constants"

const StyledContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
`

const truncate = (str, maxLength) => {
  if (str.length <= maxLength) return str
  return str.substring(0, maxLength) + "..."
}

const getPasteLabel = resource => truncate(resource.name, 15)

const ResourceCopyControl = ({ actionLabel, resource }) => {
  const pwContext = usePathwrightContext()
  const clipboard = useClipboard()

  // For now, don't show copy button for collections
  if (resource.resource_type === RESOURCE_TYPE_COLLECTION) return null

  const clip = {
    object_type: "resource",
    object_id: resource.id,
    object_label: getPasteLabel(resource)
  }
  const clipData = {
    school_id: pwContext.school.id,
    user_is_admin_of_school: canAdministrate(pwContext)
  }
  const copied = !!clipboard.getClip(clip)

  const toggleCopy = () => {
    copied ? clipboard.clear(clip) : clipboard.copy(clip, clipData)
  }

  return (
    <StyledContainer onClick={toggleCopy}>
      <Pathicon icon="copy" style={{ marginRight: "5px" }} />
      {copied ? "Copied" : actionLabel}
    </StyledContainer>
  )
}

ResourceCopyControl.displayName = "ResourceCopyControl"

ResourceCopyControl.propTypes = {
  actionLabel: PropTypes.string,
  resource: PropTypes.object
}

ResourceCopyControl.defaultProps = {
  actionLabel: "Copy"
}

export default ResourceCopyControl
