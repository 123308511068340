import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import uniq from "lodash/uniq"
import { useEffect, useMemo, useState } from "react"
import useControlledQueryParams from "../../utils/useControlledQueryParams"

// NOTE: handling author and category filtering by name values NOT ids
// this is because Algolia filters by name values and does not provide facet id
const useControlledLibraryQueryParams = () => {
  // debounced search
  const [
    search,
    debouncedSearch,
    debouncingSearch,
    handleDebounceSearch
  ] = useDebouncedValue(200, null)

  // author nanes for algolia search
  const [selectedAuthorNames, setSelectedAuthorNames] = useState([])
  // category names for algolia search
  const [selectedCategoryNames, setSelectedCategoryNames] = useState([])

  // useMemo necessary for avoiding infinite loop of setState, probably
  // avoidable by optimizing useControlledQueryParams, but either way
  // useMemo here is probably more efficient.
  const paramsObj = useMemo(
    () => ({
      author: selectedAuthorNames,
      category: selectedCategoryNames,
      search: debouncedSearch
    }),
    [selectedAuthorNames, selectedCategoryNames, debouncedSearch]
  )

  const controlledQueryParams = useControlledQueryParams(paramsObj)

  useEffect(() => {
    // Prevents glitch when clearing search via "View full Library"
    if (debouncingSearch) return

    const { search: searchQueryParam, author, category } = controlledQueryParams

    if (search !== searchQueryParam) {
      // Allow searchQueryParam to override search if search is falsey, for instance,
      // if on first mount.
      handleDebounceSearch(search || searchQueryParam)
    }
    // only update selectedAuthorNames if authors in query params differs from local state
    if (uniq([...author, ...selectedAuthorNames]).length) {
      setSelectedAuthorNames(author || [])
    }
    // only update selectedCategoryNames if categories in query params differs from local state
    if (uniq([...category, ...selectedCategoryNames]).length) {
      setSelectedCategoryNames(category || [])
    }
  }, [controlledQueryParams])

  return {
    search,
    debouncedSearch,
    debouncingSearch,
    handleDebounceSearch,
    selectedAuthorNames,
    setSelectedAuthorNames,
    selectedCategoryNames,
    setSelectedCategoryNames,
    controlledQueryParams
  }
}

export default useControlledLibraryQueryParams
