import PropTypes from 'prop-types';
import React from "react"
import { get } from "lib/core/api/request"

class ImgixDataProvider extends React.Component {
  state = {
    loading: false,
    data: null,
  }

  UNSAFE_componentWillMount() {
    if (this.props.dataUrl) {
      return this.fetchData(this.props.dataUrl)
    }
  }

  UNSAFE_componentWillReceiveProps({ dataUrl }) {
    if (dataUrl && dataUrl !== this.props.dataUrl) {
      return this.fetchData(dataUrl)
    }
  }

  fetchData = url => {
    this.setState({ loading: true })
    return get(url, {}, false)
      .then(data => {
        return this.setState({ data, loading: false })
      })
      .catch(err => {
        return this.setState({ loading: false })
      })
  }

  render() {
    return this.props.children(this.state)
  }
}

ImgixDataProvider.displayName = "ImgixDataProvider"

ImgixDataProvider.propTypes = {
  children: PropTypes.func.isRequired,
  dataUrl: PropTypes.string,
}

export default ImgixDataProvider
