import isEqual from "react-fast-compare"
import { BlockType } from "../types"

export type BatchBlockType = {
  draft?: boolean
  order?: number
  layout?: string
  type?: string
  id: string
  data?: Record<string, any>
  style?: Record<string, any>
  scope?: string
  trackProgress?: boolean
  userID?: string
}

export type BlockBatchType = {
  [key: string]: BatchBlockType
}

// store batch of unsaved block edits
export const initBatch = (): BlockBatchType => {
  let batch: BlockBatchType = {}

  return batch
}
