import gql from "graphql-tag"
import BANNER_FRAGMENT from "./banner-fragment"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  query BannerQuery($school_id: Int!, $id: Int!) {
    context(school_id: $school_id) {
      school {
        ...School
        banner(id: $id) {
          ...Banner
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${BANNER_FRAGMENT}
`
