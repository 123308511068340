import PopupAlert from "@pathwright/ui/src/components/alert/PopupAlert"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import React, { useState } from "react"
import { downloadReport } from "./utils"

// simple HOC for handling PopupAlert for status when
// downloading a report
const DownloadReport = ({ children }) => {
  const { t } = useTranslate()

  const initialState = {
    loading: null,
    success: null,
    error: null
  }

  const [state, setState] = useState(initialState)

  const handleDownloadReport = async reportUrl => {
    try {
      setState({
        loading: {
          title: `${t("Downloading report")}...`,
          description: t(
            "Feel free to close this message and continue working while your download is processing."
          )
        },
        error: null
      })
      await downloadReport(reportUrl)
      setState({
        ...initialState,
        success: t("Finished downloading report.")
      })
    } catch (error) {
      setState({
        ...initialState,
        error: error.message ? error.message : error
      })
    }
  }

  return (
    <>
      <PopupAlert
        loading={state.loading}
        success={state.success}
        error={state.error}
        onClear={() => setState(initialState)}
        // allow loading state to continue indefinitely
        clearAfter={state.loading ? null : 3000}
      />
      {children({
        handleDownloadReport
      })}
    </>
  )
}

DownloadReport.displayName = "DownloadReport"

export default DownloadReport
