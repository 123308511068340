import withLauncher from "@pathwright/ui/src/components/launcher/withLauncher"
import Course from "@pathwright/ui/src/components/library/Course"
import Stack from "@pathwright/ui/src/components/stack/Stack"
import { sizePropType } from "@pathwright/ui/src/components/utils/props"
import React from "react"
import styled from "styled-components"
import { getStoreResourceUrl } from "../utils/urls"

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledResources = styled.div`
  padding: 5px;
  width: ${props => (props.draft ? 45 : 55)}%;
  transition: transform 100ms ease-out;
  flex-shrink: ${props => (props.draft ? 0 : 1)};
  &:hover {
    transform: scale(1.05);
  }
`

const StyledCourse = styled(Course)`
  font-size: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition: transform 100ms ease-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .Course__info {
    max-height: 80px;
    min-height: 80px;
    overflow: hidden;
  }
`

const getCourseStyle = (width, offset) => {
  const active = offset === 0
  return {
    width,
    zIndex: 100 - Math.abs(offset),
    boxShadow: `${active ? 0 : offset < 0 ? -2 : 2}px 0 4px ${
      active ? 2 : 0
    }px rgba(0,0,0,0.1)`
  }
}

const LibraryPreviewCourse = ({ item, itemWidth, offset, draft, onLaunch }) => {
  const handleLaunch = e => {
    e.preventDefault()
    e.stopPropagation()
    onLaunch(getStoreResourceUrl(item.slug))
  }
  return (
    <StyledCourse
      course={item}
      onClick={handleLaunch}
      style={getCourseStyle(sizePropType._serialize(itemWidth).style, offset)}
    />
  )
}

const LibraryPreview = ({ visibleResources, privateResources, onLaunch }) => {
  const offset =
    Math.sign(visibleResources.length - privateResources.length) * 5
  return (
    <StyledContainer>
      {visibleResources.length > 0 && (
        <StyledResources style={{ width: `${50 + offset}%` }}>
          <Stack items={visibleResources} itemWidth="100px" hoverable>
            {itemProps => (
              <LibraryPreviewCourse {...itemProps} onLaunch={onLaunch} />
            )}
          </Stack>
        </StyledResources>
      )}
      {privateResources.length > 0 && (
        <StyledResources draft style={{ width: `${50 - offset}%` }}>
          <Stack items={privateResources} itemWidth="100px" hoverable>
            {itemProps => (
              <LibraryPreviewCourse {...itemProps} draft onLaunch={onLaunch} />
            )}
          </Stack>
        </StyledResources>
      )}
    </StyledContainer>
  )
}

LibraryPreview.displayName = "LibraryPreview"

export default withLauncher({
  Component: LibraryPreview,
  Preload: window.LazyLibraryRoutes
})
