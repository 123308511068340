import produce from "immer"
import { getApolloClient } from "../../pathwright/PathwrightClient"
import { DISCUSSION_TYPE_INPUTS, DISCUSSION_TYPE_QUESTION } from "../constants"
import DISCUSSIONS_QUERY from "../graphql/discussions-query"

export const DISCUSSION_CONTROL_FILTER_ALL = 1
export const DISCUSSION_CONTROL_FILTER_UNANSWERED = 2
export const DISCUSSION_CONTROL_FILTER_DISCUSSION_QUESTIONS = 3
export const DISCUSSION_CONTROL_FILTER_QUESTIONS = 4

export const DISCUSSION_CONTROL_SORT_MOST_ACTIVE = 1
export const DISCUSSION_CONTROL_SORT_BEST = 2
export const DISCUSSION_CONTROL_SORT_RECENTLY_CREATED = 3
export const DISCUSSION_CONTROL_SORT_RECENTLY_ACTIVE = 4
export const DISCUSSION_CONTROL_SORT_STEP_DISCUSSION_PROMPTS = 5 // not a dropdown option, custom sorting option for discussion prompts on steps

export const POSSIBLE_FILTERS = [
  DISCUSSION_CONTROL_FILTER_ALL,
  DISCUSSION_CONTROL_FILTER_UNANSWERED,
  DISCUSSION_CONTROL_FILTER_DISCUSSION_QUESTIONS,
  DISCUSSION_CONTROL_FILTER_QUESTIONS
]

export const POSSIBLE_SORTS = [
  DISCUSSION_CONTROL_SORT_MOST_ACTIVE,
  DISCUSSION_CONTROL_SORT_BEST,
  DISCUSSION_CONTROL_SORT_RECENTLY_CREATED,
  DISCUSSION_CONTROL_SORT_RECENTLY_ACTIVE,
  DISCUSSION_CONTROL_SORT_STEP_DISCUSSION_PROMPTS
]

export const FILTERS = [
  DISCUSSION_CONTROL_FILTER_ALL,
  DISCUSSION_CONTROL_FILTER_UNANSWERED
  // Hiding these for now.
  // DISCUSSION_CONTROL_FILTER_DISCUSSION_QUESTIONS,
  // DISCUSSION_CONTROL_FILTER_QUESTIONS
]

export const SORTS = [
  DISCUSSION_CONTROL_SORT_RECENTLY_ACTIVE,
  DISCUSSION_CONTROL_SORT_RECENTLY_CREATED,
  DISCUSSION_CONTROL_SORT_MOST_ACTIVE,
  DISCUSSION_CONTROL_SORT_BEST
]

const BASE_ORDERING = ["-created_time", "order"]

export const getFilterVariables = filter => {
  switch (filter) {
    case DISCUSSION_CONTROL_FILTER_ALL:
      return {
        isDiscussionQuestion: null,
        totalResponses: null
      }
    case DISCUSSION_CONTROL_FILTER_UNANSWERED:
      return {
        isDiscussionQuestion: null,
        totalResponses: { eq: 0 }
      }
    case DISCUSSION_CONTROL_FILTER_DISCUSSION_QUESTIONS:
      return {
        isDiscussionQuestion: true,
        totalResponses: null
      }
    case DISCUSSION_CONTROL_FILTER_QUESTIONS:
      return {
        isDiscussionQuestion: false,
        totalResponses: null
      }
  }
}

export const getSortVariables = sort => {
  switch (sort) {
    case DISCUSSION_CONTROL_SORT_MOST_ACTIVE:
      return {
        sort,
        orderBy: ["-total_responses", ...BASE_ORDERING]
      }
    case DISCUSSION_CONTROL_SORT_RECENTLY_ACTIVE:
      return {
        sort,
        orderBy: ["-last_activity_time", ...BASE_ORDERING]
      }
    case DISCUSSION_CONTROL_SORT_BEST:
      return {
        sort,
        orderBy: ["-likes", ...BASE_ORDERING]
      }
    case DISCUSSION_CONTROL_SORT_RECENTLY_CREATED:
      return {
        sort,
        orderBy: BASE_ORDERING
      }
    case DISCUSSION_CONTROL_SORT_STEP_DISCUSSION_PROMPTS: // not a dropdown option, custom sorting option for discussion prompts on steps
      return {
        sort,
        orderBy: ["order", "created_time"]
      }
  }
}

export const getDiscussionsQueryVariables = ({
  context,
  tagIdFilter,
  filter,
  sort,
  first
}) => {
  const { isDiscussionQuestion, totalResponses } = getFilterVariables(filter)
  const { orderBy } = getSortVariables(sort)

  return {
    context,
    tagIdFilter,
    type: DISCUSSION_TYPE_INPUTS[DISCUSSION_TYPE_QUESTION],
    is_discussion_question: isDiscussionQuestion,
    total_responses: totalResponses,
    order_by: orderBy,
    first: first || 15
  }
}

// NOTE: not working! Currently get error from Apollo: "Invariant Violation: Can't find field total on object undefined."
export const deleteDiscussionInDiscussionsCache = ({
  context,
  tagIdFilter,
  filter,
  sort
}) => discussion => {
  const client = getApolloClient(window.school.id)
  const query = DISCUSSIONS_QUERY
  const variables = getDiscussionsQueryVariables({
    context,
    tagIdFilter,
    filter,
    sort
  })

  try {
    const data = client.readQuery({ query, variables })
    const nextData = produce(data, draft => {
      const edgeIndex = draft.discussions.edges.findIndex(
        edge => edge.node.id === discussion.id
      )
      draft.discussions.edges.splice(edgeIndex, 1)
    })

    client.writeQuery({ query, variables, data: nextData })
  } catch (error) {
    console.warn(
      `Failed to update Apollo cache with deleted discussion: ${error}`
    )
  }
}

// HACK ALERT: https://medium.com/@martinseanhunt/how-to-invalidate-cached-data-in-apollo-and-handle-updating-paginated-queries-379e4b9e4698
export const invalidateDiscussionCache = discussionContext => {
  if (!discussionContext) return

  const cache = getApolloClient(window.school.id).cache

  // get's all possible context keys for context
  // NOTE: sort() is important to match the order in which the keys
  // appear in the Apollo cache key
  const keyRes = Object.entries(discussionContext)
    .sort()
    .reduce((res, [key, value], i, entries) => {
      // get the context keys
      const contextEntries = entries.slice(0, i + 1)
      // stringify them (without those escaped double qoutes you get from JSON.stringify)
      const stringifiedContextKeys = contextEntries
        .map(([key, value]) => `"${key}":${value}`)
        .join(",")
      // construct the cache key
      const re = new RegExp(
        `\\$ROOT_QUERY\\.discussions\\(\\{"context":\\{${stringifiedContextKeys}\\}`
      )

      return [...res, re]
    }, [])

  keyRes.forEach(re =>
    // for each cached discussion key matching the re,
    // delete the cache so that the queries will refetch when
    // the filters change (causing the newly created discussion to appear in results)
    Object.keys(cache.data.data).forEach(key => {
      if (re.test(key)) cache.data.delete(key)
    })
  )
}
