import gql from "graphql-tag"
import Page from "./page-fragment"

export default gql`
  mutation(
    $id: Int!
    $name: String
    $page_type: Int
    $title: String
    $description: String
    $slug: String
    $show_in_nav: Boolean
    $external_url: String
    $iframe_url: String
    $template_content: String
    $scripts: String
    $mode: String
    $is_live: Boolean
    $content_type: Int
  ) {
    updatePage(
      id: $id
      name: $name
      page_type: $page_type
      title: $title
      description: $description
      slug: $slug
      show_in_nav: $show_in_nav
      external_url: $external_url
      iframe_url: $iframe_url
      template_content: $template_content
      scripts: $scripts
      mode: $mode
      is_live: $is_live
      content_type: $content_type
    ) {
      ...Page
    }
  }
  ${Page}
`
