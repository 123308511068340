import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Dropdown from "@pathwright/ui/src/components/dropdown"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import { media, sizes } from "@pathwright/ui/src/components/utils/styles"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import React from "react"
import classnames from "classnames"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { flattenEdges, usePaginator } from "../../utils/apollo"
import { getManageResourceUrl, getStoreResourceUrl } from "../../utils/urls"
import LIBRARY_AUTHORS_QUERY from "../graphql/library-authors-query"
import LIBRARY_CATEGORIES_QUERY from "../graphql/library-categories-query"
import LIBRARY_RESOURCES_QUERY from "../graphql/library-resources-query"
import BrowseCollections from "./BrowseCollections"
import BrowseCourses from "./BrowseCourses"
import Facets from "./BrowseFacets"
import BrowseLessons from "./BrowseLessons"
import ResourcesOrderingFilter from "./BrowseResourceOrderingFilter"
import useAlgoliaSearch from "./useAlgoliaSearch"
import useControlledLibraryQueryParams from "./useControlledLibraryQueryParams"
import { massageAlgoliaLibraryBrowseResults } from "./utils"
import { Box, Flex } from "@chakra-ui/react"
import ScrollToTopButton from "@pathwright/ui/src/components/scroll/ScrollToTopButton"
import { useScrollContext } from "@pathwright/ui/src/components/scroll/ScrollView"

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .SearchInput {
    // padding: 0.5em;
    font-size: 1em;
    width: 200px;
    transition: width 0.2s ease-in-out;

    /* expanding background beyond Container max-width limit */
    // margin-left: -2000px;
    // margin-right: -2000px;
    // padding-left: 2000px;
    // padding-right: 2000px;
  }
`

const StyledH5 = styled(Text.H5)`
  margin-bottom: 8px;

  &:not(:first-of-type) {
    margin-top: 2em;
  }
`

const Controls = styled.div`
  padding: 1em;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.search-input-active {
    .SearchInput {
      width: 100%;
    }

    .BrowseContainer__sort {
      display: none;
    }
  }

  .BrowseContainer__FacetsTrigger {
    margin-right: 0.5rem;
  }

  > *:not(:first-child) {
    margin-left: 0.4em;
  }

  &.has-search .BrowseContainer__sort {
    display: none;
  }

  // @media (min-width: ${sizes.phone +
  1}px) and (max-width: ${sizes.tablet}px) {
  //   &.search-input-active .BrowseContainer__sort {
  //     display: none;
  //   }
  // }

  @media (min-width: ${sizes.tablet}px) {
    /* move ordering filter to right side */
    justify-content: space-between;

    /* unknown issue with media.min and media.max: cannot handle nested styles */
    /* hide facets filter */
    &.search-input-active .BrowseContainer__SortFilterWrapper {
      display: none;
    }

    .BrowseContainer__FacetsTrigger {
      display: none !important;
    }
  }

  @media (max-width: ${sizes.phone}px) {
    flex-direction: column;
    align-items: flex-start;

    .SearchInput {
      margin-bottom: 0.5rem;
      width: 100%;
    }

    .BrowseContainer__FacetsTrigger > .Button,
    .BrowseContainer__sort .Button {
      padding: 0.5em 1em;
    }

    > *:not(:first-child) {
      margin-left: 0;
    }
  }
`

const SortFilterWrapper = styled.div`
  display: flex;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 20px;
  padding: 1em;

  ${media.max.tablet`
    grid-template-columns: 1fr;
  `}
`

const StyledIconButton = styled(IconButton)`
  /* will make visible on hover */
  visibility: hidden;
  /* preferring that the icon not affect the FacetTitle height */
  position: absolute;
  right: 0;

  > span {
    /* fixes icon alignment, TODO: investiage/fix in Button? */
    height: 100%;
  }
`

const FacetsContainer = styled.div`
  max-width: 300px;
  min-width: 200px;
  position: relative;

  & + & {
    margin-top: 2em;
  }
`

const FacetTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* aligning with Button text */
  padding-left: 0.5rem;
`

// authors/categories side bar
const FacetsSideBar = styled.div`
  ${media.max.tablet`
    display: none;
  `}

  & ${FacetsContainer}:hover ${StyledIconButton} {
    visibility: visible;
  }
`

// collections/courses/lessons main
const Main = styled.div`
  ${StyledH5} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 2em;
  }

  > div {
    margin: 0;
  }

  > div > div {
    /* zero out padding on BrowseGrid */
    padding: 0;
  }
`

// handles pagination/pagination button for queries
const LoadMore = ({ query, label, ...buttonProps }) => {
  // get the path to the connection schema
  const path = Object.keys(get(query, "data") || {})[0]

  // guarantees that clicking the "View all" buttons load all remaining results
  query.variables.first = 1042

  const { hasMore, loadMore, loadingMore } = usePaginator({
    data: query,
    path
  })

  if (!hasMore) return null

  return (
    <Button
      {...buttonProps}
      styleType="secondary"
      stretch
      style={{ height: "initial", marginTop: "1em" }}
      onClick={(e) => {
        e.stopPropagation() // prevents closing dropdown menu
        if (!loadingMore) loadMore()
      }}
    >
      {loadingMore ? (
        <LoadingCircle
          center={false}
          size={buttonProps.size === "small" ? "1.1em" : "1.3em"}
        />
      ) : (
        <Box as="span">{label}</Box>
      )}
    </Button>
  )
}

// only update UI with latest data once all queries have finished loading
class LoadingAwaiter extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.loading) return false
    return true
  }

  render() {
    return this.props.children
  }
}

const BrowseContainer = () => {
  const { t, tc } = useTranslate()

  const { me, school } = usePathwrightContext()
  const [searchInputActive, setSearchInputActive] = React.useState(false)
  const { scrollNode } = useScrollContext()

  const canEditLibrary =
    get(me, "permissions.can_administrate") ||
    get(me, "permissions.can_edit_library")

  const schoolLicensorId = get(
    school,
    "curriculum_licensee_subs[0].licensor.id"
  )

  const [orderingFilter, setOrderingFilter] = React.useState(
    ResourcesOrderingFilter.filters.featured
  )

  const {
    search,
    debouncedSearch,
    debouncingSearch,
    handleDebounceSearch,
    selectedAuthorNames,
    setSelectedAuthorNames,
    selectedCategoryNames,
    setSelectedCategoryNames,
    controlledQueryParams
  } = useControlledLibraryQueryParams()

  // reset filter state
  // NOTE: if multiple filters are in play, resetting glitches in that the search value remains in state somehow
  const resetFilters = () => {
    setSelectedAuthorNames([])
    setSelectedCategoryNames([])
    handleDebounceSearch(null)
    setSearchInputActive(false)
  }

  const {
    results: algoliaResults,
    loading: algoliaLoading,
    algoliaEnabled
  } = useAlgoliaSearch({
    indices: ["library_resource"],
    query: search,
    authorFacetValues: selectedAuthorNames,
    categoryFacetValues: selectedCategoryNames,
    massageResults: massageAlgoliaLibraryBrowseResults
  })

  // if algolia is enabled and search has value then search library via algolia, not gql
  const algoliaSearchActivated = algoliaEnabled && search
  // effectively retains gql query data when searching via algolia, better than completely ditching the data via query skip option
  const gqlSearch = algoliaSearchActivated ? null : debouncedSearch || null

  // only filter if there are selected ids
  const categoryNameFilter = selectedCategoryNames.length
    ? { in: selectedCategoryNames }
    : null
  const authorNameFilter = selectedAuthorNames.length
    ? { in: selectedAuthorNames }
    : null

  const facetQueryVariables = {
    search: gqlSearch,
    resourceSearch: gqlSearch,
    resourceAuthorNameFilter: authorNameFilter,
    resourceCategoryNameFilter: categoryNameFilter
  }

  const resourcesQueryVariables = {
    search: gqlSearch,
    categorySearch: gqlSearch,
    authorSearch: gqlSearch,
    categoryNameFilter,
    authorNameFilter,
    orderBy: orderingFilter.join(",") // resources orderBy arg only accepting String for now, but does allow for comma-separated String
  }

  // query for handling non-search & non-algolia querying
  const collectionsQuery = useQuery(LIBRARY_RESOURCES_QUERY, {
    variables: {
      ...resourcesQueryVariables,
      resourceTypeFilter: { eq: "collection" },
      first: 2 // yes, 2
    },
    // only drop query data if we have algolia results
    skip: !!algoliaResults
  })

  const coursesQuery = useQuery(LIBRARY_RESOURCES_QUERY, {
    variables: {
      ...resourcesQueryVariables,
      resourceTypeFilter: { eq: "course" },
      first: 25
    },
    // only drop query data if we have algolia results
    skip: !!algoliaResults
  })

  const lessonsQuery = useQuery(LIBRARY_RESOURCES_QUERY, {
    variables: {
      ...resourcesQueryVariables,
      resourceTypeFilter: { eq: "lesson" },
      first: 25
    },
    // only drop query data if we have algolia results
    skip: !!algoliaResults
  })

  const authorsQuery = useQuery(LIBRARY_AUTHORS_QUERY, {
    variables: facetQueryVariables,
    // only drop query data if we have algolia results
    skip: !!algoliaResults
  })

  const categoriesQuery = useQuery(LIBRARY_CATEGORIES_QUERY, {
    variables: facetQueryVariables,
    // only drop query data if we have algolia results
    skip: !!algoliaResults
  })

  const gqlResults = {
    collections: flattenEdges(get(collectionsQuery, "data.resources")) || [],
    courses: flattenEdges(get(coursesQuery, "data.resources")) || [],
    lessons: flattenEdges(get(lessonsQuery, "data.resources")) || [],
    authors: flattenEdges(get(authorsQuery, "data.authors")) || [],
    categories: flattenEdges(get(categoriesQuery, "data.categories")) || []
  }

  const { collections, courses, lessons, authors, categories } =
    algoliaResults || gqlResults

  const loading =
    algoliaLoading ||
    collectionsQuery.loading ||
    coursesQuery.loading ||
    lessonsQuery.loading ||
    authorsQuery.loading ||
    categoriesQuery.loading

  const noResults =
    !collections.length &&
    !courses.length &&
    !lessons.length &&
    !collectionsQuery.loading &&
    !coursesQuery.loading &&
    !lessonsQuery.loading &&
    !algoliaLoading

  // searching if search value is currently debounced or if has search value and query is loading
  const searching = debouncingSearch || (!!search && loading)

  const selectedFacetsTotal =
    selectedAuthorNames.length + selectedCategoryNames.length

  const getResourceTo = (resource) => ({
    pathname: getStoreResourceUrl(resource.slug),
    state: { setResourceLaunchedFrom: true }
  })

  const getResourceSettingsTo = (resource) =>
    canEditLibrary ? getManageResourceUrl(resource.id) : null

  const facets = (
    <React.Fragment>
      {categories?.length ? (
        <FacetsContainer className="LibraryCategoryFacet">
          <FacetTitle>
            <StyledH5>
              {
                // i18next-scanner v.1 t("category")
                t(["library.facets.category", "category"])
              }
            </StyledH5>
            {canEditLibrary && (
              <StyledIconButton icon="gear" to="/manage/category/" />
            )}
          </FacetTitle>
          <Facets
            facets={categories}
            selectedFacetNames={selectedCategoryNames}
            onSelectFacetNames={setSelectedCategoryNames}
            facetNameField="name"
          />
          <LoadMore
            query={categoriesQuery}
            label={t("View More Categories")}
            size="small"
          />
        </FacetsContainer>
      ) : null}
      {!!authors && !!authors.length && (
        <FacetsContainer className="LibraryAuthorFacet">
          <FacetTitle>
            <StyledH5>
              {
                // i18next-scanner v.1 t("author")
                t(["library.facets.author", "author"])
              }
            </StyledH5>
            {canEditLibrary && (
              <StyledIconButton icon="gear" to="/manage/author/" />
            )}
          </FacetTitle>
          <Facets
            facets={authors}
            selectedFacetNames={selectedAuthorNames}
            onSelectFacetNames={setSelectedAuthorNames}
            facetNameField="full_name"
          />
          <LoadMore
            query={authorsQuery}
            label={t("View More Authors")}
            size="small"
          />
        </FacetsContainer>
      )}
    </React.Fragment>
  )

  return (
    <Container className="BrowseContainer">
      <React.Fragment>
        <Controls
          className={classnames({
            "search-input-active": searchInputActive,
            "has-search": !!debouncedSearch,
            BrowseContainer__Controls: true
          })}
        >
          <SearchInput
            value={search}
            searching={searching}
            onChange={handleDebounceSearch}
            onClear={() => {
              handleDebounceSearch(null)
              setSearchInputActive(false)
            }}
            placeholder={
              // i18next-scanner v.1 t("Search...")
              // t(["search.search_bar.placeholder", "Search..."])
              `${t(`Search`)}...`
            }
            autoFocus={false}
            onFocus={() => setTimeout(() => setSearchInputActive(true), 200)}
            onBlur={() => {
              if (search) return
              setTimeout(() => {
                setSearchInputActive(false)
              }, 200)
            }}
          />
          <SortFilterWrapper className={`BrowseContainer__SortFilterWrapper`}>
            <Dropdown.Trigger
              className="BrowseContainer__FacetsTrigger"
              alignment="left"
              onHide={() => {
                setTimeout(() => {
                  if (!scrollNode) return
                  scrollNode.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                  })
                }, 250)
              }}
              renderDropdown={() => (
                <Dropdown.Menu closeOnClick>{facets}</Dropdown.Menu>
              )}
            >
              <Button styleType="secondary">
                {
                  // i18next-scanner v.1 tc("filter")
                  `${tc(["library.browse.controls.filter", "filter"])}${
                    selectedFacetsTotal ? ` (${selectedFacetsTotal})` : ""
                  }`
                }
                <span> </span>
                <Pathicon icon="chevron-down" />
              </Button>
            </Dropdown.Trigger>

            <div className="BrowseContainer__sort">
              <ResourcesOrderingFilter
                filter={orderingFilter}
                onFilter={setOrderingFilter}
                disabled={!!search}
              />
            </div>
          </SortFilterWrapper>
        </Controls>
        <React.Fragment>
          <LoadingAwaiter loading={loading}>
            {noResults && debouncedSearch ? (
              <BlankSlate
                heading={
                  // i18next-scanner v.1 t("Nothing here")
                  t(["library.browse.nothing_here", "Nothing here"])
                }
                body={t(`No results found for "{{ search }}"`, {
                  search: debouncedSearch
                })}
                primaryAction={{
                  // i18next-scanner v.1 t("View full Library")
                  children: t([
                    "library.browse.view_full",
                    "View full Library"
                  ]),
                  onClick: resetFilters
                }}
              />
            ) : noResults && Object.keys(controlledQueryParams).length ? (
              <BlankSlate
                heading={
                  // i18next-scanner v.1 t("Nothing here")
                  t(["library.browse.nothing_here", "Nothing here"])
                }
                body={t(`No results found for the current selected filters`)}
                primaryAction={{
                  // i18next-scanner v.1 t("View full Library")
                  children: t([
                    "library.browse.view_full",
                    "View full Library"
                  ]),
                  onClick: resetFilters
                }}
              />
            ) : noResults && canEditLibrary && schoolLicensorId ? (
              <BlankSlate
                image="library"
                heading={t("You haven't added any curriculum yet.")}
                body={t(
                  "This is where you'll organize all your curriculum and make it available to your members."
                )}
                primaryAction={{
                  // i18next-scanner v.1 t("Get curriculum")
                  children: t([
                    "resource.licensing_form.get_curriculum",
                    "Get curriculum"
                  ]),
                  to: `/library/store/${schoolLicensorId}/`
                }}
              />
            ) : noResults && canEditLibrary ? (
              <BlankSlate
                image="library"
                heading={t("You haven't created any courses yet.")}
                body={t(
                  "This is where you'll organize all the courses you create and optionally make them available to members or to anyone."
                )}
                primaryAction={{
                  children: t("Create a new course"),
                  to: school.is_curriculum_enabled
                    ? "/home/design/"
                    : "/home/teach/"
                }}
              />
            ) : noResults ? (
              <BlankSlate
                heading={
                  // i18next-scanner v.1 t("There are no public courses here yet.")
                  t([
                    "library.browse.no_public_courses",
                    "There are no public courses here yet."
                  ])
                }
              />
            ) : (
              <Grid>
                <FacetsSideBar>{facets}</FacetsSideBar>
                <Main>
                  {!!collections && !!collections.length && (
                    <Box p="1rem 0 2.5rem">
                      {/* <StyledH5>
                      {// i18next-scanner v.1 t("collections")
                      t(["library.browse.collections", "collections"])}
                    </StyledH5> */}
                      <BrowseCollections
                        collections={collections}
                        getCollectionTo={getResourceTo}
                        getCollectionSettingsTo={getResourceSettingsTo}
                      />
                      {!!search && collections.length < 25 ? null : (
                        <LoadMore
                          query={collectionsQuery}
                          label={t("library.browse.view_more_collections")}
                        />
                      )}
                    </Box>
                  )}

                  {!!courses && !!courses.length && (
                    <Box m="1rem 0">
                      {/* <StyledH5>
                      {// i18next-scanner v.1 tc("courses")
                      t(["library.browse.courses", "courses"])}
                    </StyledH5> */}
                      <BrowseCourses
                        getCourseTo={getResourceTo}
                        getCourseSettingsTo={getResourceSettingsTo}
                        courses={courses}
                      />
                      {!!search && courses.length < 25 ? null : (
                        <LoadMore
                          query={coursesQuery}
                          label={t("View More Courses")}
                        />
                      )}
                    </Box>
                  )}
                  {!!lessons && !!lessons.length && (
                    <React.Fragment>
                      <StyledH5>
                        {
                          // i18next-scanner v.1 tc("lessons")
                          tc(["library.browse.lessons", "lessons"])
                        }
                      </StyledH5>
                      <BrowseLessons
                        lessons={lessons}
                        getLessonTo={getResourceTo}
                        getLessonSettingsTo={getResourceSettingsTo}
                      />
                      {!!search && lessons.length < 25 ? null : (
                        <LoadMore
                          query={lessonsQuery}
                          label={t("View More Lessons")}
                        />
                      )}
                    </React.Fragment>
                  )}
                  <ScrollToTopButton offset={2000} />
                </Main>
              </Grid>
            )}
          </LoadingAwaiter>
        </React.Fragment>
      </React.Fragment>
    </Container>
  )
}

BrowseContainer.displayName = "BrowseContainer"

export default BrowseContainer
