import { useLazyQuery } from "@apollo/client"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { useMemo } from "react"
import { flattenEdges, usePaginator } from "../../utils/apollo"
import { COMPLETIONS_FILTERS } from "../utils"
import COMPLETIONS_QUERY from "./completions-query"

const getFilter = filter => {
  switch (filter) {
    case COMPLETIONS_FILTERS.NEEDS_REVIEW:
      return { needs_review: true }
    case COMPLETIONS_FILTERS.REVIEWED:
      return { is_reviewed: true }
    case COMPLETIONS_FILTERS.COMPLETE:
      return { is_complete: true }
    case COMPLETIONS_FILTERS.INCOMPLETE:
      return { is_complete: false }
    case COMPLETIONS_FILTERS.NEEDS_ATTENTION:
      return { needs_attention: true }
    case COMPLETIONS_FILTERS.REVIEWABLE:
      return { reviewable: true }
    case COMPLETIONS_FILTERS.ALL:
      return {}
    default:
      return { needs_review: true }
  }
}

export const useCompletions = options => {
  const {
    userId,
    cohortId,
    pathId,
    sourceId,
    groupId,
    parentId,
    filter,
    ...rest
  } = options

  const first =
    typeof rest.first === "number" ? rest.first : userId || pathId ? 1042 : 50

  const variables = useMemo(
    () => ({
      user_id: userId,
      cohort_id: cohortId,
      path_id: pathId,
      group_id: groupId,
      source_id: sourceId ? parseInt(sourceId) : undefined,
      lesson_source_id: parentId ? parseInt(parentId) : parentId,
      first,
      ...getFilter(filter),
      ...rest
    }),
    [options, filter]
  )

  const query = useQuery(COMPLETIONS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network"
    // notifyOnNetworkStatusChange: true
  })

  // Oddly, at times, calling the original query's refetch results in an unclear
  // network error and the request fails to complete. Replacign the query's refetch
  // with this lazy query somehow avoids that glitch.
  const [refetch] = useLazyQuery(COMPLETIONS_QUERY, {
    variables,
    fetchPolicy: "network-only"
  })

  const { data, loading, error /*, refetch */ } = query
  const total = useMemo(() => get(data, "pathItems.total"), [data])
  const items = useMemo(() => {
    const items = data && data.pathItems ? flattenEdges(data.pathItems) : []
    return items
  }, [data])

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: query, // hacky
    path: "pathItems"
  })

  return {
    loading,
    error,
    total,
    items,
    loadMore,
    hasMore,
    loadingMore,
    refetch
  }
}

export default useCompletions
