// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let NotificationSettings
export default NotificationSettings = class NotificationSettings extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    //url = @urlRoot(ssl=no, absolute=yes)
    const url = this.urlRoot(`/api/private/notification/settings/`)
    return url
  }

  isNew() {
    return false
  }

  defaults() {
    return { send_notification_digest_email: 0 }
  }
}
