import get from "lodash/get"
import {
  ENROLLMENT_ROLE_EDITOR,
  ENROLLMENT_ROLE_FACILITATOR,
  ENROLLMENT_ROLE_OBSERVER,
  ENROLLMENT_ROLE_STUDENT,
  ENROLLMENT_ROLE_TEACHER,
  ROLES,
  ROLE_TYPE_OFFERING
} from "../invitation/constants"
import { canEditLibrary, hasAccess } from "../user/permissions"
import { GROUP_VISIBILITY_MEMBERS, GROUP_VISIBILITY_PRIVATE } from "./constants"

// check for registration or user_registration, assumes registration is always for the user (which isn't necessarily true)
const _hasRegistrationRole = (cohort, role) =>
  get(cohort, "registration.role") >= role ||
  get(cohort, "user_registration.role") >= role

// course editor
export const hasEditorLevelAccess = (pwContext, cohort) => {
  if (!cohort) return false
  if (!hasAccess(pwContext)) return false

  // check super-user/admin/editor status
  if (canEditLibrary(pwContext)) return true

  // check for the user's source registration which will either be user_registration if the cohort is the source or the user_source_registration if the cohort is not the source
  if (
    ((cohort.is_master || cohort.is_source_cohort) &&
      _hasRegistrationRole(cohort, ENROLLMENT_ROLE_EDITOR)) ||
    get(cohort, "user_source_registration.role") >= ENROLLMENT_ROLE_EDITOR
  )
    return true

  // possibly cohort has the source cohort
  if (cohort.source && hasEditorLevelAccess(pwContext, cohort.source))
    return true

  return false
}

// teacher
export const hasTeacherLevelAccess = (pwContext, cohort) => {
  if (!cohort) return false
  if (!hasAccess(pwContext)) return false

  if (hasEditorLevelAccess(pwContext, cohort)) return true

  // If they created the cohort they should have teacher access?
  if (pwContext.me.id === cohort.created_by_id) return true

  if (_hasRegistrationRole(cohort, ENROLLMENT_ROLE_TEACHER)) return true

  return false
}

// moderator
export const hasModeratorLevelAccess = (pwContext, cohort) => {
  if (!cohort) return false
  if (!hasAccess(pwContext)) return false

  if (hasEditorLevelAccess(pwContext, cohort)) return true

  if (_hasRegistrationRole(cohort, ENROLLMENT_ROLE_FACILITATOR)) return true

  return false
}

// learner
export const hasLearnerLevelAccess = (pwContext, cohort) => {
  if (!cohort) return false
  if (!hasAccess(pwContext)) return false

  if (hasEditorLevelAccess(pwContext, cohort)) return true

  // learner
  if (_hasRegistrationRole(cohort, ENROLLMENT_ROLE_STUDENT)) return true

  return false
}

// observer
export const hasObserverLevelAccess = (pwContext, cohort) => {
  if (!cohort) return false
  if (!hasAccess(pwContext)) return false

  if (hasEditorLevelAccess(pwContext, cohort)) return true

  // observer
  if (_hasRegistrationRole(cohort, ENROLLMENT_ROLE_OBSERVER)) return true

  return false
}

// Adapted from userCanSendInvites in server/graphql group permissions.
// Indicates whether the user can invite another user to a specific rule
// for the provided cohort.
export const userCanInviteRole = (pwContext, cohort, role = null) => {
  if (!cohort) return false

  const isPrivate =
    cohort.visibility === GROUP_VISIBILITY_PRIVATE ||
    cohort.visibility === GROUP_VISIBILITY_MEMBERS

  // Currently moderators/teachers can't invite directly to child cohort.
  if (cohort.parent_section_id && !hasEditorLevelAccess(pwContext, cohort)) {
    return false
  }

  if (cohort.is_master) {
    switch (role) {
      case ENROLLMENT_ROLE_EDITOR:
        // Only course editors+ can invite course editors to a default cohort.
        return hasEditorLevelAccess(pwContext, cohort)
    }
  } else {
    switch (role) {
      case ENROLLMENT_ROLE_OBSERVER:
      case ENROLLMENT_ROLE_STUDENT:
        // Only moderators+ can invite observers/learners to a private cohort.
        // Anyone can invite observers/learners to a public cohort.
        return hasModeratorLevelAccess(pwContext, cohort) || !isPrivate
      case ENROLLMENT_ROLE_FACILITATOR:
        // Only moderators+ can invite can invite moderators to a cohort.
        return hasModeratorLevelAccess(pwContext, cohort)
      case ENROLLMENT_ROLE_TEACHER:
        // Only course editors+ can invite can invite teachers to a cohort.
        return hasEditorLevelAccess(pwContext, cohort)
    }
  }

  return false
}

export const userCanInvite = (pwContext, cohort) => {
  const roles = ROLES[ROLE_TYPE_OFFERING]
  return roles.some(role => userCanInviteRole(pwContext, cohort, role))
}
