import gql from "graphql-tag"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"
import GROUP_FRAGMENT from "../../group/graphql/group-fragment"
import REGISTRATION_FRAGMENT from "./registration-fragment"

export default gql`
  query GroupQuery(
    $groupId: Int!
    $roleFilter: RoleFilter
    $dateLastActive: DateFilter
    $dateJoined: DateFilter
    $orderBy: [String]
    $cursor: String
  ) {
    group(id: $groupId) {
      ...Group
      resource {
        ...Resource
      }
      registrations(
        first: 50
        role_filter: $roleFilter
        last_active: $dateLastActive
        created: $dateJoined
        orderBy: $orderBy
        after: $cursor
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Registration
          }
        }
      }
    }
  }
  ${GROUP_FRAGMENT}
  ${RESOURCE_FRAGMENT}
  ${REGISTRATION_FRAGMENT}
`
