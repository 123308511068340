import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import pluralize from "pluralize"
import isEmpty from "lodash/isEmpty"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Button from "@pathwright/ui/src/components/button/Button"
import Text from "@pathwright/ui/src/components/ui/Text"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import View from "@pathwright/ui/src/components/ui/View"
import { RegistrationRoleType } from "../invitation/types"
import Alert from "@pathwright/ui/src/components/alert/Alert"

const FlexContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .SelectInput {
    flex-grow: 1;
    margin-right: 8px;
  }

  .ToggleInput {
    margin-right: 8px;
  }

  .TextArea {
    flex-grow: 1;

    textarea {
      padding: 5px 10px;
    }
  }
`

const AssignFooter = ({
  resourceCohortMap,
  user,
  role,
  getPrompt,
  sendNotification,
  toggleSendNotification,
  assignmentMessage,
  setAssignmentMessage,
  submit,
  error
}) => {
  const validationError = isEmpty(resourceCohortMap)
    ? "Please select at least one resource"
    : Object.values(resourceCohortMap).find(c => !c) === null
    ? "Please select a cohort for each resource you've selected"
    : !role
    ? "Please select a role"
    : null

  return (
    <View padding>
      {!!error && <Alert error={error} />}
      {sendNotification && (
        <FlexContainer>
          {assignmentMessage === null ? (
            <Button styleType="text" onClick={() => setAssignmentMessage("")}>
              Add a custom message
            </Button>
          ) : (
            <TextArea
              hideStatus
              value={assignmentMessage}
              onChange={setAssignmentMessage}
              placeholder="Add a personal message... (optional)"
            />
          )}
        </FlexContainer>
      )}
      {!!user && (
        <FlexContainer>
          <Text.Meta>
            {user.first_name} will be notified by email & in-app.
          </Text.Meta>
        </FlexContainer>
      )}
      <FlexContainer>
        <Tooltip placement="top" alignment="center" title={validationError}>
          <SubmitButton
            styleType="primary"
            size="large"
            disabled={!!validationError}
            onSubmit={submit}
          >
            Add to {user && user.first_name}'s path
          </SubmitButton>
        </Tooltip>
      </FlexContainer>
      {/* <FlexContainer>
          <ToggleInput
            hideStatus
            value={sendNotification}
            onChange={toggleSendNotification}
          />{" "}
          <Text.Body>Notify them about assignment</Text.Body>
        </FlexContainer> */}
    </View>
  )
}

AssignFooter.displayName = "AssignFooter"

AssignFooter.propTypes = {
  resourceCohortMap: PropTypes.object,
  user: PropTypes.object,
  role: RegistrationRoleType,
  getPrompt: PropTypes.func,
  sendNotification: PropTypes.bool,
  assignmentMessage: PropTypes.string,
  // updaters
  toggleSendNotification: PropTypes.func.isRequired,
  setAssignmentMessage: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
}

AssignFooter.defaultProps = {
  sendNotification: true
}

export default AssignFooter
