import "./CardHeader.css"
import React from "react"
import classnames from "classnames"
import Text from "../ui/Text"
import BackgroundImage from "../background/BackgroundImage"
import ThemeBackground from "../background/ThemeBackground"
import ResourceBackground from "../background/ResourceBackground"
import CardBack from "./CardBack"
import View from "../ui/View"
import { useScreensizeContext } from "../ui/Screensize"

const CardHeader = ({
  card,
  title,
  meta,
  className,
  image,
  overlay,
  resource,
  children
}) => {
  const screensize = useScreensizeContext()
  const roundedTop = screensize !== "sm"

  return (
    <header className={classnames("CardHeader", className)}>
      {image ? (
        <BackgroundImage
          roundedTop={roundedTop}
          image={image}
          overlay={overlay}
        />
      ) : resource ? (
        <ResourceBackground roundedTop={roundedTop} resource={resource} />
      ) : (
        <ThemeBackground roundedTop={roundedTop} />
      )}
      <View padding className="CardHeader__wrapper">
        <View className="CardHeader__padding">
          <CardBack card={card} inverted />
          <Text.H3 inverted>{title}</Text.H3>
          <Text.Body inverted>{meta}</Text.Body>
          {children}
        </View>
      </View>
    </header>
  )
}

CardHeader.displayName = "CardHeader"

export default CardHeader
