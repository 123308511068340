import { INVITATION_TYPE_SCHOOL } from "invitation/constants"
import { API } from "lib/core/api/request"
import { createStore } from "lib/mobx/mobx-store"
import { action, observable } from "mobx"

const state = {
  invite_token: null,
  invitation: observable.map(),
  invitation_loading: false,
  invitation_error: null
}

const actions = {
  loadInvitation(invite_token) {
    return new Promise((resolve, reject) => {
      if (invite_token) {
        this.invite_token = invite_token
        this.invitation_loading = true
        API.get(`invitation/${invite_token}/?type=${INVITATION_TYPE_SCHOOL}`)
          .then(
            action(invitation => {
              this.invitation = invitation
              resolve(invitation)
            })
          )
          .catch(
            action(err => {
              this.invitation_error = err
              reject(err)
            })
          )
          .then(
            action(() => {
              this.invitation_loading = false
            })
          )
      } else {
        resolve()
      }
    })
  },
  clearErrors() {
    this.invitation_error = null
  }
}

export default createStore(state, actions)
