import PropTypes from "prop-types"
import { Component } from "react"
import "velocity-animate"
import "velocity-animate/velocity.ui"
import { VelocityTransitionGroup } from "velocity-react"
import { PathwrightContext } from "../pathwright/PathwrightContext"
import SignupAccountForm from "./SignupAccountForm"
import SignupFauxLoading from "./SignupFauxLoading"
import SignUpPickPlan from "./SignUpPickPlan"
import SignupSchoolProfileForm from "./SignupSchoolProfileForm"
import SignupSelectSchool from "./SignupSelectSchool"

class SignupFlow extends Component {
  constructor(props, context) {
    super(props)
    this.state = {
      createdSchool: this.props.school || {},
      selectedSchool: {},
      step: this.props.school ? 4 : context.me ? 2 : 1,
      prev: null
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.handleStep) {
      this.props.handleStep(this.state.step)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextContext.me && !this.context.me) {
      this.handleStep(2)
    }
    if (this.context.me && !nextContext.me) {
      this.handleStep(1)
    }
  }

  handleStep = step => {
    this.setState(
      prevState => ({
        step: Math.min(5, Math.max(1, step)),
        prev: prevState.step
      }),
      () => {
        this.props.onStepChanged && this.props.onStepChanged(this.state.step)
      }
    )
  }

  handleNext = () => {
    this.handleStep(this.state.step + 1)
  }

  handleSchoolCreated = createdSchool => {
    this.setState({ createdSchool })
    this.handleSchoolSelected(createdSchool)
    if (this.props.onSchoolCreated) {
      this.props.onSchoolCreated(createdSchool)
    }
  }

  handleSchoolSelected = selectedSchool => {
    this.setState({ selectedSchool })
    if (this.props.onSchoolSelected) {
      this.props.onSchoolSelected(selectedSchool)
    }
  }

  get renderNav() {
    return () => {
      return this.props.renderNav({
        step: this.state.step,
        onChange: this.handleStep
      })
    }
  }

  get renderHeader() {
    return (headerProps = {}) => {
      return this.props.renderHeader({
        // from form component
        ...headerProps,
        // pass auth view as step (see SignUpOrIn)
        step: headerProps.view ? headerProps.view : this.state.step
      })
    }
  }

  get renderSubmit() {
    return (submitProps = {}) => {
      return this.props.renderSubmit({
        // pass auth view as step (see SignUpOrIn)
        step: submitProps.view ? submitProps.view : this.state.step,
        // from form component
        ...submitProps
      })
    }
  }

  renderStep() {
    // keys prop necessary for VelocityTransitionGroup to recognize new children.
    // SignupFauxLoading shares duplicate key to trick VelocityTransitionGroup into skipping transition.
    switch (this.state.step) {
      case 1:
        return (
          <SignupAccountForm
            renderHeader={this.renderHeader}
            renderSubmit={this.renderSubmit}
            inverted={this.props.inverted}
            onNext={this.handleNext}
            key={1}
          />
        )
      case 2:
        return (
          <SignupSelectSchool
            renderHeader={this.renderHeader}
            renderSubmit={this.renderSubmit}
            licensorId={this.props.licensorId}
            namePlaceholder={this.props.namePlaceholder}
            utmParameters={this.props.utmParameters}
            copy={this.props.copy}
            onSchoolCreated={this.handleSchoolCreated}
            onSchoolSelected={this.handleSchoolSelected}
            onSchoolLinked={this.props.onSchoolLinked}
            inverted={this.props.inverted}
            onNext={this.handleNext}
            key={2}
          />
        )
      case 3:
        return (
          <SignupFauxLoading
            createdSchool={this.state.createdSchool}
            inverted={this.props.inverted}
            onNext={this.handleNext}
            key={3}
          />
        )
      case 4:
        return (
          <SignupSchoolProfileForm
            licensorId={this.props.licensorId}
            renderHeader={this.renderHeader}
            renderSubmit={this.renderSubmit}
            initialSchoolType={this.props.initialSchoolType}
            createdSchool={this.state.createdSchool}
            inverted={this.props.inverted}
            onNext={this.handleNext}
            key={4}
          />
        )
      case 5:
        return (
          <SignUpPickPlan
            schoolId={this.state.createdSchool.id}
            stripeApiKey={this.props.stripeApiKey}
            inverted={this.props.inverted}
          />
        )
      default:
        return null
    }
  }

  render() {
    const animation =
      this.state.step === 4
        ? "transition.slideUpIn"
        : this.state.prev > this.state.step
        ? "transition.slideLeftIn"
        : "transition.slideRightIn"
    return (
      <VelocityTransitionGroup
        enter={{ animation, duration: this.props.animate ? 600 : 0 }}
      >
        {this.renderNav()}
        {this.renderStep()}
      </VelocityTransitionGroup>
    )
  }
}

SignupFlow.displayName = "SignupFlow"

SignupFlow.contextType = PathwrightContext

SignupFlow.propTypes = {
  animate: PropTypes.bool,
  // customize nav, header, and submit button
  renderNav: PropTypes.func.isRequired,
  renderHeader: PropTypes.func.isRequired,
  renderSubmit: PropTypes.func.isRequired,
  // see SCHOOL_TYPE (in ./constants)
  initialSchoolType: PropTypes.string,
  // listen to form progress (for tracking)
  onStepChanged: PropTypes.func.isRequired,
  // listen for school creation event
  onSchoolCreated: PropTypes.func,
  // Optionally provide target school
  school: PropTypes.object,
  // Pathwright's Stripe API key for processing platform subscriptions.
  stripeApiKey: PropTypes.string.isRequired
}

export default SignupFlow
