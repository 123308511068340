import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { WithCSS } from "../utils/styles"
import { getOrderedList } from "./utils"

const SortableList = props => {
  const {
    className,
    onChange,
    onDragStart,
    onDragEnd,
    renderItem,
    deferDragHandlingToItem,
    disableInteractiveElementBlocking
  } = props

  const [list, setList] = useState(props.list)

  const updateListOrder = dragEnd => {
    const { list: newList } = getOrderedList(list, dragEnd)
    onChange(newList)
    setList(newList)
  }

  return (
    <DragDropContext
      onDragStart={onDragStart}
      onDragEnd={dragEnd => {
        onDragEnd && onDragEnd(dragEnd)
        updateListOrder(dragEnd)
      }}
    >
      <Droppable droppableId="list" type="sortable-list">
        {(provided, droppableSnapshot) => (
          <WithCSS
            as="ul"
            className={classnames("SortableList", className)}
            css={`
              padding: 0;
              margin: 0;

              li {
                list-style: none;
              }
            `}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {list.map((item, index) => (
              <Draggable
                key={item.id}
                index={index}
                type="sortable-list"
                draggableId={`${item.id}`}
                disableInteractiveElementBlocking={
                  disableInteractiveElementBlocking
                }
              >
                {(provided, draggableSnapshot) =>
                  deferDragHandlingToItem ? (
                    <li ref={provided.innerRef} {...provided.draggableProps}>
                      {renderItem({
                        item,
                        isDragging: draggableSnapshot.isDragging,
                        dragHandleProps: provided.dragHandleProps
                      })}
                    </li>
                  ) : (
                    <li
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      {renderItem({
                        item,
                        isDragging: draggableSnapshot.isDragging
                      })}
                    </li>
                  )
                }
              </Draggable>
            ))}
            {provided.placeholder}
          </WithCSS>
        )}
      </Droppable>
    </DragDropContext>
  )
}

SortableList.displayName = "SortableList"

SortableList.deafaultProps = {
  droppableId: "foo",
  deferDragHandlingToItem: false,
  disableInteractiveElementBlocking: false
}

SortableList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  renderItem: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  droppableId: PropTypes.string,
  deferDragHandlingToItem: PropTypes.bool,
  disableInteractiveElementBlocking: PropTypes.bool
}

export default SortableList
