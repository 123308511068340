// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let Category
export default Category = (function() {
  Category = class Category extends require("lib/static-shim").default(
    require("lib/utils/model").default
  ) {
    static initClass() {
      this.prototype.defaults = { order: 0 }
    }

    url() {
      let url = this.urlRoot(`/api/private/library/category/`)
      if (this.id) {
        url += `${this.id}/`
      }
      return url
    }
  }
  Category.initClass()
  return Category
})()
