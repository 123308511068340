import joinStore from "join/store/join-store"
import StoreOrLibraryBrowse from "store/StoreOrLibraryBrowse"
import { getStoreUrl } from "store/utils"
import Join from "./components/Join"

const enterJoin = route => {
  const { ic } = route.location.query
  joinStore.action.loadInvitation(ic)
}

export default {
  path: "/join/",
  onEnter: enterJoin,
  getDefaultLaunchedFrom: () => getStoreUrl(),
  components: {
    modal: Join,
    primary: StoreOrLibraryBrowse
  }
}
