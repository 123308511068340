import PropTypes from 'prop-types';
import React from "react"
import "./Col.css"

const Col = function({ children, xs, sm, md, lg, xl, gutter, className }) {
  const getClass = function() {
    let cn = "Col"
    if (className) {
      cn += ` ${className}`
    }
    if (xs != null) {
      cn += ` Col--xs-${xs}`
    }
    if (sm != null) {
      cn += ` Col--sm-${sm}`
    }
    if (md != null) {
      cn += ` Col--md-${md}`
    }
    if (lg != null) {
      cn += ` Col--lg-${lg}`
    }
    if (xl != null) {
      cn += ` Col--xl-${xl}`
    }
    return cn
  }

  const getGutter = function() {
    const style = {
      paddingLeft: gutter,
      paddingRight: gutter,
    }
    return style
  }

  return (
    <li className={getClass()} style={getGutter()}>
      {children}
    </li>
  )
}

Col.displayName = "Col"
Col.contextTypes =
  // negative margin on row and padding on col
  { gutter: PropTypes.number }
Col.propTypes = {
  // width of col (0-12) at <480px
  xs: PropTypes.number,
  // width of col (0-12) at <758px
  sm: PropTypes.number,
  // width of col (0-12) at <992px
  md: PropTypes.number,
  // width of col (0-12) at <1200px
  lg: PropTypes.number,
  // width of col (0-12) at 1200+px
  xl: PropTypes.number,
}

export default Col
