import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import React, { Component } from "react"

class MediaQuery extends Component {
  render() {
    const { data, children } = this.props
    const { loading, error, media } = data || {}
    const { image, filestack } = media || {}
    return React.Children.only(children({ loading, error, image, filestack }))
  }
}

MediaQuery.displayName = "MediaQuery"

MediaQuery.propTypes = {
  children: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  blur: PropTypes.number,
  dpr: PropTypes.number,
  crop: PropTypes.string,
  fit: PropTypes.string
}

MediaQuery.defaultProps = {
  fit: "clamp"
}

const mediaQuery = gql`
  query(
    $url: String!
    $width: Int
    $height: Int
    $blur: Int
    $dpr: Int
    $crop: crop
    $fit: fit
  ) {
    media {
      image(
        url: $url
        width: $width
        height: $height
        blur: $blur
        dpr: $dpr
        crop: $crop
        fit: $fit
      )
      filestack {
        api_key
        policy
        signature
      }
    }
  }
`

export default graphql(mediaQuery, {
  options(props) {
    return {
      variables: {
        url: props.url,
        width: props.width,
        height: props.height,
        blur: props.blur,
        dpr: props.dpr,
        crop: props.crop,
        fit: props.fit
      }
    }
  },
  skip: ({ url }) => !url
})(MediaQuery)
