import { Button, HStack, Heading, Spacer, Text, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { usePdfDimensions } from "@pathwright/ui/src/components/filestack/utils"
import { useMeasure } from "react-use"
import { useUpdateResourceCertificateEnabled } from "../gating/DisabledResourceCertificateGate"
import useActiveCertificate from "../hooks/useActiveCertificate"
import CertificateTemplate from "../template/CertificateTemplate"
import CertificateResourceRemoveConfirm from "./CertificateResourceRemoveConfirm"

const CertificateResourceUpdatePrompt = ({ resource }) => {
  const certificate = useActiveCertificate()
  const { template } = certificate
  const templateDimensions = usePdfDimensions(template)
  const [nodeRef, size] = useMeasure()

  const [enableResourceCertificate, enableState] =
    useUpdateResourceCertificateEnabled({
      enable: true
    })
  const [disableResourceCertificate, disableState] =
    useUpdateResourceCertificateEnabled({
      enable: false
    })

  return (
    <VStack spacing={4} padding={4} pt={0} minW="100%" textAlign="center">
      <Pathicon icon="certificate" style={{ fontSize: "24px" }} />
      <Heading as="h3" size="md">
        Configure Completion Certificates
      </Heading>
      <Text textAlign="center">
        View and edit the certificate learners receive on completion of{" "}
        {resource.name}.
      </Text>
      <VStack
        maxW="250px"
        ref={nodeRef}
        spacing={0}
        pos="relative"
        width="100%"
      >
        <CertificateTemplate
          template={template}
          templateDimensions={templateDimensions}
          scaleSize={size}
        />
        {resource.has_certificate && (
          <CertificateResourceRemoveConfirm
            resource={resource}
            onRemove={disableResourceCertificate}
          >
            {({ confirm }) => (
              <HStack pos="absolute" top={0} right={0}>
                <IconButton
                  icon="edit"
                  styleType="primary"
                  to={`/certificate/?resource_id=${resource.id}`}
                  brand
                />
                <IconButton
                  icon="x"
                  styleType="primary"
                  onClick={confirm}
                  brand
                />
              </HStack>
            )}
          </CertificateResourceRemoveConfirm>
        )}
      </VStack>
      <Spacer />
      {!resource.has_certificate && (
        <Button
          colorScheme="brand"
          variant="solid"
          size="lg"
          onClick={() => enableResourceCertificate(resource)}
          isLoading={enableState.loading}
        >
          Enable Certificate
        </Button>
      )}
    </VStack>
  )
}

export default CertificateResourceUpdatePrompt
