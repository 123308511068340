import React from 'react'
import DiscussionActivity from './DiscussionActivity'

const CreateDiscussionActivity = props => {
  return <DiscussionActivity {...props} discussion_response={null} />
}

CreateDiscussionActivity.displayName = 'CreateDiscussionActivity'

export default CreateDiscussionActivity
