import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import SortableList from "@pathwright/ui/src/components/sortable/SortableList"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import PAGES_QUERY from "../graphql/pages-query"
import UPDATE_PAGE_ORDERS_MUTATION from "../graphql/update-page-orders-mutation"

const ManagePages = ({ card, loading, error, pages, updatePageOrders }) => {
  return pages ? (
    <Card card={card} title={`Pages`}>
      {pages.length > 1 ? (
        <ScrollView style={{ minHeight: 400 }} fill={true}>
          <SortableList
            list={pages}
            onChange={updatePageOrders}
            deferDragHandlingToItem
            renderItem={({ item, dragHandleProps }) => {
              return (
                <CardLink
                  label={item.name}
                  meta={item.title || item.slug}
                  to={`${window.location.pathname}${item.id}/`}
                  dragHandleProps={dragHandleProps}
                />
              )
            }}
          />
        </ScrollView>
      ) : (
        <CardLink
          label={pages[0].name}
          meta={pages[0].title || pages[0].slug}
          to={`${window.location.pathname}${pages[0].id}/`}
        />
      )}
    </Card>
  ) : error ? (
    <Alert error={error} />
  ) : loading ? (
    <LoadingCircle />
  ) : null
}

ManagePages.displayName = "ManagePages"

ManagePages.propTypes = {
  card: PropTypes.object.isRequired
}

export default compose(
  graphql(PAGES_QUERY, {
    options: () => ({
      variables: {}
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      pages: flattenEdges(get(data, "school.pages")),
      total: get(data, "school.pages.total", 0),
      hasMore: get(data, "school.pages.pageInfo.hasNextPage"),
      loadMore: createPaginator(data, "school.pages")
    })
  }),
  graphql(UPDATE_PAGE_ORDERS_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      updatePageOrders: changes => {
        const page_orders = changes.map((c, i) => ({
          id: c.id,
          order: i
        }))
        return mutate({ variables: { page_orders } })
      }
    })
  })
)(ManagePages)
