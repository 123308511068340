import React from "react"
import Text from "@pathwright/ui/src/components/ui/Text"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import BrowseResult from "./BrowseResult"
import StoreResourcesQuery from "../../graphql/StoreResourcesQuery"

function BrowseResultContainer({
  heading,
  onQuery,
  browseTo,
  resourcesQueryOptions
}) {
  return (
    <StoreResourcesQuery {...resourcesQueryOptions}>
      {data => (
        <BrowseResult
          heading={heading}
          total={data.total}
          loading={data.loading}
          resources={data.resources}
          onClear={() => onQuery(null)}
          browseTo={browseTo}
          hasMore={data.hasMore}
          loadMore={data.loadMore}
        />
      )}
    </StoreResourcesQuery>
  )
}

BrowseResultContainer.displayName = "BrowseResultContainer"

export default BrowseResultContainer
