import { getTierIsWithinRange } from "../StripePricePreview"
import getStripeProductMinQuantity from "./getStripeProductMinQuantity"

export default function getStartingStripePriceAmount(stripePrice) {
  const minQuantity = getStripeProductMinQuantity(stripePrice.product)

  if (stripePrice.billing_scheme === "tiered") {
    const amount =
      stripePrice.tiers?.filter(tier =>
        getTierIsWithinRange(stripePrice, tier)
      )?.[0]?.unit_amount || 0

    return amount * minQuantity
  } else {
    return stripePrice.unit_amount_decimal * minQuantity
  }
}
