// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let PathwrightPlanCollection
export default PathwrightPlanCollection = (function() {
  PathwrightPlanCollection = class PathwrightPlanCollection extends require("lib/static-shim").default(
    require("lib/core/collection").default
  ) {
    static initClass() {
      this.prototype.model = require("./pathwright-plan").default
    }

    url() {
      return this.urlRoot(`/api/private/billing/plan/`)
    }
  }
  PathwrightPlanCollection.initClass()
  return PathwrightPlanCollection
})()
