import React from "react"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"

const AuthorDelete = ({ id, name, count, onDelete, onCancel }) => (
  <BlankSlate
    icons={null}
    heading={`Are you sure you want to delete the author, ${name}?`}
    body="Deleting this author will not impact any resources previously attributed to them."
    primaryAction={{
      children: "Yes, Delete",
      dangerous: true,
      onClick: () => onDelete(id)
    }}
    secondaryAction={{
      children: "Cancel",
      onClick: () => onCancel(id)
    }}
  />
)

AuthorDelete.displayName = "AuthorDelete"

export default AuthorDelete
