import "./ButtonGroup.css"
import React, { Children, useCallback } from "react"
import classnames from "classnames"

// Applies styles on child <Buttons />

const ButtonGroup = React.forwardRef(function ButtonGroup(
  { children, className, border, ...rest },
  ref
) {
  // unfortunately there isn't a good strictly CSS solution for styling the borders of nested nth-child buttons
  // Though, one solution would be to set all buttons to have no border radius then let ButtonGroup handle border radius, but that requires setting overfow hidden on ButtonGroup but that will clip any overflow content.
  const setStyles = useCallback(node => {
    if (typeof ref === "function") {
      ref(node)
    } else if (ref) {
      ref.current = node
    }

    if (node) {
      const buttons = Array.from(
        node.querySelectorAll(".Button:not(.IgnoreButtonGroup)")
      )
      const l = buttons.length
      for (let i = 0; i < l; i++) {
        if (i >= 0 && i < l - 1 && l > 1) {
          buttons[i].style["border-top-right-radius"] = 0
          buttons[i].style["border-bottom-right-radius"] = 0
        }
        if (i >= 1 && l > 1) {
          buttons[i].style["border-top-left-radius"] = 0
          buttons[i].style["border-bottom-left-radius"] = 0
        }
        if (border) {
          if (i !== l - 1 && l > 1) {
            buttons[i].style["border-right"] = "1px solid rgba(0, 0, 0, 0.1)"
          }
        }
      }
    }
  })

  return (
    <div
      className={classnames("ButtonGroup", className)}
      ref={setStyles}
      {...rest}
    >
      {Children.toArray(children)}
    </div>
  )
})

ButtonGroup.defaultProps = {
  border: true
}

export default ButtonGroup
