import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import { useHistory, useLocation } from "react-router-dom"

export const PreviousLocationContext = createContext()

export const PreviousLocationProvider = ({ children }) => {
  const history = useHistory()
  // Need to intialize prevLocationRef with the current location since
  // the handler only fires after the first navigation change.
  const prevLocationRef = useRef(history.location)
  const [previousLocation, setPreviousLocation] = useState()

  useEffect(() => {
    const handler = (location, action) => {
      // Ignore "REPLACE" chnages to history.
      if (action !== "REPLACE") {
        if (prevLocationRef.current !== location) {
          // Only updating if location has changed.
          setPreviousLocation(prevLocationRef.current)
        }
        // Update last location ref.
        prevLocationRef.current = location
      }
    }

    // Returns an unlisten handler for useEffect cleanup.
    return history.listen(handler)
  }, [])

  return (
    <PreviousLocationContext.Provider value={previousLocation}>
      {children}
    </PreviousLocationContext.Provider>
  )
}

export const usePreviousLocation = () => useContext(PreviousLocationContext)

// Provides the location prior to when the component was mounted.
// Since the component will mount after the previousLocation is
// updated in state, we must check for a the first change in previousLocation,
// but before any subsequent location change occurs.
export const usePreMountLocation = defaultLocation => {
  const currentLocation = useLocation()
  const previousLocation = usePreviousLocation()
  const [preMountLocation, setPreMountLocation] = useState()
  const intialLocation = useRef(currentLocation).current

  useEffect(() => {
    if (!preMountLocation && currentLocation === intialLocation) {
      setPreMountLocation(previousLocation)
    }
  }, [previousLocation])

  return preMountLocation || defaultLocation
}

export default usePreviousLocation
