import Button from "@pathwright/ui/src/components/button/Button"
import { preview } from "@pathwright/ui/src/components/filestack/utils"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import PropTypes from "prop-types"
import styled from "styled-components"
import Modal from "../Modal"
import {
  FULLSCREEN_LAYER_BASE,
  FULLSCREEN_LAYER_OVERLAY
} from "@pathwright/ui/src/components/fullscreen/Fullscreen"

const PREVIEW_ID = "filestack-preview"

const PreviewWrapper = styled.div`
  height: calc(100% - 60px);
  width: 100%;
`

const FILESTACK_STYLES_URL =
  "https://duointeractive.github.io/filestack-styles.css"

const ModalButton = styled(Button)`
  position: relative;
  text-align: left;
  color: ${(p) => p.themeColor || "gray"};
  max-height: 30px;
  width: auto;
  height: 100%;
  text-decoration: underline;

  .Pathicon {
    color: gray;
    text-decoration: none;
    margin-right: 5px;
  }
`

const FilestackPreviewModal = ({
  previewHandle,
  overlayStyles = {},
  contentStyles = {},
  downloadURL,
  theme,
  onCloseModal
}) => {
  const handleOpenModal = () => {
    preview(previewHandle, PREVIEW_ID, FILESTACK_STYLES_URL)
  }

  return (
    <Modal
      isOpen={true}
      onAfterOpen={handleOpenModal}
      contentLabel="Filestack Preview Modal"
      domID="filestack-preview-modal"
      overlayStyles={{
        backgroundColor: "rgba(255,255,255,0.7)",
        zIndex: FULLSCREEN_LAYER_BASE + FULLSCREEN_LAYER_OVERLAY + 10,
        ...overlayStyles
      }}
      contentStyles={{ width: "95%", ...contentStyles }}
      closeText="Close"
      renderCloseButton={() => (
        <ModalButton
          className="exit-button"
          styleType="blank"
          onClick={onCloseModal}
          themeColor={theme ? theme.primaryBrandColor : null}
        >
          <Pathicon icon="x" />
          Close
        </ModalButton>
      )}
      renderTopRightButton={() => (
        <ModalButton
          className="exit-button"
          styleType="blank"
          download={downloadURL}
          href={downloadURL}
          themeColor={theme ? theme.primaryBrandColor : null}
        >
          <Pathicon icon="download" />
          Download
        </ModalButton>
      )}
    >
      <PreviewWrapper id={PREVIEW_ID} />
    </Modal>
  )
}

FilestackPreviewModal.propTypes = {
  // previewHandles: PropTypes.arrayOf(PropTypes.string).isRequired
  previewHandle: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  downloadURL: PropTypes.string.isRequired,
  theme: PropTypes.object,
  overlayStyles: PropTypes.object,
  contentStyles: PropTypes.object
}

export default FilestackPreviewModal
