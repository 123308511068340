export const isTasklistComplete = data => {
  const { tasks, completedTasks } = data
  if (!tasks || !tasks.length || !completedTasks || !completedTasks.length)
    return false

  const completedCount = completedTasks.length
  const totalCount = tasks.length

  return completedCount >= totalCount
}

export default {
  type: "Tasklist",
  icon: "to-do", // TODO: pathicon to-do => todo
  helpLink: "https://help.pathwright.com/create-a-course/tasklist-block-new",
  menu: {
    category: "task",
    order: 4
  },
  layouts: {
    tasklist: {
      key: "tasklist",
      label: "Tasklist",
      data: {
        type: "Tasklist",
        title: "Paper submission checklist",
        description:
          "<p>Make sure you've completed the following tasks before submitting your paper.</p>",
        tasks: [
          {
            content: "<p>My spelling and grammar is correct</p>",
            order: 1
          },
          {
            content:
              "<p>My document is nicely formatted and using a readable font</p>",
            order: 2
          },
          {
            content: "<p>I've correctly attributed my sources</p>",
            order: 3
          },
          {
            content: "<p>I've included a cover letter </p>",
            order: 4
          }
        ]
      }
    }
  },
  resolveUserCompletion: (context, data, layout) => {
    const progress = isTasklistComplete(data) ? 1 : 0

    return Promise.resolve({
      progress,
      score: null
    })
  },
  schema: `
    enum ListFeedbackShowCondition {
      ALWAYS
      COMPLETE
      INCOMPLETE
    }

    type ListFeedback {
      html: String!
      showCondition: ListFeedbackShowCondition @default(value: "ALWAYS")
    }

    type Task {
      content: String!
      order: Int!
    }

    type Tasklist implements Block {
      id: ID!
      tasks: [Task]
      title: String
      description: String
      feedback: [ListFeedback]
      completedTasks: [Task] @scope(type: USER)
    }
  `
}
