import { observer } from "mobx-react"
import store from "invitation/invite/store/invite-store"
import pluralize from "pluralize"
import Text from "@pathwright/ui/src/components/ui/Text"
import Button from "@pathwright/ui/src/components/button/Button"

const LicensedSeats = props => {
  const offering = store.context && store.context.offering

  if (
    !offering ||
    !offering.resource_license ||
    offering.resource_license.ignore_seat_count
  ) {
    return null
  }

  const seatsAvailable = Math.max(
    offering.resource_license.seat_count -
      offering.resource_license.seats_filled,
    0
  )
  const subscriptionAvailable =
    offering.include_in_subscription_plans.length > 0
  const costsToJoin =
    offering.resource_license.license_offering.cost_per_seat > 0
  const prepurchased =
    offering.resource_license.seat_count > 0 ? "pre-purchased" : ""
  const seatsCount = !!seatsAvailable ? seatsAvailable : "no"
  const seatsPlural = pluralize("seat", seatsAvailable)
  const subscriptionClause = subscriptionAvailable
    ? " (subscribers can join for free)"
    : ""
  const paymentClause =
    "Additional invitees will need to pay for their own seats"
  const inviteClause = `${paymentClause}${subscriptionClause}`
  const purchaseClause = costsToJoin ? "purchase" : "get"

  return offering.resource_license.is_belltower_school_licensing ? (
    <Text.Body>
      You have {seatsCount} {seatsPlural} remaining. If you'd like to{" "}
      {purchaseClause} more seats, please contact us at{" "}
      <Button
        styleType="text"
        href="mailto:belltower@pathwright.com"
        target="_blank"
      >
        belltower@pathwright.com
      </Button>
      .
    </Text.Body>
  ) : costsToJoin ? (
    <Text.Body>
      You have {seatsCount} {prepurchased} {seatsPlural} remaining.{" "}
      {inviteClause}.
    </Text.Body>
  ) : null
}

LicensedSeats.displayName = "LicensedSeats"

export default observer(LicensedSeats)
