import { createDownloader as createDownloaderFn } from "@pathwright/ui/src/components/form/form-file-input/utils"

// create a downloader based on the response for downloading a CSV list of
// options can have 'key' for specifying a json key to extract and 'filename' for if the response doesn't have a content-disposition header
export const createDownloader = async (response, options = {}) => {
  let { key, filename } = options
  let text = null

  filename = getFilename(response) || filename

  if (key) {
    // assuming we're expecting json in response
    const json = await response.json()
    text = json[key].join("\n")
  } else {
    text = await response.text()
  }

  return createDownloaderFn(text, filename)
}

const getFilename = response => {
  const contentDisposition = response.headers.get("content-disposition")
  if (contentDisposition) {
    return contentDisposition.split("filename=")[1]
  }
  return null
}
