class Resource extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  static initClass() {
    this.prototype.defaults = {
      cover_image: null,
      categories: [],
      authors: [],
      resource_type: 10
    }
    // course
  }

  url() {
    let url = this.urlRoot(`/api/private/library/resource/`)
    if (this.id) {
      url = `${url}${this.id}/`
    }
    return url
  }

  parse(response) {
    if (response.cover_image == null) {
      response.cover_image = response.image || null
    }
    delete response["image"]
    response.authors = this.mapToIds(response.authors)
    response.categories = this.mapToIds(response.categories)
    return response
  }

  toJSON() {
    const json = super.toJSON()
    json.authors = this.mapToIds(json.authors)
    json.categories = this.mapToIds(json.categories)
    return json
  }

  mapToIds(data) {
    let mapped
    const sample = _.first(data || [])
    if (sample && _.isObject(sample)) {
      mapped = data.map(d => d.id || [])
    }
    return mapped || data
  }
}
Resource.initClass()

export default Resource
