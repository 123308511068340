// Globals (yay)
window._ = require("lodash")
window.Marionette = require("backbone.marionette")
window.React = require("react")
window.jQuery = window.$ = require("jquery")
window.rivets = require("rivets")
// TODO: drop/depricate this. nunjucks is big.
window.nunjucks = require("nunjucks")

window.moment = require("moment")

// Explicitly add supported i18n/moment.js languages here
// See locale codes: https://www.science.co.il/language/Locale-codes.php
import "moment/locale/ar" // Arabic
import "moment/locale/de" // German
import "moment/locale/es" // Spanish
import "moment/locale/fr" // French
import "moment/locale/hi" // Hindi
import "moment/locale/pl" // Polish
import "moment/locale/pt" // Portuguese
import "moment/locale/sv" // Swedish
import "moment/locale/ta" // Tamil
import "moment/locale/th" // Thai
import "moment/locale/uk" // Ukrainian
import "moment/locale/zh-cn" // Chinese - China
import "moment/locale/zh-hk" // Chinese Hong Kong SAR
import "moment/locale/zh-mo" // Macau SAR
import "moment/locale/zh-tw" // Taiwan
import "moment/locale/sl" // Slovenian
import "moment/locale/th" // Russian
import "moment/locale/cs" // Czech
import "moment/locale/hu" // Hungarian
import "moment/locale/lv" // Latvian
import "moment/locale/ro" // Romanian
import "moment/locale/sk" // Slovak
import "moment/locale/tr" // Turkish
