import { useMutation } from "@apollo/client"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Text from "@pathwright/ui/src/components/ui/Text"
import { Formik } from "formik"
import styled from "styled-components"
import UPDATE_REGISTRATION_MUTATION from "../../../registration/graphql/update-registration-mutation"

const tPrefix = "group_notifications_form"

const Toggle = styled(ToggleInput)`
  padding: 5px 10px;
  & > div {
    align-items: center;
  }
`

const GroupNotificationsForm = ({ registration, labelWidth = 150 }) => {
  const { t } = useTranslate()
  const [mutation] = useMutation(UPDATE_REGISTRATION_MUTATION)

  return (
    <Formik
      initialValues={{
        discussion: registration.send_discussion_notifications,
        dates: registration.send_due_notifications
      }}
      enableReinitialize
      validate={values => {
        const changes = {
          id: registration.id,
          send_discussion_notifications: !!values.discussion,
          send_due_notifications: !!values.dates
        }

        mutation({
          variables: changes,
          optimisticResponse: {
            updateRegistration: {
              ...changes,
              __typename: "Registration"
            }
          }
        })
      }}
    >
      {({ values, handleChange }) => (
        <div>
          <Toggle
            name="discussion"
            value={values.discussion}
            onChange={(value, e) => handleChange(e)}
            label={
              <Text.Meta>{t(`${tPrefix}.notify_me_discussions`)}</Text.Meta>
            }
            labelWidth={labelWidth}
            hideStatus
            alignRight
          />
          <Toggle
            name="dates"
            value={values.dates}
            onChange={(value, e) => handleChange(e)}
            label={<Text.Meta>{t(`${tPrefix}.notify_me_due`)}</Text.Meta>}
            labelWidth={labelWidth}
            hideStatus
            alignRight
          />
        </div>
      )}
    </Formik>
  )
}

GroupNotificationsForm.displayName = "GroupNotificationsForm"

export default GroupNotificationsForm
