import { useFormikContext } from "formik"
import defaults from "lodash/defaults"
import pick from "lodash/pick"

// Helper function for Formik's validate prop.
// Accepts a validator function used to return either an error String or null.
// The errors object is then handled by Formik and used to determine if the form is valid for submission
export const validate = validator => values =>
  Object.entries(values).reduce((errors, [key, value]) => {
    const error = validator(key, value, values)

    if (error)
      return {
        ...errors,
        [key]: error
      }

    return errors
  }, {})

// util for getting form error
export const getFormError = (form, key, mustBeTouched = true) => {
  const { initialValues, values, errors, touched } = form
  // only get error for key if the form values have been altered
  // const hasValue = Object.entries(values).reduce(
  //   (hasValue, [key, value]) => value !== initialValues[key] || hasValue,
  //   false
  // )

  if (errors[key] && (!mustBeTouched || touched[key])) {
    return [errors[key]]
  }
  return null
}

export const useInitialValues = initialValues => {
  const formikContext = useFormikContext()

  // Allow formikContext initialValues to ovderride the default initialValues.
  if (formikContext) {
    const providedInitialValues = pick(
      formikContext.initialValues,
      Object.keys(initialValues)
    )

    return defaults(providedInitialValues, initialValues)
  }

  return initialValues
}
