// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let PaymentHistoryCollection
export default PaymentHistoryCollection = (function() {
  PaymentHistoryCollection = class PaymentHistoryCollection extends require("lib/static-shim").default(
    require("lib/core/collection").default
  ) {
    static initClass() {
      this.prototype.model = require("./payment-history").default
    }

    url() {
      return this.urlRoot(`/api/private/payment/history/`)
    }

    parse(response) {
      this.download_url = response.download_url
      return response.payments
    }
  }
  PaymentHistoryCollection.initClass()
  return PaymentHistoryCollection
})()
