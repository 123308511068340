import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import IconButton from "../button/IconButton"
import ErrorMessage from "../error/ErrorMessage"
import View from "../ui/View"
import "./Alert.css"

const Alert = function({
  success,
  error,
  info,
  loading,
  onClear,
  children,
  inverted,
  style,
  icon: upstreamIcon,
  ...rest
}) {
  const className = classnames(
    "Alert",
    {
      "Alert--inverted": inverted,
      "Alert--loading": loading != null,
      "Alert--success": success != null,
      "Alert--error": error != null,
      "Alert--info": info != null,
      "Alert--clear": !!onClear
    },
    rest.className
  )

  const icon = upstreamIcon
    ? upstreamIcon
    : error
    ? "caution-triangle"
    : "check-square"
  if (success || error || loading || info || children) {
    return (
      <View className={className} rounded style={style}>
        {upstreamIcon || (!loading && !info) ? (
          <IconButton icon={icon} inverted={inverted} />
        ) : null}
        {success && typeof success !== "boolean" && <span>{success}</span>}
        {info && typeof info !== "boolean" && <span>{info}</span>}
        {error && typeof error !== "boolean" && <ErrorMessage error={error} />}
        {!!loading && (
          <div className="Alert--download__text">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5em"
              }}
            >
              <LoadingCircle
                size={20}
                style={{
                  position: "static",
                  left: "0",
                  top: "0",
                  margin: "0px 5px 0 0"
                }}
              />
              {loading.title}
            </span>
            {!!loading.description && (
              <span className="Alert--download__subtext">
                {loading.description}
              </span>
            )}
          </div>
        )}
        {children}
        {onClear && (
          <IconButton
            inverted={inverted}
            icon="x"
            styleType="blank"
            onClick={e => {
              e.preventDefault()
              onClear(e)
            }}
          />
        )}
      </View>
    )
  } else {
    return null
  }
}

Alert.displayName = "Alert"

Alert.propTypes = {
  success: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool
  ]),
  loading: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string
  }),
  onClear: PropTypes.func,
  inverted: PropTypes.bool
}

export default Alert
