import React from "react"
import BackgroundImage from "./BackgroundImage"

const ResourceBackground = ({
  resource,
  className,
  size,
  forceBlur,
  rounded,
  roundedTop,
  roundedBottom,
  shadow,
  overlay,
  dots,
  zoom,
  children,
  style
}) => {
  if (!overlay) overlay = resource.background_overlay
  const image =
    resource.background_type === 5 || forceBlur
      ? resource.background_image_blurred_cdn || resource.blurred_background
      : resource.background_image

  return (
    <BackgroundImage
      className={className}
      overlay={overlay}
      image={image}
      size={size}
      rounded={rounded}
      roundedTop={roundedTop}
      roundedBottom={roundedBottom}
      shadow={shadow}
      dots={dots}
      zoom={zoom}
      style={style}
    >
      {children}
    </BackgroundImage>
  )
}

ResourceBackground.defaultProps = {
  className: "ResourceBackground"
}

ResourceBackground.displayName = "ResourceBackground"

export default ResourceBackground
