import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { usePaginator } from "../utils/apollo"

const SectionQuery = ({ queryString, searchTerm, onUpdateQuery }) => {
  const query = useQuery(queryString, {
    fetchPolicy: "network-only"
  })

  // NOTE: in order for the section query to auto-paginatee, must add a
  // "_pathToPageInfo" key to the gql object.
  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: query, // hacky
    path: queryString._pathToPageInfo
  })

  // Auto-paginate all remaining pages when user is searching.
  // This will automatically update the list of items loaded in memory that
  // the user can search from.
  useEffect(() => {
    if (searchTerm && hasMore && !loadingMore) {
      loadMore()
    }
  }, [searchTerm, loadMore])

  useEffect(() => {
    onUpdateQuery(query)
  }, [query])

  return null
}

SectionQuery.displayName = "SectionQuery"

// The goal of withSectionQueries is to supply to the wrapped Component the merged data and
// query state of all queries used to build the section data. At the same time, the SectionQuery
// component is responsible for auto-paginating all pages of a query when user is searching, ensuring
// the in-memory section data has all the data it needs in order to perform an accurate search.
const withSectionQueries = Component => props => {
  const [queries, setQueries] = useState(new Map())
  // Combine queries into one.
  const [query, setQuery] = useState({
    data: null,
    loading: true, // Defaulting to true since fetchPolicy is "network-only".
    error: null
  })

  const handleUpdateQuery = (queryString, query) => {
    queries.set(queryString, query)
    // NOTE: need a new Map for change to be detected in useEffect.
    setQueries(new Map(queries))
  }

  useEffect(() => {
    const loading = [...queries].reduce(
      (acc, [_, query]) => acc || query.loading,
      false
    )
    const error = [...queries].reduce(
      (acc, [_, query]) => acc || query.error,
      null
    )

    const data = [...queries].reduce((acc, [_, query]) => {
      // Only using data when all queries have loaded and no queries have
      // resulted in an error.
      if (!loading && !error) {
        if (query.data) {
          if (acc) {
            return {
              ...acc,
              ...query.data
            }
          }
          return query.data
        }
      }
      return acc
    }, null)

    setQuery({
      data,
      loading,
      error
    })
  }, [queries])

  // Since hooks can't be called in a loop, looping over components that use the hook(s) instead.
  return (
    <>
      {props.section.queries.map((queryString, i) => (
        <SectionQuery
          // NOTE: the query order will never change.
          key={i}
          queryString={queryString}
          searchTerm={props.searchTerm}
          onUpdateQuery={query => handleUpdateQuery(queryString, query)}
        />
      ))}
      <Component {...props} sectionQuery={query} />
    </>
  )
}

export default withSectionQueries
