import React from "react"
import styled from "styled-components"
import BlankSlate from "../blank/BlankSlate"
import Fullscreen, { FULLSCREEN_LAYER_OVERLAY } from "../fullscreen/Fullscreen"
import View from "../ui/View"

const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const Container = styled(View.Primary)`
  position: relative;
  min-width: 300px;
  max-width: 500px;
  text-align: center;
  h4 {
    margin-bottom: 5px;
  }
`

const FullscreenBlankSlate = ({ onClickBackground, ...blankSlateProps }) => (
  <Fullscreen
    layer={FULLSCREEN_LAYER_OVERLAY}
    background={{
      overlay: "rgba(0, 0, 0, 0.6)"
    }}
    onClickBackground={onClickBackground}
  >
    <StyledWrapper>
      <Container rounded border>
        <BlankSlate {...blankSlateProps} />
      </Container>
    </StyledWrapper>
  </Fullscreen>
)

FullscreenBlankSlate.displayName = "FullscreenBlankSlate"

export default FullscreenBlankSlate
