import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import {
  FULLSCREEN_LAYER_BASE,
  FULLSCREEN_LAYER_OVERLAY
} from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import CustomEvent from "custom-event"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { i18nTogglerActiveKey, i18nVisibleKey } from "./I18nextProvider"

const StyledToggle = styled.div`
  z-index: ${FULLSCREEN_LAYER_BASE + FULLSCREEN_LAYER_OVERLAY + 10};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: blue;
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);

  .ToggleInput {
    height: 100%;
  }

  .ToggleInput,
  .ToggleInput > div:first-of-type {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .ToggleInput > div:first-of-type label {
    margin-bottom: 5px;
    padding-right: 0;
  }

  .ToggleInput > div:last-of-type {
    display: none;
  }

  .ToggleInput label {
    width: auto !important;
    color: white;
    font-size: 0.7em;
  }
`

const Togglei18nKeys = () => {
  const [on, setOn] = useState(
    () => eval(JSON.parse(window.localStorage.getItem(i18nVisibleKey))) || false
  )
  const [active, setActive] = useState(
    !!window.sessionStorage.getItem(i18nTogglerActiveKey)
  )

  useEffect(() => {
    const handleEvent = e => setActive(e.detail.active)
    window.addEventListener(i18nTogglerActiveKey, handleEvent)
    return () => {
      window.removeEventListener(i18nTogglerActiveKey, handleEvent)
    }
  }, [])

  const handleToggle = val => {
    setOn(val)
    window.localStorage.setItem(i18nVisibleKey, val)
    window.dispatchEvent(
      new CustomEvent(i18nVisibleKey, {
        detail: {
          on: val
        }
      })
    )
  }

  if (!active) return null

  return (
    <StyledToggle>
      <ToggleInput label="i18n" value={!!on} onChange={handleToggle} />
    </StyledToggle>
  )
}

Togglei18nKeys.displayName = "Togglei18nKeys"

export default Togglei18nKeys
