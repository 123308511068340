import mergeWith from "lodash/mergeWith"
import { useCallback, useEffect, useMemo } from "react"
import { useLocalStorage } from "react-use"

/**
 * The goal of `getStoredLastViewed` is to manage a dictionary of active/non-active
 * nested keys, called "entries". An entry is analagous to a URL route. The complete
 * entries dictionary is analagous to a user's navigation history. For example, user
 * visits "a/a1". This is the path to their "active" entry. Next, user visits "b/b1".
 * The entry at "a" must now be inactive, but we remember the user was last active at
 * "a/a1" by leaving "a/*" history intact, and, at the same time, we record the user
 * active at "b/b1". If user returns to "a", we can redirect them to "a/a1", knowing
 * that "a/a1" was their last visit in "a/a1".
 */

// Borrowing from https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
// Get all possible key.nested.key combinations. Excluding 'data' and 'entries' keys.
export type NestedPathOf<ObjectType extends object> = {
  [Key in Exclude<keyof ObjectType & (string | number), 'data'>]: ObjectType[Key] extends object
   ? Key extends 'entries'
     ? `${NestedPathOf<ObjectType[Key]>}`
     : `${Key}` | `${Key}.${NestedPathOf<ObjectType[Key]>}`
   : `${Key}`
}[Exclude<keyof ObjectType & (string | number), 'data'>]

// Borrowing from https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
// Get all possible nested keys. Excluding 'data' and 'entries' keys.
export type NestedKeyOf<ObjectType extends object> = {
  [Key in Exclude<keyof ObjectType & (string | number), 'data'>]: ObjectType[Key] extends object
   ? Key extends 'entries'
     ? `${NestedKeyOf<ObjectType[Key]>}`
     : `${Key}` | `${NestedKeyOf<ObjectType[Key]>}`
   : `${Key}`
}[Exclude<keyof ObjectType & (string | number), 'data'>]

export type EntriesConfigType = {
  data?: boolean // hmm, in some cases this is required
  entries?: EntriesType
}

export type EntriesType = {
  [key: string]: EntriesConfigType
}

export type KeyedEntriesType = EntriesConfigType & {
  key: string
}

export type EntryKeysType = string

export type GetterInputType = {
  key: string // denote name of key in localStorage
  entries: EntriesType
}

export type HookInputType<ObjectType extends object> = {
  path?: NestedPathOf<ObjectType> | NestedPathOf<ObjectType>[]
  permissions: NestedPathOf<ObjectType>[]
}

export type HookReturnType<ObjectType extends object> = [
  EntriesType,
  (path: NestedPathOf<ObjectType> | NestedPathOf<ObjectType>[]) => void
]

export type GetEntriesOptionsType = {
  dataRequired?: boolean
  deep?: boolean
}

const defaultGeEntriesOptions: GetEntriesOptionsType = {
  dataRequired: false,
  deep: false
}

// Get's a flat list of entries. Provide null path in order to get list
// of active entries, otherwise, attempt to get list based on path.
// Optionally require `data` to be true.
export function getEntries<Entries extends object>(
  path: NestedPathOf<Entries> | null,
  entries: EntriesType,
  options: GetEntriesOptionsType = defaultGeEntriesOptions,
  entriesList: KeyedEntriesType[] = []
): KeyedEntriesType[] {
  entriesList = Object.entries(entries).reduce<KeyedEntriesType[]>(
    (acc, [key, entry], _, list) => {
      const nextKeyIndex = entriesList.length
      const entryKey = path ? path.split(".")[nextKeyIndex] : null

      // If entry already found, simply return current list of flat entries.
      if (path && acc.length === path.split(".").length && !options.deep)
        return acc

      // Include entry if entry.data is true and the entryKey doesn't match any in the list
      // or if entry.key equals the entryKey. That avoids including multiple entries for a given tier.
      if (
        (entry.data || !options.dataRequired) &&
        ((entry.data && !list.find(([key]) => key === entryKey)) ||
          key === entryKey)
      ) {
        const nextAcc = [
          ...acc,
          {
            key,
            ...entry
          }
        ]

        if (entry.entries)
          return getEntries<typeof entry.entries>(
            path,
            entry.entries,
            options,
            nextAcc
          )
        return nextAcc
      }

      return acc
    },
    entriesList
  )

  return entriesList
}

// Extend provided `entries` with a `data` key whose value is `false` for all keys
// that are not found in the provided `path`(s).
export function getNextEntries<Entries extends object>(
  path:
    | NestedPathOf<Entries>
    | NestedKeyOf<Entries>
    | (NestedKeyOf<Entries> | NestedPathOf<Entries>)[],
  entries: EntriesType
): EntriesType {
  function inner<Entries extends object>(
    path: NestedPathOf<Entries> | NestedKeyOf<Entries>,
    entries: EntriesType,
    nextEntries: EntriesType = {}
  ): EntriesType {
    nextEntries = Object.entries(entries).reduce(
      (acc, [key, entry], _, list) => {
        // Find if an entry key in the provided `path` matches provieded `key`.
        function getEntryKey(key: string) {
          return path.split(".").find((entryKey: string) => key === entryKey)
        }

        const entryKey = getEntryKey(key)

        // Always enable the current entry if it matches the desired key.
        if (entryKey) {
          entry.data = true
        } else {
          // Check if an "active" sibling exists in the current list.
          // If one exists, we must reset current entry's `data` to false.
          // Otherwise, we want to preserve the entry's `data` value if possible.
          const activeSibling = list.find(
            ([_key]) => _key !== key && getEntryKey(_key)
          )
          // The entry's `data` will either be true or false.
          entry.data = activeSibling ? false : entry.data || false
        }
        // Recursively get the next child `entries`.
        if (entry.entries) {
          entry.entries = getNextEntries<typeof entry.entries>(
            path,
            entry.entries
          )
        }

        return { ...acc, [key]: entry }
      },
      nextEntries
    )

    return nextEntries
  }

  // If the path is an array, apply to entries separately, than merge the
  // `data` keys, so that a `true` value takes precedence.
  if (Array.isArray(path)) {
    // Call `getNextEntries` for each EntryKeysNestedType in EntryKeysNestedType[].
    const nextEntries = (path as string[]).reduce<EntriesType[]>(
      (acc, path) => [...acc, getNextEntries<typeof entries>(path, entries)],
      []
    )
    // Now, merge the entries.
    const mergedEntries = (<any>mergeWith)(
      ...nextEntries,
      (objValue: any, srcValue: any, key: string) => {
        if (key === "data") return objValue || srcValue
      }
    )
    return mergedEntries
  }

  // Poor man's deep clone.
  // NOTE: copying the object probably results in a breakdown of the benefit gained by
  // the generic Entries type, where we get typying support for the `entries` keys.
  // This isn't a big deal though since it merely affects the inner/recursive functions
  // which cannot except user input directly anyhow.
  const copiedEntries = JSON.parse(JSON.stringify(entries))

  // Finally, call the `inner` function.
  return inner<typeof entries>(path as string, copiedEntries)
}

function validateEntries<Entries extends object>(
  path: NestedPathOf<Entries>,
  entries: EntriesType,
  permissions: EntriesType
) {
  const keyCount = path.split(".").length

  // Satisfy TypeScript by allowing the possibility of null?
  const validPath = path || null

  const invalid = getEntries<Entries>(validPath, entries).length !== keyCount
  const unprivileged =
    getEntries<Entries>(validPath, permissions, { dataRequired: true })
      .length !== keyCount

  if (!invalid && !unprivileged) return

  let errorMessage = `Could not store the following path: ${path}.`

  if (invalid) {
    errorMessage += `\n  - The following provided path was invalid: ${path}.`
  } else {
    errorMessage += `\n  - The user does not have permission for the following path: ${path}.`
  }

  throw new Error(errorMessage)
}

// Validate the stored entries against permissions.
function validateStoredEntries<Entries extends object>(
  entries: EntriesType,
  storedEntries: EntriesType | undefined,
  permissionsEntries: EntriesType
) {
  let validStoredEntries

  // Ensure stored entries are valid.
  try {
    if (storedEntries) {
      const focusedEntries = getEntries<Entries>(null, storedEntries)
      const entryKeys = focusedEntries.map(entry => entry.key)
      const path = entryKeys.join(".") as NestedPathOf<Entries>
      validateEntries<Entries>(path, entries, permissionsEntries)
      validStoredEntries = storedEntries
    }
  } catch (error) {
    /* noop */
  }

  // Default the entries if they aren't stored.
  if (!validStoredEntries) validStoredEntries = entries

  return validStoredEntries
}

// Light getter of the stored entries, for use outside of React Component scope.
export const getStoredActiveEntries = <Entries extends object>({
  key,
  entries
}: GetterInputType) => (
  permissions: NestedPathOf<Entries>[]
): EntriesType => {
  const permissionsEntries = getNextEntries<typeof entries>(
    permissions,
    entries
  )
  const storedEntriesJSON = localStorage.getItem(`${key}ActiveEntries`)

  let validStoredEntries

  // Ensure stored entries are valid.
  try {
    if (storedEntriesJSON) {
      const storedEntries = JSON.parse(storedEntriesJSON)
      validStoredEntries = validateStoredEntries(
        entries,
        storedEntries,
        permissionsEntries
      )
    }
  } catch (error) {
    /* noop */
  }

  // Default the entries if they aren't stored.
  if (!validStoredEntries) validStoredEntries = entries

  return validStoredEntries
}

// Returns a hook that can be used for getting and setting the user's current
// active entries config for the provided `entries`. The `entries` are stored and accessed
// from local storage, but are validated against the provided `permissions`, and, if invalid,
// are cleared.
export const getStoredActiveEntriesHook = <Entries extends object>({
  key,
  entries
}: GetterInputType) => ({
  path,
  permissions
}: HookInputType<Entries>): HookReturnType<Entries> => {
  const permissionsEntries = useMemo(
    () => getNextEntries<typeof entries>(permissions, entries),
    [permissions]
  )

  const [storedEntries, setStoredEntries, clearStoredEntries] = useLocalStorage(
    `${key}ActiveEntries`,
    entries
  )

  const onChangeEntry = useCallback(
    (path: NestedPathOf<Entries> | NestedPathOf<Entries>[]) => {
      try {
        // Preferably concatentate path and just do the loop, but my TypeScript knowledge
        // is lacking...
        if (Array.isArray(path)) {
          for (let i = 0; i < path.length; i++) {
            validateEntries<Entries>(path[i], entries, permissionsEntries)
          }
        } else {
          validateEntries<Entries>(path, entries, permissionsEntries)
        }
        setStoredEntries(
          getNextEntries<typeof entries>(path, storedEntries || entries)
        )
      } catch (error) {
        if (error instanceof Error) console.log(error.message)
      }
    },
    [storedEntries, permissionsEntries]
  )

  useEffect(() => {
    // Must provide list of entries in order to update. That way you can use
    // getStoredLastViewed for querying current state.
    if (path) onChangeEntry(path)
  }, [path])

  // Ensure stored entries are valid.
  let validStoredEntries = useMemo(
    () => validateStoredEntries(entries, storedEntries, permissionsEntries),
    [permissions, storedEntries]
  )

  // Clear the stored entries if they are invalid.
  useEffect(() => {
    if (storedEntries && validStoredEntries !== storedEntries)
      clearStoredEntries()
  }, [validStoredEntries])

  return [validStoredEntries, onChangeEntry]
}