import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Alert from "./Alert"

// FormAlert is concerned with removing an Alert after the
// form values change. The user is not interested in seeing the
// Alert any longer and as a convenience, we can hide it.
const FormAlert = ({ values, ...alertProps }) => {
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    setShowAlert(Boolean(alertProps.success || alertProps.error))
  }, [alertProps.success, alertProps.error])

  useEffect(() => {
    if (showAlert) setShowAlert(false)
  }, [values])

  return showAlert ? <Alert {...alertProps} /> : null
}

FormAlert.displayName = "FormAlert"

FormAlert.propTypes = {
  ...Alert.propTypes,
  values: PropTypes.object.isRequired
}

export default FormAlert
