import { useEffect, useRef, useState } from "react"
import { useReviewContext } from "./ReviewContextProvider"
import { getLeafNodes } from "./tree/path-tree"
import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"

const getOrderedItemStack = nodes => {
  return nodes.map((node, index) => {
    node.index = index
    return node
  })
}

const useReviewStack = () => {
  // this uses the review context selected and selected parent to build a stack of completions
  // that can be reviewed and navigated through
  const {
    selected,
    selectedParent,
    context,
    itemsFilter,
    actions: { select, getItem }
  } = useReviewContext()

  // this is weird... selectedParent is stale here so I have to get it directly from the store?
  // it seems the staleness happens when a server side context filter occurs
  const parent = selectedParent ? getItem(selectedParent.id) : null

  // the stack of completions that are selected
  const [items, setItems] = useState(() =>
    parent ? getOrderedItemStack(getLeafNodes(parent)) : []
  )
  // Those items which can be navigated, which includes all items, just reorganized
  // so that the all "newer" items appearing at earlier leaf node indexes will
  // be moved to after the currently selected item. This makes pagination a little less awkward.
  const [navigableItems, setNavigableItems] = useState(items)

  // If after paginating, we are awaiting a `next` selection, then
  // pendingNextRef.current will be true.
  const pendingNextRef = useRef(false)

  // Handle setting the navigableItems. Comparing the previous items with the current
  // to determine which new items to the list should get moved to appear after the
  // currently selected item.
  usePreviousEffect(
    ([prevItems]) => {
      const hasNew = items.find(
        item => !prevItems.find(prevItem => prevItem.id === item.id)
      )
      const selectedIndex = selected
        ? prevItems.findIndex(item => item.id === selected.id)
        : -1
      if (hasNew && selectedIndex !== -1) {
        const startingItems = prevItems.filter((item, i) => i <= selectedIndex)
        const endingItems = items.filter(
          item =>
            !startingItems.find(startingItem => startingItem.id === item.id)
        )
        const nextItems = [...startingItems, ...endingItems]
        setNavigableItems(nextItems)
      } else if (items.length !== navigableItems.length) {
        setNavigableItems(items)
      }
    },
    [items]
  )

  useEffect(() => {
    if (parent) {
      const nextStack = getOrderedItemStack(getLeafNodes(parent))
      setItems(nextStack)
    }
  }, [parent])

  // a horrible, no good, terrible hack to set the the index of the selected item in the stack
  const selectedIndex = selected
    ? navigableItems.findIndex(item => item.id === selected.id)
    : -1
  if (selected) selected.index = selectedIndex

  const isSingleItemMode = !!context.query && !!context.query.step_id
  const hasNext = selected && selectedIndex < context.total - 1
  const hasPrevious = selected && selectedIndex > 0

  const previous = hasPrevious
    ? () => select(navigableItems[selectedIndex - 1])
    : undefined

  const next = hasNext
    ? () => {
        const { hasMore, loadingMore, loadMore } = context
        const next = navigableItems[selectedIndex + 1]

        if (!next && hasMore && !loadingMore) {
          pendingNextRef.current = true
          loadMore()
        }

        if (next) {
          pendingNextRef.current = false
          select(next)
        }
      }
    : undefined

  // Select next pending item.
  useEffect(() => {
    if (pendingNextRef.current) {
      next()
    }
  }, [navigableItems.length])

  // if we have a filter, we need to apply it here...
  useEffect(() => {
    if (itemsFilter && parent) {
      const nextStack = getOrderedItemStack(
        getLeafNodes(parent).filter(itemsFilter.filter)
      )
      setItems(nextStack)
    }
  }, [itemsFilter, parent])

  return {
    items: navigableItems,
    selected,
    hasNext,
    hasPrevious,
    isSingleItemMode,
    actions: {
      previous,
      next
    }
  }
}

export default useReviewStack
