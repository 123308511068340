import Button from "@pathwright/ui/src/components/button/Button"
import { FullscreenError } from "@pathwright/ui/src/components/error/Error"
import jwtDecode from "jwt-decode"
import PropTypes from "prop-types"
import { useRef } from "react"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const getErrorDetails = (context) => {
  switch (context.error_code) {
    case "missing_intent":
      return {
        errorTitle: "Missing intent",
        errorMessage: `The Global SSO URL lacks an ?auth_intent query param. Requiring one of "sign_in:without_password", "sign_in:password", "sign_up", or "sign_in_or_sign_up".`
      }
    case "missing_attributes":
      return {
        errorTitle: "Missing attributes",
        errorMessage: `The response from the identity provider did not include the following attributes: ${context.attrs
          .map((attr) => `"${attr}"`)
          .join(", ")}`
      }
    case "email_update_duplicate":
      return {
        errorTitle: "Email update error",
        errorMessage: `An error occurred while attempting to update the user's email address to match the IdP. The user is 
        currently using ${context.pw_email} on Pathwright, and the IdP reported the new email as ${context.idp_email}. During 
        the update, another user on Pathwright was already using ${context.idp_email}, so the email could not be updated.`
      }
    case "saml2_invalid_response":
      return {
        errorTitle: "Invalid SAML2 response",
        errorMessage: `The response from the identity provider was invalid. Reason: ${context.reason}`
      }
    case "saml2_missing_request_id":
      return {
        errorTitle: "Missing request ID",
        userFriendlyMessage: (
          <>
            Please ensure{" "}
            <Button
              styleType="text"
              href="https://www.google.com/search?q=how+to+enable+cookies+in+my+browser"
              target="_blank"
            >
              cookies are enabled in your browser
            </Button>
            .
          </>
        ),
        errorMessage:
          "An auth request ID could not be found on the session. Are cookies disabled?"
      }
    case "oidc_missing_nonce":
      return {
        errorTitle: "Missing nonce",
        userFriendlyMessage: (
          <>
            Please ensure{" "}
            <Button
              styleType="text"
              href="https://www.google.com/search?q=how+to+enable+cookies+in+my+browser"
              target="_blank"
            >
              cookies are enabled in your browser
            </Button>
            .
          </>
        ),
        errorMessage:
          "A nonce could not be found on the session. Are cookies disabled?"
      }
    case "oidc_state_mismatch":
      return {
        errorTitle: "State mismatch",
        userFriendlyMessage:
          "Make sure that you're only clicking the login button once.",
        errorMessage:
          "The state on the session did not match the expected state. This may have been caused by clicking the SSO login button multiple times in quick succession."
      }
    case "oidc_token_endpoint_error":
      return {
        errorTitle: "Token endpoint error",
        errorMessage: `Encountered HTTP error ${context.code} when requesting a token from the identity provider.`
      }
    case "oidc_userinfo_endpoint_error":
      return {
        errorTitle: "Userinfo endpoint error",
        errorMessage: `Encountered HTTP error ${context.code} when requesting user information from the identity provider.`
      }
    case "oidc_jwk_endpoint_error":
      return {
        errorTitle: "JWK endpoint error",
        errorMessage: `Encountered HTTP error ${context.code} when requesting a key from the identity provider.`
      }
    case "oidc_nonce_validation_error":
      return {
        errorTitle: "Token nonce validation error",
        errorMessage: `Mismatch between Pathwright session and IdP token nonce. (expected: ${context.expected}; actual: ${context.actual})`
      }
    case "disallowed":
      return {
        errorTitle: "Disallowed",
        errorMessage: `Reason: ${context.reason}`
      }
    default:
      return {
        errorTitle: "Unknown",
        errorMessage: ""
      }
  }
}

const SSOError = ({ token }) => {
  const { school } = usePathwrightContext()

  const context = useRef(token ? jwtDecode(token) : {}).current

  const { idp_protocol: idpProtocol, idp_id: idpId } = context

  const { errorTitle, userFriendlyMessage, errorMessage } =
    getErrorDetails(context)
  const fullErrorTitle = `SSO Error: ${errorTitle}`

  return (
    <FullscreenError
      email={school.email}
      errorTitle={`Something went wrong signing in with your ${school.name} account.`}
      errorMessage={
        <>
          {userFriendlyMessage}
          {!!userFriendlyMessage && (
            <>
              <br />
              <br />
            </>
          )}
          If you continue to experience issues signing in, please contact{" "}
          {school.name} support and share the technical error message below to
          help them resolve the issue:
        </>
      }
      errorDetails={
        <>
          {fullErrorTitle}
          <br />
          <br />
          {errorMessage}
          <br />
          <br />
          ---
          <br />
          <br />
          Login attempted at: {new Date().toUTCString()}
          <br />
          Integration type: {idpProtocol}
          <br />
          IdP ID: {idpId}
        </>
      }
      primaryAction={{
        children: `Email ${school.name} Support`
      }}
      secondaryAction={{
        children: "Or try signing-in again"
      }}
    />
  )
}

SSOError.displayName = "SSOError"

SSOError.propTypes = {
  token: PropTypes.string // jwt
}

export default SSOError
