import classnames from "classnames"
import delay from "lodash/delay"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import React, { PureComponent } from "react"
import OutsideClickWrapper from "./OutsideClickWrapper"
import Overlay from "./Overlay"

// Component concerns:
// - Hide/show event handling
// - Hide/show state of Overlay
// - Passing props to Overlay

const eventIsTrigger = (name, trigger) => {
  if (Array.isArray(trigger)) {
    return trigger.indexOf(name) > -1
  }
  return trigger === name
}

export const OverlayTriggerContext = React.createContext()
export const useOverlayTriggerContext = () =>
  React.useContext(OverlayTriggerContext)

class OverlayTrigger extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      show: props.show
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.handleReset(nextProps.show)
    }
    // Allow becoming inactive to change show state.
    if (!nextProps.active && nextProps.active !== this.props.active) {
      this.handleReset(false)
    }
  }

  componentWillUnmount() {
    this.delayedHide && clearTimeout(this.delayedHide)
    this.delayedShow && clearTimeout(this.delayedShow)
  }

  handleReset = show => {
    this.setState({ show })
  }

  handleToggle = e => {
    e && e.stopPropagation()
    if (this.state.show) {
      this.handleHide(e)
    } else {
      this.handleShow(e)
    }
    isFunction(this.props.onClick) && this.props.onClick(e)
  }

  handleHide = e => {
    if (this.props.preventHide) return

    if (this.state.show) {
      this.setState({ show: false })
    }
    isFunction(this.props.onHide) && this.props.onHide(e)
  }

  handleShow = e => {
    this.setState({ show: true })
    isFunction(this.props.onShow) && this.props.onShow(e)
  }

  handleHoverShow = e => {
    // Cancel delayed hide
    if (this.delayedHide != null) {
      clearTimeout(this.delayedHide)
      this.delayedHide = null
    }
    // Avoid unnecessary calls
    if (this.state.show || this.delayedShow != null) {
      return
    }
    // Start delayed show
    this.delayedShow = delay(() => {
      this.delayedShow = null
      this.handleShow(e)
    }, this.props.delayShow)
  }

  handleHoverHide = e => {
    // Cancel delayed show
    if (this.delayedShow != null) {
      clearTimeout(this.delayedShow)
      this.delayedShow = null
    }
    // Avoid unnecessary calls
    if (!this.state.show || this.delayedHide != null) {
      return
    }
    // Start delayed hide
    this.delayedHide = delay(() => {
      this.delayedHide = null
      this.handleHide(e)
    }, this.props.delayHide)
  }

  getEventProps = () => {
    // Captures bubble up events
    let eventProps = {}
    // Default open/close event
    if (eventIsTrigger("click", this.props.trigger)) {
      eventProps.onClick = this.handleToggle
    }
    // Note: needs another trigger for keyboard/touch users
    if (eventIsTrigger("hover", this.props.trigger)) {
      eventProps.onMouseEnter = this.handleHoverShow
      eventProps.onMouseLeave = this.handleHoverHide
    }
    // Useful in combination with hover for keyboard/touch users
    if (eventIsTrigger("focus", this.props.trigger)) {
      eventProps.onFocus = this.handleShow
      eventProps.onBlur = this.handleHide
    }
    // Useful with hover-only (i.e. tooltip) overlays
    if (this.props.closeOnTriggerClick) {
      if (eventIsTrigger("hover", this.props.trigger)) {
        eventProps.onMouseDown = this.handleHide
      } else {
        eventProps.onMouseUp = this.handleHide
      }
    }
    return eventProps
  }

  handleOverlayClick = e => {
    if (!this.props.closeOnOverlayClick && e) {
      e.stopPropagation()
      e.nativeEvent && e.nativeEvent.stopImmediatePropagation()
    }
  }

  render() {
    const className = classnames(
      "OverlayTrigger",
      {
        "OverlayTrigger--show": this.state.show,
        "OverlayTrigger--disabled": this.props.disabled
      },
      this.props.className
    )
    const eventProps = this.getEventProps()
    const shouldListen =
      eventIsTrigger("click", this.props.trigger) ||
      eventIsTrigger("focus", this.props.trigger)
    return (
      <OverlayTriggerContext.Provider
        value={{
          // Allow children to trigger hideOverlay
          hideOverlay: this.handleHide
        }}
      >
        <OutsideClickWrapper
          shouldListen={shouldListen && this.state.show}
          onOutsideClick={this.handleHide}
        >
          <div
            {...eventProps}
            className={className}
            style={{
              position: "relative",
              display: "inline-block",
              ...this.props.style
            }}
          >
            {this.props.children}
            <Overlay
              show={this.props.active && this.state.show}
              onClick={this.handleOverlayClick}
              enterTransition={this.props.enterTransition}
              leaveTransition={this.props.leaveTransition}
              enterDuration={this.props.enterDuration}
              leaveDuration={this.props.leaveDuration}
              placement={this.props.placement}
              alignment={this.props.alignment}
            >
              {isFunction(this.props.overlay)
                ? this.props.overlay({
                    handleHide: this.handleHide,
                    handleShow: this.handleShow
                  })
                : this.props.overlay}
            </Overlay>
          </div>
        </OutsideClickWrapper>
      </OverlayTriggerContext.Provider>
    )
  }
}

OverlayTrigger.displayName = "OverlayTrigger"

OverlayTrigger.propTypes = {
  // Make OverlayTrigger a controlled component
  show: PropTypes.bool,
  // Event(s) to cause overlay to show
  trigger: PropTypes.oneOfType([
    PropTypes.oneOf(["click", "hover", ""]),
    PropTypes.arrayOf(PropTypes.oneOf(["click", "hover", "focus"]))
  ]), //.isRequired,
  // Content to inject in overlay
  overlay: PropTypes.oneOfType([PropTypes.node, PropTypes.func]), //.isRequired,
  // Should click on trigger close overlay
  closeOnTriggerClick: PropTypes.bool,
  // Allow content click to toggle overlay
  closeOnOverlayClick: PropTypes.bool,
  // Delay overlay hide or show
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  // Override to prevent overlay from showing
  active: PropTypes.bool,
  // Prevent the overlay from hiding
  preventHide: PropTypes.bool
}
// ...Overlay.propTypes

OverlayTrigger.defaultProps = {
  show: false,
  preventHide: false,
  delayHide: 100,
  delayShow: 0,
  closeOnOverlayClick: false,
  active: true
}

export default OverlayTrigger
