import gql from "graphql-tag"

export default gql`
  mutation($id: Int!, $has_access: Boolean!) {
    setMemberAccess(id: $id, has_access: $has_access) {
      id
      access_revoked_time
      has_access
    }
  }
`
