import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Text from "@pathwright/ui/src/components/ui/Text"
import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import { withTranslate } from "@pathwright/ui/src/components/"
import { AuthFooter } from "./styles"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  text-align: left;
`

const StyledUser = styled.div`
  padding: 10px;
`

const SignedIn = props => {
  const { me, onAuthChange } = usePathwrightContext()
  const { t, continueLabel } = props
  const signOut = () => onAuthChange(null)

  if (!me) {
    return null
  }

  return (
    <StyledContainer>
      {props.renderHeader({ header: null })}
      <StyledWrapper>
        <Avatar user={me} />
        <StyledUser>
          <Text.H3 inverted={props.inverted}>{me.full_name}</Text.H3>
          {!props.hideSignOut && (
            <Button
              styleType="text"
              onClick={signOut}
              inverted={props.inverted}
            >
              {t("auth.sign_out")}
            </Button>
          )}
        </StyledUser>
      </StyledWrapper>
      <AuthFooter>
        {props.renderSubmit({
          submit: props.onContinue ? (
            <Button
              styleType="primary"
              inverted={props.inverted}
              onClick={props.onContinue}
            >
              {continueLabel || t("auth.signed_in_footer.continue")}{" "}
              <Pathicon icon="arrow-right" />
            </Button>
          ) : null,
          onSubmit: props.onContinue
        })}
      </AuthFooter>
    </StyledContainer>
  )
}

SignedIn.displayName = "SignedIn"

SignedIn.propTypes = {
  renderHeader: PropTypes.func,
  renderSubmit: PropTypes.func,
  onContinue: PropTypes.func,
  continueLabel: PropTypes.string,
  inverted: PropTypes.bool,
  hideSignOut: PropTypes.bool
}

SignedIn.defaultProps = {
  hideSignOut: false,
  renderHeader: ({ header }) => header,
  renderSubmit: ({ submit }) => submit
}

export default withTranslate(SignedIn)
