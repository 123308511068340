import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import styled from "styled-components"
import View from "../ui/View"

const StyledWrapperView = styled(View)`
  display: block;
  position: relative;
  width: 100%;
`

const StyledView = styled(View)`
  &:empty {
    display: none;
  }

  border-top-color: transparent !important;
  ${StyledWrapperView} + ${StyledWrapperView} > &.with-top-border {
    border-top-color: inherit !important;
  }
  ${StyledWrapperView} + ${StyledWrapperView} > &.without-top-border {
    padding-top: 0px !important;
  }

  /* while the solution above works, the solution below ends up applying _both_ styles, which is logically impossible */
  /*
  ${StyledWrapperView} + ${StyledWrapperView} > & {
    ${p =>
      p.borderTop
        ? "border-top-color: inherit !important;"
        : "padding-top: 0px !important;"}
  }
  */
`

const CardBlock = ({
  children,
  borderTop,
  borderBottom,

  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  ...rest
}) => {
  return (
    <StyledWrapperView
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      {...rest}
    >
      <StyledView
        className={classnames({
          "with-top-border": borderTop !== false,
          "without-top-border": borderTop === false
        })}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        borderTop={borderTop !== false}
        borderBottom={borderBottom}
      >
        {children}
      </StyledView>
    </StyledWrapperView>
  )
}

CardBlock.displayName = "CardBlock"

CardBlock.propTypes = {
  // also show border on top
  borderTop: PropTypes.bool,
  // override default border bottom behavior
  borderBottom: PropTypes.bool
}

CardBlock.defaultProps = {
  borderTop: null, // expecting explicit override; null allows for more flexible logic
  borderBottom: false,
  paddingLeft: true,
  paddingRight: true,
  paddingTop: true,
  paddingBottom: true
}

export default CardBlock
