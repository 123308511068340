import gql from "graphql-tag"

export default gql`
  mutation(
    $context: InvitationContext
    $type: InvitationType
    $invite_emails: [String]!
    $invite_to_community_group_role: MentorGroupMembershipRole!
  ) {
    createInvitations(
      context: $context
      type: $type
      invite_emails: $invite_emails
      invite_to_community_group_role: $invite_to_community_group_role
    ) {
      invitations_attempted_count
      invitations_sent_count
      unsuccessful_invitations
    }
  }
`
