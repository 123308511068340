import isEqual from "react-fast-compare"

let FilterMenu
require("./templates/styles/filter-menu.css")

class FilterMenuItem extends require("lib/static-shim").default(
  require("lib/core/item-view").default
) {
  static initClass() {
    this.prototype.template = require("./templates/filter-menu-item.html")
    this.prototype.tagName = "li"

    this.prototype.triggers = { "click a": "select" }
  }

  onRender() {
    if (this.model.get("default")) {
      return this.triggerMethod("select")
    }
  }

  onSelect() {
    this.$el.addClass("selected")
    return this.$el.siblings().removeClass("selected")
  }
}
FilterMenuItem.initClass()

export default FilterMenu = (function() {
  FilterMenu = class FilterMenu extends require("lib/static-shim").default(
    require("lib/core/composite-view").default
  ) {
    static initClass() {
      this.prototype.template = require("./templates/filter-menu.html")
      this.prototype.className = "filter-menu-view"
      this.prototype.childViewContainer = ".filter-menu-items"
      this.prototype.childView = FilterMenuItem

      this.prototype.childEvents = {
        select(item) {
          return this.onItemSelected(item.model)
        }
      }
    }

    initialize() {
      if (
        !this.collection &&
        (this.model != null ? this.model.get("options") : undefined)
      ) {
        this.collection = new Backbone.Collection(this.model.get("options"))
      }
      return (this.filteredCollection = this.options.filteredCollection)
    }

    onRender() {
      return (this.binding = rivets.bind(this.el, { model: this.model }))
    }

    onDestroy() {
      return this.binding.unbind()
    }

    selectByLabel(itemLabel) {
      return (() => {
        const result = []
        for (let item of Array.from(this.collection.models)) {
          let item1
          if (item.get("label").toLowerCase() === itemLabel.toLowerCase()) {
            item1 = this.onItemSelected(item)
          }
          result.push(item1)
        }
        return result
      })()
    }

    selectByFilters(filters) {
      return (() => {
        const result = []
        for (let item of Array.from(this.collection.models)) {
          let item1
          if (isEqual(item.get("filters"), filters)) {
            item1 = this.onItemSelected(item)
          }
          result.push(item1)
        }
        return result
      })()
    }

    onItemSelected(filter) {
      this.$el.removeClass("open")
      // `altLabel` being used at least for now if `label` contains
      this.model.set(
        "selected_item",
        filter.get("altLabel") || filter.get("label")
      )
      this.triggerMethod("filter:selected", filter)
      if (this.filteredCollection) {
        return this.filteredCollection.applyFilter(filter)
      }
    }
  }
  FilterMenu.initClass()
  return FilterMenu
})()
