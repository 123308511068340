import React from "react"
import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Pricing from "@pathwright/web/src/modules/pricing/PricingAdapter"
import { ConnectStores } from "lib/core/store"
import PricingCoreOverlay from "./overlay/PricingCoreOverlay"
import PricingBack from "./PricingBack"
import "./PricingContainer.css"

const PricingContainer = props => {
  const { current_step, selected_plan, match } = props

  if (!selected_plan) return null

  return (
    <div className="PricingContainer">
      {current_step === "plan" && (
        <div className="PricingLinks">
          <PricingBack />
          <Button
            styleType="tertiary"
            onClick={() => {
              const dest = document.querySelector("#pricing-faq")
              if (!dest) return

              const offset = 200
              window.scrollTo({
                top: dest.offsetTop - offset,
                behavior: "smooth"
              })
            }}
            className="PricingInfo"
          >
            <Pathicon icon="info-circle" /> Pricing FAQs
          </Button>
        </div>
      )}
      <Pricing
        stripeApiKey={
          window.App.getStore("pathwrightAccount").getState().payment_key
        }
        schoolId={App.getStore("school").getState().school.id}
      />
      {match.params.billingStep === "core" && <PricingCoreOverlay {...props} />}
    </div>
  )
}

PricingContainer.displayName = "PricingContainer"

const mapStateToProps = (state, ownProps) => {
  const { selected_plan, current_step } = state.billingStore.state
  return _.assign(
    {
      selected_plan,
      current_step
    },
    ownProps
  )
}

const stores = [
  {
    store: "navigation"
  },
  {
    store: "billing",
    watch: [
      "selected_plan",
      "learner_count",
      "billing_interval",
      "current_step"
    ]
  }
]

export default ConnectStores(PricingContainer, stores, {}, mapStateToProps)
