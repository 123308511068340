import { capitalCase } from "change-case"
import { getCompletionAction } from "../../../completion/utils"
import { getStepVerbIcon } from "../../../path/utils"

const getCompletionMeta = item => {
  const { completion, name } = item

  let title, subtitle

  const { user } = completion
  const action = getCompletionAction(completion)

  title = `${capitalCase(action)}: ${name}`
  subtitle = user ? `${user.full_name}` : null

  return { title, subtitle, user }
}

export const getCompletionNodeFromItem = item => {
  const {
    needs_grading,
    is_late,
    is_complete,
    completion_date,
    checked_by
  } = item.completion
  let tags = []
  if (needs_grading) tags.push("pending-review")
  if (is_late) tags.push("overdue")
  if (checked_by) tags.push("reviewed")
  if (is_complete) tags.push("completed")
  if (!is_complete) tags.push("incomplete")

  return {
    id: item.id,
    type: "completion",
    user: item.completion.user,
    created: item.completion.completion_data
      ? new Date(item.completion.completion_data)
      : null,
    meta: {
      ...getCompletionMeta(item),
      avatar: item.completion.user.profile.image,
      // icon: is_complete ? "check" : "box",
      when: completion_date ? new Date(completion_date) : null,
      tags
    },
    data: { ...item },
    children: new Map()
  }
}

export const getPathNodeFromItem = node => {
  const { resource, cohort } = node.data
  const path = {
    id: cohort.id,
    type: "path",
    meta: {
      title: resource.name,
      subtitle: cohort.name,
      image: resource.image
    },
    data: node.data,
    children: new Map()
  }
  return path
}

export const getLessonNodeFromItem = node => {
  const { parent } = node.data

  const lesson = {
    id: parent.source_id,
    type: "lesson",
    meta: {
      title: parent.name
    },
    data: {},
    children: new Map()
  }
  return lesson
}

export const getStepNodeFromItem = node => {
  const { source_id } = node.data
  const step = {
    id: source_id,
    type: "step",
    meta: {
      title: `${node.data.name}`,
      icon: getStepVerbIcon(node.data.verb)
    },
    data: { ...node.data },
    children: new Map()
  }
  return step
}

export const createPathCompletionsTree = (context, items, filter = null) => {
  let map = {}
  let nodes = items.map(getCompletionNodeFromItem)

  if (filter) {
    nodes = nodes.filter(filter)
  }

  const { meta, id, key } = context

  const tree = {
    id,
    key,
    meta,
    type: "root",
    children: new Map()
  }

  let pathNodes = new Map()
  let lessonNodes = new Map()
  let stepNodes = new Map()

  map[tree.id] = tree

  nodes.forEach(node => {
    const { cohort, parent, source_id } = node.data

    if (!pathNodes.has(cohort.id)) {
      const path = getPathNodeFromItem(node)
      path.parent = tree
      pathNodes.set(path.id, path)
      tree.children.set(path.id, path)
      map[path.id] = path
    }

    // lesson nodes
    if (parent && !lessonNodes.has(parent.source_id)) {
      const lesson = getLessonNodeFromItem(node)
      lessonNodes.set(lesson.id, lesson)

      // add to cohort node if it's not already there
      let path = pathNodes.get(cohort.id)
      if (!path.children.has(lesson.id)) {
        path.children.set(lesson.id, lesson)
        lesson.parent = path
      }
      // add to all nodes
      map[lesson.id] = lesson
    }

    // create step nodes
    if (!stepNodes.has(source_id)) {
      const step = getStepNodeFromItem(node)
      stepNodes.set(step.id, step)

      // add to lesson node if it's not already there
      if (
        parent &&
        !lessonNodes.get(parent.source_id).children.has(source_id)
      ) {
        lessonNodes
          .get(parent.source_id)
          .children.set(source_id, stepNodes.get(source_id))
        stepNodes.get(source_id).parent = lessonNodes.get(parent.source_id)
      }

      // add to all nodes
      map[step.id] = step
    }

    // add completion to step node if it's not already there
    if (!stepNodes.get(source_id).children.has(node.id)) {
      stepNodes.get(source_id).children.set(node.id, node)
      node.parent = stepNodes.get(source_id)
    }

    if (!map[node.id]) {
      map[node.id] = node
    }
  })

  return { map, tree }
}

export const flattenTreeToItem = (tree, itemId) => {
  let path = []
  let root = null
  let stack = [tree]
  while (stack.length > 0) {
    let node = stack.pop()
    if (`${node.id}` === `${itemId}`) {
      root = node
      break
    } else {
      path.push(node)
    }
    if (node.children.size > 0) {
      stack = stack.concat([...node.children.values()])
    }
  }
  return { root, path }
}

export const flattenTree = tree => {
  // convert the tree to a flat array of all nodes
  let nodes = []
  let stack = [tree]
  while (stack.length > 0) {
    let node = stack.pop()
    nodes.push(node)
    if (node.children.size > 0) {
      stack = stack.concat([...node.children.values()])
    }
  }
  return nodes
}

export const isLeaf = node => {
  return node.children && node.children.size === 0
}

export const getLeafNodes = node => {
  // rewrite this to depth first search
  if (isLeaf(node)) return [node]
  let leafNodes = []
  node.children.forEach(child => {
    leafNodes = leafNodes.concat(getLeafNodes(child))
  })
  return leafNodes
}
export const findFirstParentOfType = (node, type) => {
  if (node.type === type) return node
  if (node.parent) return findFirstParentOfType(node.parent, type)
  return null
}

export const logTree = tree => {
  // recursively log the tree meta.title
  const logNode = (node, depth) => {
    console.log(`${depth} ${node.meta.title}`)
    node.children.forEach(child => logNode(child, depth + "  "))
  }
  logNode(tree, "")
}

export const isDecendant = (node, ancestor) => {
  if (node.id === ancestor.id) return true
  if (node.parent) return isDecendant(node.parent, ancestor)
  return false
}

export const getPathParent = item => {
  if (item.parent && item.parent.type === "path") {
    return item.parent
  } else if (item.parent) {
    return getPathParent(item.parent)
  }
  return null
}
