require("./views/styles/discussion.css")
require("./views/styles/discussion-library-redesign.css")

import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import DiscussionUnlocksOverlay from "@pathwright/web/src/modules/discussion/DiscussionLockedOverlay"
import DiscussionItemContainer from "@pathwright/web/src/modules/discussion/list/item/DiscussionItemContainer"
import { withCohortSyncPlanContext } from "@pathwright/web/src/modules/path/sync/SyncPlanContext"
import {
  constructUrl,
  getCohortSectionUrlValues,
  getUrlValues,
  STEP_RE
} from "@pathwright/web/src/modules/utils/urls"
import { wrapMarionetteView } from "lib/core/react/react-utils"
import capitalize from "lodash/capitalize"
import get from "lodash/get"
import React from "react"
import styled from "styled-components"
import DiscussionPanelView from "./views/discussion-panel"

const StyledDiscussionItemContainer = styled(DiscussionItemContainer)`
  &.DiscussionItemContainer__edit,
  &.DiscussionItemContainer__create {
    /* Enforces a min margin of 10px on left and right sides */
    max-width: min(700px, 100%, calc(100% - 20px));
    margin: 20px auto;
    background-color: white;
    border-radius: 20px;
  }

  .DiscussionListItem {
    border-radius: 0;
  }
`

export default window.App.module("Discussion", function(
  DiscussionModule,
  App,
  Backbone,
  Marionette,
  $,
  _
) {
  this.startWithParent = false

  let discussionStore = window.App.stores.requestStore("discussion")

  window.App.execute(
    "register:behavior",
    "DiscussionVote",
    require("./views/behaviors/discussion-vote").default
  )

  window.App.reqres.setHandler("get:context:key", function(context) {
    // Returns a string version of the context for passing along to the server
    const contexts = []
    for (var key in context) {
      const val = context[key]
      if (val != null) {
        contexts.push(`${key}_${val}`)
      }
    }
    key = contexts.join(",")
    return key
  })

  window.App.reqres.setHandler("show:discussion:panel", function(
    discussionId,
    options
  ) {
    // Messy hacking, wish we had a panel_launched_from url or something
    const onExitDiscussion = () => {
      const navStore = window.App.getStore("navigation")
      let { url } = navStore.getState()
      // if /discussion/ is in the URL
      if (url.indexOf("/discussion/") > -1) {
        // grab the first part of the URL prior to /discussion/
        url = url.split("/discussion/")[0]
        if (url.indexOf("/path/") === -1) {
          // add back in discussion for non-path discussion routes
          url = constructUrl(url, "discussion")
        }
      }
      window.App.getStore("navigation").action.navigate(url)
    }

    const openPanelWithContext = function(discussion) {
      let header

      if (options.context_label) {
        // legacy?
        header = { title: options.context_label }

        // Looks like all this logic should just be in context_label
      } else if (
        __guard__(
          discussion != null ? discussion.get("target") : undefined,
          x => x.step_verb
        ) != null
      ) {
        // discussion was made on a step
        header = {
          title: `${discussion.get("target").step_verb}: ${
            discussion.get("target").name
          }`,
          url: discussion.get("target").url
        }
      } else if (
        __guard__(
          discussion != null ? discussion.get("target") : undefined,
          x1 => x1.name
        ) != null
      ) {
        // discussion wasn't made on a step
        header = { title: `${discussion.get("target").name}` }
      } else {
        // new discussion
        header = { title: "" }
      }

      // Get discussion context URL
      if (discussion != null) {
        discussionStore = window.App.getStore("discussion")
        header = {
          title:
            header.title ||
            discussionStore.request.getTitleForDiscussionContext(),
          url: discussionStore.request.getURLForDiscussionContext()
        }
      }

      // How? Possible that a mentor is clicking a discussion activity on a mentee's activity feed. Stop gap fix.
      if (discussion && !discussion.toJSON) {
        return alert("Cannot access discussion")
      }

      // Show the panel view
      options.model = discussion
      const WrappedDiscussionPanelView = wrapMarionetteView(DiscussionPanelView)

      // Get discussionContext for DiscussionItemContainer based on
      // discussion, options, or current route.
      const discussionContext = discussion
        ? {
            step_id: discussion.get("context").step_id,
            cohort_id: discussion.get("context").offering,
            resource_id: discussion.get("context").resource
          }
        : options.context
        ? {
            step_id: get(options, "context.step"),
            cohort_id: get(options, "context.class"),
            resource_id: get(options, "context.course")
          }
        : {
            step_id: getUrlValues(STEP_RE).stepSourceId,
            cohort_id: getCohortSectionUrlValues().cohortId,
            resource_id: getCohortSectionUrlValues().resourceId
          }

      const closePanel = () => {
        onExitDiscussion()
        window.App.getStore("layout").action.closeIn("panel")
      }

      const DiscussionPanelViewContainer = withCohortSyncPlanContext(props => (
        <ScrollView fill>
          <StyledDiscussionItemContainer
            discussionId={get(discussion, "id")}
            // HACK: defaulting isDiscussionQuestion to true if no discussion
            // since we only ever at this point allow creating a "discussino question"
            // from the discussion panel.
            isDiscussionQuestion={
              get(discussion, "is_discussion_question") || true
            }
            context={discussionContext}
            compact={false}
            mode={get(options, "view") === "update" ? "edit" : null}
            onCreateDiscussion={closePanel}
            onDeleteDiscussion={closePanel}
          />
          <WrappedDiscussionPanelView
            {...props}
            onCreateDiscussion={(...args) => {
              props.onCreateDiscussion && props.onCreateDiscussion(...args)
              // HACK: Apollo bug? This does refetch the sync plan queries but DOES NOT cause the SyncButton to update
              // unclear as to why, but the query data in the SyncButton is stale
              // Maybe related: https://github.com/apollographql/apollo-client/issues/3909
              // appears that below works because the useSyncPlanContext is higher up in the tree, above the SyncButton
              // props.refetchSyncPlanQueries && props.refetchSyncPlanQueries()
              window.App &&
                window.App.getStore("pathAdmin").storeTrigger(
                  "path:item:updated"
                )
            }}
            onDeleteDiscussion={(...args) => {
              props.onDeleteDiscussion && props.onDeleteDiscussion(...args)
              // HACK: Apollo bug? This does refetch the sync plan queries but DOES NOT cause the SyncButton to update
              // unclear as to why, but the query data in the SyncButton is stale
              // Maybe related: https://github.com/apollographql/apollo-client/issues/3909
              // appears that below works because the useSyncPlanContext is higher up in the tree, above the SyncButton
              // props.refetchSyncPlanQueries && props.refetchSyncPlanQueries()
              window.App &&
                window.App.getStore("pathAdmin").storeTrigger(
                  "path:item:updated"
                )
            }}
          />
          {!!discussion && !!discussion.id && (
            <DiscussionUnlocksOverlay
              cohortId={get(options, "context.class")}
              pathItemSourceId={get(discussion.toJSON(), "context.step")}
            />
          )}
        </ScrollView>
      ))

      const panelOptions = {
        panelWidthPerc: 0.8,
        title: capitalize(header.title),
        link: header.url || get(options, "context.url"),
        onWillClose: closePanel,
        className: "DiscussionPanel"
      }

      window.App.getStore("layout").action.showIn(
        "panel",
        props => (
          <DiscussionPanelViewContainer
            {...props}
            cohortId={get(options, "context.class")}
          />
        ),
        options,
        panelOptions
      )

      return window.App.vent.trigger("discussion:panel:shown", discussion)
    }

    if (discussionId) {
      let fetchContext = {}
      if (options.context != null) {
        fetchContext = options.context
      } else {
        fetchContext = discussionStore.request.getLegacyContext()
      }
      if (fetchContext.view != null) {
        delete fetchContext["view"]
      }
      return discussionStore.action
        .getDiscussionByID(discussionId, options.context)
        .promise.then(discussion => openPanelWithContext(discussion))
        .catch(err => {
          console.error("Discussion error: ", err)
          alert("Discussion not found. It may have been deleted.")
          // after clicking "ok", remove failed discussion ID from URL
          onExitDiscussion()
        })
    }

    return openPanelWithContext()
  })

  window.App.reqres.setHandler("get:discussion:perms", function(discussion) {
    if (discussion == null) {
      discussion = null
    }
    const perms =
      (discussion != null ? discussion.get("user_permissions") : undefined) ||
      {}
    perms.can_post_discussion_question = false
    const user = window.App.request("get:user")

    if (
      __guard__(
        App.getStore("resource").request.permissions &&
          App.getStore("resource").request.permissions(),
        x => x.can_facilitate
      )
    ) {
      perms.can_post_discussion_question = true
    }

    if (
      __guard__(user.get("membership"), x1 => x1.is_school_admin) ||
      user.get("is_superuser")
    ) {
      perms.can_post_discussion_question = true
    } else if (
      __guard__(
        typeof context !== "undefined" && context !== null
          ? context.user
          : undefined,
        x2 => x2.can_facilitate_course
      )
    ) {
      // Context is a global!
      // TODO: replace once old path is gone
      perms.can_post_discussion_question = context.user.can_facilitate_course
    }

    // Catch exception where facilitators cannot change a students
    // question to a discussion question
    if (discussion != null && perms.can_post_discussion_question) {
      if (
        discussion.get("author").id !== user.id &&
        !discussion.get("is_discussion_question")
      ) {
        // This is a student question, doesn't make sense to show discussion option
        perms.can_post_discussion_question = false
      }
    }

    return perms
  })

  // TODO: move this stuff
  window.App.reqres.setHandler(
    "get:response:filters",
    () => require("./defines").default.responseFilters
  )

  window.App.reqres.setHandler(
    "get:discussion:filters",
    () => require("./defines").default.discussionFilters
  )

  return window.App.reqres.setHandler("get:discussion:filter", function(label) {
    const filters = window.App.request("get:discussion:filters")
    return _.find(
      filters.options,
      op => op.label.toLowerCase() === label.toLowerCase()
    )
  })
})

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
