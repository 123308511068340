// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let PathwrightSubscription
export default PathwrightSubscription = class PathwrightSubscription extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    const { plan_id } = this.attributes
    const url = this.urlRoot(
      `/api/private/billing/plan/${plan_id}/subscription/`
    )
    // if @id
    //   url = "#{url}#{@id}/"
    return url
  }
}
