// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const { getResourceCache } = require("./resource-cache")

const createResourceDestroyer = function(Resource, resourceKey) {
  let resourceDestroyer
  return (resourceDestroyer = function(data, initialData) {
    // we don't handle collections currenly for update
    if (initialData == null) {
      initialData = {}
    }
    data = _.defaults(data, initialData)
    const resource = new Resource(data)
    return new Promise(function(resolve, reject) {
      return resource
        .destroy()
        .then(function() {
          const cache = getResourceCache(resourceKey)
          if (cache != null) {
            cache.clearAll()
          } // force clear cache to avoid stale data
          return resolve(resource)
        })
        .fail(err => reject(err))
    })
  })
}

const createResourceDestroyAction = function(
  store,
  resourceDestroyer,
  resource_key,
  destroying_key,
  error_key
) {
  const destroyAction = function(data) {
    const actionData = {
      action: `${resource_key}.destroy`,
      args: arguments
    }
    store._logAction(actionData)
    store._mutate({ [destroying_key]: true })
    const initialData = store.getState()[resource_key] || {}
    return resourceDestroyer(data, initialData)
      .then(function(resource) {
        const update = {}
        update[destroying_key] = false
        update[resource_key] = null
        update[error_key] = null
        return store._mutate(update)
      })
      .catch(function(err) {
        const update = {}
        update[destroying_key] = false
        update[error_key] = err
        store._mutate(update)
        if (err.status == null) {
          console.error(
            `${resource_key} resource destroy action runtime error: `,
            err
          )
          return console.trace()
        }
      })
  }
  return destroyAction
}

export { createResourceDestroyer, createResourceDestroyAction }
