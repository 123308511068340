import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import PropTypes from "prop-types"
import React from "react"
import { Field, Form, Submit } from "react-connect-form-forked"
import ImagePicker from "../../media-manager/ImagePickerWithMM"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const ManageAccountLogo = ({ card, initialValue, onSubmit }) => {
  const { school } = usePathwrightContext()
  return (
    <Card card={card} title={`${school.name} Logo`}>
      <Form initialValue={initialValue} onSubmit={onSubmit}>
        <CardBlock>
          <Field
            name="logo"
            topLabel
            labelWidth={280}
            label="Upload a custom logo"
            instructions="A custom logo replaces your account name in the header. It should be in landscape orientation and have a transparent background. Upload a .png at least 100px wide."
            component={props => (
              <ImagePicker {...props} blocksContextKey={`logo-${school.id}`} />
            )}
            apiKey="PKIcKga_QLKv1rkAdPQB"
            width={250}
            alignCenter
            background
            imageSize="80%"
            options={{ accept: "image/png" }}
          />
        </CardBlock>
        <Submit styleType="primary" component={SubmitButton}>
          Save
        </Submit>
      </Form>
    </Card>
  )
}

ManageAccountLogo.displayName = "ManageAccountLogo"

ManageAccountLogo.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    logo: PropTypes.string
  })
}

export default ManageAccountLogo
