import { ApolloProvider, useApolloClient } from "@apollo/client"
import { Spinner } from "@chakra-ui/react"
import { getApolloClient } from "@pathwright/blocks-engine"
import { MediaManager as BlocksMediaManager } from "@pathwright/legacy-media-manager"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import PropTypes from "prop-types"
import { Suspense, useMemo } from "react"
import { getGraphQLEndpoint } from "../pathwright/PathwrightClient"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import useAuthAwareQuery from "../utils/useAuthAwareQuery"
import MEDIA_CONTEXT_QUERY from "./graphql/media-context-query"

const getClientEndpoint = (schoolID) =>
  `${getGraphQLEndpoint()}blocks/graphql?school_id=${schoolID}`

const MediaManager = (props) => {
  const { data, loading, error } = useAuthAwareQuery(MEDIA_CONTEXT_QUERY)
  const pwContext = usePathwrightContext()

  // Either acquire a new blocks Apollo client or use the one in context
  // when testing provided by the ApolloMockingProvider.
  const blocksClient = useMemo(
    () =>
      getApolloClient({
        endpoint: getClientEndpoint(pwContext.school.id),
        accountID: pwContext.school.id
      }),
    [pwContext.school.id]
  )
  const contextClient = useApolloClient()
  const client = contextClient?.defaultOptions?.mocking
    ? contextClient
    : blocksClient

  if (!pwContext.me) return null
  if (loading || !data) return props.renderLoading()
  if (error) return new Error(error)

  const { accountID, user, mediaStoragePath } = data.mediaContext

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={LoadingCircle}>
        <BlocksMediaManager
          userID={user.id}
          accountID={accountID}
          mediaStoragePath={mediaStoragePath}
          {...props}
        />
      </Suspense>
    </ApolloProvider>
  )
}

MediaManager.propTypes = {
  contextKey: PropTypes.string
}

MediaManager.defaultProps = {
  renderLoading: () => <Spinner />
}

export default MediaManager
