import {
  i18nTogglerActiveKey,
  i18nVisibleKey
} from "@pathwright/ui/src/components"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import useDidMountEffect from "@pathwright/ui/src/components/hooks/useDidMountEffect"
import CustomEvent from "custom-event"
import PropTypes from "prop-types"
import React, { useState } from "react"

const ManageToggleTranslations = ({ card }) => {
  const [isActive, setIsActive] = useState(
    window.sessionStorage.getItem(i18nTogglerActiveKey)
  )

  useDidMountEffect(() => {
    if (isActive) {
      window.sessionStorage.setItem(i18nTogglerActiveKey, "visible")
    } else {
      window.sessionStorage.removeItem(i18nTogglerActiveKey)
      window.localStorage.setItem(i18nVisibleKey, "false")
    }

    // Signal to Togglei18nKeys that the toggler visibility has changed.
    window.dispatchEvent(
      new CustomEvent(i18nTogglerActiveKey, {
        detail: {
          active: isActive
        }
      })
    )
  }, [isActive])

  return (
    <Card card={card} title="Toggle Translation Keys">
      <CardBlock>
        <ToggleInput
          name="enable_i18n_toggle"
          label="Enable i18n keys translation toggler"
          type="custom"
          helperText="When toggled on, the i18n translation toggler will be visible. The toggler will display the paths to translateable labels in the JSON translation files. The toggler will be visible even if logged out (so that you can test sign-up flows, etc.), but will remain active only for the duration of a session (i.e., when the current tab or browser window is closed, this toggle will be turned off)."
          alignRight
          labelWidth={275}
          value={isActive}
          onChange={value => setIsActive(value)}
        />
      </CardBlock>
    </Card>
  )
}

ManageToggleTranslations.displayName = "ManageToggleTranslations"

ManageToggleTranslations.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired
}

export default ManageToggleTranslations
