import { Spacer, Spinner, VStack } from "@chakra-ui/react"
import { ReactNode, createContext, useContext } from "react"
import TranslationsProvider from "../../../lib/lng/TranslationsProvider"
import ThemeProvider from "../../../lib/theme/ThemeProvider"
import { SpaceLoaderReturnType, useSpaceLoader } from "./loader"

export const PW_SPACE_KEY = "PW_SPACE"

export type SpaceContextType = SpaceLoaderReturnType

const SpaceContext = createContext<SpaceContextType>(null as any)

export const useSpaceContext = () => {
  return useContext(SpaceContext)
}

export const Provider = ({
  children,
  value,
  useTheme = true
}: {
  children: ReactNode
  value: SpaceContextType
  useTheme?: boolean
}) => {
  return useTheme ? (
    <SpaceContext.Provider value={value!}>
      <ThemeProvider>
        <TranslationsProvider>{children}</TranslationsProvider>
      </ThemeProvider>
    </SpaceContext.Provider>
  ) : (
    <SpaceContext.Provider value={value!}>
      <TranslationsProvider>{children}</TranslationsProvider>
    </SpaceContext.Provider>
  )
}

export const ConnectedProvider = ({
  children,
  useTheme
}: {
  children: ReactNode
  useTheme?: boolean
}) => {
  const value = useSpaceLoader()
  return value ? (
    <Provider value={value} useTheme={useTheme}>
      {children}
    </Provider>
  ) : (
    <VStack w="100%" justifyItems="center">
      <Spacer />
      <Spinner />
      <Spacer />
    </VStack>
  )
}

// TODO: update all imports of ConnectedProvider to use ConnectedSpaceProvider
export const ConnectedSpaceProvider = ConnectedProvider

export default SpaceContext
