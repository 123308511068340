import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { getSchoolMutation } from "../../school/graphql/update-school-mutation"
import { PAGE_MODE_CONTAINED, PAGE_TYPES, PAGE_TYPE_TERMS } from "../constants"
import CREATE_PAGE_MUTATION from "../graphql/create-page-mutation"
import PAGE_QUERY from "../graphql/page-query"
const { isMaxLength, isRequired } = validators

const ManagePrivacy = ({
  card,
  updatePrivacy,
  createPage,
  page,
  pageType,
  loading,
  error
}) => {
  const { school } = usePathwrightContext()
  const initialValue = {
    consent_description: school.consent_description,
    enable_consent_description: !!school.consent_description,
    enable_privacy_policy: false
  }

  return loading ? (
    <LoadingCircle />
  ) : error ? (
    <Alert error={error} />
  ) : (
    <Card card={card} title="Privacy">
      <Form
        initialValue={initialValue}
        onSubmit={updatePrivacy}
        render={({ onSubmit, ...formState }) => {
          return (
            <form onSubmit={onSubmit}>
              <CardBlock>
                <Field
                  name="enable_consent_description"
                  label="Enable opt-in agreement on sign-up"
                  type="custom"
                  helperText="Enable a checkbox opt-in to gather consent for information use."
                  component={ToggleInput}
                  alignRight
                  labelWidth={275}
                />
              </CardBlock>
              {formState.value.enable_consent_description && (
                <CardBlock>
                  <Field
                    name="consent_description"
                    label="Opt-in description"
                    type="text"
                    helperText="Describe how you use the personal information you collect."
                    placeholder="Send me emails with occasional updates and promotions."
                    component={TextArea}
                    topLabel
                    validators={[isRequired]}
                  />
                </CardBlock>
              )}
              {!page && (
                <CardBlock>
                  <Field
                    name="enable_privacy_policy"
                    label="Enable custom privacy policy"
                    type="custom"
                    helperText="Include a link to your privacy policy from your sign-up form."
                    component={ToggleInput}
                    alignRight
                    labelWidth={275}
                    loading={formState.value.enable_privacy_policy}
                    onChange={enable_privacy_policy => {
                      // Create privacy when toggled on
                      if (enable_privacy_policy) {
                        createPage({
                          name: PAGE_TYPES[PAGE_TYPE_TERMS],
                          title: PAGE_TYPES[PAGE_TYPE_TERMS],
                          page_type: PAGE_TYPE_TERMS,
                          mode: PAGE_MODE_CONTAINED
                        })
                      }
                    }}
                  />
                </CardBlock>
              )}
              {page && (
                <CardLink
                  icon="file-text"
                  label="Edit custom privacy policy"
                  meta="Include a link to your privacy policy from your sign-up form."
                  to={`${card.path}page/`}
                />
              )}
              <Submit
                styleType="primary"
                component={SubmitButton}
                disabled={!formState.valid}
              >
                Save
              </Submit>
            </form>
          )
        }}
      />
    </Card>
  )
}

ManagePrivacy.displayName = "ManagePrivacy"

ManagePrivacy.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  page: PropTypes.object
}

export default compose(
  graphql(PAGE_QUERY, {
    options: () => ({
      variables: {
        page_type: PAGE_TYPE_TERMS
      }
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      page: get(data, "school.page")
    })
  }),
  graphql(CREATE_PAGE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      createPage: changes =>
        mutate({
          variables: {
            show_in_nav: false,
            ...changes
          },
          refetchQueries: ["PageQuery"]
        })
    })
  }),
  graphql(getSchoolMutation(["consent_description"]), {
    props: ({ mutate, ownProps }) => ({
      updatePrivacy: changes => {
        delete changes.enable_consent_description
        return mutate({
          variables: {
            consent_description: get(changes, "consent_description") || ""
          }
        })
      }
    })
  })
)(ManagePrivacy)
