// helper for getting embed code from Iframely for provided URL
export const embedIframelyURL = url => {
  const root = "https://iframe.ly/api/iframely"
  const key = "b8a640388fb50cfa2f18b0"
  const query = `${root}?url=${url}&api_key=${key}&omit_script=true&iframe=true`

  return new Promise((resolve, reject) => {
    fetch(query)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          reject(res.error)
        } else {
          resolve(res)
        }
      })
      .catch(err => {
        reject(new Error(err))
      })
  })
}

// TODO: implement a better embed verification
// This check is too stringent, not allowing for external embeds
// const iframelyCanLoad = () => {
//   return (
//     document.querySelectorAll("[data-iframely-url]").length ||
//     document.querySelectorAll("iframe[src*='iframe.ly']").length
//   )
// }

// Replace 'iframe.ly' with your custom CDN if available.
export const loadIframely = () => {
  var iframely = (window.iframely = window.iframely || {})
  var widgets = (iframely.widgets = iframely.widgets || {})
  if (widgets.load) {
    widgets.load()
  } else {
    var ifs = document.createElement("script")
    ifs.type = "text/javascript"
    ifs.async = true
    ifs.src =
      ("https:" === document.location.protocol ? "https:" : "http:") +
      "//cdn.iframe.ly/embed.js"
    var s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(ifs, s)
  }
  return true
}

const addScriptTag = function(el, script) {
  const tag = document.createElement("script")
  if (script.src) {
    tag.src = script.src
  } else if (script.innerHTML) {
    tag.innerHTML = script.innerHTML
  }
  return el.appendChild(tag)
}

export const executeScripts = function(el) {
  const scripts = el.getElementsByTagName("script")
  const l = scripts.length
  for (let i = 0; i < l; i++) {
    addScriptTag(el, scripts[i])
  }
}

// util for inserting try/catch statements in each script found in the provided html string
export const insertTryCatch = htmlString => {
  // create a document fragment via Range using the html string
  const docFrag = document.createRange().createContextualFragment(htmlString)
  // get all the scripts lacking a src attribute
  const scripts = docFrag.querySelectorAll("script:not([src])")

  // for each script...
  scripts.forEach(script => {
    // ... insert wrapping try/catch statement
    script.innerHTML = `
      // NOTE: this try/catch statement is added by Pathwright to safeguard against any unexpected errors found in injected scripts
      try {
        // insert the script code inside the try block of the try/catch statement
        ${script.innerHTML}
      } catch (error) {
        // TODO: handle error in UI?
        console.error("Caught script error:", error)
      }
    `
  })

  // append document fragment to dummy element in order to grab the innerHTML
  const dummyContainer = document.createElement("div")
  dummyContainer.appendChild(docFrag)
  return dummyContainer.innerHTML
}
