import gql from "graphql-tag"
import REGISTRATION_FRAGMENT from "./registration-fragment"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import GROUP_FRAGMENT from "../../group/graphql/group-fragment"

export default gql`
  query RegistrationQuery($group_id: Int!, $registration_id: Int) {
    group(id: $group_id) {
      ...Group
      permissions {
        can_invite_observer
        can_invite_student
        can_invite_moderator
        can_invite_teacher
        can_invite_editor
      }
      registration(id: $registration_id) {
        ...Registration
        user {
          ...User
        }
      }
    }
  }
  ${GROUP_FRAGMENT}
  ${REGISTRATION_FRAGMENT}
  ${USER_FRAGMENT}
`
