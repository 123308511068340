import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import styled from "styled-components"
import useScrollToMostRecentNode from "../../hooks/useScrollToMostRecentNode"
import { getOrderedList } from "../../sortable/utils"
import TagForm from "../form/TagForm"
import {
  onChangeTagsPropType,
  tagPermissionsPropType,
  tagsPropType
} from "../propTypes"
import * as utils from "../utils"
import TagManagerListItem from "./TagManagerListItem"

const Container = styled.div`
  overflow: scroll;
  max-height: 400px;
  margin-bottom: 1em;

  ul {
    list-style: none;
    padding: 0;
  }

  /* Hiding controls when dragging over TagManagerList */
  ul.TagManagerList__is-dragging-over
    .TagManagerListItem:not(.TagManagerListItem__is-dragging)
    .TagManagerListItem__controls {
    visibility: hidden;
  }
`

const TagManagerList = ({
  tags,
  tagPermissions,
  tagFormLabels,
  onChange,
  getTagMetaLabels
}) => {
  // Scroll to the newest tag to enter selection.
  const handleTagItemNode = useScrollToMostRecentNode({
    list: tags.selected
  })

  if (!tags.selected || !tags.selected.length) return null

  // Updates the order of the selected tags.
  const updateSelectedTagsOrder = dragEnd => {
    // Ensure TagManagerListItem was not dropped outside the TagManagerList.
    if (!dragEnd.destination) return

    const { list, item } = getOrderedList(tags.selected, dragEnd)
    onChange(
      {
        ...tags,
        selected: list
      },
      {
        action: "reorder-tag",
        target: item
      }
    )
  }

  let updateTag = (newTag, oldTag) => {
    const updateTag = tagsList =>
      tagsList.map(tag => (tag.name === oldTag.name ? newTag : tag))

    onChange(
      {
        all: updateTag(tags.all),
        filtered: updateTag(tags.filtered),
        selected: updateTag(tags.selected)
      },
      { action: "update-tag", target: newTag }
    )
  }

  let removeTag = removedTag => {
    onChange(
      {
        ...tags,
        // Guaranteed we want to remove the tag from the currently
        // selected tags, but will defer to the onChange handler for
        // removing the tag from the broader scope.
        selected: utils.removeTag(tags.selected, removedTag)
      },
      { action: "unselect-tag", target: removedTag }
    )
  }

  // Reset mutation functions if disabled via permissions.
  if (!tagPermissions.canEdit) updateTag = null
  if (!tagPermissions.canRemove) removeTag = null

  const itemProps = {
    tags,
    getTagMetaLabels,
    tagPermissions,
    tagFormLabels,
    updateTag,
    removeTag
  }

  // List with DnD.
  if (tagPermissions.canReorder && tags.selected.length > 1) {
    return (
      <Container>
        <DragDropContext onDragEnd={updateSelectedTagsOrder}>
          <Droppable type="tag" droppableId="tags">
            {(provided, droppableSnapshot) => (
              <React.Fragment>
                <ul
                  className={classnames("TagManagerList", {
                    ["TagManagerList__is-dragging-over"]:
                      droppableSnapshot.isDraggingOver
                  })}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tags.selected.map((tag, index) => (
                    <Draggable
                      key={tag.name}
                      type="tag"
                      draggableId={tag.name}
                      index={index}
                    >
                      {(provided, draggableSnapshot) => (
                        <TagManagerListItem
                          ref={ref => {
                            provided.innerRef(ref)
                            handleTagItemNode(tag)(ref)
                          }}
                          tag={tag}
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          draggableSnapshot={draggableSnapshot}
                          {...itemProps}
                        />
                      )}
                    </Draggable>
                  ))}
                </ul>
                {provided.placeholder}
              </React.Fragment>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    )
  }

  // Simple list without DnD.
  return (
    <Container>
      <ul className="TagManagerList">
        {tags.selected.map(tag => (
          <TagManagerListItem
            key={tag.name}
            ref={ref => handleTagItemNode(tag, ref)}
            tag={tag}
            {...itemProps}
          />
        ))}
      </ul>
    </Container>
  )
}

TagManagerList.displayName = "TagManagerList"

TagManagerList.propTypes = {
  tags: tagsPropType,
  tagPermissions: tagPermissionsPropType.isRequired,
  tagFormLabels: TagForm.propTypes.labels,
  onChange: onChangeTagsPropType.isRequired,
  getTagMetaLabels: PropTypes.func
}

TagManagerList.defaultProps = {
  tagPermissions: {
    canAdd: false,
    canEdit: false,
    canRemove: false,
    canReorder: false
  }
}

export default TagManagerList
