import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Select, * as SelectGoodies from "react-select"
import "react-select/dist/react-select.css"
import styled from "styled-components"
import FieldWrapper from "../form-utils/FieldWrapper"
import "./ReactSelectInput.css"

export const components = SelectGoodies.components

const StyledSelect = styled(Select)`
  ${props =>
    props.position === "top"
      ? `
      &.is-open > .Select-control {
        border-radius: 0 0 4px 4px !important;
        border-top: none !important;
      }

      .Select-menu-outer {
        top: auto !important;
        bottom: 100% !important;
        border-bottom: none !important;
        border-top: 1px solid #6a9fcb !important;
        border-radius: 4px 4px 0 0 !important;
      }
    `
      : `
      &.is-open > .Select-control {
        border-radius: 4px 4px 0 0 !important;
        border-bottom: none !important;
      }

      .Select-menu-outer {
        bottom: auto !important;
        top: 100% !important;
        border-top: none !important;
        border-bottom: 1px solid #6a9fcb !important;
        border-radius: 0 0 4px 4px !important;
      }`}
`

const SelectInput = function(props) {
  const {
    label,
    value,
    className,
    readOnly,
    disabled,
    onChange,
    onInputChange
  } = props

  return (
    <FieldWrapper {...props} className={classnames("SelectInput", className)}>
      {({ placeholder, onFocus, onBlur }) => {
        return (
          <React.Fragment>
            <StyledSelect
              {...props}
              name={label}
              value={value}
              idDisabled={disabled || readOnly || props.isDisabled}
              placeholder={placeholder}
              onChange={onChange}
              onInputChange={onInputChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {props.children}
          </React.Fragment>
        )
      }}
    </FieldWrapper>
  )
}

SelectInput.displayName = "SelectInput"

SelectInput.propTypes = {
  position: PropTypes.oneOf(["top", "bottom"]) // position of menu
}

SelectInput.defaultProps = {
  searchable: false,
  clearable: false,
  multi: false,
  position: "bottom"
}

export default SelectInput
