// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
if (window.ParsleyConfig == null) {
  window.ParsleyConfig = {}
}

$.extend(true, window.ParsleyConfig, {
  successClass: "pw-field-success",
  errorClass: "pw-field-error",
  animateDuration: 0,
  validateIfUnchanged: true,
  focus: true,
  // The parsley docs seem to say that adding custom
  // validators here will work, but curently that is
  // not the case. So using the alternative method
  // below by individually adding each validator.
  validators: {},
  en: {
    cc_number: "Please enter a valid card number",
    cvc_code: "Please enter a valid CVC code",
    exp_date: "Please select a future expiration date",
    address_zip: "Please enter a valid zip code",
    multiple_email: "Invalid email",
    required: "Required",
    type: {
      email: "Invalid email",
      digits: "Digits only",
      alphanum: "Alphanumeric only"
    },
    min: "Must be > %s.",
    max: "Must be < %s.",
    range: "Must be between %s and %s.",
    minlength: "Must be at least %s characters",
    maxlength: "Must be less than %s characters",
    equalto: "Passwords must match",
    serverError: "Server error",
    slug:
      "Please enter a valid url. Only alphanumeric, dash, or underscore characters are accepted."
  },
  errorsWrapper: "<div class='pw-error-message-container'></div>",
  errorTemplate: "<span class='pw-error-message'></span>"
})

const validators = {
  cc_number: {
    priority: 32,
    fn(val) {
      return Stripe.card.validateCardNumber(val)
    }
  },
  cvc_code: {
    priority: 32,
    fn(val) {
      return Stripe.card.validateCVC(val)
    }
  },
  exp_date: {
    priority: 32,
    fn(val, els) {
      const m = $(`select#id_${els[0]}`).val()
      const y = $(`select#id_${els[1]}`).val()
      return Stripe.card.validateExpiry(m, y)
    }
  },
  slug: {
    priority: 32,
    fn(val) {
      const urlregex = /^[a-zA-Z0-9-_]+$/
      return urlregex.test(val)
    }
  },
  address_zip: {
    priority: 32,
    fn(val) {
      const zipCodRegex = /^[a-zA-Z 0-9\-]{3,10}$/g
      return zipCodRegex.test(val)
    }
  },
  multiple_email: {
    priority: 32,
    fn(val) {
      // Regex stolen from parsleyjs
      let email
      const rgx = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
      const emails = (() => {
        const result = []
        for (email of Array.from(
          __guardMethod__(val, "split", o => o.split(","))
        )) {
          if ($.trim(email).length) {
            result.push($.trim(email))
          }
        }
        return result
      })()
      const invalidEmails = (() => {
        const result1 = []
        for (email of Array.from(emails)) {
          if (!rgx.test(email)) {
            result1.push(email)
          }
        }
        return result1
      })()
      return invalidEmails.length === 0
    }
  }
}

// parsley.addMessages("en", ParsleyConfig.en)
// parsley.setLocale("en")

for (let name in validators) {
  const validator = validators[name]
  window.Parsley.addValidator(name, validator.fn, validator.priority)
}
function __guardMethod__(obj, methodName, transform) {
  if (
    typeof obj !== "undefined" &&
    obj !== null &&
    typeof obj[methodName] === "function"
  ) {
    return transform(obj, methodName)
  } else {
    return undefined
  }
}
