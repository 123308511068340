// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let UserSchoolCollection
export default UserSchoolCollection = (function() {
  UserSchoolCollection = class UserSchoolCollection extends require("lib/static-shim").default(
    require("lib/core/collection").default
  ) {
    static initClass() {
      this.prototype.model = require("./user-school").default
    }

    url() {
      return this.urlRoot(`/api/private/user/schools/`)
    }
  }
  UserSchoolCollection.initClass()
  return UserSchoolCollection
})()
