import { getFormStateStorageKey } from "@pathwright/ui/src/components/form/form-state/useFormStateStorage"
import useCleanupInterval from "@pathwright/ui/src/components/hooks/useCleanupInterval"
import useLocalStorage from "@pathwright/ui/src/components/hooks/useLocalStorage"
import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Modal from "@pathwright/ui/src/components/modal/Modal"
import classnames from "classnames"
import merge from "lodash/merge"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { useCertificateContext } from "../context/CertificateContext"
import useCertificatePreview from "../hooks/useCertificatePreview"
import useCertificatePreviewParam from "../hooks/useCertificatePreviewParam"
import { useWeavedBaseVariables } from "../variables/variables"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
  z-index: 999999999;

  iframe {
    min-height: 100vh;
    min-width: 100vw;
  }

  iframe:first-of-type {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  iframe:last-of-type {
    z-index: 2;
    ${p => (p.$loading ? "display: none;" : "")}
  }
`

const useCertificateFormStateStorage = () => {
  const { certificateId, certificateScope } = useCertificateContext()
  const baseKeyParts = ["certificate", certificateId, certificateScope]
  const initalValue = {}
  const options = { sync: true }

  const [certificateFormState] = useLocalStorage(
    getFormStateStorageKey(baseKeyParts),
    initalValue,
    options
  )

  return certificateFormState ? certificateFormState.values : null
}

const _useCertificatePreview = () => {
  const formState = useCertificateFormStateStorage()
  // Get the default preview for a certificate, necessary for when
  // there does not yet exist any form state storage.
  const [previewData] = useCertificatePreviewParam()
  const defaultValues = previewData || {}
  const values = merge(formState || defaultValues, {
    baseVariables: useWeavedBaseVariables()
  })
  return useCertificatePreview(values)
}

const CertificatePreview = ({ className }) => {
  const certificatePreview = _useCertificatePreview()

  const [loading, setLoading] = useState(true)
  // Track current and previous preview urls for handling loading states.
  const [previews, setPreviews] = useState({})
  const cleanupInterval = useCleanupInterval()

  // Using a timeout of 2000 which works well when testing. This is to account
  // for the time it takes for the PDF to be fully rendered after the iframe
  // has initially loaded. I am not aware of a declarative approach :(
  const handleLoaded = () =>
    cleanupInterval(setTimeout(() => setLoading(false), 2000))

  // Only updating the previous preview when the current preview changes
  // as long as we're not loading. Ideally would not rely on the usePreviousEffect
  // as it seems to add more complexity than necessary, but alternative solutions
  // have introduced some slightly less desireble loading UX.
  usePreviousEffect(
    ([prevCertificatePreview]) => {
      setPreviews({
        previous: loading ? previews.previous : prevCertificatePreview,
        current: certificatePreview
      })
    },
    [certificatePreview]
  )

  useEffect(() => setLoading(true), [previews.current])

  return previews.current ? (
    <Container
      className={classnames("CertificatePreview", className)}
      $loading={loading}
    >
      {!!previews.previous && (
        <iframe
          key={previews.previous}
          src={`${previews.previous}&download=0`}
        />
      )}
      <iframe
        key={previews.current}
        src={`${previews.current}&download=0`}
        onLoad={handleLoaded}
      />
      {loading && (
        <Modal>
          <LoadingCircle center inverted />
        </Modal>
      )}
    </Container>
  ) : null
}

CertificatePreview.displayName = "CertificatePreview"

export default CertificatePreview
