import React from "react"
import styled from "styled-components"
import { media } from "@pathwright/ui/src/components/utils/styles"

import Text from "@pathwright/ui/src/components/ui/Text"

const StyledContainer = styled.header`
  margin-bottom: 40px;
  text-align: center;
  ${media.max.phone`margin-bottom: 10px;`};
`

const SignupHeader = ({ title, body, inverted }) => {
  return (
    <StyledContainer>
      <Text.H2 style={{ marginBottom: 10 }} inverted={inverted}>
        {title}
      </Text.H2>
      <Text.Body inverted={inverted}>{body}</Text.Body>
    </StyledContainer>
  )
}

SignupHeader.displayName = "SignupHeader"

export default SignupHeader
