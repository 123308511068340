import Alert from "@pathwright/ui/src/components/alert/Alert"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import RadioGroup from "@pathwright/ui/src/components/form/form-radio/RadioGroup"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import {
  useInitialValues,
  validate
} from "@pathwright/ui/src/components/form/utils"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Text from "@pathwright/ui/src/components/ui/Text"
import { Formik } from "formik"
import SchoolSubscriptionPlansCheckboxes from "../../../subscription/manage/SchoolSubscriptionPlansCheckboxes"

const ManageDiscountSubscriptionPlansForm = ({ setValues }) => {
  const { t, tc } = useTranslate()

  return (
    <Formik
      initialValues={useInitialValues(
        ManageDiscountSubscriptionPlansForm.initialValues
      )}
      validate={values => {
        setValues(values)
        // return the errors
        return ManageDiscountSubscriptionPlansForm.validate(values)
      }}
    >
      {({
        values,
        setValues,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldTouched
      }) => (
        <>
          <CardBlock>
            <TextInput
              name="numCycles"
              value={values.numCycles}
              type="text"
              required
              label="Free months"
              labelWidth={105}
              helperText="Enter the number of free months granted with this code."
              autoSize
              minWidth={50}
              stretch={false}
              errors={
                errors.numCycles && touched.numCycles
                  ? [errors.numCycles]
                  : null
              }
              onChange={(value, e) => handleChange(e)}
              onBlur={(value, e) => handleBlur(e)}
            />
          </CardBlock>
          <CardBlock
            paddingTop={false}
            paddingBottom={false}
            borderBottom={false}
          >
            <CardBlock paddingLeft={false} paddingRight={false}>
              <RadioGroup hideStatus topLabel label={tc("apply discount to:")}>
                <RadioButton
                  name="targetType"
                  value="allPlans"
                  checked={values.targetType === "allPlans"}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <Text.Body>
                    {tc("all current & future monthly subscription plans")}
                  </Text.Body>
                </RadioButton>

                <RadioButton
                  name="targetType"
                  type="radio"
                  value="selectPlans"
                  checked={values.targetType === "selectPlans"}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <Text.Body>{tc("select subscription plans")}</Text.Body>
                </RadioButton>
              </RadioGroup>

              {values.targetType === "selectPlans" && (
                <SchoolSubscriptionPlansCheckboxes
                  intervalFilter={{ eq: "monthly" }}
                  onChange={selectedPlanIds => {
                    setFieldTouched("selectedPlanIds")
                    setValues({ ...values, selectedPlanIds })
                  }}
                  value={values.selectedPlanIds}
                />
              )}
              {Boolean(
                errors.subscriptionPlanSelectioxwnType &&
                  touched.targetType &&
                  touched.selectedPlanIds
              ) && <Alert error={errors.targetType} />}
            </CardBlock>
          </CardBlock>
        </>
      )}
    </Formik>
  )
}

ManageDiscountSubscriptionPlansForm.displayName =
  "ManageDiscountSubscriptionPlansForm"

ManageDiscountSubscriptionPlansForm.initialValues = {
  targetType: "allPlans",
  selectedPlanIds: [],
  numCycles: null
}

ManageDiscountSubscriptionPlansForm.validate = validate(
  (key, value, values) => {
    switch (key) {
      case "targetType":
        if (value === "selectPlans" && !values.selectedPlanIds.length)
          return "Must select at least 1 subscription plan"
        break
      case "numCycles":
        if (!value || parseInt(value) <= 0)
          return "Please enter the number of plan cycles to discount."
    }
  }
)

export default ManageDiscountSubscriptionPlansForm
