import React from "react"
import StepActivity from "./StepActivity"

const CompleteStepActivity = props => {
  return <StepActivity {...props} icon="check-circle" />
}

CompleteStepActivity.displayName = "CompleteStepActivity"

export default CompleteStepActivity
