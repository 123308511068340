import PropTypes from 'prop-types';
import React from "react"
import "./ToggleInput.css"
import classnames from "classnames"
import FieldWrapper from "../form-utils/FieldWrapper"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Switch from "./Switch"

const ToggleInput = function(props) {
  let {
    label,
    value,
    loading,
    onChange,
    className,
    borderTop,
    borderBottom,
    alignRight
  } = props

  className = classnames(
    "ToggleInput",
    {
      "ToggleInput--align-right": alignRight,
      "ToggleInput--border-top": borderTop,
      "ToggleInput--border-bottom": borderBottom
    },
    className
  )

  return (
    <FieldWrapper {...Object.assign({}, props, { className: className })}>
      <div className="ToggleInput__switch">
        <Switch
          color="#3B99FC"
          active={value}
          onChange={val =>
            typeof onChange === "function" ? onChange(val, props) : undefined
          }
        />
        {loading ? <LoadingCircle size={16} /> : undefined}
      </div>
    </FieldWrapper>
  )
}

ToggleInput.displayName = "ToggleInput"
ToggleInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
}

export default ToggleInput
