import Button from "@pathwright/ui/src/components/button/Button"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import RichTextInput from "@pathwright/ui/src/components/form/form-richtext/RichTextInput"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingBlock from "@pathwright/ui/src/components/loading/LoadingBlock"
import Modal from "@pathwright/ui/src/components/modal/Modal"
import ProgressBar from "@pathwright/ui/src/components/progress/ProgressBar"
import View from "@pathwright/ui/src/components/ui/View"
import {
  DANGEROUS_RED,
  WARNING_ORANGE
} from "@pathwright/ui/src/components/utils/colors"
import pluralize from "pluralize"
import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { StyledRow } from "../../../home/learn/ui"
import useReviewStack from "../../state/useReviewStack"
import { isItemReviewable } from "../../utils"

const ReviewFormContainer = styled.form`
  display: flex;
  flex-direction: column;

  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
  }

  .MarkReviewed__Notes {
    width: 100%;
    min-width: 280px;
    margin-bottom: 1em;

    .ql-editor {
      border-radius: 12px;
    }
  }
`

const ReviewForm = ({ items }) => {
  const { t } = useTranslate()
  const [notify, setNotify] = useState(false)
  const [reviewMessage, setReviewMessage] = useState("")

  const users = new Set(items.map(item => item.user.id))

  return (
    <ReviewFormContainer>
      <div className="input">
        <Checkbox
          name={"notify"}
          checked={notify}
          onChange={() => setNotify(!notify)}
        />
        <label for="notify">{`Notify ${pluralize(
          "reviewee",
          users.size,
          true
        )} `}</label>
      </div>
      {notify && (
        <div className="MarkReviewed__Notes">
          <RichTextInput
            // innerRef={ref => (this.inputRef = ref)}
            placeholder={"Review notes (optional)..."}
            value={reviewMessage}
            onChange={value => {
              setReviewMessage(value)
            }}
            hideStatus
            hideLabel
            errors={null}
            stretch={true}
            controlled={true}
            LoadingComponent={() => <LoadingBlock style={{ height: "2em" }} />}
            style={{ minWidth: 280 }}
          />
        </div>
      )}
    </ReviewFormContainer>
  )
}

const ErrorContainer = styled.div`
  padding: 10px;
  bckground-color: ${WARNING_ORANGE};
  color: ${DANGEROUS_RED};
`

const Error = ({ error }) => {
  return (
    <ErrorContainer>
      <p>{error.toString()}</p>
    </ErrorContainer>
  )
}

const fakeReview = params => {
  // just return a promise that resolves after a random timeout
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(params)
    }, Math.random() * 1000)
  })
}

const defaultBatchOptions = {
  notify: false,
  userpoints_earned: 0,
  userpoints_explanation: ""
}

export const useBatchReview = () => {
  const { items } = useReviewStack()

  const reviewableItems = useMemo(() => items.filter(isItemReviewable), [items])

  const [progress, setProgress] = useState(0)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const doBatchReview = async options => {
    setLoading(true)
    for (let i = 0; i < reviewableItems.length; i++) {
      let item = items[i]
      let payload = {
        item,
        ...defaultBatchOptions,
        ...options
      }
      try {
        const result = await fakeReview(payload)
      } catch (e) {
        setError(e)
        return
      }
      // update progress
      setProgress((i + 1) / reviewableItems.length)
    }
    setLoading(false)
  }

  return {
    loading,
    error,
    items: reviewableItems,
    progress,
    doBatchReview
  }
}

const Container = styled(View.Primary)`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    p {
      text-align: center;
      font-style: italic;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  h1 {
    margin: 0px;
  }
`

const MarkAllReviewedModal = ({ onClose }) => {
  const { t } = useTranslate()

  const { items, doBatchReview, loading, error, progress } = useBatchReview()

  console.log({ loading, error, progress })

  const handleMarkReviewed = async () => {
    await doBatchReview()
  }

  const heading =
    items.length > 1
      ? t(`Mark ${items.length} items as reviewed?`)
      : t(`Mark reviewed?`)

  const buttonLabel = loading
    ? "Processing..."
    : items.length > 1
    ? `Mark ${items.length} items as reviewed`
    : `Mark reviewed`

  const prompt = `Are you sure you want to mark ${pluralize(
    "items",
    items.length,
    true
  )} as reviewed? This will apply to all mentors or teachers who can review these items.`

  const renderActionArea = () => {
    if (loading || progress >= 1) {
      const message =
        progress < 1
          ? `Processing ${pluralize("items", items.length, true)}...`
          : `${pluralize("items", items.length, true)} reviewed.`

      return (
        <React.Fragment>
          <p>{message}</p>
          <ProgressBar progress={progress} style={{ marginBottom: 10 }} />
          {progress >= 1 && (
            <Button styleType="primary" onClick={onClose}>
              Done
            </Button>
          )}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <ReviewForm items={items} />
          <StyledRow>
            <Button
              styleType="primary"
              onClick={handleMarkReviewed}
              disabled={loading}
              style={{ marginRight: "1em" }}
            >
              {buttonLabel}
            </Button>
            <Button styleType="text" onClick={onClose}>
              Cancel
            </Button>
          </StyledRow>
        </React.Fragment>
      )
    }
  }

  return (
    <Modal onClose={onClose}>
      <Container rounded>
        <h1>{heading}</h1>
        <p>{prompt}</p>

        {error && <Error error={error} />}
        <footer>{renderActionArea()}</footer>
      </Container>
    </Modal>
  )
}

export default MarkAllReviewedModal
