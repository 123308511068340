import PropTypes from "prop-types"
import {
  BILLING_MODEL_PAYG,
  BILLING_MODEL_HYBRID,
  BILLING_MODEL_USER
} from "./constants"
import {
  SUBSCRIPTION_INTERVAL_MONTHLY,
  SUBSCRIPTION_INTERVAL_YEARLY
} from "../subscription/constants"

const commonInvoiceProps = {
  __typename: PropTypes.oneOf(["SchoolBillingInvoice"]),
  id: PropTypes.number,
  cycle_end: PropTypes.string,
  cycle_start: PropTypes.string,
  invoiced_date: PropTypes.string,
  stripe_invoice_id: PropTypes.string,
  stripe_invoice_status: PropTypes.string,
  stripe_next_payment_attempt: PropTypes.string,
  total_amount_billed: PropTypes.number,
  url: PropTypes.string,
  child_subscriptions: PropTypes.arrayOf(PropTypes.object),
  parent_subscription: PropTypes.object
}

const commonInvoiceJsonProps = {
  billing_interval: PropTypes.oneOf([
    SUBSCRIPTION_INTERVAL_MONTHLY,
    SUBSCRIPTION_INTERVAL_YEARLY
  ]),
  plan_details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    color: PropTypes.string,
    base_fee: PropTypes.number,
    included_members: PropTypes.number,
    included_staff: PropTypes.number,
    learner_counting: PropTypes.number,
    additional_user_fee: PropTypes.number,
    blocks: PropTypes.shape({
      learners: PropTypes.object, // { 500 [qty]: 156 [price]}
      staff: PropTypes.object
    })
  }),
  line_items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.number,
      desc: PropTypes.string,
      qty: PropTypes.number,
      unit_price: PropTypes.number,
      total_price: PropTypes.number
    })
  ),
  free_extras: PropTypes.shape({
    learners: PropTypes.number,
    staff: PropTypes.number
  })
}

// Not in use currently
export const SubscriptionPlanType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  amount: PropTypes.number,
  interval: PropTypes.number,
  trial_period_days: PropTypes.number,
  trial_type: PropTypes.number
})

// Not in use currently
export const SchoolBillingPlanType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  visibility: PropTypes.number,
  amount: PropTypes.number,
  description: PropTypes.string,
  fee_per_additional_learner: PropTypes.number,
  learners_included: PropTypes.number,
  staff_included: PropTypes.number,
  color: PropTypes.string,
  order: PropTypes.number,
  should_contact_for_pricing: PropTypes.bool,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      key: PropTypes.number,
      icon: PropTypes.string,
      feature_type: PropTypes.number,
      order: PropTypes.number,
      platform_version: PropTypes.number
    })
  ),
  billing_blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      block_size: PropTypes.number,
      block_type: PropTypes.number,
      price: PropTypes.number
    })
  )
})

export const UserType = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  full_name: PropTypes.string,
  display_name: PropTypes.string,
  profile: PropTypes.shape({
    bio: PropTypes.string,
    image: PropTypes.string,
    location: PropTypes.string,
    last_activity: PropTypes.string
  })
})

export const InvoiceBlockType = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.number,
  size: PropTypes.number,
  qty: PropTypes.number,
  unit_price: PropTypes.number,
  total_price: PropTypes.number
})

const InvoicePAYGType = PropTypes.shape({
  ...commonInvoiceProps,
  invoice_json: PropTypes.shape({
    ...commonInvoiceJsonProps,
    billing_model: PropTypes.oneOf([BILLING_MODEL_PAYG]),
    payg: PropTypes.shape({
      sales: PropTypes.shape({
        sales_fee: PropTypes.number,
        total_fee: PropTypes.number,
        total_revenue: PropTypes.number
      }),
      subscriptions: PropTypes.shape({
        active: PropTypes.number,
        total_fee: PropTypes.number,
        total_revenue: PropTypes.number,
        subscription_fee: PropTypes.number
      }),
      enrollments: PropTypes.shape({
        billable: PropTypes.number,
        billed: PropTypes.number,
        reserved: PropTypes.number,
        reserved_cost: PropTypes.number
      })
    })
  })
})

const InvoiceHybridType = PropTypes.shape({
  ...commonInvoiceProps,
  invoice_json: PropTypes.shape({
    ...commonInvoiceJsonProps,
    billing_model: PropTypes.oneOf([BILLING_MODEL_HYBRID]),
    hybrid: PropTypes.shape({
      sales: PropTypes.shape({
        sales_fee: PropTypes.number,
        total_fee: PropTypes.number,
        total_revenue: PropTypes.number
      }),
      subscriptions: PropTypes.shape({
        active: PropTypes.number,
        total_fee: PropTypes.number,
        total_revenue: PropTypes.number,
        subscription_fee: PropTypes.number
      }),
      members: PropTypes.shape({
        total: PropTypes.number,
        billed: PropTypes.number,
        total_fee: PropTypes.number
      })
    })
  })
})

const InvoiceUserType = PropTypes.shape({
  ...commonInvoiceProps,
  invoice_json: PropTypes.shape({
    ...commonInvoiceJsonProps,
    cycle: PropTypes.shape({
      end: PropTypes.string,
      start: PropTypes.string
    }),
    total_amount_billed: PropTypes.number,
    scholarship: PropTypes.any,
    parent_subscription: PropTypes.object,
    partner: PropTypes.object,
    user: PropTypes.shape({
      learners: PropTypes.shape({
        billable: PropTypes.number,
        billed: PropTypes.number,
        reserved: PropTypes.number,
        reserved_cost: PropTypes.number,
        blocks: PropTypes.arrayOf(InvoiceBlockType)
      }),
      staff: PropTypes.shape({
        billable: PropTypes.number,
        billed: PropTypes.number,
        blocks: PropTypes.arrayOf(InvoiceBlockType)
      }),
      interval_discount: PropTypes.number
    })
  })
})

export const InvoiceType = PropTypes.oneOfType([
  InvoicePAYGType,
  InvoiceHybridType,
  InvoiceUserType
])

// Not in use currently
export const SchoolBillingSubscriptionType = PropTypes.shape({
  id: PropTypes.number,
  interval: PropTypes.number,
  charge_type: PropTypes.oneOf([
    "stripe",
    "invoice",
    "delinquent",
    "trial_ended"
  ]),
  subscription: PropTypes.shape({}),
  plan: SchoolBillingPlanType
})

export const SubscriptionType = PropTypes.shape({
  id: PropTypes.number,
  canceled_dtime: PropTypes.string,
  cancellation_reason: PropTypes.number,
  card_last_4: PropTypes.string,
  cycle_end_dtime: PropTypes.string,
  cycle_start_dtime: PropTypes.string,
  is_canceled_at_cycle_end: PropTypes.bool,
  is_delinquent: PropTypes.bool,
  paused_until_dtime: PropTypes.string,
  trial_type: PropTypes.number,
  trial_end_dtime: PropTypes.string,
  subscription_plan: SubscriptionPlanType,
  customer: UserType
})
