import React from "react"
import styled from "styled-components"
import {
  FULLSCREEN_LAYER_BASE,
  FULLSCREEN_LAYER_OVERLAY
} from "../fullscreen/Fullscreen"
import Portal from "../portal/Portal"
import ScrollView from "../scroll/ScrollView"
import View from "../ui/View"
import { TERTIARY_GRAY } from "../utils/colors"

const OFFSET = 15
const PADDING = 10
const TRIANGLE = 10

const Container = styled(View.Primary)`
  z-index: ${FULLSCREEN_LAYER_BASE + FULLSCREEN_LAYER_OVERLAY};
  position: absolute;
  left: ${p => p.center}px;
  top: ${p => (p.offsetTop ? p.offsetTop : p.offsetBottom)}px;

  > .View {
    max-width: calc(100vw - 20px);
    min-width: 200px;
    box-shadow: 0 0 24px 0 ${TERTIARY_GRAY}; /* need to cast a slightly broader shadow than View provides */
    padding: ${PADDING}px;
    position: absolute;
    ${p => (p.offsetTop ? `bottom: 0px;` : `top: 0px;`)}
    left: calc(50% + ${p => p.offsetLeft}px);
    transform: translateX(-50%);
    background-color: black;

    &:before {
      content: "";
      position: absolute;
      left: calc(50% - ${TRIANGLE}px);
      width: 0;
      height: 0;
      ${p =>
        p.offsetTop
          ? `
        bottom: -${TRIANGLE}px;
        border-top: ${TRIANGLE}px solid black;
        `
          : `
        top: -${TRIANGLE}px;
        border-bottom: ${TRIANGLE}px solid black;
        `}
      border-left: ${TRIANGLE}px solid transparent;
      border-right: ${TRIANGLE}px solid transparent;
    }

    > .ScrollView {
      margin-left: -${PADDING}px;
      margin-right: -${PADDING}px;
      padding-left: ${PADDING}px;
      padding-right: ${PADDING}px;
      max-height: ${p => p.maxHeight}px;
    }
  }
`

// TODO: break the modal part out into a separate component
const VariableSelectorPicker = ({ rect: inputRect, children }) => {
  const [containerNode, setContainerNode] = React.useState(null)
  // hold onto prevOffesetLeft so as to adjust offsetLeft relative to prevOffesetLeft
  const prevOffesetLeftRef = React.useRef(null)

  let maxHeight = 400
  let center = 0
  let offsetTop = 0
  let offsetLeft = 0
  let offsetRight = 0
  let offsetBottom = 0

  // NOTE: yes, this is executing on each render - unfortunately, need to recalc the containerRect and no good way to observe when it's rect position values change
  if (inputRect && containerNode) {
    // need to calc current bounding rect each render... or maybe not?
    const containerRect = containerNode.getBoundingClientRect()

    const minHeight = 250
    const heightTopSide = inputRect.top - TRIANGLE * 2 - OFFSET
    const heightBottomSide =
      window.innerHeight - inputRect.bottom - TRIANGLE * 2 - OFFSET

    // center the wrapping container based on the left of the inputRect
    center = inputRect.left - inputRect.width / 2

    if (prevOffesetLeftRef.current !== null) {
      // handle offsetLeft, resulting in negative offset if container is offscreen to the left
      if (containerRect.left - prevOffesetLeftRef.current < 0) {
        offsetLeft = Math.abs(containerRect.left - prevOffesetLeftRef.current)
      }

      // handle offsetLeft, resulting in negative offset if container is offscreen to the right
      if (
        containerRect.right >
        prevOffesetLeftRef.current + window.innerWidth
      ) {
        offsetLeft =
          prevOffesetLeftRef.current + window.innerWidth - containerRect.right
      }
    }

    if (heightBottomSide > minHeight) {
      maxHeight = Math.min(heightBottomSide, maxHeight)
      offsetBottom = window.scrollY + inputRect.top + inputRect.height + OFFSET
    } else if (heightTopSide > minHeight) {
      maxHeight = Math.min(heightTopSide, maxHeight)
      offsetTop = window.scrollY + inputRect.top - OFFSET
    }

    prevOffesetLeftRef.current = offsetLeft
  }

  return (
    <Portal portalElementID="fullscreen-portal">
      <Container
        maxHeight={maxHeight}
        center={center}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        offsetRight={offsetRight}
        offsetBottom={offsetBottom}
        border
      >
        <View rounded>
          <ScrollView lockBodyScroll={false} ref={setContainerNode}>
            {children}
          </ScrollView>
        </View>
      </Container>
    </Portal>
  )
}

VariableSelectorPicker.displayName = "VariableSelectorPicker"

export default VariableSelectorPicker
