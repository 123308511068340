const FilterMenu = require("lib/filter-menu/views/filter-menu").default
class FilteredCollectionBehavior extends require("lib/static-shim").default(
  Marionette.Behavior
) {
  static initClass() {
    this.prototype.defaults = {
      scrollTopOnFilter: true,
      filterEvent: null,
      filteringClass: "collection-filtering",
      stateModelKey: "state"
    }
  }

  initialize() {
    let filterEvent
    ;({ filterEvent, filterMenu: this.filterMenu } = this.options)
    this.isInitialLoad = false
    if (filterEvent) {
      this.listenTo(filterEvent.object, filterEvent.event, this.filter)
    }

    // Allow the view to select a label
    if (this.filterMenu) {
      return this.listenTo(this.view, "select:filter", label => {
        return this.menu.select(label)
      })
    }
  }

  onRender() {
    // If we need to auto create the filter menu
    if (this.filterMenu) {
      return this.renderFilterMenu()
    }
  }

  renderFilterMenu() {
    // Create the filter menu and render in the view
    this.view.triggerMethod("before:filter:render")
    this.menu = new FilterMenu({
      model: new Backbone.Model(this.filterMenu.filters())
    })
    this.$(this.filterMenu.uiContainer).html(this.menu.render().el)

    // Set the filtered value to whatever is set on the collection
    if (this.view.collection.filters) {
      this.menu.selectByFilters(this.view.collection.filters)
    }

    // Auto filter the collection and tell the view
    this.listenTo(this.menu, "filter:selected", filter => {
      this.view.triggerMethod("filter:selected", filter)
      return this.filter(filter.get("filters"))
    })

    this.toggleMenuVisibility()
    const { collection } = this.view
    collection.once("sync", this.toggleMenuVisibility)
    return this.view.triggerMethod("filter:render")
  }

  toggleMenuVisibility = () => {
    // if not @isInitialLoad
    this.isInitialLoad = true
    const { collection } = this.view
    return this.menu.$el.toggle(collection.length > 1)
  }

  onDestroy() {
    if (this.menu) {
      return this.menu.destroy()
    }
  }

  filter(filters) {
    this.view.triggerMethod("before:filter")
    const state = this.view[this.options.stateModelKey]
    state.set("filtering", true)
    this.$el.addClass(this.options.filteringClass)
    const { collection } = this.view
    return collection.applyFilter(filters).then(() => {
      if (this.options.scrollTopOnFilter) {
        this.$el.scrollTop(0)
      }
      this.$el.removeClass(this.options.filteringClass)
      this.view.triggerMethod("filter", filters)
      return state.set("filtering", false)
    })
  }
}

FilteredCollectionBehavior.initClass()
export default FilteredCollectionBehavior
