import { default as UIModal } from "@pathwright/ui/src/components/modal/Modal"
import { connect } from "lib/core/store"
import path from "path-browserify"
import "./Modal.css"

const modalClassNames = {
  "/trailer/": "ResourceTrailer__Modal",
  "/register/": "Register__Modal",
  "/license/": "ResourceLicense__Modal",
  "/invite/": "Invite__Modal",
  "/user/": "User__Modal",
  "/help/": "Support__Modal"
}

const mapStateToProps = function(state, ownProps) {
  let className, props
  const {
    modal_launched_from_url,
    prevent_modal_close,
    is_direct_modal,
    url
  } = state.navigationStore.state
  const { navigate } = state.navigationStore.action
  const { defaultRoute } = state.navigationStore.request

  const isDirectModal = is_direct_modal

  let backTo = ownProps.backTo
  if (!backTo) {
    backTo = modal_launched_from_url
      ? modal_launched_from_url
      : isDirectModal
      ? path.join(url, "../")
      : defaultRoute()
  }

  // store ref in case backTo is cleared
  const onCloseBackTo = backTo

  let shouldHideX = prevent_modal_close
  const hideXURLS = ["/manage/", "/join/", "/calendar/"]
  if (
    (() => {
      const result = []
      for (let hideURL of Array.from(hideXURLS)) {
        if (url.indexOf(hideURL) > -1) {
          result.push(hideURL)
        }
      }
      return result
    })().length
  ) {
    shouldHideX = true
  }

  if (shouldHideX) {
    backTo = null
  }

  const handleClose = function() {
    if (prevent_modal_close) {
      return
    } else if (ownProps.onClose) {
      return ownProps.onClose()
    } else {
      return navigate(onCloseBackTo)
    }
  }

  // get className based on route. Hacky but simplest solution
  for (let k in modalClassNames) {
    const v = modalClassNames[k]
    if (url.indexOf(k) > -1) {
      className = v
    }
  }

  return (props = _.assign(
    {},
    {
      modal_launched_from_url,
      prevent_modal_close,
      onClose: handleClose,
      className
    },
    ownProps,
    { backTo }
  ))
}

export default connect(UIModal, "navigation", mapStateToProps, [
  "modal_launched_from_url",
  "prevent_modal_close"
])
