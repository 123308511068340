import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const StyledSchoolInactive = styled.div`
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SchoolInactive = () => {
  const { school } = usePathwrightContext()
  return (
    <StyledSchoolInactive>
      <ThemeBackground />
      <BlankSlate
        inverted
        heading={`${school.name} is not available at this time.`}
        body={`Please contact ${school.name} with any questions.`}
        primaryAction={{
          children: `Email ${school.email}`,
          onClick: () => window.open(`mailto:${school.email}`)
        }}
      />
    </StyledSchoolInactive>
  )
}

SchoolInactive.displayName = "SchoolInactive"

export default SchoolInactive
