// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let FlashMessageBehavior
const APIUtils = require("global/api-utils").default

export default FlashMessageBehavior = (function() {
  FlashMessageBehavior = class FlashMessageBehavior extends require("lib/static-shim").default(
    Marionette.Behavior
  ) {
    static initClass() {
      this.prototype.defaults = { flashEl: ".pw-flash-message-container" }
    }

    onRender() {
      return (this.uiFlash = this.$(this.options.flashEl))
    }

    onFlashApiError(resp) {
      // This assumes and api response with error messagein in a
      // details array
      const messages = new APIUtils().getErrorDetails(resp)
      this.triggerMethod("clear:flash:messages")
      return (() => {
        const result = []
        for (let m of Array.from(messages)) {
          if (m.name != null) {
            m.field = m.name
          }
          result.push(this.triggerMethod("flash", m.message, "error", m.field))
        }
        return result
      })()
    }

    onFlash(message, type, field, clear) {
      if (field == null) {
        field = null
      }
      if (clear == null) {
        clear = true
      }
      if (clear) {
        this.onClearFlashMessages()
      }
      // Shows a message on the form, type can be success or error
      const mcontext = {
        message,
        type,
        field
      }
      message = window.App.utils.renderString(
        `\
<div class="pw-flash-message alert alert-{{type}}">
  {% if field %}<strong>{{ field }}: </strong>{% endif %}
  <span>{{message|safe}}</span>
  <a href="#" class="pwai-cancel" data-dismiss="alert"></a>
</div>\
`,
        mcontext
      )
      this.uiFlash.append(message)
      return this.uiFlash.show()
    }

    onClearFlashMessages() {
      return this.uiFlash.html("")
    }
  }
  FlashMessageBehavior.initClass()
  return FlashMessageBehavior
})()
