import { graphql } from "@apollo/client/react/hoc"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Card from "@pathwright/ui/src/components/card/Card"
import Link from "@pathwright/ui/src/components/link/Link"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import SortableList from "@pathwright/ui/src/components/sortable/SortableList"
import compose from "lodash/flowRight"
import styled from "styled-components"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import { withContextAsProp } from "../../utils/component"
import Banner from "../display/Banner"
import BANNERS_QUERY from "../graphql/banners-query"
import BannersQuery from "../graphql/BannersQuery"
import UPDATE_BANNER_ORDER_MUTATION from "../graphql/update-banner-order-mutation"

const StyledSortableList = styled(SortableList)`
  .SortablePreview {
    width: 100%;
    max-width: 600px;
  }
`

const BannerLink = styled(Link)`
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 600px;

  .Banner {
    min-height: 100%;
  }
`

const BannerLinkHandle = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .IconButton {
    cursor: move;
  }

  ${props => (props.hide ? "display: none;" : "")}
`

const BannerLinkArrow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 75px;
  padding: 10px;
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.4) 75%);

  .Pathicon {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
  }
`

const BannerListCard = ({ card, updateBannerOrder }) => (
  <Card
    title="Banners"
    completeAction={{
      to: `${card.path}new/`,
      childre: "New Banner"
    }}
    card={card}
  >
    <BannersQuery manage>
      {({ loading, banners }) =>
        loading ? (
          <LoadingCircle />
        ) : banners && banners.length > 0 ? (
          <ScrollView style={{ minHeight: 400 }} fill={true}>
            <StyledSortableList
              list={banners}
              deferDragHandlingToItem
              disableInteractiveElementBlocking
              onChange={reorderedBanners => {
                const bannerOrders = []
                for (let index = 0; index < reorderedBanners.length; index++) {
                  const banner = reorderedBanners[index]
                  const order = index + 1
                  if (banner.order !== order) {
                    bannerOrders.push({
                      id: banner.id,
                      order
                    })
                  }
                }
                bannerOrders.length && updateBannerOrder(bannerOrders)
              }}
              renderItem={({ item: banner, dragHandleProps }) => {
                return (
                  <BannerLink to={`${card.path}${banner.id}/`}>
                    <Banner {...banner} />
                    <BannerLinkHandle
                      {...dragHandleProps}
                      hide={banners.length <= 1}
                    >
                      <IconButton
                        icon="arrow-double-vertical"
                        inverted={true}
                      />
                    </BannerLinkHandle>
                    <BannerLinkArrow>
                      <Pathicon icon="chevron-right" />
                    </BannerLinkArrow>
                  </BannerLink>
                )
              }}
            />
          </ScrollView>
        ) : (
          <BlankSlate
            heading="Your school has no banners"
            body="Add a banner above"
          />
        )
      }
    </BannersQuery>
  </Card>
)

BannerListCard.displayName = "BannerListCard"

export default compose(
  withContextAsProp(PathwrightContext, "schoolId", "school.id"),
  graphql(UPDATE_BANNER_ORDER_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      updateBannerOrder: bannerOrders =>
        mutate({
          variables: {
            banner_orders: bannerOrders
          },
          update: cache => {
            const queries = [
              {
                query: BANNERS_QUERY,
                variables: {
                  first: 50,
                  school_id: ownProps.schoolId,
                  manage: true
                }
              },
              {
                query: BANNERS_QUERY,
                variables: {
                  first: 50,
                  school_id: ownProps.schoolId,
                  manage: false
                }
              }
            ]

            queries.forEach(({ query, variables }) => {
              try {
                const data = cache.readQuery({ query, variables })

                const nextData = {
                  context: {
                    ...data.context,
                    school: {
                      ...data.context.school,
                      banners: {
                        ...data.context.school.banners,
                        // Update order of banners
                        edges: data.context.school.banners.edges
                          .map(edge => {
                            const banner = bannerOrders.find(
                              bannerOrder => bannerOrder.id === edge.node.id
                            )

                            const newOrder = banner && banner.order
                            // Update this banner's order
                            edge.node.order = isNaN(parseInt(newOrder))
                              ? edge.node.order
                              : newOrder

                            return edge
                          })
                          // Sort banners based on new orders
                          .sort(({ node: bannerA }, { node: bannerB }) => {
                            return bannerA.order - bannerB.order
                          })
                      }
                    }
                  }
                }
                cache.writeQuery({ query, variables, data: nextData })
              } catch (error) {
                // Possible that the query is not present in the cache, and that's OK
                console.log(
                  "Error updating cache after UPDATE_BANNER_ORDER_MUTATION",
                  error
                )
              }
            })
          }
        })
    })
  })
)(BannerListCard)
