import Gate from "@pathwright/ui/src/components/gate/Gate"
import Gates from "@pathwright/ui/src/components/gate/Gates"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { SIGN_IN, SIGN_UP } from "../../auth/constants"
import SignUpOrIn from "../../auth/SignUpOrIn"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const RedeemSchoolGiftSubscriptionAuthGateOld = (props) => {
  const { me, school } = usePathwrightContext()
  const { t } = useTranslate()

  return me ? (
    <SignUpOrIn />
  ) : (
    <Gates heading="Sign in or create an account">
      <Gate
        line1={"I have an account"}
        line2={"Sign in to redeem your gift subscription."}
        gate={() => <SignUpOrIn initialView={SIGN_IN} />}
      />
      <Gate
        line1={"I don't have an account"}
        line2={t(`registration.auth_gate.create_an_account`, {
          school_name: school.name
        })}
        gate={() => <SignUpOrIn initialView={SIGN_UP} />}
      />
    </Gates>
  )
}

RedeemSchoolGiftSubscriptionAuthGateOld.displayName =
  "RedeemSchoolGiftSubscriptionAuthGateOld"

export default RedeemSchoolGiftSubscriptionAuthGateOld
