import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { getDescriptionForActions, getActingUser } from "./utils"
import { getStoreResourceUrl, getGroupUrl, getStepUrl } from "../utils/urls"
import {
  SECONDARY_WHITE,
  BORDER_WHITE
} from "@pathwright/ui/src/components/utils/colors"

import Link from "@pathwright/ui/src/components/link/Link"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { LEARNING_ACTION_SKIP_STEP } from "./constants"

const StyledHeading = styled.h4`
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
`

const StyledLink = styled(Link)`
  color: white;
  border-bottom: 1px solid ${BORDER_WHITE};
  &:hover {
    color: white;
    border-bottom-color: ${SECONDARY_WHITE};
  }
`

export const ResourceLink = ({ resource }) => {
  // resource might've been archived.
  if (!resource) {
    return null
  }

  return (
    <StyledLink to={getStoreResourceUrl(resource.slug)}>
      {resource.name}
    </StyledLink>
  )
}

export const GroupLink = ({ resource, group }) => {
  // resource or group might've been archived.
  if (!resource || !group) {
    return null
  }

  return (
    <StyledLink to={getGroupUrl(resource.slug, group.id)}>
      {group.name}
    </StyledLink>
  )
}

export const StepLink = ({ resource, group, step }) => {
  // resource, group, or step might've been archived.
  if (!resource || !group || !step) {
    return null
  }

  return (
    <StyledLink to={getStepUrl(resource.slug, group.id, step.source_id)}>
      {step.name}
    </StyledLink>
  )
}

const ActivityDescription = ({ actions }) => {
  const { me } = usePathwrightContext()
  const description = getDescriptionForActions(actions)
  const actingUser = getActingUser(actions[0])

  return (
    <StyledHeading>
      <strong>
        {me && me.id === actingUser.id ? "You" : actingUser.display_name}
      </strong>
      &nbsp;
      {description}
    </StyledHeading>
  )
}

ActivityDescription.displayName = "ActivityDescription"

ActivityDescription.propTypes = {
  actions: PropTypes.array.isRequired
}

export default ActivityDescription
