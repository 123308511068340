const Storage = require("lib/utils/store").default
const User = require("./models/user").default

export default Pathwright.module("Auth", function(
  Auth,
  App,
  Backbone,
  Marionette,
  $,
  _
) {
  this.startWithParent = false

  Auth.nextURL = null

  // Note: This MUST be before the authStore init because Session relies on it.
  // TODO: depricate this whole module in favor of auth stores
  const storage = new Storage()
  window.App.commands.setHandler("set:auth_token", token =>
    storage.set("auth_token", token)
  )

  window.App.commands.setHandler("clear:auth_token", () =>
    storage.remove("auth_token")
  )

  window.App.reqres.setHandler("get:auth_token", () =>
    storage.get("auth_token")
  )

  // init our store
  const authStore = window.App.stores.requestStore("auth")

  window.App.reqres.setHandler("get:session", () => authStore.get("session"))

  window.App.reqres.setHandler("get:user", function() {
    // Guarantees a User model
    const session = authStore.get("session")
    const user = session.get("user")
    if (user != null) {
      if (user && !(user instanceof User)) {
        return new User(user)
      } else {
        return user
      }
    } else {
      // TODO: fix this to be an anonymous user?
      return new User()
    }
  })

  return window.App.vent.on("session:unauthenticated", function(session) {
    window.userData = null
    if (window.bootstrappedData != null) {
      window.bootstrappedData.user = null
    }
    return delete window.userData
  })
})
