// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let ConnectStoreBehavior
export default (ConnectStoreBehavior = (function() {
  ConnectStoreBehavior = class ConnectStoreBehavior extends require("lib/static-shim").default(
    Marionette.Behavior
  ) {
    static initClass() {
      ;`\
Connects a BackboneStore to a view\
`

      this.prototype.defaults = {
        stores: [],
        store: null, // a string (indicating a reqr request) or a function
        storePropName: null, // the property to assign the store to on the view
        events: {},
        disposeOnDestroy: false
      }
    }

    initialize() {
      this._connectedStores = []
      if (!this.options.stores.length) {
        let disposeOnDestroy, events, store, storePropName
        this.options.stores = [
          (({ store, storePropName, events, disposeOnDestroy } = this.options))
        ]
      }
      return this._connectStores()
    }

    onDestroy() {
      return (() => {
        const result = []
        for (let storeOptions of Array.from(this.options.stores)) {
          const store = this.view[storeOptions.storePropName]
          if (storeOptions.disposeOnDestroy) {
            window.App.stores.destroyStore(store._key)
          }
          result.push(delete this.view[storeOptions.storePropName])
        }
        return result
      })()
    }

    _connectStores() {
      return Array.from(this.options.stores).map(storeOptions =>
        this._connectStore(storeOptions)
      )
    }

    _connectStore(storeOptions) {
      let store = null
      if (_.isFunction(storeOptions.store)) {
        store = _.bind(storeOptions.store, this.view)()
      } else if (_.isString(storeOptions.store)) {
        store = window.App.stores.requestStore(storeOptions.store)
      }

      if (!storeOptions.storePropName) {
        storeOptions.storePropName = `${store._storeKey}Store`
      }

      if (store) {
        if (!this.view[storeOptions.storePropName]) {
          this.view[storeOptions.storePropName] = store
        } else {
          throw new Error(
            `ConnectStoreBehavior: Store with storePropName ${
              storeOptions.storePropName
            } already exists on view (${this.view.cid}).`
          )
        }
      } else {
        throw new Error(
          `ConnectStoreBehavior: Could not connect store: ${store}. Make sure it is a registered store`,
          this.view,
          window.App.stores.request.registeredStoreKeys()
        )
      }

      this._initStoreEvents(store, storeOptions)
      return this._connectedStores.push()
    }

    _initStoreEvents(store, storeOptions) {
      return (() => {
        const result = []
        for (let e in storeOptions.events) {
          let handler = storeOptions.events[e]
          if (_.isString(handler)) {
            handler = this.view[handler]
          }
          if (!_.isFunction(handler)) {
            console.error(
              `ConnectStoreBehavior: event handlers must be a string or a function. Event handler, \"${handler}\" was not found.`
            )
          }
          result.push(this.view.listenTo(store, e, _.bind(handler, this.view)))
        }
        return result
      })()
    }
  }
  ConnectStoreBehavior.initClass()
  return ConnectStoreBehavior
})())
