import React from "react"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import { useSchoolSubscriptionPlans } from "../../../subscription/manage/SchoolSubscriptionPlansCheckboxes"

const SubscriptionPlanDiscountsCardLink = ({ card }) => {
  const { schoolSubscriptionPlans } = useSchoolSubscriptionPlans({
    intervalFilter: { eq: "monthly" }
  })

  return schoolSubscriptionPlans.length ? (
    <CardLink
      to={`${card.path}subscriptions/`}
      icon="tag"
      label="Create monthly subscription plan discounts"
      meta="Create discount codes for monthly subscription plans"
    />
  ) : null
}

const ManageDiscounts = ({ card }) => (
  <Card card={card} title="Manage Discounts">
    <CardLink
      to={`${card.path}resources/`}
      icon="tag"
      label="Create course discount codes"
      meta="Create a one-off or set of discount codes for courses"
    />
    <SubscriptionPlanDiscountsCardLink card={card} />
  </Card>
)

ManageDiscounts.displayName = "ManageDiscounts"

export default ManageDiscounts
