import gql from "graphql-tag"
import Page from "./page-fragment"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  query PageQuery($id: Int, $page_type: Int, $slug: String, $is_live: Boolean) {
    school {
      ...School
      page(id: $id, page_type: $page_type, slug: $slug, is_live: $is_live) {
        ...Page
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${Page}
`
