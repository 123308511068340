import { graphql } from "@apollo/client/react/hoc"
import { withTranslate } from "@pathwright/ui/src/components/"
import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import get from "lodash/get"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { getSchoolUrl } from "../utils/urls"
import { PAGE_TYPE_TERMS } from "./constants"
import PAGE_QUERY from "./graphql/page-query"

const StyledTermsAgreement = styled.div`
  padding-bottom: 10px;
  text-align: center;
  color: ${p => (p.inverted ? "white" : "inherit")};
`

const TermsAgreement = ({
  inverted,
  buttonLabel,
  consentFieldProps,
  terms_page,
  loading,
  error,
  style,
  t,
  Trans
}) => {
  const { school } = usePathwrightContext()
  const theme = useUIThemeContext()

  const termsLinkProps = {
    target: "_blank",
    rel: "noopener noreferrer",
    style: {
      color: inverted ? "white" : theme.primaryBrandColor
    }
  }

  return (
    <StyledTermsAgreement
      className="TermsAgreement"
      style={style}
      inverted={inverted}
    >
      <div>
        {terms_page ? (
          // prettier-ignore
          // Trans formatting integral to translation json!
          <Trans i18nKey="account.terms_agreement_with_school_terms">
            {"By clicking the "}
            {{ button_label: buttonLabel ? `“${buttonLabel}” ` : "" }}
            {"button below you agree to "}
            {{ school_name_possessive: `${school.name}'s ` }}
            <a {...termsLinkProps} href={terms_page.external_url || getSchoolUrl(`p/${terms_page.slug}/`)}>
              {{ school_terms_of_use: terms_page.name }}
            </a>
            {" and the platform "}
            <a
              {...termsLinkProps}
              href="https://www.pathwright.com/privacy-policy/"
            >
              {{ pathwright_privacy_policy: t(`account.privacy_policy`) }}
            </a>
            {" & "}
            <a
              {...termsLinkProps}
              href="https://www.pathwright.com/terms-of-use/"
            >
              {{ pathwright_terms_of_use: t(`account.terms_of_use`) }}
            </a>
            {"."}
          </Trans>
        ) : (
          // prettier-ignore
          // Trans formatting integral to translation json!
          <Trans i18nKey="account.terms_agreement">
            {"By clicking the "}
            {{ button_label: buttonLabel ? `“${buttonLabel}” ` : ""}}
            {"button below you agree to the "}
            <a
              {...termsLinkProps}
              href="https://www.pathwright.com/privacy-policy/"
            >
              {{ pathwright_privacy_policy: t(`account.privacy_policy`) }}
            </a>
            {" & "}
            <a
              {...termsLinkProps}
              href="https://www.pathwright.com/terms-of-use/"
            >
              {{ pathwright_terms_of_use: t(`account.terms_of_use`) }}
            </a>
            {"."}
          </Trans>
        )}
      </div>
    </StyledTermsAgreement>
  )
}

TermsAgreement.displayName = "TermsAgreement"

export default withTranslate(
  graphql(PAGE_QUERY, {
    options: () => ({
      variables: {
        page_type: PAGE_TYPE_TERMS,
        is_live: true
      }
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      terms_page: get(data, "school.page")
    })
  })(TermsAgreement)
)
