import React from "react"
import { TagManagerCard } from "../../tag/TagManagerContainer"
import DiscussionTagManager from "./DiscussionTagManager"
import useDiscussionTagContextLabels from "./useDiscussionTagContextLabels"

// HACK? Removing the managePrompt as a means of *not* showing
// the TagManagerPrompt.
const _useDiscussionTagContextLabels = (...args) => {
  const labels = useDiscussionTagContextLabels(...args)
  delete labels.managePrompt
  return labels
}

const DiscussionTagManagerCard = ({ card, context }) => {
  return (
    <TagManagerCard
      card={card}
      context={context}
      useContextLabels={_useDiscussionTagContextLabels}
      CustomTagManager={DiscussionTagManager}
    />
  )
}

DiscussionTagManagerCard.displayName = "DiscussionTagManagerCard"

export default DiscussionTagManagerCard
