import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import Button from "../../button/Button"
import useControlledState from "../../hooks/useControlledState"
import { useTranslate } from "../../lng/withTranslate"
import Pathicon from "../../pathicon/Pathicon"
import { tagPropType } from "../propTypes"
import Tag from "../Tag"
import {
  getTagNameAlreadyExists,
  getTagNameAlreadySelected,
  useDefaultTag
} from "../utils"
import TagForm from "./TagForm"

// Rather than managing overflow toggle via state, using a keyframe
// triggered when CreateTag is expanded. We must make overflow visible
// after TagForm is transitioned in so that elements that overflow the
// Container (looking at you ColorPicker) do not get clipped.
const overflow = keyframes`
  0% { overflow: hidden; }
  99% { overflow: hidden; }
  100% { overflow: visible; }
`

// forwards keeps the final animation state applied.
const transitionOverflow = css`
  animation: ${overflow} 100ms forwards;
`

const Container = styled.div`
  overflow: hidden;
  ${p => p.expanded && transitionOverflow}

  &,
  > .TagForm,
  > .Button {
    border-radius: inherit;
  }

  .TagForm {
    position: relative;
    transition: right 100ms ease, opacity 100ms ease;
    right: ${p => (p.expanded ? 0 : "-100%")};
    opacity: ${p => (p.expanded ? 1 : 0)};
  }
`

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 4px;
  width: 100%;

  .Button__content-wrapper {
    width: 100%;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      max-width: 100%;
      overflow: hidden;
    }
  }
`

const CreateTagButton = ({ tag, label, ...buttonProps }) => (
  <StyledButton styleType="secondary" {...buttonProps}>
    <div>
      <b>{label}:</b> <Tag tag={tag} />
    </div>{" "}
    <Pathicon icon="chevron-right" />
  </StyledButton>
)

const CreateTagButtonContainer = ({ tags, tag, onExpand, onSubmit }) => {
  const { t } = useTranslate()

  const alreadyExists = getTagNameAlreadyExists(tag.name, tags)
  const alreadySelected = getTagNameAlreadySelected(tag.name, tags)

  if (alreadyExists && !alreadySelected) {
    return (
      <CreateTagButton
        tag={tag}
        label={t("Add")}
        onClick={() => onSubmit(tag)}
      />
    )
  }

  return (
    <CreateTagButton
      tag={tag}
      label={t("Create")}
      onClick={onExpand}
      disabled={alreadyExists}
    />
  )
}

const CreateTag = React.forwardRef(
  (
    {
      className,
      tag: tagProp,
      tags,
      tagFormLabels,
      expanded: expandedProp,
      onSubmit,
      onChange,
      onToggleExpaneded
    },
    ref
  ) => {
    const [expanded, setExpanded] = useControlledState(
      expandedProp,
      onToggleExpaneded
    )

    // Necessary for transitioning TagForm postition (to creat slide-in effect).
    const [expandedEffect, setExpandedEffect] = useState(false)

    // The next tick after expanded has changed, update expandedEffect to match.
    useEffect(() => {
      if (expandedEffect !== expanded) setExpandedEffect(expanded)
    }, [expanded])

    const tag = useDefaultTag(tagProp, tags)

    // When tag.name already exists but the TagForm is in use,
    // collapse, replacing TagForm with CreateTagButtonContainer which
    // will properly prompt the user to "Add" the tag.
    useEffect(() => {
      if (getTagNameAlreadyExists(tag.name, tags) && expanded) {
        setExpanded(false)
      }
    }, [tag.name])

    return (
      <Container
        ref={ref}
        role="application"
        aria-expanded={expanded}
        className={classnames("CreateTag", className)}
        expanded={expandedEffect}
      >
        {expanded ? (
          <TagForm
            tag={tag}
            tags={tags}
            labels={tagFormLabels}
            onSubmit={onSubmit}
            onChange={onChange}
            onClose={() => setExpanded(false)}
          />
        ) : (
          <CreateTagButtonContainer
            tags={tags}
            tag={tag}
            onExpand={() => setExpanded(true)}
            onSubmit={onSubmit}
          />
        )}
      </Container>
    )
  }
)

CreateTag.displayName = "CreateTag"

CreateTag.propTypes = {
  tag: tagPropType,
  tagFormLabels: TagForm.propTypes.labels,
  expanded: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggleExpaneded: PropTypes.func
}

CreateTag.defaultProps = {
  expanded: false
}

export default CreateTag
