/**
 * From: https://gist.github.com/mikelehen/3596a30bd69384624c11
 * Fancy ID generator that creates 20-character string identifiers with the following properties:
 *
 * 1. They're based on timestamp so that they sort *after* any existing ids.
 * 2. They contain 72-bits of random data after the timestamp so that IDs won't collide with other clients' IDs.
 * 3. They sort *lexicographically* (so the timestamp is converted to characters that will sort properly).
 * 4. They're monotonically increasing.  Even if you generate more than one in the same timestamp, the
 *    latter ones will sort after the former ones.  We do this by using the previous random bits
 *    but "incrementing" them by 1 (only in the case of a timestamp collision).
 */
export const createID = (): string => {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz"

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars = Array<number>(12)

  var now = new Date().getTime()
  var duplicateTime = now === lastPushTime
  lastPushTime = now

  var timeStampChars = new Array(8)
  for (var i = 7; i >= 0; i--) {
    timeStampChars[i] = PUSH_CHARS.charAt(now % 64)
    // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
    now = Math.floor(now / 64)
  }
  if (now !== 0)
    throw new Error("We should have converted the entire timestamp.")

  var id = timeStampChars.join("")

  if (!duplicateTime) {
    for (i = 0; i < 12; i++) {
      lastRandChars[i] = Math.floor(Math.random() * 64)
    }
  } else {
    // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
    for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
      lastRandChars[i] = 0
    }
    lastRandChars[i]++
  }
  for (i = 0; i < 12; i++) {
    id += PUSH_CHARS.charAt(lastRandChars[i])
  }
  if (id.length !== 20) throw new Error("Length should be 20.")

  return id
}

// Default file name length
const MAX_NAME_LENGTH = 20

export const clampString = (
  n: string,
  options: { isFile?: boolean; len?: number } = {}
): string => {
  const { isFile = true, len = MAX_NAME_LENGTH } = options
  if (!isFile) {
    return n.length > len
      ? // ? n.substr(0, len - 4) + "..." + n.substr(len - 3, n.length)
        n.substr(0, len - 4) + "..."
      : n
  }

  const FINAL_LETTER_COUNT = 3
  const ext =
    n.lastIndexOf(".") !== -1
      ? n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase()
      : ""
  let filename = n.replace("." + ext, "")

  if (filename.length <= len) return n

  const returned =
    filename.substr(0, len - FINAL_LETTER_COUNT) +
    (n.length > len ? "[...]" : "") +
    filename.substr(filename.length - FINAL_LETTER_COUNT, filename.length - 1)

  return `${returned}${ext ? "." + ext : ""}`
}

// https://ourcodeworld.com/articles/read/376/how-to-strip-html-from-a-string-extract-only-text-content-in-javascript
export const removeHTML = (html: string) => {
  // Create a new div element
  const tempDivElement = window.document.createElement("div")
  // Set the HTML content with the providen
  tempDivElement.innerHTML = html
  // Retrieve the text property of the element (cross-browser support)
  return tempDivElement.textContent || tempDivElement.innerText || ""
}

export const isIPhone = () => {
  return !!window.navigator.platform && /iPhone/.test(window.navigator.platform)
}

const toDataURL = (url: string) =>
  window
    .fetch(url)
    .then((response) => {
      return response.blob()
    })
    .then((blob) => {
      return URL.createObjectURL(blob)
    })

export const download = async (filename: string, src: string) => {
  const element = document.createElement("a")
  // element.href = src
  element.href = await toDataURL(src)
  element.download = filename
  element.style.display = "none"

  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

// TODO: implement with Chakra breakpoint values
export const isMobile = (): false => false

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export const humanFileSize = (bytes: number, si?: boolean) => {
  var thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }
  var units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  var u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + " " + units[u]
}
