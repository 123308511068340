import "./Gates.css"
import classnames from "classnames"
import React, { useState, useContext } from "react"
import isFunction from "lodash/isFunction"
import Button from "../button/Button"
import Pathicon from "../pathicon/Pathicon"
import { withTranslate } from "@pathwright/ui/src/components/"

export const GatesContext = React.createContext()

export const useGatesContext = () => useContext(GatesContext)

const Gates = ({ children, heading, className, t }) => {
  const [SelectedGate, setSelectedGate] = useState(null)

  if (SelectedGate) {
    return (
      <div className={classnames("Gates__selected", className)}>
        <Button
          styleType="blank"
          brand
          onClick={e => {
            setSelectedGate(null)
          }}
        >
          <Pathicon icon="arrow-left" />
          {t("gates.back")}
        </Button>
        {isFunction(SelectedGate) ? <SelectedGate /> : SelectedGate}
      </div>
    )
  }

  return (
    <GatesContext.Provider
      value={{
        setSelectedGate
      }}
    >
      <div className={classnames("Gates", className)}>
        {heading}
        {children && <div className="Gates__wrapper">{children}</div>}
      </div>
    </GatesContext.Provider>
  )
}

Gates.displayName = "Gates"

export default withTranslate(Gates)
