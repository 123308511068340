import { graphql } from "@apollo/client/react/hoc"
import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import {
  DANGEROUS_RED,
  TERTIARY_GRAY
} from "@pathwright/ui/src/components/utils/colors"
import compose from "lodash/flowRight"
import get from "lodash/get"
import pluralize from "pluralize"
import { Component } from "react"
import styled from "styled-components"
import ACCOUNT_QUERY from "../../account/graphql/account-query"
import REMOVE_INACTIVE_MEMBER_ACCESS_MUTATION from "../../membership/graphql/remove-inactive-member-access-mutation"
import TOTAL_MEMBERS_WITH_FILTER_QUERY from "../../membership/graphql/total-members-with-filters-query"
import { PathwrightContext } from "../../pathwright/PathwrightContext"

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${TERTIARY_GRAY};
  background: whitesmoke;

  &:first-of-type {
    margin-top: 15px;
    border-radius: 5px 5px 0 0;
  }

  &:last-of-type {
    border-bottom: none;
    border-radius: 0 0 5px 5px;
  }
`

const RemoveAccessConfirmWrapper = styled.div`
  .BlankSlate__action-container {
    flex-direction: column;
    align-items: center;
  }
`

const RemoveAccessConfirm = ({ totalMembersInactive }) => {
  return (
    <RemoveAccessConfirmWrapper className="RemoveAccessConfirm">
      <Pathicon
        icon="caution-triangle"
        style={{ margin: "0.5rem 0", fontSize: "1.8rem" }}
      />
      <p style={{ fontSize: "1.2em", margin: "0" }}>
        <strong>
          Remove access for {totalMembersInactive}{" "}
          {pluralize("member", totalMembersInactive)}?
        </strong>
      </p>
      <div
        style={{ marginBottom: "1rem", padding: "0 1.5rem", textAlign: "left" }}
      >
        <p>Once a member's access is removed, they will no longer:</p>
        <ul style={{ textAlign: "left", paddingLeft: "18px" }}>
          <li>Receive any email or in-app notifications.</li>
          <li>Appear in cohorts, groups, or elsewhere.</li>
          <li>Keep progress or other data for more than 12 months.</li>
        </ul>
        <Button
          style={{ marginTop: "1rem" }}
          styleType="text"
          target="_blank"
          href="http://help.pathwright.com/en/articles/2883404-configure-billing-settings-for-your-pathwright-account#how-to-reduce-fees-for-additional-members"
        >
          Learn more about removing access
          <Pathicon style={{ marginLeft: "3px" }} icon="chevron-right" />
        </Button>
      </div>
    </RemoveAccessConfirmWrapper>
  )
}

const RemoveAccessConfirmedWrapper = styled.div`
  h2 {
    max-width: 400px;
    margin: 0 auto 1rem;
    color: white;
    font-size: 1.5em;
    line-height: 1.2;
  }

  .RemoveAccessConfirmed__link {
    margin: 0 auto;

    .Button {
      color: white;
      text-decoration: underline !important;

      .Button__content-wrapper {
        white-space: pre-wrap;
      }
    }
  }
`

const RemoveAccessConfirmed = ({ totalMembersInactive }) => {
  return (
    <RemoveAccessConfirmedWrapper>
      <Text.H2>
        Access removed for {totalMembersInactive}{" "}
        {pluralize("member", totalMembersInactive)}.
      </Text.H2>
      <Text.Body className="RemoveAccessConfirmed__link">
        <Button
          styleType="link"
          target="_blank"
          href="http://help.pathwright.com/en/articles/2883404-configure-billing-settings-for-your-pathwright-account#how-to-reduce-fees-for-additional-members"
        >
          Here's how to automatically or manually restore access for members in
          the future.
        </Button>
      </Text.Body>
    </RemoveAccessConfirmedWrapper>
  )
}

class RemoveAccessForInactiveMembers extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      typeof this.props.totalMembersWithAccess === "number" &&
      this.props.totalMembersWithAccess !== nextProps.totalMembersWithAccess
    ) {
      this.props.refetch()
    }
  }

  render() {
    const {
      loading,
      daysInactive,
      totalMembersInactive,
      removeAccessForInactiveMembers
    } = this.props

    return loading ? null : (
      <OptionWrapper>
        <Text.Body>
          {pluralize("member", totalMembersInactive, true)}{" "}
          {totalMembersInactive === 1 ? "hasn't" : "haven't"} been seen in{" "}
          <strong>{daysInactive} days</strong>{" "}
        </Text.Body>
        {totalMembersInactive > 0 && (
          <FullscreenConfirm
            heading="Confirm access removal"
            stack
            body={
              <RemoveAccessConfirm
                totalMembersInactive={totalMembersInactive}
              />
            }
            //   this.context.school.grant_access_upon_authentication
            //     ? "Members who have their access turned off will have access restored when they next attempt to sign-in."
            //     : "Members who have their access turned off won't be able to sign-in or access any courses from your account."
            // }
            cancelPrompt="Never mind, go back"
            confirmPrompt={`Remove access for ${pluralize(
              "member",
              totalMembersInactive,
              true
            )}`}
            confirmProps={{
              color: DANGEROUS_RED,
              style: { borderRadius: "5px" }
            }}
            // confirmingStatus="Turning off access..."
            confirmedBody={
              <RemoveAccessConfirmed
                totalMembersInactive={totalMembersInactive}
              />
            }
            onConfirm={removeAccessForInactiveMembers}
          >
            {({ confirm }) => (
              <Button styleType="tertiary" onClick={confirm}>
                <Pathicon icon="minus-circle" /> Remove access
              </Button>
            )}
          </FullscreenConfirm>
        )}
      </OptionWrapper>
    )
  }
}

RemoveAccessForInactiveMembers.contextType = PathwrightContext

const RemoveAccessForInactiveMembersContainer = compose(
  graphql(TOTAL_MEMBERS_WITH_FILTER_QUERY, {
    options: ({ daysInactive }) => ({
      variables: {
        daysInactive,
        hasAccess: true,
        role_filter: { eq: "student" }
      }
    }),
    props: ({ data }) => {
      const { error, loading, refetch, school } = data
      return {
        error,
        loading,
        refetch,
        totalMembersInactive: get(school, "members.total")
      }
    }
  }),
  graphql(REMOVE_INACTIVE_MEMBER_ACCESS_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      removeAccessForInactiveMembers: daysInactive =>
        mutate({
          variables: {
            daysInactive: ownProps.daysInactive
          }
        }).then(() => {
          ownProps.onRemoveAccess()
          ownProps.refetch()
        })
    })
  })
)(RemoveAccessForInactiveMembers)

const VALID_DAYS_INACTIVE_VALUES = [30, 60, 90, 180, 360]

const ManageBillingAccessRemove = ({
  card,
  account,
  loading,
  refetch,
  totalMembersWithAccess
}) =>
  loading ? (
    <LoadingCircle />
  ) : (
    <Card card={card} title="Remove member access">
      {account.is_on_legacy_plan || !account.billing_subscription ? (
        <CardBlock>
          <Text.Body>You are on a legacy plan.</Text.Body>
        </CardBlock>
      ) : (
        <CardBlock>
          {totalMembersWithAccess > 0 ? (
            <Text.Body>
              {totalMembersWithAccess}{" "}
              {pluralize("member", totalMembersWithAccess)} have access to your
              account. If you'd like to remove access for members who no longer
              need it (and reduce any extra learner fees), the options below are
              the quickest way to do so.
              <Button
                style={{ marginTop: "1rem" }}
                styleType="text"
                target="_blank"
                href="http://help.pathwright.com/en/articles/2883404-configure-billing-settings-for-your-pathwright-account#how-to-reduce-fees-for-additional-members"
              >
                Learn more about what happens when access is removed
                <Pathicon style={{ marginLeft: "3px" }} icon="chevron-right" />
              </Button>
            </Text.Body>
          ) : (
            <Text.Body>
              You currently have <strong>{totalMembersWithAccess}</strong>{" "}
              {pluralize("member", totalMembersWithAccess)} with access to your
              account.
            </Text.Body>
          )}
          {totalMembersWithAccess > 0 &&
            VALID_DAYS_INACTIVE_VALUES.map(daysInactive => (
              <RemoveAccessForInactiveMembersContainer
                daysInactive={daysInactive}
                onRemoveAccess={refetch}
                totalMembersWithAccess={totalMembersWithAccess}
              />
            ))}
        </CardBlock>
      )}
    </Card>
  )

ManageBillingAccessRemove.displayName = "ManageBillingAccessRemove"

export default compose(
  graphql(ACCOUNT_QUERY, {
    props: ({ data }) => {
      const { error, loading, account } = data
      return {
        error,
        loading,
        account
      }
    }
  }),
  graphql(TOTAL_MEMBERS_WITH_FILTER_QUERY, {
    options: () => ({
      variables: {
        hasAccess: true,
        role_filter: { eq: "student" }
      }
    }),
    props: ({ data, ownProps }) => {
      const { error, loading, refetch, school } = data
      return {
        error,
        loading: ownProps.loading || loading,
        refetch,
        totalMembersWithAccess: get(school, "members.total")
      }
    }
  })
)(ManageBillingAccessRemove)
