import { graphql } from "@apollo/client/react/hoc"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CodeEditor from "@pathwright/ui/src/components/form/form-code-editor/LazyCodeEditor"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { Field, Form, Submit } from "react-connect-form-forked"
import PAGE_QUERY from "../graphql/page-query"
import UPDATE_PAGE_MUTATION from "../graphql/update-page-mutation"

const ManagePageHTML = ({ initialValue, onSubmit }) => (
  <Form
    initialValue={initialValue}
    onSubmit={formValues =>
      onSubmit({
        ...formValues,
        id: initialValue.id
      })
    }
  >
    <CardBlock>
      <Fieldset title="HTML" header="Build your page using HTML.">
        <Field name="template_content" type="text" component={CodeEditor} />
      </Fieldset>
    </CardBlock>
    <Submit styleType="primary" component={SubmitButton}>
      Save
    </Submit>
  </Form>
)

ManagePageHTML.displayName = "ManagePageHTML"
ManagePageHTML.propTypes = {
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    scripts: PropTypes.string
  })
}

export default compose(
  graphql(PAGE_QUERY, {
    options: ({ id, pageType }) => ({
      variables: {
        id,
        page_type: pageType
      }
    }),
    props: ({ data }) => ({
      initialValue: get(data, "school.page")
    })
  }),
  graphql(UPDATE_PAGE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onSubmit: changes =>
        mutate({
          variables: {
            ...ownProps.initialValue,
            ...changes
          },
          refetchQueries: ["PageQuery"]
        })
    })
  })
)(ManagePageHTML)
