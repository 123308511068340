import { useEffect, useState } from "react"
import { createPathCompletionsTree } from "./tree/path-tree"

const useCompletionsStore = context => {
  // this takes incoming completions items from context and creates a node tree + map
  // it also handles filtering and searching client side
  let [treeData, setTreeData] = useState()
  let [tree, setTree] = useState()
  let [map, setMap] = useState()

  const [itemsFilter, setItemsFilter] = useState(null)
  const [searchTerm, setSearchTerm] = useState(null)

  const reset = () => {
    setTreeData()
    setTree()
    setMap()
  }

  // initial load
  useEffect(() => {
    if (context.items && context.items.length > 0) {
      setTreeData(createPathCompletionsTree(context, context.items))
    } else {
      reset()
    }
  }, [context.items])

  useEffect(() => {
    if (treeData && itemsFilter) {
      setTreeData(
        createPathCompletionsTree(context, context.items, itemsFilter.filter)
      )
    }
  }, [itemsFilter])

  useEffect(() => {
    if (treeData) {
      setTree(treeData.tree)
      setMap(treeData.map)
    }
  }, [treeData])

  const filter = filter => {
    if (filter) {
      setItemsFilter(filter)
    } else {
      setItemsFilter(null)
      // reset the tree
      setTreeData(createPathCompletionsTree(context, context.items))
    }
  }

  const search = term => {
    setSearchTerm(term)
  }

  const set = (id, data) => {
    const item = map[id]
    if (item) {
      const newItem = { ...item, ...data }
      setMap({ ...map, [id]: newItem })
    }
  }

  const get = id => {
    if (map) return map[id]
    return null
  }

  return {
    map,
    tree,
    itemsFilter,
    actions: {
      filter,
      search,
      set,
      get
    }
  }
}

export default useCompletionsStore
