// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const customTransitions = {
  "transition.shrinkSmallIn": {
    defaultDuration: 750,
    calls: [
      [{
        opacity: [1, 0],
        transformOriginX: ["50%","50%"],
        transformOriginY: ["50%","50%"],
        scaleX: [1, 1.4],
        scaleY: [1, 1.4],
        translateZ: 0,
        zIndex: [1,1]
      }
      ]
    ]
  },
  "transition.shrinkSmallOut": {
    defaultDuration: 600,
    calls: [
      [{
        opacity: [ 0, 1 ],
        transformOriginX: [ "50%", "50%" ],
        transformOriginY: [ "50%", "50%" ],
        scaleX: 1.4,
        scaleY: 1.4,
        translateZ: 0,
        zIndex: [0,0]
      }
      ]
    ]
  },
  "transition.expandSmallIn": {
    defaultDuration: 600,
    calls: [
      [{
        opacity: [ 1, 0 ],
        transformOriginX: [ "50%", "50%" ],
        transformOriginY: [ "50%", "50%" ],
        scaleX: [ 1, 0.75 ],
        scaleY: [ 1, 0.75 ],
        translateZ: 0,
        zIndex: [1,1]
      }
      ]
    ]
  },
  "transition.expandSmallOut": {
    defaultDuration: 600,
    calls: [
      [{
        opacity: [ 0, 1 ],
        transformOriginX: [ "50%", "50%" ],
        transformOriginY: [ "50%", "50%" ],
        scaleX: 0.75,
        scaleY: 0.75,
        translateZ: 0,
        zIndex: [0,0]
      }
      ]
    ]
  },

  "ui.pulse": {
    defaultDuration: 300,
    calls: [
      [{opacity: 0.0}],
    ]
  },

  "transition.expandHalfFadeIn": {
    defaultDuration: 600,
    calls: [
      [{
        opacity: [ 0.5, 0 ],
        transformOriginX: [ "50%", "50%" ],
        transformOriginY: [ "50%", "50%" ],
        scaleX: [ 1, 0.75 ],
        scaleY: [ 1, 0.75 ],
        translateZ: 0,
        zIndex: [1,1]
      }
      ]
    ]
  }
};

const velocity = require("velocity-animate");
require("velocity-animate/velocity.ui");

const Velocity = $.Velocity ? $.Velocity : velocity;

for (let transitionKey in customTransitions) {
  const transitionData = customTransitions[transitionKey];
  Velocity.RegisterEffect(transitionKey, transitionData);
}
