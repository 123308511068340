import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import styled from "styled-components"
import useSSOLink from "../auth/graphql/useSSOLink"
import { useCurrencyFormatter } from "../currency/CurrencyFormatterProvider"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import SCOPED_RESOURCE_LICENSE_QUERY from "../resource/license/graphql/scoped-resource-license-query"
import { getGroupUrl } from "../utils/urls"
import useOrderPollingQuery from "./useOrderPollingQuery"

const NarrowCard = styled(Card)`
  & > .View {
    max-width: 500px;
  }
`

const CenteredContent = styled.div`
  padding: 40px 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

// TODO: combine w/ GroupLicenseOrderConfirmationCard?

export default function CurriculumLicenseOrderConfirmationCard({
  card,
  orderId
}) {
  const { school } = usePathwrightContext()
  const { currencyFormatter } = useCurrencyFormatter()
  const orderQuery = useOrderPollingQuery(orderId)
  const order = get(orderQuery, "data.order")

  const ssoLink = useSSOLink(order && order.school_id)

  const resourceLicenseQuery = useQuery(SCOPED_RESOURCE_LICENSE_QUERY, {
    skip: !order || !order.fulfilled_object_id,
    variables: {
      schoolId: order && order.school_id,
      resourceLicenseId: order && order.fulfilled_object_id
    }
  })
  const resourceLicense = get(
    resourceLicenseQuery,
    "data.context.school.resource_license"
  )

  return (
    <NarrowCard card={card} navless>
      {resourceLicense ? (
        <>
          <CardHeader
            card={card}
            title={resourceLicense.group.name}
            meta={
              resourceLicense.license_offering.cost_per_seat > 0
                ? `Curriculum License • ${currencyFormatter.format(
                    resourceLicense.license_offering.cost_per_seat
                  )}/member`
                : "Curriculum License"
            }
          />
          {orderQuery.error ? (
            <CenteredContent>
              <Alert error={orderQuery.error} />
            </CenteredContent>
          ) : order && order.order_status === "fulfilled" ? (
            <BlankSlate
              animateIcon
              stack
              icons={null}
              icon="check"
              heading={`${resourceLicense.group.name} is ready to teach!`}
              body={`The curriculum is ready to offer under your account, ${resourceLicense.school.name}`}
              {
                // when licensee school licenses through licensor store
                ...(resourceLicense.school.id === school.id
                  ? {
                      primaryAction: {
                        to: getGroupUrl(
                          resourceLicense.group.resource.slug,
                          resourceLicense.group.id
                        ),
                        children: "Go To Your Course"
                      }
                    }
                  : {
                      primaryAction: {
                        href: ssoLink,
                        children: "Go To Your Course"
                      },
                      secondaryAction: {
                        brand: true,
                        to: "/store/",
                        children: "Or continue browsing"
                      }
                    })
              }
            />
          ) : (
            <BlankSlate
              icons={null}
              heading="Processing order..."
              primaryAction={{
                brand: false,
                submitting: true
              }}
            />
          )}
        </>
      ) : (
        <LoadingCircle />
      )}
    </NarrowCard>
  )
}

CurriculumLicenseOrderConfirmationCard.displayName =
  "CurriculumLicenseOrderConfirmationCard"
