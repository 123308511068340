import { useEffect, useMemo, useState } from "react"
import {
  FILESTACK_API_KEY,
  FILESTACK_OPTIONS,
  MEDIA_EXTENSIONS_MAP,
  MEDIA_TYPES
} from "./constants"

// https://vimeo.com/help/compression - these are in Mbps
// const bitRates = {
//   xs: 30, // SD = 2-5
//   sm: 70, // 720p = 5 – 10
//   md: 150, //1080p	= 10 – 20
//   lg: 250, // 2K	= 20 – 30
//   xl: 450, // 4K =	30 – 60
//   xxl: 650 // 8K =	50 – 80
// }

export const videoEncodingOptions = {
  video_convert: {
    // video_bitrate: bitRates.md,
    // extname: ".mp4",
    // preset: "h264" // This is also Filestack's default
    preset: "hls.variant", // This is also Filestack's default
    clip_length: "20:00:00",
    aspect_mode: "preserve"
  }
}

export const loadFilestack = async () => {
  if (!window.filestack) {
    const script = document.createElement("script")
    script.src =
      "https://static.filestackapi.com/filestack-js/3.30.2/filestack.min.js"
    // wait for filestack.min.js to load for window.filestack to be available
    const promise = new Promise((resolve) => (script.onload = resolve))
    // execute the script
    document.body.appendChild(script)
    // now wait for script to load
    await promise
  }

  return window.filestack
}

export const getStoreTo = (mediaStoragePath = "") => ({
  container: "pw-filestack-uploads",
  path: mediaStoragePath,
  region: "us-east-1"
})

export const getFilestackHandle = (filestackUrl) => {
  const filestackHandleRe = new RegExp(
    "https:\\/\\/cdn\\.filestackcontent\\.com\\/(?<handle>[a-zA-Z0-9]+)"
  )

  if (filestackUrl) {
    try {
      return filestackUrl.match(filestackHandleRe).groups.handle
    } catch (error) {
      console.error(`Error getting filestack handle: ${error}`)
    }
  }
}

export const getPreviewURL = (handle) =>
  `https://cdn.filestackcontent.com/preview/${handle}`

// Url can be substituted with a file handle (ex: j1ECLHtTbevSEtIwObMe)
export const transform = async (url, options) => {
  const filestack = await loadFilestack()
  const client = filestack.init(FILESTACK_API_KEY)
  let transformURL
  try {
    transformURL = client.transform(url, options)
    return transformURL
  } catch (error) {
    return console.log({ error })
  }
}

export const pick = async (options) => {
  const filestack = await loadFilestack()
  const client = filestack.init(FILESTACK_API_KEY)
  return new Promise((resolve) => {
    client
      .picker({
        ...options,
        onUploadDone: resolve,
        onFileUploadFailed: (file, error) => {
          console.error(
            `Error uploading filestack file(s): ${error?.message}\n${error?.stack}\nfile: ${file}`
          )
        }
      })
      .open()
  })
}

export const crop = async (urls, options = {}) => {
  const filestack = await loadFilestack()
  const client = filestack.init(FILESTACK_API_KEY)
  return new Promise((resolve) => {
    client
      .picker({
        ...options,
        onUploadDone: resolve
      })
      .crop(urls)
  })
}

export const preview = async (
  handle,
  domID,
  cssURL = "https://duointeractive.github.io/filestack-styles.css"
) => {
  const filestack = await loadFilestack()
  const client = filestack.init(FILESTACK_API_KEY)
  try {
    client.preview(handle, { id: domID, css: cssURL })
  } catch (error) {
    return console.log(
      `Couldn't preview the Filestack file(s) ${handle} in the ${domID} id.`,
      { error }
    )
  }
}

export const getOptions = (mediaType, overrides = {}) => {
  // mediaType is optional.
  mediaType = mediaType || MEDIA_TYPES.ANY

  const options =
    // Force mediaType to be uppercase.
    FILESTACK_OPTIONS[mediaType.toUpperCase()] ||
    // Default to MEDIA_TYPES.ANY options.
    FILESTACK_OPTIONS[MEDIA_TYPES.ANY]

  return {
    ...options,
    ...overrides
  }
}

export const isFilePickerURL = (url) => {
  return (
    (url && url.indexOf("filepicker.io") > -1) || url.indexOf("filestack") > -1
  )
}

export const getHumanFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  )
}

export const getHumanExtensionList = (mediaType) => {
  const extensions = [...(MEDIA_EXTENSIONS_MAP[mediaType.toUpperCase()] || [])]
  if (!extensions.length) return ""
  if (extensions.length > 1) {
    if (window.App && window.App.utils && window.App.utils.oxfordCommatize) {
      return `a ${window.App.utils.oxfordCommatize(extensions, "or")} file`
    }
  }

  return `${extensions[0]} file`
}

export const getSignedFilestackURL = (url, extraParams, convert) => {
  // if (extraParams == null) {
  //   extraParams = {}
  // }
  // if (convert == null) {
  //   convert = false
  // }
  // let params = getQueryObject(new URL(url).search)
  // params = extend(params, extraParams)
  // params.cache = true
  // if (params.signature != null) {
  //   // assuming this is already signed
  //   return url
  // }
  // // if @isFilePickerURL(url)
  // //   # TODO: don't hardcode CDN url or move to server
  // //   url = url.replace("https://www.filepicker.io/","https://d3s4ni8cjo6ws9.cloudfront.net/")
  // // Default to read policy from integrations
  // let policy = null
  // let signature = null
  // if (!window.FPPolicy) {
  //   ;({
  //     read_policy: policy,
  //     read_signature: signature
  //   } = bootstrappedData.integrations.filepicker)
  // } else {
  //   ;({ policy, signature } = window.FPPolicy)
  // }
  // params = extend(params, { policy, signature })
  // // Rebuild and return
  // const parsed = new URL(url)
  // url = `${parsed.protocol}//${parsed.host}${parsed.pathname}`
  // if (convert) {
  //   url = `${url}/convert`
  // }
  // url = `${url}?${$.param(params)}`
  return url
}

// fetches the pdf info from filestack
export const usePdfInfo = (pdfUrl) => {
  const [pdfInfo, setPdfInfo] = useState(null)

  useEffect(() => {
    if (pdfUrl) {
      const pdfInfoUrl = `https://cdn.filestackcontent.com/pdfinfo/${getFilestackHandle(
        pdfUrl
      )}`

      fetch(pdfInfoUrl).then(async (response) => {
        const pdfInfo = await response.json()
        setPdfInfo(pdfInfo)
      })
    }
  }, [pdfUrl])

  return pdfInfo
}

// Simple hook for extracting the dimensions from some pdf info.
export const usePdfDimensions = (pdfUrl) => {
  const pdfInfo = usePdfInfo(pdfUrl)

  const dimensions = useMemo(() =>
    pdfInfo
      ? {
          height: pdfInfo.pagesize.height,
          width: pdfInfo.pagesize.width
        }
      : null
  )

  return dimensions
}
