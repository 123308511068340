import PropTypes from "prop-types"
import React from "react"
import withPathItems from "../graphql/withPathItems"
import ReportPathList from "./ReportPathList"

const ReportPath = props => <ReportPathList {...props} />

ReportPath.propTypes = {
  id: PropTypes.number,
  cohort_id: PropTypes.number
}

export default withPathItems(ReportPath)
