import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import useIntersectionLoader from "@pathwright/ui/src/components/hooks/useIntersectionLoader"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import gql from "graphql-tag"
import pluralize from "pluralize"
import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { StringParam, useQueryParam } from "use-query-params"
import { useReviewContext } from "../state/ReviewContextProvider"

import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import { usePaginator } from "../../utils/apollo"

const INBOX_PEOPLE_COMPLETION_COUNTS_QUERY = gql`
  query InboxPeopleCompletionCounts(
    $targetType: InboxPeopleCompletionCountsTarget!
    $targetId: Int!
    $first: Int!
    $cursor: String
    $isComplete: Boolean
    $needsReview: Boolean
    $stepSourceId: Int
    $parentSourceId: Int
  ) {
    inboxPeopleCompletionCounts(
      target_type: $targetType
      target_id: $targetId
      first: $first
      after: $cursor
      is_complete: $isComplete
      needs_review: $needsReview
      step_source_id: $stepSourceId
      parent_source_id: $parentSourceId
    ) {
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          count
          user {
            ...User
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
`

const usePeopleWithCompletionCounts = ({
  targetType,
  targetId,
  first,
  isComplete,
  needsReview,
  stepSourceId,
  parentSourceId
}) => {
  const variables = {
    targetType,
    targetId,
    first,
    isComplete,
    needsReview,
    stepSourceId,
    parentSourceId
  }

  const query = useQuery(INBOX_PEOPLE_COMPLETION_COUNTS_QUERY, {
    variables,
    fetchPolicy: "cache-and-network"
  })

  const { data, loading, error, refetch } = query

  const people = useMemo(() => {
    if (!data) {
      return []
    }

    return data.inboxPeopleCompletionCounts.edges.map(({ node }) => node)
  }, [data])

  const paginationProps = usePaginator({
    data: query,
    path: "inboxPeopleCompletionCounts"
  })

  return { people, data, loading, error, refetch, ...paginationProps }
}

const PeopleFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  margin: 20px 0px;

  overflow: hidden;
  overflow-x: auto;

  > nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      .Avatar {
        margin-right: 5px;
      }
      h2 {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
      }
      h1 {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
`

const AVATAR_SIZE = 48

const PersonContainer = styled.button`
  /* reset button styles */
  background: none;
  border: none;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  width: 60px;

  .Avatar {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 13px;
    margin: 0px;
    color: rgba(255, 255, 255, 0.9);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60px;
  }

  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #000;
    font-weight: bold;
    /* background shuld be a darkish blue */
    background-color: #fff;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .overlay {
    transition: all 0.2s ease;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    flex-shrink: 0;
    color: #fff;
    font-weight: bold;
    text-shadow: 0px 0px 1px #000;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    position: absolute;
    top: 0px;
    width: ${AVATAR_SIZE}px;
    height: ${AVATAR_SIZE}px;
  }

  &:hover {
    .overlay {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.6);
      border: 2px solid rgba(255, 255, 255, 0.8);
    }
  }
`

const IntersectionLoaderContainer = styled.div`
  position: relative;
  height: 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;
  margin-left: -10px;

  .LoadingCircle {
    position: static !important;
  }
`

// Load more people when PeopleFilter has been
// scrolled to the right.
const IntersectionLoader = ({ hasMore, loadingMore, loadMore }) => {
  const intersectionRef = useIntersectionLoader({
    hasMore,
    loadingMore,
    loadMore
  })

  return hasMore ? (
    <IntersectionLoaderContainer ref={intersectionRef}>
      <LoadingCircle inverted />
    </IntersectionLoaderContainer>
  ) : null
}

const Person = ({ user, count, onSelect }) => {
  const handleClick = () => {
    if (onSelect) {
      onSelect({ user, count })
    }
  }

  return (
    <PersonContainer key={user.id} onClick={handleClick}>
      <Avatar user={user} size={AVATAR_SIZE} />
      <h1>{user.display_name}</h1>
      {/* <span className="count">{count}</span> */}
      <div className="overlay">{count}</div>
    </PersonContainer>
  )
}

const PeopleFilter = () => {
  const {
    actions: { useSyncReviewUpdates },
    context: { query, selectedFilter }
  } = useReviewContext()

  const topLevelContextType = !!query.cohort_id
    ? "cohort"
    : !!query.group_id
    ? "group"
    : null

  const topLevelContextId = query.cohort_id || query.group_id

  let filterParams = {}

  if (selectedFilter == "complete") {
    filterParams.isComplete = true
  } else if (selectedFilter === "incomplete") {
    filterParams.isComplete = false
  } else if (selectedFilter == "pending") {
    filterParams.needsReview = true
  } else if (selectedFilter === "reviewed") {
    filterParams.needsReview = false
  } else if (selectedFilter === "all") {
    filterParams = {}
  }

  if (query.source_id) {
    filterParams.stepSourceId = query.source_id
  }
  if (query.parent_id) {
    filterParams.parentSourceId = query.parent_id
  }

  const [selectedUserId, setSelectedUserId] = useQueryParam(
    "user_id",
    StringParam
  )

  const {
    people,
    hasMore,
    loadingMore,
    loadMore,
    refetch
  } = usePeopleWithCompletionCounts({
    targetType: topLevelContextType,
    targetId: topLevelContextId,
    first: 50,
    ...filterParams
  })

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    // TODO: if the selectedUserId is past the page size then
    // the user won't be in the list yet...
    // perhaps one way to fix this would be store the pagination info  as a query param to make sure
    // the user is in the list
    if (selectedUserId) {
      const user = people.find(p => `${p.user.id}` == selectedUserId)
      if (user) {
        setSelected(user)
      } else {
        setSelected(null)
      }
    }
  }, [selectedUserId, people])

  useEffect(() => {
    if (!selectedUserId && selected) {
      setSelected(null)
    }
  }, [selected, selectedUserId])

  // Conditionally refetch people items in order to sync any review status updates
  // that would require syncing the Inbox list view with those updates.
  useSyncReviewUpdates(refetch)

  const handleSelect = selection => {
    setSelected(selection)
    setSelectedUserId(selection.user.id, "replaceIn")
  }

  const handleUnselectUser = () => {
    setSelected(null)
    setSelectedUserId(null, "replaceIn")
  }

  return (
    <PeopleFilterContainer>
      {selected ? (
        <nav>
          <IconButton
            icon="chevron-left"
            onClick={() => handleUnselectUser()}
            inverted
          />
          <div>
            <Avatar user={selected.user} size={AVATAR_SIZE} />
            <div>
              <h1>{selected.user.full_name}</h1>
              <h2>{pluralize("step", selected.count, true)} </h2>
            </div>
          </div>
        </nav>
      ) : (
        <>
          {people.map(({ user, count }) => {
            return (
              <Person
                key={user.id}
                user={user}
                count={count}
                onSelect={handleSelect}
              />
            )
          })}
          <IntersectionLoader
            hasMore={hasMore}
            loadingMore={loadingMore}
            loadMore={loadMore}
          />
        </>
      )}
    </PeopleFilterContainer>
  )
}

export default PeopleFilter
