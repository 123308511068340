import memoize from "lodash/memoize"
import Color from "jscolor"
import {
  PRIMARY_WHITE,
  SECONDARY_WHITE,
  TERTIARY_WHITE,
  PRIMARY_GRAY,
  SECONDARY_GRAY,
  TERTIARY_GRAY
} from "../utils/colors"

export const invertForBackground = memoize(color =>
  color ? new Color(color).yiq < 128 : false
)

export const getTextColor = ({
  emphasis,
  inverted,
  backgroundColor,
  baseColor
}) => {
  const invert = typeof inverted === "boolean" ? inverted : invertForBackground(backgroundColor) // prettier-ignore
  // prefer baseColor if not inverted
  if (!invert && baseColor) return baseColor
  switch (emphasis) {
    case "tertiary":
      return invert ? TERTIARY_WHITE : TERTIARY_GRAY
    case "secondary":
      return invert ? SECONDARY_WHITE : SECONDARY_GRAY
    case "primary":
    default:
      return invert ? PRIMARY_WHITE : PRIMARY_GRAY
  }
}

export const colorStyles = props => ({ color: getTextColor(props) })
