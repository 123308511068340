const AppStore = require("./app-store").default

window.App.module("Stores", function() {
  this.startWithParent = false

  window.App.stores = new AppStore()

  require("school/school-store")
  require("auth/auth-store")
  require("global/analytics-store")
  require("global/navigation-store")
  require("layout/layout-store")
  require("discussion/discussion-store")
  require("library/admin/resource-admin-store")
  require("library/admin/category-admin-store")
  require("library/admin/group-admin-store")
  require("library/admin/author-admin-store")
  require("notifications/notification-store")
  require("support/support-store")
  require("home/profile-store")
  require("account/subscription/subscribe-store")
  require("billing/store/pathwright-account-store")
  require("billing/store/billing-store")
  require("license/resource-license-admin-store").default

  _.defer(() => window.App.stores.initDebug())

  // Shortcut
  window.App.getStore = key => window.App.stores.requestStore(key)

  return App
})
