import PropTypes from "prop-types"
import React from "react"
import "./TextArea.css"
import classnames from "classnames"
import FieldWrapper from "../form-utils/FieldWrapper"
import TextareaAutosize from "react-textarea-autosize"

const handleEnterKey = function(e, preventEnter, onKeyDown) {
  if (typeof onKeyDown === "function") {
    onKeyDown(e.target.value, e)
  }
  if (preventEnter && e.keyCode === 13) {
    // stop new line behavior but allow propagation
    // so form can listen to enter and possibly submit
    return e.preventDefault()
  } else {
    // stop propagation so form won't submit
    return e.stopPropagation()
  }
}

const TextArea = function(props) {
  let {
    id,
    label,
    value,
    rows,
    minRows,
    maxRows,
    showLimit,
    className,
    autoFocus,
    onChange,
    onKeyDown,
    placeholder,
    preventEnter
  } = props
  className = classnames("TextArea", className)

  let charRemainingLabel = null
  if (showLimit) {
    charRemainingLabel = `${(value != null ? value.length : undefined) ||
      0}/${showLimit}`
  }

  return (
    <FieldWrapper
      {...Object.assign({}, props, {
        className: className,
        showStatus: charRemainingLabel
      })}
    >
      {({ focus, onFocus, onBlur }) => {
        return (
          <div className="TextArea__input">
            <TextareaAutosize
              id={id}
              name={label}
              minRows={minRows}
              maxRows={maxRows || rows}
              autoFocus={autoFocus}
              placeholder={placeholder}
              value={value != null ? value : ""}
              onKeyDown={e => handleEnterKey(e, preventEnter, onKeyDown)}
              onChange={e =>
                typeof onChange === "function"
                  ? onChange(e.target.value)
                  : undefined
              }
              onFocus={onFocus}
              onBlur={onBlur}
              autoCapitalize="none"
              autoCorrect="off"
            />
          </div>
        )
      }}
    </FieldWrapper>
  )
}

TextArea.displayName = "TextArea"
TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  // Specific number of rows to show
  rows: PropTypes.number,
  // Min number of rows to show
  minRows: PropTypes.number,
  // Max number of rows to show
  maxRows: PropTypes.number,
  // Show validation results
  showSuccess: PropTypes.bool,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  showLimit: PropTypes.number,
  // Hook to TextInput events
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onHeightChange: PropTypes.func
}

export default TextArea
