import React from "react"
import PropTypes from "prop-types"
import BlockContent from "@pathwright/web/src/modules/blocks/BlockContent"

const ManagePageBuilder = props => {
  return <BlockContent contextKey={`/page-${props.id}`} templateVariables={{}} mode="EDIT"/>
}

ManagePageBuilder.displayName = "ManagePageBuilder"

ManagePageBuilder.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired
}

export default ManagePageBuilder
