import Lesson from "@pathwright/ui/src/components/library/Lesson"
import { media } from "@pathwright/ui/src/components/utils/styles"
import React from "react"
import styled from "styled-components"
import { getAuthorLine } from "../../store/browse/grid/BrowseGrid"
import { useResourceDisplayPrice } from "./utils"

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  ${media.max.phone`
    grid-template-columns: 1fr;
  `}

  li {
    /* override default grid item min-height and min-width so that grid item does not overflow based on its content size */
    min-height: 0;
    min-width: 0;
  }
`

const BrowseLesson = ({ lesson, ...rest }) => {
  const displayPrice = useResourceDisplayPrice(lesson)

  return <Lesson {...rest} lesson={lesson} displayPrice={displayPrice} />
}

const BrowseLessons = ({ lessons, getLessonTo, getLessonSettingsTo }) => {
  if (!lessons || !lessons.length) return null

  return (
    <List>
      {lessons.map(lesson => (
        <li key={lesson.id}>
          <BrowseLesson
            to={getLessonTo(lesson)}
            toSettings={getLessonSettingsTo(lesson)}
            meta={getAuthorLine(lesson)}
            lesson={lesson}
          />
        </li>
      ))}
    </List>
  )
}

BrowseLessons.defaultProps = { standalone: false }

export default BrowseLessons
