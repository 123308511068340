import { useEffect } from "react"
import { useSignIn } from "./api/action"
import SignInPasswordView, {
  SignInPasswordViewProps
} from "./SignInPasswordView"

export type SignInSuccessHandler = (
  value: ReturnType<typeof useSignIn>[0]["value"]
) => void

interface SignInPasswordViewContainerProps {
  onSuccess?: SignInSuccessHandler
}

const SignInPasswordViewContainer = ({
  onSuccess
}: SignInPasswordViewContainerProps) => {
  const [state, action] = useSignIn()

  const handleSubmit: SignInPasswordViewProps["onSubmit"] = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await action(formData)
  }

  useEffect(() => {
    if (!!state.value) onSuccess?.(state.value)
  }, [state.value])

  return (
    <SignInPasswordView
      loading={state.loading}
      success={!!state.value}
      error={state.error?.message}
      onSubmit={handleSubmit}
    />
  )
}

export default SignInPasswordViewContainer
