import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import ReactSelect from "react-select"
import TagForm from "../form/TagForm"
import { tagPermissionsPropType, tagsPropType } from "../propTypes"
import {
  findMatchingTag,
  getTagNameAlreadyExists,
  getTagNameAlreadyFiltered,
  getTagNameAlreadySelected,
  mergeSelectedTags,
  useDefaultTag
} from "../utils"
import components from "./components"
import { getStyles } from "./styles"

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

const TagSelector = ({
  tags,
  contextTags: contextTagsProp,
  tagPermissions,
  tagFormLabels,
  selectLabel,
  onChange,
  onInputChange,
  styles: stylesProp,
  isMulti,
  isClearable,
  inverted,
  postMenuList,
  ...selectorProps
}) => {
  const [inputValue, setInputValue] = useState("")
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [selectIsFocused, setSelectIsFocused] = useState(false)
  const selectRef = useRef(null)

  // Derive contextTags from the contextTagsProp or just default to
  // the tags prop.
  const contextTags = useMemo(() => {
    if (contextTagsProp) {
      // In the end, contextTags is a blend of current state and props.
      // This way perm checks for whether a tag can be created/selected
      // are informed by current state and props, the most accurate data.
      return mergeSelectedTags(contextTagsProp, [
        ...contextTagsProp.selected,
        ...tags.selected
      ])
    }
    return tags
  }, [contextTagsProp, tags])

  // Whether ReactSelect is currently being interacted with.
  const interactive = Boolean(selectIsFocused || menuIsOpen)
  // Setting ReactSelect isSearchable to false will remove the
  // text input. We need to remove this for truncating the list
  // of tags to one line, or alternatively we could use styles to
  // achieve a simlar effect (though not display none as that causes)
  // the main select container to be unclickable.
  const searchable = Boolean(interactive || inputValue)

  // If contextTags changes and we find that the current inputValue
  // matches a selected tag, we can clear the inputValue, assuming the
  // selected tag was just created.
  useEffect(() => {
    if (findMatchingTag(inputValue, contextTags.selected)) {
      setInputValue("")
    }
  }, [contextTags])

  const handleInputChange = (value, { action }) => {
    // explicitly controlling intputValue as react-select clears
    // the inputValue onBlur, which is not ideal for us
    if (action !== "input-blur" && action !== "menu-close") {
      if (onInputChange) onInputChange(value)
      setInputValue(value)
    }
  }

  // TEMP: fix JS error in react-select dist when running `yarn start`
  const handleFilterOption = (option, value) => {
    const matchesValue = new RegExp(escapeRegExp(value), "i").test(
      option.data.name
    )
    const alreadyExists = getTagNameAlreadyExists(value, contextTags)

    // User with add perms is provided UI for adding existing tag, so hide
    // any tag matching the name value.
    if (tagPermissions.canAdd) return !alreadyExists && matchesValue
    // Otherwise just include those tags whose name matches the name value.
    return matchesValue
  }

  // Use the defaults for new tag, handled when pressing "Enter" key.
  const currentTag = useDefaultTag({ name: inputValue }, contextTags)

  // Allow for adding a tag by pressing "Enter" key.
  function handleKeyDown(e) {
    if (tagPermissions.canAdd && inputValue && e.key === "Enter") {
      // Make sure tag has not already been selected.
      // TODO: may need to check agains selectedTags!
      if (!getTagNameAlreadySelected(inputValue, contextTags)) {
        e.preventDefault()
        // Assuming tags is controlled, merging latest tags.selected
        // will add the currentTag to list of selected tags.
        onChange([...tags.selected, currentTag])
      } else if (getTagNameAlreadyFiltered(inputValue, contextTags)) {
        // Prevent any submission when input has value but unable to add/create.
        e.preventDefault()
      }
    }
  }

  return (
    <ReactSelect
      className="TagSelector"
      ref={selectRef}
      value={tags.selected}
      inputValue={inputValue}
      options={tags.filtered}
      // Overriding default message with empty string.
      // We'll still use the NoOptionsMessage component, just as an empty space.
      noOptionsMessage={() => ""}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.name}
      filterOption={handleFilterOption}
      // filterOption alternative matching multiple words: https://github.com/JedWatson/react-select/issues/3067#issue-363771398
      // naive fuzzy search, likely not needed:
      // filterOption={(option, value) => new RegExp(value, "i").test(option.data.name)}
      // NOTE: selectedOptons will be an array if isMulti otherwise,
      // a single option value
      onChange={selectedOptions => onChange(selectedOptions)}
      onInputChange={handleInputChange}
      onFocus={() => setSelectIsFocused(true)}
      onBlur={() => setSelectIsFocused(false)}
      onKeyDown={handleKeyDown}
      components={components}
      styles={getStyles(stylesProp)}
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={searchable}
      placeholder={selectLabel}
      menuPlacement="auto"
      // conditionally keeping menu open
      openMenuOnFocus
      // Conditionally forcing menu to be open (basically
      // prevents menu from closing prematurely).
      menuIsOpen={menuIsOpen || undefined}
      // below are custom props passed to non-react-select components
      customProps={{
        tags: contextTags,
        tagPermissions,
        tagFormLabels,
        inverted, // For styles.
        interactive, // For styles.
        postMenuList,
        setMenuIsOpen,
        setInputValue,
        selectRef
      }}
      // Grab bag.
      {...selectorProps}
    />
  )
}

TagSelector.displayName = "TagSelector"

TagSelector.propTypes = {
  tags: tagsPropType,
  // The contextTags optionally override tags when it comes to perm checks.
  // like if the tag can be created/selected.
  contextTags: tagsPropType,
  tagPermissions: tagPermissionsPropType,
  tagFormLabels: TagForm.propTypes.labels,
  selectLabel: PropTypes.string,
  // onChange function will receive array of selected options
  onChange: PropTypes.func.isRequired,
  // onInputChange function will receive the value of the text input
  onInputChange: PropTypes.func,
  // Any styles (as obj) or returned from styles (as func) will override custom styles from styles.js.
  styles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // isMulti handled directly by react-select and allows for
  // enabling selecting multiple options
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  inverted: PropTypes.bool,
  // Optional node rendered within the Menu after the MenuList
  postMenuList: PropTypes.node
}

TagSelector.defaultProps = {
  isMulti: true,
  isClearable: false,
  inverted: false,
  tagPermissions: {
    canAdd: false,
    canEdit: false,
    canRemove: false,
    canReorder: false
  }
}

export default TagSelector
