import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import copyToClipboard from "@pathwright/ui/src/components/copy/copyToClipboard"
import LazyCodeEditor from "@pathwright/ui/src/components/form/form-code-editor/LazyCodeEditor"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import get from "lodash/get"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { Field, Form, Submit } from "react-connect-form-forked"
import styled from "styled-components"
import SelectInput from "../../lib/SelectInput/SelectInput"
import { sampleEventData } from "../../utils/dispatcher"

const StyledEventEditorContainer = styled.div`
  background-color: #eee;
  margin-top: 10px;
  border-radius: 4px 4px 0 0;
`

const StyledSelectContainer = styled.div`
  display: flex;
  justifycontent: center;
  align-items: center;
  padding: 10px;
`

const StyledEditorContainer = styled.div`
  position: relative;
  button {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 10;
  }
  .CodeEditor {
    padding: 0;
  }
  .Test {
    ${props => {
      return !props.show ? "display: none;" : ""
    }}
`

const StyledEventNameLabel = styled.span`
  margin-right: 5px;
`

const StyledSelectInput = styled(SelectInput)`
  flex-grow: 1;
`

// Create event listener script with sample data
// Using prettier-ignore to maintain correct indentation!
const getSampleScript = event => {
  // stringify JSON using indentation
  const stringifyJSON = detail => JSON.stringify({ detail }, null, 2)

  // Make a bold comment
  //////////
  // bold //
  //////////
  const boldComment = line =>
    [
      new Array(line.length + 6).fill("/").join(""),
      `// ${line} //`,
      new Array(line.length + 6).fill("/").join("")
    ].join("\n")

  // "payment", "subscription"
  const getValueListForType = type =>
    Object.keys(sampleEventData[event]._types[type])
      .map(value => `"${value}"`)
      .join(", ")

  const eventVariableName = "e"

  return `
<script>
  document.addEventListener("${event}", function (${eventVariableName}) {

    // Handle the event.

    // Below is *sample* JSON of the data supplied to the "${event}" event.
    
${`${sampleEventData[event]._types
      ? Object.keys(sampleEventData[event]._types).map(type =>
    `${eventVariableName}.detail.${type} can be one of [${getValueListForType(type)}]

${Object.keys(sampleEventData[event]._types[type]).map(value =>
    `${boldComment(`When ${eventVariableName}.detail.${type} === "${value}", the data will have the following shape`)} 

${stringifyJSON(sampleEventData[event]._types[type][value])}`).join("\n\n")}`).join("")
      : stringifyJSON(sampleEventData[event])}
    `.replace(/^( *)/gm, "    // $1")
     .replace(/^ *\/\/ ($|\/\/)/gm, "    $1")}
  
  })
</script>`.trim() // prettier-ignore
}

class EventSampler extends Component {
  state = {
    eventName: Object.keys(sampleEventData)[0],
    showSampleData: true
  }

  render() {
    return (
      <div>
        {this.state.showSampleData ? (
          <span>
            Copy the code below and paste into one of the code editors above.
          </span>
        ) : (
          <Button
            style={{
              padding: 0
            }}
            styleType="blank"
            onClick={() =>
              this.setState({
                showSampleData: !this.state.showSampleData
              })
            }
          >
            See events examples.
          </Button>
        )}
        {this.state.showSampleData && (
          <StyledEventEditorContainer>
            <StyledSelectContainer>
              <StyledEventNameLabel>
                <strong>Event Name:</strong>
              </StyledEventNameLabel>
              <StyledSelectInput
                value={this.state.eventName}
                options={Object.keys(sampleEventData).map(eventName => ({
                  label: eventName,
                  value: eventName
                }))}
                onChange={({ value }) => this.setState({ eventName: value })}
                labelKey="label"
                valueKey="value"
                inline
                hideStatus
              />
            </StyledSelectContainer>
            <StyledEditorContainer>
              <Button
                styleType="primary"
                onClick={() =>
                  copyToClipboard(getSampleScript(this.state.eventName))
                }
              >
                Copy
              </Button>
              {// Bit of a hack to get the code editor to render with the appropriate event sample data
              Object.keys(sampleEventData)
                .filter(eventName => eventName === this.state.eventName)
                .map(eventName => (
                  <LazyCodeEditor
                    key={eventName}
                    hideStatus
                    codeMirrorOptions={{
                      theme: "material",
                      mode: "htmlmixed",
                      lineNumbers: true,
                      lineWrapping: true
                    }}
                    value={getSampleScript(eventName)}
                  />
                ))}
            </StyledEditorContainer>
          </StyledEventEditorContainer>
        )}
      </div>
    )
  }
}

const ManageCustomCode = ({ card, initialValue, onSubmit, loading }) => {
  return (
    <Card card={card} title="Custom Code">
      {loading ? (
        <LoadingCircle />
      ) : (
        <Form initialValue={initialValue} onSubmit={onSubmit}>
          <CardBlock>
            <Fieldset
              title="Inject code into the header"
              header={
                <span>
                  Inject custom Javascript or HTML code into the header so that
                  it loads <strong>before</strong> the content on each page.
                </span>
              }
            >
              <Field
                name="head_markup"
                hideStatus
                codeMirrorOptions={{
                  theme: "material",
                  mode: "htmlmixed",
                  lineNumbers: true
                  // lineWrapping: true
                }}
                component={LazyCodeEditor}
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              hideStatus
              title="Inject code into the body"
              header={
                <span>
                  Inject custom Javascript or HTML code into the body so that it
                  loads <strong>after</strong> the content on each page. Listen
                  to custom events for tracking conversions and other data.
                </span>
              }
            >
              <Field
                name="script_markup"
                hideStatus
                codeMirrorOptions={{
                  theme: "material",
                  mode: "htmlmixed",
                  lineNumbers: true
                  // lineWrapping: true
                }}
                component={LazyCodeEditor}
              />
            </Fieldset>
            {/*
              TODO: Fix these event handlers!
              Since the Order release these events have not been firing, or at best,
              have been firing at a fraction of what they should.
            */}
            {/* <EventSampler /> */}
          </CardBlock>
          {get(initialValue, "conversion_tracking_code") && (
            <CardBlock>
              <Fieldset
                title="Inject code or a pixel when someone purchases a course or subscription"
                header="Inject custom Javascript, HTML, or a pixel so that it loads on purchase confirmation screens."
              >
                <Field
                  name="conversion_tracking_code"
                  hideStatus
                  codeMirrorOptions={{
                    theme: "material",
                    mode: "htmlmixed",
                    lineNumbers: true
                    // lineWrapping: true
                  }}
                  component={LazyCodeEditor}
                />
              </Fieldset>
            </CardBlock>
          )}
          <Submit styleType="primary" component={SubmitButton}>
            Save
          </Submit>
        </Form>
      )}
    </Card>
  )
}

ManageCustomCode.displayName = "ManageCustomCode"

ManageCustomCode.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    head_markup: PropTypes.string,
    script_markup: PropTypes.string,
    conversion_tracking_code: PropTypes.string
  })
}

export default ManageCustomCode
