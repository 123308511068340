// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.

import { getSchoolUrl } from "@pathwright/web/src/modules/utils/urls"
const { get } = require("lib/core/api/request")

// incase this validator is used with legacy forms
const handleLegacyFormValidation = func => (value, values) => {
  if (typeof value !== "string") {
    return func(values, value)
  }
  return func(value, values)
}

export const isSubdomainUnique = handleLegacyFormValidation(
  (value, values) =>
    new Promise(function(resolve, reject) {
      const url = `${availUrl}?check=subdomain&q=${value}`
      return get(url).then(function(result) {
        if (result.available) {
          return resolve()
          // TODO: handle existing domain?
        } else {
          return reject(new Error("Subdomain already exists"))
        }
      })
    })
)

export const isEmailUnique = handleLegacyFormValidation(
  async (value, values) => {
    const { user } = App.getStore("auth").getState()

    if (!value) return
    if (user && user.email === value) return

    const search = new URLSearchParams({
      check: "email",
      q: value
    })
    const url = getSchoolUrl(
      "/api/private/account/availability/",
      `?${search.toString()}`
    )
    const result = await get(url)

    if (!result.available) {
      throw new Error("Email already exists")
    }
  }
)
