import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { LEARNING_ACTION_COMPONENTS } from "./constants"
import { Motion, spring } from "react-motion"
import ActivityTime from "./ActivityTime"

const StyledList = styled.ul`
  margin-top: 10px;
  padding: 0;
  position: relative;
  list-style-type: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`

const StyledItem = styled.li`
  position: relative;
  transition: transform 100ms ease-out;
`

const StyledTime = styled.div`
  position: relative;
  top: -12px;
  width: 100%;
`

// NOTE: currently only steps are grouped
// so that multiple actions come into play.
const STEP_ACTION_HEIGHT = 32
const STEP_ACTION_OVERLAP = 5
const HOVER_GROW_DISTANCE = 5

class ActivityActions extends Component {
  state = {
    hovering: false,
    expanded: this.numActions === 1
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.actions.length !== this.numActions) {
      this.setState({ expanded: nextProps.actions.length === 1 })
    }
  }

  get numActions() {
    return this.props.actions.length
  }

  handleHover = hovering => {
    if (this.state.expanded) return
    return () => {
      this.setState({ hovering })
    }
  }

  handleExpand = () => {
    if (this.state.expanded) return
    this.setState({ expanded: true })
  }

  // move each each action up under the previous action in the stack
  getPositionForIndex = (index, position) => {
    const { expanded, hovering } = this.state
    const growDistance = (index / this.numActions) * HOVER_GROW_DISTANCE
    return {
      marginTop: index ? position : 0,
      transform: !expanded && `translateY(${hovering ? growDistance : 0}px)`,
      zIndex: this.numActions - index
    }
  }

  // the deeper in the stack, the darker the background color
  getColorForIndex = index => {
    if (this.state.expanded) return
    const shade = Math.round(240 - (index / this.numActions) * 40)
    return `rgb(${shade},${shade},${shade})`
  }

  render() {
    const { expanded } = this.state
    const { actions, transition, ...passProps } = this.props
    const latestAction = actions[0]
    const ActionComponent = LEARNING_ACTION_COMPONENTS[latestAction.action_type]
    if (!ActionComponent) {
      return (
        <StyledTime>
          <ActivityTime {...passProps} action={latestAction} />
        </StyledTime>
      )
    }
    const spacing = expanded ? 10 : STEP_ACTION_OVERLAP - STEP_ACTION_HEIGHT
    return (
      <Motion style={{ pos: spring(spacing, transition) }}>
        {interpolatingStyle => (
          <StyledList
            onClick={this.handleExpand}
            onMouseEnter={this.handleHover(true)}
            onMouseLeave={this.handleHover(false)}
          >
            {actions.map((action, index) => (
              <StyledItem
                key={action.id}
                index={index}
                style={this.getPositionForIndex(index, interpolatingStyle.pos)}
              >
                <ActionComponent
                  {...action}
                  {...passProps}
                  includeLink={expanded}
                  includeThumbnail={index === 0}
                  // style={{ backgroundColor: this.getColorForIndex(index) }}
                />
                {expanded && <ActivityTime {...passProps} action={action} />}
              </StyledItem>
            ))}
            {!expanded && <ActivityTime {...passProps} action={latestAction} />}
          </StyledList>
        )}
      </Motion>
    )
  }
}

ActivityActions.displayName = "ActivityActions"

ActivityActions.propTypes = {
  actions: PropTypes.array,
  isCourseActivity: PropTypes.bool,
  isStepActivity: PropTypes.bool,
  isUserActivity: PropTypes.bool
}

ActivityActions.defaultProps = {
  transition: {
    stiffness: 250,
    damping: 25
  }
}

export default ActivityActions
