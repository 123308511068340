let Resource
const ResourceAuthorCollection = require("./author-collection").default
const ResourceOfferingCollection = require("./offering-collection").default

export default Resource = class Resource extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    let url = this.urlRoot(`/api/private/library/resource/`)
    if (this.id) {
      url = `${url}${this.id}/`
    } else if (this.get("slug")) {
      url = `${url}by-slug/${this.get("slug")}/`
    }
    return url
  }

  initialize() {
    this.authors = new ResourceAuthorCollection(this.get("authors"))
    this.offerings = new ResourceOfferingCollection(this.get("offerings"))
    this.getCoverImage()
    this.createOfferingAccessURLs()
  }

  parse(resp) {
    this.authors = new ResourceAuthorCollection(resp.authors)
    return resp
  }

  getCoverImage() {
    return this.get("thumb")
  }

  getAuthorListString() {
    const authors = this.get("authors")
    if (authors != null ? authors.length : undefined) {
      const names = _.map(authors, author => author.display_name)
      return names.join(", ")
    }
    return null
  }

  getTypeName() {
    const type = this.get("resource_type")
    switch (type) {
      case 10:
        return "course"
      case 20:
        return "lesson"
      case 30:
        return "collection"
      default:
        return "resource"
    }
  }

  getRelativeURL() {
    return `/library/${this.attributes.slug}/`
  }

  getOrder() {
    // call in browse view to allow sorting by default order
    if (this.collection) {
      const order = this.collection.indexOf(this.model)
      return order
    }
  }

  _getImage() {
    if (this.get("cover_image")) {
      return this.get("cover_image")
    } else {
      return window.App.request("get:school").get("media").image
    }
  }

  serialize() {
    let data = this.toJSON()
    data = _.assign(data, {
      author: this.getAuthorListString()
    })
    return data
  }

  toJSON() {
    let data = super.toJSON()
    if (!this.isNew()) {
      data = _.assign(data, {
        author: this.getAuthorListString()
      })
    }
    return data
  }

  // This is on the resource model for convenience but there is probably a better place
  createOfferingAccessURLs() {
    return this.offerings.each(model => {
      return model.set({
        access_url: `/app/course/${this.id}/class/${model.id}/path`
      })
    })
  }
}
