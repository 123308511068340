import gql from 'graphql-tag'

export default gql`
  query ResourceLicenses($first: Int!, $cursor: String) {
    resource_licenses(first: $first, after: $cursor) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          seat_count
          seats_filled
          expiration_dtime
          group {
            id
            name
            resource {
              id
              name
              image(width: 300, height: 225, fit: crop)
            }
          }
        }
      }
    }
  }
`
