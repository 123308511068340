import Button from "@pathwright/ui/src/components/button/Button"
import Dropdown from "@pathwright/ui/src/components/dropdown"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import PropTypes from "prop-types"
import React from "react"

const ResourcesOrderingFilter = ({
  filter: currentFilter,
  onFilter,
  disabled
}) => {
  const { tc } = useTranslate()

  // match filters with translated labels
  const filters = {
    // i18next-scanner v.1 tc("featured")
    [ResourcesOrderingFilter.filters.featured.toString()]: tc([
      "library.browse.controls.featured",
      "featured"
    ]),
    // i18next-scanner v.1 tc("popular")
    [ResourcesOrderingFilter.filters.popular.toString()]: tc([
      "library.browse.controls.popular",
      "popular"
    ]),
    // i18next-scanner v.1 tc("newest")
    [ResourcesOrderingFilter.filters.newest.toString()]: tc([
      "library.browse.controls.newest",
      "newest"
    ])
  }

  // derive current translated label from current filter
  const currentFilterLabel = filters[currentFilter.toString()]

  return (
    <Dropdown.Trigger
      alignment="left"
      disabled={disabled}
      renderDropdown={() => (
        <Dropdown.Menu closeOnClick>
          {Object.entries(filters).map(([filter, label]) => (
            <Dropdown.Item
              key={label}
              label={label}
              onClick={() => onFilter(filter.split(","))} // morph filter back into array
            />
          ))}
        </Dropdown.Menu>
      )}
    >
      <Button styleType="secondary" disabled={disabled}>
        {currentFilterLabel}
        <span> </span>
        <Pathicon icon="chevron-down" />
      </Button>
    </Dropdown.Trigger>
  )
}

ResourcesOrderingFilter.displayName = "ResourcesOrderingFilter"

ResourcesOrderingFilter.propTypes = {
  onFilter: PropTypes.func.isRequired
}

ResourcesOrderingFilter.filters = {
  featured: ["list_order", "-created"],
  popular: ["-total_registration_count", "list_order"],
  newest: ["-created", "list_order"]
}

export default ResourcesOrderingFilter
