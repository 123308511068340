import { useEffect } from "react"
import { AuthRequestResultType, useStartAuthRequest } from "./api/action"
import { useShowPasswordOption } from "./api/loader"
import SignInAuthRequestView from "./SignInAuthRequestView"

interface SignInAuthRequestViewContainerProps {
  onSuccess: (value: AuthRequestResultType) => void
}

const SignInAuthRequestViewContainer = ({
  onSuccess
}: SignInAuthRequestViewContainerProps) => {
  const [state, action] = useStartAuthRequest()
  const passwordOptionResult = useShowPasswordOption()
  const showPasswordOption =
    passwordOptionResult.value?.showPasswordOption !== false

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await action(formData)
  }

  useEffect(() => {
    if (state.value) onSuccess(state.value)
  }, [state.value])

  return (
    <SignInAuthRequestView
      showPasswordOption={showPasswordOption}
      loading={state.loading}
      success={!!state.value}
      error={state.error?.message}
      onSubmit={handleSubmit}
    />
  )
}

export default SignInAuthRequestViewContainer
