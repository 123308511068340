// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let SchoolSubscriptionPlan
export default (SchoolSubscriptionPlan = class SchoolSubscriptionPlan extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  initialize() {
    this.set("has_free_trial", this.get("plan").trial_period_days > 0)
    const { interval } = this.get("plan")
    return this.set({ plan_type: this.getPlanType(interval) })
  }

  getDisplayName() {
    const name = `${this.get("name")}`
    if (this.get("plan_type") === "monthly") {
      return `${name} - $${this.get("plan").amount}/month`
    } else if (this.get("plan_type") === "yearly") {
      return `${name} - $${this.get("plan").amount}/year`
    } else {
      return name
    }
  }

  getPlanType(interval) {
    const plans = {
      5: "monthly",
      10: "yearly",
      20: "lifetime"
    }
    return plans[interval]
  }
})
