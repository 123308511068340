import { graphql } from "@apollo/client/react/hoc"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CodeEditor from "@pathwright/ui/src/components/form/form-code-editor/LazyCodeEditor"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { Field, Form, Submit } from "react-connect-form-forked"
import PAGE_QUERY from "../graphql/page-query"
import UPDATE_PAGE_MUTATION from "../graphql/update-page-mutation"

const ManagePageAdvanced = ({ card, initialValue, onSubmit }) => {
  return (
    <Card
      card={card}
      title={`${get(initialValue, "name") || "New"} Page Scripts`}
    >
      <Form
        initialValue={initialValue}
        onSubmit={formValues =>
          onSubmit({
            ...formValues,
            id: initialValue.id
          })
        }
      >
        <CardBlock>
          <Fieldset
            title="Scripts"
            header="Any scripts that should be run on page load."
          >
            <Field
              name="scripts"
              type="text"
              placeholder="Any scripts"
              component={CodeEditor}
            />
          </Fieldset>
        </CardBlock>

        <Submit styleType="primary" component={SubmitButton}>
          Save
        </Submit>
      </Form>
    </Card>
  )
}

ManagePageAdvanced.displayName = "ManagePageAdvanced"

ManagePageAdvanced.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    scripts: PropTypes.string
  })
}

export default compose(
  graphql(PAGE_QUERY, {
    options: ({ id, pageType }) => ({
      variables: {
        id,
        page_type: pageType
      }
    }),
    props: ({ data }) => ({
      initialValue: get(data, "school.page")
    })
  }),
  graphql(UPDATE_PAGE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onSubmit: changes =>
        mutate({
          variables: {
            ...ownProps.initialValue,
            ...changes
          }
        })
    })
  })
)(ManagePageAdvanced)
