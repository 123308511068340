import { graphql } from "@apollo/client/react/hoc"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import get from "lodash/get"
import path from "path-browserify"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canEditLibrary } from "../../user/permissions"
import { flattenEdges } from "../../utils/apollo"
import { getStoreResourceUrl } from "../../utils/urls"
import ManageResourceHeaderContainer from "../header/ManageResourceHeaderContainer"
import MANAGE_RESOURCE_QUERY from "./graphql/manage-resource-query"

// used if storepages are enabled
function ManageResourceStorepage({ resource }) {
  const numStorepages = 1 // TODO

  if (numStorepages > 0) {
    return (
      <CardLink
        icon="tag-dollar-sign"
        label={`Manage store ${pluralize("pages", numStorepages)}`}
        meta={`View the store ${pluralize(
          "pages",
          numStorepages
        )} for your course.`}
        to={getStoreResourceUrl(resource.slug)}
      />
    )
  }

  return (
    <CardLink
      icon="tag-dollar-sign"
      label="Create a store page"
      meta="Create a pitch page that helps your members or anyone decide if they should join."
      to="/TODO"
    />
  )
}

function ManageResourceEditorLink({ resource }) {
  return (
    <CardLink
      label="Manage Editors"
      meta="Manage who can design and edit this Path."
      icon="badge-edit"
      to={path.join(window.location.pathname, "/editors/")}
    />
  )
}

function ManageResourceMenu({
  card,
  resource,
  total_group_count,
  onSubmitSuccess,
  t
}) {
  const pwContext = usePathwrightContext()
  const { school } = pwContext
  if (!resource) {
    return <Card card={card} />
  }

  return (
    <Card card={card} title={resource.name}>
      <ManageResourceHeaderContainer
        card={card}
        resource={resource}
        onSubmitSuccess={onSubmitSuccess}
      />
      <CardLink
        icon="layout2"
        label="Update About Page"
        meta="Update title, descriptions, categories, and more."
        to={path.join(window.location.pathname, "/details/")}
      />
      <CardLink
        icon="group"
        label="Manage Cohorts"
        meta="Add, configure, and delete the Cohorts in this Path"
        to={path.join(window.location.pathname, "/cohorts/")}
      />
      <CardLink
        icon="certificate"
        label="Configure completion certificate"
        meta="Enable, disable, or configure a completion certificate."
        to={path.join(window.location.pathname, "/certificate/")}
        preview={resource.has_certificate ? "Enabled" : null}
      />
      {school.platform_version >= 2.8 && (
        <ManageResourceStorepage resource={resource} />
      )}
      {canEditLibrary(pwContext) && (
        <ManageResourceEditorLink card={card} resource={resource} />
      )}
      <CardLink
        icon="trash"
        label="Delete"
        meta="Delete this Path and all it’s data."
        to={path.join(window.location.pathname, "/archive/")}
      />
    </Card>
  )
}

ManageResourceMenu.displayName = "ManageResourceMenu"

ManageResourceMenu.propTypes = {
  id: PropTypes.number.isRequired,
  card: PropTypes.object.isRequired
}

export default graphql(MANAGE_RESOURCE_QUERY, {
  options: ({ id }) => ({
    variables: {
      id
    }
  }),
  props: ({ data }) => ({
    resource: flattenEdges(data.resource),
    total_group_count: get(data, "resource.groups.total", 0)
  })
})(withTranslate(ManageResourceMenu))
