// @ts-ignore
import { Redirect } from "react-router-dom"

import { ApolloClient } from "@apollo/client"
import { hasAuthenticationToken } from "@pathwright/app-web/src/lib/utils/auth-token"
import { getApolloClient } from "@pathwright/web/src/modules/pathwright/PathwrightClient"
import { lazy, Suspense, useRef, useState } from "react"
import InvalidRoute from "./lib/core/routing/InvalidRoute"

const LazyWebAppRoutes = lazy(
  () =>
    import(
      "@pathwright/app-web/src/routes/WebAppRoutesForLegacy" /* webpackChunkName: "WebAppRoutesForLegacy" */
    )
)

// Clears the Apollo cache and selectively refetches the active queries.
const resetClientStore = async () => {
  const client = getApolloClient() as ApolloClient<{}>
  try {
    await client.clearStore()
    await client.refetchQueries({
      // Include all active queries by default, which may be ill-advised unless
      // you also use onQueryUpdated to filter those queries.
      include: "active",
      // Called once for every active query, allowing dynamic filtering:
      onQueryUpdated(observableQuery) {
        switch (observableQuery.queryName) {
          case "InboxQuery":
          case "StripeProductsQuery":
          case "SubscriptionsQuery":
          case "UserGroupSubscriptions":
          case "UserSchoolSubscriptionQuery":
          case "ActivityQuery":
          case "ActivityStats":
          case "PlacesQuery":
          case "UserRegistrationsQuery":
          case "CalStats":
          case "Cohorts":
          case "UserResources":
          case "CodexSpace":
          case "Members":
          case "MentorGroups":
          case "MentorGroupStaffMemberships":
          case "MentorGroupMemberships":
          case "MentorGroupInvitations":
          case "MentorGroup":
          case "UserCompletionsNeedingReview":
          case "UserQuery":
          case "Completions":
          case "InboxReviewers":
          case "DiscussionQuery":
          case "Notes":
          case "PathSyncPlanQuery":
          case "CohortSyncPathQuery":
          case "RegistrationExpirationQuery":
            return hasAuthenticationToken()
          default:
            // By default, we're refetching every query, unless handled by a case above.
            return true
        }
      }
    })
  } catch (error) {
    console.log("error", error)
  }
}

// Ensures auth state is synced between PW2 adn PW3 clients.
export const useAuthSync = (): (() => Promise<void>) => {
  // Use the current auth_token to determine if auth state should be synced.
  const authTokenRef = useRef<string | null>(localStorage.getItem("auth_token"))
  // Use a ref for the auth sync promise to determine 1) if auth state
  // is currently being sycned and 2) awaiting the current sync process to finish
  // rather than initiating another.
  const authSyncPromiseRef = useRef<Promise<void> | null>(null)

  // Sync auth state from app/web with legacy/client
  const authSync = async () => {
    if (authTokenRef.current !== localStorage.getItem("auth_token")) {
      authTokenRef.current = localStorage.getItem("auth_token")
      await resetClientStore()

      // @ts-ignore
      const { signOut, checkSession } = window.App.getStore("auth").action
      if (localStorage.getItem("auth_token")) {
        // this will check the session and trigger bootstrapping the school store. Bit messy, but it works.
        await checkSession().promise
      } else {
        await signOut().promise
      }
      authTokenRef.current = localStorage.getItem("auth_token")
      authSyncPromiseRef.current = null
    }
  }

  const handleAuthSync = () => {
    return (authSyncPromiseRef.current ||= authSync())
  }

  return handleAuthSync
}

// A Map of the /client routes that should be handled by /legacy
const clientRedirectRoutes = new Map([[/^\/$/, "/"]])

type ClientRoutesProps = {
  authSync: ReturnType<typeof useAuthSync>
}

// Wrap the /client routes with a 404 handler so that we can
// redirect 404 routes to the /legacy/client router.
const ClientRoutes = ({ authSync }: ClientRoutesProps) => {
  const [redirectUrl, setRedirectUrl] = useState<string>()
  // const authSync = useAuthSync()

  // Called when /client route changes. We can redirect from any /client
  // route that should be handled by /legacy.
  const handleBeforeLoad = async ({ request }: any) => {
    const { pathname } = new URL(request.url)
    // Get the redirectUrl for current route, if any.
    const [_, redirectUrl] =
      Array.from([...clientRedirectRoutes.entries()]).find(([re, path]) =>
        re.test(pathname) ? path : null
      ) || []

    if (redirectUrl) {
      // Before we redirect, handle syncing auth.
      await authSync()
      // Redirect the route.
      setRedirectUrl(pathname)
      // Just defer loading the route entirely.
      return new Promise(() => {})
    }
    return null
  }

  const handle404 = async ({ request }: any) => {
    const url = new URL(request.url)
    const nextRedirectUrl = url.pathname + url.search
    // Before we redirect, handle syncing auth.
    await authSync()

    if (redirectUrl !== nextRedirectUrl) {
      setRedirectUrl(nextRedirectUrl)
      // This may also be fine...
      // window.App.navigate(nextRedirectUrl)
    } else {
      // Likely not reaching here.
      return <InvalidRoute />
    }

    // Loaders should at least return null.
    return null
  }

  return redirectUrl ? (
    <Redirect to={redirectUrl} />
  ) : (
    <Suspense>
      <LazyWebAppRoutes
        onBeforeLoad={handleBeforeLoad}
        // @ts-ignore
        on404={handle404}
      />
    </Suspense>
  )
}

export default ClientRoutes
