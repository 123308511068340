export const isHTMLEmpty = function(html) {
  if (!html) {
    return true
  }
  const $html = $(`<div>${html}<div>`)
  $html.find("p:empty,div:empty,span:empty").remove()
  html = $.trim($html.html())
  return html.length < 1
}

export const responsifyKatex = function(containerEl) {
  const elements = document.getElementsByClassName("katex")
  return (() => {
    const result = []
    for (let el of Array.from(elements)) {
      let item
      const kw = $(el).width() + $(el).position().left
      const cw = $(containerEl).width()
      if (kw > cw) {
        const percShrink = Math.floor((cw / kw) * 100)
        item = $(el).css("font-size", `${percShrink}%`)
      }
      result.push(item)
    }
    return result
  })()
}
