import { useCallback } from "react"
import { usePathwrightClient } from "../pathwright/PathwrightClient"
import { TAGS_QUERY } from "./graphql"
import { getTagsContext } from "./utils"

const useRefetchTagQueries = context => {
  const client = usePathwrightClient()
  const tagsContext = getTagsContext(context)

  // Handles refetching TAGS_QUERY for top-level and lower-level contexts.
  const refetchQueries = useCallback(() => {
    // Requery top-level context tags.
    client.query({
      query: TAGS_QUERY,
      fetchPolicy: "network-only",
      variables: {
        withContextualTagAttachment: false
      }
    })

    if (tagsContext) {
      // Requery context-level tags.
      client.query({
        query: TAGS_QUERY,
        variables: {
          context: tagsContext,
          withContextualTagAttachment: true
        },
        fetchPolicy: "network-only"
      })
    }
  }, [tagsContext])

  return refetchQueries
}

export default useRefetchTagQueries
