import { getSeedRandomColor } from "@pathwright/ui/src/components/utils/colors"
import get from "lodash/get"

class Avatar extends HTMLElement {
  constructor() {
    super()
    const shadow = this.attachShadow({ mode: "open" })
    const anchor = document.createElement("a")

    if (this.src) {
      const img = document.createElement("img")
      img.setAttribute("src", this.src)
      anchor.appendChild(img)
    } else {
      anchor.innerHTML = this.initials
    }

    const style = document.createElement("style")
    style.textContent = `
      ${this.getStyles(this.size)}
      @media screen and (max-width: 500px) {
        ${this.getStyles(this.size * 0.55)}
      }
    `
    shadow.appendChild(style)
    shadow.appendChild(anchor)
  }

  getStyles(size) {
    return `
      a {
        display: inline-block;
        vertical-align: top;
        position: relative;
        border-radius: 50%;
        cursor: default;
        color: inherit;
        padding-left: 2px;
        text-decoration: none;
        width: ${size}px;
        height: ${size}px;
        line-height: ${size + size / 30}px;
        font-size: ${size / 2.1425}px;
        display: block;
        ${
          !this.src
            ? `
            background: ${this.randomColor};
            text-align: center;
            color: white;
            letter-spacing: 2px;
            text-transform: uppercase;
        `
            : ""
        }
      }
      a:hover {
        color: ${this.src ? "inherit" : "white"};
        text-decoration: none;
      }
      a img {
        border-radius: 50%;
        height: inherit;
        width: inherit;
        position: absolute;
        top: 0;
        left: 0;
        width: ${size}px;
        height: ${size}px;
      }
    `
  }

  get size() {
    const CLASSES_SIZE_MAP = {
      "x-small": 24,
      small: 35,
      medium: 48,
      large: 115,
      "x-large": 150
    }

    // Set size based on size or src attribute unlass a class size has been set
    const klass = Object.keys(CLASSES_SIZE_MAP).find(klass =>
      this.classList.contains(klass)
    )

    if (klass) {
      return CLASSES_SIZE_MAP[klass]
    }

    // finding matches in the src attribute for width=num or numXnum, else use @size
    return parseInt(
      this.hasAttribute("size")
        ? this.getAttribute("size")
        : this.hasAttribute("src")
        ? get(this.gettAttribute("src").match(/width=(\d+)/g), "[1]") ||
          get(this.gettAttribute("src").match(/(\d+)x\d+/), "[1]")
        : 0
    )
  }

  get src() {
    return this.hasAttribute("src") &&
      !/generic_defaults/.test(this.getAttribute("src"))
      ? this.getAttribute("src")
      : null
  }

  get initials() {
    return `${
      this.hasAttribute("first-name") && this.getAttribute("first-name")[0]
        ? this.getAttribute("first-name")[0].toUpperCase()
        : ""
    }${
      this.hasAttribute("last-name") && this.getAttribute("last-name")[0]
        ? this.getAttribute("last-name")[0].toUpperCase()
        : ""
    }`
  }

  get randomColor() {
    const userIdOrInitials = this.getAttribute("user-id")
      ? parseInt(this.getAttribute("user-id"))
      : this.initials.toLowerCase()
    return getSeedRandomColor(userIdOrInitials)
  }
}

window.customElements.define("pw-avatar", Avatar)
