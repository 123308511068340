import { Box, useEventListener } from "@chakra-ui/react"
import classnames from "classnames"
import { useEffect, useRef, useState } from "react"
import { useCertificateState } from "../context/CertificateState"
import { getObjectIndexFromClassName } from "./utils"

function getDefaultSelection() {
  return {
    start: {
      top: 0,
      left: 0
    },
    stop: {
      x: 0,
      y: 0
    }
  }
}

function MultiSelect() {
  const { handleSelectTextObjectIndexes } = useCertificateState()
  const [isSelecting, setIsSelecting] = useState(false)
  const [selection, setSelection] = useState(getDefaultSelection)
  const [objectRects, setObjectRects] = useState({})
  const [selectedIndexes, setSelectedIndexes] = useState([])
  const selectionRef = useRef(null)

  function handleObjectRects() {
    // Find all the *top-level* object wich we want to check for selection intersection.
    const objectRects = [
      ...document.querySelectorAll(".CertificateText > .Object")
    ].reduce((acc, node) => {
      // Grab the index from the class name (should use an ID instead?).
      const index = getObjectIndexFromClassName(node)
      // Store the rect.
      acc[index] = node.getBoundingClientRect()
      return acc
    }, {})
    setObjectRects(objectRects)
  }

  function getPosition(e) {
    return {
      top: e.clientY,
      left: e.clientX
    }
  }

  function handleSelection(e) {
    if (isSelecting) {
      e.preventDefault()
      e.stopPropagation()
      const position = getPosition(e)
      setSelection((selection) => {
        return {
          start: selection.start,
          stop: position
        }
      })
    }
  }

  const handleIsSelecting = (isSelected) => (e) => {
    if (
      !isSelected ||
      (!e.target.closest(".Object") &&
        !e.target.closest(".CertificateDrawer") &&
        !e.target.closest(".CertificateDrawerLauncher"))
    ) {
      const position = getPosition(e)
      setIsSelecting(isSelected)
      // Set initial selection.
      if (isSelected) {
        setSelection({
          start: position,
          stop: position
        })
        handleObjectRects()
      } else {
        setSelection(getDefaultSelection)
      }
    } else {
      setIsSelecting(false)
    }
  }

  useEventListener("mousedown", handleIsSelecting(true), document, [])
  useEventListener("mouseup", handleIsSelecting(false), document, [])

  useEffect(() => {
    if (!isSelecting) {
      // Finally, select the indexes now.
      if (selectedIndexes.length) {
        handleSelectTextObjectIndexes(selectedIndexes)
      }
      // Reset after selection ends.
      setObjectRects({})
      setSelectedIndexes([])
    }
  }, [isSelecting])

  // Derive the selected indexes based on the object rects intersecting the current selection.
  useEffect(() => {
    if (selectionRef.current) {
      // Use latest rect values for selection.
      const selectionRect = selectionRef.current.getBoundingClientRect()
      const indexes = Object.entries(objectRects).reduce(
        (acc, [index, objectRect]) => {
          const topContained =
            selectionRect.top <= objectRect.top &&
            selectionRect.bottom >= objectRect.top &&
            selectionRect.right >= objectRect.left &&
            selectionRect.left <= objectRect.right
          const bottomContained =
            selectionRect.top <= objectRect.bottom &&
            selectionRect.bottom >= objectRect.bottom
          selectionRect.right >= objectRect.left &&
            selectionRect.left <= objectRect.right
          const leftContained =
            selectionRect.left <= objectRect.left &&
            selectionRect.right >= objectRect.left &&
            selectionRect.top <= objectRect.bottom &&
            selectionRect.bottom >= objectRect.top
          const rightContained =
            selectionRect.left <= objectRect.right &&
            selectionRect.right >= objectRect.right &&
            selectionRect.top <= objectRect.bottom &&
            selectionRect.bottom >= objectRect.top

          // The object rect must be contained in at least one way.
          if (
            topContained ||
            bottomContained ||
            leftContained ||
            rightContained
          ) {
            acc.push(parseInt(index))
          }
          return acc
        },
        []
      )
      setSelectedIndexes(indexes)
    }
  }, [selection])

  // Find the quadrant where quadrantes are:
  // Q1 | Q2
  // -------
  // Q3 | Q4

  let position

  // Q1
  if (
    selection.start.top >= selection.stop.top &&
    selection.start.left >= selection.stop.left
  ) {
    position = {
      top: selection.stop.top,
      left: selection.stop.left
    }
    // Q2
  } else if (
    selection.start.top >= selection.stop.top &&
    selection.start.left <= selection.stop.left
  ) {
    position = {
      top: selection.stop.top,
      left: selection.start.left
    }
    // Q3
  } else if (
    selection.start.top <= selection.stop.top &&
    selection.start.left <= selection.stop.left
  ) {
    position = {
      top: selection.start.top,
      left: selection.start.left
    }
    // Q4
  } else {
    position = {
      top: selection.start.top,
      left: selection.stop.left
    }
  }

  let dimensions = {
    height: Math.max(2, Math.abs(selection.stop.top - selection.start.top)),
    width: Math.max(2, Math.abs(selection.stop.left - selection.start.left))
  }

  const selectionClassNames = selectedIndexes.reduce((acc, index) => {
    acc[`isSelecting__${index}`] = true
    return acc
  }, {})

  return (
    <Box
      className={classnames("CertificateMultiSelect", {
        isSelecting,
        ...selectionClassNames
      })}
      pos="fixed"
      inset={0}
      // The trick to getting UX working well between multi-select and other interactive elements
      // is to suppress the mult-select element until the mousedown event, at which point we bring
      // the multi-select element to the front in order to capture the mousemove events.
      zIndex={isSelecting ? 100 : 0}
      onMouseMove={handleSelection}
    >
      {isSelecting && (
        <Box
          ref={selectionRef}
          key={1}
          border="2px rgb(100, 100, 100) dashed"
          position="absolute"
          style={{
            ...position,
            ...dimensions
          }}
        />
      )}
    </Box>
  )
}

export default MultiSelect
