
        
    const runtime = require("../../../../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("../../../lib/uniform/views/templates/uniform-macros.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
            const __nunjucks_template_import__filter_t = require("../../../nunjucks/t.js");
            __nunjucks_module_dependencies__.filters['t'] = {
                module: __nunjucks_template_import__filter_t && __nunjucks_template_import__filter_t.default || __nunjucks_template_import__filter_t,
                async: false
            };
            const __nunjucks_template_import__filter_trans = require("../../../nunjucks/trans.js");
            __nunjucks_module_dependencies__.filters['trans'] = {
                module: __nunjucks_template_import__filter_trans && __nunjucks_template_import__filter_trans.default || __nunjucks_template_import__filter_trans,
                async: false
            };
    
        
            __nunjucks_module_dependencies__.templates["discussion/views/templates/discussion-form.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("lib/uniform/views/templates/uniform-macros.html", false, "discussion/views/templates/discussion-form.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("form", t_1);
output += "\n\n";
output += runtime.suppressValue((lineno = 2, colno = 19, runtime.callWrap(runtime.memberLookup((t_1),"flash_message"), "form[\"flash_message\"]", context, [])), env.opts.autoescape);
output += "\n\n<div class=\"discussion-detail-inputs\">\n  <div data-row-span=\"1\">\n    <div data-field-span=\"1\">\n      <label for=\"id_response_title\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "Question","discussion.form.question"), env.opts.autoescape);
output += "</label>\n      ";
output += runtime.suppressValue((lineno = 8, colno = 16, runtime.callWrap(runtime.memberLookup((t_1),"text"), "form[\"text\"]", context, ["response_title",{"required": true,"maxlength": 255},{"nowrap": true,"bind": "model:title","placeholder": env.getFilter("trans").call(context, "","discussion.form.question_placeholder")}])), env.opts.autoescape);
output += "\n    </div>\n  </div>\n\n  <div data-row-span=\"1\">\n    <div data-field-span=\"1\">\n      ";
if(runtime.contextOrFrameLookup(context, frame, "rich_text_enabled")) {
output += "\n        ";
output += runtime.suppressValue((lineno = 15, colno = 22, runtime.callWrap(runtime.memberLookup((t_1),"richtext"), "form[\"richtext\"]", context, ["response_body",{"required": false},{"nowrap": true,"label": env.getFilter("trans").call(context, "","discussion.form.more_info"),"placeholder": env.getFilter("trans").call(context, "","discussion.form.more_info_placeholder"),"rt_config": "discussion","bind": "model:body"}])), env.opts.autoescape);
output += "\n      ";
;
}
else {
output += "\n        ";
output += runtime.suppressValue((lineno = 17, colno = 22, runtime.callWrap(runtime.memberLookup((t_1),"textarea"), "form[\"textarea\"]", context, ["response_body",{"required": false},{"nowrap": true,"label": env.getFilter("trans").call(context, "","discussion.form.more_info"),"placeholder": env.getFilter("trans").call(context, "","discussion.form.more_info_placeholder"),"bind": "model:body"}])), env.opts.autoescape);
output += "\n      ";
;
}
output += "\n    </div>\n  </div>\n<!--   <div class=\"reponse-form-input discussion-body-text\">\n  </div> -->\n\n\n  ";
if(runtime.inOperator(10,runtime.contextOrFrameLookup(context, frame, "types")) && runtime.inOperator(5,runtime.contextOrFrameLookup(context, frame, "types"))) {
output += "\n  <div data-row-span=\"1\">\n    <div data-field-span=\"1\" class=\"radio-buttons\">\n      <label>";
output += runtime.suppressValue(env.getFilter("t").call(context, "Question type","discussion.form.question_type"), env.opts.autoescape);
output += "</label>\n      <ul class=\"question-type-options\" data-radio=\"is_discussion_question\">\n        ";
if(runtime.inOperator(10,runtime.contextOrFrameLookup(context, frame, "types"))) {
output += "\n        <li rv-class-selected=\"state:discussion_question\">\n          <label class=\"radio option-discussion\">\n            <input type=\"radio\" name=\"option_is_discussion_question\" rv-checked=\"state:is_discussion_question\" value=\"true\">\n            <pw-avatar class=\"small\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"thumb"), env.opts.autoescape);
output += "\"></pw-avatar>\n            <span>\n              <strong>";
output += runtime.suppressValue(env.getFilter("t").call(context, "Discussion prompt","discussion.form.prompt"), env.opts.autoescape);
output += ":</strong> ";
output += runtime.suppressValue(env.getFilter("t").call(context, "a question that prompts learners to connect with their classmates and apply what they've learned in this step","discussion.form.prompt_content"), env.opts.autoescape);
output += "\n              (";
output += runtime.suppressValue(env.getFilter("t").call(context, "learn more about","discussion.form.learn_more"), env.opts.autoescape);
output += " <a href=\"https://www.pathwright.com/blog/how-to-write-discussion-questions/\" target=\"_blank\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "writing discussion questions","discussion.form.writing_questions"), env.opts.autoescape);
output += "</a>)\n            </span>\n          </label>\n        </li>\n        ";
;
}
output += "\n        ";
if(runtime.inOperator(5,runtime.contextOrFrameLookup(context, frame, "types"))) {
output += "\n        <li rv-class-selected=\"state:personal_question\">\n          <label class=\"radio option-user\">\n            <input type=\"radio\" name=\"option_is_discussion_question\" rv-checked=\"state:is_discussion_question\" value=\"false\">\n            <pw-avatar\n              class=\"small\"\n              src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"profile")),"image_thumb"), env.opts.autoescape);
output += "\"\n              first-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"first_name"), env.opts.autoescape);
output += "\"\n              last-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"last_name"), env.opts.autoescape);
output += "\"></pw-avatar>\n            <span><strong>";
output += runtime.suppressValue(env.getFilter("t").call(context, "Question","discussion.form.question"), env.opts.autoescape);
output += ":</strong> ";
output += runtime.suppressValue(env.getFilter("t").call(context, "a personal question from you to this cohort","discussion.form.personal_question"), env.opts.autoescape);
output += ".</span>\n          </label>\n        </li>\n        ";
;
}
output += "\n      </ul>\n    </div>\n  </div>\n  ";
;
}
else {
if(runtime.inOperator(10,runtime.contextOrFrameLookup(context, frame, "types")) && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perms")),"can_post_discussion_question")) {
output += "\n  <div data-row-span=\"1\">\n    <div data-field-span=\"1\">\n      <label>";
output += runtime.suppressValue(env.getFilter("t").call(context, "Question type","discussion.form.question_type"), env.opts.autoescape);
output += "</label>\n      <ul class=\"question-type-options\">\n        <li>\n          <label class=\"option-discussion\">\n            <pw-avatar class=\"small\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"thumb"), env.opts.autoescape);
output += "\"></pw-avatar>\n            <span>\n              <strong>";
output += runtime.suppressValue(env.getFilter("t").call(context, "Discussion prompt","discussion.form.prompt"), env.opts.autoescape);
output += ":</strong> ";
output += runtime.suppressValue(env.getFilter("t").call(context, "a question that prompts learners to connect with their classmates and apply what they've learned in this step","discussion.form.prompt_content"), env.opts.autoescape);
output += "\n              (";
output += runtime.suppressValue(env.getFilter("t").call(context, "learn more about","discussion.form.learn_more"), env.opts.autoescape);
output += " <a href=\"https://www.pathwright.com/blog/how-to-write-discussion-questions/\" target=\"_blank\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "writing discussion questions","discussion.form.writing_questions"), env.opts.autoescape);
output += "</a>)\n            </span>\n          </label>\n        </li>\n      </ul>\n    </div>\n  </div>\n  ";
;
}
else {
if(runtime.inOperator(5,runtime.contextOrFrameLookup(context, frame, "types"))) {
output += "\n  <div data-row-span=\"1\">\n    <div data-field-span=\"1\">\n      <label>";
output += runtime.suppressValue(env.getFilter("t").call(context, "Question type","discussion.form.question_type"), env.opts.autoescape);
output += "</label>\n      <ul class=\"question-type-options\">\n        <li>\n          <label class=\"option-user\">\n            <pw-avatar\n              class=\"small\"\n              src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"profile")),"image_thumb"), env.opts.autoescape);
output += "\"\n              first-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"first_name"), env.opts.autoescape);
output += "\"\n              last-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"last_name"), env.opts.autoescape);
output += "\"></pw-avatar>\n            <span><strong>";
output += runtime.suppressValue(env.getFilter("t").call(context, "Question","discussion.form.question"), env.opts.autoescape);
output += ":</strong> ";
output += runtime.suppressValue(env.getFilter("t").call(context, "a personal question from you to this cohort","discussion.form.personal_question"), env.opts.autoescape);
output += ".</span>\n          </label>\n        </li>\n      </ul>\n    </div>\n  </div>\n  ";
;
}
;
}
;
}
output += "\n</div>\n\n<footer>\n  ";
if(runtime.contextOrFrameLookup(context, frame, "support_enabled")) {
output += "\n  <p class=\"discussion-support-prompt\">\n    ";
output += runtime.suppressValue(env.getFilter("t").call(context, "Have a technical question?","discussion.form.tech_question.question"), env.opts.autoescape);
output += " <a href=\"javascript:void(0)\" class=\"show-support brand-color\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "Contact support","discussion.form.tech_question.contact"), env.opts.autoescape);
output += "</a> ";
output += runtime.suppressValue(env.getFilter("t").call(context, "directly","discussion.form.tech_question.directly"), env.opts.autoescape);
output += ".\n  </p>\n  ";
;
}
output += "\n\n  <button class=\"pw-form-submit ButtonLegacy medium rounded primary interactive\" type=\"submit\">\n    ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "action_label"), env.opts.autoescape);
output += "\n  </button>\n\n  <span class=\"pw-form-cancel\">\n    ";
output += runtime.suppressValue(env.getFilter("t").call(context, "or","discussion.form.tech_question.or"), env.opts.autoescape);
output += " <a href=\"#\" class=\"cancel-discussion brand-color\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "cancel","discussion.form.tech_question.cancel"), env.opts.autoescape);
output += "</a>\n  </span>\n\n</footer>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "discussion/views/templates/discussion-form.html",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "discussion/views/templates/discussion-form.html",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["discussion/views/templates/discussion-form.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    