import get from "lodash/get"
import React from "react"
import StripeAlert from "../commerce/manage/stripe/StripeAlert"
import { flattenEdges } from "../utils/apollo"
import useAuthAwareQuery from "../utils/useAuthAwareQuery"
import SUBSCRIPTIONS_QUERY from "./graphql/subscriptions-query"

export const useSubscriptoinsNeedingActionQuery = (options = {}) => {
  const subscriptionsQuery = useAuthAwareQuery(SUBSCRIPTIONS_QUERY, {
    variables: {
      // only want those subscriptions where the payment_intent_needing_action_id is not null
      paymentIntentNeedingActionIdFilter: { neq: null }
    },
    ...options
  })

  return subscriptionsQuery
}

const SubscriptionsNeedingAction = () => {
  const subscriptionsQuery = useSubscriptoinsNeedingActionQuery()

  const subscriptions = flattenEdges(
    get(subscriptionsQuery, "data.subscriptions")
  )

  if (!subscriptions || !subscriptions.length) return null

  return (
    <React.Fragment>
      {subscriptions.map(subscription => (
        <StripeAlert.Confirm
          key={subscription.id}
          subscriptionID={subscription.id}
        />
      ))}
    </React.Fragment>
  )
}

SubscriptionsNeedingAction.displayName = "SubscriptionsNeedingAction"

export default SubscriptionsNeedingAction
