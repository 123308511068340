import React from "react"
import styled from "styled-components"
import { overlay } from "./ui"

const Container = styled.div`
  ${overlay}
  border-radius: inherit;
`

const GradientOverlay = styled.div`
  ${overlay}
  border-radius: inherit;
  background: linear-gradient(
    180deg,
    rgba(51, 49, 50, 0.5) 0%,
    rgba(51, 49, 50, 0.8) 100%
  );
`

// repeats a grid of dots sized at 4px spaced 50px apart
const Dots = styled.div`
  ${overlay}
  border-radius: inherit;
  background-image: radial-gradient(
    rgba(256, 256, 256, 0.2) ${p => `${(p.dotSize / p.dotSpacing) * 100}%`},
    transparent ${p => `${(p.dotSize / p.dotSpacing) * 100}%`}
  );
  background-position: 0 0,
    ${p => `${p.dotSpacing / 2}px ${p.dotSpacing / 2}px`};
  background-size: ${p => `${p.dotSpacing}px ${p.dotSpacing}px`};
`

const DotBackground = ({ dotSize = 4, dotSpacing = 50 }) => (
  <Container className="DotBackground">
    <GradientOverlay />
    <Dots dotSize={dotSize} dotSpacing={dotSpacing} />
  </Container>
)

export default DotBackground
