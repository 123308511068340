import React from "react"
import PropTypes from "prop-types"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import { HELP_LINK_SUBSCRIPTIONS } from "../../help/constants"
import FeatureLock from "../../lib/FeatureLock"

const ManageSubscriptions = ({ card, subscription_plans }) => {
  return (
    <Card card={card} title="Subscriptions">
      <FeatureLock has="sell-subscriptions">
        {({
          hasFeatures,
          activationLocked,
          lockedFeatures,
          getPlansNameList
        }) =>
          !hasFeatures ? (
            <BlankSlate
              icon="recur"
              heading="Grow long term community & revenue"
              body={
                <span>
                  Add monthly or yearly subscription plans to encourage long
                  term community membership and steadily grow recurring revenue.{" "}
                  <a href={HELP_LINK_SUBSCRIPTIONS} target="_blank">
                    Learn all about Pathwright's powerful subscription features{" "}
                    <Pathicon icon="chevron-right" />
                  </a>
                </span>
              }
              meta={`Included in ${getPlansNameList(
                lockedFeatures["sell-subscriptions"]
              )}`}
              primaryAction={{
                children: activationLocked ? "Activate" : "Upgrade Plan",
                to: "/pricing/plan/"
              }}
            />
          ) : subscription_plans && subscription_plans.length > 0 ? (
            subscription_plans.map(plan => {
              // TODO: show subscription plans
              return null
            })
          ) : (
            <BlankSlate
              icon="recur"
              heading="Let's setup subscription plans"
              body={
                <span>
                  A new subscription plan manager is under construction, but in
                  the mean time, our Guidance Team is happy to create
                  subscriptions.{" "}
                  <a href={HELP_LINK_SUBSCRIPTIONS} target="_blank">
                    Here's the information we'll need.
                  </a>
                </span>
              }
              meta="(We'll get back to you in 1 business day or sooner)"
              primaryAction={{
                children: "Setup Subscriptions",
                href: "mailto:hello@pathwright.com"
              }}
            />
          )
        }
      </FeatureLock>
    </Card>
  )
}

ManageSubscriptions.displayName = "ManageSubscriptions"

ManageSubscriptions.propTypes = {
  card: PropTypes.object.isRequired,
  subscription_plans: PropTypes.array
}

export default ManageSubscriptions
