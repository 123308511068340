import { Box } from "@chakra-ui/react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import BGMode from "./BGMode"
import SpaceSignupFlow from "./SpaceSignupFlow"

// @ts-ignore
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"

function PathwrightSpaceSignupFlow() {
  const {
    school: { stripe_publishable_api_key }
  } = usePathwrightContext()

  return <SpaceSignupFlow stripeApiKey={stripe_publishable_api_key!} />

  return (
    <BGMode w="100vw" minH="100vh">
      <ThemeBackground />
      <Box w="100vw" minH="100vh" pos="relative">
        <SpaceSignupFlow stripeApiKey={stripe_publishable_api_key!} />
      </Box>
    </BGMode>
  )
}

export default PathwrightSpaceSignupFlow
