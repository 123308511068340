import { snakeCase } from "change-case"

// Util for converting the keys of an object using a provided
// convertMethod, such as snakeCase.
const convertObjectKeys = (obj, convertMethod) => {
  if (!obj) return obj
  if (typeof obj !== "object") return obj

  // Convert the object's keys using the provided convertMethod.
  const convertedObj = Object.entries(obj).reduce(
    (obj, [key, value]) => ({
      ...obj,
      [convertMethod(key)]: value
    }),
    {}
  )

  return convertedObj
}

export const convertObjectKeysToSnakeCase = obj =>
  convertObjectKeys(obj, snakeCase)

export default convertObjectKeys
