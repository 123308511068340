import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Button from "./Button"

const StyledButton = styled(Button)`
  text-align: left;
  white-space: normal;
  padding: 20px;

  .Button__content-wrapper {
    justify-content: flex-start;
  }
`

const BlankButton = props => {
  const { inverted } = props
  const className = classnames(
    "BlankButton",
    {
      "BlankButton--inverted": inverted
    },
    props.className
  )

  return (
    <StyledButton
      {...props}
      className={className}
      styleType="empty"
      size="custom"
      rounded
      stretch
    />
  )
}

BlankButton.displayName = "BlankButton"

BlankButton.propTypes = {
  icon: PropTypes.string
}

BlankButton.defaultProps = {
  icon: "plus-circle"
}

export default BlankButton
