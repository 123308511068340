import React from "react"
import styled from "styled-components"

const Wrapper = styled.label`
  display: flex;
  margin: 0;
  padding: 5px 0;
  font-size: 16px;
  line-height: 25px;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);

  & + & {
    margin-top: 10px;
  }

  & > span {
    position: relative;
    top: 1px;
    display: flex;
    align-items: baseline;
  }

  input[type="radio"] {
    flex-shrink: 0;
    border: none;
    outline: none;
    margin-right: 8px;

    & + div:last-child {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
    }
  }

  input[type="number"] {
    display: inline;
  }
`

export const RadioButton = ({
  className,
  id,
  name,
  value,
  checked,
  children,
  onChange,
  onBlur,
  disabled,
  style
}) => {
  return (
    <Wrapper className={`RadioButton ${className || ""}`} style={style}>
      <input
        type="radio"
        name={name}
        id={id || name || null}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <span>{children}</span>
    </Wrapper>
  )
}

RadioButton.displayName = "RadioButton"

RadioButton.defaultProps = {
  disabled: false
}

export default RadioButton
