import gql from "graphql-tag"

export default gql`
  mutation(
    $id: Int!
    $send_due_notifications: Boolean
    $send_discussion_notifications: Boolean
  ) {
    updateRegistration(
      id: $id
      send_due_notifications: $send_due_notifications
      send_discussion_notifications: $send_discussion_notifications
    ) {
      id
      send_due_notifications
      send_discussion_notifications
    }
  }
`
