import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import isEqual from "react-fast-compare"
import FieldWrapper from "../form-utils/FieldWrapper"
import "./Radio.css"

class RadioButton extends React.Component {
  static contextTypes = {
    radioGroup: PropTypes.shape({
      id: PropTypes.string,
      selected: PropTypes.any,
      onChange: PropTypes.func
    })
  }

  constructor(props) {
    super(props)
    const { value } = props
    this.state = { value }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      return this.setState({ value: nextProps.value })
    }
  }

  handleChange = value => {
    this.setState({ value })
    this.context.radioGroup.onChange(value)
    return typeof this.props.onChange === "function"
      ? this.props.onChange(value)
      : undefined
  }

  render() {
    const { value } = this.state
    const { radioGroup } = this.context
    let { children, className, disabled } = this.props
    className = classnames("RadioButton--old", className)
    const isChecked = isEqual(value, radioGroup.selected)

    return (
      <label className={className} disabled={disabled}>
        <input
          type="radio"
          value={value != null ? value : ""}
          name={radioGroup.id}
          checked={isChecked}
          onChange={() => this.handleChange(value)}
          disabled={disabled}
        />
        <span>
          {_.isFunction(children)
            ? children({ value, onChange: this.handleChange })
            : children}
        </span>
      </label>
    )
  }
}

class RadioGroup extends React.Component {
  getChildContext() {
    const radioGroup = {
      id: this.props.id,
      selected: this.props.value,
      onChange: this.props.onChange
    }
    return { radioGroup }
  }

  render() {
    let { children, description, className } = this.props

    className = classnames("RadioGroup--old", className)

    return (
      <FieldWrapper
        {...Object.assign({}, this.props, {
          className: className,
          fixedLabel: true
        })}
      >
        {description ? (
          <p className="RadioGroup__description">{description}</p>
        ) : (
          undefined
        )}
        {children}
      </FieldWrapper>
    )
  }
}

RadioGroup.displayName = "RadioGroup"

RadioGroup.childContextTypes = {
  radioGroup: PropTypes.shape({
    // Sets child RadioButton name to group inputs.
    id: PropTypes.string,
    // Compared against child RadioButton value to set checked.
    selected: PropTypes.any,
    // Hook for child RadioButton to trigger.
    onChange: PropTypes.func
  })
}

export default { RadioGroup, RadioButton }
