import { PRICING_STEP_PLAN } from "../store/constants"
import PricingPlanUpgrade from "./components/plans/PricingPlanUpgrade"
import PricingContainer from "./components/PricingContainer"
const { createCardRoutes } = require("lib/core/routing/card-routing")

const billingStore = window.App.getStore("billing")

const loadPlans = params => {
  billingStore.action.loadPlans()
  if (params.billingStep === "plan") {
    billingStore.action.selectStepByKey(PRICING_STEP_PLAN)
  }
}

const onEnterPricing = (nextState, replace) => {
  const { location } = nextState

  if (!window.App.getStore("navigation").get("resource_launched_from")) {
    window.App.getStore("navigation").action.setResourceLaunchedFrom()
  }

  if (location.pathname === "/pricing/") {
    location.pathname = "/pricing/plan/"
    return replace(location)
  }

  const { params } = nextState.match

  loadPlans(params)
}

const basePath = "/pricing/:billingStep(plan|subscribed|core)?/"

export const upgradeCardRoutes = createCardRoutes(
  basePath,
  {
    upgrade: {
      path: "upgrade/:planId(\\d+)/",
      getDefaultLaunchedFrom: () => "/pricing/plan/",
      middleware: ({ match }) => loadPlans(match.params),
      component: props => (
        <PricingPlanUpgrade
          card={props.card}
          planId={parseInt(props.match.params.planId)}
        />
      )
    }
  },
  PricingContainer
)

export default {
  path: basePath,
  onEnter: onEnterPricing,
  component: PricingContainer
}
