import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import styled from "styled-components"
import LoadingCircle from "../loading/LoadingCircle"
import Carousel from "../carousel/Carousel"
import {
  PRIMARY_WHITE,
  PRIMARY_GRAY,
  TERTIARY_GRAY,
  TERTIARY_WHITE
} from "../utils/colors"
import { useObserveSize } from "../observers/ObserveSizeContext"

const Row = styled.ul`
  z-index: 100;
  padding: 0;
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
`

const Dot = styled.li`
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  border-radius: 5px;
  border: 1px solid ${props => (props.inverted ? PRIMARY_WHITE : PRIMARY_GRAY)};
  list-style-type: none;
  cursor: pointer;
  transition: background-color 100ms ease-in;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  background-color: ${props =>
    // prettier-ignore
    props.inverted
      ? props.active ? PRIMARY_WHITE : props.filled ? TERTIARY_WHITE : "transparent"
      : props.active ? PRIMARY_GRAY : props.filled ? TERTIARY_GRAY : "transparent"};
  &:hover {
    background-color: ${props =>
      props.inverted ? PRIMARY_WHITE : PRIMARY_GRAY};
  }
`

const Container = styled.div`
  width: 100%;

  .Carousel {
    overflow: hidden;
  }
`

class DotFlow extends Component {
  state = {
    dotIndex: null
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const prevDotIndex = prevState.dotIndex
    const currentDotIndex = this.indexes.dot
    // Reset the dot state if the current dot has become completed
    if (
      prevDotIndex === currentDotIndex &&
      this.dotIsComplete(this.dots[currentDotIndex])
    ) {
      this.setState({ dotIndex: null })
    }
  }

  get indexes() {
    return this.getIndexesForDotIndex(this.state.dotIndex)
  }

  // Determine what dot and step the flow is currently at
  getIndexesForDotIndex = dot => {
    const indexes = {
      dot: 0,
      step: 0
    }
    for (let di = 0; di < this.dots.length; di++) {
      indexes.step = 0
      for (let si = 0; si < this.dots[di].length; si++) {
        if (this.dots[di].length > 0) {
          const step = this.dots[di][si]
          // This is the next step if it is incomplete
          if (dot === di || typeof dot !== "number") {
            if (!step.isComplete) {
              return indexes
            }
          }
          // Othwerwise, this is the next step if it's the final step in the current dot
          if (dot === di && this.dots[di].length - 1 === si) {
            return indexes
          }
        }
        indexes.step += 1
      }
      indexes.dot += 1
    }
    return indexes
  }

  get step() {
    return this.dots[this.indexes.dot][this.indexes.step]
  }

  //
  get renderableSteps() {
    const numCompletedDots = this.dots.filter(this.dotIsComplete).length
    return (
      this.dots
        // filter steps whose index is less than or equal to the number of completed dots
        .filter((dot, di) => di <= numCompletedDots)
        // map each dot to the
        .map((dot, di) => {
          const indexes = this.getIndexesForDotIndex(di)
          return this.dots[indexes.dot][indexes.step]
        })
    )
  }

  get dots() {
    return this.props.dots
  }

  dotIsComplete(dot) {
    return dot.reduce((isComplete, step) => isComplete && step.isComplete, true)
  }

  get flowIsComplete() {
    return this.dots.every(this.dotIsComplete)
  }

  get showDots() {
    return this.dots.length > 1 && this.indexes.dot < this.dots.length
  }

  renderDots = () => {
    return this.showDots ? (
      <Row>
        {this.dots.map((dot, di) => (
          <Dot
            key={`dot-${di}`}
            onClick={e =>
              this.setState({
                dotIndex: di
              })
            }
            filled={this.dotIsComplete(dot)}
            active={di === this.indexes.dot}
            inverted={this.props.inverted}
            disabled={
              this.props.freeze ||
              // disabled if previous dot is incomplete
              (!!this.dots[di - 1] && !this.dotIsComplete(this.dots[di - 1]))
            }
          />
        ))}
      </Row>
    ) : null
  }

  renderStep = step => {
    const StepComponent = step.component
    const passProps = step.props || {}
    return <StepComponent {...passProps} />
  }

  renderCompleted() {
    const CompletionComponent = this.props.completionComponent
    return <CompletionComponent />
  }

  renderLoading() {
    return <LoadingCircle inverted={this.props.inverted} />
  }

  renderCarousel() {
    const {
      loading,
      inverted,
      dots,
      completionComponent,
      ...passProps
    } = this.props

    return (
      <Carousel
        {...passProps}
        activeIndex={this.indexes.dot}
        onChange={activeIndex => {
          if (this.state.dotIndex !== activeIndex) {
            this.setState({ dotIndex: activeIndex })
          }
        }}
        items={this.renderableSteps}
        itemWidth={this.props.rectValue.width}
        renderItem={({ item, index }) => this.renderStep(item)}
      />
    )
  }

  render() {
    return (
      <Container>
        {this.renderDots()}
        {this.props.loading
          ? this.renderLoading()
          : this.flowIsComplete
          ? this.renderCompleted()
          : this.props.rectValue.width
          ? this.renderCarousel()
          : null}
      </Container>
    )
  }
}

DotFlow.displayName = "DotFlow"

DotFlow.propTypes = {
  freeze: PropTypes.bool, // prevent user from navigating to different dot
  loading: PropTypes.bool,
  dots: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        isComplete: PropTypes.bool.isRequired,
        component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
          .isRequired,
        props: PropTypes.object
      })
    )
  ),
  completionComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

export default props => {
  const [rectValue, _, setNode] = useObserveSize()
  return <DotFlow {...props} rectValue={rectValue} ref={setNode} />
}
