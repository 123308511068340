import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import OverlayTrigger from "../overlay/OverlayTrigger"
import "./DropdownTrigger.css"

// TODO: merge into Overlay context?
const DropDownTriggerContext = React.createContext()

export const useDropDownTriggerContext = () =>
  React.useContext(DropDownTriggerContext)

const DropdownTrigger = ({
  style,
  children,
  className,
  trigger,
  placement,
  alignment,
  enterTransition,
  leaveTransition,
  closeOnTriggerClick,
  closeOnOverlayClick,
  renderDropdown,
  show,
  preventHide: preventHideProp,
  onHide,
  onShow,
  onClick,
  disabled
}) => {
  const [preventHide, setPreventHide] = useState(preventHideProp)

  // handle setting preventHide only if next state doesn't match current
  const handlePreventHide = useCallback(
    _preventHide => {
      if (preventHide !== _preventHide) setPreventHide(_preventHide)
    },
    [preventHide]
  )

  // update state to match preventHide prop
  useEffect(() => {
    if (preventHideProp !== preventHide) setPreventHide(preventHideProp)
  }, [preventHideProp])

  return (
    <DropDownTriggerContext.Provider
      value={{
        preventHide,
        setPreventHide: handlePreventHide
      }}
    >
      <OverlayTrigger
        style={style}
        enterTransition={enterTransition}
        leaveTransition={leaveTransition}
        className={classnames("Dropdown__Trigger", className)}
        trigger={trigger}
        placement={placement}
        alignment={alignment}
        preventHide={preventHide}
        onHide={onHide}
        onShow={onShow}
        onClick={onClick}
        overlay={renderDropdown}
        show={show}
        closeOnTriggerClick={closeOnTriggerClick}
        closeOnOverlayClick={closeOnOverlayClick}
        disabled={disabled}
      >
        {children}
      </OverlayTrigger>
    </DropDownTriggerContext.Provider>
  )
}

DropdownTrigger.displayName = "DropdownTrigger"

DropdownTrigger.propTypes = {
  renderDropdown: PropTypes.func.isRequired
}

DropdownTrigger.defaultProps = {
  trigger: "click",
  placement: "bottom",
  alignment: "right"
}

export default DropdownTrigger
