import Button from "@pathwright/ui/src/components/button/Button"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import HtmlText from "@pathwright/ui/src/components/text/HtmlText"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"

const SupportSchool = props => {
  const {
    school,
    support_email,
    support_phone,
    support_faqs,
    support_link,
    support_policy,
    userIsSchoolAdmin,
    has_faqs,
    t
  } = props

  const tFAQs = t("support_school.faqs")

  return (
    <div>
      <header>
        {school.image && <img src={school.image} />}
        <Text.H1>
          {t("support_school.title", { school_name: school.name })}
        </Text.H1>
      </header>

      <View className="Support__content" borderTop paddingTop paddingBottom>
        <ul>
          {has_faqs && (
            <li>
              <span>
                <Pathicon icon="check-circle" />
                <span>
                  {t("support_school.read_faqs")}&nbsp;
                  <span style={{ display: "inline-block" }}>
                    {support_link ? (
                      <Button
                        brand
                        styleType="text"
                        target="_blank"
                        href={support_link}
                      >
                        {tFAQs}
                      </Button>
                    ) : (
                      <Button
                        brand
                        styleType="text"
                        target="_blank"
                        href={support_faqs}
                      >
                        {tFAQs}
                      </Button>
                    )}
                  </span>
                </span>
              </span>
            </li>
          )}
          {// dumb
          Boolean(
            has_faqs && (support_email || window.Intercom || support_phone)
          ) && <hr />}
          <li>
            {Boolean(
              has_faqs && (support_email || window.Intercom || support_phone)
            ) && (
              <span>
                <Pathicon icon="check-circle" />
                <span>{t("support_school.ask_us")}</span>
                <br />
              </span>
            )}
            {support_policy && (
              <HtmlText className="Support__policy" html={support_policy} />
            )}
            <span className="Support__contact-option">
              {!!window.Intercom && userIsSchoolAdmin && (
                <Tooltip title={t("support_school.send_message_title")}>
                  <span style={{ display: "inline-block" }}>
                    <Button brand styleType="text" href="javascript:void(0)">
                      {t("support_school.send_message")}
                    </Button>
                  </span>
                </Tooltip>
              )}
              {!!window.Intercom && !userIsSchoolAdmin && (
                <span style={{ display: "inline-block" }}>
                  <Button
                    brand
                    styleType="text"
                    onClick={() => window.Intercom("show")}
                    href="javascript:void(0)"
                  >
                    {t("support_school.send_message")}
                  </Button>
                </span>
              )}
              {support_email && !window.Intercom && (
                <span style={{ display: "inline-block" }}>
                  <Button
                    brand
                    styleType="text"
                    href={`mailto:${support_email}`}
                  >
                    {t("support_school.send_message")}
                  </Button>
                </span>
              )}
              {(support_email || !!window.Intercom) && support_phone && (
                <span> {t("support_school.or")} </span>
              )}
              {support_phone && (
                <span>
                  {t("support_school.call_us")}{" "}
                  <span style={{ display: "inline-block" }}>
                    <Button
                      brand
                      styleType="text"
                      href={`tel:${support_phone}`}
                    >
                      {support_phone}
                    </Button>
                  </span>
                </span>
              )}
            </span>
          </li>
        </ul>
      </View>
    </div>
  )
}

export default withTranslate(SupportSchool)
