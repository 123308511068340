import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { CustomPicker } from "react-color"
import { Alpha, Hue, Saturation } from "react-color/lib/components/common"
import styled from "styled-components"
import { cleanHex, objectifyRgba, parseRgba } from "../../utils/colors"
import ColorInput from "./ColorInput"

const StyledContainer = styled.div`
  overflow: hidden;
  min-width: 250px;
  max-width: 100vw;
  padding: 10px;
`

const StyledSwatch = styled.div`
  min-width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;

  ${p =>
    p.selected &&
    `
    &:before {
      content: "";
      background-color: transparent;
      border-radius: inherit;
      border: 2px solid white;
      margin: 2px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `}
`

const StyledSwatchList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;

  li:not(:first-child) {
    margin-left: 5px;
  }

  li {
    display: flex;
    flex-grow: 1;
  }
`

const StyledSquareSwatchList = styled(StyledSwatchList)`
  li {
    max-width: 24px;
    height: 24px;
  }
`

const StyledCircleSwatchList = styled(StyledSwatchList)`
  ${StyledSwatch} {
    border-radius: 100%;
    aspect-ratio: 1/1;
  }
`

const StyledInputRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-context: space-between;
  ${p =>
    p.show
      ? ``
      : `
    height: 0;
    overflow: hidden;
  `}

  > * {
    padding: 0;

    &:first-child {
      width: ${p => (p.enableHex ? "85px" : "65px")};
    }

    &:not(:first-child) {
      padding-left: 5px;
      width: 65px;
    }
  }
`

const PickerContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`

const GridPickerContainer = styled(PickerContainer)`
  height: 150px;
`

const ScrubPickerContainer = styled(PickerContainer)`
  height: 12px;
`

const Pointer = styled.div`
  width: 16px;
  height: 16px;
  transform: translate(-8px, -2px);
  border-radius: 8px;
`

// Important flag necessary, seemingly to override react-color styles?
const OutlinePointer = styled(Pointer)`
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid white !important;
`

// Important flag necessary, seemingly to override react-color styles?
const CirclePointer = styled(Pointer)`
  background-color: white !important;
  border: 1px solid #ccc !important;
`

const Swatch = ({ rgb, swatch, onChange }) => {
  const sourceSwatch = useMemo(() => objectifyRgba(swatch), [swatch])
  const parsedSwatch = useMemo(() => parseRgba(swatch), [swatch])
  const parsedRgb = useMemo(() => parseRgba(rgb), [rgb])

  return (
    <StyledSwatch
      style={{ backgroundColor: swatch }}
      selected={parsedRgb === parsedSwatch}
      onClick={() => onChange({ ...sourceSwatch, source: "rgba" })}
    />
  )
}

const ColorPicker = props => {
  const {
    className,
    swatches,
    enableSaturation,
    enableHue,
    enableAlpha,
    enableHex,
    enableRgb,
    enableRgba,
    circleSwatches,
    // Rest is from CustomPicker.
    hex,
    rgb,
    onChange,
    showInputs
  } = props

  const { onChangeComplete, ...passProps } = props

  const handleInputChange = data => {
    const key = Object.keys(data)[0]
    const value = data[key]

    // NOTE: no need to validate value as ColorInput
    // only calls onChange when value is valid.
    switch (key) {
      case "hex":
        return onChange({
          [key]: value,
          source: "hex"
        })
      case "r":
      case "g":
      case "b":
        return onChange({
          ...rgb,
          [key]: value,
          source: "rgb"
        })
      case "a":
        return onChange({
          ...rgb,
          [key]: value / 100,
          source: "rgba"
        })
    }
  }

  const SwatchListComponent = circleSwatches
    ? StyledCircleSwatchList
    : StyledSquareSwatchList

  return (
    <StyledContainer className={classnames("ColorPicker", className)}>
      {!!enableSaturation && (
        <GridPickerContainer>
          <Saturation
            {...passProps}
            onChange={onChange}
            pointer={OutlinePointer}
          />
        </GridPickerContainer>
      )}
      {!!enableHue && (
        <ScrubPickerContainer>
          <Hue {...passProps} onChange={onChange} pointer={CirclePointer} />
        </ScrubPickerContainer>
      )}
      {!!enableAlpha && (
        <ScrubPickerContainer>
          <Alpha {...passProps} onChange={onChange} pointer={CirclePointer} />
        </ScrubPickerContainer>
      )}
      <SwatchListComponent>
        {swatches.map(swatch => (
          <li key={swatch}>
            <Swatch rgb={rgb} swatch={swatch} onChange={onChange} />
          </li>
        ))}
      </SwatchListComponent>
      <StyledInputRow enableHex={enableHex} show={showInputs}>
        {!!enableHex && (
          <ColorInput
            type="#"
            value={cleanHex(hex)}
            onChange={hex => handleInputChange({ hex })}
            placeholder="FF0000"
            labelWidth={16}
            hideStatus
          />
        )}
        {!!enableRgb && (
          <React.Fragment>
            <ColorInput
              type="r"
              value={rgb.r}
              onChange={r => handleInputChange({ r })}
              placeholder="255"
              labelWidth={16}
              hideStatus
            />
            <ColorInput
              type="g"
              value={rgb.g}
              onChange={g => handleInputChange({ g })}
              placeholder="255"
              labelWidth={16}
              hideStatus
            />
            <ColorInput
              type="b"
              value={rgb.b}
              onChange={b => handleInputChange({ b })}
              placeholder="255"
              labelWidth={16}
              hideStatus
            />
            {!!enableRgba && !!enableAlpha && (
              <ColorInput
                type="a"
                value={Math.round(rgb.a * 100)}
                onChange={a => handleInputChange({ a })}
                placeholder="100"
                labelWidth={16}
                hideStatus
              />
            )}
          </React.Fragment>
        )}
      </StyledInputRow>
    </StyledContainer>
  )
}

ColorPicker.displayName = "ColorPicker"

ColorPicker.propTypes = {
  swatches: PropTypes.arrayOf(PropTypes.string).isRequired,
  enableSaturation: PropTypes.bool,
  enableHue: PropTypes.bool,
  enableAlpha: PropTypes.bool,
  enableHex: PropTypes.bool,
  enableRgb: PropTypes.bool,
  enableRgba: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showInputs: PropTypes.bool
}

ColorPicker.defaultProps = {
  swatches: [
    "rgba(255, 59, 48, 1)",
    "rgba(255, 149, 0, 1)",
    "rgba(255, 204, 0, 1)",
    "rgba(52, 199, 89, 1)",
    "rgba(0, 199, 190, 1)",
    "rgba(48, 176, 199, 1)",
    "rgba(50, 173, 230, 1)",
    "rgba(0, 122, 255, 1)",
    "rgba(88, 86, 214, 1)",
    "rgba(175, 82, 222, 1)",
    "rgba(255, 45, 85, 1)",
    "rgba(162, 132, 94, 1)"
  ],
  enableSaturation: true,
  enableHue: true,
  enableAlpha: true,
  enableHex: true,
  enableRgb: true,
  enableRgba: true,
  showInputs: true
}

export default CustomPicker(ColorPicker)
