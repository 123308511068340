import React from "react"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import OptionsButton from "@pathwright/ui/src/components/button/OptionsButton"

const NotificationItemControls = ({ item, onPin, onToggleRead, onDelete }) => {
  const { t } = useTranslate()

  const handlePin = () => {
    onPin(item)
  }

  const handleToggleRead = () => {
    onToggleRead(item)
  }

  const handleDelete = () => {
    onDelete(item)
  }

  const isPinned = item.data.pinned === true

  return (
    <DropdownTrigger
      alignment="right"
      className="Notification__trigger"
      renderDropdown={() => (
        <DropdownMenu closeOnClick>
          <DropdownItem
            icon={item.data.read ? "eye-off" : "eye"}
            label={item.data.read ? t("Mark as unread") : t("Mark as read")}
            onClick={handleToggleRead}
          />
          <DropdownItem
            icon={isPinned ? "pin" : "pin-2"}
            label={isPinned ? t("Unpin") : t("Pin")}
            onClick={handlePin}
          />
          <DropdownItem icon="x" label={t("Remove")} onClick={handleDelete} />
        </DropdownMenu>
      )}
    >
      <OptionsButton />
    </DropdownTrigger>
  )
}

export default NotificationItemControls
