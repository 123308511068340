import { ChakraProps, Input, InputProps } from "@chakra-ui/react"
import { FieldConfig } from "formik"
import Field from "../Field"
import useFormikDiscardFormState from "./useFormikDiscardFormState"
import { FormStateKey } from "./useFormStateStorage"
import { LocalStorageOptions } from "./useLocalStorage"

function FormikFormStateField<T extends ChakraProps>(
  props: FieldConfig &
    T & {
      formStateKey?: FormStateKey
      localStorageOptions?: LocalStorageOptions
    }
): JSX.Element {
  const { formStateKey, localStorageOptions, ...passProps } = props

  useFormikDiscardFormState({
    keys: [props.name],
    formStateKey,
    localStorageOptions
  })

  return <Field {...passProps} />
}

export const FormikFormStateInputField: typeof FormikFormStateField<
  InputProps
> = (props) => <FormikFormStateField type="text" {...props} as={Input} />

export default FormikFormStateField
