import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import AssignCard from "@pathwright/web/src/modules/assign/AssignCard"
import Dashboard from "@pathwright/web/src/modules/dashboard/Dashboard"
import UserReportCard from "@pathwright/web/src/modules/path/report/UserReportCard"
import ProfileCard from "@pathwright/web/src/modules/profile/ProfileCard"
import { createCardRoutes } from "lib/core/routing/card-routing"
import { ConnectStores } from "lib/core/store"

const UserReportCardWrapped = props =>
  props.pathId && props.userId ? (
    <UserReportCard {...props} pathId={props.pathId} userId={props.userId} />
  ) : (
    <Card {...props}>
      <LoadingCircle />
    </Card>
  )

UserReportCardWrapped.displayName = "UserReportCardWrapped"

const withCurrentUserReportCard = UserReportCardContainer =>
  ConnectStores(
    UserReportCardContainer,
    [
      { store: "path", watch: ["path"] },
      { store: "auth", watch: ["user"] }
    ],
    {},
    (state, ownProps) => ({
      pathId: state.pathStore.state.path && state.pathStore.state.path.id,
      userId: state.authStore.state.user && state.authStore.state.user.id,
      ...ownProps
    }),
    false /* cacheComponent */
  )

export const CurrentUserReportCardContainer = withCurrentUserReportCard(
  UserReportCardWrapped
)
export const CurrentUserResourceReportCardContainer = withCurrentUserReportCard(
  UserReportCardWrapped
)

export const reportCardRoutes = {
  report: {
    path: ":pathId(\\d+)/",
    component: props => (
      <UserReportCard
        {...props}
        pathId={parseInt(props.match.params.pathId)}
        userId={parseInt(props.match.params.userId)}
      />
    ),
    childCards: {
      resourceReport: {
        path: ":collectionResourcePathId(\\d+)/",
        component: props => (
          <UserReportCard
            {...props}
            pathId={parseInt(props.match.params.collectionResourcePathId)}
            userId={parseInt(props.match.params.userId)}
          />
        )
      }
    }
  }
}

export const assignCardRoutes = {
  ["add-to-path"]: {
    getDefaultLaunchedFrom: () => "/dashboard/",
    component: props => (
      <AssignCard
        card={props.card}
        userId={parseInt(props.location.query.user)}
        communityGroupId={parseInt(props.location.query.community_group)}
      />
    )
  }
}

export const profileCardRoute = {
  profile: {
    path: ":userId(\\d+)/",
    getDefaultLaunchedFrom: () => "/dashboard/",
    component: props => (
      <ProfileCard {...props} userId={parseInt(props.match.params.userId)} />
    ),
    childCards: {
      ...assignCardRoutes,
      ...reportCardRoutes
    }
  }
}

export default createCardRoutes("/user/", profileCardRoute, Dashboard)
