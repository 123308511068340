import { useFirebaseValue } from "../firebase/utils"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const useClipboard = () => {
  const { me } = usePathwrightContext()
  if (!me)
    console.error("Attempting to use the clipboard without being logged in")

  const firebasePath = me ? `/user/${me.id}/clipboard/` : null
  const [_clips, clipboardRef] = useFirebaseValue(firebasePath)
  // Default clips to an empty array.
  const clips = _clips || []

  // Util for filtering existing clips by clip key/value pairs
  const _filterClips = ({ object_type, object_id, object_label }) => {
    const filteredClips = clips.filter(
      clip =>
        (!object_type || clip.object_type === object_type) &&
        (!object_id || clip.object_id === object_id) &&
        (!object_label || clip.object_label === object_label)
    )
    return filteredClips
  }

  const _removeClipsOfType = object_type =>
    clips.filter(clip => clip.object_type !== object_type)

  const _save = async (nextClips = null) => {
    if (!me) return
    clipboardRef.set(nextClips || clips)
  }

  // Remove clips of this type (w/ legacy support for multiple clips of one type)
  const clear = ({ object_type }) => {
    const remainingClips = _removeClipsOfType(object_type)
    return _save(remainingClips)
  }

  const getClip = ({ object_type, object_id }) => {
    const filteredClips = _filterClips({ object_type, object_id })
    return filteredClips[filteredClips.length - 1]
  }

  const copy = ({ object_type, object_id, object_label }, data = {}) => {
    const clip = { object_type, object_id, object_label, data }
    const nextClips = _removeClipsOfType(object_type).concat([clip])
    _save(nextClips)
  }

  return {
    clear,
    copy,
    getClip
  }
}

export default useClipboard
