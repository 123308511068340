export const snapToList = function(snap) {
  const list = []
  const object = snap.val()
  for (let id in object) {
    const data = object[id]
    list.push(_.assign({ id }, data))
  }
  return list
}

let authedRefsPromises = {}
export const getAuthedRef = firebase_config => {
  const token = firebase_config.auth_token

  // return the cached version
  if (!authedRefsPromises[token]) {
    // initializeFirebase()

    authedRefsPromises[token] = new Promise((resolve, reject) => {
      window.firebase
        .auth()
        .signInWithCustomToken(token)
        .then(resolve)
        .catch(err => console.log("got error: ", err))
    })
  }
  return authedRefsPromises[token]
}

export const getUserRef = path => {
  const firebaseConfig = window.App.getStore("school").request.integration(
    "firebase"
  )
  return getAuthedRef(firebaseConfig).then(() =>
    window.firebase.database().ref(path)
  )
}
