import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import PlayButton from "./PlayButton"

const PlaylistUI = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
`

const PlaylistItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e5e5e5;
  color: rgb(102, 102, 102);
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  .Button {
    margin: 0px !important;
    padding-bottom: 4px !important;
  }
  i.Pathicon {
    color: rgba(116, 116, 116, 0.5);
  }
`

export const Playlist = ({ onSelectSource, current, sources }) => {
  const handleSelectSource = index => () => {
    onSelectSource(index)
  }

  const currentIndex = sources.indexOf(current)

  const mappedItems = sources.map((source, i) => (
    <PlaylistItem
      key={i}
      onClick={handleSelectSource(i)}
      selected={i === currentIndex}
    >
      {source.name || "Current Track"}
      <PlayButton onTogglePlaying={handleSelectSource(i)} />
    </PlaylistItem>
  ))

  return <PlaylistUI>{mappedItems}</PlaylistUI>
}

Playlist.propTypes = {
  onSelectSource: PropTypes.func,
  current: PropTypes.object,
  sources: PropTypes.arrayOf(PropTypes.object)
}

export default Playlist
