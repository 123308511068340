export default {
  type: "Image",
  icon: "image",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/image-blocks-new",
  menu: {
    category: "image",
    order: 1
  },
  layouts: {
    text: {
      key: "text",
      label: "Text and image",
      data: {
        src: "https://cdn.filestackcontent.com/RhFIhnADTf6On4PQAvGo",
        text:
          "<h1>A heading</h1><p>Roman concrete is significantly more resistant to erosion by seawater than modern concrete.</p>",
        altText: "Many staircases in a pattern"
      }
    },
    fullwidth: {
      key: "fullwidth",
      label: "Image",
      data: {
        src: "https://cdn.filestackcontent.com/Rb6L3Yp5Rfa1O0RHXPN2",
        altText: "A stone amphitheater"
      }
    },
    center: {
      key: "center",
      label: "Image centered",
      data: {
        src: "https://cdn.filestackcontent.com/HEHMFQDLR02TUTPjM67h",
        altText: "A peripheral walkway around a building"
      }
    },
    text_background: {
      key: "text_background",
      label: "Text on image",
      data: {
        src: "https://cdn.filestackcontent.com/Tq1s3TXMSMOqqHou7Uww",
        text: `<p><strong>Concrete helps urban planners turn function into artwork.</strong></p>`,
        altText: "Steps at an amphitheater"
      }
    },
    annotated: {
      menuCategory: "interactive",
      helpLink:
        "https://help.pathwright.com/en/articles/6197688-annotated-image-block",
      menuOrder: 3,
      key: "annotated",
      label: "Annotated image",
      data: {
        src: "https://cdn.filestackcontent.com/91yL6HEKS9SNOltaqeir",
        annotations: [{ html: "", xPos: 50, yPos: 50 }],
        altText: "Many railings in a pattern"
      }
    }
  },
  schema: `
    type Annotation {
      html: String!
      xPos: Float!
      yPos: Float!
    }

    type ImageCaption {
      html: String!
    }

    type Image implements Block {
      id: ID!
      src: String! @media
      altText: String
      caption: ImageCaption
      text: String
      annotations: [Annotation]
    }
  `
}
