import PropTypes from "prop-types"
import { useCallback, useEffect, useRef, useState } from "react"
import usePreviousEffect from "../hooks/usePreviousEffect"
import { getSumbitPromise } from "./utils"

/**
 * Handles submision state
 */

// State types:
export const SUBMIT_STATUS = {
  DEFAULT: "default",
  LOADING: "loading",
  SUCCESS: "success",
  FAILURE: "failure"
}

export const useSubmitHandler = (onSubmit, options = {}) => {
  const {
    submitting,
    submitFailure,
    submitSuccess,
    hideSuccess,
    onClick, // used interchangeably with onSubmit – TODO: drop this.
    duration = 1000, // how long should the success/failure state display
    onSubmitProcessing,
    resetStatus = true, // allow the status to be reset to the default status after timeout has ellapsed
    onSubmitSuccess,
    onSubmitFailure
  } = options

  const timeoutRef = useRef(null)
  const unmountedRef = useRef(false)
  const handlingSubmitdRef = useRef(false)

  const [status, _setStatus] = useState(
    submitting ? SUBMIT_STATUS.LOADING : SUBMIT_STATUS.DEFAULT
  )

  const statusRef = useRef(status)
  const setStatus = useCallback(status => {
    statusRef.current = status
    _setStatus(status)
  }, [])

  const [error, setError] = useState(null)
  const [result, setResult] = useState(null)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
      unmountedRef.current = true
    }
  }, [])

  usePreviousEffect(
    ([prevSubmitting, prevReset]) => {
      if (submitting !== prevSubmitting) {
        if (submitting) {
          return handleLoading()
        } else if (submitFailure) {
          return handleError()
        } else if (submitSuccess) {
          if (!hideSuccess) {
            return handleSuccess()
          }
        } else if (resetStatus) {
          return handleNothing()
        }
      }

      if (resetStatus && !prevReset) {
        return handleNothing()
      }
    },
    [submitting, resetStatus]
  )

  const handleDelayedStatusChange = useCallback(status => {
    if (!unmountedRef.current) setStatus(status)
    timeoutRef.current = setTimeout(() => {
      if (resetStatus) handleNothing()
    }, duration)
  }, [])

  const handleLoading = useCallback(() => {
    if (statusRef.current !== SUBMIT_STATUS.LOADING) {
      if (!unmountedRef.current) setStatus(SUBMIT_STATUS.LOADING)
      if (!unmountedRef.current) setError(null)
      onSubmitProcessing && onSubmitProcessing()
    }
  }, [onSubmitProcessing])

  const handleNothing = useCallback(() => {
    if (statusRef.current !== SUBMIT_STATUS.DEFAULT) {
      if (!unmountedRef.current) setStatus(SUBMIT_STATUS.DEFAULT)
    }
  }, [])

  const handleSuccess = useCallback(
    silent => {
      if (statusRef.current !== SUBMIT_STATUS.SUCCESS) {
        if (!hideSuccess && !silent) {
          handleDelayedStatusChange(SUBMIT_STATUS.SUCCESS)
        } else if (resetStatus) {
          handleNothing()
        }
        onSubmitSuccess && onSubmitSuccess()
      }
    },
    [
      hideSuccess,
      resetStatus,
      handleDelayedStatusChange,
      handleNothing,
      onSubmitSuccess
    ]
  )

  const handleError = useCallback(
    error => {
      if (!unmountedRef.current) setError(error)
      if (statusRef.current !== SUBMIT_STATUS.FAILURE) {
        handleDelayedStatusChange(SUBMIT_STATUS.FAILURE)
        onSubmitFailure && onSubmitFailure(error)
      }
    },
    [handleDelayedStatusChange, onSubmitFailure]
  )

  const processSubmit = useCallback(
    (...args) => {
      let result = null
      if (!handlingSubmitdRef.current) {
        handlingSubmitdRef.current = true
        if (typeof onClick === "function") {
          const e = args[0] // potential Event args
          result = onClick(e)
        }
        if (typeof onSubmit === "function") {
          result = onSubmit(...args)
        }

        const promise = getSumbitPromise(result)

        if (promise && promise.then) {
          handleLoading()

          const catchMethod = promise.catch
            ? "catch"
            : promise.fail
            ? "fail"
            : "?"

          const finallyMethod = catchMethod === "catch" ? "finally" : "always"

          return promise[catchMethod](error => {
            handleError(error)
            // bubble up
            throw error
          })
            .then(result => {
              if (!unmountedRef.current) setResult(result)
              handleSuccess()
            })
            [finallyMethod](() => {
              handlingSubmitdRef.current = false
            })
        } else {
          handlingSubmitdRef.current = false
          if (submitting === undefined) {
            handleSuccess(true)
          }
        }
      }
    },
    [submitting, onClick, onSubmit, handleLoading, handleSuccess, handleError]
  )

  const handleSubmit = useCallback(
    (...args) => {
      // potential Event args
      const e = args[0]

      // only stop propagation and prevent default if button acting as submit button
      if (
        e &&
        e.currentTarget &&
        e.currentTarget.type === "submit" &&
        typeof onSubmit === "function"
      ) {
        e && e.stopPropagation && e.stopPropagation()
        e && e.preventDefault && e.preventDefault()
      }

      if (!submitting && statusRef.current === SUBMIT_STATUS.DEFAULT)
        return processSubmit(...args)
    },
    [submitting, processSubmit]
  )

  const clearError = () => setError(null)

  return {
    status,
    error,
    result,
    handleSubmit,
    clearError
  }
}

const SubmitHandler = ({ children, onSubmit, ...options }) => {
  const passProps = useSubmitHandler(onSubmit, options)
  return children(passProps)
}

SubmitHandler.displayName = "SubmitHandler"

SubmitHandler.propTypes = {
  duration: PropTypes.number, // how long should the success/failure state display
  hideSuccess: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSuccess: PropTypes.bool,
  submitFailure: PropTypes.any,
  onClick: PropTypes.func, // used interchangeably with onSubmit
  onSubmit: PropTypes.func,
  onSubmitFailure: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitProcessing: PropTypes.func,
  children: PropTypes.func,
  resetStatus: PropTypes.bool // allow the status to be reset to the default status after timeout has ellapsed
}

SubmitHandler.defaultProps = {
  duration: 1000,
  resetStatus: true
}

export default SubmitHandler
