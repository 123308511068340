// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let School
export default School = class School extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    return this.urlRoot(`/school/api/v1/school/`)
  }
}
