import { useEffect } from "react"
import { useUserStatusWithAuthRequest } from "./api/action"
import SignInView, { SignInViewProps } from "./SignInView"

export type SignInUserEmailStatusSuccessHandler = (
  value: ReturnType<typeof useUserStatusWithAuthRequest>[0]["value"]
) => void

interface SignInViewContainerProps {
  onSuccess?: SignInUserEmailStatusSuccessHandler
}

const SignInViewContainer = ({ onSuccess }: SignInViewContainerProps) => {
  const [state, action] = useUserStatusWithAuthRequest()

  const handleSubmit: SignInViewProps["onSubmit"] = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await action(formData)
  }

  useEffect(() => {
    if (!!state.value) onSuccess?.(state.value)
  }, [state.value])

  return (
    <SignInView
      loading={state.loading}
      success={!!state.value}
      error={state.error?.message}
      onSubmit={handleSubmit}
    />
  )
}

export default SignInViewContainer
