// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let SchoolSubscriptionPause
export default SchoolSubscriptionPause = class SchoolSubscriptionPause extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    return this.urlRoot(
      `api/private/subscription/${this.get("subscription_id")}/pause/`
    )
  }

  toJSON() {
    const json = super.toJSON()
    json.paused_until_dtime = moment(json.paused_until_dtime).toISOString()
    return json
  }

  defaults() {
    return {
      subscription_id: null,
      paused_until_dtime: null
    }
  }
}
