import { sizePropType } from "@pathwright/ui/src/components/utils/props"
import PropTypes from "prop-types"
import React from "react"
import usePathAnalytics from "../../usePathAnalytics"
import PathProgress from "./PathProgress"

const PathProgressContainer = ({ cohort }) => {
  const analytics = usePathAnalytics(cohort)
  return <PathProgress analytics={analytics} avatarSize="8px" />
}

PathProgressContainer.displayName = "PathProgressContainer"

PathProgressContainer.propTypes = {
  id: PropTypes.number,
  avatarSize: sizePropType,
  firebase: PropTypes.shape({
    url: PropTypes.string,
    auth_token: PropTypes.string
  })
}

export default PathProgressContainer
