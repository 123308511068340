import useControlledState from "./useControlledState"
import useEventListener from "./useEventListener"

// from: https://usehooks.com/useOnClickOutside/
function useOnClickOutside(handler, nodeProp) {
  // nodeProp is optional, so node state can be controlled by prop
  // or returned setNode method.
  const [node, setNode] = useControlledState(nodeProp)

  useEventListener(
    ["mousedown", "touchstart"],
    event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!node || node.contains(event.target)) {
        return
      }
      handler(event)
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [node, handler]
  )

  return setNode
}

export default useOnClickOutside
