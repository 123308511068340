// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let SchoolSubscriptionCollection
export default SchoolSubscriptionCollection = (function() {
  SchoolSubscriptionCollection = class SchoolSubscriptionCollection extends require("lib/static-shim").default(
    require("lib/utils/collection").default
  ) {
    static initClass() {
      this.prototype.model = require("./school-subscription").default
    }

    url() {
      return this.urlRoot(`/api/private/subscription/`)
    }
  }
  SchoolSubscriptionCollection.initClass()
  return SchoolSubscriptionCollection
})()
