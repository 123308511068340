import React from "react"
import PropTypes from "prop-types"
import { SkipButtonUI } from "./ui"

export const SkipButton = ({ onSkip, type }) => {
  return (
    <SkipButtonUI
      icon={type === "next" ? "skip-forward-2" : "skip-back-2"}
      onClick={onSkip(type)}
    />
  )
}

SkipButton.propTypes = {
  type: PropTypes.string.isRequired, // prev or next
  onSkip: PropTypes.func.isRequired //
}

export default SkipButton
