import { graphql } from "@apollo/client/react/hoc"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import get from "lodash/get"
import { Component } from "react"
import styled from "styled-components"
import { ROLE_LABELS, ROLE_TYPE_OFFERING } from "../../invitation/constants"
import DELETE_REGISTRATION_MUTATION from "../../registration/graphql/delete-registration-mutation"
import UserListItem from "../../user/UserListItem"

const StyledContainer = styled.div`
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  display: flex !important;
  flex-direction: column;
  height: 100%;
`

const StyledScrollView = styled(ScrollView)`
  flex-grow: 0;
  flex-shrink: 1;
  padding: 10px 0;
  height: 400px;
  min-height: auto;
`

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const StyledFooter = styled.footer`
  position: relative;
  padding: 10px;
`

class ResourceEditorsList extends Component {
  state = {
    loadingMore: false
  }

  handleScrollBottom = () => {
    const hasMore = get(this.props, "registrations.pageInfo.hasNextPage")
    if (hasMore && !this.state.loadingMore) {
      this.setState({ loadingMore: true })
      this.props.loadMore().then(() => {
        this.setState({ loadingMore: false })
      })
    }
  }

  render() {
    const {
      loading,
      refetch,
      resource,
      group,
      registrations,
      deleteRegistration
    } = this.props

    if (loading) {
      return <LoadingCircle />
    }
    if (registrations && registrations.length > 0) {
      return (
        <StyledContainer>
          <StyledScrollView onScrollBottom={this.handleScrollBottom}>
            <StyledList>
              {registrations.map(registration => (
                <li key={registration.id}>
                  <UserListItem
                    meta={[ROLE_LABELS[ROLE_TYPE_OFFERING][registration.role]]}
                    user={registration.user}
                    controls={[
                      {
                        icon: "envelope",
                        label: registration.user.email,
                        href: `mailto:${registration.user.email}`
                      },
                      {
                        icon: "minus-circle",
                        label: "Remove editor",
                        confirmMessage: "This will be permanent",
                        action: () =>
                          deleteRegistration(group.id, registration.id).then(
                            refetch
                          )
                      }
                    ]}
                  />
                </li>
              ))}
            </StyledList>
            <StyledFooter>
              {this.state.loadingMore && <LoadingCircle />}
            </StyledFooter>
          </StyledScrollView>
        </StyledContainer>
      )
    } else {
      return (
        <BlankSlate
          icon="group-plus"
          heading="There are currently no editors"
          body={`This is where you can manage the course editors for ${resource.name}`}
          primaryAction={{
            children: "Invite an editor",
            to: `${window.location.pathname}invite/`
          }}
        />
      )
    }
  }
}

ResourceEditorsList.displayName = "ResourceEditorsList"

export default graphql(DELETE_REGISTRATION_MUTATION, {
  props: ({ mutate, ownProps }) => ({
    deleteRegistration: (group_id, registration_id) =>
      mutate({
        variables: { group_id, registration_id },
        refetchQueries: ["ResourceEditorQuery"] // need to decouple this mutation from the ResourceEditorQuery
      })
  })
})(ResourceEditorsList)
