import PropTypes from "prop-types"
import { createContext, useContext, useEffect, useState, useMemo } from "react"
import { useBlocksConfig } from "../config/BlocksConfigProvider"
import { BlockCompletionType } from "../types"

export type BlockCompletionState = {
  inputState?: BlockInputState
  isCompleted?: boolean
  score?: number | null
  blurred?: boolean
  blockRegistered?: boolean
  useAutocomplete?: boolean
  preventChanges?: Boolean
  showSubmit?: boolean
  isSubmitting?: boolean
  isSubmittedOptimistic?: boolean
  getBlurProps: () => Record<string, any>
  setUseAutocomplete: (useAutocomplete: boolean) => void
  setBlockFunctions: (blockFunctions: Partial<BlockFunctions>) => void
  onSubmit: () => void
  onUnsubmit: () => void
}

type BlockInputState = "none" | "invalid" | "valid"

type BlockFunctions = {
  validateInput: (data?: Record<string, any>) => BlockInputState
  getBlockIsCompleted: (data?: Record<string, any>) => boolean
  scoreData: (data?: Record<string, any>) => number | null
}

type BlockCompletionProviderProps = {
  children: JSX.Element
  completion?: BlockCompletionType
  data?: Record<string, any>
}

export const BLOCK_INPUT_STATES: {
  [key: string]: BlockInputState
} = {
  NONE: "none",
  INVALID: "invalid",
  VALID: "valid"
}

export const BLOCK_FEEDBACK_SETTINGS = {
  NONE: "none",
  PROGRESSIVE: "progressive",
  ON_COMPLETE: "on complete step",
  ALWAYS: "always"
}

const defaultCompletionState: BlockCompletionState = {
  showSubmit: false,
  isSubmitting: false,
  isCompleted: false,
  score: null, // null means not yet completed or not gradable (ex: Survey)
  isSubmittedOptimistic: false,
  getBlurProps: () => ({}),
  setUseAutocomplete: () => {},
  setBlockFunctions: () => {},
  onSubmit: () => {},
  onUnsubmit: () => {}
}

const defaultBlockFunctions: BlockFunctions = {
  validateInput: (data?: Record<string, any>) => BLOCK_INPUT_STATES.NONE,
  getBlockIsCompleted: (data?: Record<string, any>) => false,
  scoreData: (data?: Record<string, any>) => null
}

const BlockCompletionContext = createContext(defaultCompletionState)

export const useBlockCompletion = (): BlockCompletionState =>
  useContext(BlockCompletionContext)

export const BlockCompletionProvider = ({
  children,
  completion = {},
  data
}: BlockCompletionProviderProps) => {
  const { needsForcedUnsubmit, disableInput } = useBlocksConfig()

  const [blockRegistered, setBlockRegistered] = useState(false)
  const [blurred, setBlurred] = useState(false)
  const [inputState, setInputState] = useState(BLOCK_INPUT_STATES.NONE)
  const [useAutocomplete, setUseAutocomplete] = useState(false)
  const [blockFunctions, setBlockFunctions] = useState(defaultBlockFunctions)
  const [completionState, setCompletionState] = useState(
    defaultCompletionState
  )

  const blockIsCompleted: boolean = useMemo(
    () => blockFunctions.getBlockIsCompleted(data),
    [data, blockFunctions.getBlockIsCompleted]
  )

  useEffect(() => {
    // Wait for block to be registered
    if (blockRegistered) {
      setInputState(getValidationState())
      handleForcedUnsubmit()
    }
  }, [blockRegistered, data])

  useEffect(() => {
    if (blurred) setInputState(getValidationState())
  }, [blurred])

  useEffect(() => {
    if (blockRegistered) handleInputStateChange()
  }, [inputState])

  // When block completion or submitting changes,
  useEffect(() => {
    if (completionState.isCompleted !== blockIsCompleted) {
      updateCompletionState({
        isCompleted: blockIsCompleted,
        // isSubmitting: false, // this OK?
        isSubmittedOptimistic: !completionState.isSubmitting
          ? blockIsCompleted
          : completionState.isSubmittedOptimistic
      })
    }
  }, [blockIsCompleted, completionState.isSubmitting])

  useEffect(() => {
    updateCompletionState({
      isSubmitting: false
    })
  }, [completion.progress])

  const handleForcedUnsubmit = (): void => {
    if (needsForcedUnsubmit && blockIsCompleted) handleUnsubmit()
  }

  const updateCompletionState = (stateUpdate = {}): void => {
    const nextState = { ...completionState, ...stateUpdate }
    setCompletionState(nextState)
  }

  const getValidationState = (): BlockInputState => {
    const blockValidation = blockFunctions.validateInput(data)
    if (blockValidation === BLOCK_INPUT_STATES.NONE && blurred) {
      return BLOCK_INPUT_STATES.INVALID
    }
    return blockValidation
  }

  const handleInputStateChange = (): void => {
    const hasValidInput = inputState === BLOCK_INPUT_STATES.VALID

    updateCompletionState({
      showSubmit: hasValidInput && !useAutocomplete,
      isCompleted: blockIsCompleted,
      score: blockFunctions.scoreData(data)
    })

    if (!useAutocomplete) return

    if (hasValidInput && !blockIsCompleted) handleSubmit()
    if (!hasValidInput && blockIsCompleted) handleUnsubmit()
  }

  const handleSubmission = (submitToggle: boolean): void => {
    const blockGrade: number | null = blockFunctions.scoreData(data)
    updateCompletionState({
      isSubmitting: true,
      isSubmittedOptimistic: submitToggle,
      score: blockGrade
    })
  }

  const handleSubmit = (): void => handleSubmission(true)

  const handleUnsubmit = (): void => handleSubmission(false)

  const shouldPreventChanges = (): boolean =>
    disableInput || (!useAutocomplete && blockIsCompleted)

  const handleSetBlockFunctions = (
    nextBlockFunctions: Partial<BlockFunctions>
  ) => {
    setBlockRegistered(true)
    setBlockFunctions({ ...blockFunctions, ...nextBlockFunctions })
  }

  const getBlurProps = (): {
    tabIndex: number
    onBlur: () => void
    onFocus: () => void
  } => {
    return {
      tabIndex: 0,
      onBlur: () => setBlurred(true),
      onFocus: () => setBlurred(false)
    }
  }

  const passValue: BlockCompletionState = {
    blockRegistered,
    inputState,
    useAutocomplete,
    blurred,
    preventChanges: shouldPreventChanges(),
    ...completionState,
    setUseAutocomplete,
    setBlockFunctions: handleSetBlockFunctions,
    getBlurProps,
    onSubmit: handleSubmit,
    onUnsubmit: handleUnsubmit
  }

  return (
    <BlockCompletionContext.Provider value={passValue}>
      {children}
    </BlockCompletionContext.Provider>
  )
}

BlockCompletionProvider.propTypes = {
  children: PropTypes.node,
  completion: PropTypes.object,
  data: PropTypes.object
}

export default BlockCompletionProvider
