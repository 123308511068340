import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import { withTranslate } from "@pathwright/ui/src/components/"
import { useDiscussionContext } from "./DiscussionProvider"

const Row = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  li {
    display: flex;
    align-items: center;
  }
`

const ControlState = styled(Text.Meta)`
  padding-left: 5px;
`

const StyledTooltip = styled(Tooltip)`
  text-align: center;
`

const buttonStyle = {
  styleType: "default",
  label: null
}

const DiscussionControls = ({ t, Trans }) => {
  const {
    post,
    subscribeToDiscussion,
    unsubscribeToDiscussion
  } = useDiscussionContext()

  if (!post) {
    return null
  }

  const subscribeProps = {
    icon: post.user_is_subscribed ? "chat-bubble-check" : "chat-bubble-plus",
    label: post.user_is_subscribed ? (
      // prettier-ignore
      // Trans formatting integral to translation json!
      <Trans parent="span" i18nKey="discussion.controls.subscribed_message">
        You'll be notified in-app and <br /> by email whenever someone responds.
      </Trans>
    ) : (
      // prettier-ignore
      // Trans formatting integral to translation json!
      <Trans parent="span" i18nKey="discussion.controls.unsub_message">
        Subscribe to be notified in-app and <br /> by email whenever someone
        responds.
      </Trans>
    ),
    onClick: () =>
      post.user_is_subscribed
        ? unsubscribeToDiscussion()
        : subscribeToDiscussion()
  }

  return (
    <Row>
      <li>
        <StyledTooltip title={subscribeProps.label}>
          <IconButton {...subscribeProps} {...buttonStyle} />
        </StyledTooltip>
        <ControlState>
          {post.user_is_subscribed
            ? t("discussion.controls.subscribed")
            : t("discussion.controls.notify_me")}
        </ControlState>
      </li>
    </Row>
  )
}

DiscussionControls.displayName = "DiscussionControls"

DiscussionControls.contextTypes = {
  discussionProvider: PropTypes.object.isRequired
}

export default withTranslate(DiscussionControls)
