// Determine the proper CDN to use based on the browser's hostname
const host = window.location.hostname
const matches = match => host.indexOf(match) > -1
const isStaging = matches("pathwrightstaging.com")
const isDev = matches("pathwrightdev.com")
const isLocal = matches("localhost")
const isProd = !isStaging && !isDev && !isLocal

let __webpack_public_path__
if (isLocal) {
  __webpack_public_path__ = "/"
} else if (isDev) {
  __webpack_public_path__ = "https://dev.pathwrightcdn.com/"
} else if (isStaging) {
  __webpack_public_path__ = "https://staging.pathwrightcdn.com/"
} else if (isProd) {
  __webpack_public_path__ = "https://prod.pathwrightcdn.com/"
}
