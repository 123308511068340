// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let SchoolSubscription
export default SchoolSubscription = class SchoolSubscription extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  constructor(...args) {
    super(...args)
    this.setStatusFields = this.setStatusFields.bind(this)
  }

  url() {
    return this.urlRoot(`/api/private/subscription/`)
  }

  defaults() {
    return {
      school_plan: null,
      payment_token: null,
      sid: null,
      group_course: null
    }
  }

  parse(response, options) {
    // Get returns a list, just assume the last one (latest)
    if (response.length) {
      return response[response.length - 1]
    }
    return super.parse(response, options)
  }

  initialize() {
    super.initialize()
    this.setStatusFields()
    return this.on("change:subscription", this.setStatusFields)
  }

  setStatusFields() {
    this.set("is_trial", this.isTrial())
    this.set("is_expired", this.isExpired())
    this.set("is_canceled", this.isCanceled())
    this.set("is_active", this.isActive())
    this.set("is_paused", this.isPaused())
    this.set("is_lifetime", this.isLifetime())
    this.set("is_monthly", this.isMonthly())
    this.set("is_yearly", this.isYearly())

    // status set in order of priority
    this.set("status", "active")
    if (this.get("is_trial")) {
      this.set("status", "trial")
    }
    if (this.get("is_paused")) {
      this.set("status", "paused")
    }
    if (this.get("is_expired")) {
      this.set("status", "expired")
    }
    if (this.get("is_canceled")) {
      this.set("status", "canceled")
    }

    if (this.get("plan")) {
      return (this.get("plan").term = this.getTerm())
    }
  }

  getTerm() {
    const interval = __guard__(this.get("plan"), x => x.interval)
    if (interval < 10) {
      return "monthly"
    } else if (interval === 10) {
      return "yearly"
    } else if (interval === 20) {
      return "lifetime"
    }
  }

  isActive() {
    if (this.get("subscription") == null) {
      return false
    }
    return (
      !this.isCanceled() &&
      !this.isExpired() &&
      !(this.isTrial() && !this.isTrialWithCard())
    )
  }

  isTrialWithCard() {
    const subscription = this.get("subscription")
    return subscription.trial_type === 10
  }

  isTrial() {
    const subscription = this.get("subscription")
    if (!subscription) {
      return false
    }
    if (subscription.trial_type !== null && subscription.trial_type > 0) {
      // compare based on UTC timezone
      const trialEnd = new Date(subscription.trial_end_dtime).toUTCString()
      const cycleEnd = new Date(subscription.cycle_end_dtime).toUTCString()
      // only consider a trial subscription if the trial end date and cycle end date are the same,
      // otherwise, if the subscription is still active, the subscription was activated
      const isTrialing = moment(trialEnd).isSame(cycleEnd)
      if (isTrialing) {
        return true
      }
    }
    return false
  }

  isCanceled() {
    if (this.get("subscription") == null) {
      return false
    }

    const {
      canceled_dtime,
      cycle_end_dtime,
      is_canceled_at_cycle_end
    } = this.get("subscription")

    const now = new Date()

    // consider subscription to not be canceled IF the subscription is set to cancel at cycle end and the cycle end dtime is in the future
    return (
      // canceled_dtime exists
      !!canceled_dtime &&
      // canceled_dtime is in the past
      moment(canceled_dtime).isBefore(now) &&
      // subscription is not set to cancel at end of cycle or cycle_end_dtime is in the past
      (!is_canceled_at_cycle_end || moment(cycle_end_dtime).isBefore(now))
    )
  }

  isLifetime() {
    return __guard__(this.get("plan"), x => x.interval) === 20
  }

  isYearly() {
    return __guard__(this.get("plan"), x => x.interval) === 10
  }

  isMonthly() {
    return __guard__(this.get("plan"), x => x.interval) < 10
  }

  getTrialExpiration() {
    const subscription = this.get("subscription") // the Stripe subscription
    if (!subscription) {
      return false
    }
    if (this.isTrial() && subscription.cycle_end_dtime != null) {
      return moment(this.get("subscription").cycle_end_dtime).toDate()
    }
    return null
  }

  isExpired() {
    if (!this.isTrial()) {
      return false
    }
    const exp = this.getTrialExpiration()
    if (exp) {
      const now = new Date()
      if (moment(exp).isBefore(now)) {
        return true
      }
    }
    return false
  }

  isPaused() {
    if (this.get("subscription") == null) {
      return false
    }
    return this.get("subscription").paused_until_dtime !== null
  }
}

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
