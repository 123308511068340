import { withTranslate } from "@pathwright/ui/src/components/"
import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import ThemeBackground from "../background/ThemeBackground"
import Button, { buttonPropType } from "../button/Button"
import ErrorBoundary from "../error/ErrorBoundary"
import Tooltip from "../tooltip/Tooltip"
import { useScreensizeContext } from "../ui/Screensize"
import Text from "../ui/Text"
import View from "../ui/View"
import "./Card.css"
import CardBack from "./CardBack"
import { useCardStackContext } from "./CardStack"
import { CARD_SIZES } from "./constants"
import { cardSizeStyle } from "./ui"

const Container = styled.div`
  ${cardSizeStyle}
`

const StyledHeader = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.8em;

  h5,
  h6 {
    position: relative; /* display above ThemeBackground */
  }
`

const Card = ({
  t,
  card,
  title,
  subTitle,
  headerTitle,
  headerSubTitle,
  headerContrast,
  navContrast,
  themeHeader,
  floatHeader,
  style,
  size,
  navless,
  noaction,
  children,
  completeAction,
  hideClose,
  emphasis,
  className: _className
}) => {
  const screensize = useScreensizeContext()
  const cardStackContext = useCardStackContext()
  const hasBeenActive = useRef(false)
  hasBeenActive.current = card.isActive || hasBeenActive.current

  // When card becomes active, size the entire cardstack based on active ("top") card.
  // This uniforms the widths of the underlying cards.
  useEffect(() => {
    if (card.isActive) {
      cardStackContext && cardStackContext.setCardStackSize(size)
    }
  }, [card.isActive])

  const renderAction = () => (
    <Button
      className="Card__Action"
      to={completeAction.to}
      onClick={completeAction.onClick}
      styleType={completeAction.styleType || "primary"}
      brand
    >
      {completeAction.children || t("card.add")}
    </Button>
  )

  const cardHeading = `${subTitle ? `${title}: ${subTitle}` : title}${
    card.isDirty ? "*" : ""
  }`

  const styles = {
    ...style,
    marginLeft: card.index > 0 ? "-100%" : undefined
  }

  const className = classnames(
    "Card",
    `Card--index-${card.index}`,
    {
      "Card--navless": navless,
      "Card--noaction": noaction
    },
    _className
  )

  const showHeader = !!headerTitle || !!headerSubTitle

  return (
    <ErrorBoundary>
      <Container className={className} style={styles} size={size}>
        <View className="CardContainer" rounded={screensize !== "sm"}>
          {showHeader && themeHeader && (
            <ThemeBackground
              roundedTop={screensize !== "sm"}
              roundedBottom={screensize !== "sm"}
            />
          )}
          {showHeader && (
            <StyledHeader
              className="CardHeader"
              emphasis={!floatHeader && !themeHeader && emphasis}
              contrast={headerContrast} // darker contrast
              paddingLeft
              paddingRight
              roundedTop={screensize !== "sm"}
            >
              {!!headerTitle && (
                <Text.H5 inverted={themeHeader || floatHeader}>
                  {headerTitle}
                </Text.H5>
              )}
              {!!headerSubTitle && (
                <Text.H6 inverted={themeHeader || floatHeader}>
                  {headerSubTitle}
                </Text.H6>
              )}
            </StyledHeader>
          )}
          <View
            className="CardBody"
            emphasis={emphasis}
            roundedTop={screensize !== "sm" || showHeader}
            roundedBottom={screensize !== "sm"}
          >
            {hasBeenActive.current && (
              <div
                style={{
                  display: card.showChildren ? "block" : "none"
                }}
              >
                {!navless && (
                  <View
                    background
                    className="Card__Nav"
                    contrast={navContrast}
                    roundedTop={screensize !== "sm" || showHeader}
                  >
                    {!card.hideClose && !hideClose && <CardBack card={card} />}
                    {!!title && (
                      <Text.H4 className="Card__Heading">{cardHeading}</Text.H4>
                    )}
                    {!!title && (
                      <Tooltip className="Card__Tooltip" title={cardHeading} />
                    )}
                    {!!completeAction && renderAction()}
                  </View>
                )}
                {children}
              </div>
            )}
          </View>
        </View>
      </Container>
    </ErrorBoundary>
  )
}

Card.displayName = "Card"

Card.propTypes = {
  card: PropTypes.shape({
    index: PropTypes.number,
    showChildren: PropTypes.bool,
    isDirty: PropTypes.bool,
    onClose: PropTypes.func
  }).isRequired,
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerSubTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  // TODO: handle backAction
  completeAction: buttonPropType,
  noaction: PropTypes.bool,
  isPristine: PropTypes.bool,
  hideClose: PropTypes.bool,
  emphasis: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  headerContrast: PropTypes.number,
  navContrast: PropTypes.number,
  themeHeader: PropTypes.bool,
  floatHeader: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(CARD_SIZES))
}

Card.defaultProps = {
  isPristine: true,
  emphasis: "primary",
  themeHeader: false,
  floatHeader: false,
  size: "medium"
}

export default withTranslate(Card)
