import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import styled, { css } from "styled-components"
import PathwrightUI, { useUIThemeContext } from "./PathwrightUI"
import { cssCX, componentWithCSS } from "../utils/styles"
import Screensize, { useScreensizeContext } from "./Screensize"

const StyledViewWithCSS = componentWithCSS(styled.div`
  &:empty {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  &.separate:first-child {
    border-top: none;
  }

  &.separate:last-child {
    border-bottom: none;
  }

  &.borderBottom:not(:empty) + &.borderTop:not(:empty) {
    border-top: none;
  }
`)

export const paddingScreensizeSmall = Screensize.select({
  sm: 8,
  md: 12,
  lg: 16
})

export const paddingScreensizeMedium = Screensize.select({
  sm: 10,
  md: 15,
  lg: 20
})

export const paddingScreensizeLarge = Screensize.select({
  sm: 12,
  md: 18,
  lg: 24
})

export const getPadding = (size, screensize) => {
  switch (size) {
    case "small":
      return paddingScreensizeSmall({ screensize })
    case "medium":
      return paddingScreensizeMedium({ screensize })
    case "large":
      return paddingScreensizeLarge({ screensize })
  }
}

const View = React.forwardRef((props, ref) => {
  const theme = useUIThemeContext()
  const screensize = useScreensizeContext()
  // optionally select props based on screensize
  const selectedProps = {
    ...props,
    ...Screensize.select(props.screensizeSelect)({ screensize })
  }

  const {
    emphasis,
    contrast,
    rounded,
    roundedTop,
    roundedBottom,
    shadow,
    background,
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    separate,
    screensizeSelect,
    size,
    el,
    ...rest
  } = selectedProps

  return (
    <PathwrightUI.Provider emphasis={emphasis || theme.emphasis}>
      <PathwrightUI.Consumer contrast={contrast}>
        {({ boxShadow, borderColor, borderRadius, backgroundColor }) => {
          const roundedTop = selectedProps.roundedTop || rounded
          const roundedBottom = selectedProps.roundedBottom || rounded
          const roundedLeft = selectedProps.roundedLeft || rounded
          const roundedRight = selectedProps.roundedRight || rounded
          const borderTop = selectedProps.borderTop || border || separate
          const borderBottom = selectedProps.borderBottom || border || separate
          const borderLeft = selectedProps.borderLeft || border
          const borderRight = selectedProps.borderRight || border
          const paddingTop = selectedProps.paddingTop || padding
          const paddingBottom = selectedProps.paddingBottom || padding
          const paddingLeft = selectedProps.paddingLeft || padding
          const paddingRight = selectedProps.paddingRight || padding
          return (
            <StyledViewWithCSS
              {...rest}
              as={el}
              ref={ref}
              className={classnames("View", rest.className, {
                roundedTop,
                roundedBottom,
                roundedLeft,
                roundedRight,
                borderTop,
                borderBottom,
                borderLeft,
                borderRight,
                paddingTop,
                paddingBottom,
                paddingLeft,
                paddingRight,
                separate
              })}
              css={cssCX({
                [css`${{ boxShadow }}`]: shadow && boxShadow, // prettier-ignore
                [css`${{ borderTopLeftRadius: borderRadius }}`]: (roundedTop || roundedLeft) && borderRadius, // prettier-ignore
                [css`${{ borderTopRightRadius: borderRadius }}`]: (roundedTop || roundedRight) && borderRadius, // prettier-ignore
                [css`${{ borderBottomLeftRadius: borderRadius }}`]: (roundedBottom || roundedLeft) && borderRadius, // prettier-ignore
                [css`${{ borderBottomRightRadius: borderRadius }}`]: (roundedBottom || roundedRight) && borderRadius, // prettier-ignore
                [css`${{ backgroundColor }}`]: (background || emphasis || contrast) && backgroundColor, // prettier-ignore
                [css`${{ borderTop: `1px solid ${borderColor}` }}`]: borderTop && borderColor, // prettier-ignore
                [css`${{ borderBottom: `1px solid ${borderColor}` }}`]: borderBottom && borderColor, // prettier-ignore
                [css`${{ borderLeft: `1px solid ${borderColor}` }}`]: borderLeft && borderColor, // prettier-ignore
                [css`${{ borderRight: `1px solid ${borderColor}` }}`]: borderRight && borderColor, // prettier-ignore
                // fine to always set borderColor for child elements to optionally inherit from?
                [css`${{ borderColor }}`]: borderColor, // prettier-ignore
                [css`${{ paddingTop: `${getPadding(size, screensize)}px` }}`]: paddingTop, // prettier-ignore
                [css`${{ paddingBottom: `${getPadding(size, screensize)}px` }}`]: paddingBottom, // prettier-ignore
                [css`${{ paddingLeft: `${getPadding(size, screensize)}px` }}`]: paddingLeft, // prettier-ignore
                [css`${{ paddingRight: `${getPadding(size, screensize)}px` }}`]: paddingRight // prettier-ignore
              })}
            />
          )
        }}
      </PathwrightUI.Consumer>
    </PathwrightUI.Provider>
  )
})

View.propTypes = {
  el: PropTypes.string,
  emphasis: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  rounded: PropTypes.bool,
  roundedTop: PropTypes.bool,
  roundedBottom: PropTypes.bool,
  roundedLeft: PropTypes.bool,
  roundedRight: PropTypes.bool,
  shadow: PropTypes.bool,
  background: PropTypes.bool,
  contrast: PropTypes.number,
  border: PropTypes.bool,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
  padding: PropTypes.bool,
  paddingTop: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  paddingLeft: PropTypes.bool,
  paddingRight: PropTypes.bool,
  separate: PropTypes.bool,
  screensizeSelect: PropTypes.object,
  size: PropTypes.oneOf(["small", "medium", "large"])
}

View.defaultProps = {
  el: "div",
  screensizeSelect: {},
  size: "medium"
}

View.Primary = React.forwardRef((props, ref) => (
  <View {...props} ref={ref} emphasis="primary" />
))

View.Secondary = React.forwardRef((props, ref) => (
  <View {...props} ref={ref} emphasis="secondary" />
))

View.Tertiary = React.forwardRef((props, ref) => (
  <View {...props} ref={ref} emphasis="tertiary" />
))

export default View
