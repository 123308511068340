import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"

const StyledContainer = styled.div`
  overflow: hidden;
`

const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const SchoolIcon = ({ size, className }) => {
  const styles = {
    width: size,
    height: size,
    borderRadius: size / 10
  }
  const { icon } = useUIThemeContext()
  return (
    <StyledContainer className={className} style={styles}>
      {icon && <StyledImage style={{ backgroundImage: `url(${icon})` }} />}
    </StyledContainer>
  )
}

SchoolIcon.displayName = "SchoolIcon"

export default SchoolIcon
