import "./DatePicker.css"
import "react-datetime/css/react-datetime.css"
import React from "react"
import moment from "moment"
import classnames from "classnames"
import DateTime from "react-datetime"
import IconButton from "../../button/IconButton"
import Pathicon from "../../pathicon/Pathicon"
import FieldWrapper from "../form-utils/FieldWrapper"

const DatePicker = props => {
  const {
    id,
    name,
    open,
    input,
    value,
    locale,
    placeholder,
    onChange,
    viewMode,
    inputProps,
    timeFormat,
    dateFormat,
    className,
    renderDay,
    renderYear,
    renderMonth,
    isValidDate,
    strictParsing,
    closeOnSelect
  } = props

  const processValue = () => {
    if (value == null) return
    const date = new Date(value)
    const isValid = moment(date).isValid()
    if (isValid) {
      return moment(date)
    } else {
      return undefined
    }
  }
  const processedValue = processValue()

  return (
    <FieldWrapper
      {...props}
      id={id || name}
      className={classnames("DatePicker", className)}
    >
      {({ onFocus, onBlur }) => {
        return (
          <div className="DatePicker__input">
            {/* Using IconButton for consistent styling. */}
            <IconButton el="span" icon="calendar" color="#888" />
            <DateTime
              value={processedValue}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              input={input}
              open={open}
              locale={locale}
              onBlur={onBlur}
              onFocus={onFocus}
              viewMode={viewMode}
              isValidDate={isValidDate}
              renderDay={renderDay}
              renderMonth={renderMonth}
              renderYear={renderYear}
              strictParsing={strictParsing}
              closeOnSelect={closeOnSelect}
              onChange={d => onChange(moment(d).toISOString())}
              inputProps={{ ...inputProps, id: id || name, name, placeholder }}
            />
            {input && processedValue && (
              <IconButton
                onClick={() => onChange(null)}
                icon="x"
                color="#888"
              />
            )}
          </div>
        )
      }}
    </FieldWrapper>
  )
}

DatePicker.displayName = "DatePicker"

DatePicker.defaultProps = {
  input: true,
  inputProps: {
    readOnly: true
  }
}

export default DatePicker
