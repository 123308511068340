import { getEnv } from "@pathwright/ui/src/components/utils/env"
import CustomEvent from "custom-event"
import {
  MEMBERSHIP_ROLE_ADMIN,
  MEMBERSHIP_ROLE_EDITOR,
  MEMBERSHIP_ROLE_FACILITATOR,
  MEMBERSHIP_ROLE_STUDENT
} from "../membership/constants"
import { hasPathwrightStaffAccess } from "../user/permissions"
import { isUserProxied } from "../user/utils"

const _shouldEnableIntercom = pwContext => {
  // Must have an app_id.
  if (!pwContext.integrations.intercom.app_id) return false

  // Must not be super user on production.
  if (hasPathwrightStaffAccess(pwContext) && getEnv("production")) return false

  // Must not be proxied in as another user.
  if (isUserProxied()) return false

  // Must not be in development, purely as a dev preference.
  // (Comment out if you need to for testing.)
  if (getEnv("development")) return false

  // Must not be in test mode, avoids runtime errors.
  if (getEnv("test")) return false

  return true
}

export const loadIntercom = async pwContext => {
  if (!_shouldEnableIntercom(pwContext)) return
  // Already loaded.
  if (window.Intercom) return

  return new Promise(resolve => {
    ;(function() {
      var w = window
      var ic = w.Intercom
      if (typeof ic === "function") {
        ic("reattach_activator")
        ic("update", w.intercomSettings)
      } else {
        var d = document
        var i = function() {
          i.c(arguments)
        }
        i.q = []
        i.c = function(args) {
          i.q.push(args)
        }
        w.Intercom = i
        var l = function() {
          var s = d.createElement("script")
          s.type = "text/javascript"
          s.async = true
          s.src =
            "https://widget.intercom.io/widget/" +
            pwContext.integrations.intercom.app_id
          s.onload = resolve
          var x = d.getElementsByTagName("script")[0]
          x.parentNode.insertBefore(s, x)
        }
        if (document.readyState === "complete") {
          l()
        } else if (w.attachEvent) {
          w.attachEvent("onload", l)
        } else {
          w.addEventListener("load", l, false)
        }
      }
    })()
  })
}

export const bootIntercom = pwContext => {
  const { school, me } = pwContext

  const getTimeStamp = date => (date ? new Date(date).getTime() / 1000 : null)

  // Maps to already used labels.
  const getMembershipRoleLabel = role => {
    switch (role) {
      case MEMBERSHIP_ROLE_STUDENT:
        return "Student"
      case MEMBERSHIP_ROLE_FACILITATOR:
        return "Staff Member"
      case MEMBERSHIP_ROLE_EDITOR:
        return "Library Editor"
      case MEMBERSHIP_ROLE_ADMIN:
        return "Admin"
      default:
        return "Unknown"
    }
  }

  if (window.Intercom && _shouldEnableIntercom(pwContext)) {
    let data = {
      app_id: pwContext.integrations.intercom.app_id,
      school_id: pwContext.school.id,
      use_counter: true
    }

    if (isUsingPathwrightIntercom(pwContext)) {
      data = {
        ...data,
        hide_default_launcher: true,
        vertical_padding: 100
      }
    }

    if (me) {
      data = {
        ...data,
        user_id: me.id,
        email: me.email,
        name: me.full_name,
        user_hash: pwContext.integrations.intercom.user_hash,
        avatar: {
          type: "avatar",
          image_url: me.profile.image
        }
      }

      // NOTE: below are some bools and other such values that we set on the user which
      // are actually user-school scoped attributes. Meaning, there is the potential for
      // these attributes to be overwritten anytime the user visits a school on Pathwright.
      // As far as we know, Intercom does not provide a way to track these types of attributes.
      // Best we can do is not override existing values when we can, hence we're conditionally
      // updating the bools.

      // User-school scoped bool!
      // NOTE: using window.bootstrappedData to avoid adding extra overhead to the context-query.
      if (
        window.bootstrappedData &&
        window.bootstrappedData.commerce &&
        window.bootstrappedData.commerce.subscription_plans &&
        window.bootstrappedData.commerce.subscription_plans.length
      )
        data.is_subscriber = Boolean(
          window.bootstrappedData &&
            window.bootstrappedData.commerce.user_subscriptions &&
            window.bootstrappedData.commerce.user_subscriptions.length
        )
      // User-school scoped bool!
      if (me.permissions.is_school_owner) data.is_school_owner = true
      // User-school scoped bool!
      if (me.permissions.can_administrate) data.is_school_admin = true
      // User-school scoped bool!
      if (me.membership && me.membership.role >= MEMBERSHIP_ROLE_FACILITATOR) data.is_school_staff = true // prettier-ignore
      // TODO: drop is_licensing, a user-school scoped bool!
      if (school.is_curriculum_subscriber) data.is_licensing = school.is_curriculum_subscriber // prettier-ignore

      if (me.membership) {
        data = {
          ...data,
          // User-school scoped value!!
          role: getMembershipRoleLabel(me.membership.role),
          // User-school scoped value!!
          consent_snapshot: me.membership.consent_snapshot,
          // User-school scoped value!!
          consented_at: getTimeStamp(me.membership.consent_dtime),
          // User-school scoped value!!
          created_at: getTimeStamp(me.membership.created)
        }

        // Associate user with school if they are an admin.
        if (me.membership.role >= MEMBERSHIP_ROLE_ADMIN) {
          data = {
            ...data,
            company: {
              id: school.id,
              name: school.name,
              url: school.url
            }
          }
        }
      }
    }
    // Boots Intercom and updates user in Intercom with provided data.
    window.Intercom("boot", data)
    dispatchIntercomEvent("boot")
  }
}

// Determine whether Pathwright Intercom is being used.
export const isUsingPathwrightIntercom = pwContext => {
  const { app_id, pathwright_app_id } = pwContext.integrations.intercom

  // Using Pathwrignt Intercom if app_id is same as pathwright_app_id
  return Boolean(
    window.Intercom &&
      app_id &&
      pathwright_app_id &&
      app_id === pathwright_app_id
  )
}

// A hacky way to listen for events we trigger on Intercom.
export const dispatchIntercomEvent = eventName => {
  const event = new CustomEvent("intercom", {
    detail: eventName
  })
  document.dispatchEvent(event)
}
