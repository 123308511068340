import { Input, InputGroup, InputLeftElement, Stack } from "@chakra-ui/react"
import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import { useState } from "react"
import { useEmailOptions } from "./SelectPeople"
import { useSelectPeopleContext } from "./SelectPeopleContext"

function SelectEmails() {
  const { addToPeopleSelection } = useSelectPeopleContext()
  const [emailStr, setEmailStr] = useState("")
  const emailOptions = useEmailOptions(emailStr)

  function handleSubmit(e) {
    e.preventDefault()
    // Maybe show some error feedback when invalid emails are present?
    if (emailOptions.length) {
      addToPeopleSelection(emailOptions)
      setEmailStr("")
    }
  }

  // Trying to match the height of the SelectInput.
  const h = "34px"

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <InputGroup h={h}>
          <InputLeftElement pointerEvents="none" h={h}>
            <Pathicon icon="envelope-fill" />
          </InputLeftElement>
          <Input
            value={emailStr}
            onChange={(e) => setEmailStr(e.target.value)}
            placeholder="Enter one or more email addresses to invite..."
            fontSize={{ base: "sm", md: null }}
            h={h}
          />
        </InputGroup>
      </Stack>
    </form>
  )
}

export default SelectEmails
