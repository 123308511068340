import "./Gate.css"
import React from "react"

import GateButton from "../button/GateButton"
import { useGatesContext } from "./Gates"

// Similar styles are used for ManageContentOptions
// Gate should become a simple wrapper to connect the click event.

const Gate = props => {
  const { setSelectedGate } = useGatesContext() || {}

  let handleClick
  if (props.to && props.navigate) {
    handleClick = () => props.navigate(props.to)
  }
  if (props.gate && setSelectedGate) {
    handleClick = () => setSelectedGate(props.gate)
  }
  return (
    <GateButton
      icon={props.icon}
      line1={props.line1}
      line2={props.line2}
      to={props.to}
      onClick={e => {
        handleClick && handleClick(e)
        props.onClick && props.onClick(e)
      }}
    >
      {props.children}
    </GateButton>
  )
}

Gate.displayName = "Gate"

export default Gate
