import Button from "@pathwright/ui/src/components/button/Button"
import useOnClickOutside from "@pathwright/ui/src/components/hooks/useOnClickOutside"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import InboxNavigator from "../navigator/InboxNavigator"
import useInboxContext from "../navigator/useInboxContext"
import { useReviewContext } from "../state/ReviewContextProvider"

const InboxSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
  flex-grow: 0;
  margin: 10px auto;
  position: relative;
  min-width: 0;

  > .Button {
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    background-color: transparent !important;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.4);
    }

    h1 {
      color: #fff;
      font-size: 1.2em;
      margin: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 1;
    }

    .Pathicon:first-child {
      margin-right: 6px;
    }

    .Pathicon:last-child {
      margin-left: 6px;
    }

    .Pathicon {
      color: #fff;
    }
  }
`

const InboxNavigatorContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
`

const InboxSelector = () => {
  const {
    context: { query },
    actions: { clearAllSelections }
  } = useReviewContext()

  const { inbox, loading } = useInboxContext(query)
  const [showItems, setShowItems] = useState(false)
  const toggleShowItems = () => setShowItems(showItems => !showItems)

  // Close selector when clicking outside container.
  const setNode = useOnClickOutside(
    useCallback(() => {
      if (showItems) setShowItems(false)
    }, [showItems])
  )

  const handleSelectInbox = selected => {
    clearAllSelections()
    toggleShowItems()
  }

  const showLoading = loading === true || !inbox

  return (
    <InboxSelectorContainer ref={setNode}>
      <Button styleType="primary" onClick={toggleShowItems}>
        <Pathicon icon="inbox-fill" />
        <h1>{showLoading ? "Loading... " : inbox.meta.title}</h1>
        <Pathicon icon="chevron-down" />
      </Button>
      {showItems && (
        <InboxNavigatorContainer>
          <InboxNavigator onSelect={handleSelectInbox} />
        </InboxNavigatorContainer>
      )}
    </InboxSelectorContainer>
  )
}

export default InboxSelector
