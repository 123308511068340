import Button from "@pathwright/ui/src/components/button/Button"
import Course from "@pathwright/ui/src/components/library/Course"
import Screensize from "@pathwright/ui/src/components/ui/Screensize"
import range from "lodash/range"
import React from "react"
import styled from "styled-components"
import BrowseBlock from "../BrowseBlock"
import BrowseResultDescription from "./BrowseResultDescription"

const List = styled.ul`
  margin: -10px;
  padding: 0;
  min-height: 80vh;
  list-style-type: none;
`

const Item = styled.li`
  flex-shrink: 0;
  padding: 10px;
  width: 100%;
  height: 100px;
`

function BrowseResult({
  total,
  resources,
  onClear,
  browseTo,
  loading,
  hasMore,
  loadMore
}) {
  return (
    <Screensize.Consumer>
      {screensize => (
        <BrowseBlock>
          <BrowseResultDescription total={total} loading={loading} />
          <List>
            {loading
              ? range(5).map(n => (
                  <Item key={n} screensize={screensize}>
                    <Course loading layout="list" />
                  </Item>
                ))
              : resources
              ? resources.map(resource => (
                  <Item key={resource.id} screensize={screensize}>
                    <Course
                      course={resource}
                      meta={resource.short_description}
                      to={browseTo(resource)}
                      layout="list"
                    />
                  </Item>
                ))
              : null}
            {hasMore && !loading && (
              <Item style={{ height: "initial" }}>
                <Button
                  onClick={loadMore}
                  styleType="empty"
                  rounded={false}
                  stretch
                >
                  Load more
                </Button>
              </Item>
            )}
          </List>
        </BrowseBlock>
      )}
    </Screensize.Consumer>
  )
}

BrowseResult.displayName = "BrowseResult"

export default BrowseResult
