import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import ConfirmationPrompt from "./ConfirmationPrompt"

const StyledDropdownTrigger = styled(DropdownTrigger)`
  min-height: 30px;
  min-width: 30px;
`

class Controls extends Component {
  state = {
    loading: false,
    success: false,
    pendingConfirmation: null
  }

  handleSuccess = () => {
    this.setState(
      {
        loading: false,
        success: true,
        pendingConfirmation: null
      },
      () => setTimeout(() => this.setState({ success: false }), 1000)
    )
  }

  handleAction = ({ action, confirmMessage, label }) => {
    confirmMessage
      ? this.setState({
          pendingConfirmation: { action, confirmMessage, label }
        })
      : this.executeAction(action)
  }

  handleCancel = () => {
    this.setState({
      pendingConfirmation: null
    })
  }

  executeAction = action => {
    this.setState({ loading: true })
    action()
      .catch(err => console.warn(err))
      .then(this.handleSuccess)
  }

  render() {
    const { loading, success, pendingConfirmation } = this.state
    const { label, confirmMessage, action } = pendingConfirmation || {}
    const { controls } = this.props
    return (
      <div>
        <StyledDropdownTrigger
          renderDropdown={() => {
            return !pendingConfirmation ? (
              <DropdownMenu closeOnClick>
                {controls.map((item, i) => {
                  const { action, confirmMessage, ...passProps } = item
                  return (
                    <DropdownItem
                      {...passProps}
                      key={i}
                      onClick={action ? () => this.handleAction(item) : null}
                    />
                  )
                })}
              </DropdownMenu>
            ) : null
          }}
        >
          {loading ? (
            <LoadingCircle size={20} />
          ) : (
            <IconButton
              styleType="blank"
              icon={success ? "check" : "ellipses-horizontal"}
              color={success ? "forestgreen" : "#888"}
              rotate={success ? 0 : 90}
            />
          )}
        </StyledDropdownTrigger>
        {pendingConfirmation && !loading && (
          <ConfirmationPrompt
            meta={confirmMessage}
            confirmButtonText={label}
            onConfirm={() => this.executeAction(action)}
            onCancel={this.handleCancel}
          />
        )}
      </div>
    )
  }
}

Controls.displayName = "Controls"
Controls.propTypes = {
  controls: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      to: PropTypes.string,
      action: PropTypes.func
    })
  )
}

export default Controls
