import Alert from "@pathwright/ui/src/components/alert/Alert"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import React from "react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const CompletionFailedAttempt = ({ step, ...rest }) => {
  const { t } = useTranslate()
  const { me } = usePathwrightContext()

  if (step.completion.has_failed_passing_attempt && step.grading_type === "A") {
    const tOptions = {
      user: step.completion.user.first_name,
      earned: step.completion.userpoints_earned,
      minimum: step.completion.minimum_passing_score,
      total: step.completion.userpoints_value
    }

    const translation =
      step.completion.user.id === me.id
        ? "Your last attempt to complete the step scored {{ earned, number }}/{{ total, number }} and did not meet the minimum required score of {{ minimum, number }}/{{ total, number }}."
        : "{{ user }}'s last attempt to complete the step scored {{ earned, number }}/{{ total, number }} and did not meet the minimum required score of {{ minimum, number }}/{{ total, number }}."

    return <Alert {...rest} error={t(translation, tOptions)} />
  }

  return null
}

CompletionFailedAttempt.displayName = "CompletionFailedAttempt"

export default CompletionFailedAttempt
