// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let PaymentHistory
export default PaymentHistory = class PaymentHistory extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    return this.urlRoot(`/api/private/payment/history/`)
  }
}
