import React, { Component } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"

// Either show or hide an element. Use of MutationObserver ensures the element is hidden or shown
// even if the element does not yet exist in the DOM when child components call the hide/show methods

class ToggleElementVisibility extends Component {
  state = {
    elementIds: {},
    elementClassNames: {}
  }

  UNSAFE_componentWillMount() {
    // search all child nodes for element with expected id
    const searchTree = node => {
      // check elementIds
      if (this.props.elementIds.indexOf(node.id) > -1) {
        // handle hide or show
        this.state.elementIds[node.id]
          ? this.showElements()
          : this.hideElements()
      }
      // check elementClassNames
      const className = this.props.elementClassNames.find(
        cn => node.classList && node.classList.contains(cn)
      )
      if (className) {
        // handle hide or show
        this.state.elementClassNames[className]
          ? this.showElements()
          : this.hideElements()
      }
      const l = get(node.children, "length")
      if (l) {
        for (var i = 0; i < l; i++) {
          searchTree(node.children[i])
        }
      }
    }

    // observe DOM for changes
    this.observer = new MutationObserver(mutations =>
      mutations.forEach(function(mutation) {
        const l = get(mutation.addedNodes, "length")
        if (l) {
          for (var i = 0; i < l; i++) {
            searchTree(mutation.addedNodes[i])
          }
        }
      })
    )
  }

  componentWillUnmount() {
    this.observer.disconnect()
  }

  observe() {
    this.observer.observe(document.body, { subtree: true, childList: true })
  }

  hideElements = () => {
    this.elements.forEach(el => (el.style.display = "none"))
  }

  showElements = () => {
    this.elements.forEach(el => (el.style.display = null))
  }

  handleHideElements = () => {
    this.observer.disconnect()
    this.handleObserveElements(false)
    this.hideElements()
  }

  handleShowElements = () => {
    this.observer.disconnect()
    this.handleObserveElements(true)
    this.showElements()
  }

  // all elements derived from ids and classNames
  get elements() {
    const elementsById = this.props.elementIds
      .map(id => document.getElementById(id))
      .filter(el => el)

    // nested arrays
    const elementsByClassName = this.props.elementClassNames.map(cn =>
      Array.from(document.getElementsByClassName(cn))
    )

    // reduce to flat array
    return elementsByClassName.reduce(
      (elements, nestedElements) => [...elements, ...nestedElements],
      elementsById
    )
  }

  handleObserveElements = show => {
    const getState = list =>
      list.reduce((update, id) => {
        // determines whether to show or hide the element when the observer observes the element entering the DOM
        update[id] = show
        return update
      }, {})

    this.setState({
      elementIds: getState(this.props.elementIds),
      elementClassNames: getState(this.props.elementClassNames)
    })
    // begin to observe in case element with missing id enters DOM before next hideElements or next showElements call
    this.observe()
  }

  render() {
    return React.Children.only(
      this.props.children({
        hideElements: this.handleHideElements,
        showElements: this.handleShowElements
      })
    )
  }
}

ToggleElementVisibility.displayName = "ToggleElementVisibility"

ToggleElementVisibility.propTypes = {
  elementIds: PropTypes.arrayOf(PropTypes.string),
  elementClassNames: PropTypes.arrayOf(PropTypes.string)
}

ToggleElementVisibility.defaultProps = {
  elementIds: [],
  elementClassNames: []
}

export default ToggleElementVisibility
