import { LICENSOR_SUPPORT } from "@pathwright/web/src/modules/support/SupportLicensorSchool"
import get from "lodash/get"
const { PATHWRIGHT_SCHOOL_ID } = require("lib/core/constants")
const urls = require("lib/core/utils/urls")

class SupportStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.defaults = {
      pathwright_support_email: "hello@pathwright.com",
      mode: null
    }

    this.prototype.storeTriggerHandlers = {
      school: {
        "bootstrapped:school"(data) {
          return this.bootstrapStore(data)
        }
      }
    }

    this.prototype.actions = {
      setMode(mode) {
        return this._mutate({ mode })
      },

      activateSupport() {
        const { support } = this.getState()
        const {
          has_faqs,
          support_phone,
          support_email,
          support_policy
        } = support
        const { user_is_school_staff } = this.getStore(
          "school"
        ).getState().permissions
        // School only has email support option
        if (
          !has_faqs &&
          !support_phone &&
          !user_is_school_staff &&
          !support_policy
        ) {
          if (window.Intercom) {
            return window.Intercom("show")
          } else if (support_email) {
            return (window.location.href = `mailto:${support_email}`)
          }
        }
        // TODO: fix so that this doesn't reset the primary component!
        return window.App.navigate(urls.modalRoute("/help/"))
      }
    }

    this.prototype.requests = {
      getMode() {
        const { school, permissions } = this.getStore("school").getState()
        const { mode } = this.getState()
        if (mode) {
          return mode
        }

        // Assuming school only has one licensor
        const curriculumLicensorId = get(
          school,
          "curriculum_licensee_subs[0].licensor.id"
        )
        const hasLicensorSupport =
          school.is_curriculum_subscriber &&
          curriculumLicensorId &&
          Object.values(LICENSOR_SUPPORT).find(
            id => id === curriculumLicensorId
          )

        if (
          (permissions.user_is_school_admin && !hasLicensorSupport) ||
          school.id === PATHWRIGHT_SCHOOL_ID
        ) {
          return "pathwright"
        }
        if (permissions.user_is_school_staff && hasLicensorSupport) {
          return "licensor"
        }
        return "school"
      }
    }
  }

  bootstrapStore(d) {
    return this._mutate({
      support: d.support
    })
  }
}
SupportStore.initClass()

export default window.App.stores.registerStore("support", SupportStore)
