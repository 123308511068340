import {
  ENROLLMENT_ROLE_EDITOR,
  ENROLLMENT_ROLE_FACILITATOR
} from "../invitation/constants"
import {
  MEMBERSHIP_ROLE_ADMIN,
  MEMBERSHIP_ROLE_EDITOR,
  MEMBERSHIP_ROLE_FACILITATOR
} from "../membership/constants"
import {
  MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN,
  MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR
} from "../mentor-group/constants"

export const hasPathwrightStaffAccess = pwContext => {
  if (!pwContext.me) return false
  if (pwContext.me.permissions.has_pathwright_staff_access) return true
  return false
}

export const hasSchoolStaffAccess = pwContext => {
  if (!hasAccess(pwContext)) return false
  if (hasPathwrightStaffAccess(pwContext)) return true
  if (
    pwContext.me.membership &&
    pwContext.me.membership.role >= MEMBERSHIP_ROLE_FACILITATOR
  )
    return true
  return false
}

export const hasAccess = pwContext => {
  if (!pwContext.me) return false
  if (hasPathwrightStaffAccess(pwContext)) return true
  if (pwContext.me.permissions.has_pathwright_staff_access) return true
  if (pwContext.me.membership) return true
  return false
}

export const canAdministrate = pwContext => {
  // checks if a user has manage access to the given school
  if (!hasAccess(pwContext)) return false
  if (hasPathwrightStaffAccess(pwContext)) return true
  if (
    pwContext.me.membership &&
    pwContext.me.membership.role === MEMBERSHIP_ROLE_ADMIN
  )
    return true
  return false
}

export const canEditLibrary = pwContext => {
  if (!hasAccess(pwContext)) return false
  if (canAdministrate(pwContext)) return true
  if (
    pwContext.me.membership &&
    pwContext.me.membership.role === MEMBERSHIP_ROLE_EDITOR
  )
    return true
  return false
}

const _hasGroupRole = (pwContext, { type, role }) => {
  if (!hasAccess(pwContext)) return false
  return !!pwContext.me.group_role_stats.find(
    stat => stat.type === type && stat.total > 0 && stat.role >= role
  )
}

export const canViewHomeTeachTab = pwContext => {
  if (!hasAccess(pwContext)) return false
  if (canEditLibrary(pwContext)) return true
  if (
    _hasGroupRole(pwContext, {
      type: "registration",
      role: ENROLLMENT_ROLE_FACILITATOR
    })
  )
    return true
  return false
}

export const canViewHomeDesignTab = pwContext => {
  if (!hasAccess(pwContext)) return false
  if (canEditLibrary(pwContext)) return true
  if (
    _hasGroupRole(pwContext, {
      type: "registration",
      role: ENROLLMENT_ROLE_EDITOR
    })
  )
    return true
  return false
}

// requiring the group status to be active,
// hence checking the active_registration type
export const canViewHomePeople = pwContext => {
  if (!hasAccess(pwContext)) return false
  if (canEditLibrary(pwContext)) return true
  if (
    _hasGroupRole(pwContext, {
      type: "active_registration",
      role: ENROLLMENT_ROLE_FACILITATOR
    })
  )
    return true
  if (
    _hasGroupRole(pwContext, {
      type: "community_group",
      role: MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR
    })
  )
    return true
  return false
}

export const canViewMentorGroups = pwContext => {
  if (!hasAccess(pwContext)) return false
  if (canEditLibrary(pwContext)) return true
  if (
    _hasGroupRole(pwContext, {
      type: "community_group",
      // Seems would be fine to allow mentors to view mentor groups as well?
      role: MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN
    })
  )
    return true
  return false
}
