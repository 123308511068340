import CodexAPI from "./graphql"
import CodexStore from "./store"

class CodexClient {
  config
  context
  api
  store

  constructor(config) {
    this.context = null
    this.config = config
    this.api = new CodexAPI(config)
  }

  get isInitiialized() {
    return !!this.context && !!this.store
  }

  async init() {
    this.context = await this.api.init()
    // await this.api.auth(this.context!.firebase.userToken)
    this.store = CodexStore(this.context)
    // create the user node?
    this.store.create(
      this.context.user.id,
      "persona",
      this.context.account.id,
      {}
    )
  }

  async load(id, type) {
    if (!this.isInitiialized)
      throw new Error(`You must call init() before accessing a node.`)

    const result = await this.api.load(id, true)

    if (!result) throw new Error(`Could not load node ${id}`)

    let node = this.store.create(id, type)

    result.nodes.forEach(data => {
      let n = this.store.create(
        data.id,
        data.key,
        data.type,
        node.id,
        data.data,
        {
          key: data.key,
          created: data.created,
          updated: data.updated,
          userId: data.userId,
          fromId: data.fromId,
          parentId: data.parentId,
          toId: data.toId
        }
      )
      node.nodes.push(n)
    })

    return node
  }

  log() {
    console.log(JSON.stringify(this.store.getState(), null, 2))
  }
}

export default CodexClient
