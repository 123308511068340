import { getQueryObject } from "@pathwright/web/src/modules/utils/urls"

class PaginatedCollection extends require("lib/static-shim").default(
  require("./collection").default
) {
  initialize(models, options) {
    if (options == null) {
      options = {}
    }
    this.options = options
    _.defaults(this.options, {
      page_size: 50,
      page: 1
    })
    if (this.options.filters) {
      this.options = _.extend(this.options, this.options.filters)
    }
    ;({
      filters: this.filters,
      pageSize: this.pageSize,
      page: this.page
    } = this.options)
    return super.initialize(models, this.options)
  }

  getURL(baseUrl, extra) {
    if (extra == null) {
      extra = {}
    }
    const params = this.getPaginationParams(extra)
    const url = `${baseUrl}?${$.param(params)}`
    return url
  }

  getPaginationParams(extra) {
    if (extra == null) {
      extra = {}
    }
    const params = _.extend(_.omit(this.options, "filters"), extra)
    return params
  }

  parse(response) {
    this._parsePaging(response)
    this._parseContext(response)
    const parsed = super.parse(response.results)
    return parsed
  }

  applyFilter(filters) {
    this.options.filters = this.filters = filters
    if (this.filters.ordering) {
      this.options.ordering = this.filters.ordering
    }
    const d = new $.Deferred()
    this.resetPaging()
    this.fetch({ remove: false, reset: true }).then(() => {
      this.trigger("applyFilter page", this)
      return d.resolve(this)
    })
    return d.promise()
  }

  paginate(page) {
    if (page == null) {
      page = 1
    }
    const d = new $.Deferred()
    this.options.page = page
    this.page = page
    this.fetch({ remove: false }).then(result => {
      if (result.context) {
        this.context = result.context
      }
      this.trigger("page", this)
      return d.resolve(this)
    })
    return d.promise()
  }

  resetPaging() {
    this.page = 1
    return (this.previousPage = this.nextPage = null)
  }

  next() {
    if (this.nextPage) {
      return this.paginate(this.nextPage)
    }
  }

  previous() {
    if (this.previousPage) {
      return this.paginate(this.previousPage)
    }
  }

  getNextCount() {
    const nextmax = this.nextPage * this.pageSize
    if (nextmax >= this.count) {
      return this.pageSize - (nextmax - this.count)
    } else {
      return this.pageSize
    }
  }

  getPreviousCount() {
    return this.pageSize
  }

  _parseContext(response) {
    if (response.context) {
      return (this.context = response.context)
    }
  }

  _parsePaging(response) {
    let next, nextPage, previous, previousPage
    ;({ next, previous, count: this.count } = response)
    if (this.nextPage == null) {
      this.nextPage = 0
    }
    if (this.previousPage == null) {
      this.previousPage = this.count
    }

    if (next && this.nextPage > -1) {
      const nextURL = new URL(next)
      nextPage = parseInt(getQueryObject(nextURL.search).page)
      this.nextPage = Math.max(nextPage, this.nextPage)
      this.pageSize = parseInt(getQueryObject(nextURL.search).page_size)
    } else {
      this.nextPage = -1
    }
    if (previous && this.previousPage > -1) {
      const previousURL = new URL(next)
      previousPage = parseInt(getQueryObject(previousURL.search).page)
      this.previousPage = Math.min(previousPage, this.previousPage)
      return (this.pageSize = parseInt(
        getQueryObject(previousURL.search).page_size
      ))
    } else {
      return (this.previousPage = -1)
    }
  }
}

export default PaginatedCollection
