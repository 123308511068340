import React from "react"
import "./Fieldset.css"
import FieldHeader from "./FieldHeader"
import FieldFooter from "./FieldFooter"

const Fieldset = ({ title, header, footer, children }) => (
  <div className="Fieldset">
    {header || title ? (
      <FieldHeader title={title}>{header}</FieldHeader>
    ) : (
      undefined
    )}
    {children}
    {footer ? <FieldFooter>{footer}</FieldFooter> : undefined}
  </div>
)

Fieldset.displayName = "Fieldset"

export default Fieldset
