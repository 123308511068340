import React from "react"
import PropTypes from "prop-types"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import Card from "@pathwright/ui/src/components/card/Card"
import { Form, Field, Submit } from "react-connect-form-forked"
import { HELP_LINK_GA } from "../../help/constants"

const ManageGoogleAnalytics = ({ card, initialValue, onSubmit }) => {
  return (
    <Card card={card} title="Google Analytics Integration">
      <Form onSubmit={onSubmit}>
        <CardBlock>
          <Fieldset
            title="Gain insight into engagement and marketing campaigns"
            header={
              <span>
                Integrate your free Google Analytics account to get deep
                insights
                on how people are finding and engaging with your courses.{" "}
                <a
                  href={HELP_LINK_GA}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more about tracking
                </a>
              </span>
            }
          >

            <Field
              initialValue={
                initialValue ? initialValue.analytics_account_id : null
              }
              name="analytics_account_id"
              label="GA Tracking ID"
              labelWidth={150}
              placeholder="UA-1234567-8"
              helperText="To enable, paste your tracking id from ADMIN > Property Settings > Tracking Id"
              component={TextInput}
            />
          </Fieldset>
        </CardBlock>
        <Submit styleType="primary" component={SubmitButton}>
          Save
        </Submit>
      </Form>
    </Card>
  )
}

ManageGoogleAnalytics.displayName = "ManageGoogleAnalytics"

ManageGoogleAnalytics.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    analytics_account_id: PropTypes.string
  })
}

export default ManageGoogleAnalytics
