import AlertBanner from "@pathwright/ui/src/components/alert/AlertBanner"
import { getEnv, getIsLocalDev } from "@pathwright/ui/src/components/utils/env"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import get from "lodash/get"
import React from "react"

const StagingPreviewAlert = ({ forceShow }) => {
  const { me } = usePathwrightContext()
  const isPWStaff = get(me, "permissions.has_pathwright_staff_access")
  const hideAlert = getEnv("production") || getIsLocalDev() || isPWStaff
  forceShow = forceShow && !getEnv("production")

  if (hideAlert && !forceShow) return null

  return (
    <AlertBanner.Banner
      icon="eye"
      message="Viewing Pathwright Preview Server"
      alertType="info"
      actionIcon="external-link"
      brand
      target="_blank"
      label="Learn More"
      href="https://help.pathwright.com/en/articles/5167187-how-to-preview-seasonal-feature-releases"
    />
  )
}

StagingPreviewAlert.defaultProps = {
  // Force the StagingPreviewAlert to be displayed for dev purposes.
  forceShow: false
}

export default StagingPreviewAlert
