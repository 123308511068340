const Spinner = require("spin.js")

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let LoadingView
require("./styles/loading.css")

export default LoadingView = (function() {
  LoadingView = class LoadingView extends require("lib/static-shim").default(
    require("lib/core/item-view").default
  ) {
    static initClass() {
      this.prototype.template = require("./templates/loading-view.html")
      this.prototype.className = "loading-container"
    }

    initialize(options) {
      if (options == null) {
        options = {}
      }
      this.options = options
      ;({ type: this.type, message: this.message } = this.options)
      return this.options.spinner_options != null
        ? this.options.spinner_options
        : (this.options.spinner_options = {})
    }

    onShow() {
      if (this.type === "path-skeleton") {
        return this.$el.addClass("path-skeleton")
      } else {
        // _.defaults @options,
        //     spinner_options: {}
        //     spinner_type: "default"
        const opts = this._getOptions(this.options.spinner_type)
        _.extend(opts, this.options.spinner_options)
        return (this.spinner = new Spinner(opts).spin(this.$el[0]))
      }
    }

    serializeData() {
      return { message: this.message }
    }

    onDestroy() {
      return this.spinner != null ? this.spinner.stop() : undefined
    }

    _getOptions(type) {
      if (type == null) {
        type = "default"
      }
      return {
        lines: 10, // The number of lines to draw
        length: 6, // The length of each line
        width: 2.5, // The line thickness
        radius: 7, // The radius of the inner circle
        corners: 1, // Corner roundness (0..1)
        rotate: 9, // The rotation offset
        direction: 1, // 1: clockwise, -1: counterclockwise
        color: "#fff", // #rgb or #rrggbb
        speed: 1, // Rounds per second
        trail: 60, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: true, // Whether to use hardware acceleration
        className: "loading-spinner", // The CSS class to assign to the spinner
        zIndex: 2e9
      }
    }
  }
  LoadingView.initClass()
  return LoadingView // The z-index (defaults to 2000000000)
})()
// top: 'auto' # Top position relative to parent in px
// left: 'auto' # Left position relative to parent in px

// configs =
//   default:
//     lines: 10 # The number of lines to draw
//     length: 6 # The length of each line
//     width: 2.5 # The line thickness
//     radius: 7 # The radius of the inner circle
//     corners: 1 # Corner roundness (0..1)
//     rotate: 9 # The rotation offset
//     direction: 1 # 1: clockwise, -1: counterclockwise
//     color: '#000' # #rgb or #rrggbb
//     speed: 1 # Rounds per second
//     trail: 60 # Afterglow percentage
//     shadow: false # Whether to render a shadow
//     hwaccel: true # Whether to use hardware acceleration
//     className: 'spinner' # The CSS class to assign to the spinner
//     zIndex: 2e9 # The z-index (defaults to 2000000000)
//     top: 'auto' # Top position relative to parent in px
//     left: 'auto' # Left position relative to parent in px
//   small:
//     lines: 13 # The number of lines to draw
//     length: 4 # The length of each line
//     width: 2 # The line thickness
//     radius: 4 # The radius of the inner circle
//     corners: 1 # Corner roundness (0..1)
//     rotate: 0 # The rotation offset
//     direction: 1 # 1: clockwise, -1: counterclockwise
//     color: "#000" # #rgb or #rrggbb
//     speed: 1.4 # Rounds per second
//     trail: 60 # Afterglow percentage
//     shadow: false # Whether to render a shadow
//     hwaccel: false # Whether to use hardware acceleration
//     className: "spinner" # The CSS class to assign to the spinner
//     zIndex: 2e9 # The z-index (defaults to 2000000000)
//     top: "auto" # Top position relative to parent in px
//     left: "auto" # Left position relative to parent in px
// configs[type]
