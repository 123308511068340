import { capitalCase } from "change-case"
import React from "react"
import { getCompletionAction } from "../../../completion/utils"

const What = ({ item, hideObject = false, hideVerb = false }) => {
  const object = item.data.name
  const verb = getCompletionAction(item.data.completion)
  return (
    <span className="Completion--What">
      {!hideVerb ? capitalCase(verb) : null}
      {!hideObject ? ` ${object}` : ""}
    </span>
  )
}

export default What
