import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from "styled-components"
import DropdownItem from "../dropdown/DropdownItem"
import DropdownMenu from "../dropdown/DropdownMenu"
import DropdownTrigger from "../dropdown/DropdownTrigger"
import Pathicon from "../pathicon/Pathicon"
import { SUBMIT_STATUS } from "../submit/SubmitHandler"
import SubmitSizer from "../submit/SubmitSizer"
import Tooltip from "../tooltip/Tooltip"
import Button from "./Button"
import ButtonGroup from "./ButtonGroup"
import SubmitButton from "./SubmitButton"

const StyledDropdownMenu = styled(props => <DropdownMenu {...props} />)`
  padding: 0 !important;
`

const StyledPathicon = styled(Pathicon)`
  margin-left: 8px;
`

// Handle opacity depending on whether elment should be seen or not based on if default status
const StyledDefaultVisibility = styled.div`
  opacity: ${props =>
    props.showWhenDefault
      ? props.status === SUBMIT_STATUS.DEFAULT
        ? 1
        : 0
      : props.status !== SUBMIT_STATUS.DEFAULT
      ? 1
      : 0};
`

const StyledOptionsContainer = styled(StyledDefaultVisibility)`
  display: grid;
  grid-template-columns: 1fr min-content;
`

// UI displayed when SelectButton is submitting and succeeds or fails
const StyledSubmitElement = styled(StyledDefaultVisibility)`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`

class SelectButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: props.options,
      showingDropDown: false
    }
  }

  get selectedOption() {
    return this.state.options.find(option => option.selected) || null
  }

  selectOption = selectedOption => {
    this.setState({
      options: this.props.options.map(option => ({
        ...option,
        selected: option.label === selectedOption.label
      }))
    })
  }

  toggleShowingDropDown = () => {
    this.setState({ showingDropDown: !this.state.showingDropDown })
  }

  render() {
    const {
      children,
      label,
      disabled,
      size,
      onSubmitFailure,
      onSubmitSuccess,
      onSubmitProcessing,
      brand,
      inverted,
      truncate,
      action
    } = this.props

    const hasOptions = this.state.options.length > 1

    return (
      <SubmitSizer
        className="SelectButton"
        onSubmit={this.selectedOption && this.selectedOption.action}
        onSubmitFailure={onSubmitFailure}
        onSubmitSuccess={onSubmitSuccess}
        onSubmitProcessing={onSubmitProcessing}
      >
        {({ status, style, sizerRef, handleSubmit }) => (
          <ButtonGroup disabled={disabled} ref={sizerRef}>
            <StyledSubmitElement status={status} showWhenDefault={false}>
              <SubmitButton
                el="div"
                className="IgnoreButtonGroup"
                styleType="primary"
                size={size}
                status={status}
                style={style}
                submitting={status === SUBMIT_STATUS.LOADING}
                submitSuccess={status === SUBMIT_STATUS.SUCCESS}
                submitFailure={status === SUBMIT_STATUS.FAILURE}
                brand={brand}
                inverted={inverted}
              />
            </StyledSubmitElement>
            <StyledOptionsContainer status={status} showWhenDefault>
              <Tooltip
                title={
                  this.selectedOption && !this.state.showingDropDown
                    ? this.selectedOption.label
                    : ""
                }
              >
                <DropdownTrigger
                  onShow={this.toggleShowingDropDown}
                  onHide={this.toggleShowingDropDown}
                  alignment="left"
                  trigger={disabled ? null : "click"}
                  renderDropdown={() =>
                    hasOptions ? (
                      <StyledDropdownMenu closeOnClick>
                        {this.state.options.map((option, i) => (
                          <DropdownItem
                            key={option.label}
                            selected={option.selected}
                            onClick={() => this.selectOption(option)}
                            brand={brand}
                            className="IgnoreButtonGroup"
                          >
                            {option.label}
                            {option.selected ? (
                              <StyledPathicon icon="check-circle" />
                            ) : null}
                          </DropdownItem>
                        ))}
                      </StyledDropdownMenu>
                    ) : null
                  }
                >
                  <Button
                    el={hasOptions ? "button" : "div"}
                    styleType="secondary"
                    disabled={disabled}
                    size={size}
                    brand={brand}
                    inverted={inverted}
                    truncate={truncate}
                  >
                    {hasOptions && (
                      <React.Fragment>
                        <Pathicon icon="chevron-down" />
                        <span> </span>
                      </React.Fragment>
                    )}
                    {label || children}
                  </Button>
                </DropdownTrigger>
              </Tooltip>
              {!!this.selectedOption && (
                <Button
                  styleType="primary"
                  // disabled={this.state.showingDropDown ? true : disabled}
                  disabled={disabled}
                  onClick={handleSubmit}
                  size={size}
                  brand={brand}
                  inverted={inverted}
                >
                  {action || (
                    <React.Fragment>
                      Go <Pathicon icon="chevron-right" />
                    </React.Fragment>
                  )}
                </Button>
              )}
            </StyledOptionsContainer>
          </ButtonGroup>
        )}
      </SubmitSizer>
    )
  }
}

SelectButton.displayName = "SelectButton"

SelectButton.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      selected: PropTypes.bool
    })
  ).isRequired,
  brand: PropTypes.bool,
  inverted: PropTypes.bool,
  truncate: PropTypes.bool,
  action: PropTypes.oneOf([PropTypes.string, PropTypes.node])
}

SelectButton.defaultProps = {
  brand: false,
  inverted: false,
  truncate: false,
  action: null
}

export default SelectButton
