// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const pluralize = require("pluralize")

const {
  createResourceLoader,
  createResourceLoadAction
} = require("./resource-loader")

const {
  createResourceSaver,
  createResourceSaveAction
} = require("./resource-saver")

const {
  createResourceDestroyer,
  createResourceDestroyAction
} = require("./resource-destroyer")

const { clearResourceCache } = require("./resource-cache")

const { isCollection } = require("./utils")

const getResourceState = function(Resource, resourceKey, options) {
  if (options == null) {
    options = {}
  }
  const keys = getResourceKeys(Resource, resourceKey, options)
  const values = [null, false, false, false, false, null]
  const state = _.zipObject(_.values(keys), values)
  return state
}

var getResourceKeys = function(Resource, resourceKey, options) {
  if (options == null) {
    options = {}
  }
  let keys = _.clone(options.keys || {})
  let key = keys.resourceKey || resourceKey
  if (isCollection(Resource) && options.pluralize) {
    key = pluralize(key)
  }
  keys = _.defaults({}, keys, {
    resourceKey: key,
    loadingKey: `${key}_loading`,
    loadedKey: `${key}_loaded`,
    savingKey: `${key}_saving`,
    destroyingKey: `${key}_destroying`,
    errorKey: `${key}_error`,
    controllerKey: `${key}_controller`
  })
  return keys
}

const createLoadAction = function(
  store,
  resourceKey,
  Resource,
  cache,
  options
) {
  if (options == null) {
    options = {}
  }
  const loader = createResourceLoader(Resource, resourceKey, cache)
  const keys = getResourceKeys(Resource, resourceKey, options)
  return createResourceLoadAction(
    store,
    loader,
    keys.resourceKey,
    keys.loadingKey,
    keys.errorKey,
    keys.loadedKey,
    keys.controllerKey
  )
}

const createSaveAction = function(
  store,
  resourceKey,
  Resource,
  cache,
  options
) {
  if (options == null) {
    options = {}
  }
  const saver = createResourceSaver(Resource, resourceKey, cache)
  const keys = getResourceKeys(Resource, resourceKey, options)
  return createResourceSaveAction(
    store,
    saver,
    keys.resourceKey,
    keys.savingKey,
    keys.errorKey,
    keys.loadedKey
  )
}

const createDestroyAction = function(
  store,
  resourceKey,
  Resource,
  cache,
  options
) {
  // TODO: resource destroyer should handle updating the cache if applicable
  // as well as updating the collection if it's set for the resource

  if (options == null) {
    options = {}
  }
  const updater = createResourceDestroyer(Resource, resourceKey, cache)
  const keys = getResourceKeys(Resource, resourceKey, options)
  return createResourceDestroyAction(
    store,
    updater,
    keys.resourceKey,
    keys.destroyingKey,
    keys.errorKey
  )
}

export const createResourceActions = function(store, resourceKey, options) {
  //console.log("createResourceActions: ", store, resourceKey)
  options = _.defaults(options, {
    cache: true,
    keys: {},
    pluralize: true
  })
  return getAction(store, resourceKey, options)()
}

var getAction = (store, resourceKey, options) =>
  // create a new scope
  function() {
    let destroy, list, load, save
    const { model, collection, cache } = options

    const initialState = {}

    // set the initial state for resource keys
    if (model) {
      const initialModelState = getResourceState(model, resourceKey, options)
      store._mutate(initialModelState)
      _.extend(initialState, initialModelState)

      load = createLoadAction(store, resourceKey, model, cache, options)
      save = createSaveAction(store, resourceKey, model)
      destroy = createDestroyAction(store, resourceKey, model, cache)
    } else {
      load = () =>
        console.error(`Cannot load ${resourceKey} without a model provided`)
      save = () =>
        console.error(`Cannot save ${resourceKey} without a model provided`)
      destroy = () =>
        console.error(`Cannot destroy ${resourceKey} without a model provided`)
    }

    if (collection) {
      const initialCollectionState = getResourceState(
        collection,
        resourceKey,
        options
      )
      store._mutate(initialCollectionState)
      _.extend(initialState, initialCollectionState)

      store._mutate(getResourceState(collection, resourceKey, options))
      list = createLoadAction(store, resourceKey, collection, cache, options)
    } else {
      list = () =>
        console.error(
          `Cannot list for loader ${resourceKey} since no resource collection was provided.`
        )
    }

    const clearCache = () => clearResourceCache(resourceKey)

    return {
      list,
      load,
      save,
      destroy,
      clearCache,
      initialState
    }
  }
