import React from "react"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"

const PaymentMaintenanceMode = () => (
  <BlankSlate
    icon="caution-triangle"
    heading="Payments Under Maintenance."
    body={
      <span>
        Please{" "}
        <a href="https://status.pathwright.com/" target="_blank">
          check back
        </a>{" "}
        shortly to complete your purchase.
      </span>
    }
  />
)

PaymentMaintenanceMode.displayName = "PaymentMaintenanceMode"

export default PaymentMaintenanceMode
