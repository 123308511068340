import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import PropTypes from "prop-types"
import React from "react"
import { HELP_LINK_SSO } from "../../help/constants"
import FeatureLock from "../../lib/FeatureLock"

const ManageSSO = ({ card, supportEmail }) => {
  return (
    <Card card={card} title="Single Sign-On">
      <FeatureLock has="single-sign-on-sso">
        {({
          hasFeatures,
          activationLocked,
          lockedFeatures,
          getPlansNameList
        }) =>
          !hasFeatures ? (
            <BlankSlate
              icon="integration-2"
              heading="Integrate with your own login"
              body={
                <>
                  Enable your members to sign-in using their existing login with
                  your system.{" "}
                  <a href={HELP_LINK_SSO} target="_blank">
                    Learn more about SSO support
                  </a>
                </>
              }
              meta={`Included in the ${getPlansNameList(
                lockedFeatures["single-sign-on-sso"]
              )}`}
              primaryAction={{
                children: activationLocked ? "Activate" : "Upgrade Plan",
                to: "/pricing/"
              }}
            />
          ) : (
            <BlankSlate
              icon="integration-2"
              heading="Integrate with your own login"
              body="To setup SSO with your SAML 2.0 compliant service, just get in touch with the integration team below."
              meta="Our integration team will be in touch with next steps."
              primaryAction={{
                children: "Contact Us",
                href: `mailto:${supportEmail}`
              }}
            />
          )
        }
      </FeatureLock>
    </Card>
  )
}

ManageSSO.displayName = "ManageSSO"

ManageSSO.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // if intercom isn't enabled, send email
  supportEmail: PropTypes.string
}

export default ManageSSO
