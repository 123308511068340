const { snapToList, getUserRef } = require("./utils")
const {
  MAX_NOTIFICATIONS_SHOWN,
  DISCUSSION_ASK_TO_ANSWER,
  RESPONSE_ASK_TO_REPLY
} = require("./constants")

const NotificationAction = require("./models/notification-action").default
const NotificationSettings = require("./models/notification-settings").default

class NotificationStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.storeTriggers = {
      "notification:received"() {
        return arguments
      }
    }

    this.prototype.storeTriggerHandlers = {
      school: {
        "bootstrapped:school"(data) {
          this.bootstrapStore(data)
          // return this.action.setSubscriptionState()
        }
      }
    }

    this.prototype.resources = {
      settings: {
        model: NotificationSettings
      },
      notification_action: {
        model: NotificationAction
      }
    }

    this.prototype.actions = {
      setSubscriptionState() {
        // handles subscribing/unsubscribing based on auth status
        if (this.getState().firebase != null) {
          if (!this.get("subscribed_to_ref")) {
            this.action.subscribeToNotifications()
          }
        } else {
          this.action.unsubscribeFromNotifications()
        }
      },

      subscribeToNotifications() {
        if (!this.getStore("auth").request.isAuthenticated()) {
          return
        }
        const url = this.request.notificationsURL()
        this._mutate({ notifications_loading: true })
        return getUserRef(`/user/${App.user.id}/notifications/`).then(ref => {
          ref
            .limitToLast(MAX_NOTIFICATIONS_SHOWN) // limits to newest first?
            .on("value", this.action.receiveFirebaseNotifications.bind(this))
          return this._mutate({ is_subscribed: true, subscribed_to_ref: ref })
        })
      },

      markNotificationRead(id, read = true) {
        const url = this.request.notificationsURL() + `${id}/`
        const ref = this.get("subscribed_to_ref")
        return ref
          .child(id)
          .child("read")
          .set(read)
      },

      unsubscribeFromNotifications() {
        const ref = this.get("subscribed_to_ref")
        if (ref) {
          ref.off("value", this.action.receiveFirebaseNotifications)
          return this._mutate({ is_subscribed: false, subscribed_to_ref: null })
        }
      },

      clearAll() {
        const ref = this.get("subscribed_to_ref")
        return ref.set(null)
      },

      clearNotification(id) {
        const url = this.request.notificationsURL() + `${id}/`
        const ref = this.get("subscribed_to_ref")
        return ref.child(id).set(null)
      },

      loadNotificationSettings() {
        return this.resource.settings.load()
      },

      saveNotificationSettings(data) {
        return this.resource.settings
          .save(data)
          .then(this.action.toggleSettingsShown)
      },

      toggleSettingsShown() {
        const settings_shown = !this.get("settings_shown")
        return this._mutate({ settings_shown })
      },

      toggleNotificationsShown() {
        const notifications_shown = !this.get("notifications_shown")
        return this._mutate({ notifications_shown })
      },

      receiveFirebaseNotifications(notificationsSnap) {
        const notifications_map = notificationsSnap.val()
        let notifications = snapToList(notificationsSnap)
        notifications = _.sortBy(notifications, n => -n.when).filter(
          n => !!n.action
        )
        this._mutate({
          notifications,
          notifications_map,
          notifications_loading: false
        })
        return this.action.emitNotificationChanges()
      },

      emitNotificationChanges() {
        // Don't emit changes on initial load
        let last_notifications_received_time
        if (!this.get("last_notifications_received_time")) {
          last_notifications_received_time = new Date().getTime()
          this._mutate({ last_notifications_received_time })
          return
        }

        // Check the previous notification IDs with the new ones
        const previous_ids = _.keys(this.previous("notifications_map"))
        const current_ids = _.keys(this.get("notifications_map"))
        const diff = _.difference(current_ids, previous_ids)

        for (let id of Array.from(diff)) {
          // filter out old notifications beyond a certain threshold (probably just a paged notification)
          const notification = this.get("notifications_map")[id]
          const notificationCreated = moment(new Date(notification.when))
          const threshold = moment().subtract(5, "minutes")
          if (notificationCreated.isAfter(threshold)) {
            this.storeTrigger("notification:received", notification)
          }
        }

        last_notifications_received_time = new Date().getTime()
        return this._mutate({ last_notifications_received_time })
      },

      sendAskToAnswerNotification(
        discussionItem,
        toUser,
        discussionContextKey
      ) {
        let target = `discussion_${discussionItem.id}`
        let action = DISCUSSION_ASK_TO_ANSWER
        let discussion_context = __guard__(
          discussionItem != null ? discussionItem.context : undefined,
          x => x.id
        )
        if (discussionItem.discussion != null) {
          // this is a response!
          target = `response_${discussionItem.id}`
          action = RESPONSE_ASK_TO_REPLY
          discussion_context = __guard__(
            this.getStore("discussion").getState().discussion,
            x1 => x1.context.id
          )
        }
        return this.action.sendActionNotification(
          action,
          target,
          [toUser.id],
          discussionContextKey,
          discussion_context
        )
      },

      sendActionNotification(
        action,
        target,
        to_users,
        context,
        discussion_context,
        send_email
      ) {
        if (send_email == null) {
          send_email = true
        }
        const payload = {
          action,
          target,
          context,
          to_users: to_users.join(","),
          discussion_context,
          send_email
        }
        return this.resource.notification_action.save(payload)
      }
    }

    this.prototype.requests = {
      notificationsURL() {
        const base = __guard__(this.getState().firebase, x => x.url)
        return `${base}/user/${App.user.id}/notifications/`
      }
    }
  }

  defaults() {
    return {
      notifications: [], // list of user's notification (not implemented)
      subscribed_to_ref: null, // the subscribed to firebase ref
      settings: null,
      firebase: null, // school firebase integration

      // UI
      last_notifications_received_time: null,
      is_sending_action_notification: false,
      notifications_loading: false,
      notifications_shown: false,
      subscribe_error: null,
      is_subscribed: false,
      settings_shown: false
    }
  }

  bootstrapStore(data) {
    return this._mutate({ firebase: data.integrations.firebase })
  }
}
NotificationStore.initClass()

export default window.App.stores.registerStore(
  "notification",
  NotificationStore
)
function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
