import React from "react"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import styled from "styled-components"

const ControlsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);

  .IconButton {
    color: #999;
  }
`

const ControlsButton = ({ onClick, ...props }) => {
  return (
    <ControlsButtonContainer className="ControlsButton">
      <IconButton icon="ellipses-horizontal" onClick={onClick} {...props} />
    </ControlsButtonContainer>
  )
}

export default ControlsButton
