import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import withLauncher from "@pathwright/ui/src/components/launcher/withLauncher"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import gql from "graphql-tag"
import Place from "./Place"
import UserPreview from "./UserPreview"

const PlaceLauncher = withLauncher({
  Component: Place,
  Preview: ThemeBackground
})

const UserPlace = withTranslate(({ user, teacherRegistrations, Trans }) => {
  if (!user) return null
  const hasRegistrations = teacherRegistrations.edges.length > 0
  return (
    <PlaceLauncher
      to="/home/teach/"
      icon={user}
      name={user.first_name}
      action={hasRegistrations ? "Visit your home" : "Teach a course"}
      isBlank={!hasRegistrations}
      blankImage="teaching"
      blankDescription="Guide people through the courses you teach."
      footer={
        // prettier-ignore
        // Trans formatting integral to translation json!
        teacherRegistrations.total > 0 &&
        hasRegistrations && (
          <Trans
            parent="p"
            i18nKey="dashboard.places.user.footer"
            count={teacherRegistrations.total}>
            Teaching {{ count: teacherRegistrations.total }} cohorts
          </Trans>
        )
      }
    >
      {hasRegistrations && (
        <UserPreview
          groups={teacherRegistrations.edges
            .map(edge => edge.node.group)
            .reverse()}
        />
      )}
    </PlaceLauncher>
  )
})

UserPlace.displayName = "UserPlace"

UserPlace.fragments = {
  user: gql`
    fragment UserDisplay on User {
      id
      first_name
      last_name
      display_name
      profile {
        id
        image(width: 100, height: 100)
      }
    }
  `,
  teacherRegistrations: gql`
    fragment TeacherRegistrations on User {
      registrations(first: 6, role_filter: { gt: student }) {
        total
        edges {
          node {
            group {
              id
              name
              seats_filled
              resource {
                id
                slug
              }
              path(type: source) {
                id
              }
            }
          }
        }
      }
    }
  `
}

export default UserPlace
