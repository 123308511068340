const EnterKeyPrompt = () => {
	return (
		<span className="EnterKeyPrompt">
			↵ to add
		</span>
	)
}

EnterKeyPrompt.displayName = "EnterKeyPrompt"

export default EnterKeyPrompt