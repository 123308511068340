import React from "react"
import { useObserveSizeContext } from "../observers/ObserveSizeContext"

// Bumps element down to make room for any alert banners (like staging preview).
const AlertBannerSpacer = props => {
  const { getRectValue } = useObserveSizeContext()
  return (
    <div
      {...props}
      style={{
        marginTop: getRectValue("alert.height")
      }}
    />
  )
}

export default AlertBannerSpacer
