import {
  getHumanExtensionList,
  getHumanFileSize,
  getOptions,
  getSignedFilestackURL,
  pick
} from "@pathwright/ui/src/components/filestack/utils"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import PropTypes from "prop-types"
import React from "react"
// USAGE:
// <FilePickerUpload
//   mediaType={"image"}
//   previewer={<UploadPreview />}
//   uploader={<UploadArea />}
//   />
import "./FilePickerUpload.css"

const FilePickerPreviewer = ({ file }) => (
  <div className="FilePickerPreviewer">
    {(() => {
      if (file) {
        const url = getSignedFilestackURL(file.url)
        return (
          <span>
            File selected:{" "}
            <a href={url} target="_blank">
              {file.filename}
            </a>
          </span>
        )
      }
    })()}
  </div>
)

const FilePickerUploader = function({ uploading, error, mediaType, pickFile }) {
  if (uploading) {
    return (
      <div className="FilePickerUpload__uploading">
        <LoadingCircle />
        {`\
Picking...\
`}
      </div>
    )
  } else if (error) {
    return (
      <div className="alert alert-error FilePickerUpload__error">{error}</div>
    )
  } else {
    // supportsDragDrop = typeof(FileReader) is 'function' and Modernizr.draganddrop
    // uploadPrompt = "Drag and drop #{mediaType} here or "
    // if not supportsDragDrop
    //   uploadPrompt = "Upload #{mediaType}"

    let message
    let { maxSize } = getOptions(mediaType.toUpperCase())
    const extensions = getHumanExtensionList(mediaType)
    const size = getHumanFileSize(maxSize)

    if (mediaType.toLowerCase() === "file") {
      // includes all extensions, so we don't list them
      message = `Upload any file no larger than ${size}.`
    } else {
      const article_an = ["Image", "Archive", "audio"]
      // all other mediaType(s) are preceded by "a"
      const article = article_an.indexOf(mediaType) > -1 ? "an" : "a"
      message = `Upload ${article} ${mediaType} (${extensions}) no larger than ${size}.`
    }
    return (
      <div className="FilePickerUpload__instructions">
        <p>{message}</p>
        <a
          href="javascript:void(0)"
          onClick={pickFile}
          className="pw-pick-file Button default inverted brand-color"
        >
          Pick a File
        </a>
      </div>
    )
  }
}

class FilePickerUpload extends React.Component {
  static displayName = "FilePickerUpload"

  static propTypes = {
    mediaType: PropTypes.string,
    onFileUploaded: PropTypes.func,
    previewer: PropTypes.func,
    uploader: PropTypes.func
  }

  static defaultProps = {
    previewer: FilePickerPreviewer,
    uploader: withTranslate(FilePickerUploader)
  }

  state = {
    uploading: false,
    error: null,
    file: this.props.file
  }

  pickFile = async () => {
    const fpOptions = getOptions(this.props.mediaType)

    this.setState({
      uploading: true,
      error: null
    })

    const result = await pick(fpOptions)
    if (result.filesFailed.length) {
      return this.onPickError(
        `Error uploading file: ${result.filesFailed[0].filename}`
      )
    }

    if (!result.filesUploaded.length) {
      return this.onPickError("One or more files could not be uploaded.")
    }

    this.onPickSuccess(result.filesUploaded[0])
  }

  onPickSuccess = fpfile => {
    this.setState({
      file: fpfile,
      error: null,
      uploading: false
    })
    return this.props.onFileUploaded(fpfile)
  }

  onPickError = fperror => {
    let error = null
    if (fperror.code !== 101) {
      error = fperror.toString()
    }
    return this.setState({
      uploading: false,
      error
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.file) !== JSON.stringify(this.props.file)) {
      return this.setState({
        file: nextProps.file
      })
    }
  }

  render() {
    const { file, uploading, error } = this.state
    const { mediaType, previewer: Previewer, uploader: Uploader } = this.props
    const { pickFile } = this

    return (
      <div className="FilePickerUpload">
        <Previewer file={file} />
        <Uploader
          uploading={uploading}
          error={error}
          mediaType={mediaType}
          pickFile={pickFile}
        />
      </div>
    )
  }
}

export default withTranslate(FilePickerUpload)
