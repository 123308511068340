import React from "react"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import ResourceBackground from "@pathwright/ui/src/components/background/ResourceBackground"
import { useReviewContext } from "../state/ReviewContextProvider"
import { findFirstParentOfType } from "../state/tree/path-tree"

import styled from "styled-components"

const BackgroundContainer = styled.div`
  position: fixed;
  /* position: absolute; */
  background-color: rgba(0, 0, 0, 0.77);
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  &.with-overlay {
    // add a backgroudn overlay
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.33);
    }
  }
`

const SelectionBackground = ({ item, overlay = false }) => {
  const { selected, selectedParent } = useReviewContext()

  item = item || selected || selectedParent

  // TODO: get the resource background from the inbox context.
  const path = item ? findFirstParentOfType(item, "path") : null

  var className = "SelectionBackground"
  if (overlay) className += " with-overlay"

  return (
    <BackgroundContainer className={className}>
      {path ? (
        <ResourceBackground resource={path.data.resource} forceBlur />
      ) : (
        <ThemeBackground forceBlur />
      )}
    </BackgroundContainer>
  )
}

export default SelectionBackground
