import PropTypes from "prop-types"

// NOTE: babel likely causing issues with named capture groups, this throws SyntaxError
// const sizeRe = /^(?<plus>+)(?<minus>-)(?<number>\d*\.?\d+)(?<unit>[a-z]+)/i

const sizeRe = /^(-)?(\d*\.?\d+)([a-z]+)/i

export const sizePropType = PropTypes.oneOfType([
  PropTypes.shape({
    number: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired
  }),
  PropTypes.array,
  PropTypes.string,
  PropTypes.number
])

sizePropType._serialize = size => {
  let number, unit

  if (typeof size !== undefined) {
    if (Array.isArray(size)) {
      number = size[0]
      unit = size[1]
    }

    if (typeof size === "object") {
      number = size.number
      unit = size.unit
    }

    if (typeof size === "string") {
      const m = size.match(sizeRe)
      if (m) {
        number = parseFloat(m[2])
        unit = m[3].toLowerCase()
        if (m[1]) number = number * -1
      }
    }

    if (typeof size === "number") {
      number = size
      unit = "px"
    }
  }

  return {
    number: number,
    unit: unit,
    style: number && unit ? `${number}${unit}` : ""
  }
}
