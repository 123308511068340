// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let RadioBehavior;
export default (RadioBehavior = (function() {
  RadioBehavior = class RadioBehavior extends require("lib/static-shim").default(Marionette.Behavior) {
    static initClass() {
  
      this.prototype.defaults = {
        bindEvent: "render",
        attribute: "data-radio",
        getModel() { return this.model; },
        getState() { return this.state; }
      };
    }

    initialize() {
      this.attribute = this.options.attribute;
      if (!this.attribute) {
        throw new Error("An attribute must be defined.");
      }
      return this.listenTo(this.view, this.options.bindEvent, () => {
        this.getData();
        return this.setEachList();
      });
    }

    setEachList() {
      const $uls = this.$(`ul[${this.attribute}]`);
      return $uls.each((i, el)=> {
        const $ul = $(el);
        const radio = $ul.attr(this.attribute);
        this.setupStateToggle(radio, $ul);
        return this.initState(radio);
      });
    }

    getData() {
      this.model = _.bind(this.options.getModel, this.view)();
      return this.state = _.bind(this.options.getState, this.view)();
    }

    setupStateToggle(radio, $ul){
      // Watch for note toggle changes and properly bind to model values
      return this.listenTo(this.state, `change:${radio}`, (...args)=> {
        // Get the value
        const v = parseInt(args[1]) || args[1];
        // Find the input with this value, and get its parent li
        const $li = $ul.find(`input[value='${v}']`).closest("li");
        // Get the currently selected state
        const current = _.last($li.attr("rv-class-selected").split("state:"));
        // Update current state
        this.state.set(current, true);
        this.state.set(this[`previous-${radio}`], false);
        this.model.set(radio, v);
        // Keep track of the current state
        return this[`previous-${radio}`] = current;
      });
    }

    initState(radio){
      // Need defer here in case @ is being shown in a layout view
      // that is rendered at the same time.
      return _.defer(() => {
        return this.state.set(radio, this.model.get(radio));
      });
    }
  };
  RadioBehavior.initClass();
  return RadioBehavior;
})());
