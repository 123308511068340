import { graphql } from "@apollo/client/react/hoc"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import get from "lodash/get"
import PropTypes from "prop-types"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import RESOURCE_EDITORS_QUERY from "./graphql/resource-editors-query"
import ResourceEditorsHeader from "./ResourceEditorsHeader"
import ResourceEditorsList from "./ResourceEditorsList"

const ResourceEditorsCard = ({ card, ...data }) => (
  <Card card={card} navless>
    {data.error ? (
      <BlankSlate
        icon="caution-triangle"
        body={`An unexpected error occurred: ${data.error.message}`}
      />
    ) : (
      <div>
        <ResourceEditorsHeader card={card} resource={data.resource} />
        <ResourceEditorsList
          error={data.error}
          loading={data.loading}
          refetch={data.refetch}
          resource={data.resource}
          group={data.group}
          registrations={data.registrations}
          loadMore={data.loadMore}
        />
      </div>
    )}
  </Card>
)

ResourceEditorsCard.displayName = "ResourceEditorsCard"

ResourceEditorsCard.propTypes = {
  id: PropTypes.number.isRequired
}

export default graphql(RESOURCE_EDITORS_QUERY, {
  options: ({ id }) => ({
    variables: {
      id
    }
  }),
  props: ({ data }) => ({
    error: data.error,
    loading: data.loading,
    resource: data.resource,
    group: get(data, "resource.group"),
    registrations: flattenEdges(get(data, "resource.group.registrations")),
    loadMore: createPaginator(data, "resource.group.registrations")
  })
})(ResourceEditorsCard)
