import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import PropTypes from "prop-types"
import React from "react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const ManageIntegrationMenu = ({
  card,
  gaEnabled,
  zapierEnabled,
  ssoEnabled
}) => {
  const pwContext = usePathwrightContext()

  return (
    <Card card={card} title="App Integrations">
      <CardLink
        to={`${card.path}intercom/`}
        icon="chat-bubble"
        label="Integrate Intercom.io"
        meta="Enable Intercom messenger within your account."
        preview={pwContext.integrations.intercom.school_app_id ? "Enabled" : ""}
      />
      <CardLink
        to={`${card.path}ga/`}
        icon="bar-chart"
        label="Track with Google Analytics"
        meta="Add Google Analytics to track visits, time on site, and more."
        preview={gaEnabled ? "Enabled" : ""}
      />
      <CardLink
        to={`${card.path}zapier/`}
        icon="integration-2"
        label="Integrate with other apps via Zapier"
        meta="Use triggers & actions to integrate with hundreds of apps."
        preview={zapierEnabled ? "Enabled" : ""}
      />
      <CardLink
        to={`${card.path}sso/`}
        icon="integration-2"
        label="Integrate with your own login"
        meta="Enable SSO so members can use an external login to sign in."
        preview={ssoEnabled ? "Enabled" : ""}
      />
      <CardLink
        to={`${card.path}custom/`}
        icon="code-square"
        label="Add custom code & pixel tracking"
        meta="Inject custom JavaScript or HTML code (advanced)"
      />
    </Card>
  )
}

ManageIntegrationMenu.displayName = "ManageIntegrationMenu"

ManageIntegrationMenu.propTypes = {
  card: PropTypes.object.isRequired,
  gaEnabled: PropTypes.bool,
  zapierEnabled: PropTypes.bool,
  ssoEnabled: PropTypes.bool
}

export default ManageIntegrationMenu
