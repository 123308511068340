import { ConnectStores } from "lib/core/store"
import GroupAccessForm from "./GroupAccessForm"

const mapStateToProps = function(state, ownProps) {
  const { group } = state.groupAdminStore.state
  const { saveGroup } = state.groupAdminStore.action

  const getDateString = function(date) {
    if (date != null) {
      return moment(date).toISOString()
    } else {
      return null
    }
  }

  const initialValues = {
    seats_limit: (group != null ? group.seats : undefined) > 0 ? 1 : 0,
    seats: (group != null ? group.seats : undefined) > 0 ? group.seats : null,
    enrollment_window:
      (group != null ? group.enrollment_starts : undefined) ||
      (group != null ? group.enrollment_ends : undefined)
        ? true
        : false,
    enrollment_starts: group != null ? group.enrollment_starts : undefined,
    enrollment_ends: group != null ? group.enrollment_ends : undefined,
    access_window:
      (group != null ? group.access_starts : undefined) ||
      (group != null ? group.access_ends : undefined)
        ? true
        : false,
    access_starts: group != null ? group.access_starts : undefined,
    access_ends: group != null ? group.access_ends : undefined
  }

  const onSubmit = function(values) {
    const {
      seats,
      access_window,
      access_starts,
      access_ends,
      enrollment_window,
      enrollment_starts,
      enrollment_ends
    } = values

    const groupData = {
      seats: seats > 0 ? seats : null,
      access_starts: access_window ? getDateString(access_starts) : null,
      access_ends: access_window ? getDateString(access_ends) : null,
      enrollment_starts: enrollment_window
        ? getDateString(enrollment_starts)
        : null,
      enrollment_ends: enrollment_window ? getDateString(enrollment_ends) : null
    }

    return saveGroup(groupData)
  }

  return _.assign(
    {},
    {
      group,
      initialValues,
      onSubmit
    },
    ownProps
  )
}

const stores = [{ store: "groupAdmin", watch: ["group"] }]

export default ConnectStores(GroupAccessForm, stores, {}, mapStateToProps)
