import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import CompletionsItem from "./CompletionsItem"
import CompletionsListHeader, {
  getCompletionsFilerInfo
} from "./CompletionsListHeader"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const Nav = styled.nav`
  flex-shrink: 0;
  height: 50px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Close = styled(IconButton)`
  position: absolute;
  top: 0;
  left: 0;
`
const Body = styled(View.Secondary)`
  min-height: 0; /* Firefox fix */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
const Scroll = styled(ScrollView)`
  /* flex-grow: 1; */
`
const Header = styled.header`
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;
  padding: 15px 25px;
`
const List = styled.ul`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  max-width: 1000px;
  list-style: none;
`

const StyledLoader = styled.div`
  position: relative;
  min-height: 35px;
`

const CompletionsList = ({
  error,
  loading,
  pathItems,
  hasMore,
  loadMore,
  loadingMore,
  user,
  step,
  hideUser,
  selectedId,
  onClose,
  filter,
  onFilter,
  onSelect,
  children
}) => {
  const { me } = usePathwrightContext()
  const { t } = useTranslate()

  return (
    <>
      <Wrapper>
        <Nav className="CompletionsList__nav">
          <Close
            onClick={onClose}
            styleType="blank"
            icon="chevron-left"
            size={50}
            inverted
          />
          {step ? (
            <Text.H5 inverted>{step.name}</Text.H5>
          ) : user && !(me && user.id === me.id) ? (
            <Text.H5 inverted>{user.display_name}</Text.H5>
          ) : (
            <Text.H5 inverted>Completions</Text.H5>
          )}
        </Nav>
        <Body>
          <Header className="CompletionsList__header">
            <CompletionsListHeader
              user={user}
              filter={filter}
              onFilter={onFilter}
            />
          </Header>
          <Scroll>
            {pathItems && pathItems.length ? (
              <List>
                {pathItems.map(item => (
                  <li key={item.id} className="CompletionList__item">
                    <CompletionsItem
                      step={item}
                      completion={item.completion}
                      hideUser={hideUser}
                      selected={item.id === selectedId}
                      onSelect={onSelect}
                    />
                  </li>
                ))}
                {loadingMore ? (
                  <StyledLoader>
                    <LoadingCircle center />
                  </StyledLoader>
                ) : hasMore ? (
                  <Button
                    onClick={loadMore}
                    styleType="empty"
                    rounded={false}
                    stretch
                  >
                    Load more
                  </Button>
                ) : null}
              </List>
            ) : loading ? (
              <LoadingCircle />
            ) : error ? (
              <BlankSlate
                icons={null}
                heading="An unexpected error occurred."
                body={
                  (error &&
                    error.message &&
                    error.message.replace("GraphQL error: ", "")) ||
                  error
                }
              />
            ) : (
              <BlankSlate
                icons={null}
                heading={getCompletionsFilerInfo(filter, t).blankHeading}
              />
            )}
          </Scroll>
        </Body>
      </Wrapper>
      {children({ selectedId, onSelect, filter })}
    </>
  )
}

CompletionsList.displayName = "CompletionsList"

CompletionsList.propTypes = {
  // CompletionsNav uses to provide context
  user: PropTypes.object,
  step: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  // highlight selected item
  selectedId: PropTypes.number,
  // active filter
  filter: PropTypes.string,
  // query result
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  pathItems: PropTypes.arrayOf(
    PropTypes.shape({
      completion: PropTypes.object.isRequired
    })
  ),
  // could be used to display a Panel, for instance
  children: PropTypes.func
}

CompletionsList.defaultProps = {
  onSelect: () => null,
  onClose: () => null,
  children: () => null
}

export default CompletionsList
