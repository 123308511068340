import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Form, Submit } from "react-connect-form-forked"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Text from "@pathwright/ui/src/components/ui/Text"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const BLACK = "#2a2e33"

const Container = styled.div`
  text-align: center;

  > div {
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    border: 2px solid ${BLACK};
    border-radius: 8px;
    margin-bottom: 20px;

    img,
    div,
    p {
      margin-bottom: 10px;
    }

    img {
      width: 80px;
    }

    p {
      display: flex;
      flex-direction: column;

      h4 {
        text-transform: uppercase;
      }
    }
  }
`

const Code = styled.div`
  color: white;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 30px;
  background-color: ${BLACK};
`

const RedeemSchoolGiftSubscriptionGift = ({
  gift_subscription,
  redeemGiftSubscription,
  giftSubscriptionRedeeming
}) => {
  const { school } = usePathwrightContext()
  return gift_subscription ? (
    <Container>
      <div>
        <img src={gift_subscription.school_subscription_plan.image} />
        {gift_subscription.school_subscription_plan.subscription_plan
          .interval === 20 ? (
          <Text.Body>
            <Text.H4>
              {
                gift_subscription.school_subscription_plan.subscription_plan
                  .name
              }
            </Text.H4>
            <em>from {school.name}</em>
          </Text.Body>
        ) : (
          <Text.Body>
            <Text.H4>
              {gift_subscription.gifted_subscription_cycles} month subscription
            </Text.H4>
            <em>to {school.name}</em>
          </Text.Body>
        )}

        <Code>{gift_subscription.gift_code}</Code>

        {gift_subscription.gift_message && (
          <Text.Body>"{gift_subscription.gift_message}"</Text.Body>
        )}
      </div>

      <Form
        onSubmit={() =>
          redeemGiftSubscription({
            gift_subscription,
            school_subscription_plan_id:
              gift_subscription.school_subscription_plan.id
          })
        }
      >
        <Submit
          component={SubmitButton}
          styleType="secondary"
          submitting={giftSubscriptionRedeeming}
        >
          Redeem Gift
        </Submit>
      </Form>
    </Container>
  ) : null
}

RedeemSchoolGiftSubscriptionGift.displayName =
  "RedeemSchoolGiftSubscriptionGift"

export default RedeemSchoolGiftSubscriptionGift
