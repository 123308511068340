import Card from "@pathwright/ui/src/components/card/Card"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import React from "react"
import MentorGroups from "./MentorGroups"

const MentorGroupsCard = ({ card }) => {
  const { t } = useTranslate()

  return (
    <Card className="MentorGroupsCard" card={card} title={t("Manage Groups")}>
      <MentorGroups parentId={null} />
    </Card>
  )
}

MentorGroupsCard.displayName = "MentorGroupsCard"

export default MentorGroupsCard
