import React from "react"
import styled from "styled-components"
import BlankImage from "@pathwright/ui/src/components/blank/BlankImage"

const StyledContainer = styled.div`
	width: 100%;
	padding: 0 20px;
`

const StyledDescription = styled.p`
	width: 100%;
	font-family: "Kalam";
`

const PlaceBlank = ({ image, description }) => {
  return (
    <StyledContainer>
      {image && <BlankImage style={{ height: 100 }} image={image} inverted />}
      <StyledDescription>
        {description}
      </StyledDescription>
    </StyledContainer>
  )
}

PlaceBlank.displayName = "PlaceBlank"

export default PlaceBlank
