
        
    const runtime = require("../../../../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
            const __nunjucks_template_import__filter_t = require("../../../nunjucks/t.js");
            __nunjucks_module_dependencies__.filters['t'] = {
                module: __nunjucks_template_import__filter_t && __nunjucks_template_import__filter_t.default || __nunjucks_template_import__filter_t,
                async: false
            };
            const __nunjucks_template_import__filter_date = require("../../../nunjucks/date.js");
            __nunjucks_module_dependencies__.filters['date'] = {
                module: __nunjucks_template_import__filter_date && __nunjucks_template_import__filter_date.default || __nunjucks_template_import__filter_date,
                async: false
            };
            const __nunjucks_template_import__filter_date_from_now = require("../../../nunjucks/date_from_now.js");
            __nunjucks_module_dependencies__.filters['date_from_now'] = {
                module: __nunjucks_template_import__filter_date_from_now && __nunjucks_template_import__filter_date_from_now.default || __nunjucks_template_import__filter_date_from_now,
                async: false
            };
            const __nunjucks_template_import__filter_pluralize = require("../../../nunjucks/pluralize.js");
            __nunjucks_module_dependencies__.filters['pluralize'] = {
                module: __nunjucks_template_import__filter_pluralize && __nunjucks_template_import__filter_pluralize.default || __nunjucks_template_import__filter_pluralize,
                async: false
            };
    
        
            __nunjucks_module_dependencies__.templates["discussion/views/templates/discussion-detail.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<header>\n  <div class=\"discussion-who\">\n    ";
if(!runtime.contextOrFrameLookup(context, frame, "is_discussion_question")) {
output += "\n    <pw-avatar\n      class=\"small\"\n      src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"profile")),"image_thumb"), env.opts.autoescape);
output += "\"\n      user-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"id"), env.opts.autoescape);
output += "\"\n      first-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"first_name"), env.opts.autoescape);
output += "\"\n      last-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"last_name"), env.opts.autoescape);
output += "\"\n    ></pw-avatar>\n    ";
;
}
else {
output += "\n    <pw-avatar class=\"small\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"thumb"), env.opts.autoescape);
output += "\"></pw-avatar>\n    ";
;
}
output += "\n  </div>\n\n  <div class=\"discussion-detail\">\n    <div class=\"discussion-meta\">\n      ";
if(!runtime.contextOrFrameLookup(context, frame, "is_discussion_question")) {
output += "\n      <span class=\"who-name\">\n        <span class=\"discussion-type-label\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "discussion_type_label"), env.opts.autoescape);
output += "</span>\n        ";
output += runtime.suppressValue(env.getFilter("t").call(context, "by","discussion.detail.by"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"abb_name"), env.opts.autoescape);
output += "</span\n      >\n      <span class=\"discussion-date\">\n        <time\n          is=\"time-ago\"\n          datetime=\"";
output += runtime.suppressValue(env.getFilter("date").call(context, runtime.contextOrFrameLookup(context, frame, "created_time"),""), env.opts.autoescape);
output += "\"\n          >";
output += runtime.suppressValue(env.getFilter("date_from_now").call(context, runtime.contextOrFrameLookup(context, frame, "created_time"),runtime.contextOrFrameLookup(context, frame, "created_time")), env.opts.autoescape);
output += "</time\n        >\n      </span>\n      ";
;
}
else {
output += " <span class=\"who-name\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "Discussion Question","discussion.detail.question"), env.opts.autoescape);
output += "</span> ";
;
}
output += "\n      ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "target")),"url")) {
output += "\n      <span class=\"discussion-context\"\n        >";
output += runtime.suppressValue(env.getFilter("t").call(context, "in","discussion.detail.in"), env.opts.autoescape);
output += "\n        <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "target")),"url"), env.opts.autoescape);
output += "\" class=\"discussion-context-link\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "target")),"label"), env.opts.autoescape);
output += "</a></span\n      >\n      ";
;
}
output += "\n    </div>\n    <h1 href=\"#\" class=\"discussion-title discussion-link\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "</h1>\n    <article class=\"discussion-body brand-content content-reset\">\n      ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "body")), env.opts.autoescape);
output += "\n    </article>\n\n    <div class=\"discussion-actions\">\n      ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user_permissions")),"can_vote") || runtime.contextOrFrameLookup(context, frame, "user_has_voted")) {
output += "\n      <a\n        href=\"#\"\n        class=\"disussion-vote\"\n        data-action=\"vote\"\n        rv-hide=\"model:is_discussion_question\"\n        rv-class-voted=\"model:user_has_voted\"\n        rv-title=\"model:vote_prompt\"\n      >\n        <i class=\"pwai-chevronup\"></i>\n        <span class=\"vote-count\">{ model:num_votes }</span>\n        <span rv-unless=\"model:user_has_voted\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "Upvote","discussion.detail.upvote"), env.opts.autoescape);
output += "</span>\n        <span rv-if=\"model:user_has_voted\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "Upvoted","discussion.detail.upvoted"), env.opts.autoescape);
output += "</span>\n      </a>\n      ";
;
}
else {
output += " ";
if(runtime.contextOrFrameLookup(context, frame, "num_votes") > 0) {
output += "\n      <a\n        href=\"#\"\n        class=\"disussion-vote\"\n        rv-hide=\"model:is_discussion_question\"\n        rv-class-voted=\"model:user_has_voted\"\n        rv-title=\"model:vote_prompt\"\n      >\n        <span class=\"vote-count\">{ model:num_votes }</span>\n        ";
output += runtime.suppressValue(env.getFilter("pluralize").call(context, "votes",runtime.contextOrFrameLookup(context, frame, "num_votes")), env.opts.autoescape);
output += "\n      </a>\n      ";
;
}
output += " ";
;
}
output += "\n\n      <!-- TODO: following/unfollow state -->\n      ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user_permissions")),"can_vote") || runtime.contextOrFrameLookup(context, frame, "user_has_subscribed")) {
output += "\n      <a\n        href=\"#\"\n        class=\"disussion-subscribe brand-color\"\n        data-action=\"subscribe\"\n        data-placement=\"top\"\n        rv-class-subscribed=\"model:user_has_subscribed\"\n        title=\"";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.detail.subscribe_title",{"type": runtime.contextOrFrameLookup(context, frame, "discussion_type_label")}), env.opts.autoescape);
output += "\"\n      >\n        <i class=\"pwai-chatfollow\"></i>\n        <span class=\"subscribe\" rv-unless=\"model:user_has_subscribed\"\n          >";
output += runtime.suppressValue(env.getFilter("t").call(context, "Subscribe","discussion.detail.subscribe"), env.opts.autoescape);
output += "</span\n        >\n        <span class=\"subscribe\" rv-if=\"model:user_has_subscribed\"\n          >";
output += runtime.suppressValue(env.getFilter("t").call(context, "Unsubscribe","discussion.detail.unsubscribe"), env.opts.autoescape);
output += "</span\n        >\n      </a>\n      ";
;
}
output += "\n    </div>\n  </div>\n\n  ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user_permissions")),"can_edit")) {
output += "\n  <div class=\"discussion-admin-menu-container\"></div>\n  ";
;
}
output += "\n</header>\n\n<div class=\"discussion-response-region\"></div>\n<div class=\"new-responses-prompt alert alert-info\" style=\"display:none\">\n  New responses available:\n  <a id=\"load-new-responses\" href=\"javascript:;\">load responses.</a>\n</div>\n<div class=\"discussion-responses-region\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "discussion/views/templates/discussion-detail.html",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "discussion/views/templates/discussion-detail.html",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["discussion/views/templates/discussion-detail.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    