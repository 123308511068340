import { merge } from "lodash"
import { getBlockType } from "../block/utils"
import { EDITOR_MODE } from "./constants"
import {
  immutableSplice,
  sortBlocks,
  updateBlocksOrders,
  replaceBlockInState,
  handleMissingBlock,
  createID
} from "../utils/utils"
import { getStyles } from "../block/utils"
import { EditorStateType } from "./editorState"
import {
  BlockType,
  CopiedBlockClipType,
  BlockDataType,
  BlockStyleType
} from "../types"
import { BlockLayoutType, ServerBlockTypeType } from "@pathwright/blocks-core"

type StatePartial = Partial<EditorStateType>

export const startSyncingAction = () => (): StatePartial => {
  return {
    syncing: true
  }
}

export const stopSyncingAction = () => (): StatePartial => {
  return {
    syncing: false
  }
}

export const errorAction = ({
  error
}: {
  error: Error
}) => (): StatePartial => {
  return {
    error,
    publishing: false,
    discarding: false,
    syncing: false
  }
}

export const addBlockAction = ({
  type,
  order,
  data,
  layout,
  style,
  id
}: {
  type: string
  order?: number
  data: BlockDataType
  layout: any
  style?: BlockStyleType
  id: string
}) => (state: EditorStateType): StatePartial => {
  let blocks = [...state.blocks]
  order = order === undefined ? blocks.length + 1 : order
  const newBlock = { type, order, id: id || createID(), data, layout, style }
  blocks.splice(order - 1, 0, newBlock)
  blocks = updateBlocksOrders(blocks)

  return {
    blocks: sortBlocks(blocks),
    lastModified: new Date().getTime()
  }
}

export const clearDeleteBlockAction = () => (): StatePartial => {
  return {
    deleteBlockID: undefined
  }
}

export const deleteBlockAction = ({ id }: { id: string }) => (
  state: EditorStateType
): StatePartial => {
  const block = state.blocks.find(b => b.id === id)

  // Nothing left to do if block doesn't exist
  if (!block) return handleMissingBlock(id, "delete")

  // set the deleteBlockID if it's not already set
  if (id !== state.deleteBlockID) {
    return {
      deleteBlockID: id
    }
  }

  // remove the block and reset orders
  let blocks: BlockType[] = [...state.blocks]
  const index: number = blocks.findIndex(b => b.id === id)
  blocks.splice(index, 1)
  blocks = updateBlocksOrders(blocks)

  const stateChange: StatePartial = {
    blocks: sortBlocks(blocks),
    lastModified: new Date().getTime(),
    deleteBlockID: undefined
  }

  // Clear the clipboard if it's the block we're deleting
  if (state.copiedBlockClip && state.copiedBlockClip.object_id.includes(id)) {
    stateChange.copiedBlockClip = undefined
    stateChange.copiedBlock = undefined
  }

  return stateChange
}

export const updateBlockAction = ({
  id,
  block: blockUpdate
}: {
  id: string
  block: Partial<BlockType>
}) => (state: EditorStateType): StatePartial => {
  const oldBlock = state.blocks.find(b => b.id === id)

  if (!oldBlock) return handleMissingBlock(id)

  const nextBlock = {
    ...oldBlock,
    ...blockUpdate
  }

  return {
    blocks: replaceBlockInState(nextBlock, state),
    lastModified: new Date().getTime()
  }
}

export const updateBlockLayoutAction = ({
  id,
  layout,
  blockTypes
}: {
  id: string
  layout: string
  blockTypes: ServerBlockTypeType[]
}) => (state: EditorStateType): StatePartial => {
  const block: BlockType | undefined = state.blocks.find(
    (b: BlockType) => b.id === id
  )

  if (!block) return handleMissingBlock(id)

  // no need to update the layout if it's the same
  if (layout === block.layout) return {}

  // if the layout of a block is changed,
  // we need to merge in the default layout data
  const blockType: ServerBlockTypeType | undefined = getBlockType(
    block.type,
    blockTypes
  )
  if (!blockType) return {}

  const { layouts } = blockType
  const nextLayout: BlockLayoutType | undefined = Object.values(layouts).find(
    l => l.key === layout
  )
  if (!nextLayout) return {}

  const nextData = merge({}, nextLayout.data, block.data, { layout })

  const nextBlock = {
    ...block,
    layout,
    data: nextData
  }

  return {
    blocks: replaceBlockInState(nextBlock, state),
    lastModified: new Date().getTime()
  }
}

export const updateBlockStyleAction = ({
  id,
  style
}: {
  id: string
  style: BlockStyleType
}) => (state: EditorStateType): StatePartial => {
  const block: BlockType | undefined = state.blocks.find(
    block => block.id === id
  )

  if (!block) return handleMissingBlock(id)

  const nextStyle = getStyles(block, style)

  const nextBlock = {
    ...block,
    style: nextStyle
  }

  return {
    blocks: replaceBlockInState(nextBlock, state),
    lastModified: new Date().getTime()
  }
}

export const setCopiedBlockAction = ({
  copiedBlockClip
}: {
  copiedBlockClip: CopiedBlockClipType | undefined
}) => (): StatePartial => {
  return {
    copiedBlockClip
  }
}

export const clearCopiedBlockAction = () => (): StatePartial => {
  return {
    copiedBlockClip: undefined,
    copiedBlock: undefined
  }
}

export const pasteBlockAction = ({
  index
}: {
  index: number
}) => (): StatePartial => {
  return {
    syncing: true,
    pastingBlockIndex: index
  }
}

export const syncedPasteBlockAction = ({
  copiedBlock
}: {
  copiedBlock: BlockType
}) => (state: EditorStateType): StatePartial => {
  const order =
    state.pastingBlockIndex !== undefined
      ? state.pastingBlockIndex + 1
      : copiedBlock.order || state.blocks.length + 1

  const nextState = {
    ...addBlockAction({
      ...copiedBlock,
      order
    })(state),
    copiedBlockClip: undefined,
    pastingBlockIndex: undefined
  }

  return {
    ...nextState,
    syncing: false
  }
}

export const syncedAddBlockAction = (newBlock: BlockType) => (
  state: EditorStateType
): StatePartial => {
  const optimisticBlock: BlockType | undefined = state.blocks.find(
    b => b.id === newBlock.id
  )

  if (!optimisticBlock) {
    return handleMissingBlock(newBlock.id, "add", { syncing: false })
  }

  const mergedBlock = {
    ...optimisticBlock,
    ...newBlock
  }

  return {
    blocks: replaceBlockInState(mergedBlock, state),
    syncing: false
  }
}

export const syncedUpdateBlockAction = (block: BlockType) => (
  state: EditorStateType
): StatePartial => {
  const oldBlock: BlockType | undefined = state.blocks.find(
    b => b.id === block.id
  )

  if (!oldBlock) {
    return handleMissingBlock(block.id, "update", { syncing: false })
  }

  const mergedBlock = {
    ...oldBlock,
    ...block
  }

  return {
    blocks: replaceBlockInState(mergedBlock, state),
    syncing: false
  }
}

export const syncedBlocksAction = (result: any) => (): StatePartial => {
  return {
    syncing: false
  }
}

const moveBlock = (
  state: EditorStateType,
  id: string,
  oldIndex: number,
  newIndex: number
): StatePartial => {
  if (oldIndex === newIndex) return {}

  const block: BlockType | undefined = state.blocks.find(b => b.id === id)

  if (!block) return handleMissingBlock(id, "move")

  const blocks = [...state.blocks].filter(b => b.id !== block.id)
  const order = newIndex + 1

  const sliced: BlockType[] = immutableSplice(blocks, newIndex, 0, {
    ...block,
    order
  })

  return {
    blocks: updateBlocksOrders(sliced),
    lastModified: new Date().getTime()
  }
}

export const moveBlockUpAction = ({
  oldIndex,
  id
}: {
  oldIndex: number
  id: string
}) => (state: EditorStateType): StatePartial => {
  const newIndex = Math.max(0, oldIndex - 1)

  return moveBlock(state, id, oldIndex, newIndex)
}

export const moveBlockDownAction = ({
  oldIndex,
  id
}: {
  oldIndex: number
  id: string
}) => (state: EditorStateType): StatePartial => {
  const newIndex = Math.min(state.blocks.length - 1, oldIndex + 1)

  return moveBlock(state, id, oldIndex, newIndex)
}

export const setModeAction = () => (state: EditorStateType): StatePartial => {
  const mode =
    state.mode === EDITOR_MODE.EDIT ? EDITOR_MODE.VIEW : EDITOR_MODE.EDIT

  return {
    mode
  }
}

export const startPublishAction = () => (): StatePartial => {
  return {
    publishing: true
  }
}

export const syncedPublishAction = ({
  lastPublishedDateTime,
  lastModifiedDateTime
}) => (): StatePartial => {
  return {
    lastPublished: lastPublishedDateTime,
    lastModified: lastModifiedDateTime,
    publishing: false
  }
}

export const startDiscardDraftAction = () => (): StatePartial => {
  return {
    discarding: true
  }
}

export const syncedDiscardDraftAction = ({
  lastPublishedDateTime,
  lastModifiedDateTime,
  blocks
}) => (): StatePartial => {
  return {
    lastPublished: lastPublishedDateTime,
    lastModified: lastModifiedDateTime,
    blocks,
    discarding: false
  }
}
