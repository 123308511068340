import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import View from "@pathwright/ui/src/components/ui/View"
import CategoriesQuery from "@pathwright/web/src/modules/category/graphql/CategoriesQuery"
import React from "react"
import { Field } from "react-connect-form-forked"
import SelectInput from "../lib/SelectInput/SelectInput"
import { withLibraryResources } from "../library/graphql/LibraryResourcesQuery"
import { withSearch } from "../utils/search"
import GenerateCouponCodes from "./GenerateCouponCodes"

const LibraryResourcesQuery = withSearch(
  withLibraryResources(({ children, debouncedSearch, ...rest }) => {
    return children({ ...rest, search: debouncedSearch }) || null
  })
)

const GenerateCouponCodesForResources = ({ card }) => (
  <GenerateCouponCodes card={card} title="Generate Coupon Codes For Resources">
    {formState => (
      <View.Secondary>
        <CardBlock>
          <Fieldset
            title="All resources"
            header="Apply coupon(s) to all resources."
          >
            <Field
              name="allResources"
              type="checkbox"
              component={Checkbox}
              hideStatus
            ></Field>
          </Fieldset>
        </CardBlock>
        {!formState.value.allResources && (
          <CardBlock>
            <LibraryResourcesQuery first={50}>
              {({ resources, loading, hasMore, loadMore, onSearch }) => (
                <Fieldset
                  title="Select resources"
                  header="Apply coupon(s) to selected resources."
                >
                  <Field
                    name="resources"
                    type="text"
                    component={SelectInput}
                    options={resources}
                    isLoading={loading}
                    onMenuScrollToBottom={() => hasMore && loadMore()}
                    placeholder="Select resources..."
                    noResultsText="No resources found"
                    labelKey="name"
                    valueKey="id"
                    searchable
                    topLabel
                    multi
                    clearable
                    onInputChange={val => {
                      onSearch && onSearch(val, hasMore !== false)
                      // return val for react-select to handle
                      return val
                    }}
                    onFocus={() => onSearch && onSearch("", true)}
                    required
                  />
                </Fieldset>
              )}
            </LibraryResourcesQuery>
          </CardBlock>
        )}
        {!formState.value.allResources && (
          <CardBlock>
            <CategoriesQuery first={50} manage>
              {({ categories, loading, hasMore, loadMore, onSearch }) => (
                <Fieldset
                  title="Select categories"
                  header="Apply coupon(s) to selected resource categories."
                >
                  <Field
                    name="categories"
                    id="course_categories"
                    component={SelectInput}
                    isLoading={loading}
                    options={categories}
                    onMenuScrollToBottom={() => hasMore && loadMore()}
                    placeholder="Select resource categories..."
                    noResultsText="No categories found"
                    labelKey="name"
                    valueKey="id"
                    searchable
                    topLabel
                    multi
                    onInputChange={val => {
                      onSearch && onSearch(val, hasMore !== false)
                      // return val for react-select to handle
                      return val
                    }}
                    onFocus={() => onSearch && onSearch("", true)}
                  />
                </Fieldset>
              )}
            </CategoriesQuery>
          </CardBlock>
        )}
      </View.Secondary>
    )}
  </GenerateCouponCodes>
)

GenerateCouponCodesForResources.displayName = "GenerateCouponCodesForResources"

export default GenerateCouponCodesForResources
