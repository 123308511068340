import Home from "home/components/HomeOrLibraryBrowse"
import { invitationCardRoute } from "invitation/list/routes"
import { createCardRoutes } from "lib/core/routing/card-routing"
import { loadResource } from "path/middleware"
import Invite from "./components/Invite"
import inviteStore from "./store/invite-store"

const enterInvites = route => {
  const { role, method } = route.location.query
  const { link, offering } = route.match.params
  inviteStore.action.initialize()
  if (offering) {
    loadResource(route).then(() => {
      const { resource, offering } = window.App.getStore("resource").getState()
      inviteStore.action.setContext({ resource, offering, method, role })
    })
  } else {
    inviteStore.action.setContext({ method, role })
  }
}

const leaveInvites = () => {
  inviteStore.action.reset()
}

export const inviteCardRoute = {
  invite: {
    component: Invite,
    middleware: enterInvites,
    leaveMiddleware: leaveInvites,
    childCards: invitationCardRoute
  }
}

export const schoolRoute = createCardRoutes("/manage/", inviteCardRoute, Home)
