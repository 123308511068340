import Button from "@pathwright/ui/src/components/button/Button"
import Course from "@pathwright/ui/src/components/library/Course"
import StatusBar from "@pathwright/ui/src/components/library/StatusBar"
import Screensize from "@pathwright/ui/src/components/ui/Screensize"
import Text from "@pathwright/ui/src/components/ui/Text"
import get from "lodash/get"
import range from "lodash/range"
import React from "react"
import styled from "styled-components"
import BrowseBlock from "../BrowseBlock"

const itemsPerRow = Screensize.select({
  xl: 5,
  lg: 4,
  md: 3,
  sm: 2
})

const Grid = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(${itemsPerRow}, 1fr);
  grid-gap: 1em;
  list-style-type: none;
`

const Item = styled.li`
  min-height: 0;
  min-width: 0;
`

function mapToRows(items, screensize, clip) {
  if (!clip) return items
  const rowsize = itemsPerRow({ screensize })
  if (items.length < rowsize) return items
  const slice = items.length - (items.length % rowsize)
  return items.slice(0, slice)
}

export const getAuthorLine = resource => {
  let authorLine = []
  get(resource, "authors", [])
    .slice(0, 2)
    .forEach(a => {
      authorLine.push(a.full_name)
    })
  if (resource.total_authors_count > 2) {
    authorLine.push(`+${resource.total_authors_count - 2}`)
  }
  return authorLine.join(", ")
}

function BrowseGrid({
  count,
  heading,
  loading,
  resources,
  hasMore,
  loadMore,
  browseTo,
  settingsTo,
  clip
}) {
  return (
    <Screensize.Consumer>
      {screensize => (
        <BrowseBlock
          heading={heading}
          action={
            hasMore && (
              <Button
                styleType="blank"
                style={{ padding: 0 }}
                onClick={loadMore}
              >
                See More
              </Button>
            )
          }
        >
          <Grid screensize={screensize}>
            {loading
              ? mapToRows(range(count), screensize, true).map(n => (
                  <Item key={n}>
                    <Course loading />
                  </Item>
                ))
              : resources
              ? mapToRows(resources, screensize, clip).map(resource => (
                  <Item key={resource.id}>
                    <Course
                      course={resource}
                      meta={getAuthorLine(resource)}
                      to={browseTo(resource)}
                      toSettings={settingsTo(resource)}
                    >
                      {get(resource, "school_licenses.length") > 0 && (
                        <StatusBar>In your library</StatusBar>
                      )}
                      {get(resource, "license_offerings.total") === 0 && (
                        <Text.Meta>Coming Soon</Text.Meta>
                      )}
                    </Course>
                  </Item>
                ))
              : null}
          </Grid>
        </BrowseBlock>
      )}
    </Screensize.Consumer>
  )
}

BrowseGrid.displayName = "BrowseGrid"

BrowseGrid.defaultProps = {
  clip: true,
  browseTo: () => null,
  settingsTo: () => null
}

export default BrowseGrid
