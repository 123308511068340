import { validators } from 'react-connect-form-forked'

export const isMinLetters = min => {
  return (value, values) => {
    if (value && value.length < 2) {
      return `At least ${min} letters, please`
    }
  }
}

export const isEmail = (value, values) => {
  if (validators.isEmail(value, values)) {
    return 'Please enter a valid email address (e.g., buzz@space.com)'
  }
}

export const isPassword = (value, values) => {
  if (value && value.length < 6) {
    return 'Please enter a password with 6+ characters'
  }
}
