import PropTypes from 'prop-types';
import React from "react"
const Row = function({ children, align, gutter, className }) {
  const getClass = function() {
    let cn = "Row"
    if (className) {
      cn += ` ${className}`
    }
    return cn
  }

  const getStyle = function() {
    const style = {
      textAlign: align,
      marginLeft: -gutter,
      marginRight: -gutter,
    }
    return style
  }

  return (
    <ul className={getClass()} style={getStyle()}>
      {React.Children.map(children, function(child, index) {
        if (child != null) {
          return React.cloneElement(child, {
            key: `Col${index}`,
            gutter,
          })
        }
      })}
    </ul>
  )
}

Row.displayName = "Row"
Row.propTypes = {
  // width of padding between columns
  gutter: PropTypes.number,
  // align trailing columns
  align: PropTypes.oneOf(["left", "center", "right"]),
}
Row.defaultProps = {
  align: "left",
  gutter: 0,
}

export default Row
