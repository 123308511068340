import React from "react"
import { ConnectStores } from "lib/core/store/"
import CategoryForm from "./CategoryForm"

const mapStateToProps = function(state, ownProps) {
  const {
    category,
    category_selected_for_deletion,
  } = state.categoryAdminStore.state
  let {
    saveCategory,
    deleteCategory,
    selectCategoryForDeletion,
    unselectCategoryForDeletion,
  } = state.categoryAdminStore.action

  const isDeleting = category_selected_for_deletion

  const canDelete = (category != null ? category.id : undefined) ? true : false

  const fieldValues = {
    categoryname: category != null ? category.name : undefined,
    description: category != null ? category.description : undefined,
  }

  const onSubmit = function(fieldsData, resetForm) {
    const categoryData = {
      name: fieldsData.categoryname.value,
      description: fieldsData.description.value || "",
    }
    return saveCategory(categoryData).promise.then(function() {
      window.apolloClientStoreBridge.refetchCategories({
        school_id: window.App.school.id,
        first: 50,
      })
      return ownProps.closeCard()
    })
  }

  const handleDelete = function(id) {
    let finalize
    return deleteCategory(id, (finalize = true)).promise.then(function() {
      window.apolloClientStoreBridge.refetchCategories({
        school_id: window.App.school.id,
        first: 50,
      })
      return ownProps.closeCard()
    })
  }

  selectCategoryForDeletion = function(id) {
    let finalize
    return deleteCategory(id, (finalize = false))
  }

  unselectCategoryForDeletion = function(id) {
    let cancel, finalize
    return deleteCategory(id, (finalize = false), (cancel = true))
  }

  return _.assign(
    {},
    {
      category,
      fieldValues,
      onSubmit,
      canDelete,
      isDeleting,
      handleDelete,
      selectCategoryForDeletion,
      unselectCategoryForDeletion,
    },
    ownProps
  )
}

const stores = [
  {
    store: "categoryAdmin",
    watch: ["category", "category_selected_for_deletion"],
  },
]

export default ConnectStores(CategoryForm, stores, {}, mapStateToProps)
