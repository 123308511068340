// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let HighlightBehavior
export default (HighlightBehavior = (function() {
  HighlightBehavior = class HighlightBehavior extends require("lib/static-shim").default(
    Marionette.Behavior
  ) {
    static initClass() {
      ;`\
Adds a highlight class to a view if an attribute is set on the view's model.\
`

      this.prototype.defaults = {
        highlightAttribute: "highlight",
        highlightDuration: 2500
      }
    }

    onRender() {
      this.highlight()
      return this.listenTo(
        this.view.model,
        `change:${this.options.highlightAttribute}`,
        this.highlight
      )
    }

    highlight() {
      if (this.view.model.get(this.options.highlightAttribute)) {
        this.$el.toggleClass("highlighted")
        return _.delay(() => {
          this.$el.removeClass("highlighted")
          return this.view.model.set("highlighted", false)
        }, this.options.highlightDuration)
      }
    }
  }
  HighlightBehavior.initClass()
  return HighlightBehavior
})())
