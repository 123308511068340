import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import useTagPermissions from "../../tag/useTagPermissions"
import useTags from "../../tag/useTags"
import { getTagLinksContext, getTagsContext } from "../../tag/utils"

// Based on provided context, return expected, translated labels.
const useDiscussionTagContextLabels = context => {
  const { t } = useTranslate()

  const tagsContext = getTagsContext(context)
  const tagLinksContext = getTagLinksContext(context)
  const { tags } = useTags({ context })
  const tagPermissions = useTagPermissions({ context })

  // These labels apply in every context.
  const baseLabels = {
    selectLabel: tagPermissions.canAdd
      ? tags.all.length
        ? t("Add or create a topic...")
        : t("Create a topic...")
      : t("Add a topic..."),
    tagFormLabels: {
      name: t("Topics"),
      prompt: t("Add a prompt for discussion (optional)")
    }
  }

  if (tagLinksContext) {
    return {
      ...baseLabels,
      // Cannot manage tags at the discussion level.
      manageTitle: null,
      // Prompt user to manage tags at the resourcce level.
      managePrompt: t("Manage topics")
    }
  }

  if (tagsContext) {
    return {
      ...baseLabels,
      // Managing tags at the resource level.
      manageTitle: t("Topics"),
      // Prompt user to manage tags at the school level.
      managePrompt: t("Manage all topics")
    }
  }

  return {
    ...baseLabels,
    // Managing tags at the school level.
    manageTitle: t("Topics"),
    // No higher level at which to manage tags.
    managePrompt: null
  }
}

export default useDiscussionTagContextLabels
