import capitalize from "lodash/capitalize"
import get from "lodash/get"
import uniq from "lodash/uniq"
import { getStepVerb } from "../utils"
import {
  FORCED_EXCLUDE_USER_REQUESTED,
  FORCED_INCLUDE_USER_REQUESTED
} from "./constants"

export const getCohortLabel = (cohort, t) =>
  get(cohort, "name") || t("Source Path")

// get tooltip for change
export const getChangeTooltip = (change, changes, translator) => {
  const getUpdateLabel = change => {
    const getChangedLabels = change => {
      const getLabel = changeKey => {
        switch (changeKey) {
          case "name":
            return translator("title")
          case "description":
            return translator("description")
          case "is_required":
            return translator("required setting")
          case "allow_resets":
            return translator("retry setting")
          case "show_grading_feedback":
            return translator("answer display setting")
          case "minimum_passing_score":
          case "userpoints_value":
            return translator("points")
          case "grading_type":
            return translator("completion settings")
          case "is_previewable":
            return translator("preview setting")
          case "lock_password":
            return translator("password setting")
          default:
            return ""
        }
      }

      if (change.change_details.changed) {
        return Object.keys(change.change_details.changed.changed_attributes)
          .map(key => getLabel(key))
          .filter(label => label)
      }

      return []
    }

    const getChangedGradingTypeLabels = change => {
      if (change.change_details.changed_attributes_grading_type) {
        return [translator("completion settings")]
      }
      return []
    }

    const getChangedPointsLabels = change => {
      if (change.change_details.changed_points) {
        return [translator("points")]
      }
      return []
    }

    // make sure we have no duplicates by using uniq
    let changesListLabel = uniq([
      ...getChangedLabels(change),
      ...getChangedGradingTypeLabels(change),
      ...getChangedPointsLabels(change)
    ]).join(", ")

    // add space if list is not empty
    changesListLabel += changesListLabel ? " " : ""
    // append "updated"
    changesListLabel += translator("updated")
    // capitalize first letter
    changesListLabel = capitalize(changesListLabel)

    return changesListLabel
  }

  const childItemCounts = changes.filter(
    c => c.parent_source_id === change.item_source_id
  ).length

  if (change.change_details.deleted) {
    if (change.item_type === "lesson") {
      if (childItemCounts) {
        return translator("Lesson with {{ count }} step deleted", {
          defaultValue_plural: "Lesson with {{ count }} steps deleted",
          count: childItemCounts
        })
      }
      // catch edge case where no steps exist on lesson (pre simple-sync)
      return translator("Lesson deleted")
    }

    if (change.item_type === "step") {
      return translator("Step deleted from {{ lessonName }}", {
        lessonName: change.parent_name
      })
    }
  }

  if (change.change_details.created) {
    if (change.item_type === "lesson") {
      if (childItemCounts) {
        return translator("Lesson created with {{ count }} step", {
          defaultValue_plural: "Lesson created with {{ count }} steps",
          count: childItemCounts
        })
      }
      // catch edge case where no steps exist on lesson (pre simple-sync)
      return translator("Lesson created")
    }

    if (change.item_type === "step") {
      return translator("Step created in {{ lessonName }}", {
        lessonName: change.parent_name
      })
    }
  }

  return getUpdateLabel(change)
}

export const getChangeLabel = (change, translator) => {
  switch (change.item_type) {
    case "step":
      return `${getStepVerb(change.assignment_type, translator)}: ${
        change.item_name
      }`
    default:
      return change.item_name
  }
}

// change is syncable if the change is not forced or forced by user request
export const isSyncableChange = change =>
  !change.forced ||
  change.forced === "include" ||
  change.forced_reasons.includes(FORCED_INCLUDE_USER_REQUESTED) ||
  change.forced_reasons.includes(FORCED_EXCLUDE_USER_REQUESTED)

export const getSyncChangesCount = syncPlan => {
  const syncable = get(syncPlan, "groupedChanges.syncable", [])
  // only the included syncable changes count towards the total number of sync changes
  const syncableCount = syncable.filter(
    item => !item.forced || item.forced === "include"
  ).length

  let count = syncableCount

  // counting cumulative reorders as 1 change
  if (get(syncPlan, "willReorder")) {
    count++
  }

  return count
}

// massage items data
export const groupSyncChanges = syncPlanChanges => {
  const getChildItems = item =>
    syncPlanChanges.filter(i => i.parent_source_id === item.item_source_id)

  const getParentItem = item =>
    syncPlanChanges.find(i => i.item_source_id === item.parent_source_id)

  const getIgnoredChildItem = item => {
    // ignoring changes on child item changes when matching certain change types
    // for example, ignore deleted or created child items whose lesson is also deleted or created
    const ignoredChildItemChangeTypes = ["deleted", "created"]

    return ignoredChildItemChangeTypes.find(
      ignoredChangeType =>
        // matching change type
        item.change_details[ignoredChangeType] &&
        // and has parent with matching change type
        getParentItem(item) &&
        getParentItem(item).change_details[ignoredChangeType]
    )
  }

  const getIgnoredItem = item => {
    // ignoring changes on item if item only has one of these changes
    const ignoredItemChanges = ["reorder"]

    return ignoredItemChanges.find(
      ignoredChangeType =>
        item.change_details[ignoredChangeType] &&
        Object.keys(item.change_details).length === 1
    )
  }

  const getLessonNoSteps = item =>
    item.forced === "exclude" &&
    item.forced_reasons.includes("exclude_lesson_empty")

  const getStepNoContent = item =>
    item.forced === "exclude" &&
    item.forced_reasons.includes("exclude_step_no_content")

  // a lesson has no valid steps if the lesson is "empty" and has steps that lack content
  // Thought: should the simple fact that a step exists for an "empty" lesson be proof of the step beinb invalid?
  const getLessonNoValidSteps = item =>
    getLessonNoSteps(item) && getChildItems(item).find(getStepNoContent)

  return syncPlanChanges.reduce(
    (map, item) => {
      const mergeItem = key => ({
        ...map,
        [key]: [...map[key], item]
      })

      // group all ignored items
      if (getIgnoredChildItem(item) || getIgnoredItem(item)) {
        return mergeItem("ignored")
      }

      // group all lessons lacking valid steps
      if (getLessonNoValidSteps(item)) {
        return mergeItem("lessonsNoValidSteps")
      }

      // group all lessons lacking steps
      if (getLessonNoSteps(item)) {
        return mergeItem("lessonsNoSteps")
      }

      // group all steps needing content
      if (getStepNoContent(item)) {
        return mergeItem("stepsNoContent")
      }

      return mergeItem("syncable")
    },
    {
      // NOTE: syncable not quite clear as there could potentially be
      // forced excluded changes in this list, though it seems the current
      // pattern is to handle forced excluded separately (i.e. stepsLackingContent and lessonsLackingSteps)
      syncable: [],
      ignored: [],
      stepsNoContent: [],
      lessonsNoSteps: [],
      lessonsNoValidSteps: []
    }
  )
}

// Prevent syncing from the source cohort when there are no cohorts
// AND no license offerings (allowing syncing even when there are no
// cohorts yet the resource is licened – either via school licensing
// or group licensing).
export const syncFromSourceCohortPrevented = cohort =>
  Boolean(
    cohort &&
      cohort.is_master &&
      cohort.resource.cohorts.total === 0 &&
      cohort.resource.license_offerings.total === 0
  )
