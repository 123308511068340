import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import { parseRgba } from "@pathwright/ui/src/components/utils/colors"
import ManageAccountCustomIcon from "@pathwright/web/src/modules/account/manage/ManageAccountCustomIcon"
import ManageAccountIcon from "@pathwright/web/src/modules/account/manage/ManageAccountIcon"
import ManageAccountLogo from "@pathwright/web/src/modules/account/manage/ManageAccountLogo"
import ManageBackground from "@pathwright/web/src/modules/account/manage/ManageBackground"
import ManageBrand from "@pathwright/web/src/modules/account/manage/ManageBrand"
import ManageCustomCSS from "@pathwright/web/src/modules/account/manage/ManageCustomCSS"
import { observer } from "mobx-react"
import React from "react"
import store from "school/manage/school-manage-store"
const rgbaToString = rgba => rgba.replace(/[^0-9.,]/g, "") // "rgba(0,0,0,0.1)" => "0,0,0,0.1"

const ManageAccountIconContainer = observer(({ card }) => (
  <ManageAccountIcon
    card={card}
    icon={store.details.image}
    logo={store.details.logo_image}
  />
))

const ManageAccountCustomIconContainer = observer(({ card }) => (
  <ManageAccountCustomIcon
    card={card}
    initialValue={{ icon: store.details.image }}
    onSubmit={form => store.action.saveDetails({ image: form.icon })}
  />
))

const ManageAccountLogoContainer = observer(({ card }) => (
  <ManageAccountLogo
    card={card}
    initialValue={{ logo: store.details.logo_image }}
    onSubmit={form => store.action.saveDetails({ logo_image: form.logo || "" })}
  />
))

const ManageBackgroundContainer = observer(({ card }) => (
  <ManageBackground
    card={card}
    initialValue={{
      image: store.theme.background_image_raw,
      blur: store.theme.background_type === 5,
      overlay: parseRgba(store.theme.background_overlay)
    }}
    onSubmit={form => {
      return store.action.saveTheme({
        background_image: form.image,
        background_type: form.blur ? 5 : 1,
        background_overlay: form.overlay ? rgbaToString(form.overlay) : ""
      })
    }}
  />
))

const ManageBrandContainer = observer(({ card, color }) => (
  <ManageBrand
    card={card}
    initialValue={{ color }}
    onSubmit={form =>
      store.action.saveTheme({
        primary_color: form.color
      })
    }
  />
))

const ManageCustomCSSContainer = observer(({ card }) => (
  <ManageCustomCSS
    card={card}
    initialValue={{ css: store.theme.custom_css }}
    theme={store.theme}
    onSubmit={form => store.action.saveTheme({ custom_css: form.css })}
  />
))

const loadBrand = () => {
  store.action.loadDetails()
  store.action.loadTheme()
}

export default {
  brand: {
    component: props => {
      const theme = useUIThemeContext()
      return <ManageBrandContainer {...props} color={theme.primaryBrandColor} />
    },
    middleware: loadBrand,
    childCards: {
      icon: {
        component: ManageAccountIconContainer,
        childCards: {
          logo: {
            component: ManageAccountLogoContainer
          },
          "custom-icon": {
            component: ManageAccountCustomIconContainer
          }
        }
      },
      background: {
        component: ManageBackgroundContainer
      },
      css: {
        component: ManageCustomCSSContainer
      }
    }
  }
}
