import defaults from "lodash/defaults"
import { useCallback, useEffect, useRef, useState } from "react"
import useDidMountEffect from "./useDidMountEffect"

function useLocalStorage(key, initialValue, options = {}) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once

  options = defaults(options, {
    storageType: "local",
    sync: false
  })

  const sourceRef = useRef()

  const storage = window[`${options.storageType}Storage`]

  if (!storage) {
    throw Error(
      `Cannot find storage on window for storage type: "${options.storageType}"`
    )
  }

  const getInitialValue = () => {
    const handleInitialValue = result => {
      if (initialValue instanceof Function) {
        return initialValue(result)
      }
      // Ensure we return result even if it's falsey, unless it's null which
      // indicates the key doesn't exist.
      return result !== null ? result : initialValue
    }

    if (!key) {
      return handleInitialValue()
    }

    try {
      // Get from local storage by key
      const item = storage.getItem(key)
      // Parse stored json or if none return initialValue
      return handleInitialValue(item && JSON.parse(item))
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return handleInitialValue()
    }
  }

  const [storedValue, setStoredValue] = useState(getInitialValue)

  // For tracking current storedValue.
  const storedValueRef = useRef()
  storedValueRef.current = storedValue

  useDidMountEffect(() => {
    setStoredValue(getInitialValue())
  }, [key])

  const setValueToStore = useCallback(valueToStore => {
    try {
      // Calling setValue with no argument provides simple mechanic for
      // removing item from localStorage.
      if (typeof valueToStore === "undefined") {
        storage.removeItem(key)
      } else {
        // Save to local storage
        storage.setItem(key, JSON.stringify(valueToStore))
      }
      // Save state
      setStoredValue(valueToStore)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }, [])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    sourceRef.current = "local"
    // Allow value to be a function so we have same API as useState
    const valueToStore =
      value instanceof Function ? value(storedValueRef.current) : value

    setValueToStore(valueToStore)
  }

  // Sync changes in localStorage from other same-domain web pages.
  // Keep in mind that this does not sync changes in localStorage for
  // the same web page making the change.
  useEffect(() => {
    if (options.sync) {
      const handler = e => {
        if (e.key === key) {
          sourceRef.current = "global"
          // e.newValue will be null when the item has been removed
          // from localStorage.
          if (e.newValue === null) {
            setValueToStore()
          } else {
            setValueToStore(JSON.parse(e.newValue))
          }
        }
      }
      // Add and remove listener.
      window.addEventListener("storage", handler)
      return () => window.removeEventListener("storage", handler)
    }
  }, [options.sync])

  // Data detainling info about the stored value.
  //   - source: The actor responsible for the current value, one of:
  //     - "local": The change occurred locally, in the current web page.
  //     - "global": The change occurred globally, from a different web page.
  const meta = { source: sourceRef.current }

  return [storedValue, setValue, meta]
}

export const useSessionStorage = (key, initialValue) =>
  useLocalStorage(key, initialValue, { storageType: "session" })

export default useLocalStorage
