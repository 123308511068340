import './InviteFormError.css'
import pluralize from 'pluralize'
import { observer } from 'mobx-react'
import inviteStore from '../../store/invite-store'

const InviteFormError = () => {
	return (
		<div className='InviteFormError'>
			Please fix {pluralize('errors', inviteStore.errors.length, true)} before sending invites.&nbsp;
			<a
				onClick={inviteStore.action.removeErrors} 
				href="javascript:void(0)">
				Remove errors
			</a>
		</div>
	)
}

InviteFormError.displayName = 'InviteFormError'

export default observer(InviteFormError)