import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import styled from "styled-components"
import { sizePropType } from "../utils/props"
import { DANGEROUS_RED, PRIMARY_WHITE } from "../utils/colors"

const StyledIndicator = styled.div`
  display: inline-block;
  border-radius: 100%;
  text-align: center;
`

const Indicator = ({
  size: sizeProp,
  color,
  inverted,
  className,
  children,
  style: styleProp = {}
}) => {
  const size = sizePropType._serialize(sizeProp)

  const style = {
    height: size.style,
    width: size.style,
    minHeight: size.style,
    minWidth: size.style,
    color: inverted ? color : PRIMARY_WHITE,
    backgroundColor: inverted ? PRIMARY_WHITE : color,
    ...styleProp
  }

  return (
    <StyledIndicator
      className={classnames("Indicator", className)}
      style={style}
    >
      <span style={{ fontSize: `${size.number / 2}${size.unit}` }}>
        {children}
      </span>
    </StyledIndicator>
  )
}

Indicator.displayName = "Indicator"

Indicator.propTypes = {
  size: sizePropType,
  color: PropTypes.string,
  inverted: PropTypes.bool
}

Indicator.defaultProps = {
  size: "1em",
  color: DANGEROUS_RED
}

export default Indicator
