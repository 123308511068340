import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import REST_API_GET_QUERY from "./rest-api-get-query"

const useRestAPIGetQuery = endpoint =>
  useQuery(REST_API_GET_QUERY, {
    variables: {
      endpoint
    }
  })

export default useRestAPIGetQuery

// import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
// import REST_API_GET_QUERY from "./rest-api-get-query"

// const useRestAPIGetQuery = async endpoint => {
//   const result = await useQuery(REST_API_GET_QUERY, {
//     variables: {
//       endpoint
//     }
//   })

//   return result.restAPIGet
// }

// export default useRestAPIGetQuery
