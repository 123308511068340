import styled from "styled-components"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { media } from "@pathwright/ui/src/components/utils/styles"

export const AudioButtonUI = styled(IconButton)`
  width: 30px;
  height: 30px;
  margin: 0 15px;
  ${media.max.tablet`
    margin: 0;
  `};

  i.Pathicon {
    font-size: 2em;
    color: gray;
  }
`

export const TrackTitle = styled.div`
  font-weight: bold;
  text-align: center;
  margin: 15px 0px 0px;
  color: #282e32;
`

export const TrackTimeUI = styled.div`
  color: #747474;
  font-size: 0.9em;
`

export const SimpleSkin = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;

  audio:not([controls]) {
    display: none !important;
  }
`

export const SpeedSelectorUI = styled.div``

export const SkipButtonUI = styled(AudioButtonUI)`
  margin: 0px 15px;
  i.Pathicon {
    font-size: 24px;
  }
  &:after {
    content: "15";
    color: gray;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 2px;
    line-height: 30px;
    font-size: 10px;
    font-weight: 600;
  }
`

export const ControlsUI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;

  ${TrackTitle},
  ${TrackTimeUI},
  ${SpeedSelectorUI} button,
  ${AudioButtonUI} i.Pathicon,
  ${SkipButtonUI} .Button:after {
    color: ${p => (p.customStyles ? p.customStyles.styleAttributes.color : "")};
  }
`

export const SpeedButton = styled.button`
  color: rgb(102, 102, 102);
  background: none;
  border: none;
  outline: none;
  width: 50px;
  cursor: pointer;
`

export const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 20px;
  &.control-players {
    max-width: 500px;
    justify-content: center;

    ${media.max.tablet`
      width: auto;
    `}
  }

  ${media.max.tablet`
    flex-direction: ${props => (props.rowOnMobile ? "row" : "column")};
  `}
`

const ControlsSection = styled.div`
  display: flex;
  flex-basis: 33%;
  justify-content: center;
  align-items: center;

  ${media.max.tablet`
    flex-basis: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  `}
`

export const ControlsLeft = styled(ControlsSection)``

export const ControlsRight = styled(ControlsSection)``

export const ControlsCenter = styled(ControlsSection)`
  margin: 0 15px;
`
