import React from "react"
import { ConnectStores } from "lib/core/store"
import Card from "@pathwright/ui/src/components/card/Card"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import CategoryList from "./CategoryList"

const CategoriesCard = ({
  card,
  baseURL,
  categories,
  categories_loading,
  updateCategoryOrder
}) => (
  <Card
    title="Categories"
    completeAction={{
      to: `${baseURL}new/`,
      children: "New Category"
    }}
    card={card}
  >
    {categories_loading ? (
      <LoadingCircle />
    ) : (categories != null ? (
        categories.length
      ) : (
        undefined
      )) ? (
      <CategoryList
        baseURL={baseURL}
        categories={categories}
        updateCategoryOrder={updateCategoryOrder}
      />
    ) : (
      <BlankSlate
        heading="Your library doesn't have any categories yet."
        body="Use categories to organize your courses into topics, difficulty levels, or any other helpful grouping."
      />
    )}
  </Card>
)

CategoriesCard.displayName = "CategoriesCard"

const mapStateToProps = function(state, ownProps) {
  const { categories, categories_loading } = state.categoryAdminStore.state
  const { updateCategoryOrder } = state.categoryAdminStore.action
  const { url: baseURL } = state.navigationStore.state

  return _.assign(
    {},
    {
      baseURL,
      categories,
      categories_loading,
      updateCategoryOrder
    },
    ownProps
  )
}

const connectStores = [
  { store: "navigation", watch: ["url"] },
  { store: "categoryAdmin", watch: ["categories", "categories_loading"] }
]

export default ConnectStores(CategoriesCard, connectStores, {}, mapStateToProps)
