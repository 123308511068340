import gql from 'graphql-tag'

export default gql`
  fragment Category on Category {
    id
    name
    slug
    order
    description
  }
`
