import "./ActivationLockedFallback.css"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ActivateButton from "./ActivateButton"

const ActivationLockedFallback = props => {
  return (
    <div className="ActivationLockedFallback">
      <Pathicon icon="rocket" />
      <p>
        Ready to start teaching? Just activate your account and you’ll be
        running in minutes.
      </p>
      <ActivateButton styleType="primary" utmSoure="Activation Locked">
        Activate Now
      </ActivateButton>
    </div>
  )
}

ActivationLockedFallback.displayName = "ActivationLockedFallback"

export default ActivationLockedFallback
