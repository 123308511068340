// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let UpdatePaymentModel
export default UpdatePaymentModel = class UpdatePaymentModel extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    if (!this.get("licensor_id")) {
      return this.urlRoot(`/api/private/subscription/payment_details/`)
    } else {
      return this.urlRoot(
        `school-licensing/api/v1/school/${this.get(
          "licensor_id"
        )}/subscription/payment_details/`
      )
    }
  }

  // Default ID to force PUT
  defaults() {
    return {
      id: "foo",
      card_last_4: "????"
    }
  }
}
