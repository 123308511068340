import React from "react"
import get from "lodash/get"
import { useCurrencyFormatter } from "../../currency/CurrencyFormatterProvider"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"

export const getResourcePriceRange = resource => {
  let {
    lowest_public_offering_price: lowest,
    highest_public_offering_price: highest
  } = resource

  // if the resource has licensing enabled, factor in the licensing cost per seat
  if (resource.licensing_enabled) {
    const sortedLowHighLicenseOfferings = resource.license_offerings.sort(
      (loA, loB) => loA.cost_per_seat - loB.cost_per_seat
    )

    const lowestLicenseOfferingPrice = get(
      sortedLowHighLicenseOfferings[0],
      "cost_per_seat",
      null
    )
    const highestLicenseOfferingPrice = get(
      sortedLowHighLicenseOfferings[sortedLowHighLicenseOfferings.length - 1],
      "cost_per_seat",
      null
    )

    const lows = [lowest, lowestLicenseOfferingPrice].filter(
      price => price != null
    )

    const highs = [highest, highestLicenseOfferingPrice].filter(
      price => price != null
    )

    lowest = lows.length ? Math.min(...lows) : null
    highest = highs.length ? Math.max(...highs) : null
  }

  const priceRange = []
  if (lowest != null) {
    priceRange.push(lowest)
  }
  if (highest != null && highest > lowest) {
    priceRange.push(highest)
  }

  return priceRange
}

export const useResourceDisplayPrice = resource => {
  const { t } = useTranslate()
  const { currencyFormatter } = useCurrencyFormatter()
  const priceRange = getResourcePriceRange(resource, currencyFormatter, t)

  // Remove USD only (other currencies spelled out for clarify)
  // if (currencyFormatter.currencyLabel) {
  //   currencyFormatter.currencyLabel.replace(" USD", "")
  // }

  // return empty string if no prices are available
  if (!priceRange.length) return ""

  // change 0 to "free"
  if (priceRange[0] === 0) priceRange[0] = t("resource.free")

  // handle price range if multiple price points
  return priceRange.length === 1
    ? currencyFormatter.format(...priceRange)
    : currencyFormatter.formatRange(...priceRange)
}

// handle massaging results data into expected shape
export const massageAlgoliaLibraryBrowseResults = results => {
  const hits = results[0].hits.map(hit => ({
    ...hit,
    id: hit.resource_id
  }))

  const massageFacets = (facetsName, facetsKey) => {
    if (results[0].facets) {
      const facetKey = `${facetsName}.${facetsKey}`
      const facets = results[0].facets[facetKey]
      if (facets) {
        return Object.entries(facets).map(([name, count]) => ({
          [facetsKey]: name,
          id: name,
          count
        }))
      }
    }

    return []
  }

  const massageAuthors = authors =>
    authors.map(author => ({
      ...author,
      full_name: author.display_name
    }))

  const massageResources = resources =>
    resources.map(resource => ({
      ...resource,
      image: resource.thumb,
      // displayPrice: getPriceLabel(resource, currencyFormatter, t),
      authors: massageAuthors(resource.authors)
    }))

  const collections = massageResources(
    hits.filter(r => r.resource_type_display === "Collection")
  )
  const courses = massageResources(
    hits.filter(r => r.resource_type_display === "Course")
  )
  const lessons = massageResources(
    hits.filter(r => r.resource_type_display === "Lesson")
  )
  const authors = massageAuthors(massageFacets("authors", "display_name"))
  const categories = massageFacets("categories", "name")

  return {
    collections,
    courses,
    lessons,
    authors,
    categories
  }
}
