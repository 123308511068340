import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import camelCase from "lodash/camelCase"
import capitalize from "lodash/capitalize"
import get from "lodash/get"
import React, { Component, useEffect } from "react"

// This is a helper for the new context API
export const withContext = (Context, Component) => {
  return props => (
    <Context.Consumer>
      {context => <Component {...props} context={context} />}
    </Context.Consumer>
  )
}

// TODO: Depricate this!
// ex. withContextAsProp(ResourceContext, "parentGroup", "group")(Component)
export const withContextAsProp = (Context, attrName, attrPath, debug) => {
  return function(ComposedComponent) {
    const fullAttrName = camelCase(
      attrPath
        .split(".")
        .map(capitalize)
        .join("")
    )
    const WithContextAsProp = props => (
      <Context.Consumer>
        {context => (
          <ComposedComponent
            {...props}
            {...{
              [attrName]:
                typeof props[attrName] !== "undefined"
                  ? props[attrName]
                  : get(context, attrPath)
            }}
          />
        )}
      </Context.Consumer>
    )

    WithContextAsProp.displayName = `with${fullAttrName}${ComposedComponent.displayName ||
      ""}`

    return WithContextAsProp
  }
}

// TODO: Deprecate withStateAsProps in favor of useState React Hook
// ex. withStateAsProp({ code:  null })(Component)
// ComposedComponent receives props code, withStateAsProps
// Use withStateAsProps prop same as setState
export const withStateAsProps = attributes => {
  return function(ComposedComponent) {
    return class extends Component {
      static displayName = `withStateAsProps${ComposedComponent.displayName ||
        ""}`

      constructor(props) {
        super(props)
        this.state = attributes
      }

      handleState = attributes => {
        this.setState(attributes)
      }

      render() {
        const passProps = {
          ...this.props,
          ...this.state,
          withStateAsProps: this.handleState
        }

        return <ComposedComponent {...passProps} />
      }
    }
  }
}

export const withDebouncedProp = (
  propName,
  delay = 300
) => ComposedComponent => props => {
  // searchValue -> debouncedSearchValue
  const debouncedPropName = camelCase(`debounced ${propName}`)
  const debouncingPropName = camelCase(`debouncing ${propName}`)
  const debouncedHandlerPropName = camelCase(`handle ${debouncedPropName}`)

  const [
    value,
    debouncedValue,
    debouncing,
    handleDebounceValue
  ] = useDebouncedValue(delay)

  // optionally debounce upstream value
  useEffect(() => {
    if (props.hasOwnProperty(propName)) {
      handleDebounceValue(props[propName])
    }
  }, [props[propName]])

  const passProps = {
    [propName]: value,
    [debouncedPropName]: debouncedValue,
    [debouncingPropName]: debouncing,
    [debouncedHandlerPropName]: handleDebounceValue
  }

  return <ComposedComponent {...props} {...passProps} />
}

export const withPollForProp = (
  attrName,
  attrPath
) => withQuery => PassedComponent => {
  let resolvePollingPromise = null
  const pollingPromise = new Promise((resolve, reject) => {
    resolvePollingPromise = resolve
  })

  class PollForProp extends Component {
    static displayName = `with${camelCase(attrName)}${Component.displayName ||
      ""}`

    UNSAFE_componentWillReceiveProps(nextProps) {
      const attr = get(this.props, attrPath)
      const nextAttr = get(nextProps, attrPath)
      if (!attr && nextAttr) {
        this.props.endPolling()
      }
    }

    render() {
      return (
        <PassedComponent
          {...this.props}
          {...{ [attrName]: this.props.polling }}
        />
      )
    }
  }

  const ComposedPollForProp = withQuery(PollForProp)

  return class PollForPropContainer extends Component {
    static displayName = `with${camelCase(
      attrName
    )}Container${Component.displayName || ""}`

    state = {
      polling: false,
      pollInterval: 0
    }

    beginPolling = () => {
      this.setState({
        polling: true
      })
      // TODO: move to prop?
      const pollIntervals = [1000, 3000, 5000, 10000]
      const delays = [0].concat(
        pollIntervals.slice(1).map(interval => interval * 4)
      )

      delays.forEach((d, i) => {
        setTimeout(() => {
          if (this.state.polling) {
            this.setState({
              pollInterval: pollIntervals[i]
            })
          }
        }, d)
      })

      return pollingPromise
    }

    endPolling = () => {
      this.setState({
        polling: false,
        pollInterval: 0
      })
      resolvePollingPromise()
    }

    render() {
      return (
        <ComposedPollForProp
          {...this.props}
          {...{ [attrName]: this.state.polling }}
          beginPolling={this.beginPolling}
          endPolling={this.endPolling}
          pollInterval={this.state.pollInterval}
        />
      )
    }
  }
}
