import Card from "@pathwright/ui/src/components/card/Card"
import React from "react"
import AssignFlow from "./AssignFlow"

const AssignCard = ({ card, ...passProps }) => {
  return (
    <Card card={card} noaction>
      <AssignFlow {...passProps} onClose={card.onClose} />
    </Card>
  )
}

AssignCard.displayName = "AssignCard"

export default AssignCard
