import PropTypes from "prop-types"
import React from "react"
import { SpeedButton, SpeedSelectorUI } from "./ui"

const SpeedSelector = props => {
  const { speedOptions, currentSpeed: propsCurrentSpeed, onSelectSpeed } = props

  const [currentSpeed, setCurrentSpeed] = React.useState(propsCurrentSpeed)

  // Allow speed to be changed from parent sources
  React.useEffect(() => {
    setCurrentSpeed(propsCurrentSpeed)
  }, [propsCurrentSpeed])

  const handleSetSpeed = () => {
    const speedIndex = speedOptions.indexOf(Number(currentSpeed))

    const nextSpeed =
      speedIndex >= speedOptions.length - 1
        ? speedOptions[0]
        : speedOptions[speedIndex + 1]

    setCurrentSpeed(nextSpeed)
    onSelectSpeed(nextSpeed)
  }

  return (
    <SpeedSelectorUI>
      <SpeedButton onClick={handleSetSpeed}>{`${currentSpeed}x`}</SpeedButton>
    </SpeedSelectorUI>
  )
}

SpeedSelector.propTypes = {
  onSelectSpeed: PropTypes.func.isRequired,
  speedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentSpeed: PropTypes.number.isRequired
}

SpeedSelector.defaultProps = {
  speedOptions: [1, 1.5, 2, 3, 0.5],
  currentSpeed: 1
}

export default SpeedSelector
