import HtmlText from "@pathwright/ui/src/components/text/HtmlText"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import InlineConfirm from "@pathwright/ui/src/components/confirm/InlineConfirm"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import { media } from "@pathwright/ui/src/components/utils/styles"
import moment from "moment"
import React from "react"
import styled from "styled-components"
import DiscussionInput from "./DiscussionInput"
import { useDiscussionContext } from "./DiscussionProvider"
import DiscussionResponseAttachments from "./DiscussionResponseAttachments"
import DiscussionResponseControls from "./DiscussionResponseControls"

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 0px 10px;
  opacity: ${p => (p.inactive ? 0.3 : 1)};
  background-color: rgba(0, 0, 0, ${p => (p.active ? 0.02 : 0)});
  margin: 10px 0px;
  .Avatar {
    margin-top: 10px;
  }
  /* border-radius: 20px; */
  /* padding-bottom: 30px; */
  ${media.max.phone`padding: 10px;`};
`

const Detail = styled.div`
  flex-grow: 1;
  margin-left: 10px;
  min-width: 0;
`
const Byline = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`
const Timestamp = styled(Text.Meta)`
  margin-left: 5px !important;
  &:first-letter {
    text-transform: capitalize;
  }
  ${Container}:is([disabled]):not(:hover) & {
    visibility: hidden;
  }
`

const Controls = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 10000;
  visibility: hidden;

  .ControlsButton {
    background-color: #f7f7f7 !important;
  }
`

const ChatBubbleContainer = styled.div`
  max-width: 100%;
  position: relative;
  border-radius: 22px;
  white-space: normal;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
  display: inline-block;
  /* add a small box shadow */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);

  font-size: 16px;

  &:hover {
    .Controls {
      visibility: visible;
    }
  }

  strong {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }
`

const Content = styled(HtmlText)`
  margin: 5px 0;
  /* line-height: 1.5em; */
  word-break: break-word;

  pre {
    overflow: auto;
  }

  p,
  ul,
  ol {
    margin: 5px 0;
  }
  img,
  video,
  audio,
  iframe {
    max-width: 100%;
  }
`

const ResponseControlsContainer = styled.div`
  .IconButton {
    .Button__content-wrapper {
      flex-direction: row;
      .IconButton__label {
        margin-left: 2px;
        font-weight: bold;
      }
    }
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;
`

const ResponseControls = ({ response, onToggleLike }) => {
  const { t } = useTranslate()
  const icon = response.user_liked ? "heart" : "heart"
  const color = response.user_liked ? "#0d71fb" : "#999"
  const title = response.user_liked ? "unlike" : "like"

  return (
    <ResponseControlsContainer>
      <Tooltip title={t(`discussion.response.${title}`)} delayShow={1000}>
        <IconButton
          icon={icon}
          label={response.likes ? `${response.likes}` : null}
          color={color}
          iconProps={{ size: "24px" }}
          onClick={() => onToggleLike(!response.user_liked)}
        ></IconButton>
      </Tooltip>
    </ResponseControlsContainer>
  )
}

const Response = React.forwardRef((props, ref) => {
  const { response } = props
  const { t } = useTranslate()

  const {
    replyingTo,
    likeResponse,
    unlikeResponse,
    viewResponse,
    editingResponse,
    deleteResponse
  } = useDiscussionContext()

  if (!response) {
    return null
  }

  const handleLike = () => {
    if (response.user_liked) {
      unlikeResponse(response)
    } else {
      likeResponse(response)
    }
  }

  return (
    <div className="DiscussionResponse" data-response={response.id} ref={ref}>
      {editingResponse && editingResponse.id === response.id ? (
        <DiscussionInput response={response} />
      ) : (
        <Container
          active={replyingTo && replyingTo.id === response.id}
          inactive={replyingTo && replyingTo.id !== response.id}
        >
          <InlineConfirm overlay onConfirm={() => deleteResponse(response)}>
            {({ confirm: confirmDelete }) => (
              <>
                <Avatar user={response.user} size="36px" />
                <Detail>
                  <ChatBubbleContainer>
                    <strong>{response.user.display_name}</strong>
                    <Button
                      styleType="inline"
                      onClick={() => viewResponse(response)}
                    >
                      <Timestamp>
                        {moment(response.created_time).fromNow()}
                      </Timestamp>
                    </Button>
                    <Content html={response.body} quill />
                    <DiscussionResponseAttachments
                      attachments={response.attachments}
                      download
                    />
                    {!!parseInt(response.id) && (
                      <Controls className="Controls">
                        <DiscussionResponseControls
                          response={response}
                          onDelete={confirmDelete}
                        />
                      </Controls>
                    )}
                    <Meta>
                      <ResponseControls
                        response={response}
                        onToggleLike={handleLike}
                      />
                    </Meta>
                  </ChatBubbleContainer>
                </Detail>
              </>
            )}
          </InlineConfirm>
        </Container>
      )}
    </div>
  )
})

Response.displayName = "Response"

export default Response
