import debounce from "lodash/debounce"
import { useEffect, useMemo, useRef } from "react"
import useCleanupInterval from "./useCleanupInterval"
import useIsMountedRef from "./useIsMountedRef"

// Simply tracks whether a debounced interval has been reached.
// Lodash does not currently provide a way of checking if the
// debounced function is pending.
const useIsDebouncing = delay => {
  const isDebouncingRef = useRef(false)
  const cleanupInterval = useCleanupInterval()

  const setIsDebouncing = () => {
    isDebouncingRef.current = true
    // If the previous timeout has not yet been reached, cleanupInterval
    // will stop it, preventing it from resetting isDebouncingRef.current.
    cleanupInterval(
      setTimeout(() => {
        isDebouncingRef.current = false
      }, delay)
    )
  }

  return [isDebouncingRef.current, setIsDebouncing]
}

const useDebouncedEffect = (fn, inputs = [], options = {}) => {
  // TODO: useEffectHook feels awkward, maybe just create wrappers
  // for useDebouncedEffect instead?
  const { delay = 300, useEffectHook = useEffect } = options
  // Track whether the debounced function is currently debouncing.
  const [isDebouncing, setIsDebouncing] = useIsDebouncing(delay)

  const debouncedFn = useMemo(() => {
    const debouncedFn = debounce(fn, delay, {
      // Lead the debounced function when not debouncing, which is the
      // expected behavior for leading a debounced function.
      leading: !isDebouncing,
      trailing: true
    })

    return () => {
      debouncedFn()
      setIsDebouncing()
      // Subsequent executions of the useEffect will cancel out call to
      // the previous debouncedFn.
      return debouncedFn.cancel
    }
  }, inputs)

  useEffectHook(debouncedFn, inputs)
}

export default useDebouncedEffect
