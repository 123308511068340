import React from "react"
import Activity from "../activity/Activity"

const ProfileActivity = ({ userId }) => {
  return (
    <div className="ProfileActivity">
      <Activity userId={userId} singleColumn={true} />
    </div>
  )
}

ProfileActivity.displayName = "ProfileActivity"

export default ProfileActivity
