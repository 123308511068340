const SchoolSubscriptionPlanCollection = require("account/subscription/models/school-subscription-plan-collection")
  .default
const SchoolSubscriptionCollection = require("account/subscription/models/school-subscription-collection")
  .default
const SchoolSubscription = require("account/subscription/models/school-subscription")
  .default
const { post } = require("lib/core/api/request")
import { getSchoolUrl } from "@pathwright/web/src/modules/utils/urls"

const PauseSubscription = require("account/subscription/models/school-subscription-pause")
  .default

class SubscribeStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    // hack for rendering the subscribe flow again after loading coupon

    this.prototype.resources = {
      school_subscription_plans: {
        model: SchoolSubscriptionPlanCollection
      },
      subscription: {
        model: SchoolSubscription,
        collection: SchoolSubscriptionCollection
      },
      pause_subscription: {
        model: PauseSubscription
      }
    }

    this.prototype.storeTriggerHandlers = {
      school: {
        "bootstrapped:school"() {
          return this.action.reload()
        }
      }
    }

    this.prototype.storeTriggers = {
      subscribed() {
        return this.get("subscription")
      }
    }

    this.prototype.storeEvents = {
      "change:subscription"() {
        if (!this.get("mute_subscribe_state")) {
          return this.action.setSubscribeState()
        }
      }
    }

    this.prototype.actions = {
      setSubscribeState() {
        let subscribe_state = "unsubscribed"
        const { subscription } = this.getState()
        if (
          (subscription != null ? subscription.subscription : undefined) != null
        ) {
          if (subscription.is_canceled) {
            subscribe_state = "paused"
          } else {
            subscribe_state = "active"
          }
        }
        return this._mutate({ subscribe_state })
      },

      loadPlans(vc = null, forceReload = false) {
        return this.resource.school_subscription_plans
          .load({ vc }, forceReload)
          .then(plans => {
            this._mutate({ plans })
          })
      },

      loadUserSubscription(forceReload) {
        if (forceReload == null) {
          forceReload = false
        }
        return new Promise(
          function(resolve, reject) {
            if (this.getStore("auth").request.isAuthenticated()) {
              return resolve(this.resource.subscription.load({}, forceReload))
            } else {
              return resolve(null)
            }
          }.bind(this)
        )
      },

      pauseSubscription(paused_months) {
        const { subscription } = this.getState()
        const cycleEnd = subscription.subscription.cycle_end_dtime
        const paused_until_dtime = moment(cycleEnd)
          .add("months", paused_months)
          .toDate()
        const subscription_id = this.get("subscription").id
        return this.resource.pause_subscription
          .save({ subscription_id, paused_until_dtime })
          .then(() => {
            let forceReload
            return this.action.loadUserSubscription((forceReload = true))
              .promise
          })
      },

      unPauseSubscription() {
        const subscription_id = this.get("subscription").id
        return this.resource.pause_subscription
          .destroy({ subscription_id, id: "foo" })
          .then(() => {
            let forceReload
            return this.action.loadUserSubscription((forceReload = true))
              .promise
          })
      },

      cancelSubscription() {
        this._mutate({
          cancel_subscription_saving: true,
          cancel_subscription_error: null
        })
        return new Promise(
          function(resolve, reject) {
            return post(
              getSchoolUrl("/api/private/payment/subscription/cancel/")
            )
              .then(() => {
                let forceReload
                return resolve(
                  this.action.loadUserSubscription((forceReload = true)).promise
                )
              })
              .catch(err => {
                this._mutate({ cancel_subscription_error: err })
                return reject(err)
              })
              .then(() => {
                return this._mutate({ cancel_subscription_saving: false })
              })
          }.bind(this)
        )
      },

      reload() {
        this.resource.subscription.clearCache()
        this._mutate(this.resource.subscription.initialState)
        if (this.getStore("auth").request.isAuthenticated()) {
          return this.action.loadUserSubscription()
        }
      },

      clearErrors() {
        return this._mutate({
          cancel_subscription_error: null,
          pause_subscription_error: null
        })
      }
    }
  }

  defaults() {
    return {
      plans: null,
      plan: null,
      subscribe_state: null
    }
  }

  bootstrapStore(d) {
    const plans = new SchoolSubscriptionPlanCollection(
      d.commerce.subscription_plans
    )
    this.action.loadUserSubscription()
    return this._mutate({ plans })
  }
}
SubscribeStore.initClass()

export default window.App.stores.registerStore("subscribe", SubscribeStore)
