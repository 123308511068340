import React, { Component } from "react"
import Animated from "animated/lib/targets/react-dom"

class PickerAnimator extends Component {
  anim = new Animated.Value(0.66)

  componentDidMount() {
    Animated.spring(this.anim, {
      toValue: 1,
      tension: 90,
      friction: 9
    }).start(({ finished }) => {
      if (finished) {
        this.props.onFinish && this.props.onFinish()
      }
    })
  }

  render() {
    return (
      <Animated.div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          transform: [
            {
              scale: this.anim
            }
          ]
        }}
      >
        {this.props.children}
      </Animated.div>
    )
  }
}

PickerAnimator.displayName = "PickerAnimator"

export default PickerAnimator
