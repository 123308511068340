import moment from "moment"
import get from "lodash/get"

export const getPricePreview = function(group) {
  let preview = ""
  if (group != null ? group.is_subscription_only : undefined) {
    preview += "Subscription only"
  } else if ((group != null ? group.price : undefined) === 0) {
    preview += "Free"
  } else if ((group != null ? group.price : undefined) > 0) {
    preview = group.price
  }
  return preview
}

export const getSubscriptionPreview = function(group) {
  let preview = ""
  if (
    (group != null ? group.include_in_subscription_plans.length : undefined) > 0
  ) {
    preview += `${group.include_in_subscription_plans.length} plan`
    if (group.include_in_subscription_plans.length > 1) {
      preview += "s"
    }
  }
  return preview
}

export const getAccessPreview = function(group) {
  let preview = ""
  if (
    (group != null ? group.enrollment_starts : undefined) &&
    (group != null ? group.enrollment_ends : undefined)
  ) {
    preview += `${moment(group.enrollment_starts).format("MMM Do")} - ${moment(
      group.enrollment_ends
    ).format("MMM Do")}`
  } else {
    preview += "Join anytime"
  }
  if (group != null ? group.seats : undefined) {
    preview += `, ${group.seats} seats`
  } else {
    preview += ", Unlimited seats"
  }
  return preview
}

export const cohortEndsWhenLicenseExpires = cohort => {
  if (!cohort) return false

  return (
    !!cohort.access_ends &&
    moment(cohort.access_ends).isSame(
      get(cohort, "resource_license.expiration_dtime")
    )
  )
}
