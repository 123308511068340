import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import RichTextInput from "@pathwright/ui/src/components/form/form-richtext/RichTextInput"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import Link from "@pathwright/ui/src/components/link/Link"
import { TOOLBAR_CONFIG_TYPES } from "@pathwright/ui/src/components/quill/toolbar/QuillToolbar"
import PropTypes from "prop-types"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
const { isEmail, isMaxLength } = validators
import styled from "styled-components"

const StyledCardBlock = styled(CardBlock)`
  .RichTextInput .ql-editor {
    min-height: 3.5rem;
  }
`

const ManageSupport = ({ card, initialValue, onSubmit }) => {
  const { school } = usePathwrightContext()
  return (
    <Card card={card} title={`${school.name} Support`}>
      <Form initialValue={initialValue} onSubmit={onSubmit}>
        <CardBlock>
          <Field
            name="support_email"
            label="Contact email"
            type="email"
            placeholder="Enter your support email address"
            helperText="The email address where your members can contact you for help."
            component={TextInput}
            labelWidth={130}
            validators={[isEmail]}
          />
        </CardBlock>
        <CardBlock>
          <Field
            name="support_phone"
            label="Contact phone"
            type="tel"
            placeholder="1 (888) 888-8888"
            helperText="The phone number where your members can call you."
            component={TextInput}
            labelWidth={130}
            validators={[isMaxLength(18)]}
          />
        </CardBlock>
        <StyledCardBlock>
          <Field
            name="support_policy"
            label="Support policy"
            type="textarea"
            placeholder="Describe how and when your members can get help (support hours, links to help articles, etc.)"
            component={RichTextInput}
            theme="snow"
            toolbar={TOOLBAR_CONFIG_TYPES.FULL}
            // NOTE: attempting to add indent options, but causes toolbar UI glitchiness.
            // createCustomToolbar={({ getMergedToolbarConfig }) =>
            //   getMergedToolbarConfig({
            //     config: [{ indent: "-1" }, { indent: "+1" }]
            //   })
            // }
            getToolbarConfig
            labelWidth={130}
            topLabel
            instructions={
              <span>
                A great place to list your hours, expected response time, and
                links to any help resources. This text is displayed on the{" "}
                <Link to="/help/?mode=school">"Need help" screen.</Link>
              </span>
            }
          />
        </StyledCardBlock>
        <Submit styleType="primary" component={SubmitButton}>
          Save
        </Submit>
      </Form>
    </Card>
  )
}

ManageSupport.displayName = "ManageSupport"

ManageSupport.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    support_email: PropTypes.string,
    support_phone: PropTypes.string,
    support_policy: PropTypes.string
  })
}

export default ManageSupport
