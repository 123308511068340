import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "@pathwright/ui/src/components/button/Button"

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 0 25px;
`

const StyledPrompt = styled.div`
  flex-grow: 1;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  h5 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 12px;
  }
`

const StyledActions = styled.div`
  display: flex;
  align-items: center;
`

const ConfirmationPrompt = props => {
  const { confirmButtonText, meta, onCancel, onConfirm } = props

  return (
    <StyledWrapper>
      <StyledPrompt>
        <h5>Are you sure?</h5>
        {meta && <p>{meta}</p>}
      </StyledPrompt>
      <StyledActions>
        <Button onClick={onConfirm} dangerous size="small">
          {confirmButtonText}
        </Button>
        <Button onClick={onCancel} styleType="blank" size="small" inverted>
          Cancel
        </Button>
      </StyledActions>
    </StyledWrapper>
  )
}

ConfirmationPrompt.displayName = "ConfirmationPrompt"

ConfirmationPrompt.propTypes = {
  confirmButtonText: PropTypes.string.isRequired,
  meta: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default ConfirmationPrompt
