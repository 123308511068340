import { Button, chakra, Spinner, Text, VStack } from "@chakra-ui/react"
import { Formik } from "formik"
import { toFormikValidate } from "zod-formik-adapter"
import Pathicon from "../../../../components/pathicon/Pathicon"
import { useScopedTranslation } from "../../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../../lib/types"
import { useActionState } from "../../../../lib/utils/action-state"
import { useSpaceContext } from "../../../space/api/context"
import AuthFormAlert from "../../components/AuthFormAlert"
import AuthFormButton from "../../components/AuthFormButton"
import AuthFormHeading from "../../components/AuthFormHeading"
import AuthMotion from "../../components/AuthMotion"
import useColorModeBranding from "../../utils/useColorModeBranding"
import PasswordInput from "../components/PasswordInput"
import { schema } from "./api/action"
import PasswordsForm from "./PasswordsForm"

const validate = toFormikValidate(schema)

const PasswordChangeView = ({
  data,
  loading,
  success,
  error,
  onSubmit,
  onContinue
}: ActionType & { onContinue: Function }) => {
  const colorScheme = useColorModeBranding()
  const { user } = useSpaceContext()
  // NOTE: there should always be a user + email.
  const email = user?.email || ""
  const { t } = useScopedTranslation("password_form.fields")
  const useActiveActionState = useActionState({
    data,
    loading,
    success,
    error,
    onSubmit
  })
  const changePassword = useActiveActionState()
  const sendPasswordResetEmail = useActiveActionState()

  if (sendPasswordResetEmail.data?.success) {
    return (
      <AuthMotion>
        <VStack spacing={4}>
          <AuthFormHeading>Check your email</AuthFormHeading>
          <Text>
            We sent a link to {email} with instructions for resetting your
            password.
          </Text>
        </VStack>
      </AuthMotion>
    )
  }

  if (changePassword.data?.success) {
    return (
      <AuthMotion>
        <VStack spacing={4}>
          <Pathicon icon="check-circle-fill" color="border" size={30} />
          <AuthFormHeading>Password updated</AuthFormHeading>
          <Text>You can use your new password to sign-in from now on.</Text>
          <Button
            variant="solid"
            colorScheme={colorScheme}
            onClick={() => onContinue()}
          >
            Continue
          </Button>
        </VStack>
      </AuthMotion>
    )
  }

  return (
    <Formik
      initialValues={{
        email,
        currentPassword: "",
        password1: "",
        password2: ""
      }}
      validate={validate}
      onSubmit={() => {}} // noop
      schema={schema}
    >
      {(form) => (
        <AuthMotion>
          <form
            hidden
            id="startPasswordRetrieval"
            onSubmit={sendPasswordResetEmail.onSubmit}
          />
          <chakra.form onSubmit={changePassword.onSubmit} width="100%">
            <VStack spacing={4} width="100%">
              <AuthFormAlert error={error} />
              <AuthFormHeading>Update Password</AuthFormHeading>
              <Text textAlign="center">
                Passwords must be six characters, include an upper and lower
                case letter, and at least one number.
              </Text>
              <Text>
                <b>Email:</b> {email}
              </Text>
              <input hidden readOnly name="email" value={email} />
              <PasswordInput
                id="currentPassword"
                name="currentPassword"
                placeholder={t(`current_ph`) + "..."}
                isDisabled={loading}
              />
              <input
                hidden
                readOnly
                name="email"
                value={email}
                form="startPasswordRetrieval"
              />
              <Button
                variant="link"
                colorScheme={colorScheme}
                alignSelf="flex-end"
                textDecor="underline"
                form="startPasswordRetrieval"
                value={email}
                type="submit"
                isDisabled={loading}
                // Preventing tabbing to the "Forgot password?" button when user is
                // clearly intending to enter new password.
                tabIndex={
                  form.values.currentPassword && !form.touched.currentPassword
                    ? -1
                    : undefined
                }
              >
                {sendPasswordResetEmail.loading && <Spinner size="sm" mr={2} />}
                Forgot password?
              </Button>
              <PasswordsForm isSubmitting={loading} />
              <AuthFormButton loading={changePassword.loading}>
                Update Password
              </AuthFormButton>
            </VStack>
          </chakra.form>
        </AuthMotion>
      )}
    </Formik>
  )
}

export default PasswordChangeView
