import { useEffect, useState } from "react"
import useCodex from "./useCodex"

const useCodexNode = (id, type) => {
  const client = useCodex()
  let [node, setNode]: any = useState()
  const [loading, setLoading] = useState(true)

  const loadNode = async () => {
    setLoading(true)
    let node = await client.load(id, type)
    setLoading(false)
    setNode(node)
  }

  useEffect(() => {
    loadNode()
  }, [id])

  if (node) {
    node.loading = loading
    node.refetch = loadNode
  }

  return node
}

export default useCodexNode
