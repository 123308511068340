// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let ResponseCollection
export default ResponseCollection = (function() {
  ResponseCollection = class ResponseCollection extends require("lib/static-shim").default(
    require("lib/core/paginated-collection").default
  ) {
    static initClass() {
      this.prototype.model = require("./response").default
    }

    initialize(models, options) {
      if (options == null) {
        options = {}
      }
      this.options = options
      this.options = _.defaults(this.options, {
        filters: {
          ordering: "recent"
        }
      })
      // Context should already be a string
      ;({ context: this.context } = this.options)
      return super.initialize(models, this.options)
    }

    url() {
      const discussionID = this.discussionID || this.options.discussion_id
      const responseID = this.options.response_id
      if (this.options.discussion_id) {
        delete this.options["discussion_id"]
      }
      let url = this.urlRoot(
        `/api/private/discussion/${discussionID}/response/`
      )
      if (responseID) {
        url = `${url}${responseID}/`
      }
      const params = this.getPaginationParams()
      url = `${url}?${$.param(params)}`
      return url
    }

    // comparator: (response)->
    //   val = 0
    //   console.log "ResponseCollection.comparator: ", @options
    //   if not @options.filters?.ordering?
    //     @options.filters = {} if not @options.filters?
    //     @options.filters.ordering = "active"
    //   console.log "filtering by :", @options.filters.ordering.split(",")
    //   for field in @options.filters.ordering.split(",")
    //     sgn = 1
    //     if field[0] is "-"
    //       sgn = -1
    //       field = field[1..]
    //     val += response.get(field) * sgn
    //   return val

    parse(response) {
      // Set a context on all discussions
      const responses = response.results
      for (let r of Array.from(responses)) {
        r.context_str = this.context
      }
      return super.parse(response)
    }
  }
  ResponseCollection.initClass()
  return ResponseCollection
})()
