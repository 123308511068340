import classnames from "classnames"
import { isFunction } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import useControlledState from "../hooks/useControlledState"
import Tab from "./Tab"

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`

const TabsContainer = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const PanelsContainer = styled.main`
  flex: 1;
  display: flex;
  min-width: 0;
`

const PanelContainer = styled.section`
  flex: 1;
  ${p => !p.active && "display: none"};
  min-width: 0;
`

// NOTE: TabsContext would really only be necessary if we built out
// TabsContainer and PanelsContainer as separate components.
const TabsContext = React.createContext()

const Tabs = props => {
  const {
    children,
    className,
    tabs,
    selected: selectedProp,
    onSelect,
    inverted
  } = props
  const [selected, setSelected] = useControlledState(selectedProp, onSelect)
  const [selectedHistory, setSelectedHistory] = useState(
    () => new Set([selected])
  )

  useEffect(() => {
    if (selected) {
      setSelectedHistory(new Set([...selectedHistory, selected]))
    } else {
      // Defaulting initially selected tab to first tab.
      // This really should only happen if Tabs is not controlled.
      if (!props.hasOwnProperty("selected") && tabs.length > 0) {
        setSelected(tabs[0].key)
      }
    }
  }, [selected])

  var containerClassName = "Tabs__panels-container"
  if (selected) containerClassName += ` Tabs__panels-container--${selected}`

  const renderedTabs = (
    <Container className={classnames("Tabs", className)}>
      <TabsContainer className="Tabs__tabs-container">
        {tabs.map(tab => (
          <Tab
            key={tab.key}
            tab={tab}
            onClick={() => setSelected(tab.key)}
            active={tab.key === selected}
            inverted={inverted}
          >
            {tab.Tab &&
              (({ renderTab }) => (
                <tab.Tab
                  tab={tab.key}
                  active={tab.key === selected}
                  renderTab={renderTab}
                  {...tab}
                />
              ))}
          </Tab>
        ))}
      </TabsContainer>
      <PanelsContainer className={containerClassName}>
        {tabs.map(tab =>
          // Ensuring a tab that had been previously selected does not unmount.
          selectedHistory.has(tab.key) ? (
            <PanelContainer
              className="Tabs__panel-container"
              key={tab.key}
              active={tab.key === selected}
            >
              <tab.Panel
                panel={tab.key}
                active={tab.key === selected}
                {...tab}
              />
            </PanelContainer>
          ) : null
        )}
      </PanelsContainer>
    </Container>
  )

  return (
    <TabsContext.Provider
      value={{
        selected,
        setSelected
      }}
    >
      {isFunction(children)
        ? children({ renderedTabs, selected, setSelected })
        : renderedTabs}
    </TabsContext.Provider>
  )
}

Tabs.displayName = "Tabs"

Tabs.propTypes = {
  children: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      indicator: PropTypes.bool,
      count: PropTypes.number,
      Panel: PropTypes.elementType.isRequired,
      Tab: PropTypes.elementType
    })
  ).isRequired,
  // For controlling selected tab.
  selected: PropTypes.string,
  onSelect: PropTypes.func
}

export default Tabs
