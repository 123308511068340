import upperCase from "lodash/upperCase"
import { MEDIA_TYPES, MEDIA_TYPE_MAP } from "./media-types"
import { MediaType } from "./types"
import { removeHTML } from "./general"
import { PickerOptions, PickerFileMetadata } from "filestack-js"

export const FILESTACK_API_KEY = "PKIcKga_QLKv1rkAdPQB"

export const FILESTACK_STYLES_URL =
  "https://duointeractive.github.io/filestack-styles.css"

export const BASE_FILESTACK_OPTIONS: PickerOptions = {
  imageMax: [2000, 2000],
  maxFiles: 5,
  uploadInBackground: false,
  fromSources: [
    "local_file_system",
    "dropbox",
    "googledrive",
    "onedrive",
    "facebook"
  ]
}

export const FILESTACK_OPTIONS: { [key in MediaType]?: PickerOptions } = {
  [MEDIA_TYPES.DOCUMENT]: {
    ...BASE_FILESTACK_OPTIONS,
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.DOCUMENT],
    maxSize: 100 * 1024 * 1024,
    maxFiles: 1
  },
  [MEDIA_TYPES.ARCHIVE]: {
    ...BASE_FILESTACK_OPTIONS,
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.ARCHIVE],
    maxSize: 100 * 1024 * 1024,
    maxFiles: 1
  },
  [MEDIA_TYPES.IMAGE]: {
    ...BASE_FILESTACK_OPTIONS,
    fromSources: [
      ...BASE_FILESTACK_OPTIONS.fromSources!,
      // "customsource",
      "unsplash",
      "imagesearch",
      "webcam",
      // "url",
      "instagram"
    ],
    customSourceName: "Sample Images",
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.IMAGE],
    maxSize: 100 * 1024 * 1024,
    maxFiles: 20
  },
  [MEDIA_TYPES.FILE]: {
    ...BASE_FILESTACK_OPTIONS,
    accept: undefined,
    maxSize: 100 * 1024 * 1024,
    maxFiles: 15
  },
  [MEDIA_TYPES.VIDEO]: {
    ...BASE_FILESTACK_OPTIONS,
    fromSources: [...BASE_FILESTACK_OPTIONS.fromSources!, "video", "url"],
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.VIDEO],
    maxSize: 500 * 1024 * 1024
  },
  [MEDIA_TYPES.AUDIO]: {
    ...BASE_FILESTACK_OPTIONS,
    fromSources: [...BASE_FILESTACK_OPTIONS.fromSources!, "audio", "url"],
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.AUDIO],
    acceptFn: async (file: any, options: any) => {
      // This custom accept function added specifically to permit the m4a file type;
      // For some reason the magicbytes validation doesn't recognize it
      // https://www.filestack.com/docs/uploads/pickers/web/#acceptFn

      let mimeTest = ""

      // Local File system has the unique .originalFile key
      if (file.source === "local_file_system") {
        const magicMime = await options.mimeFromMagicBytes(file.originalFile)
        const extMime = await options.mimeFromExtension(file.originalFile.name)
        mimeTest = magicMime.indexOf("audio/") >= 0 ? magicMime : extMime
        // Use mimeType if it's set
      } else if (file.mimetype) {
        mimeTest = file.mimetype
        // Fall back to Filestack's built in extension detector
      } else {
        mimeTest = await options.mimeFromExtension(file.name)
      }

      if (mimeTest.indexOf("audio/") === -1) {
        return Promise.reject(
          `Please upload a valid audio file. Detected file type: ${file.mimetype}.`
        )
      }

      return Promise.resolve()
    },
    maxSize: 100 * 1024 * 1024
  },
  [MEDIA_TYPES.ANY]: {
    ...BASE_FILESTACK_OPTIONS,
    accept: undefined,
    fromSources: [...BASE_FILESTACK_OPTIONS.fromSources!, "url"],
    maxSize: 500 * 1024 * 1024
  }
}

export const getStoreTo = (
  mediaStoragePath = ""
): PickerOptions["storeTo"] => ({
  container: "pw-filestack-uploads",
  path: mediaStoragePath,
  region: "us-east-1"
})

export const getOptions = (
  mediaType: string,
  overrides: Partial<PickerOptions> = {}
): PickerOptions => {
  // mediaType is optional.
  mediaType = mediaType || MEDIA_TYPES.ANY

  const typeKey: MediaType = mediaType.toUpperCase() as MediaType

  const options =
    // Force mediaType to be uppercase.
    FILESTACK_OPTIONS[typeKey] ||
    // Default to MEDIA_TYPES.ANY options.
    FILESTACK_OPTIONS[MEDIA_TYPES.ANY]

  return {
    ...options,
    ...overrides
  }
}

// mediaType: ["IMAGE", "FILE", "VIDEO", "AUDIO"]
// mediaStoragePath: string
// pickOptions: (current Filestack pick options)
// returns (new Filestack pick options)
export const getUploaderOptions = ({
  mediaType,
  pickOptions,
  mediaStoragePath
}: {
  mediaType: MediaType
  pickOptions: PickerOptions
  mediaStoragePath: string
}): PickerOptions => {
  // store the media in the appropriate account bucket
  const storeTo = getStoreTo(mediaStoragePath)

  const options = getOptions(upperCase(mediaType) as MediaType, {
    ...pickOptions,
    storeTo
  })

  return options
}

export const createFilestackDownloadURL = (handle = "", name = ""): string => {
  const base = "https://cdn.filestackcontent.com"
  const content = `content=t:attachment,f:%22${window.encodeURIComponent(
    removeHTML(name)
  )}%22`

  return `${base}/${FILESTACK_API_KEY}/${content}/${handle}`
}

export const getPreviewURL = (handle: string) =>
  `https://cdn.filestackcontent.com/preview/${handle}`
