import url from "url"
import extend from "lodash/extend"
import assign from "lodash/assign"

const route = function(pathname, state) {
  if (state == null) {
    state = {}
  }
  return { pathname, state }
}

const modalRoute = function(pathname, state) {
  if (state == null) {
    state = {}
  }
  if (pathname.pathname != null) {
    ;({ pathname } = pathname)
    state = assign(state, pathname.state || {})
  }
  state = extend(state, { modal: true })
  return route(pathname, state)
}

const formatUrl = function(urlToFormat) {
  urlToFormat = urlToFormat.replace(/\/?$/, "/")
  if (urlToFormat.indexOf("/?") > -1) {
    urlToFormat = urlToFormat.slice(0, urlToFormat.length - 1)
  }
  return urlToFormat
}

const emailUrl = function(email, subject, body) {
  if (subject == null) {
    subject = null
  }
  if (body == null) {
    body = null
  }
  email = `mailto:${email}`
  if (subject) {
    email = `${email}?subject=${subject}`
  }
  if (body) {
    const char = subject ? "&" : "?"
    email = `${email}${char}body=${body}`
  }
  return email
}

const getRelativeUrl = absUrl => url.parse(absUrl).pathname

const isRelativeUrl = url => url.indexOf("http") === -1

const getRedirect = function(url) {
  const redirects = [
    ["/school/catalog/", "/library/"],
    [
      "/school/catalog/course/",
      "/library/",
      "/about/" // appended to URL
    ]
  ]

  for (let redirect of Array.from(redirects)) {
    if (url.indexOf(redirect[0]) > -1) {
      url = url.replace(redirect[0], redirect[1])
      if (redirect[2]) {
        url += redirect[2]
      }
      return url.replace(/\/\//g, "/")
    }
  }
  return url
}

// Hack for making absolute urls within <Text /> relative (mainly for linking within app)
const routeToRelativeURL = function(url) {
  url = getRelativeUrl(url)
  url = getRedirect(url)
  // return App.navigate(url)
}

const getSchoolURL = () => {
  // App.getStore("school").getState().school.url;
}

export {
  route,
  modalRoute,
  formatUrl,
  emailUrl,
  getRelativeUrl,
  isRelativeUrl,
  getRedirect,
  routeToRelativeURL,
  getSchoolURL
}
