import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "@pathwright/ui/src/components/button/Button"
import Text from "@pathwright/ui/src/components/ui/Text"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const Container = styled.div`
  text-align: center;
  img {
    width: 140px;
    margin-bottom: 20px;
  }
  ul {
    text-decoration: none;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    li {
      list-style: disc;
      list-style-position: inside;
    }
  }
`

const SchoolSubscriptionComplete = ({ school_subscription }) => {
  const { school } = usePathwrightContext()
  const {
    subscribe_complete_html
  } = school_subscription.school_subscription_plan

  return (
    <Container>
      <img src={school_subscription.school_subscription_plan.image} />

      <Text.Body>
        {subscribe_complete_html ? (
          <div dangerouslySetInnerHTML={{ __html: subscribe_complete_html }} />
        ) : (
          <div>
            <Text.H1>You're subscribed!</Text.H1>
            <ul>
              <li>You now have 24/7 access to {school.name} courses.</li>
              <li>
                We've sent you an email receipt with your subscription
                information for your records.
              </li>
            </ul>
          </div>
        )}
      </Text.Body>

      <Button styleType="primary" to="/library/">
        Start Learning
      </Button>
    </Container>
  )
}

SchoolSubscriptionComplete.displayName = "SchoolSubscriptionComplete"

SchoolSubscriptionComplete.propTypes = {
  school_subscription: PropTypes.object
}

export default SchoolSubscriptionComplete
