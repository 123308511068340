import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { useFormikContext } from "formik"
import gql from "graphql-tag"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect } from "react"
import useCohortQuery from "../../cohort/graphql/useCohortQuery"
import useCohortMembersStats from "../../cohort/useCohortMembersStats"
import useCohortPermissions from "../../cohort/useCohortPermissions"
import { discussionContextPropType } from "../propTypes"
import { DISCUSSION_NOTIFY_OPTIONS } from "./constants"
import DiscussionNotifiySelect from "./DiscussionNotifySelect"

const PATH_ITEM_PUBLISHED_QUERY = gql`
  query PathItemPublishedQuery($sourceId: Int!, $cohortId: Int!) {
    pathItem(source_id: $sourceId, cohort_id: $cohortId) {
      id
      source_id
      last_published_dtime
    }
  }
`

const usePathItemPublished = context => {
  const pathItemPublishedQuery = useQuery(PATH_ITEM_PUBLISHED_QUERY, {
    variables: {
      sourceId: context.step_id,
      cohortId: context.cohort_id
    },
    skip: !context.step_id
  })

  // Defaulting to null so that a null return value can be ignored.
  let pathItemPublished = null

  if (context.step_id) {
    // User path items do not have a last_published_dtime set. To workaround this
    // we can assume the path item has been published if the ID and source ID are not
    // equal. Any user path items will (currently) never have an equal ID and source ID.
    // If the IDs are equal then we can require that the path item also has a last_published_dtime set.
    pathItemPublished = Boolean(
      get(pathItemPublishedQuery, "data.pathItem.id") !==
        get(pathItemPublishedQuery, "data.pathItem.source_id") ||
        get(pathItemPublishedQuery, "data.pathItem.last_published_dtime")
    )
  }

  return pathItemPublished
}

// Util for getting the appropriate notify tooltip.
const useNotifyTooltip = context => {
  const { t } = useTranslate()
  const cohortMemberStats = useCohortMembersStats(context)
  const pathItemPublished = usePathItemPublished(context)

  // If path item for context has not been published, learners cannot be notified.
  if (pathItemPublished === false) {
    return t(
      "Learners can't view this post until the step is synced with the cohort."
    )
  }

  // if there are no members to notify due to no members in cohort, disable notification.
  if (cohortMemberStats.total === 0) {
    return t("No one has joined this cohort yet, so there's no one to notify.")
  }

  // if there are no members to notify due to no members accepting discussion notifications, disable notification.
  if (cohortMemberStats.send_discussion_notifications === 0) {
    return t(
      "All members of this cohort opted out of notifications, so there's no one to notify."
    )
  }
}

// Shown to moderators+ for selecting whether to notify cohort members.
const DiscussionFormNotifySelect = ({ context, inverted }) => {
  const form = useFormikContext()

  const tooltip = useNotifyTooltip(context)
  // Presence of a tooltip indicates that the DiscussionNotifySelect
  // should be disabled.
  const disabled = !!tooltip

  const handleChange = value =>
    form.setFieldValue("notify", value, false /* shouldValidate */)

  // By default the DiscussionNotifiySelect is disabled, but if it becomes
  // enabled, we then need to default the notify option to NOTIFY.
  useEffect(() => {
    if (!disabled)
      // NOTE: Using resetForm rather than setFieldValue to avoid causing
      // form to become dirty.
      form.resetForm({
        values: {
          ...form.values,
          notify: DISCUSSION_NOTIFY_OPTIONS.NOTIFY
        }
      })
  }, [disabled])

  return (
    <Tooltip title={tooltip} placement="right">
      <DiscussionNotifiySelect
        context={context}
        value={form.values.notify}
        onChange={handleChange}
        disabled={disabled}
        inverted={inverted}
      />
    </Tooltip>
  )
}

// Shown to learners- for indicating other cohort members will see discussion.
const DiscussionFormNotifyNobody = ({ context, inverted }) => {
  const { t } = useTranslate()
  // Need cohort name for tooltip.
  const cohortQuery = useCohortQuery({ cohortId: context.cohort_id })

  const cohortMemberStats = useCohortMembersStats(context)

  return (
    <Text.Body inverted={inverted}>
      <Tooltip
        title={t(
          "{{ count, number }} person in this cohort and anyone who joins in the future can see this post, but only {{ cohort }} staff will be notifed about it.",
          {
            defaultValue_plural:
              "{{ count, number }} people in this cohort and anyone who joins in the future can see this post, but only {{ cohort }} staff will be notifed about it.",
            count: cohortMemberStats.total,
            cohortName: get(cohortQuery, "data.cohort.name")
          }
        )}
        placement="right"
      >
        <span>
          {t("Post to {{ count, number }} person", {
            defaultValue_plural: "Post to {{ count, number }} people",
            count: cohortMemberStats.total
          })}
        </span>
      </Tooltip>
    </Text.Body>
  )
}

const DiscussionFormNotify = ({ context, inverted }) => {
  // Must check for moderator+ permissions to determine
  // whether to show DiscussionNotifySelect.
  const cohortPerms = useCohortPermissions({
    cohortId: context.cohort_id
  })

  return (
    <div className="DiscussionFormNotify">
      {cohortPerms.hasModeratorLevelAccess ? (
        <DiscussionFormNotifySelect context={context} inverted={inverted} />
      ) : (
        <DiscussionFormNotifyNobody context={context} inverted={inverted} />
      )}
    </div>
  )
}

DiscussionFormNotify.displayName = "DiscussionFormNotify"

DiscussionFormNotify.propTypes = {
  context: discussionContextPropType,
  inverted: PropTypes.bool
}

DiscussionFormNotify.defaultProps = {
  inverted: true
}

export default DiscussionFormNotify
