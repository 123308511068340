import "./InviteLookupResult.css"
import classnames from "classnames"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import { validate } from "lib/core/components/forms"

const InviteLookupResult = ({ email, user, highlight, onSelect }) => {
  const className = classnames("InviteLookupResult", {
    "InviteLookupResult--highlight": highlight
  })
  return (
    <div className={className} onClick={() => onSelect(email)}>
      <Avatar initials={email && email[0]} user={user} size="40px" />
      <div className="InviteLookupResult__meta">
        {user ? (
          <h4>{user.full_name}</h4>
        ) : (
          <h5>Invite new member with email:</h5>
        )}
        <span>{email}</span>
      </div>
      <span className="InviteLookupResult__prompt">↵ to add</span>
    </div>
  )
}

InviteLookupResult.displayName = "InviteLookupResult"

export default InviteLookupResult
