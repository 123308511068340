import React from "react"
import { hasStoreEnabled } from "./utils"
import StoreBrowseContainer from "@pathwright/web/src/modules/store/StoreBrowseContainer"
import LibraryBrowse from "library/browse/components/LibraryBrowse"

// school.platform_version is not availble when declaring
// static routes so this component acts as intermediate
// container to render LibraryBrowse or StoreBrowse.

const StoreOrLibraryBrowse = props => {
  if (hasStoreEnabled()) {
    return <StoreBrowseContainer {...props} />
  }
  return <LibraryBrowse {...props} />
}

StoreOrLibraryBrowse.displayName = "StoreOrLibraryBrowse"

export default StoreOrLibraryBrowse
