import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PropTypes from "prop-types"
import React from "react"
import FeaturePermissionsForm from "../form/FeaturePermissionsForm"
import { featureKeyPropType } from "../propTypes"
import { getFeatureInfo } from "../utils"

const ManageFeaturePermissions = ({ card, featureKey }) => {
  const { t } = useTranslate()
  const { title } = getFeatureInfo(featureKey, t)

  return (
    <Card
      card={card}
      title={t("{{ feature }} visibility", {
        feature: title
      })}
      noaction
    >
      <CardBlock paddingTop={false}>
        <FeaturePermissionsForm featureKey={featureKey} />
      </CardBlock>
    </Card>
  )
}

ManageFeaturePermissions.displayName = "ManageFeaturePermissions"

ManageFeaturePermissions.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  featureKey: featureKeyPropType
}

export default ManageFeaturePermissions
