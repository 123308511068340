import React from "react"
import {
  BASE_FILESTACK_OPTIONS,
  MEDIA_TYPES,
  MEDIA_TYPE_MAP
} from "../../filestack/constants"
import { pick } from "../../filestack/utils"
import Pathicon from "../../pathicon/Pathicon"
import { useQuillEditorContext } from "../QuillEditorContext"

// handles attachment Quill toolbar option using Filestack
const handleAttachment = async function(quill) {
  // get initial range now before quill loses focus
  let range = quill.editor.getSelection()

  const handleImagesUploaded = ({ filesUploaded }) => {
    if (filesUploaded && filesUploaded.length) {
      filesUploaded.forEach((attachment, i) => {
        // update range after each embed is inserted
        range = quill.editor.getSelection() || range

        if (range) {
          // if a range of text is selected, let's replace the text with the attachment
          if (range.length) {
            quill.editor.deleteText(
              range.index,
              range.length,
              "api" // source
            )
          }

          if (i > 0) {
            quill.editor.insertText(
              range.index,
              "\n",
              "user" // source
            )
          }

          quill.editor.insertText(
            range.index,
            attachment.filename,
            "link",
            attachment.url,
            "user" // source
          )
        }
      })
    }
  }

  pick({
    ...BASE_FILESTACK_OPTIONS,
    fromSources: [
      ...BASE_FILESTACK_OPTIONS.fromSources,
      "unsplash",
      "imagesearch",
      "webcam",
      "instagram"
    ],
    accept: [
      ...MEDIA_TYPE_MAP[MEDIA_TYPES.IMAGE],
      ...MEDIA_TYPE_MAP[MEDIA_TYPES.FILE]
    ]
  }).then(handleImagesUploaded)
}

const AttachmentToolbarOption = () => {
  const { quillRef } = useQuillEditorContext()

  return (
    <button
      className="ql-attachment"
      onClick={() => handleAttachment(quillRef.current)}
    >
      <Pathicon icon="file" />
    </button>
  )
}

AttachmentToolbarOption.displayName = "AttachmentToolbarOption"

export default AttachmentToolbarOption
