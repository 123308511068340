import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import Text from "@pathwright/ui/src/components/ui/Text"
import { SIGN_IN } from "@pathwright/web/src/modules/auth/constants"
import NeedHelpButton from "@pathwright/web/src/modules/auth/NeedHelpButton"
import SignUpOrIn from "@pathwright/web/src/modules/auth/SignUpOrIn"
import { ConnectStores } from "lib/core/store"
import "./Auth.css"

const Auth = ({ auth_route, school, media }) => (
  <div className="AuthWrapper pw2">
    <ThemeBackground />
    <div className="Auth">
      <header className="AuthHeader">
        <img src={media.thumb} />
        <Text.H4>{school.name}</Text.H4>
      </header>
      {auth_route !== "sign-out" ? (
        <SignUpOrIn view={SIGN_IN} renderHeader={() => null} />
      ) : null}
      {auth_route !== "sign-out" && !window.Intercom ? (
        <NeedHelpButton to="" />
      ) : null}
    </div>
  </div>
)

Auth.displayName = "Auth"

const stores = [{ store: "school" }]

const mapStateToProps = function (state, ownProps) {
  const { school, media } = state.schoolStore.state
  const { auth_route } = ownProps.match.params
  return _.assign(
    {},
    {
      auth_route,
      school,
      media
    },
    ownProps
  )
}

export default ConnectStores(Auth, stores, {}, mapStateToProps)
