import React from "react"
import { ConnectStores } from "lib/core/store"
import Card from "@pathwright/ui/src/components/card/Card"
import CategoryFormContainer from "./CategoryFormContainer"

const CategoryFormCard = ({ card, title, existingCategory }) => (
  <Card title={title} card={card}>
    <CategoryFormContainer
      closeCard={card.onClose}
      onDirty={() => {
        if (existingCategory) {
          return card.setCardDirty(true)
        }
      }}
      onPristine={() => {
        if (existingCategory) {
          return card.setCardDirty(false)
        }
      }}
    />
  </Card>
)

CategoryFormCard.displayName = "CategoryFormCard"

const mapStateToProps = function(state, ownProps) {
  const { category } = state.categoryAdminStore.state

  const existingCategory = category != null ? category.id : undefined
  const title = existingCategory
    ? category.name
    : category
    ? "New Category"
    : ""

  return _.assign(
    {},
    {
      title,
      existingCategory
    },
    ownProps
  )
}

const stores = [{ store: "categoryAdmin", watch: ["category"] }]

export default ConnectStores(CategoryFormCard, stores, {}, mapStateToProps)
