import "./InviteLink.css"
import { Component } from "react"
import { observer } from "mobx-react"
import inviteStore from "../../store/invite-store"
import CopyToClipboard from "react-copy-to-clipboard"
import { INVITE_METHOD_EMAIL } from "invitation/constants"
import LicensedSeats from "invitation/invite/components/licensed-seats/LicensedSeats"
import SelectRole from "@pathwright/web/src/modules/invitation/SelectRole"
import { filterAvailableRoles } from "@pathwright/web/src/modules/invitation/utils"
import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"

class InviteLink extends Component {
  constructor(props) {
    super(props)
    this.handleCopy = this.handleCopy.bind(this)
    this.state = {
      copyStatus: "Copy link"
    }
  }

  handleCopy() {
    this.setState({ copyStatus: "Copied!" })
    setTimeout(() => {
      this.setState({ copyStatus: "Copy link" })
    }, 1000)
  }

  render() {
    return (
      <div className="InviteLink">
        <LicensedSeats />
        <p className="InviteLink__prompt">
          {inviteStore.context &&
          inviteStore.context.offering &&
          inviteStore.context.offering.visibility === 15 ? (
            <span>Share or post this invite link, or </span>
          ) : (
            <span>Share this private invite link, or </span>
          )}
          <Button
            brand
            styleType="text"
            onClick={() => inviteStore.action.changeMethod(INVITE_METHOD_EMAIL)}
          >
            <Pathicon icon="envelope" /> invite people by email
          </Button>
          .
        </p>
        <div className="InviteLink__controls">
          <SelectRole
            onChange={inviteStore.action.changeRole}
            value={inviteStore.role}
            roleType={inviteStore.type}
            roleFilter={(type, role) =>
              filterAvailableRoles(
                type,
                role,
                inviteStore.context && inviteStore.context.offering
              )
            }
            prompt={role => (
              <span className="InviteLink__role">
                <span>Anyone with the link can </span>
                <strong>join as a {role.label}</strong>
              </span>
            )}
          />
          {!inviteStore.isMobile && (
            <CopyToClipboard
              text={inviteStore.inviteUrl}
              onCopy={this.handleCopy}
            >
              <Button brand>{this.state.copyStatus}</Button>
            </CopyToClipboard>
          )}
        </div>
        <div className="InviteLink__field">
          <input
            onFocus={e => e.target.select()}
            value={inviteStore.inviteUrl}
            readOnly
            rows={1}
          />
        </div>
      </div>
    )
  }
}

InviteLink.displayName = "InviteLink"

export default observer(InviteLink)
