import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import { createPathFromItems } from "../utils"
import PATH_QUERY from "./path-query"
import withPathQueryVariables from "./withPathQueryVariables"

export const getPathItems = data => {
  const pathItems = createPathFromItems(flattenEdges(get(data, "path.items")))

  return pathItems.filter(item => {
    if (item.type === "resource") {
      return !!item.linked_resource // for whatever reason, some resource steps have formed without a linked resource, which should not be possible. We'll just ignore them for now.
    }
    return true
  })
}

export default compose(
  withPathQueryVariables,
  graphql(PATH_QUERY, {
    options: ({ id, cohortId, pathQueryVariables }) => ({
      variables: {
        id: id || pathQueryVariables.id, // optional
        cohort_id: cohortId || pathQueryVariables.cohort_id
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      hasMore: get(data, "path.items.pageInfo.hasNextPage"),
      loadMore: createPaginator(data, "path.items"),
      items: getPathItems(data),
      path: get(data, "path")
    })
  })
)
