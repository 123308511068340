import { useStartPasswordRetrieval } from "./api/action"
import PasswordResetView from "./PasswordResetView"

const PasswordResetViewContainer = () => {
  const [state, startPasswordRetrieval] = useStartPasswordRetrieval()

  const handleSubmit = async e => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await startPasswordRetrieval(formData)
  }

  return (
    <PasswordResetView
      loading={state.loading}
      success={state.value?.success}
      error={state.error?.message}
      onSubmit={handleSubmit}
    />
  )
}

export default PasswordResetViewContainer
