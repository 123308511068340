import { useFormikContext } from "formik"
import defaultsDeep from "lodash/defaultsDeep"
import { useEffect, useMemo } from "react"
import useDiscardFormState, {
  DiscardFormStateReturn,
  SetFormState
} from "./useDiscardFormState"
import { FormStateKey, FormStateStorage } from "./useFormStateStorage"
import { LocalStorageOptions } from "./useLocalStorage"

export type OnChangeDiscardFormState = (
  discardFormState: DiscardFormStateReturn
) => void

type FormikDiscardFormStateOptions = {
  // Optionally limit the keys that are stored. Only those specified keys will be stored
  // and compared for determining if form state has changed.
  keys?: string[]
  formStateKey?: FormStateKey
  localStorageOptions?: LocalStorageOptions
  onChangeDiscardFormState?: OnChangeDiscardFormState
}

// Handles setting initial formik state based on stored form state and
// synchronizing formik state with stored form state.
const useFormikDiscardFormState = ({
  keys,
  formStateKey,
  localStorageOptions,
  onChangeDiscardFormState
}: FormikDiscardFormStateOptions) => {
  const formikContext = useFormikContext()

  // formState naturally maps to Formik's context.
  const formState: FormStateStorage = useMemo(() => {
    // Reduce the values per the provided `keys`.
    // If no `keys`, then retain all values.
    const reducer = (values?: any) => {
      if (keys) {
        const nextValues = keys.reduce((acc, key) => {
          acc[key] = values?.[key]
          return acc
        }, {})

        return nextValues
      }
      return values
    }

    return {
      values: reducer(formikContext.values),
      initialValues: reducer(formikContext.initialValues)
    }
  }, [formikContext.values, formikContext.initialValues])

  const setFormState: SetFormState = (
    // TODO: get type of `nextState` from Formik.
    nextState
  ) => {
    // Use resetForm or setValues depending on if Formik should
    //   1) reset to initial state or reset to a new state
    //   2) update to a modified state
    // Only necessary since resetForm does not support handling #2.
    if (
      nextState.values === formikContext.values ||
      nextState.values === nextState.initialValues
    ) {
      formikContext.resetForm(defaultsDeep(nextState, formikContext))
    } else {
      formikContext.setValues(
        defaultsDeep(nextState.values, formikContext.values),
        true /* shouldValidate */
      )
    }
  }

  const discardFormState = useDiscardFormState({
    key: formStateKey,
    formState,
    setFormState,
    localStorageOptions
  })

  // Send change upstream, only when discardCount changes.
  useEffect(() => {
    onChangeDiscardFormState?.(discardFormState)
  }, [discardFormState.discardCount])

  return discardFormState
}

export default useFormikDiscardFormState
