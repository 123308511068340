import { useEffect, useState } from "react"
import isEqual from "react-fast-compare"
import useDebouncedEffect from "../../hooks/useDebouncedEffect"
import useDidMountEffect from "../../hooks/useDidMountEffect"
import useFormStateStorage from "./useFormStateStorage"

// Handles setting initial form state based on stored form state and
// synchronizing provided form state with stored form state.
const useDiscardFormState = ({ key, formState, setFormState }) => {
  const [
    formStateStorage,
    setFormStorageState,
    formStateStorageMeta
  ] = useFormStateStorage(formState.initialValues, key)
  const [numberOfChanges, setNumberOfChangedFields] = useState(0)

  // Manually count how many fields differ from their initial value.
  const getNumberOfChangedFields = () => {
    const keys = Object.keys(formState.values)
    const numberOfChanges = keys.reduce((numberOfChanges, key) => {
      // Compare initialValues against current values, per key.
      const increment = isEqual(
        formState.initialValues[key],
        formState.values[key]
      )
        ? 0
        : 1
      return numberOfChanges + increment
    }, 0)

    return numberOfChanges
  }

  useDebouncedEffect(
    () => {
      // The form state has been modified.
      setFormStorageState(formState.values)
      setNumberOfChangedFields(getNumberOfChangedFields)
    },
    [formState.initialValues, formState.values],
    // Only respond to changes to fomrState after initial setup.
    { useEffectHook: useDidMountEffect }
  )

  // When formStateStorage cchanges and the change source was
  // "global", meaning this change occurred in another web page,
  // update the current formState, which in turn will update the
  // "local" storage.
  useDebouncedEffect(() => {
    if (formStateStorageMeta.source === "global") {
      // An undefined value indicates the storage key has been completely
      // removed, while an otherwise nullish value indicates the storage
      // data has been discarded. Both cases indicate that the "local"
      // storage should be discarded.
      if (!formStateStorage) {
        // If formStateStorage is undefined, we can safely preserve the
        // formState. Otherwise we discard formState changes.
        const preserve = formStateStorage === undefined
        discardFormState(preserve)
      } else {
        // Simply update the current values.
        setFormState({
          values: formStateStorage.values,
          initialValues: formState.initialValues
        })
      }
    }
  }, [formStateStorage])

  useEffect(() => {
    if (
      formStateStorage &&
      !isEqual(formState.values, formStateStorage.values)
    ) {
      // The form state has not been modified but differs from the
      // local form state, presumably upon initial form mount.
      setFormState({
        values: formStateStorage.values,
        initialValues: formState.initialValues
      })
    }
  }, [])

  // Reset form to initial state.
  const discardFormState = (preserve = false) => {
    // Discarding form state might require preserving the form's
    // current state, for instance, after successful form submission.
    if (preserve === true) {
      setFormState({
        values: formState.values,
        initialValues: formState.values
      })
    } else {
      // Here we're discarding form's current state, which will result in
      // also discarding stored form state.
      setFormState({
        values: formState.initialValues,
        initialValues: formState.initialValues
      })
    }

    // HACK ALERT...

    // Sort of rerverse the logic for formStateStorage. The main reason for
    // handling discard logic differently for formStateStorage based on preserve
    // flag is for "global" syncing of localStorage state. The type of discard
    // communicates to other web pages about how to set the preserve flag value
    // when discarding changes.
    if (preserve === true) {
      // Completely discard stored form state.
      setFormStorageState()
    } else {
      // Preseve form state key, but clear the value.
      // Only necessary for "global" localStorage syncing.
      setFormStorageState(null)
    }
  }

  const mergeFormState = mergeFn => {
    if (formState && formStateStorage) {
      setFormState({
        values: mergeFn(formState.values),
        initialValues: mergeFn(formState.initialValues)
      })

      setFormStorageState({
        values: mergeFn(formStateStorage.values),
        initialValues: mergeFn(formStateStorage.initialValues)
      })
    }
  }

  return {
    discardCount: numberOfChanges,
    discardFormState,
    mergeFormState
  }
}

export default useDiscardFormState
