const occurrences = (str, char) => {
	let count = 0
	let pos = 0
  while (true) {
    pos = str.indexOf(char, pos)
    if (pos >= 0) {
      count++
      pos += 1
    } else break
  }
  return count
}

// counts strings split by commas 
// ignores leading and trailing commas
export const emailCount = (str) => {
	const trimmed = str.replace(/(^\s*,)|(,\s*$)/g,"")
	return occurrences(trimmed, ",") + 1
}