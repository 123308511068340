import { useMutation } from "@apollo/client"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import PropTypes from "prop-types"
import { Field, Form, Submit } from "react-connect-form-forked"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import START_PASSWORD_RETRIEVAL_MUTATION from "./graphql/start-password-retrieval-mutation"
import { AuthFooter, AuthWrapper, StyledHeader } from "./styles"
import { isEmail } from "./validators"

const RequestResetPasswordForm = ({
  renderHeader,
  renderSubmit,
  onSignIn,
  inverted
}) => {
  const { t, Trans } = useTranslate()
  const { school } = usePathwrightContext()
  const [mutation, { loading, error, data }] = useMutation(
    START_PASSWORD_RETRIEVAL_MUTATION
  )

  const tPrefix = "auth.request_reset_password_form"

  return (
    <Form
      onSubmit={async form => {
        // forcing mutation to always appear to succeed as a security precaution
        try {
          await mutation({ variables: form })
        } catch (error) {
          // noop
        }
      }}
    >
      {renderHeader({
        header: (
          <StyledHeader as="header">
            <Text.H2>{t(`${tPrefix}.prompt`)}</Text.H2>
            <Text.Body>{t(`${tPrefix}.instructions`)}</Text.Body>
          </StyledHeader>
        )
      })}
      <AuthWrapper>
        <Field
          name="email"
          type="email"
          // label={`${t(`${tPrefix}.email`)}:`}
          placeholder={`${t(`${tPrefix}.email_placeholder`)}...`}
          validators={[isEmail]}
          inverted={inverted}
          component={TextInput}
          labelWidth={70}
          flushLabel
          autoFocus
        />
        {/* treating both success or error as a success as a security precaution */}
        {(!!data || !!error) && (
          <Alert
            success={
              <Trans
                i18nKey="If an account was found with that email address, a password reset link will be sent to your email.<br/><br/> Can't remember what email address you used? Send an email to <a>{{ email }}</a> to get assistance."
                tOptions={{
                  email: school.email
                }}
                components={{
                  a: (
                    <Button
                      // #3ca63d is Alert success green, yay
                      color="#3ca63d"
                      styleType="text"
                      target="_blank"
                      href={`mailto:${school.email}`}
                    />
                  )
                }}
              />
            }
          />
        )}
      </AuthWrapper>
      <AuthFooter>
        <Submit
          render={passProps =>
            renderSubmit({
              ...passProps,
              submit: (
                <SubmitButton
                  {...passProps}
                  inverted={inverted}
                  styleType="primary"
                  size="large"
                >
                  {t(`${tPrefix}.send_reset_instructions`)}
                </SubmitButton>
              )
            })
          }
        />
        {onSignIn && (
          <Button onClick={onSignIn} inverted={inverted} styleType="blank">
            <Pathicon icon="arrow-left" /> {t(`${tPrefix}.back_to_signin`)}
          </Button>
        )}
      </AuthFooter>
    </Form>
  )
}

RequestResetPasswordForm.displayName = "RequestResetPasswordForm"

RequestResetPasswordForm.propTypes = {
  renderHeader: PropTypes.func,
  renderSubmit: PropTypes.func,
  inverted: PropTypes.bool,
  onSignIn: PropTypes.func
}

RequestResetPasswordForm.defaultProps = {
  renderHeader: ({ header }) => header,
  renderSubmit: ({ submit }) => submit
}

export default RequestResetPasswordForm
