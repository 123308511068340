import { useMutation } from "@apollo/client"
import PopupAlert from "@pathwright/ui/src/components/alert/PopupAlert"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import { useFileInput } from "@pathwright/ui/src/components/form/form-file-input/FileInput"
import { createDownloader } from "@pathwright/ui/src/components/form/form-file-input/utils"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const TRANSLATION_QUERY = gql`
  query TranslationQuery {
    translation {
      id
      school_translation
      master_translation
    }
  }
`

const TRANSLATION_MUTATION = gql`
  mutation UpdateTranslationMutation($id: Int!, $translation: JSON!) {
    updateTranslation(id: $id, translation: $translation) {
      id
      school_translation
    }
  }
`

const StyledCardLink = styled(CardLink)`
  &,
  * {
    cursor: pointer;
  }
`

const ManageTranslationCardLinks = ({ card }) => {
  // error handles both a JSON.parse error when uploading bad JSON
  // and GQL mutation error.
  const [error, setError] = useState()
  const pwContext = usePathwrightContext()
  const translationQuery = useQuery(TRANSLATION_QUERY)

  const {
    id: schoolTranslationId,
    school_translation: schoolTranslationJSON,
    master_translation: masterTranslationJSON
  } = get(translationQuery, "data.translation") || {}

  const [mutateSchoolTranslation, translationMutationState] = useMutation(
    TRANSLATION_MUTATION,
    {
      variables: {
        id: schoolTranslationId
      },
      refetchQueries: ["ContextQuery"]
    }
  )

  // Alternates between false and true when mutation is triggered and complete.
  const uploadSuccess =
    translationMutationState.called &&
    !translationMutationState.loading &&
    !translationMutationState.error

  // Potentially storing mutation error.
  const uploadError =
    translationMutationState.called &&
    !translationMutationState.loading &&
    translationMutationState.error

  // Handle mutation error.
  useEffect(() => {
    setError(translationMutationState.error || null)
  }, [translationMutationState.error])

  // Get school translation's filename either from current schoolTranslationJSON or generated
  // from school name.
  const getSchoolJSONFilename = () => {
    return (
      get(schoolTranslationJSON, "_filename") ||
      pwContext.school.name
        .toLowerCase()
        .split(" ")
        .join("-") + "-translation.json"
    )
  }

  const schoolJSONFilename = getSchoolJSONFilename()

  // Provides a filesData object in the expected shape for useFileInput.
  const schoolFilesData = useMemo(
    () => ({
      [schoolJSONFilename]: JSON.stringify(schoolTranslationJSON, null, 2)
    }),
    [schoolTranslationJSON]
  )

  // Handles setting parsed json in state and catching any parse errors.
  const handleChangeSchoolTranslation = translationData => {
    const [translationFilename, translationString] = Object.entries(
      translationData
    )[0]
    // Default the next translationJSON and error to null.
    let translationJSON = null
    let error = null

    if (translationString) {
      // Attempt to parse the supplied json and store any parse error in state.
      try {
        translationJSON = JSON.parse(translationString)
        error = null
      } catch (e) {
        error = `Error parsing the translation JSON: ${e}`
      }
    }

    if (error) {
      setError(error)
    } else if (translationJSON) {
      // Store uploaded file name and date in the translation JSON.
      translationJSON._filename = translationFilename
      translationJSON._last_updated_date = new Date().getTime()

      // Update the school's translation with the uploaded json.
      mutateSchoolTranslation({
        variables: {
          translation: translationJSON
        }
      })
    }
  }

  // Get upload and download utils from useFileInput which generates
  // a file input on the fly in the background when calling uploadFile
  const {
    uploadFile: uploadSchoolTranslation,
    downloadFile: downloadSchoolTranslation
  } = useFileInput({
    maxFiles: 1,
    accept: [".json"],
    onChange: handleChangeSchoolTranslation,
    // Supplying filesData in order to use downloadFile with current schoolJSONFilename.
    filesData: schoolFilesData
  })

  // Simply hide translation management card links when school
  // has no translation.
  if (!schoolTranslationJSON) return null

  // Grab last stored update date.
  const getLastStoredUpdateDate = () => {
    return schoolTranslationJSON._last_updated_date
      ? moment(schoolTranslationJSON._last_updated_date).format("MM/DD/YY")
      : null
  }

  const lastUpdatedDate = getLastStoredUpdateDate()

  const downloadMainTranslation = createDownloader(
    JSON.stringify(masterTranslationJSON, null, 2),
    "master-english-translation.json"
  )

  return (
    <Card title="Translation" card={card}>
      <CardLink
        icon="shuffle"
        label="Toggle translation keys"
        meta="Turn on/off i18n translation keys to help you locate translateable labels."
        to={`${window.location.pathname}toggle-translations/`}
      />
      <StyledCardLink
        icon="download"
        label="Download your current translation file"
        meta={
          lastUpdatedDate
            ? `Current file, ${schoolJSONFilename}, last updated on ${lastUpdatedDate}.`
            : `Current file, ${schoolJSONFilename}.`
        }
        onClick={() => downloadSchoolTranslation(schoolJSONFilename)}
      />
      <StyledCardLink
        icon="upload"
        label="Upload a new translation file"
        // Showing the error in the CardLink itself with dangerous red color.
        meta={error || "Replace the current file by uploading a new one."}
        dangerous={!!error}
        onClick={() => uploadSchoolTranslation()}
      />
      <StyledCardLink
        icon="download"
        label="Download the main (English) translation file"
        meta={`Consult the latest main JSON translation file as you translate.`}
        onClick={downloadMainTranslation}
      />
      {uploadSuccess ? (
        <PopupAlert
          success={`Uploaded translation file: ${schoolJSONFilename}`}
        />
      ) : uploadError ? (
        <PopupAlert success={uploadError} />
      ) : null}
    </Card>
  )
}

ManageTranslationCardLinks.displayName = "ManageTranslationCardLinks"

export default ManageTranslationCardLinks
