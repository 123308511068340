import gql from "graphql-tag"
import Page from "./page-fragment"

export default gql`
  mutation(
    $name: String!
    $title: String
    $page_type: Int
    $show_in_nav: Boolean = false
    $mode: String
  ) {
    createPage(
      name: $name
      title: $title
      page_type: $page_type
      show_in_nav: $show_in_nav
      mode: $mode
    ) {
      ...Page
    }
  }
  ${Page}
`
