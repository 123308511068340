// Pathwright variables. Allows for mapping strings to store data
// Example: PATHWRIGHT___REGISTER___REGISTRATION___TRANSACTION_ID -> window.App.getStore("register").getState().registration.transaction_id

export const mapVariable = function(variable) {
  // separate keys
  const list = variable.split("___").map(
    key =>
      // camelCase
      key[0].toLowerCase() +
      key
        .split("__")
        .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join("")
        .slice(1)
  )

  const storeKey = list[1]
  const keys = list.slice(2)
  const map = `App.getStore('${storeKey}').getState().${keys.join(".")}`

  return `(()=> {
    try {
      return ${map};
    } catch (err) {
      console.warn("App variable could not be mapped to: ${map}");
      return undefined;
    }
  })()`
}

export const parseVariable = str => str.match(/PATHWRIGHT___\w+\b/g)

export const injectVariable = function(str) {
  const variables = parseVariable(str)
  if (variables != null) {
    for (let variable of Array.from(variables)) {
      let value = mapVariable(variable)
      str = str.replace(variable, value)
    }
  }
  return str
}
