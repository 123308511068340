import React from "react"
import styled from "styled-components"
import { Motion, spring } from "react-motion"
import View from "@pathwright/ui/src/components/ui/View"
import Text from "@pathwright/ui/src/components/ui/Text"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Button from "@pathwright/ui/src/components/button/Button"

const ExpandIn = props => {
  return (
    <Motion
      defaultStyle={{ x: 0 }}
      style={{ x: spring(1, { stiffness: 300, damping: 20 }) }}
    >
      {interpolated => (
        <div
          {...props}
          style={{
            opacity: interpolated.x,
            transformOrigin: "50% 50%",
            msTransformOrigin: "50% 50%",
            WebkitTransformOrigin: "50% 50%",
            transform: `scale(${interpolated.x})`,
            msTransform: `scale(${interpolated.x})`,
            WebkitTransform: `scale(${interpolated.x})`,
            backgroundColor: "transparent"
          }}
        />
      )}
    </Motion>
  )
}

const StyledCard = styled(View.Primary)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  padding: 60px 30px;
  max-width: 400px;
  text-align: center;
`

const TeachGate = () => {
  return (
    <ExpandIn>
      <StyledCard rounded>
        <Text.H1 style={{ marginBottom: 20 }}>
          <Pathicon icon="rocket" />
        </Text.H1>
        <Text.H4>Activate to create new content</Text.H4>
        <Text.Body style={{ margin: "20px 0" }}>
          Ready to create new content? Just activate your account and you’ll be
          up and running in minutes.
        </Text.Body>
        <Button
          styleType="primary"
          size="large"
          to="/pricing/"
          utmSource="Teach Gate"
        >
          Activate Now <Pathicon icon="arrow-right" />
        </Button>
      </StyledCard>
    </ExpandIn>
  )
}

TeachGate.displayName = "TeachGate"

export default TeachGate
