
        
    const runtime = require("../../../../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("../../../lib/uniform/views/templates/uniform-macros.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
            const __nunjucks_template_import__filter_t = require("../../../nunjucks/t.js");
            __nunjucks_module_dependencies__.filters['t'] = {
                module: __nunjucks_template_import__filter_t && __nunjucks_template_import__filter_t.default || __nunjucks_template_import__filter_t,
                async: false
            };
    
        
            __nunjucks_module_dependencies__.templates["discussion/views/templates/discussion-response-form.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("lib/uniform/views/templates/uniform-macros.html", false, "discussion/views/templates/discussion-response-form.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("form", t_1);
output += "\n\n<pw-avatar\n  class=\"small\"\n  src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"profile")),"image_thumb"), env.opts.autoescape);
output += "\"\n  user-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"id"), env.opts.autoescape);
output += "\"\n  first-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"first_name"), env.opts.autoescape);
output += "\"\n  last-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"last_name"), env.opts.autoescape);
output += "\"></pw-avatar>\n\n<div class=\"pw-field text form-group response-form-prompt\">\n  <input type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.responses.form.answer_placeholder"), env.opts.autoescape);
output += "\" id=\"id_answer_prompt\" class=\"form-control fake-input\">\n</div>\n\n<div class=\"reponse-form-input response-body-text \" style=\"display: none\">\n  <div class=\"pw-uniform-table\">\n    <div data-row-span=\"1\">\n      <div data-field-span=\"1\">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "rich_text_enabled")) {
output += "\n        ";
output += runtime.suppressValue((lineno = 18, colno = 22, runtime.callWrap(runtime.memberLookup((t_1),"richtext"), "form[\"richtext\"]", context, ["response_body",{"required": true},runtime.contextOrFrameLookup(context, frame, "form_options")])), env.opts.autoescape);
output += "\n        ";
;
}
else {
output += "\n        ";
output += runtime.suppressValue((lineno = 20, colno = 22, runtime.callWrap(runtime.memberLookup((t_1),"textarea"), "form[\"textarea\"]", context, ["response_body",{"required": true},runtime.contextOrFrameLookup(context, frame, "form_options")])), env.opts.autoescape);
output += "\n        ";
;
}
output += "\n      </div>\n    </div>\n  </div>\n  <button type=\"submit\" class=\"ButtonLegacy medium rounded primary interactive inverted brand-color pw-form-submit\">\n    ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "response_action_label"), env.opts.autoescape);
output += "\n  </button>\n  <span class=\"pw-form-cancel\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.form.tech_question.or"), env.opts.autoescape);
output += " <a href=\"#\" class=\"cancel-response\">";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.form.tech_question.cancel"), env.opts.autoescape);
output += "</a></span>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "discussion/views/templates/discussion-response-form.html",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "discussion/views/templates/discussion-response-form.html",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["discussion/views/templates/discussion-response-form.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    