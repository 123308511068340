import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import PropTypes from "prop-types"
import React from "react"
import { Field, Form, Submit } from "react-connect-form-forked"
import ImagePicker from "../../media-manager/ImagePickerWithMM"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const ManageAccountCustomIcon = ({ card, initialValue, onSubmit }) => {
  const { school } = usePathwrightContext()
  return (
    <Card card={card} title={`${school.name} Custom Icon`}>
      <Form initialValue={initialValue} onSubmit={onSubmit}>
        <CardBlock>
          <Field
            name="icon"
            label="Upload a custom icon"
            instructions="Your icon shows up on your loading screen, as a favicon, and as a Homescreen icon when added on mobile. It should be square ratio and at least 100px by 100px. PNG or JPG files work best."
            topLabel
            alignCenter
            width={160}
            height={160}
            labelWidth={280}
            component={props => (
              <ImagePicker
                {...props}
                blocksContextKey={`custom-icon-${school.id}`}
              />
            )}
            apiKey="PKIcKga_QLKv1rkAdPQB"
            options={{ transformations: { crop: { aspectRatio: 1 / 1 } } }}
            required
          />
        </CardBlock>
        <Submit styleType="primary" component={SubmitButton}>
          Save
        </Submit>
      </Form>
    </Card>
  )
}

ManageAccountCustomIcon.displayName = "ManageAccountCustomIcon"

ManageAccountCustomIcon.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    icon: PropTypes.string
  })
}

export default ManageAccountCustomIcon
