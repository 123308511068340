import React, { Component, Children, useContext } from "react"
import PropTypes from "prop-types"
import {
  PathwrightContext,
  usePathwrightContext
} from "../pathwright/PathwrightContext"

export class CurrencyFormat extends Intl.NumberFormat {
  constructor(locale, options) {
    options.style = "currency"
    super(locale, options)
    this.currencyLabel = options.currency.toUpperCase()
  }

  get format() {
    return value =>
      isNaN(parseInt(value))
        ? value
        : super.format(value).indexOf("$") > -1 // only append currency label for "$" currencies to avoid being redundant
        ? `${super.format(value)} ${this.currencyLabel}`
        : super.format(value)
  }

  get formatRange() {
    return (startValue, endValue) =>
      `${
        isNaN(parseInt(startValue)) ? startValue : super.format(startValue)
      }-${this.format(endValue)}`
  }
}

export const CurrencyFormatterContext = React.createContext()

const CurrencyFormatterContainer = ({ children, school: schoolProp }) => {
  const { school: schoolContext } = usePathwrightContext()
  const school = schoolProp || schoolContext
  const { ietf_language_tag: locale, currency_code: currency } = school || {}

  const _getCurrencyFormatter = React.useCallback((locale, currency) => {
    if ((locale || currency) && !(locale && currency)) {
      console.warn(
        "CurrencyFormatterProvider.getCurrencyFormatter called with locale or currency but not both!"
      )
    }

    let formatter = null

    if (locale && currency) {
      try {
        formatter = new CurrencyFormat(locale, { currency })
      } catch (error) {
        console.error(
          `Failed to instantiate a CurrencyFormatter for "${locale}": ${error}`
        )
        // still provide currency formatter so we don't break everything
        formatter = new CurrencyFormat("EN-US", { currency: "USD" })
      }
    }

    return formatter
  }, [])

  const currentFormatter = React.useMemo(
    () => _getCurrencyFormatter(locale, currency),
    [locale, currency]
  )

  const getCurrencyFormatter = React.useCallback(
    (locale, currency) =>
      _getCurrencyFormatter(locale, currency) || currentFormatter,
    [currentFormatter]
  )

  return (
    <CurrencyFormatterContext.Provider
      value={{
        currencyFormatter: currentFormatter,
        getCurrencyFormatter
      }}
    >
      {Children.only(children)}
    </CurrencyFormatterContext.Provider>
  )
}

export const useCurrencyFormatter = () => {
  return useContext(CurrencyFormatterContext)
}

export const withCurrencyFormatter = Component => props => {
  const x = useCurrencyFormatter()
  return <Component {...x} {...props} />
}

export default CurrencyFormatterContainer
