import React from "react"
import styled from "styled-components"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { VelocityComponent } from "velocity-react"
import "velocity-animate"
import "velocity-animate/velocity.ui"

export const StyledWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex !important;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  .FieldWrapper__label {
    text-align: right;
  }
  ${media.max.phone`padding: 20px 10px;`};
`

export const StyledHeader = styled(StyledWrapper)`
  text-align: center;
`

export const StyledFooter = styled.footer`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  opacity: 0;

  button[type="submit"] {
    .SubmitButton__content .Pathicon {
      margin-left: 3px;
    }
  }

  > button:last-child {
    margin-top: 30px;
  }
`

export const AuthWrapper = ({ children }) => {
  if (!children) return null

  return (
    <VelocityComponent
      animation="transition.slideUpIn"
      duration={500}
      runOnMount
    >
      <StyledWrapper>{children}</StyledWrapper>
    </VelocityComponent>
  )
}

export const AuthFooter = ({ children }) => {
  if (!children) return null

  return (
    <VelocityComponent
      animation="transition.slideUpIn"
      duration={500}
      runOnMount
    >
      <StyledFooter>{children}</StyledFooter>
    </VelocityComponent>
  )
}
