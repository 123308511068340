import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import CurrencyFormatter from "@pathwright/web/src/modules/currency/CurrencyFormatter"
import {
  ENROLLMENT_ROLE_FACILITATOR,
  ENROLLMENT_ROLE_TEACHER,
  ROLE_TYPE_OFFERING
} from "@pathwright/web/src/modules/invitation/constants"
import SelectRole from "@pathwright/web/src/modules/invitation/SelectRole"
import {
  Checkbox,
  CheckboxGroup,
  ConnectForm,
  RadioButton,
  RadioGroup,
  TextInput,
  validate
} from "lib/core/components/forms"
import FieldWrapper from "lib/core/components/forms/form-utils/FieldWrapper"
import { ConnectStores } from "lib/core/store"
import omit from "lodash/omit"
import pluralize from "pluralize"
import { useRLOSubscriptionOptions } from "../utils"

const COST_OPTIONS = {
  SUBSCRIPTION_ONLY: 1,
  NO_CHARGE: 2,
  CHARGE: 3
}

const filterCreatorRegistrationRoleOptions = (type, role) => {
  if (type === ROLE_TYPE_OFFERING) {
    const creatorRegistratioRoleOptions = [
      ENROLLMENT_ROLE_FACILITATOR,
      ENROLLMENT_ROLE_TEACHER
    ]
    return creatorRegistratioRoleOptions.includes(role)
  }
  return false
}

const hasCost = fields => fields.cost_options.value === COST_OPTIONS.CHARGE
const hasDuration = fields => fields.duration_options.value === 1

const fieldsData = props => {
  const hasStripeAccount = (values, value) => {
    if (value > 0 && !props.canAcceptPayments)
      return "You must set up a Stripe account first."
  }

  return {
    cost_options: {
      labelWidth: 110,
      label: "Price per seat"
    },
    cost_per_seat: {
      type: "number",
      step: "any",
      minWidth: 30,
      labelWidth: 110,
      autoSize: true,
      inline: true,
      optional: true,
      validations: [
        validate.validateIf(hasCost, hasStripeAccount),
        validate.validateIf(hasCost, validate.isNumeric),
        validate.validateIf(
          hasCost,
          validate.isGreaterThanOrEqualTo(
            0.5,
            n => parseFloat(n).toFixed(2) /* parser */
          )
        )
      ]
    },
    include_creator_seat: {
      labelWidth: 110,
      label: "Cohort creator"
    },
    register_creator_as: {
      label: "Cohort creator role"
    },
    minimum_seat_count: {
      minWidth: 30,
      labelWidth: 110,
      autoSize: true,
      type: "number",
      optional: true,
      inline: true,
      validations: [
        validate.validateIf(hasCost, validate.isInt),
        validate.validateIf(hasCost, validate.isGreaterThanOrEqualTo(0))
      ]
    },
    duration_options: {
      label: "Access"
    },
    minimum_license_duration: {
      minWidth: 30,
      labelWidth: 110,
      autoSize: true,
      type: "number",
      inline: true,
      optional: true,
      validations: [
        validate.validateIf(hasDuration, validate.isNumeric),
        validate.validateIf(hasDuration, validate.isGreaterThanOrEqualTo(1))
      ]
    }
  }
}

const ManageLicensePricingForm = props => {
  const { fieldsData, isValid, handleSubmit, license_offering } = props
  const {
    cost_options,
    duration_options,
    cost_per_seat,
    minimum_seat_count,
    minimum_license_duration,
    include_creator_seat,
    register_creator_as
  } = fieldsData

  const { hasOptions, hasSelectedOptions } = useRLOSubscriptionOptions(
    license_offering
  )

  const handleRadioChange = radio => {
    cost_options.onChange(radio)

    switch (radio) {
      case COST_OPTIONS.SUBSCRIPTION_ONLY:
      case COST_OPTIONS.NO_CHARGE:
        // clear nested fields
        cost_per_seat.onChange(null)
        minimum_seat_count.onChange(null)
        break
      case COST_OPTIONS.CHARGE:
        // trigger onChange event to run validations
        cost_per_seat.onChange(cost_per_seat.value)
        break
    }
  }

  const handleDurationRadioChange = radio => {
    duration_options.onChange(radio)
    if (radio === 0) {
      // clear nested field
      minimum_license_duration.onChange(null)
    } else {
      // trigger onChange event to run validations
      minimum_license_duration.onChange(minimum_license_duration.value)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <SubmitButton
        onSubmit={handleSubmit}
        disabled={!isValid}
        styleType="primary"
        type="submit"
      >
        Save
      </SubmitButton>

      <RadioGroup {...cost_options} onChange={handleRadioChange}>
        {(hasOptions ||
          cost_options.value === COST_OPTIONS.SUBSCRIPTION_ONLY) && (
          <RadioButton
            value={COST_OPTIONS.SUBSCRIPTION_ONLY}
            disabled={!hasSelectedOptions}
          >
            Not available for non-subscribers.
          </RadioButton>
        )}
        <RadioButton value={COST_OPTIONS.NO_CHARGE}>No charge</RadioButton>
        <RadioButton value={COST_OPTIONS.CHARGE}>
          <div>
            <span>Charge </span>
            <CurrencyFormatter value={cost_per_seat.value || 0}>
              <TextInput {...cost_per_seat} />
            </CurrencyFormatter>
            <span>&nbsp;per seat with a minimum of&nbsp;</span>
            <TextInput {...minimum_seat_count} />
            <span>
              &nbsp;{pluralize("seat", parseInt(minimum_seat_count.value) || 2)}
            </span>
          </div>
        </RadioButton>
      </RadioGroup>

      {cost_per_seat.value > 0 && (
        <CheckboxGroup {...fieldsData.include_creator_seat}>
          <Checkbox value={true}>
            Include cohort creator seat at no extra charge
          </Checkbox>
        </CheckboxGroup>
      )}

      <FieldWrapper label={register_creator_as.label} fixedLabel>
        <SelectRole
          {...omit(register_creator_as, "label")}
          position="bottom"
          onChange={({ value }) => register_creator_as.onChange(value)}
          roleType={ROLE_TYPE_OFFERING}
          roleFilter={filterCreatorRegistrationRoleOptions}
        />
      </FieldWrapper>

      <RadioGroup {...duration_options} onChange={handleDurationRadioChange}>
        <RadioButton value={0}>Never expires</RadioButton>
        <RadioButton value={1}>
          <div>
            <span>Expires&nbsp;</span>
            <TextInput {...minimum_license_duration} />
            <span>
              &nbsp;{pluralize("day", minimum_license_duration.value)} after
              cohort is created
            </span>
          </div>
        </RadioButton>
      </RadioGroup>
    </form>
  )
}

ManageLicensePricingForm.displayName = "ManageLicensePricingForm"

const mapStateToProps = (state, ownProps) => {
  const { integrations, commerce, school } = state.schoolStore.state
  const { saveLicenseOffering } = state.resourceLicenseAdminStore.action
  const { license_offering } = state.resourceLicenseAdminStore.state
  const canAcceptPayments = integrations.stripe.publishable_key

  const duration =
    (license_offering &&
      license_offering.minimum_license_duration &&
      parseInt(
        license_offering.minimum_license_duration.split(" 00:00:00")[0]
      )) ||
    0
  let fieldValues = {}
  if (license_offering) {
    fieldValues = {
      cost_options:
        license_offering.cost_per_seat === -1
          ? COST_OPTIONS.SUBSCRIPTION_ONLY
          : license_offering.cost_per_seat > 0 ||
            license_offering.minimum_seat_count > 0
          ? COST_OPTIONS.CHARGE
          : COST_OPTIONS.NO_CHARGE,
      cost_per_seat:
        license_offering.cost_per_seat > 0
          ? license_offering.cost_per_seat
          : null,
      minimum_seat_count: license_offering.minimum_seat_count || null,
      duration_options: duration ? 1 : 0,
      minimum_license_duration: duration || null,
      // hack alert
      include_creator_seat: license_offering.include_creator_seat ? [true] : [],
      register_creator_as: license_offering.register_creator_as
    }
  }

  const onSubmit = (fieldsData, resetForm) => {
    // hack alert
    const include_creator_seat =
      fieldsData.include_creator_seat.value[0] || false

    const licenseData = {
      cost_per_seat:
        // backend understand a cost_per_seat of -1 to indicate subscription-only
        fieldsData.cost_options.value === COST_OPTIONS.SUBSCRIPTION_ONLY
          ? -1
          : fieldsData.cost_per_seat.value || 0,
      // make sure we don't include_creator_seat if the cost_per_seat isn't > 0
      include_creator_seat:
        include_creator_seat && fieldsData.cost_per_seat.value > 0,
      register_creator_as: fieldsData.register_creator_as.value,
      minimum_seat_count: fieldsData.minimum_seat_count.value || 0,
      minimum_license_duration: fieldsData.minimum_license_duration.value
        ? fieldsData.minimum_license_duration.value + " 00:00:00"
        : null
    }
    return saveLicenseOffering(licenseData)
  }

  return _.assign(
    {},
    {
      canAcceptPayments,
      onSubmit,
      fieldValues,
      license_offering
    },
    ownProps
  )
}

const stores = [
  { store: "school", watch: false },
  { store: "resourceLicenseAdmin", watch: ["license_offering"] },
  { store: "resourceAdmin", watch: ["resource_saving"] }
]

export default ConnectStores(
  ConnectForm(ManageLicensePricingForm, fieldsData),
  stores,
  {},
  mapStateToProps
)
