// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const { Collection } = Backbone
const Model = Backbone.Collection
const pluralize = require("pluralize")

rivets.adapters[":"] = {
  observe(obj, keypath, callback) {
    // Subscribes to the model->attribute change event
    return obj.on(`change:${keypath}`, callback)
  },

  unobserve(obj, keypath, callback) {
    // Unsubscribes from the model->attribute change event
    // Used when View.unbind() is called
    return obj.off(`change:${keypath}`, callback)
  },

  get(obj, keypath) {
    // Read a value from a model based on the watched key
    return obj.get(keypath)
  },

  set(obj, keypath, value) {
    // Write a value to a model based on a watched key
    return obj.set(keypath, value)
  }
}

rivets.binders.richtext = {
  publishes: true,

  bind(el) {
    return $(el).on("rt-changed", this.publish)
  },

  unbind(el) {
    return $(el).off("rt-changed", this.publish)
  },

  routine(el, value) {
    const code = $(el).redactor("code.get")
    if (Pathwright.utils.isCodeBlank(value)) {
      value = ""
    }
    // This currently causes issues with typing in the redactor field
    // value an code are not equal because of linebreaks in the html
    // if value != code
    //   $(el).redactor('code.set', value)
    // For now, will use:
    if (!code) {
      return $(el).redactor("code.set", value)
    }
  }
}

rivets.binders.pickadate = {
  publishes: true,

  bind(el) {
    const pickadate = $(this.el).pickadate("picker")
    return pickadate.on("set", this.publish)
  },

  unbind(el) {
    return $(this.el)
      .pickadate("picker")
      .stop()
  },

  routine(el, value) {
    const pickadate = $(el).pickadate("picker")
    if (!value && !pickadate.get()) {
      return
    }
    if (value !== pickadate.get()) {
      if (typeof value === "string") {
        value = moment(value).toDate()
      }
      if (value) {
        return pickadate.set("select", value)
      } else {
        return pickadate.set("clear")
      }
    }
  }
}

const liveValue = Object.create(rivets.binders.value)
liveValue.bind = function(el) {
  this.handler = this.handler || this.publish.bind(this)
  el.addEventListener("keyup", this.handler)
}

liveValue.unbind = function(el) {
  if (this.handler) {
    el.removeEventListener("keyup", this.handler)
  }
}

rivets.binders["live-value"] = liveValue

rivets.formatters.negate = function(value) {
  return !value
}
rivets.formatters.shortdate = function(value) {
  return moment(value).format("MMMM Do YYYY")
}
rivets.formatters.pluralize = function(count, input) {
  return pluralize(input, count)
}
rivets.formatters.yesno = function(isyes, label) {
  const [yesLabel, noLabel] = Array.from(label.split(","))
  if (isyes) {
    return yesLabel
  } else {
    return noLabel
  }
}
