import useIntersection from "@pathwright/ui/src/components/hooks/useIntersection"
import CountIndicator from "@pathwright/ui/src/components/indicator/CountIndicator"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import classnames from "classnames"
import gql from "graphql-tag"
import get from "lodash/get"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { inboxNavigatorItemPropType } from "./propTypes"

const Container = styled.div`
  position: relative;

  .CountIndicator {
    position: absolute;
    top: 0.1em;
    right: 0.1em;
    transform: translate(0.5em, -0.5em);
  }
`

const INBOX_PENDING_REVIEW_COUNT_QUERY = gql`
  query InboxPendingReviewCount(
    $targetId: Int!
    $targetType: InboxPendingReviewTarget!
  ) {
    inboxPendingReview(target_id: $targetId, target_type: $targetType) {
      id
      count
    }
  }
`

const useInboxPendingReviewCount = ({ targetType, targetId }, options = {}) => {
  const query = useQuery(INBOX_PENDING_REVIEW_COUNT_QUERY, {
    variables: {
      targetType,
      targetId
    },
    ...options
  })

  const count = get(query, "data.inboxPendingReview.count", null)
  return count
}

// Query count on intersection.
const useInboxPendingReviewCountOnIntersection = ({ targetType, targetId }) => {
  const [scrollNode, setScrollNode] = useState()
  const { setIntersectionNode, hasIntersected } = useIntersection({
    root: scrollNode
  })

  useEffect(() => {
    setScrollNode(document.querySelector(".InboxNavigator__items"))
  }, [])

  const count = useInboxPendingReviewCount(
    {
      targetType,
      targetId
    },
    {
      skip: !hasIntersected
    }
  )

  return { count, setIntersectionNode }
}

const InboxNavigatorItemReviewCount = ({ className, item }) => {
  const {
    count,
    setIntersectionNode
  } = useInboxPendingReviewCountOnIntersection({
    targetType: item.type,
    targetId: item.id
  })

  return (
    <Container
      className={classnames("InboxNavigatorItemReviewCount", className)}
      ref={setIntersectionNode}
    >
      <Pathicon icon="inbox" />
      {!!count && <CountIndicator count={count} />}
    </Container>
  )
}

InboxNavigatorItemReviewCount.displayName = "InboxNavigatorItemReviewCount"

InboxNavigatorItemReviewCount.propTypes = {
  item: inboxNavigatorItemPropType.isRequired
  // onSelect: PropTypes.func.isRequired
}

export default InboxNavigatorItemReviewCount
