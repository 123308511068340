import PropTypes from "prop-types"
import { forwardRef, useState, useRef, useEffect, memo } from "react"
import {
  ClosedCaptionButton,
  ControlBar,
  PlaybackRateMenuButton,
  Player as Video,
  VolumeMenuButton
} from "video-react"
import { isIPhone, isMobile } from "@pathwright/media-utils"
import "./css/video-react.css"
import DownloadButton from "./DownloadButton"
import HLSSource from "./HLSSource"
import { VideoWrapper } from "./ui"

const MAX_HEIGHT = 600

const propsAreEqual = (prevProps, nextProps) => {
  if (prevProps.coverImage !== nextProps.coverImage) return false
  if (prevProps.allowDownload !== nextProps.allowDownload) return false
  if (prevProps.src !== nextProps.src) return false
  if (prevProps.downloadURL !== nextProps.downloadURL) return false
  if (prevProps.subtitleTracks?.length !== nextProps.subtitleTracks?.length)
    return false

  return true
}

const VideoPlayer = memo(
  forwardRef((props, dimensionsRef) => {
    const {
      src,
      downloadURL,
      coverImage,
      height,
      width,
      type,
      allowDownload,
      subtitleTracks = [],
      showControls = true
    } = props

    const [videoLoaded, setVideoLoaded] = useState(false)

    const [isNativeHLS, setIsNativeHLS] = useState(false)
    const videoRef = useRef(null)
    const video = videoRef.current

    useEffect(() => {
      if (video && !isNativeHLS) {
        // Support streaming on iOS devices
        setIsNativeHLS(!!video.canPlayType("application/vnd.apple.mpegurl"))
      }
    }, [videoRef.current])

    const isStreamingVideo = (props) =>
      props.src &&
      (props.src.includes("video_playlist") || props.src.includes(".m3u8")) &&
      props.src !== props.downloadURL

    const calculateHeight = (naturalHeight, naturalWidth) =>
      Number(naturalHeight) >= MAX_HEIGHT ? MAX_HEIGHT : naturalHeight

    const isStreaming = isStreamingVideo(props)

    const calculatedHeight = calculateHeight(height, width)

    const iPhone = isIPhone()

    const controls = ["PlayPause"]

    if (!iPhone) {
      controls.push("Seek")
      controls.push("Time")
      controls.push("Volume")
      controls.push("Fullscreen")
    }

    return (
      <VideoWrapper
        className="VideoWrapper"
        videoHeight={height && width ? calculatedHeight : null}
        hideFullscreen={!!iPhone}
        hideControls={!showControls}
        ref={dimensionsRef}
      >
        <Video
          crossOrigin={"true"} // Necessary for hosted subtitle files to work
          playsInline={!iPhone}
          autoPlay={false}
          fluid={false}
          // maxHeight={calculatedHeight}
          width="100%"
          controls={controls}
          poster={coverImage}
          className="TheVideo"
          ref={videoRef}
          // This doesn't seem to have an effect
          preload="metadata"
          aspectRatio="auto"
          src={src}
          onLoadedData={() => {
            // A bit of a hack, but this forces a rerender,
            // which is necessary to load subtitles sometimes in some cases
            setVideoLoaded(true)
          }}
        >
          {isStreaming && !isNativeHLS ? (
            <HLSSource isVideoChild src={src} video={video} type={type} />
          ) : (
            <source src={src || downloadURL} />
          )}
          {subtitleTracks.length
            ? subtitleTracks.map((track, index) => {
                return (
                  <track
                    key={track.id}
                    src={`${track.src}?${Date.now()}`}
                    srcLang={track.language_code}
                    kind={track.closed_captions ? "captions" : "subtitles"}
                    label={track.name}
                    default={index === 0}
                  />
                )
              })
            : null}
          <ControlBar>
            <VolumeMenuButton order={2} vertical={isMobile()} />
            <PlaybackRateMenuButton
              rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
              order={7.1}
            />
            {subtitleTracks.length ? <ClosedCaptionButton order={8} /> : null}
            {allowDownload !== false && (
              <DownloadButton order={6.1} downloadURL={downloadURL} />
            )}
          </ControlBar>
        </Video>
      </VideoWrapper>
    )
  }),
  propsAreEqual
)

VideoPlayer.displayName = "VideoPlayer"

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string, // ex: mp4
  coverImage: PropTypes.string,
  downloadable: PropTypes.bool,
  showControls: PropTypes.bool
}

export default VideoPlayer
// const dimensionsOptions = {
//   elementResize: true,
//   className: "DimensionsContainer"
// }

// // Hack for working around error thrown in tests:
// // TypeError: Cannot read property 'requestAnimationFrame' of undefined
// // TODO: use our own size observer instead.
// const DimensionsTestContainer = options => Component => props => (
//   <Component {...props} />
// )

// // Appears actually requiring the react-dimensions is the source of the bug
// // so avoiding that when in test env.
// const DimensionsWrapper =
//   process.env.NODE_ENV === "test"
//     ? DimensionsTestContainer
//     : require("react-dimensions")

// export default DimensionsWrapper(dimensionsOptions)(VideoPlayer)
