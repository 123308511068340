import React from "react"
import { ConnectStores } from "lib/core/store"
import Card from "@pathwright/ui/src/components/card/Card"
import AuthorFormContainer from "./AuthorFormContainer"

const AuthorFormCard = ({ card, title, existingAuthor }) => (
  <Card title={title} card={card}>
    <AuthorFormContainer
      closeCard={card.onClose}
      onDirty={() => {
        if (existingAuthor) {
          return card.setCardDirty(true)
        }
      }}
      onPristine={() => {
        if (existingAuthor) {
          return card.setCardDirty(false)
        }
      }}
    />
  </Card>
)

AuthorFormCard.displayName = "AuthorFormCard"

const mapStateToProps = function(state, ownProps) {
  const { author } = state.authorAdminStore.state

  const existingAuthor = author != null ? author.id : undefined
  const title = existingAuthor
    ? author.display_name
    : author
    ? "New Author"
    : ""

  return _.assign(
    {},
    {
      title,
      existingAuthor
    },
    ownProps
  )
}

const stores = [{ store: "authorAdmin", watch: ["author"] }]

export default ConnectStores(AuthorFormCard, stores, {}, mapStateToProps)
