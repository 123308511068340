import React from "react"
import "./FieldHeader.css"

const FieldHeader = ({ title, children }) => (
  <header className="FieldHeader">
    {title ? <h6>{title}</h6> : undefined}
    {children}
  </header>
)

FieldHeader.displayName = "FieldHeader"

export default FieldHeader
