import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import OptionsButton from "@pathwright/ui/src/components/button/OptionsButton"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import CopyToClipboard from "react-copy-to-clipboard"
import "./InvitationControls.css"

class InvitationControls extends React.Component {
  constructor(props) {
    super(props)
    this.handleCopy = this.handleCopy.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.renderActionDropdown = this.renderActionDropdown.bind(this)
    this.renderCopyLinkDropdown = this.renderCopyLinkDropdown.bind(this)
    this.state = {
      linkMenu: false,
      copyStatus: "Copy",
      placement: "bottom"
    }
  }

  handleCopy() {
    this.setState({ copyStatus: "Copied!" })
    setTimeout(() => {
      this.setState({ copyStatus: "Copy" })
    }, 1000)
  }

  handleOpen(e) {
    const top = e.target && e.target.getBoundingClientRect().top
    const placement = top > window.innerHeight - 200 ? "top" : "bottom"
    if (placement !== this.state.placement) {
      this.setState({ placement })
    }
  }

  renderActionDropdown() {
    return (
      <DropdownMenu className="InvitationControls__action_menu">
        <DropdownItem onClick={this.props.onResend} closeOnClick>
          Resend invitation
        </DropdownItem>
        <DropdownItem onClick={this.props.onRevoke} closeOnClick>
          Revoke invitation
        </DropdownItem>
        <DropdownItem onClick={() => this.setState({ linkMenu: true })}>
          Copy invitation link
        </DropdownItem>
      </DropdownMenu>
    )
  }

  renderCopyLinkDropdown() {
    const { invitation_url, to_user, to_email } = this.props.invitation
    return (
      <DropdownMenu className="InvitationControls__link_menu">
        <div className="InvitationControls__link_prompt">
          <h5>
            {to_user && `${to_user.first_name}'s `}
            private invite link:
          </h5>
          <IconButton
            onClick={() => this.setState({ linkMenu: false })}
            icon="arrow-back"
            color="#aaa"
          />
        </div>
        <div className="InvitationControls__link_field">
          <input
            onFocus={e => e.target.select()}
            value={invitation_url}
            readOnly
          />
          <CopyToClipboard text={invitation_url} onCopy={this.handleCopy}>
            <Button>{this.state.copyStatus}</Button>
          </CopyToClipboard>
        </div>
      </DropdownMenu>
    )
  }

  render() {
    return (
      <DropdownTrigger
        className="InvitationControls"
        placement={this.state.placement}
        onHide={() => this.setState({ linkMenu: false })}
        renderDropdown={
          this.state.linkMenu
            ? this.renderCopyLinkDropdown
            : this.renderActionDropdown
        }
      >
        <OptionsButton styleType="tertiary" onClick={this.handleOpen} rotate />
      </DropdownTrigger>
    )
  }
}

InvitationControls.displayName = "InvitationControls"

export default InvitationControls
