// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let VentsBehavior;
export default (VentsBehavior = (function() {
  VentsBehavior = class VentsBehavior extends require("lib/static-shim").default(Marionette.Behavior) {
    static initClass() {
  
      `\
Auto binds window.App.vent listeners\
`;
  
      this.prototype.defaults = {};
    }

    initialize() {
      return (() => {
        const result = [];
        for (let vent in this.options) {
          let handler = this.options[vent];
          if (_.isString(handler)) {
            handler = this.view[handler];
          }
          if (_.isFunction(handler)) {
            handler = handler;
            result.push(this.view.listenTo(Pathwright.vent, vent, handler));
          } else {
            throw new Error(`Handler not found on view for vent \"${vent}\"`);
          }
        }
        return result;
      })();
    }
  };
  VentsBehavior.initClass();
  return VentsBehavior;
})());