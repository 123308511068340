import React from "react"
import { ConnectStores } from "lib/core/store"
import Card from "@pathwright/ui/src/components/card/Card"
import ResourceThemeFormContainer from "./ResourceThemeFormContainer"

const ResourceThemeCard = ({
  card,
  title,
  showStatus,
  showLeaveStatus,
  statusMessage,
  statusLeaveMessage
}) => (
  <Card
    title={title}
    card={card}
    showStatus={showStatus}
    showLeaveStatus={showLeaveStatus}
    statusMessage={statusMessage}
    statusLeaveMessage={statusLeaveMessage}
  >
    <ResourceThemeFormContainer
      card={card}
      onDirty={() => card.setCardDirty(true)}
      onPristine={() => card.setCardDirty(false)}
    />
  </Card>
)

ResourceThemeCard.displayName = "ResourceThemeCard"

const mapStateToProps = function(state, ownProps) {
  const {
    resource,
    resource_loading,
    resource_saving,
    resource_success
  } = state.resourceAdminStore.state

  const title = (resource && resource.name) || "Course"
  const showStatus = resource_loading || resource_saving
  const showLeaveStatus = resource_success != null
  const statusMessage = (() => {
    if (resource_loading) {
      return "Loading..."
    } else if (resource_saving) {
      return "Saving..."
    }
  })()
  const statusLeaveMessage = resource_success ? "Saved" : undefined

  return _.assign(
    {},
    {
      resource,
      title,
      showStatus,
      showLeaveStatus,
      statusMessage,
      statusLeaveMessage
    },
    ownProps
  )
}

const stores = [
  {
    store: "resourceAdmin",
    watch: [
      "resource",
      "resource_loading",
      "resource_saving",
      "resource_success"
    ]
  }
]

export default ConnectStores(ResourceThemeCard, stores, {}, mapStateToProps)
