import gql from "graphql-tag"

export default gql`
  mutation(
    $first_name: String!
    $last_name: String!
    $email: String!
    $password: String!
    $consent: Boolean
    $invite_token: String
  ) {
    signUp(
      first_name: $first_name
      last_name: $last_name
      email: $email
      password: $password
      consent: $consent
      invite_token: $invite_token
    ) {
      token
      user_id
      created
    }
  }
`
