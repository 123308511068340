import CustomEvent from "custom-event"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

// These history events provide a workaround for this issue:
// https://github.com/remix-run/history/issues/822#issuecomment-951953365

// Possibly use _historyEntries to track history entries and handle
// going back instead of pushing previous entry, but to fully implement
// would need to listen to history itslef and update _historyEntries.
const _historyEntries = []

// Dispatch a history event.
export const dispatchHistoryEvent = (action, route, state) => {
  const event = new CustomEvent("history", {
    detail: {
      action,
      route,
      state
    }
  })
  document.dispatchEvent(event)
}

// Execute a history event.
export const handleHistoryEvent = (history, event) => {
  const { action, route, state } = event.detail
  // const stringifiedHistory = JSON.stringify({ route, state })

  // if (action === "push") {
  //   // Check if we're attempting to push the previous route into
  //   // history, and if so, simply go back to previous route.
  //   const previousEntry = _historyEntries[_historyEntries.length - 2]
  //   console.log({ previousEntry, stringifiedHistory })
  //   if (previousEntry && previousEntry === stringifiedHistory) {
  //     console.log("going back")
  //     return history.goBack()
  //   } else {
  //     // Record new entry.
  //     _historyEntries.push(stringifiedHistory)
  //   }
  // }

  // Attempt to convert unrecognized query obj to search string.
  if (route && route.query && !route.search) {
    try {
      route.search = new URLSearchParams(route.query).toString()
    } catch {
      // noop
    }
  }

  return history[action](route, state)
}

// Hook up history event listener that will execute history events.
export const useHistoryEvent = () => {
  const history = useHistory()

  useEffect(() => {
    const handler = e => handleHistoryEvent(history, e)
    document.addEventListener("history", handler)
    return () => document.removeEventListener("history", handler)
  }, [])
}
