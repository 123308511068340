import React from "react"
import PropTypes from "prop-types"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import { HELP_LINK_DOMAIN } from "../../help/constants"
import FeatureLock from "../../lib/FeatureLock"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const ManageDomain = ({ card }) => {
  const { school } = usePathwrightContext()
  const hasCustomDomain = window.location.host.indexOf("pathwright") < 0
  return (
    <Card card={card} title="Custom Domain">
      <FeatureLock has="use-your-own-domain-name">
        {({
          hasFeatures,
          activationLocked,
          lockedFeatures,
          getPlansNameList
        }) =>
          !hasFeatures ? (
            <BlankSlate
              icon="ellipses-horizontal"
              heading="Strengthen your brand identity"
              body={
                <>
                  Link a custom domain you own to your account so your members
                  only see your name (e.g. courses.{school.subdomain}.com).{" "}
                  <a href={HELP_LINK_DOMAIN} target="_blank">
                    Learn more about setting up a custom domain.
                  </a>
                </>
              }
              meta={`Included in the ${getPlansNameList(
                lockedFeatures["use-your-own-domain-name"]
              )}`}
              primaryAction={{
                children: activationLocked ? "Activate" : "Upgrade Plan",
                to: "/pricing/"
              }}
            />
          ) : hasCustomDomain ? (
            <BlankSlate
              icon="ellipses-horizontal"
              heading="Your domain is linked"
              body={`You’re domain, ${window.location.host}, is linked to your account. Want to change your custom domain?`}
              primaryAction={{
                children: "Update custom domain",
                href: HELP_LINK_DOMAIN
              }}
            />
          ) : (
            <BlankSlate
              icon="ellipses-horizontal"
              heading="Let's setup a custom domain"
              body="Linking your domain to your Pathwright account is a bit techy, but we're here to help every step of the way. The button below will take you to a step-by-step guide."
              meta="(Once you complete the setup, we'll activate your domain)"
              primaryAction={{
                children: "Start setup",
                href: HELP_LINK_DOMAIN
              }}
            />
          )
        }
      </FeatureLock>
    </Card>
  )
}

ManageDomain.displayName = "ManageDomain"

ManageDomain.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired
}

export default ManageDomain
