import Button from "@pathwright/ui/src/components/button/Button"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import React from "react"
import styled from "styled-components"
import { useReviewContext } from "../state/ReviewContextProvider"
import useHasInboxItems from "../state/useHasInboxItems"
import { getFiltersList } from "./completion-filters"

export const getCompletionsFilerInfo = (filter, translator) => {
  switch (filter) {
    case "all":
      return {
        title: translator("Everything"),
        blankHeading: translator("No items found.")
      }
    case "pending":
      return {
        title: translator("Pending review"),
        blankHeading: translator("No items pending review.")
      }
    case "reviewed":
      return {
        title: translator("Already reviewed"),
        blankHeading: translator("No completions already reviewed.")
      }
    case "complete":
      return {
        title: translator("All completed items"),
        blankHeading: translator("No completed items found.")
      }

    case "incomplete":
      return {
        title: translator("All incomplete items"),
        blankHeading: translator("No incomplete items found.")
      }
    default:
      return {
        title: "",
        blankHeading: ""
      }
  }
}

const filters = getFiltersList()

// TODO: make this horizontally scroll?
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;

  label {
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
  }

  @media (max-width: 768px) {
    label {
      display: none;
    }
  }
`

const OrderByDropdown = () => {
  const { t } = useTranslate()

  const {
    context: { selectedFilter, order },
    actions: { filterContext }
  } = useReviewContext()

  const handleSelect = order => () => {
    filterContext(selectedFilter, order)
  }

  const selectedLabel = order === "asc" ? "Oldest" : "Newest"

  return (
    <DropdownContainer className="CompletionsDropdown">
      <label>by: </label>
      <DropdownTrigger
        renderDropdown={() => (
          <DropdownMenu closeOnClick={true}>
            <DropdownItem
              label={t("Newest")}
              onClick={handleSelect("desc")}
              active={order === "desc"}
            />
            <DropdownItem
              label={t("Oldest")}
              onClick={handleSelect("asc")}
              active={order === "asc"}
            />
          </DropdownMenu>
        )}
      >
        <Button size="small" styleType="secondary" inverted>
          {t(selectedLabel)} <Pathicon icon="chevron-down" />
        </Button>
      </DropdownTrigger>
    </DropdownContainer>
  )
}

const CompletionFiltersDropdown = () => {
  const {
    context: { selectedFilter },
    actions: { filterContext }
  } = useReviewContext()

  const { t } = useTranslate()
  const hasItems = useHasInboxItems()

  return (
    <DropdownContainer>
      <label>Show: </label>
      <DropdownTrigger
        renderDropdown={() => (
          <DropdownMenu closeOnClick={true}>
            {filters.map(filter => (
              <DropdownItem
                key={filter.key}
                label={getCompletionsFilerInfo(filter.key, t).title}
                onClick={() => filterContext(filter.key)}
                active={filter.key === selectedFilter}
              />
            ))}
          </DropdownMenu>
        )}
      >
        <Button
          size="small"
          styleType="secondary"
          invertPulse={!hasItems}
          inverted
        >
          {getCompletionsFilerInfo(selectedFilter, t).title}{" "}
          <Pathicon icon="chevron-down" />
        </Button>
      </DropdownTrigger>
    </DropdownContainer>
  )
}

const CompletionFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;

  label {
    font-size: 14px;
  }
`

const CompletionFilters = () => {
  return (
    <CompletionFiltersContainer className="CompletionFilters">
      <CompletionFiltersDropdown />
      <OrderByDropdown />
    </CompletionFiltersContainer>
  )
}

export default CompletionFilters
