import React from "react"
import "./Text.css"
import ReactDOM from "react-dom"
import classnames from "classnames"
import { routeToRelativeURL } from "lib/core/utils/urls"
import {
  getSchoolUrl,
  isRelativeUrl
} from "@pathwright/web/src/modules/utils/urls"
import marked from "marked"
import { renderMathInElement } from "./katex-auto-render"
import ExecuteScripts from "@pathwright/ui/src/components/embed/ExecuteScripts"
import { isHTMLEmpty, responsifyKatex } from "./utils"

const interceptLinks = function(el) {
  const links = $(el).find("a")
  for (let link of Array.from(links)) {
    var url = $(link).attr("href")
    const targetBlank = $(link).attr("target") === "_blank"
    if (!url) {
      return
    }
    if (url.indexOf("mailto:") > -1) {
      return
    }
    if (url.indexOf("#") === 0) {
      return
    }
    if (
      !targetBlank &&
      (isRelativeUrl(url) || url.indexOf(getSchoolUrl())) > -1
    ) {
      $(link).on("click", function(e) {
        e.preventDefault()
        e.stopPropagation()
        url = $(e.currentTarget).attr("href")
        return routeToRelativeURL(url)
      })
    }
  }
}

class Text extends React.Component {
  static displayName = "Text"

  componentDidMount() {
    const el = ReactDOM.findDOMNode(this)
    if (this.props.latex === true) {
      renderMathInElement(el, false)
      responsifyKatex(el)
    }
    // Enable relative school URLs
    if (this.props.interceptLink) {
      interceptLinks(el)
    }
  }

  componentWillUnmount() {
    const el = ReactDOM.findDOMNode(this)
    return $(el)
      .find("a")
      .off()
  }

  getHTML = () => {
    let { html, markdown, templateContext } = this.props
    if (markdown) {
      html = marked(html)
    }
    // TODO: drop nunjucks dependency? Use something smaller/lighter like underscore
    if (templateContext != null && _.isObject(templateContext)) {
      try {
        html = window.App.utils.renderString(html, templateContext)
      } catch (error) {
        console.error("Error rendering string from template", error)
      }
    }
    return html
  }

  render() {
    const { markdown, latex, theme, truncateAt } = this.props
    const className = classnames(
      "Text",
      {
        "Text--markdown": markdown,
        "Text--latex": latex,
        "Text--theme": theme,
        "Text-truncated": (truncateAt != null) > 0
      },
      this.props.className
    )

    return (
      <div className={className}>
        <ExecuteScripts html={this.getHTML()} />
      </div>
    )
  }
}

// Static helpers
Text.isHTMLEmpty = isHTMLEmpty

Text.displayName = "Text"
Text.defaultProps = {
  latex: false,
  markdown: false,
  theme: false,
  templateContext: null,
  executeScripts: true,
  truncateAt: null,
  interceptLink: false
}

export default Text
