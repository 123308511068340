import { capitalize } from "lodash"
import { defaultBlockStyles } from "./blockStylesConfig"
import { BlockType, BlockStyleType } from "../types"
import { ServerBlockTypeType } from "@pathwright/blocks-core"

export const getBlockTypeKey = (type: string): string => {
  // return the proper key for a block
  // TODO: how to get proper mutation key for block without Components index?
  // return Object.keys(BlockComponents).find(
  //   k => k.toLowerCase() === type.toLowerCase()
  // )
  return type
}

export const getBlockType = (
  type?: string,
  blockTypes?: ServerBlockTypeType[]
): ServerBlockTypeType | undefined => {
  return blockTypes?.find(bt => bt.type.toLowerCase() === type?.toLowerCase())
}

export const getBlockTypeLabel = ({
  type,
  blockTypes,
  layout
}: {
  type: string
  blockTypes: ServerBlockTypeType[]
  layout: string
}): string => {
  const BT = getBlockType(type, blockTypes)
  const BL = BT?.layouts
    ? Object.values(BT.layouts).find(bl => bl.key === layout)
    : null
  return BL ? BL.label : capitalize(type)
}

export const getStyles = (
  block: BlockType | null = null,
  blockStyles: BlockStyleType | null = null
): BlockStyleType => {
  let style = {
    ...defaultBlockStyles,
    ...(block && block.style ? block.style : {})
  }

  if (blockStyles) {
    style = { ...style, ...blockStyles }
  }

  // These shouldn't be saved on the server
  delete style.styleOptions
  delete style.__typename

  return style
}
