import "./Invitation.css"
import PropTypes from "prop-types"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Text from "@pathwright/ui/src/components/ui/Text"
import { withTranslate } from "@pathwright/ui/src/components/"
import {
  MEMBERSHIP_TYPE_STUDENT,
  MEMBERSHIP_TYPE_FACILITATOR,
  MEMBERSHIP_TYPE_EDITOR,
  MEMBERSHIP_TYPE_ADMIN,
  ROLE_TYPE_SCHOOL,
  ROLE_TYPE_OFFERING,
  ROLE_ACTIONS
} from "@pathwright/web/src/modules/invitation/constants"

const getInvitationAction = (invitation, t) => {
  if (invitation.type === ROLE_TYPE_OFFERING) {
    const action = ROLE_ACTIONS[ROLE_TYPE_OFFERING][invitation.role]
    return `${t("registration.join.to")} ${t(
      `registration.role_actions.${action}`
    )}`
  }
  if (invitation.type === ROLE_TYPE_SCHOOL) {
    switch (invitation.role) {
      case MEMBERSHIP_TYPE_STUDENT:
        return t("registration.as_member_of")
      case MEMBERSHIP_TYPE_FACILITATOR:
        return t("registration.as_facilitator_of")
      case MEMBERSHIP_TYPE_EDITOR:
        return t("registration.as_editor_of")
      case MEMBERSHIP_TYPE_ADMIN:
        return t("registration.as_admin_of")
    }
  }
}

const Invitation = ({ invitation, t }) => {
  if (!invitation) return null
  if (!invitation.role || !invitation.sender) return null
  return (
    <div className="Invitation">
      <Avatar
        className="Invitation__sender"
        user={invitation.sender}
        size="20px"
      />
      <Text.Body inverted className="Invitation__description">
        {invitation.sender.full_name} {t("registration.join.invited_you")}{" "}
        {getInvitationAction(invitation, t)}
      </Text.Body>
    </div>
  )
}

Invitation.displayName = "Invitation"

Invitation.propTypes = {
  invitation: PropTypes.shape({
    sender: PropTypes.object,
    role: PropTypes.number,
    type: PropTypes.number
  })
}

export default withTranslate(Invitation)
