import {
  ENROLLMENT_ROLE_EDITOR,
  ENROLLMENT_ROLE_FACILITATOR,
  ENROLLMENT_ROLE_OBSERVER,
  ENROLLMENT_ROLE_STUDENT,
  ENROLLMENT_ROLE_TEACHER,
  MEMBERSHIP_TYPE_ADMIN,
  MEMBERSHIP_TYPE_EDITOR,
  MEMBERSHIP_TYPE_STUDENT,
  ROLE_TYPE_OFFERING
} from "@pathwright/web/src/modules/invitation/constants"
import {
  INTERCOM_INVITE_EVENTS,
  INVITE_METHOD_EMAIL,
  INVITE_METHOD_LINK
} from "./constants"

// URL STUFF

export const getShareableInviteUrl = (type, role, offering) => {
  if (type === ROLE_TYPE_OFFERING) {
    const invite_urls =
      offering && offering.is_curriculum // assuming resource store not active
        ? offering.invite_urls
        : window.App.getStore("resource").request.getInviteURLs
        ? window.App.getStore("resource").request.getInviteURLs()
        : {}
    if (role === ENROLLMENT_ROLE_OBSERVER) {
      return invite_urls.observer
    }
    if (role === ENROLLMENT_ROLE_STUDENT) {
      return invite_urls.student
    }
    if (role === ENROLLMENT_ROLE_FACILITATOR) {
      return invite_urls.moderator
    }
    if (role === ENROLLMENT_ROLE_TEACHER) {
      return invite_urls.teacher
    }
    if (role === ENROLLMENT_ROLE_EDITOR) {
      return invite_urls.editor
    }
  }
  const { school } = window.App.getStore("school").getState()
  if (role === MEMBERSHIP_TYPE_STUDENT) {
    return school.invite_url_student
  }
  if (role === MEMBERSHIP_TYPE_EDITOR) {
    return school.invite_url_editor
  }
  if (role === MEMBERSHIP_TYPE_ADMIN) {
    return school.invite_url_admin
  }
}

export const getInviteMethodUrl = (offering, method, role) => {
  let url = "/invite/"
  if (offering) {
    url = `${offering.url}invite/`
  }
  url += `?method=${method}`
  if (role) {
    url += `&role=${role}`
  }
  return url
}

export const getAltInviteMethod = method => {
  return method === INVITE_METHOD_EMAIL
    ? INVITE_METHOD_LINK
    : INVITE_METHOD_EMAIL
}

export const getLookupPlaceholder = (type, searchEnabled) => {
  if (type === ROLE_TYPE_OFFERING) {
    if (searchEnabled) {
      return "Enter name or email"
    }
    return "Enter email address"
  }
  return "Enter or paste emails"
}

export const trackIntercomEvent = (type, role, metadata) => {
  if (window.Intercom) {
    const intercomInviteEvent = INTERCOM_INVITE_EVENTS[type][role]
    window.Intercom("trackEvent", intercomInviteEvent, metadata || {})
  }
}
