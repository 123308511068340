import { ConnectStores } from "lib/core/store"
import Card from "@pathwright/ui/src/components/card/Card"
import ManageLicenseForm from "./ManageLicenseForm"
import ManageLicenseLocked from "./ManageLicenseLocked"

const ManageLicenseCard = props => {
  const { card, location, is_activated, resource_licensing_enabled } = props

  return (
    <Card title="Member-created cohorts" card={card}>
      {resource_licensing_enabled ? (
        <ManageLicenseForm
          pathname={location.pathname}
          onDirty={() => card.setCardDirty(true)}
          onPristine={() => card.setCardDirty(false)}
        />
      ) : (
        <ManageLicenseLocked is_activated={is_activated} />
      )}
    </Card>
  )
}

ManageLicenseCard.displayName = "ManageLicenseCard"

const mapStateToProps = (state, ownProps) => {
  const { is_activated } = state.pathwrightAccountStore.state
  const { resource_licensing_enabled } = state.schoolStore.state.school

  return _.assign(
    {},
    {
      is_activated,
      resource_licensing_enabled
    },
    ownProps
  )
}

const stores = [
  { store: "school", watch: ["school"] },
  { store: "pathwrightAccount", watch: ["is_activated"] }
]

export default ConnectStores(ManageLicenseCard, stores, {}, mapStateToProps)
