import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import AttachmentToolbarOption from "./AttachmentToolbarOption"
import ColorToolbarOption from "./ColorToolbarOption"

const Container = styled.div`
  &.ql-toolbar {
    font-size: 16px;
    line-height: 18px;
  }

  /* hide any formats span that contains no toolbar options */
  &.ql-toolbar span.ql-formats:empty {
    display: none;
  }

  &.ql-toolbar.ql-snow {
    background-color: white;
    position: sticky;
    top: 0;
  }

  &.ql-bubble .ql-formats {
    margin: 5px 8px 5px 0px;
  }

  /* resetting general button styles */
  button {
    color: none;
    background-color: none;
    background: none;
  }

  /* explicitly setting button color and background (same colors the themes actually use) */
  /* this ensures custom buttons are colored the same as the themes apply fills to the SVGs */

  &.ql-snow button {
    color: #444;

    &:hover {
      color: #06c;
    }
  }

  &.ql-bubble button {
    color: #ccc;

    &:hover {
      color: #fff;
    }
  }
`

// Manually assign text colors b/c by default "black" is simply "reset to default"
const colors = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466"
]

export const TOOLBAR_CONFIG_TYPES = {
  SIMPLE: 1,
  BASIC: 2,
  ADVANCED: 3,
  FULL: 4
}

// Removed formula Sept. 2019 - KaTeX is handled separately
// and sub/super script are discreet tools now.
export const TOOLBAR_CONFIGS = {}
// SIMPLE
TOOLBAR_CONFIGS[TOOLBAR_CONFIG_TYPES.SIMPLE] = [
  "bold",
  "italic",
  "underline",
  "link",
  "code-block"
]
// BASIC
TOOLBAR_CONFIGS[TOOLBAR_CONFIG_TYPES.BASIC] = [
  ...TOOLBAR_CONFIGS[TOOLBAR_CONFIG_TYPES.SIMPLE],
  { list: "ordered" },
  { list: "bullet" },
  { color: colors },
  { background: [] },
  { script: "sub" },
  { script: "super" },
  "clean"
]
// ADVANCED
TOOLBAR_CONFIGS[TOOLBAR_CONFIG_TYPES.ADVANCED] = [
  ...TOOLBAR_CONFIGS[TOOLBAR_CONFIG_TYPES.BASIC],
  { header: [1, 2, 3, false] },
  { align: [] }
]
// FULL
TOOLBAR_CONFIGS[TOOLBAR_CONFIG_TYPES.FULL] = [
  ...TOOLBAR_CONFIGS[TOOLBAR_CONFIG_TYPES.ADVANCED],
  "image",
  "attachment",
  "video"
]

// standard toolbar shape that all configs will be structured by
const toolbarShape = [
  [{ header: [] }],
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }],
  ["link", "code-block"],
  ["image", "attachment", "video"],
  ["clean"]
]

// util for merging toolbar configs right to left
export const mergeToolbarConfigs = ({
  baseConfig = [],
  config = [],
  excludedOptions = []
}) => {
  // filter out config options in baseConfig that are found in config
  const filteredBaseConfig = baseConfig.filter(
    configAOption =>
      !config.find(configBOption =>
        hasMatchingToolbarOption(configAOption, configBOption)
      )
  )

  const mergedConfig = [...filteredBaseConfig, ...config].filter(option => {
    const [optionKey] = getToolbarOption(option)
    return !excludedOptions.includes(optionKey)
  })

  return mergedConfig
}

const getToolbarOption = option => {
  // define with undefined values as an explicitly null value means
  // this option is to not be included in the toolbar!
  let key, value

  if (option != null) {
    if (typeof option === "string") key = option
    if (typeof option === "object") {
      ;[key, value] = Object.entries(option)[0]
    }
  }

  return [key, value]
}

// util for finding a matching toolbar option
const hasMatchingToolbarOption = (config, option) => {
  if (Array.isArray(config)) {
    // recursively find matching toolbar option
    return !!config.find(config => hasMatchingToolbarOption(config, option))
  }

  const [configKey, configValue] = getToolbarOption(config)
  const [optionKey, optionValue] = getToolbarOption(option)

  // same key
  if (configKey === optionKey) {
    // values of same type
    if (typeof configValue === typeof optionValue) {
      // if values are strings, they must match
      if (typeof configValue === "string") return configValue === optionValue
      // otherwise, matching the key and type is good enough
      return true
    }
  }
}

const customToolbarOptions = {
  attachment: AttachmentToolbarOption,
  color: props => <ColorToolbarOption {...props} type="color" />,
  background: props => <ColorToolbarOption {...props} type="background" />
}

// QuillToolbar handles rendering the desired toolbar config in the
// expected toolbarShape with any custom toolbar options
const QuillToolbar = React.forwardRef(
  ({ theme, config, useAdvancedColorPicker }, ref) => {
    if (!config || !config.length) return null

    return (
      <Container
        ref={ref}
        className={classnames("QuillToolbar", `ql-${theme}`)}
        // Prevent this known issue: https://github.com/quilljs/quill/issues/1290
        onMouseDown={theme === "bubble" ? e => e.preventDefault() : undefined}
      >
        {toolbarShape
          .filter(format =>
            // ensure the format contains at least one of the config options
            config.find(option => hasMatchingToolbarOption(format, option))
          )
          .map((format, i) => (
            <span key={i} className="ql-formats">
              {format.map(formatOption => {
                // get the corresponding option in the config
                const option = config.find(option =>
                  hasMatchingToolbarOption(formatOption, option)
                )
                // ensure the formatOption matches at least one of the config options
                if (!option) return null

                const [optionKey, optionValue] = getToolbarOption(option)

                const isColorOption =
                  optionKey === "color" || optionKey === "background"

                // render custom toolbar option if there is one
                const CustomOptionComponent = customToolbarOptions[optionKey]
                if (
                  CustomOptionComponent &&
                  (!isColorOption || (isColorOption && useAdvancedColorPicker))
                ) {
                  return (
                    <CustomOptionComponent
                      key={optionKey}
                      options={optionValue}
                    />
                  )
                }

                if (Array.isArray(optionValue)) {
                  // optionValue is an array, this is a select with multiple options
                  return (
                    <select
                      key={optionKey}
                      className={`ql-${optionKey}`}
                      defaultValue={""}
                      onChange={e => e.persist()}
                    >
                      {optionValue.map((value, i) =>
                        value ? (
                          <option key={i} value={value} />
                        ) : (
                          <option key={i} />
                        )
                      )}
                    </select>
                  )
                }

                // optionValue is a nullish or string, this is a simple button
                return (
                  <button
                    key={optionKey + (optionValue || "")}
                    className={`ql-${optionKey}`}
                    value={optionValue || null}
                  />
                )
              })}
            </span>
          ))}
      </Container>
    )
  }
)

QuillToolbar.displayName = "QuillToolbar"

QuillToolbar.propTypes = {
  config: PropTypes.array
}

export default QuillToolbar
