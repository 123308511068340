import Alert from "@pathwright/ui/src/components/alert/Alert"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import FieldWrapper from "@pathwright/ui/src/components/form/form-utils/FieldWrapper"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import classnames from "classnames"
import get from "lodash/get"
import isFunction from "lodash/isFunction"
import partial from "lodash/partial"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { Form, Submit } from "react-connect-form-forked"
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider
} from "react-stripe-elements"
import styled from "styled-components"
import {
  PathwrightContext,
  usePathwrightContext
} from "../pathwright/PathwrightContext"
import SCHOOL_QUERY from "../school/graphql/school-query"
import DiscountForm from "./DiscountForm"
import PaymentMaintenanceMode from "./PaymentMaintenanceMode"

// TODO: add styles https://stripe.com/docs/stripe.js#element-options

const tPrefix = "payments.elements_form"

const StyledPromptButton = styled(Button)`
  align-self: flex-end;
`

const DiscountPrompt = withTranslate(({ prompt, t, ...rest }) => (
  <StyledPromptButton {...rest} styleType="blank">
    {prompt ? (
      t(`${tPrefix}.have_a_coupon`)
    ) : (
      <>
        <Pathicon icon="arrow-left" />
        <span> </span>
        {t(`${tPrefix}.dont_have_coupon`)}
      </>
    )}
  </StyledPromptButton>
))

const MIN_HEIGHT = "150px"

const StyledForm = styled.form`
  min-height: ${MIN_HEIGHT};
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const StyledCardElement = styled(CardElement)`
  flex-grow: 1;
  width: 100%;
  margin-bottom: 10px;
`

const StyledFields = styled.div`
  width: 100%;
`

const StyledFooter = styled.footer`
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  &:empty {
    display: none;
  }
`

const StyledBottomBorder = styled.div`
  border-bottom: 1px solid rgba(222, 221, 221, 0.8);
`

const StyledFieldWrapper = styled(FieldWrapper)`
  .pathicon-caution-triangle {
    display: none;
  }
`

const LoadingCircleContainer = styled.div`
  min-height: ${MIN_HEIGHT};
  position: relative;
`

const ShowHideDiv = styled.div`
  ${props => `display: ${props.show ? "block" : "none"};`};
`

const funcWrapper = possibleFunc => value =>
  isFunction(possibleFunc) ? possibleFunc(value) : possibleFunc

const FORM_ERROR = "formError"
const DISCOUNT_ERROR = "discountError"
const CARD_ERROR = "cardError"

class StripeElementsForm extends Component {
  state = {
    cardIsValid: false,
    discount: null,
    showDiscountForm: false,
    submitting: false
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  handleChange = ({ brand, complete, empty, error, value }) => {
    if (complete != this.state.cardIsValid) {
      this.setState({
        cardIsValid: complete
      })
    }
    if (error) {
      this.handleError(CARD_ERROR, error.message)
    } else {
      this.handleError(CARD_ERROR, null)
    }
  }

  handleError = (key, error) => {
    this.setState({
      [key]: error && error.message ? error.message : error
    })
  }

  handleDiscount = discount => {
    this.setState({
      discount,
      showDiscountForm: false,
      [DISCOUNT_ERROR]: null
    })
  }

  toggleDiscountForm = () => {
    this.setState({
      showDiscountForm: !this.state.showDiscountForm
    })
  }

  get discountMessage() {
    if (typeof this.props.discountMessage === "string") {
      return this.props.discountMessage
    }

    const { discount } = this.state
    if (discount) {
      return typeof discount === "string"
        ? discount
        : discount.discountMessage
        ? discount.discountMessage
        : !!discount.discount_perc &&
          !!discount.discounted_total_currency_display
        ? ` ${this.props.t(`${tPrefix}.discount_applied`, {
            perc: discount.discount_perc * 100,
            newTotal: discount.discounted_total_currency_display
          })}.`
        : null
    }

    return null
  }

  get userData() {
    const name = get(this.context.me, "full_name") || null
    return name ? { name } : {}
  }

  handleSubmitSuccess = () => {
    // letting submit success animation finish
    setTimeout(() => {
      if (!this.willUnmount && this.element) {
        try {
          this.element.clear()
        } catch (error) {
          // the elemnt may have already been destroyed
        }
      }
    }, 800)
  }

  handleSubmit = async value => {
    if (this.state.submitting) return

    try {
      this.setState({ submitting: true })
      const shouldProcessPayment = funcWrapper(this.props.processPayment)(value)
      if (shouldProcessPayment) {
        const { token, error } = await this.props.stripe.createToken(
          this.userData
        )
        if (token) {
          await this.props.onToken(token.id, value, this.state.discount)
        } else {
          throw new Error(error)
        }
      } else {
        await this.props.onToken(null, value, this.state.discount)
      }
    } catch (error) {
      this.handleError(FORM_ERROR, error.message)
    } finally {
      this.setState({ submitting: false })
    }
  }

  handleFormOnSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    return this.button && this.button.click()
  }

  render() {
    const {
      onSubmitDiscount,
      discountSearchParam,
      title,
      heading,
      hidePostalCode,
      submitColor,
      className,
      t
    } = this.props

    const children = funcWrapper(this.props.children)
    const processPayment = funcWrapper(this.props.processPayment)
    const submitLabel = funcWrapper(
      this.props.submitLabel || t("registration.stripe.save")
    )

    return (
      <Form
        onSubmit={this.handleSubmit}
        onSubmitSuccess={this.handleSubmitSuccess}
        onSubmitFailure={partial(this.handleError, FORM_ERROR, null)}
        render={formState => {
          const { pristine, touched, valid, value } = formState
          return (
            <StyledForm
              onSubmit={this.handleFormOnSubmit}
              className={classnames(className, "StripeElementsForm", {
                "StripeElementsForm__process-payment": processPayment(value)
              })}
            >
              <Alert success={this.discountMessage} />
              <Alert
                error={this.state[FORM_ERROR]}
                onClear={partial(this.handleError, FORM_ERROR, null)}
              />
              <Alert
                error={this.state[DISCOUNT_ERROR]}
                onClear={partial(this.handleError, DISCOUNT_ERROR, null)}
              />
              <StyledFields>{children(value)}</StyledFields>
              {processPayment(value) &&
                onSubmitDiscount &&
                !this.state.discount && (
                  <DiscountPrompt
                    onClick={this.toggleDiscountForm}
                    prompt={!this.state.showDiscountForm}
                    t={t}
                  />
                )}
              <StyledFooter>
                {get(
                  this.context.platformConfig,
                  "payments_maintenance_mode"
                ) ? (
                  <PaymentMaintenanceMode />
                ) : (
                  <React.Fragment>
                    <ShowHideDiv show={this.state.showDiscountForm}>
                      <DiscountForm
                        onSubmit={code =>
                          onSubmitDiscount(code, formState.value)
                        }
                        searchParam={discountSearchParam}
                        onDiscount={this.handleDiscount}
                        onError={partial(this.handleError, DISCOUNT_ERROR)}
                      />
                    </ShowHideDiv>
                    <ShowHideDiv show={!this.state.showDiscountForm}>
                      {processPayment(value) && (
                        <Fieldset title={title} heading={heading}>
                          <StyledFieldWrapper
                            errors={
                              this.state[CARD_ERROR]
                                ? [this.state[CARD_ERROR]]
                                : []
                            }
                          >
                            <StyledBottomBorder>
                              <StyledCardElement
                                onReady={el => (this.element = el)}
                                onChange={this.handleChange}
                                hidePostalCode={hidePostalCode}
                                style={{ base: { fontSize: "16px" } }}
                              />
                            </StyledBottomBorder>
                          </StyledFieldWrapper>
                        </Fieldset>
                      )}
                      <Submit
                        color={submitColor}
                        size="large"
                        styleType="primary"
                        component={SubmitButton}
                        // best we can do ATM for showing error state in SubmitButton
                        submitFailure={
                          !!this.state[CARD_ERROR] ||
                          !!this.state[FORM_ERROR] ||
                          !!this.state[DISCOUNT_ERROR]
                        }
                        buttonRef={ref => (this.button = ref)}
                        onClick={e => {
                          e.preventDefault()
                        }}
                        disabled={
                          (Object.keys(value || {}).length && !valid) ||
                          (processPayment(value) && !this.state.cardIsValid)
                        }
                      >
                        {submitLabel(value)}
                      </Submit>
                    </ShowHideDiv>
                  </React.Fragment>
                )}
              </StyledFooter>
            </StyledForm>
          )
        }}
      />
    )
  }
}

StripeElementsForm.contextType = PathwrightContext

StripeElementsForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  title: PropTypes.string,
  heading: PropTypes.string,
  hidePostalCode: PropTypes.bool,
  onToken: PropTypes.func.isRequired,
  onSubmitDiscount: PropTypes.func,
  discountMessage: PropTypes.string,
  processPayment: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  submitColor: PropTypes.string // TODO: merge submit props
}

StripeElementsForm.defaultProps = {
  hidePostalCode: false,
  processPayment: true,
  footerStyles: "",
  title: "",
  heading: ""
}

const StripeElementsFormWithStripe = withTranslate(
  injectStripe(StripeElementsForm)
)

const StripeElementsFormContainer = ({
  apiKey,
  schoolId,
  hidePostalCode,
  ...elementsProps
}) => {
  const { translation, school } = usePathwrightContext()

  const schoolQuery = useQuery(SCHOOL_QUERY, {
    variables: {
      id: schoolId
    },
    skip: !!apiKey || !schoolId
  })

  // scope stripe to school based on schoolId if provided
  if (schoolQuery.data) {
    apiKey = schoolQuery.data.context.school.stripe_publishable_api_key
    hidePostalCode =
      hidePostalCode ||
      !schoolQuery.data.context.school.payment_requires_zip_code
  }

  // pass neither apiKey nor schoolId to scope stripe to current school
  if (!apiKey && !schoolId) {
    apiKey = school.stripe_publishable_api_key
    hidePostalCode = hidePostalCode || school.payment_requires_zip_code
  }

  if (schoolQuery.loading) {
    // Still loading apiKey. Initializing the StripeProvider without an apiKey will prevent subsequent rendering
    // with an apiKey from successfulling rendering the CardElement.
    return (
      <LoadingCircleContainer>
        <LoadingCircle />
      </LoadingCircleContainer>
    )
  }
  // If there is no Stripe API key then pass null for the Stripe instance to avoid error
  const stripeProps = apiKey ? { apiKey } : { stripe: null }
  return (
    <StripeProvider {...stripeProps} locale={translation.language}>
      <Elements locale={translation.language}>
        <StripeElementsFormWithStripe
          {...elementsProps}
          hidePostalCode={hidePostalCode}
        />
      </Elements>
    </StripeProvider>
  )
}

StripeElementsFormContainer.displayName = "StripeElementsFormContainer"

StripeElementsFormContainer.propTypes = {
  schoolId: PropTypes.number, // scope stripe to school
  apiKy: PropTypes.string // score stripe to api key
}

export default withTranslate(StripeElementsFormContainer)
