import React from "react"
import StepActivity from "./StepActivity"

const SkipStepActivity = props => {
  return <StepActivity {...props} icon="skip" />
}

SkipStepActivity.displayName = "SkipStepActivity"

export default SkipStepActivity
