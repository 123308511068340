import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Motion, spring } from "react-motion"
import BlankSlate from "../blank/BlankSlate"
import Fullscreen, { FULLSCREEN_LAYER_OVERLAY } from "../fullscreen/Fullscreen"
import { media } from "../utils/styles"

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
`

const StyledSuccess = styled.div`
  max-width: min-content;
  min-width: 300px;
  margin-top: 100px;

  > .BlankSlate {
    padding-left: 40px;
    padding-right: 40px;
  }

  ${media.max.phone`
    margin: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;

    .BlankSlate {
      height: 100%;
    }
  `}
`

const Success = ({
  children,
  hideModal,
  background,
  onBackgroundClick,
  ...passProps
}) => {
  const [animated, setAnimated] = useState(false)
  // HACK ALERT: assumption is that any time the success screen is shown, we want to hide all modals
  useEffect(() => {
    if (hideModal) {
      const modalNodes = document.querySelectorAll(".Modal")
      if (modalNodes.length) {
        modalNodes.forEach(modalNode => (modalNode.style.display = "none"))
      }
      return () => {
        if (modalNodes.length) {
          modalNodes.forEach(modalNode => (modalNode.style.display = "block")) // assuming "block" is fine
        }
      }
    }
  }, [])

  return (
    <Fullscreen
      layer={FULLSCREEN_LAYER_OVERLAY}
      background={background}
      onClickBackground={onBackgroundClick}
    >
      <StyledWrapper>
        <Motion
          defaultStyle={{ x: 0 }}
          style={{
            x: spring(1, {
              stiffness: 300,
              damping: 20
            })
          }}
          onRest={() => setAnimated(true)}
        >
          {interpolated => {
            const style = {
              opacity: interpolated.x,
              transformOrigin: "50% 50%",
              transform: `scale(${interpolated.x})`
            }
            return (
              <StyledSuccess style={style}>
                <BlankSlate
                  animateIcon
                  inverted
                  stack
                  theme
                  icons={null}
                  {...passProps}
                  // HACK ALERT: workaround for issue with LoadingCirle displaying as a gradient square, somehow related to the scale transform.
                  // Stopgap fix is to prevent the LoadingCircle from beginning animation until Success component is done animating.
                  primaryAction={
                    animated ||
                    (passProps.primaryAction &&
                      !passProps.primaryAction.submitting)
                      ? passProps.primaryAction
                      : null
                  }
                >
                  {children}
                </BlankSlate>
              </StyledSuccess>
            )
          }}
        </Motion>
      </StyledWrapper>
    </Fullscreen>
  )
}

Success.displayName = "Success"

Success.propTypes = {
  hideModal: PropTypes.bool,
  background: PropTypes.object
}

Success.defaultProps = {
  hideModal: true,
  background: {
    overlay: "rgba(0,0,0,.6)"
  }
}

export default Success
