import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import gql from "graphql-tag"
import Link from "@pathwright/ui/src/components/link/Link"
import Image from "@pathwright/ui/src/components/image/Image"
import { getStoreResourceUrl } from "../utils/urls"

const StyledThumbnail = styled(Link)`
  z-index: 1;
  position: absolute;
  width: 30px;
  top: -7px;
  left: -7px;
  pointer-events: auto;
  img {
    outline: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    width: 30px;
    height: 22.5px;
  }
`

const ResourceThumbnail = ({ resource }) => {
  return (
    <StyledThumbnail to={getStoreResourceUrl(resource.slug)}>
      <Image src={resource.image} alt="thumbnail" />
    </StyledThumbnail>
  )
}

ResourceThumbnail.displayName = "ResourceThumbnail"

ResourceThumbnail.fragments = {
  resourceThumbnail: gql`
    fragment ResourceThumbnail on Resource {
      name
      slug
      image(width: 60, height: 45, fit: clamp)
    }
  `
}

export default ResourceThumbnail
