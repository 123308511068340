import { HStack, VStack } from "@chakra-ui/react"
import { Formik } from "formik"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toFormikValidate } from "zod-formik-adapter"
import { useAuthRequestTokenQuery } from "../../../../api/generated"
import { InputField } from "../../../../components/form/Field"
import { ActionType } from "../../../../lib/types"
import {
  getAuthenticationToken,
  handleAuthToken
} from "../../../../lib/utils/auth-token"
import AuthFormAlert from "../../components/AuthFormAlert"
import AuthFormButton from "../../components/AuthFormButton"
import getAuthRoute from "../../utils/getAuthRoute"
import { schema } from "./api/action"
import loader from "./api/loader"
import { useScopedTranslation } from "../../../../lib/lng/TranslationsProvider"

const validate = toFormikValidate(schema)

// Automatically handle auth token on the device that started the auth request.
// These means we poll the auth request until a token is found.
const useAuthRequestToken = (authRequestId: string) => {
  const query = useAuthRequestTokenQuery({
    variables: { uuid: authRequestId },
    pollInterval: 2500
  })
  const token = query.data?.authRequestToken
  const navigate = useNavigate()

  useEffect(() => {
    const handleToken = async () => {
      // It's important to avoid running `handleToken` on the device
      // confirming the request, mainly to avoid unexpected redirects.
      if (token && token !== getAuthenticationToken()) {
        await handleAuthToken(token)
        navigate(getAuthRoute("/"))
      }
    }

    handleToken()
  }, [token])
}

export type AuthRequestConfirmFormProps = ActionType &
  Awaited<ReturnType<typeof loader>> & {
    submitButtonLabel?: string
  }

const AuthRequestConfirmForm = ({
  authRequestId,
  submitButtonLabel,
  error,
  loading,
  onSubmit
}: AuthRequestConfirmFormProps) => {
  const { t } = useScopedTranslation("auth.auth_request_confirm_form")
  useAuthRequestToken(authRequestId)

  if (!submitButtonLabel) submitButtonLabel = t("default_submit_button_label")

  return (
    <Formik
      initialValues={{
        authRequestKey: "",
        authRequestId
      }}
      validate={validate}
      onSubmit={() => {}} // noop
    >
      <form onSubmit={onSubmit} id="authRequestConfirmForm">
        <VStack spacing={4} width="100%" alignItems="stretch">
          <AuthFormAlert error={error} />
          <HStack alignItems="flex-start">
            <InputField
              name="authRequestKey"
              placeholder={t("enter_code")}
              maxLength={6}
              autoComplete="off"
              isDisabled={loading}
            />
            <input
              hidden
              readOnly
              id="authRequestId"
              name="authRequestId"
              type="string"
              value={authRequestId}
            />
            <AuthFormButton w="auto" loading={loading}>
              {submitButtonLabel}
            </AuthFormButton>
          </HStack>
        </VStack>
      </form>
    </Formik>
  )
}

export default AuthRequestConfirmForm
