import { useEffect, useState } from "react"

// Use only when necessary. Uses a MutationObserver for observing
// child nodes of targetNode entering the DOM until the first element
// matching the provided selector is found.
const useAsyncNode = (selector, targetNode) => {
  // Get either the current node or one of its children.
  const getNode = node => {
    if (node.matches) {
      return node.matches(selector) ? node : node.querySelector(selector)
    }
  }
  const [node, setNode] = useState(() => getNode(document.body))

  useEffect(() => {
    if (!node && selector) {
      // First check if node exists in DOM as there is a window of time that it may
      // have been added between the initial setState and the useEffect callback.
      const nextNode = getNode(document.body)
      if (nextNode) {
        setNode(nextNode)
      } else if (targetNode !== null) {
        // Handle the case where the specified selector is not yet present in the DOM.
        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            const addedNodes = mutation.addedNodes
              ? [...mutation.addedNodes]
              : []
            const node = addedNodes.find(getNode)
            if (node) setNode(getNode(node))
          })
        })

        observer.observe(targetNode || document.body, {
          childList: true,
          subtree: true
        })
        // Disconnects observer once node is found.
        return () => observer.disconnect()
      }
    }
  }, [node, targetNode])

  return node
}

export default useAsyncNode
