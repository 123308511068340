import gql from "graphql-tag"
import RESOURCE_FRAGMENT from "../../graphql/resource-fragment"

export default gql`
  mutation(
    $id: Int!
    $to_school: Int
    $create_offering: Boolean
    $create_registration_with_role: RegistrationRoleEnum
  ) {
    copyResource(
      id: $id
      to_school: $to_school
      create_offering: $create_offering
      create_registration_with_role: $create_registration_with_role
    ) {
      ...Resource
    }
  }
  ${RESOURCE_FRAGMENT}
`
