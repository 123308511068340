import { chakra, Checkbox, HStack, VStack } from "@chakra-ui/react"
import { Field, Formik } from "formik"
import defaults from "lodash/defaults"
import { toFormikValidate } from "zod-formik-adapter"
import { InputField } from "../../../components/form/Field"
import { FormikFormStateInputField } from "../../../components/form/state/FormikFormStateField"
import { useScopedTranslation } from "../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../lib/types"
import { AuthContextType } from "../api/loader"
import AuthFormAlert from "../components/AuthFormAlert"
import AuthFormButton from "../components/AuthFormButton"
import PasswordInput from "../password/components/PasswordInput"
import { UserPropertyDefaults } from "../types"
import { AUTH_EMAIL_STORAGE_KEY } from "../utils/authEmailFormStateStorage"
import { extendWithCreateMembership } from "../utils/createMembership"
import { schema, SchemaType } from "./api/action"
import TermsAgreement from "./TermsAgreement"

const validate = toFormikValidate(schema)

export type SignUpFormProps = ActionType &
  Partial<
    Pick<
      AuthContextType,
      | "inviteToken"
      | "consentDescription"
      | "userPropertyDefaults"
      | "alwaysPromptForPassword"
      | "createMembership"
    >
  > & {
    userPropertyDefaults?: UserPropertyDefaults
  }

const SignUpForm = ({
  inviteToken,
  consentDescription,
  userPropertyDefaults,
  alwaysPromptForPassword,
  createMembership,
  loading,
  error,
  onSubmit
}: SignUpFormProps) => {
  const { t } = useScopedTranslation("auth")

  let initialValues = defaults<UserPropertyDefaults, SchemaType>(
    { ...userPropertyDefaults },
    {
      email: "",
      firstName: "",
      lastName: "",
      consent: false,
      inviteToken: ""
    }
  )

  if (alwaysPromptForPassword) {
    initialValues.password = ""
  }

  initialValues = extendWithCreateMembership(initialValues, createMembership)

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnMount
      onSubmit={() => {}} // noop
    >
      {(form) => (
        <chakra.form onSubmit={onSubmit} w="100%">
          <VStack spacing={4}>
            <AuthFormAlert error={error} />
            {!!inviteToken && (
              <Field
                type="text"
                name="inviteToken"
                value={inviteToken}
                hidden
                readOnly
              />
            )}
            {/* Defaulting `createMembership` to true! This is good, as
            the backend does the same. */}
            <Field
              type="text"
              name="createMembership"
              value={createMembership === false ? "no" : "yes"}
              hidden
              readOnly
            />
            <FormikFormStateInputField
              formStateKey={AUTH_EMAIL_STORAGE_KEY}
              localStorageOptions={{ storageType: "session" }}
              name="email"
              type="email"
              placeholder={`${t("sign_in_form.email_placeholder")}...`}
              isDisabled={loading}
            />
            <HStack spacing={4} alignItems="flex-start" w="100%">
              <InputField
                name="firstName"
                placeholder={t("sign_up_form.name_placeholder")}
                isDisabled={loading}
              />
              <InputField
                name="lastName"
                placeholder={t("sign_up_form.last_name_placeholder")}
                isDisabled={loading}
              />
            </HStack>
            {alwaysPromptForPassword && <PasswordInput isDisabled={loading} />}

            <TermsAgreement />

            {!!consentDescription && createMembership !== false && (
              <Field
                as={Checkbox}
                name="consent"
                isChecked={form.values.consent}
                // Preventing submitting form when hitting Enter on checkbox.
                // NOTE: oddly must specify `isChecked` prop in this case.
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    e.preventDefault()
                    form.setFieldValue("consent", !form.values.consent, true)
                  }
                }}
                minW="100%"
              >
                {consentDescription}
              </Field>
            )}
            <AuthFormButton loading={loading}>
              {t("signed_in_footer.continue")}
            </AuthFormButton>
          </VStack>
        </chakra.form>
      )}
    </Formik>
  )
}

export default SignUpForm
