import { useCreatePasswordAction } from "./api/action"
import PasswordCreateView from "./PasswordCreateView"

const PasswordCreateViewContainer = ({
  onContinue
}: {
  onContinue: Function
}) => {
  const [state, action] = useCreatePasswordAction()

  const handleSubmit = async e => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await action(formData)
  }

  return (
    <PasswordCreateView
      data={state.value}
      loading={state.loading}
      success={!!state.value}
      error={state.error?.message}
      onSubmit={handleSubmit}
      onContinue={onContinue}
    />
  )
}

export default PasswordCreateViewContainer
