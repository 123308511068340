import Button from "@pathwright/ui/src/components/button/Button"
import Link from "@pathwright/ui/src/components/link/Link"
import Text from "@pathwright/ui/src/components/ui/Text"
import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { SUBSCRIPTION_INTERVAL_LIFETIME } from "../constants"

const RedeemSchoolGiftSubscriptionAlreadySubscribed = ({
  school_subscription
}) => {
  const { school } = usePathwrightContext()
  const { trial_type, trial_end_date } = school_subscription.subscription

  const {
    interval,
    name: planName
  } = school_subscription.school_subscription_plan.subscription_plan

  const isTrialing = !!trial_type && moment(trial_end_date).isBefore()

  const isLifetime = interval === SUBSCRIPTION_INTERVAL_LIFETIME

  return isTrialing ? (
    <Text.Body className="continue-free-prompt">
      <Text.H4>Free trial</Text.H4>
      You have <strong>{moment(trial_end_date).fromNow(true)}</strong> left in
      your free trial of {school.name}. When you're ready, you may activate your
      subscription{" "}
      <Link to="/manage/profile/billing/activate-subscription/">here</Link>.
    </Text.Body>
  ) : isLifetime ? (
    <Text.Body>You've already purchased {planName}.</Text.Body>
  ) : (
    <Text.Body>
      You've already subscribed to {school.name}. You may manage your
      subscription{" "}
      <Button styleType="text" to="/manage/profile/billing/">
        under your profile
      </Button>
      .
    </Text.Body>
  )
}

RedeemSchoolGiftSubscriptionAlreadySubscribed.displayName =
  "RedeemSchoolGiftSubscriptionAlreadySubscribed"

RedeemSchoolGiftSubscriptionAlreadySubscribed.proptTypes = {
  school_subscription: PropTypes.object,
  selectedSchoolSubscriptionPlan: PropTypes.object
}

export default RedeemSchoolGiftSubscriptionAlreadySubscribed
