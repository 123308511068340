import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import Link from "@pathwright/ui/src/components/link/Link"
import Text from "@pathwright/ui/src/components/ui/Text"
import CurrencyFormatter from "@pathwright/web/src/modules/currency/CurrencyFormatter"
import {
  ConnectForm,
  FieldFooter,
  RichText,
  ToggleInput
} from "lib/core/components/forms"
import { ConnectStores } from "lib/core/store"
import path from "path-browserify"
import pluralize from "pluralize"
import "./ManageLicenseForm.css"
import { useRLOSubscriptionOptions } from "./utils"

const fieldsData = {
  licensing_enabled: {
    labelWidth: 240,
    label: "Enable member-created cohorts",
    alignRight: true
  },
  display_description: {
    label: "Member-created cohort option description.",
    placeholder:
      "Make a short pitch for why a group admin, teacher, or mentor should create their own private cohort.",
    topLabel: true,
    optional: true,
    maxRows: 3
  }
}

// Simple HOC to provide the default or current licensingMode.
const withLicensingMode = Component => props => {
  const { licensingMode } = useRLOSubscriptionOptions(props.license_offering)
  return <Component {...props} licensingMode={licensingMode} />
}

const ManageLicenseForm = props => {
  const { fieldsData, links, isValid, handleSubmit, license_offering } = props
  const { display_description, licensing_enabled } = fieldsData
  const { hasOptions } = useRLOSubscriptionOptions(license_offering)

  return (
    <form className="ManageLicenseForm" onSubmit={handleSubmit}>
      <SubmitButton
        onSubmit={handleSubmit}
        disabled={!isValid}
        styleType="primary"
        type="submit"
      >
        Save
      </SubmitButton>

      <ToggleInput
        {...licensing_enabled}
        onChange={value => {
          // attempt to submit form whenever toggled
          licensing_enabled.onChange(value)
          display_description.onChange(display_description.value)
          handleSubmit()
        }}
      />
      {licensing_enabled.value ? (
        <div>
          <RichText {...display_description} />
          <FieldFooter>
            Need some tips? Check out our help center.{" "}
            {/* TODO: replace with new help article when available */}
            <Link to="https://help.pathwright.com/ways-to-offer-courses-with-curriculum/let-anyone-lead-a-private-cohort-through-your-courses">
              Learn more.
            </Link>
          </FieldFooter>
          <CardLink {...links.pricing} />
          {hasOptions && <CardLink {...links.subscription} />}
        </div>
      ) : (
        <FieldFooter>
          <Text.Body>
            Enabling the <strong>member-created cohorts</strong> feature unlocks
            the option for you to offer courses for a seat-based price of your
            choice or for free to any team leader, teacher, parent, or mentor
            who'd like to use your course to learn with their own private group.{" "}
            <Link to="https://help.pathwright.com/ways-to-offer-courses-with-curriculum/let-anyone-lead-a-private-cohort-through-your-courses">
              Learn more.
            </Link>
          </Text.Body>
        </FieldFooter>
      )}
    </form>
  )
}

ManageLicenseForm.displayName = "ManageLicenseForm"

const mapStateToProps = (state, ownProps) => {
  const { pathname, licensingMode } = ownProps
  const { saveLicenseOffering } = state.resourceLicenseAdminStore.action
  const { license_offering } = state.resourceLicenseAdminStore.state
  const { resource } = state.resourceAdminStore.state

  const costPerSeat = license_offering ? license_offering.cost_per_seat : 0
  const includedSubCount =
    (license_offering &&
      license_offering.include_in_subscription_plans &&
      license_offering.include_in_subscription_plans.length) ||
    0

  const links = {
    pricing: {
      icon: "tag-dollar-sign",
      label: "Pricing & Access",
      to: path.join(pathname, "/pricing/"),
      preview:
        costPerSeat > 0 ? (
          <CurrencyFormatter value={costPerSeat} />
        ) : !costPerSeat ? (
          "Free"
        ) : null
    },
    subscription: {
      icon: "recur",
      label: "Subscriber Access",
      to: path.join(pathname, "/subscription/"),
      preview:
        includedSubCount > 0 ? pluralize("plan", includedSubCount, true) : null
    }
  }

  const fieldValues = {
    licensing_enabled: resource && resource.licensing_enabled,
    display_description:
      license_offering && license_offering.display_description
  }

  const onSubmit = (fieldsData, resetForm) => {
    const licenseData = {
      licensing_enabled: fieldsData.licensing_enabled.value,
      display_description: fieldsData.display_description.value || "<p></p>",
      licensing_mode: licensingMode
    }
    return saveLicenseOffering(licenseData)
  }

  return _.assign(
    {},
    {
      links,
      onSubmit,
      fieldValues,
      includedSubCount,
      license_offering
    },
    ownProps
  )
}

const stores = [
  { store: "school", watch: false },
  { store: "resourceLicenseAdmin", watch: ["license_offering"] },
  { store: "resourceAdmin", watch: ["resource", "resource_saving"] }
]

const ConnectedManageLicensingForm = ConnectStores(
  ConnectForm(ManageLicenseForm, fieldsData),
  stores,
  {},
  mapStateToProps
)

// Ugh, gotta wrap again (to get the license_offering prop supplied to withLicensingMode).
export default ConnectStores(
  withLicensingMode(ConnectedManageLicensingForm),
  [{ store: "resourceLicenseAdmin", watch: ["license_offering"] }],
  {},
  (state, ownProps) => ({
    license_offering: state.resourceLicenseAdminStore.state.license_offering,
    ...ownProps
  })
)
