const SwitchStateModel = require("lib/switch-state-model").default
class SwitchStateBehavior extends require("lib/static-shim").default(
  Marionette.Behavior
) {
  static initClass() {
    this.prototype.defaults = {
      attr: "data-trigger-state",
      baseState: null,
      bind: true,
      bindName: "switchState"
    }
  }

  behaviors() {
    const { bind, bindName } = this.options
    if (bind) {
      return {
        Bind: {
          bindEvent: null,
          serializeBindData() {
            const bound = {}
            bound[bindName] = this[bindName]
            return bound
          }
        }
      }
    }
  }

  events() {
    const events = {}
    events[`click [${this.options.attr}]`] = "onClickStateTrigger"
    return events
  }

  onBeforeRender() {
    this.switchState =
      this.view[this.options.bindName] != null
        ? this.view[this.options.bindName]
        : (this.view[this.options.bindName] = new SwitchStateModel())
    return this.setBaseState()
  }

  getState() {
    return this.switchState.get("state")
  }

  setBaseState() {
    let { baseState } = this.options
    if (_.isFunction(baseState)) {
      baseState = _.bind(baseState, this.view)()
    }
    if (baseState) {
      return this.switchState.set({ state: baseState })
    }
  }

  onClickStateTrigger = e => {
    e.preventDefault()
    const state = $(e.currentTarget).attr(this.options.attr)
    return this.setState(state)
  }

  setState(state) {
    this.switchState.set("state", state)
    this.triggerMethod("switch:state:changed", this.getState())
    return this.view.triggerMethod("switch:state:changed", this.getState())
  }
}
SwitchStateBehavior.initClass()
export default SwitchStateBehavior
