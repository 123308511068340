import moment from "moment"
import pluralize from "pluralize"

export function dueTimeStatus(completion_date, due_date) {
  const data = completion_date || new Date()
  const days = moment(data).diff(due_date, "day")
  const hours = moment(data).diff(due_date, "hour")
  const minutes = moment(data).diff(due_date, "minute")
  const seconds = moment(data).diff(due_date, "second")

  // console.log("dueTimeStatus", { due_date, days, hours, minutes, seconds })

  if (days > 0) {
    return `${pluralize("day", days, true)} late`
  } else if (hours > 0) {
    return `${pluralize("hour", hours, true)} late`
  } else if (minutes > 0) {
    return `${pluralize("minute", minutes, true)} late`
  } else if (seconds > 0) {
    return `${pluralize("second", seconds, true)} late`
  } else {
    // if it was more than a day early, show early status
    if (hours < -24) {
      return `${pluralize("day", -days, true)} early`
    }

    return "On time"
  }
}

export const getCompletionAction = completion => {
  if (completion.is_skipped) {
    return "skipped"
  }

  if (completion.is_complete) {
    return "completed"
  }

  return "incomplete"
}

export function userpointsPerc(userpoints_earned, userpoints_value) {
  if (userpoints_earned > 0 && userpoints_value > 0) {
    return `(${Math.floor((userpoints_earned / userpoints_value) * 100)}%)`
  }
  return null
}

export const COMPLETIONS_FILTERS = {
  NEEDS_REVIEW: "needs_review",
  REVIEWED: "reviewed",
  COMPLETE: "complete",
  INCOMPLETE: "incomplete",
  NEEDS_ATTENTION: "needs_attention",
  REVIEWABLE: "reviewable",
  ALL: "all"
}
