import gql from "graphql-tag"
import BANNER_FRAGMENT from "./banner-fragment"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  query BannersQuery(
    $cursor: String
    $school_id: Int!
    $first: Int!
    $manage: Boolean = false
  ) {
    context(school_id: $school_id) {
      school {
        ...School
        banners(first: $first, after: $cursor, manage: $manage) {
          total
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...Banner
            }
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${BANNER_FRAGMENT}
`
