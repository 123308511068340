import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PickerDropdown from "@pathwright/ui/src/components/picker/PickerDropdown"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { flattenEdges, usePaginator } from "../utils/apollo"
import USER_SCHOOL_STORE_QUERY from "./graphql/user-school-license-query"

export const useUserSchoolLicenseQuery = () => {
  const { school } = usePathwrightContext()

  const userSchoolLicenseQuery = useQuery(USER_SCHOOL_STORE_QUERY, {
    variables: {
      licensorId: { eq: school.id }
    }
  })

  const memberships = flattenEdges(
    get(userSchoolLicenseQuery, "data.me.memberships")
  )

  const { loadingMore, hasMore, loadMore } = usePaginator({
    data: userSchoolLicenseQuery, // hack
    path: "me.memberships"
  })

  const schools = memberships
    ? memberships.map(membership => membership.school)
    : []

  return {
    schools: schools,
    loading: userSchoolLicenseQuery.loading,
    loadingMore,
    loadMore,
    hasMore
  }
}

export const withSchoolLicensePicker = Component => passProps => {
  const { t } = useTranslate()
  const usePickerQuery = () => {
    const { schools, ...rest } = useUserSchoolLicenseQuery()
    return {
      pickerItems: schools,
      ...rest
    }
  }

  return (
    <Component
      {...passProps}
      prompt={t("Your Pathwright Accounts")}
      searchPrompt={t("Search your Pathwright accounts")}
      usePickerQuery={usePickerQuery}
    />
  )
}

export const SchoolLicensePickerDropdown = withSchoolLicensePicker(
  PickerDropdown
)
