import React from "react"
const cache = {}
window.ConnectStoreCache = cache

export default function(
  Component,
  stores,
  props,
  mapStateToProps = null,
  cacheComponent,
  triggerCondition = null
) {
  let key
  if (props == null) {
    props = {}
  }
  if (cacheComponent == null) {
    cacheComponent = true
  }
  ;`\
A higher-level component that will attach stores
to the passed <Component> as well as triggering state updates
when watch happen.

Store state is passed as props to the wrapped component using the following pattern:
- @props.<storeKey>Store.state: The current state of the store (plain JavaScript object)
- @props.<storeKey>Store.action: A reference to available actions provided by the store.
- @props.<storeKey>Store.request: A reference to available requests provided by the store.\
`

  const debug = false // If yes, will turn on time tracking from setState to update

  if (!Component.displayName) {
    console.warn(
      "Use of ConnectStore on a component without a displayName set!",
      Component
    )
  }
  // console.trace()

  if (Component.displayName === "FormContainer") {
    console.warn(
      "Use of ConnectForm on a component without a displayName set!",
      Component
    )
  }
  // console.trace()

  let displayName = "ConnectStore"
  if (Component.displayName) {
    displayName = `${Component.displayName}Connected`
  }

  const normalizeStore = function(store) {
    if (_.isString(store.store)) {
      store.storeKey = store.store
    }
    if (store.watch == null) {
      store.watch = false
    }

    if (_.isString(store)) {
      return { storeKey: store, watch: false }
    } else if (_.isObject(store.store)) {
      return { storeKey: store.store._key, watch: store.watch }
    } else {
      return store
    }
  }

  stores = stores.map(normalizeStore)

  if (cacheComponent) {
    const storeKeys = stores.map(s => s.storeKey).join(":")
    key = `${displayName}:${storeKeys}`
    if (cache[key]) {
      return cache[key]
    }
  }

  class ConnectStore extends React.Component {
    static displayName = displayName

    constructor(props, context) {
      super(props, context)
      this._connectStores()
      this.state = { counter: 0 }
    }

    _connectStores = () => {
      this.stores = {}
      this.mountActions = []
      return (() => {
        const result = []
        for (var store of Array.from(stores)) {
          this.stores[store.storeKey] = window.App.stores.requestStore(
            store.storeKey
          )

          const parseStateChangeEvent = function(ev) {
            if (!_.isString(ev)) {
              const err = `Invalid watch key for connected component ${displayName}. Watch keys must be strings.`
              console.error(err)
              console.trace()
              throw new Error(err)
            }
            if (ev.indexOf("change:") === -1) {
              ev = `change:${ev}`
            }
            return ev
          }

          if (_.isArray(store.watch)) {
            const events = store.watch.reduce((events, ev) => {
              ev = parseStateChangeEvent(ev)
              if (ev === "change") {
                console.error(
                  `Invalid state change event for connected component ${displayName}. You cannot listen to 'change', you must specify a key!`
                )
                throw new Error(
                  "invalid state checkange event... you must specifiy a key!"
                )
              }
              if (!this.stores[store.storeKey])
                throw new Error(`invalid store key ${store.storeKey}`)

              return events ? `${events} ${ev}` : ev
            }, "")

            this.stores[store.storeKey].on(events, this._handleWatchEvent)
          }

          if (_.isFunction(store.mountActionDispatcher)) {
            var storeActions = this.stores[store.storeKey].action
            result.push(
              this.mountActions.push(() =>
                store.mountActionDispatcher(storeActions)
              )
            )
          } else {
            result.push(undefined)
          }
        }
        return result
      })()
    }

    _getStoreState = storeKey => {
      if (!this.stores) {
        console.warn(
          "state event responded to after component unmount: ",
          displayName,
          this
        )
        return
      }

      return {
        state: this.stores[storeKey].getState(),
        action: this.stores[storeKey].action,
        request: this.stores[storeKey].request
      }
    }

    _getStoresState = () => {
      const state = {}
      for (key in this.stores) {
        const store = this.stores[key]
        state[`${key}Store`] = this._getStoreState(key)
      }
      return state
    }

    componentDidMount() {
      return Array.from(this.mountActions).map(action => action())
    }

    componentWillUnmount() {
      this._isUnbound = true
      for (let store of Array.from(stores)) {
        this.stores[store.storeKey].off(null, this._handleWatchEvent)
      }
      return delete this.stores
    }

    componentDidUpdate() {
      if (debug) {
        return console.timeEnd(displayName)
      }
    }

    _getProps = () => {
      const state = stores.reduce(
        (state, store) => ({
          ...state,
          [`${store.storeKey}Store`]: this._getStoreState(store.storeKey)
        }),
        {}
      )

      if (_.isFunction(mapStateToProps)) {
        return mapStateToProps(state, this.props, props)
      } else {
        return _.assign({}, state, this.props, props)
      }
    }

    _handleWatchEvent = store => {
      // check for race condition
      if (this._isUnbound === true) {
        return
      }

      if (!store) {
        console.error(
          "ConnectStore recieved a state change event with the wrong scope! Check that the state change event was triggered properly."
        )
        console.trace()
      }

      this.setState(state => ({ counter: state.counter + 1 }))
    }

    // else
    //   console.log "ConnectStores: skipped setState on component #{displayName} because #{storeKey} state did not change", store.getState()

    render() {
      //console.log "#{displayName}.render()"
      return <Component {...Object.assign({}, this._getProps())} />
    }
  }

  cache[key] = ConnectStore
  return cache[key]
}
