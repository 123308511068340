import "./FieldFooter.css"
import React from "react"
import styled from "styled-components"
import { media } from "../../utils/styles"

const Footer = styled.footer`
  position: relative;
  padding-bottom: 20px;
  text-align: left;
  font-size: 14px;
  ${media.max.phone`margin-left: 0 !important`};
`

const FieldFooter = ({ children, offset }) => {
  return <Footer style={{ marginLeft: offset }}>{children}</Footer>
}

FieldFooter.displayName = "FieldFooter"

export default FieldFooter
