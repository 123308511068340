import gql from "graphql-tag"
import THEME_FRAGMENT from "./theme-fragment"

export default gql`
  mutation($id: Int!, $customCss: String!, $name: String!) {
    updateSchoolCustomCSS(id: $id, custom_css: $customCss, name: $name) {
      ...Theme
    }
  }
  ${THEME_FRAGMENT}
`
