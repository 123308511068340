import defaults from "lodash/defaults"
import { useEffect, useRef } from "react"
import { useObserveSize } from "../observers/ObserveSizeContext"

const defaultOptions = {
  scrollOptions: {
    behavior: "smooth",
    block: "center"
  }
}

// Scroll to the DOM node.
const useScrollToNode = options => {
  const { scrollOptions, onScrolled } = defaults(options, defaultOptions)

  const {
    rectValue,
    nodeRef: { current: node },
    setNode
  } = useObserveSize()

  const lastScrolledNodeRef = useRef(null)

  useEffect(() => {
    if (lastScrolledNodeRef.current !== node) {
      if (node && rectValue) {
        // Only scroll to node if node is currently visible.
        // Node may be displayed none or be nested under a parent
        // which is displayed noen, in which case the node will have
        // have no dimensions.
        if (rectValue.height > 0 || rectValue.width > 0) {
          // Keep a ref of the last node scrolled to, so we can ignore
          // any future attempts to scroll to this node. This might not
          // be expected for all use cases though?
          lastScrolledNodeRef.current = node
          // Scroll to that node.
          node.scrollIntoView(scrollOptions)
          // Run onScrolled callback upon successful scroll.
          onScrolled && onScrolled(node)
          // Scrolling to the node should be a one-time deal,
          // so clear out the node after scroll.
          setNode(null)
        }
      }
    }
  }, [node, rectValue])

  return setNode
}

export default useScrollToNode
