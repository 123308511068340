import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import MentorGroupList from "./MentorGroupList"

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 800px;
  min-height: 100px;
`

const MentorGroups = ({ parentId }) => {
  return (
    <Container>
      <MentorGroupList parentId={parentId} />
    </Container>
  )
}

MentorGroups.displayName = "MentorGroups"

MentorGroups.propTypes = {
  parentId: PropTypes.number
}

MentorGroups.defaultProps = {
  parentId: null
}

export default MentorGroups
