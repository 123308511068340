import React from "react"
import styled from "styled-components"
import RangedControl from "./RangedControl"

const VolumeUI = styled.div`
  padding: 12px 2px;
  width: ${props => (props.vertical ? "auto" : "75px")};
  height: ${props => (props.vertical ? "75px" : "auto")};
  margin: 0px auto;
`

const Volume = ({ currentVolume, onSetVolume, vertical }) => (
  <VolumeUI vertical={vertical}>
    <RangedControl
      vertical={vertical}
      value={currentVolume * 100}
      onSeek={onSetVolume}
      updateOnScrub={true}
    />
  </VolumeUI>
)

export default Volume
