import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import React from "react"

export const LICENSOR_SUPPORT = {
  LIGONIER_CONNECT: 1,
  CHURCHNEXT: 960
}

export const LicensorComponents = {
  [LICENSOR_SUPPORT.LIGONIER_CONNECT]: () => (
    <div>
      <header>
        <Text.H1>Contact Ligonier Connect</Text.H1>
      </header>

      <View className="Support__content" borderTop paddingTop paddingBottom>
        <ul>
          <li>
            <Pathicon icon="check-circle" />
            <span>
              Read FAQs: Quickly find an answer by reading our{" "}
              <a
                target="_blank"
                href="https://intercom.help/ligonier-connect/blueprint"
              >
                FAQs
              </a>
            </span>
          </li>
          <li>
            <Pathicon icon="check-circle" />
            <span>
              Take a Course: Visit Ligonier Connect to take{" "}
              <a
                target="_blank"
                href="https://connect.ligonier.org/library/upgrade-your-blueprint-school/register/54253/?ic=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjo1LCJvZmZlcmluZ19pZCI6NTQyNTMsInNlbmRlcl9pZCI6MiwiaW52aXRlX3R5cGUiOjEsInNjaG9vbF9pZCI6MX0.nlIcAo--XUEzIXxpzEHdWThxU2LAbk-7FLu7bl14YbA"
              >
                Blueprint Upgrade Guide
              </a>
              ,{" "}
              <a
                target="_blank"
                href="https://connect.ligonier.org/library/learner-guide-for-ligonier-connect/register/54386/?ic=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjo1LCJvZmZlcmluZ19pZCI6NTQzODYsInNlbmRlcl9pZCI6NTYyNDgsImludml0ZV90eXBlIjoxLCJzY2hvb2xfaWQiOjF9.qMcriKirpgMpOPTWHQj7QS8L8tYWNUH-3hNvQ0aMa4A"
              >
                Learner Guide
              </a>
              ,{" "}
              <a
                target="_blank"
                href="https://connect.ligonier.org/library/moderator-guide-for-ligonier-connect/register/54388/?ic=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjo1LCJvZmZlcmluZ19pZCI6NTQzODgsInNlbmRlcl9pZCI6NTYyNDgsImludml0ZV90eXBlIjoxLCJzY2hvb2xfaWQiOjF9.JfvqzANgXVZW68LHvSKv7d_6TUGCAxc6d_HMbQ7lDdY"
              >
                Moderator Guide
              </a>
              .
            </span>
          </li>
          {!!window.Intercom && (
            <li>
              <Pathicon icon="check-circle" />
              <span>
                Contact us:{" "}
                <a
                  href="javascript:void(0)"
                  onClick={() => window.Intercom("show")}
                >
                  Send a message
                </a>
              </span>
            </li>
          )}
        </ul>
      </View>
    </div>
  ),
  [LICENSOR_SUPPORT.CHURCHNEXT]: () => (
    <div>
      <header>
        <Text.H1>Contact ChurchNext</Text.H1>
      </header>

      <View className="Support__content" borderTop paddingTop paddingBottom>
        <ul>
          <li>
            <span>
              <Pathicon icon="check-circle" />
              Read FAQs: Quickly find an answer by reading our{" "}
              <a href="https://help.pathwright.com/teaching-licensed-curriculum">
                FAQs
              </a>
            </span>
          </li>
          <li>
            <Pathicon icon="check-circle" />
            <span>
              Take a Course: Visit ChurchNext to take{" "}
              <a
                target="_blank"
                href="https://www.churchnext.tv/library/upgrade-guide-for-churchnext/register/54415/?ic=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjo1LCJvZmZlcmluZ19pZCI6NTQ0MTUsInNlbmRlcl9pZCI6MiwiaW52aXRlX3R5cGUiOjEsInNjaG9vbF9pZCI6OTYwfQ.Z7UZEbxFWzRAy9HiLwu5U2bV5Tj42S4i_4b7nvmVTkQ"
              >
                ChurchNext Upgrade Guide
              </a>
              ,{" "}
              <a
                target="_blank"
                href="https://www.churchnext.tv/library/learner-guide-for-churchnext/register/54413/"
              >
                Learner Guide
              </a>
              ,{" "}
              <a
                target="_blank"
                href="https://www.churchnext.tv/library/moderator-guide-for-churchnext/register/54411/?ic=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjo1LCJvZmZlcmluZ19pZCI6NTQ0MTEsInNlbmRlcl9pZCI6MiwiaW52aXRlX3R5cGUiOjEsInNjaG9vbF9pZCI6OTYwfQ.R9PYlylm-5BHBwORpr-SawDhX0yHFkvyFh3sxIMb7nc"
              >
                Moderator Guide
              </a>
              .
            </span>
          </li>
          {!!window.Intercom && (
            <li>
              <Pathicon icon="check-circle" />
              <span>
                Contact us:{" "}
                <a
                  href="javascript:void(0)"
                  onClick={() => window.Intercom("show")}
                >
                  Send a message
                </a>
              </span>
            </li>
          )}
        </ul>
      </View>
    </div>
  )
}
