import "./TextInput.css"
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import FieldWrapper from "../form-utils/FieldWrapper"
import InputAutosize from "react-input-autosize"

const TextInput = props => {
  const {
    id,
    type,
    name,
    label,
    value,
    disabled,
    readOnly,
    required,
    minWidth,
    placeholder,
    autoSize,
    autoFocus,
    autoComplete,
    onChange,
    onKeyDown,
    onKeyUp,
    onClick,
    step,
    preventSubmit
  } = props

  const className = classnames(
    "TextInput",
    { "TextInput--autosize": autoSize },
    props.className
  )

  const Input = autoSize ? InputAutosize : "input"

  let sizeProps
  if (autoSize) {
    sizeProps = {
      minWidth: minWidth || 30,
      placeholderIsMinWidth: minWidth ? false : true
    }
  } else {
    sizeProps = {}
  }

  return (
    <FieldWrapper {...props} id={id || name} className={className}>
      {({ focus, onFocus, onBlur, registerInput }) => {
        return (
          <div className="TextInput__input">
            <Input
              id={id || name}
              type={type}
              name={name || label}
              disabled={disabled}
              readOnly={readOnly}
              ref={registerInput}
              required={required}
              autoFocus={autoFocus || focus}
              placeholder={placeholder}
              value={value != null ? value : ""}
              autoComplete={!autoComplete && "off"}
              onChange={e =>
                typeof onChange === "function"
                  ? onChange(e.target.value, e)
                  : undefined
              }
              onKeyDown={e => {
                if (typeof onKeyDown === "function") {
                  onKeyDown(e.target.value, e)
                }
                if (preventSubmit && e.keyCode === 13) {
                  e.preventDefault()
                }
              }}
              onKeyUp={e => {
                if (typeof onKeyUp === "function") {
                  onKeyUp(e.target.value, e)
                }
                if (preventSubmit && e.keyCode === 13) {
                  e.preventDefault()
                }
              }}
              onClick={onClick}
              onFocus={onFocus}
              onBlur={onBlur}
              step={step}
              {...sizeProps}
            />
          </div>
        )
      }}
    </FieldWrapper>
  )
}

TextInput.displayName = "TextInput"
TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  required: PropTypes.bool,
  focus: PropTypes.bool,
  errors: PropTypes.array,
  validated: PropTypes.bool,
  validating: PropTypes.bool,
  autoSize: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preventSubmit: PropTypes.bool,
  // Hook to TextInput events
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

TextInput.defaultProps = {
  type: "text",
  autoComplete: false,
  preventSubmit: false
}

export default TextInput
