import { getLocalizedUrl } from "@pathwright/web/src/modules/utils/urls"
import {
  ENROLLMENT_ROLE_FACILITATOR,
  ENROLLMENT_ROLE_STUDENT,
  INVITATION_TYPE_OFFERING,
  INVITATION_TYPE_SCHOOL,
  INVITE_METHOD_EMAIL,
  MEMBERSHIP_TYPE_STUDENT
} from "invitation/constants"
import { getShareableInviteUrl, trackIntercomEvent } from "invitation/utils"
import { API } from "lib/core/api/request"
import { createStore } from "lib/mobx/mobx-store"
import { action, observable, toJS } from "mobx"
import { emailCount } from "../components/lookup/utils"

const state = {
  invites: observable.map(),
  message: null,
  context: null,
  numsent: null,
  method: null,
  error: null,
  role: null,
  send_from_school: false,
  // awaiting invitation context (school or offering)
  initializing: false,
  // awaiting validation from adding email(s)
  adding: false,
  // awaiting response from sending invites
  sending: false,
  // number of emails added
  get count() {
    return this.invites.size
  },
  // extract all errors
  get errors() {
    if (!this.invites.size) return []
    return Object.values(toJS(this.invites)).reduce((errors, invite) => {
      if (invite.error) {
        errors.push(invite.error)
      }
      return errors
    }, [])
  },
  // is invite list valid
  get valid() {
    if (this.message && this.message.length > 1000) return false
    return this.errors.length === 0
  },
  // invitation type (school or offering invitation)
  get type() {
    if (this.context && this.context.offering) {
      return INVITATION_TYPE_OFFERING
    }
    return INVITATION_TYPE_SCHOOL
  },
  // shareable link
  get inviteUrl() {
    const inviteUrl = getShareableInviteUrl(
      this.type,
      this.role,
      this.context ? this.context.offering : undefined
    )

    if (inviteUrl) return getLocalizedUrl(inviteUrl)
  },
  // send redirect
  get successUrl() {
    if (this.context && this.context.offering) {
      return `${this.context.offering.url}invite/success/`
    }
    return `/manage/invite/success/`
  },

  get canInviteMore() {
    if (this.type !== INVITATION_TYPE_OFFERING) {
      return true
    }

    const offering = this.context && this.context.offering

    if (
      !offering ||
      !offering.resource_license ||
      offering.resource_license.ignore_seat_count
    ) {
      return true
    }

    const seatsAvailable = Math.max(
      offering.resource_license.seat_count -
        offering.resource_license.seats_filled,
      0
    )

    return (
      seatsAvailable > 0 ||
      !offering.resource_license.is_belltower_school_licensing
    )
  }
}

const actions = {
  addInvites(emails) {
    if (!emails) {
      return
    }
    if (emailCount(emails) + this.count > 50) {
      this.error = `Emails limited to 50 per batch. Counted ${emailCount(
        emails
      ) + this.count}.`
    } else {
      this.error = null
      this.adding = true
      return API.post("invitation/batch", {
        emails,
        type: this.type,
        role: this.role,
        offering_id: this.context ? this.context.offering.id : undefined
      })
        .then(
          action(invites => {
            if (Array.isArray(invites)) {
              invites.forEach(
                action(invite => {
                  const exists = this.invites.has(invite.email)
                  if (exists) {
                    this.invites.delete(invite.email)
                  }
                  this.invites.set(invite.email, invite)
                })
              )
            }
          })
        )
        .catch(
          action(err => {
            this.error = err
          })
        )
        .then(
          action(() => {
            this.adding = false
          })
        )
    }
  },

  removeInvite(key) {
    this.invites.delete(key)
  },

  removeErrors() {
    this.invites.forEach((invite, key) => {
      if (invite.error) {
        this.invites.delete(key)
      }
    })
  },

  sendInvites() {
    if (this.count > 50) {
      this.error = `Emails limited to 50 per batch. Counted ${this.count}.`
    } else {
      this.error = null
      this.sending = true
      const emails = Object.values(toJS(this.invites))
        .map(invite => invite.email)
        .join(",")
      return API.post("invitation/batch/send", {
        emails,
        type: this.type,
        role: this.role,
        send_from_school: this.send_from_school,
        invitation_message: this.message,
        offering_id: this.context ? this.context.offering.id : undefined
      })
        .then(
          action(response => {
            this.numsent = response.invitations_sent_count
            trackIntercomEvent(this.type, this.role)
          })
        )
        .catch(
          action(err => {
            this.error = err
          })
        )
        .then(
          action(() => {
            this.sending = false
          })
        )
    }
  },

  changeSendFromSchool(send_from_school) {
    this.send_from_school = send_from_school
  },

  changeRole(role) {
    this.role = role.value
  },

  changeMethod(method) {
    this.method = method
  },

  showMessage(shouldShow) {
    // message field shows if message is string
    this.message = shouldShow ? "" : null
  },

  changeMessage(change) {
    this.message = change
  },

  clearError() {
    this.error = null
  },

  setContext({ resource, offering, method, role }) {
    // TODO: don't update if same args
    this.context = resource && offering ? { resource, offering } : null
    this.method =
      method !== null && method !== undefined
        ? Number(method)
        : INVITE_METHOD_EMAIL
    this.role =
      role !== null && role !== undefined
        ? Number(role)
        : offering
        ? offering.parent_offering_id
          ? ENROLLMENT_ROLE_FACILITATOR
          : ENROLLMENT_ROLE_STUDENT
        : MEMBERSHIP_TYPE_STUDENT
    this.initializing = false
  },

  initialize() {
    this.initializing = true
  },

  reset() {
    // invoked when leaving invite routes
    this.invites = observable.map()
    this.message = null
    this.context = null
    this.method = null
    this.error = null
    this.role = null
    this.initializing = false
    this.sending = false
    this.adding = false
    this.numsent = null
  }
}

export default createStore(state, actions)
