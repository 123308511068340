import { useMutation } from "@apollo/client"
import { withTranslate } from "@pathwright/ui/src/components/"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import { emailRe } from "@pathwright/ui/src/components/form/constants"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import PasswordInput from "@pathwright/ui/src/components/form/form-text-input/PasswordInput"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import {
  getFormError,
  validate
} from "@pathwright/ui/src/components/form/utils"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"
import Text from "@pathwright/ui/src/components/ui/Text"
import { Formik } from "formik"
import PropTypes from "prop-types"
import styled from "styled-components"
import TermsAgreement from "../account/TermsAgreement"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { dispatchGlobalEvent, USER_SIGNED_UP_EVENT } from "../utils/dispatcher"
import SIGN_UP_MUTATION from "./graphql/sign-up-mutation"
import { AuthFooter, AuthWrapper, StyledHeader } from "./styles"

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  .TextInput:first-child {
    padding-right: 15px;
    flex-grow: 1;
  }

  .TextInput + .TextInput {
    padding-left: 0;
    flex-grow: 1;
  }
`

const SignUpForm = ({
  t,
  providedEmail,
  providedFirstName,
  providedLastName,
  renderHeader,
  renderSubmit,
  onSignIn,
  inverted,
  inviteToken,
  onAuthChange: onAuthChangeProp
}) => {
  const { school, onAuthChange } = usePathwrightContext()
  const screensize = useScreensizeContext()

  const [signUp, signUpMutation] = useMutation(SIGN_UP_MUTATION, {
    variables: {
      invite_token: inviteToken
    },
    onCompleted: result => {
      // We only get back a Token, not a User =/
      dispatchGlobalEvent(USER_SIGNED_UP_EVENT, {
        user: {
          id: result.signUp.user_id
        }
      })
    }
  })

  const handleSubmit = values =>
    signUp({ variables: values }).then(result => {
      if (result.data.signUp.token) {
        onAuthChangeProp && onAuthChangeProp()
        return Promise.resolve(onAuthChange(result.data.signUp.token))
      }
    })

  const tPrefix = "auth.sign_up_form"

  return (
    <Formik
      initialValues={{
        email: providedEmail,
        first_name: providedFirstName,
        last_name: providedLastName,
        password: null,
        consent: true
      }}
      validate={
        SignUpForm.validate // return the errors
      }
    >
      {form => {
        const {
          values,
          isValid,
          touched,
          errors,
          handleChange,
          handleBlur
        } = form

        return (
          <>
            {renderHeader({
              header: (
                <StyledHeader as="header">
                  <Text.H2>{t(`${tPrefix}.prompt`)}</Text.H2>
                </StyledHeader>
              )
            })}
            <AuthWrapper>
              {screensize === "sm" ? (
                <>
                  <TextInput
                    name="first_name"
                    type="text"
                    // label={`${t(`${tPrefix}.name_placeholder`)}:`}
                    placeholder={`${t(`${tPrefix}.name_placeholder`)}...`}
                    inverted={inverted}
                    labelWidth={95}
                    flushLabel
                    autoFocus
                    value={values.first_name}
                    onChange={(value, e) => handleChange(e)}
                    onBlur={(value, e) => handleBlur(e)}
                    errors={getFormError(form, "first_name")}
                  />
                  <TextInput
                    name="last_name"
                    type="text"
                    // label={`${t(`${tPrefix}.last_name_placeholder`)}:`}
                    placeholder={`${t(`${tPrefix}.last_name_placeholder`)}...`}
                    inverted={inverted}
                    labelWidth={95}
                    flushLabel
                    value={values.last_name}
                    onChange={(value, e) => handleChange(e)}
                    onBlur={(value, e) => handleBlur(e)}
                    errors={getFormError(form, "last_name")}
                  />
                </>
              ) : (
                <StyledRow>
                  <TextInput
                    name="first_name"
                    type="text"
                    // label={`${t(`${tPrefix}.name`)}:`}
                    placeholder={`${t(`${tPrefix}.name_placeholder`)}...`}
                    inverted={inverted}
                    labelWidth={95}
                    flushLabel
                    autoFocus
                    value={values.first_name}
                    onChange={(value, e) => handleChange(e)}
                    onBlur={(value, e) => handleBlur(e)}
                    errors={getFormError(form, "first_name")}
                  />
                  <TextInput
                    name="last_name"
                    type="text"
                    placeholder={`${t(`${tPrefix}.last_name_placeholder`)}...`}
                    inverted={inverted}
                    value={values.last_name}
                    onChange={(value, e) => handleChange(e)}
                    onBlur={(value, e) => handleBlur(e)}
                    errors={getFormError(form, "last_name")}
                  />
                </StyledRow>
              )}
              <TextInput
                name="email"
                type="text"
                // label={`${t(`${tPrefix}.email`)}:`}
                placeholder={`${t(`${tPrefix}.email_placeholder`)}...`}
                inverted={inverted}
                labelWidth={95}
                flushLabel
                value={values.email}
                onChange={(value, e) => handleChange(e)}
                onBlur={(value, e) => handleBlur(e)}
                errors={getFormError(form, "email")}
              />
              <PasswordInput
                name="password"
                type="text"
                // label={`${t(`${tPrefix}.password`)}:`}
                placeholder={`${t(`${tPrefix}.password_placeholder`)}...`}
                inverted={inverted}
                labelWidth={95}
                flushLabel
                value={values.password}
                onChange={(value, e) => handleChange(e)}
                onBlur={(value, e) => handleBlur(e)}
                errors={getFormError(form, "password")}
              />
              <TermsAgreement
                style={{ marginTop: "20px" }}
                buttonLabel={t(`${tPrefix}.sign_up`)}
                inverted={inverted}
              />

              {school.consent_description && (
                <Checkbox
                  name="consent"
                  type="checkbox"
                  checked={values.consent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={getFormError(form, "consent")}
                  style={{ display: "inline-flex", marginTop: 10 }}
                  buttonLabel={t(`${tPrefix}.sign_up`)}
                >
                  <Text inverted={inverted}>{school.consent_description}</Text>
                </Checkbox>
              )}
            </AuthWrapper>
            {signUpMutation.error && <Alert error={signUpMutation.error} />}
            <AuthFooter>
              {renderSubmit({
                submit: (
                  <SubmitButton
                    onSubmit={() => handleSubmit(values)}
                    inverted={inverted}
                    styleType="primary"
                    size="large"
                    hideSuccess
                    disabled={!isValid}
                  >
                    {t(`${tPrefix}.sign_up`)}
                  </SubmitButton>
                ),
                onSubmit: () => handleSubmit(values)
              })}
              {onSignIn && (
                <Button
                  onClick={e => {
                    e.stopPropagation()
                    onSignIn()
                  }}
                  inverted={inverted}
                  styleType="blank"
                >
                  {t(`${tPrefix}.already_have_account_prompt`)}{" "}
                  <Pathicon icon="arrow-right" />
                </Button>
              )}
            </AuthFooter>
          </>
        )
      }}
    </Formik>
  )
}

SignUpForm.displayName = "SignUpForm"

SignUpForm.propTypes = {
  renderHeader: PropTypes.func,
  renderSubmit: PropTypes.func,
  inverted: PropTypes.bool,
  onSignIn: PropTypes.func,
  inviteToken: PropTypes.string
}

SignUpForm.defaultProps = {
  renderHeader: ({ header }) => header,
  renderSubmit: ({ submit }) => submit
}

SignUpForm.validate = validate((key, value, values) => {
  switch (key) {
    case "first_name":
      if (!value) return "Please enter a first name."
      if (value.length < 2) return "Please enter a first name that's at least 2 characters." // prettier-ignore
      break
    case "last_name":
      if (!value) return "Please enter a last name."
      if (value.length < 2) return "Please enter a last name that's at least 2 characters." // prettier-ignore
      break
    case "email":
      if (!value) return "Please enter an email."
      if (!emailRe.test(value))
        return "Please enter a valid email address (e.g., buzz@space.com)"
      break
    case "password":
      if (!value) return "Please enter a password."
      if (value.length < 6)
        return "Please enter a password that's at least 6 characters."
      break
  }
})

export default withTranslate(SignUpForm)
