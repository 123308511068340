import React from "react"
import "./AuthorList.css"
import pluralize from "pluralize"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import SortableList from "@pathwright/ui/src/components/sortable/SortableList"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"

const AuthorList = ({ authors, baseURL, height, updateAuthorOrder }) => (
  <ScrollView className="AuthorList" style={{ minHeight: 400 }} fill={true}>
    <SortableList
      list={authors}
      onChange={updateAuthorOrder}
      deferDragHandlingToItem
      renderItem={({ item, isDragging, dragHandleProps }) => {
        return (
          <CardLink
            to={`${baseURL}${item.id}/`}
            label={item.display_name}
            image={item.image}
            preview={`${pluralize("course", item.resources_count, true)}`}
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
          />
        )
      }}
    />
  </ScrollView>
)

AuthorList.displayName = "AuthorList"

export default AuthorList
