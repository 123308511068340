import { useEffect, useRef, useState } from "react"
import useScrollToNode from "./useScrollToNode"

// Scroll to the DOM node of a new item of a list.
const useScrollToMostRecentNode = ({
  list,
  getItemMatch = (item1, item2) => item1 === item2,
  // Minimum number of new items required in list to trigger a scroll.
  threshold = 1,
  scrollOptions = {
    behavior: "smooth",
    block: "center"
  },
  onScrolled
}) => {
  const listRef = useRef(list)
  const [mostRecentItem, setMostRecentItem] = useState(null)
  const scrollToNode = useScrollToNode({ scrollOptions, onScrolled })

  // If the item is found to be a match, we'll set the ref as the mostRecentItemNode.
  const handleItemNode = item => node => {
    if (mostRecentItem && getItemMatch(item, mostRecentItem)) {
      if (node) scrollToNode(node)
    }
  }

  // Compare previous value of list with current value. If current value contains
  // new items, find the last new item in the list and set that as the mostRecentItem.
  // This will trigger the handleItemNode fn to execute again.
  useEffect(() => {
    const prevList = listRef.current

    if (list && prevList && list.length - threshold >= prevList.length) {
      let mostRecentItem = list.find(
        item => !prevList.find(prevItem => getItemMatch(item, prevItem))
      )

      // Item may not have been found in the case where the threshold
      // is set to 0, in which case it may be that no new items exist in the list.
      // In that case, get the last item in the list.
      if (!mostRecentItem) {
        mostRecentItem = list[list.length - 1]
      }

      // Should be guaranteed to have found a match, unless list is empty.
      if (mostRecentItem) {
        setMostRecentItem(mostRecentItem)
      }
    }

    listRef.current = list
  }, [list ? list.length : 0])

  return handleItemNode
}

export default useScrollToMostRecentNode
