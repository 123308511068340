import "./LoadingCircle.css"
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import PathwrightUI from "../ui/PathwrightUI"
import { parseRgba, objectifyRgba } from "../utils/colors"

const LoadingCircle = ({ size, inverted, center, color, brand, ...rest }) => {
  const className = classnames(
    "LoadingCircle",
    {
      "LoadingCircle--center": center,
      "LoadingCircle--inverted": inverted
    },
    rest.className
  )

  return (
    <PathwrightUI.Consumer>
      {({ primaryBrandColor }) => {
        const rgba = objectifyRgba(
          inverted
            ? "255,255,255"
            : color
            ? color
            : brand
            ? primaryBrandColor
            : "150,150,150"
        )

        const alpha = rgba.a || 0.9

        const styles = {
          minWidth: size,
          minHeight: size,
          maxWidth: size,
          maxHeight: size,
          border: `2px solid ${parseRgba({
            ...rgba,
            a: alpha
          })}`,
          borderRightColor: parseRgba({
            ...rgba,
            a: alpha / 2
          }),
          top: center ? `calc(50% - ${size / 2}px)` : "auto",
          ...rest.style
        }
        return <div className={className} style={styles} />
      }}
    </PathwrightUI.Consumer>
  )
}

LoadingCircle.displayName = "LoadingCircle"

LoadingCircle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  inverted: PropTypes.bool,
  center: PropTypes.bool,
  brand: PropTypes.bool
}

LoadingCircle.defaultProps = {
  size: 25,
  center: true
}

export default LoadingCircle
