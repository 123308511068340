import Object from "./Object"
import TextObject from "./TextObject"

export function getSelectedObjects(textObjects, indexes) {
  return textObjects.filter((_, i) => indexes.includes(i))
}

export function getPosition(textObjects) {
  return textObjects.reduce(
    (acc, textObject, i) => {
      acc.top = Math.min(textObject.position.top, acc.top)
      acc.left = Math.min(textObject.position.left, acc.left)
      return acc
    },
    {
      top: 9999999,
      left: 9999999
    }
  )
}

export function getDimensions(textObjects) {
  const position = getPosition(textObjects)

  return textObjects.reduce(
    (acc, textObject, i) => {
      acc.height = Math.max(
        textObject.position.top + textObject.dimensions.height - position.top,
        acc.height
      )
      acc.width = Math.max(
        textObject.position.left + textObject.dimensions.width - position.left,
        acc.width
      )
      return acc
    },
    {
      height: 0,
      width: 0
    }
  )
}

function MultObject({
  textObjects,
  selectedTextObjectIndexes,
  onSelect,
  onDeselect,
  onChange,
  onCopy,
  onDelete,
  onDragStart,
  onDragEnd
}) {
  const selectedTextObjects = getSelectedObjects(
    textObjects,
    selectedTextObjectIndexes
  )
  const position = getPosition(selectedTextObjects)
  const dimensions = getDimensions(selectedTextObjects)

  return (
    <Object
      index={-1} // the mult-object index
      isSelected
      // noop as you can't change the mult-object
      onChange={() => {}}
      onCopy={onCopy}
      onDelete={onDelete}
      onDeselect={onDeselect}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      position={position}
      dimensions={dimensions}
    >
      {textObjects.map((textObject, i) =>
        selectedTextObjectIndexes.includes(i) ? (
          <Object
            key={i}
            index={i}
            isSelected
            onChange={(key, value) => onChange(i, key, value)}
            onSelect={onSelect(i)}
            onDeselect={onDeselect}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            position={{
              top: textObject.position.top - position.top,
              left: textObject.position.left - position.left
            }}
          >
            <TextObject
              index={i}
              textObject={textObject}
              isSelected={selectedTextObjectIndexes.includes(i)}
              autoFocus={false}
              onChange={(key, value) => onChange(i, key, value)}
            />
          </Object>
        ) : null
      )}
    </Object>
  )
}

export default MultObject
