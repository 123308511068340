import InvitationFragment from "./invitation-fragment.js"
import gql from "graphql-tag"

export default gql`
  mutation ResendInvitationMutation($resendInvitationId: Int!) {
    resendInvitation(id: $resendInvitationId) {
      ...InvitationFragment
    }
  }
  ${InvitationFragment}
`
