import gql from "graphql-tag"
import SCHOOL_BILLING_INVOICE_FRAGMENT from "./school-billing-invoice-fragment"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  query SchoolBillingInvoicesQuery($cursor: String) {
    school {
      ...School
      school_billing_invoices(first: 50, after: $cursor) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...SchoolBillingInvoice
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${SCHOOL_BILLING_INVOICE_FRAGMENT}
`
