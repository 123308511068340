import gql from 'graphql-tag'

export default gql`
  query SubdomainQuery($subdomain: String) {
    subdomain(subdomain: $subdomain) {
      id
      subdomain
    }
  }
`
