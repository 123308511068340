import React from "react"
import DatePicker from "@pathwright/ui/src/components/form/form-date-picker/DatePicker"
import Button from "@pathwright/ui/src/components/button/Button"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import Card from "@pathwright/ui/src/components/card/Card"
import { Form, Field, Submit, validators } from "react-connect-form-forked"
const { isNumeric } = validators

const getBoolStr = bool => JSON.stringify(bool || false)

const getUrl = formState => {
  let params = {
    prefix: `${formState.value.codePrefix}${formState.value.codeSuffix}`,
    quantity: formState.value.numCodes,
    discount_percent: formState.value.discountPercentage,
    max_redemptions: formState.value.maxRedemptions || 0,
    max_num_seats: formState.value.maxSeatDiscount,
    one_redemption_per_user: getBoolStr(formState.value.isOnePerUser),
    is_suffix_numeric: getBoolStr(!!formState.value.codeSuffix),
    start_dtime: formState.value.startDtime || "",
    end_dtime: formState.value.endDtime || ""
  }

  // generating coupons for resources
  if (formState.value.hasOwnProperty("allResources")) {
    params = {
      ...params,
      all_courses: getBoolStr(formState.value.allResources),
      course_ids: formState.value.resources
        ? formState.value.resources.map(r => r.id).join(",")
        : "",
      category_ids: formState.value.categories
        ? formState.value.categories.map(c => c.id).join(",")
        : ""
    }
  }

  // generating coupons for subscription plans
  if (formState.value.hasOwnProperty("allPlans")) {
    params = {
      ...params,
      all_plans: getBoolStr(formState.value.allPlans),
      plan_ids: formState.value.plans ? formState.value.plans.join(",") : "",
      num_cycles: formState.value.numCyclesToDiscount
    }
  }

  // get rid of keys with undefined or empty string values
  const paramsPruned = Object.keys(params).reduce((obj, key) => {
    const val = params[key]
    if (val !== undefined && val !== null && val !== "") {
      obj[key] = val
    }
    return obj
  }, {})

  const urlParams = Object.keys(paramsPruned)
    .map(key => `${key}=${paramsPruned[key]}`)
    .join("&")
  const url = `/api/private/coupon/superuser/?${urlParams}`
  return url
}

const GenerateCouponCodes = ({ card, title, children }) => (
  <Card card={card} title={title || "Generate Coupon Codes"}>
    <Form
      initialValue={{
        codePrefix: "",
        codeSuffix: "",
        numCodes: 1,
        discountPercentage: 0.1,
        maxRedemptions: 1,
        maxSeatDiscount: 0,
        isOnePerUser: false,
        allResources: true,
        resources: [],
        categories: [],
        allPlans: true,
        plans: [],
        numCyclesToDiscount: 1
      }}
      render={formState => (
        <form>
          <CardBlock>
            <Fieldset
              title="Code prefix"
              header="A prefix to prepend to the code."
            >
              <Field
                name="codePrefix"
                type="text"
                component={TextInput}
                required
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              title="Code suffix (optional)"
              header="An incrementing integer to append to the code prefix."
            >
              <Field
                name="codeSuffix"
                type="number"
                component={TextInput}
                validators={[value => (value ? isNumeric(value) : "")]}
              />
            </Fieldset>
          </CardBlock>
          {children(formState)}
          <CardBlock>
            <Fieldset
              title="Number of codes"
              header="Quantity determines the number of codes we generate."
            >
              <Field
                name="numCodes"
                type="number"
                autoSize
                component={TextInput}
                required
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset title="Discount percentage" header="0.0...1.0">
              <Field
                name="discountPercentage"
                type="number"
                autoSize
                step=".1"
                component={TextInput}
                validators={[
                  value =>
                    !(value > 0 && value <= 1.0) && "Must be between 0 and 1"
                ]}
                required
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              title="Maximum number of redemptions"
              header="The number of times the coupon can be redeemed."
            >
              <Field
                name="maxRedemptions"
                type="number"
                autoSize
                component={TextInput}
                validators={[isNumeric]}
                required
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              title="Maximum number of seats that may be discounted."
              header="The number of seats that the discount percent can be applied to."
            >
              <Field
                name="maxSeatDiscount"
                type="number"
                autoSize
                component={TextInput}
                validators={[isNumeric]}
                required
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              title="Is one per user"
              header="Limit redemptions to one per user?"
            >
              <Field
                name="isOnePerUser"
                type="boolean"
                component={ToggleInput}
                required
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              title="Start"
              header="Set a start date for when these coupons become available."
            >
              <Field name="startDtime" type="custom" component={DatePicker} />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              title="End"
              header="Set an end date for when these coupons should expire."
              footer="Leave blank if coupons should never expire."
            >
              <Field name="endDtime" type="custom" component={DatePicker} />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                href={getUrl(formState)}
                disabled={!formState.valid || formState.pristine}
              >
                Generate Codes
              </Button>
            </div>
          </CardBlock>
        </form>
      )}
    />
  </Card>
)

GenerateCouponCodes.displayName = "GenerateCouponCodes"

export default GenerateCouponCodes
