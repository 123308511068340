import React from "react"
import { ConnectStores } from "lib/core/store"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import Card from "@pathwright/ui/src/components/card/Card"
import GroupAccessFormContainer from "./GroupAccessFormContainer"

const GroupAccessCard = ({ card, cardProps }) => (
  <Card {...Object.assign({}, cardProps, { card: card })}>
    <GroupAccessFormContainer
      onPristine={() => card.setCardDirty(false)}
      onDirty={() => card.setCardDirty(true)}
    />
  </Card>
)

GroupAccessCard.displayName = "GroupAccessCard"

const mapStateToProps = function(state, ownProps) {
  const {
    is_loading_group,
    is_saving_group,
    group_success
  } = state.groupAdminStore.state

  const cardProps = {
    title: "Availability",
    showStatus: is_loading_group || is_saving_group,
    showLeaveStatus: group_success != null,
    statusMessage: (() => {
      if (is_loading_group) {
        return "Loading..."
      } else if (is_saving_group) {
        return "Saving..."
      }
    })(),
    statusLeaveMessage: group_success ? "Saved" : undefined
  }

  return _.assign(
    {},
    {
      cardProps
    },
    ownProps
  )
}

const connectStores = [
  {
    store: "groupAdmin",
    watch: ["is_loading_group", "is_saving_group", "group"]
  }
]

export default ConnectStores(
  GroupAccessCard,
  connectStores,
  {},
  mapStateToProps
)
