import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { useObserveSize } from "../observers/ObserveSizeContext"
import Tooltip from "../tooltip/Tooltip"

const StyledTooltip = styled(Tooltip)`
  max-width: 100%;
  /* min-wdith: 0 is key in collapsing the width of the OverlayTrigger */
  min-width: 0;
`

const Container = styled.span`
  position: relative;
  overflow: hidden;
  display: block;
  line-height: inherit;
  max-width: 100%;

  ${p =>
    p.lines === 1
      ? `
    text-overflow: ellipsis;
    white-space: nowrap;
  `
      : `
    /* clamp lines with ellipses https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp */
    display: -webkit-box;
    -webkit-line-clamp: ${p.lines};
    -webkit-box-orient: vertical;
  `}
`

const numberTest = /^\d+$/

const TruncatedText = ({ lines, as, children, style, tooltip }) => {
  const [containerClientRect, containerRef, setContainerRef] = useObserveSize()
  const [textIsTruncated, setTextIsTruncated] = useState(false)

  const getTextIsTruncated = useCallback(() => {
    if (containerRef.current) {
      // create a dummy element that will be a clone of our containerRef
      const element = document.createElement(as)
      // get the computed style of the containerRef and clone it onto the dummy element
      const copyStyles = Object.entries(getComputedStyle(containerRef.current))
      copyStyles.forEach(([k, v]) => {
        if (!numberTest.test(k)) {
          element.style[k] = v
        }
      })
      // force the element to have auto width, to get actual width of text
      element.style.width = "auto"
      // precent element from filling the width of its container
      element.style.display = "inline-block"
      // set the text content of the dummy element element
      element.textContent = containerRef.current.textContent
      // append the dummy element to the body
      document.body.appendChild(element)
      // get the marker position, this is the caret position top and left relative to the input
      const elementClientRect = element.getBoundingClientRect()
      // lastly, remove that dummy element
      document.body.removeChild(element)
      // if the potentially truncated text is not the same width as the
      // non-truncated text then that means the potentially truncated text
      // is actually truncated
      return containerClientRect.width * lines < elementClientRect.width
    }

    return false
  }, [containerClientRect, containerRef.current, children, lines])

  useEffect(() => {
    setTextIsTruncated(getTextIsTruncated())
  }, [getTextIsTruncated])

  return (
    <StyledTooltip title={textIsTruncated ? tooltip : null}>
      <Container ref={setContainerRef} as={as} style={style} lines={lines}>
        {children}
      </Container>
    </StyledTooltip>
  )
}

TruncatedText.displayName = "TruncatedText"

TruncatedText.defaultProps = {
  lines: 1,
  as: "span"
}

export default TruncatedText
