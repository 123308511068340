import gql from "graphql-tag"
import SCHOOL_SUBSCRIPTION_FRAGMENT from "./school-subscription-fragment"

export default gql`
  mutation($for_user: ID, $cancel_immediately: Boolean) {
    cancelSchoolSubscription(
      for_user: $for_user
      cancel_immediately: $cancel_immediately
    ) {
      ...SchoolSubscription
    }
  }
  ${SCHOOL_SUBSCRIPTION_FRAGMENT}
`
