import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import View from "@pathwright/ui/src/components/ui/View"
import BrowseContainer from "@pathwright/web/src/modules/library/browse/BrowseContainer"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import { canEditLibrary } from "@pathwright/web/src/modules/user/permissions"
import Footer from "global/footer/components/Footer"
import * as urls from "lib/core/utils/urls"
import React from "react"
import FeatureBanners from "./banner/FeatureBanners"
import "./LibraryBrowse.css"

const LibraryBrowse = ({ children, location }) => {
  const pwContext = usePathwrightContext()

  // ensure school name is set in tab
  React.useEffect(() => {
    App.getStore("navigation").action.setURLTitle(pwContext.school.name)
  }, [])

  return (
    <View.Primary className="LibraryBrowse">
      {!children ? (
        <div className="LibraryBrowse__container">
          <FeatureBanners />
          {canEditLibrary(pwContext) && (
            <Button
              to={urls.modalRoute("/manage/library/")}
              className="LibraryBrowse__edit"
              styleType="secondary"
              inverted
            >
              <Pathicon icon="gear" />
              <span> Library Settings</span>
            </Button>
          )}
          <BrowseContainer urlSearch={location.search} />
          <Footer location="Library" />
        </div>
      ) : (
        children
      )}
    </View.Primary>
  )
}

export default LibraryBrowse
