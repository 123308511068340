import Tag from "@pathwright/ui/src/components/tag/Tag"
import classnames from "classnames"
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { tagAttachmentContextPropType, tagLinkPropType } from "./propTypes"
import useTags from "./useTags"
import { sortTags } from "./utils"

const Container = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

// Accepts a list of tagLinks and orders them based on the tags in context.
const OrderedTagList = ({ className, tagLinks, context, onClickTag }) => {
  const { tags } = useTags({ context })

  const orderedTags = useMemo(() => {
    // Get the tags from tags.selected which will be sortable based on the
    // tag.contextualTagAttachments. Using tags.selected as we are not provding
    // useTags with any selectedTags, in which case useTags automatically selects
    // tags from context.
    const linkedTags = tagLinks
      .map(tagLink => tags.selected.find(tag => tagLink.tag.id === tag.id))
      // At times, tagLinks and tags.selected may not contain matching
      // matching data. One reason would be after a tag has been detatched
      // from the context (via deleting a tagAttachment) it remains linked
      // to an item (via a tagLink) and lives on in the cache until refresh.
      // TODO: try removing the tagLink from the cache if possible.
      .filter(Boolean)
    // Sort those tags, which is the main purpose of OrderedTagList.
    const sortedTags = sortTags(linkedTags)
    return sortedTags
  }, [tagLinks, tags])

  return (
    <Container className={classnames("OrderedTagList", className)}>
      {orderedTags.map(tag => (
        <Tag
          key={tag.name}
          tag={tag}
          onClick={e => {
            if (onClickTag) {
              e.stopPropagation()
              onClickTag(tag)
            }
          }}
        />
      ))}
    </Container>
  )
}

OrderedTagList.displayName = "OrderedTagList"

OrderedTagList.propTypes = {
  tagLinks: PropTypes.arrayOf(tagLinkPropType).isRequired,
  context: tagAttachmentContextPropType
}

export default OrderedTagList
