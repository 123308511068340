import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import IconButton from "../button/IconButton"
import Image from "../image/Image"
import Link from "../link/Link"
import LoadingBlock from "../loading/LoadingBlock"
import Text from "../ui/Text"
import View from "../ui/View"
import "./Course.css"

// component for consistent style/layout
// allows usage for different contexts or purposes

const StyledContainer = styled(View)`
  position: relative;
  width: 100%;
  height: ${(props) => (props.layout === "list" ? "100%" : "auto")};
  display: ${(props) => (props.layout === "list" ? "flex" : "block")};
  cursor: ${(props) => (props.to || props.onClick) && "pointer"};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
`

const StyledLink = styled(Link)`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const StyledCover = styled.div`
  pointer-events: none;
  position: relative;
  overflow: hidden;
  ${(props) =>
    props.layout === "grid" &&
    css`
      padding-top: 75%;
      width: 100%;
      height: 0;
      pointer-events: none;
      border-radius: 10px 10px 0 0;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    `} ${(props) =>
    props.layout === "list" &&
    css`
      flex-shrink: 0;
      height: 100%;
      min-width: 1px;
      border-radius: 10px 0 0 10px;
      img {
        width: auto;
        height: 100%;
      }
    `};
`
const StyledLoadingCover = styled(LoadingBlock)`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const StyledInfoWrapper = styled.div`
  pointer-events: ${(props) => (props.preventInteraction ? "none" : "auto")};
  text-align: left;
  ${(props) =>
    props.layout === "grid" &&
    css`
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100px;
      padding: 5px 10px 10px;
      pointer-events: none;
    `} ${(props) =>
    props.layout === "list" &&
    css`
      flex-grow: 1;
      min-width: 1px;
      padding: 10px;
    `};
`

const StyledInfo = styled.div`
  overflow: hidden;
`

const StyledControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 5px;
  position: relative;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;

  html.no-touch & {
    display: none !important;
  }

  html.no-touch ${StyledContainer}:hover & {
    display: block !important;
  }
`

const Course = ({
  loading,
  course,
  seed,
  to,
  toSettings,
  meta,
  layout,
  children,
  onClick,
  emphasis,
  className,
  controls,
  style,
  displayPrice
}) => {
  if (loading) {
    // const random = seedrandom(seed)
    return (
      <StyledContainer
        emphasis={emphasis}
        className={className}
        layout={layout}
        rounded
        style={style}
      >
        <StyledCover layout={layout}>
          <img alt="aspect ratio helper" src="//dummyimage.com/4x4:3/" />
          <StyledLoadingCover />
        </StyledCover>
        <StyledInfoWrapper className="Course__info" layout={layout}>
          <LoadingBlock
            style={{
              marginTop: 5,
              height: "1em",
              width: `${50 + 0 * 50}%`
            }}
          />
        </StyledInfoWrapper>
      </StyledContainer>
    )
  }
  if (course) {
    return (
      <StyledContainer
        rounded
        className={className}
        emphasis={emphasis}
        onClick={onClick}
        to={to}
        layout={layout}
        style={style}
      >
        {to && <StyledLink to={to} />}
        <StyledCover layout={layout}>
          <Image src={course.image} alt="cover" />
        </StyledCover>
        <StyledInfoWrapper
          className="Course__info"
          layout={layout}
          preventInteraction={!!to}
        >
          <StyledInfo>
            {course.publisher && (
              <Text.Meta lines={1} fade>
                {course.publisher.name}
              </Text.Meta>
            )}
            <Text.H5
              className="theme-heading theme-heading-bold"
              lines={layout === "grid" ? (meta || displayPrice ? 2 : null) : 1}
            >
              {course.name}
            </Text.H5>
            {meta && <Text.Meta lines={1}>{meta}</Text.Meta>}
            {displayPrice && (
              <Text.Meta className="Course__display-price" brand lines={1}>
                {/*
                  Hacky (but simple) way to hide .00;
                  ...better way seen here: https://stackoverflow.com/a/62688921
                  However, our instance of Intl.NumberFormat is constructed in the context provider
                  Also: remove "USD" but display other currencies
                */}
                {displayPrice.replace(".00 ", " ").replace("USD", "")}
              </Text.Meta>
            )}
          </StyledInfo>
          {children}
        </StyledInfoWrapper>
        {layout === "list" && controls && (
          <StyledControls>{controls}</StyledControls>
        )}
        {toSettings && <StyledIconButton to={toSettings} icon="gear" />}
      </StyledContainer>
    )
  }

  return null
}

Course.displayName = "Course"

Course.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  layout: PropTypes.oneOf(["grid", "list"])
}

Course.defaultProps = {
  layout: "grid",
  emphasis: "primary"
}

export default Course
