import { useMutation } from "@apollo/client"
import { formatHex } from "@pathwright/ui/src/components/utils/colors"
import { useCallback } from "react"
import { UPDATE_TAG_MUTATION } from "./graphql"
import { getTagData } from "./utils"

const useUpdateTag = () => {
  const [
    updateTagMutation
    // updateTagMutationState
  ] = useMutation(UPDATE_TAG_MUTATION, {
    optimisticResponse: data => {
      return {
        __typename: "Mutations",
        updateTag: {
          ...getTagData(data.tag),
          // Reformat color, ensuring prepended "#".
          color: formatHex(data.tag.color),
          __typename: "Tag"
        }
      }
    }
  })

  const updateTag = useCallback(
    tag =>
      updateTagMutation({
        variables: {
          tag: getTagData(tag)
        }
      }),
    [updateTagMutation]
  )

  return updateTag
}

export default useUpdateTag
