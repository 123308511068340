// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let SchoolSubscriptionPlanCollection
const SchoolSubscriptionPlan = require("account/subscription/models/school-subscription-plan")
  .default

export default SchoolSubscriptionPlanCollection = (function() {
  SchoolSubscriptionPlanCollection = class SchoolSubscriptionPlanCollection extends require("lib/static-shim").default(
    require("lib/utils/collection").default
  ) {
    static initClass() {
      this.prototype.model = SchoolSubscriptionPlan
    }

    initialize(models, options) {
      this.options = options
      return (this.vc =
        this.options && this.options.vc ? this.options.vc : null)
    }

    url() {
      return this.urlRoot(
        `api/private/subscription/plan/${
          this.vc ? `?${new URLSearchParams(this.vc).toString()}` : ""
        }`
      )
    }

    comparator(plan) {
      return plan.get("plan").interval
    }
  }
  SchoolSubscriptionPlanCollection.initClass()
  return SchoolSubscriptionPlanCollection
})()
