import gql from "graphql-tag"
import PUBLISHER_FRAGMENT from "./publisher-fragment"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  query PublishersQuery($first: Int!, $cursor: String, $school_id: Int!) {
    context(school_id: $school_id) {
      school {
        ...School
        publishers(first: $first, after: $cursor) {
          total
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...Publisher
            }
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${PUBLISHER_FRAGMENT}
`
