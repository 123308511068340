// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const { BILLING_INTERVAL_MONTH } = require("./constants")

class PathwrightAccountStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.resources = {
      schools: {
        collection: require("auth/models/user-school-collection").default
      }
    }

    this.prototype.storeTriggerHandlers = {
      school: {
        "bootstrapped:school"(data) {
          return this.bootstrapStore(data)
        }
      }
    }

    this.prototype.actions = {
      loadUserSchools(forceReload) {
        if (forceReload == null) {
          forceReload = false
        }
        return this.resource.schools.list({}, forceReload)
      }
    }

    this.prototype.requests = {
      hasActivePathwright2Subscription() {
        return this.get("is_activated") && this.get("subscription") != null
      },

      hasActiveLegacySubscription() {
        return this.get("is_on_legacy_plan") && this.get("is_activated")
      },

      hasActivePathwrightSubscription() {
        return (
          this.request.hasActivePathwright2Subscription() ||
          this.request.hasActiveLegacySubscription()
        )
      },

      hasFeatures(features) {
        const {
          is_activated,
          is_on_legacy_plan,
          subscription
        } = this.getState()
        if (!is_activated) {
          return false
        }
        if (is_on_legacy_plan) {
          return true
        }
        if (subscription) {
          return _.every(features, this.request.hasFeature)
        }
        return false
      }
    }
  }

  bootstrapStore({ pathwright_account }) {
    if (pathwright_account) {
      return this._mutate(pathwright_account)
    }
  }
}
PathwrightAccountStore.initClass()

export default window.App.stores.registerStore(
  "pathwrightAccount",
  PathwrightAccountStore
)
