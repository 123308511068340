import { useMutation } from "@apollo/client"
import { getPosition } from "@pathwright/ui/src/components/utils/fractional-positioning"
import { useCallback } from "react"
import { UPDATE_TAG_ATTACHMENT_MUTATION } from "./graphql"
import { getTagAttachment, getTagsContext } from "./utils"

const getTagPosition = (tags, reorderedTags, reorderedTag) => {
  // Get the target index of the tag being moved.
  const index = reorderedTags.findIndex(tag => tag.id === reorderedTag.id)
  // Get the tagAttachment of the tag being moved.
  const tagAttachment = getTagAttachment(reorderedTag)
  // Get the list of tagAttachments associated with the selected tags.
  // This list holds the positions that can be used to determine next position
  // of the tag being moved.
  const tagAttachments = tags.selected.map(getTagAttachment)
  // Get the fractionally indexed position.
  return getPosition(tagAttachments, tagAttachment, index)
}

const useReorderTag = ({ tags, context }) => {
  const tagsContext = getTagsContext(context)

  const [updateTagAttachmentMutation] = useMutation(
    UPDATE_TAG_ATTACHMENT_MUTATION
  )

  // NOTE: currently only updating fragment in cache, need backend
  // to accept position in createTgAttachment and updateTgAttachment mutations.
  const reorderTag = useCallback(
    (reorderedTags, reorderedTag) => {
      const tagAttachment = getTagAttachment(reorderedTag)
      const position = getTagPosition(tags, reorderedTags, reorderedTag)

      // Noop if position has not changed.
      if (position === tagAttachment.position) return

      return updateTagAttachmentMutation({
        variables: {
          tagId: reorderedTag.id,
          context: tagsContext,
          position
        },
        optimisticResponse: {
          __typename: "Mutations",
          updateTagAttachment: {
            ...tagAttachment,
            position,
            __typename: "TagAttachment"
          }
        }
      })
    },
    [tags]
  )

  return reorderTag
}

export default useReorderTag
