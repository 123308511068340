import React from "react"
import PropTypes from "prop-types"
import pluralize from "pluralize"
import Button from "@pathwright/ui/src/components/button/Button"
import Text from "@pathwright/ui/src/components/ui/Text"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import { withManageGroupsQuery } from "../graphql/ManageGroupsQuery"

const ManageGroups = props => {
  const {
    children,
    groups,
    total,
    loading,
    hasMore,
    loadMore,
    handleDebouncedSearch,
    debouncedSearch,
    searchThreshold
  } = props

  const hasGroups = groups && groups.length > 0
  const showSearch =
    debouncedSearch ||
    (hasGroups && groups.length >= searchThreshold && total > 1)

  return (
    <div>
      {showSearch && (
        <CardBlock borderTop={false}>
          <SearchInput
            placeholder={`Search ${total} ${pluralize("cohort", total)}...`}
            onChange={handleDebouncedSearch}
            onClear={() => handleDebouncedSearch("")}
          />
          {!!debouncedSearch && (
            <Text.Meta>
              {" "}
              {total} {pluralize("cohort", total)}
            </Text.Meta>
          )}
        </CardBlock>
      )}
      <ScrollView>
        {hasGroups ? (
          children(groups)
        ) : loading ? (
          <LoadingCircle />
        ) : debouncedSearch ? (
          <BlankSlate heading={`No results for "${debouncedSearch}".`} />
        ) : (
          <BlankSlate heading="No cohorts are taking this course yet." />
        )}
        {hasGroups && hasMore && (
          <CardBlock borderTop={false}>
            {loading ? (
              <div style={{ margin: "15px 0", position: "relative" }}>
                <LoadingCircle />
              </div>
            ) : (
              <Button styleType="empty" stretch onClick={loadMore}>
                Load More
              </Button>
            )}
          </CardBlock>
        )}
      </ScrollView>
    </div>
  )
}

ManageGroups.displayName = "ManageGroups"
ManageGroups.propTypes = {
  children: PropTypes.func.isRequired
}
ManageGroups.defaultProps = {
  // Number of groups necessary before search input is displayed
  searchThreshold: 15
}

export default withManageGroupsQuery(ManageGroups)
