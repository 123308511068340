require("./avatar-legacy.css")

const proto = Object.create(HTMLElement.prototype)

proto.createdCallback = function() {
  this.innerHTML = "<a><img></a>"
  this.readAttributes()
  this.setHref()
  this.setSize()
  return this.display()
}

proto.attachedCallback = function() {
  return this.addEventListener("click", this.clicked, true)
}

proto.detachedCallback = function() {
  return this.removeEventListener("click", this.clicked, true)
}

// proto.attributeChangedCallback = (attr, oldVal, newVal)->
//   @setSize newVal if attr is "size"

proto.clicked = function() {}
// Pathwright.vent.trigger("")

proto.readAttributes = function() {
  return (() => {
    const result = []
    for (
      let i = 0, end = this.attributes.length - 1, asc = 0 <= end;
      asc ? i <= end : i >= end;
      asc ? i++ : i--
    ) {
      let key = this.attributes[i].name.split("-")
      const add = Array.from(key.slice(1)).map(
        substr => substr[0].toUpperCase() + substr.slice(1)
      )
      key = key[0] + add
      result.push((this[key] = this.attributes[i].value))
    }
    return result
  })()
}
// TODO: allow gravatar via email if no src

proto.setHref = function() {
  // TODO: allow for a URL attribute to override this
  this.querySelector("a").setAttribute("href", "javascript:void(0)")
  return this.classList.add("no-link")
}

proto.setSize = function(x) {
  const classes = ["x-small", "small", "medium", "large", "x-large"]
  // Set size based on size or src attribute unlass a class size has been set
  if (
    Array.from(classes)
      .filter(klass => !this.classList.contains(klass))
      .map(klass => klass).length === classes.length
  ) {
    // finding matches in the src attribute for width=num or numXnum, else use @size
    x =
      x ||
      (this.size
        ? this.size
        : __guard__(
            this.src != null ? this.src.match(/(width)=\d+/g) : undefined,
            x1 => x1[0].split("=")[1]
          ) ||
          __guard__(
            this.src != null ? this.src.match(/\d+x\d+/) : undefined,
            x2 => x2[0].split("x")[0]
          ) ||
          (() => {
            throw Error("avatar width/height cannot be set")
          })())
    x = +x
    // style anchor tag
    const a = $(this.querySelector("a"))
    return a.css({
      width: `${x}px`,
      height: `${x}px`,
      "line-height": `${x + x / 30}px`,
      "font-size": `${x / 2.1425}px`
    })
  }
}

proto.display = function() {
  if (this.src && !/generic_defaults/.test(this.src)) {
    return this.querySelector("img").setAttribute("src", this.src)
  } else if (this.firstName) {
    this.classList.add("initials")
    return (this.querySelector("a").innerHTML = this.getInitials())
  }
}

proto.getInitials = function() {
  return (
    (this.firstName != null ? this.firstName[0].toUpperCase() : undefined) +
    (this.lastName != null ? this.lastName[0].toUpperCase() : undefined)
  )
}

document.registerElement("pw-avatar", { prototype: proto })

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
