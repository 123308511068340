import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import HtmlText from "@pathwright/ui/src/components/text/HtmlText"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { usePreload } from "@pathwright/ui/src/components/utils/preload"
import { media } from "@pathwright/ui/src/components/utils/styles"
import PropTypes from "prop-types"
import styled from "styled-components"
import BlockContent from "../../blocks/BlockContent"
import withContent from "../../path/legacy/content/withContent"
import { shouldRevealCompletionFeedback } from "../../path/utils"
import withCompletion from "../graphql/withCompletion"
import CompletionFailedAttempt from "./CompletionFailedAttempt"

const Wrapper = styled(View)`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 65px;
  min-height: ${p => (p.fullscreen ? "100vh" : "100%")};
  width: 100%;
  backface-visibility: hidden;
  background-color: #999;
  ${media.max.phone`padding: 10px 55px;`};
`
const Container = styled(View.Primary)`
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
`
const FileLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  .Pathicon {
    font-size: 28px;
  }
  h5 {
    flex-grow: 1;
    margin: 0 10px;
    overflow: hidden;
    word-break: break-all;
  }
`

const FileSubmissionPreview = ({ submission }) => {
  if (!submission) return null

  if (submission.file_url) {
    return (
      <FileLink
        target="_blank"
        download={submission.file_name}
        href={submission.file_url}
      >
        <Pathicon icon="file" />
        <Text.H5>{submission.file_name}</Text.H5>
        <Pathicon icon="arrow-down-circle" />
      </FileLink>
    )
  }
  return null
}

const TextSubmissionPreview = ({ submission }) => {
  if (!submission) return null

  if (submission.text) {
    return <HtmlText html={submission.text} theme={true} />
  }
  return null
}

// HACK ALERT: requiring legacy assessment preview which is only
// set on window once the LazyLibraryRoutes have loaded.
const AssessmentSubmissionPreview = props => {
  const [preloading] = usePreload({
    Component: window.LazyLibraryRoutes,
    preload: true
  })

  if (preloading) return null

  // NOTE: rendering window.AssessmentSubmissionPreview directly fails in legacy/web
  const AssessmentSubmissionPreview = window.AssessmentSubmissionPreview
  return <AssessmentSubmissionPreview {...props} />
}

const LegacyContent = withContent(({ step, loading, content }) => {
  if (loading || !content) return null

  const { submission } = step.completion

  switch (content.method) {
    case "assessment_editor":
      return (
        <AssessmentSubmissionPreview
          stepId={step.id}
          contentId={content.id}
          userId={step.completion.user.id}
        />
      )
    case "submit_file":
      return <FileSubmissionPreview submission={submission} />
    case "submit_text":
      return <TextSubmissionPreview submission={submission} />
    case "rt":
      return <div dangerouslySetInnerHTML={{ __html: content.reading_text }} />
    case "task":
      return <div dangerouslySetInnerHTML={{ __html: content.task }} />
    case "listed":
      return (
        <div
          dangerouslySetInnerHTML={{ __html: content.listed_instructions }}
        />
      )
  }

  return null
})

LegacyContent.displayName = "LegacyContent"

// Displays preview of submission (if exists and previewable)
export const CompletionPreview = ({ step, stepId, fullscreen, me }) => {
  if (!step || step.id !== stepId) {
    return null
  }

  const { is_complete } = step.completion

  // Determine what (if anything) to show
  let preview = null

  // Blocks should always be previewable (except by the step's learner),
  //  even if not complete
  const blocksIsPreviewable =
    step.content_type === "blocks" &&
    ((!!me && me.id !== step.completion.user.id) ||
      step.permissions.can_view_content)

  if (is_complete || blocksIsPreviewable) {
    switch (step.content_type) {
      case "blocks":
        const blocksProps = {
          contextKey: `/${step.source_id}`,
          userID: step.completion.user.id,
          mode: "VIEW",
          templateVariables: {
            step,
            user: step.completion.user,
            school: window.App
              ? window.App.getStore("school").getState().school
              : {},
            resource: window.App
              ? window.App.getStore("resource").getState().resource
              : {},
            group: window.App
              ? window.App.getStore("resource").getState().offeringresource
              : {},
            path: window.App ? window.App.getStore("path").getState().path : {}
          },
          disableInput: true,
          hideAssessmentFeedback: !shouldRevealCompletionFeedback(me, step),
          onSave: (blocks) => {
            // console.log("blocks saved in Completion Preview", blocks)
          },
          // 10.21.19 - disable changing answers completely for all users
          // (later, allow staff members reset an assessment)
          // !me || !!(step.completion.user && step.completion.user.id === me.id)
        }
        preview = <BlockContent key={`blocks-feedback-${step.completion.user.id}-${step.id}`} {...blocksProps} />
        break
      default:
        preview = (
          <div style={{ padding: 20 }}>
            <LegacyContent
              step={step}
              stepId={step.id}
              id={step.content_id}
              type={step.assignment_type}
            />
          </div>
        )
    }
  }

  if (preview) {
    return (
      <Wrapper className="CompletionPreview" fullscreen={fullscreen}>
        <Container rounded shadow>
          {preview}
          {step.completion.has_failed_passing_attempt && (
            <CompletionFailedAttempt step={step} />
          )}
        </Container>
      </Wrapper>
    )
  }

  return null
}

CompletionPreview.displayName = "CompletionPreview"

CompletionPreview.propTypes = {
  // used to query completion
  stepId: PropTypes.number.isRequired
}

export default withCompletion(CompletionPreview)
