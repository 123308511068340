// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const PaginatedCollection = require("lib/core/paginated-collection").default

const isModel = PotentialModel => isResourceModel(new PotentialModel())

const isCollection = PotentialCollection =>
  isResourceCollection(new PotentialCollection())

var isResourceModel = resource => resource instanceof Backbone.Model

var isResourceCollection = resource => resource instanceof Backbone.Collection

const isPaginatedCollection = PotentialPaginatedCollection =>
  isResourcePaginatedCollection(new PotentialPaginatedCollection())

var isResourcePaginatedCollection = resource =>
  resource instanceof PaginatedCollection

export {
  isModel,
  isResourceModel,
  isCollection,
  isResourceCollection,
  isPaginatedCollection,
  isResourcePaginatedCollection
}
