// util for removing wrapping HTML chars from richtext value
export function stripHTML(html) {
  var tmp = document.createElement("div")
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ""
}

export function isHTMLEmpty(html) {
  if (!html) return true
  const $html = document.createElement("div")
  $html.innerHTML = html
  const empty = $html.querySelectorAll("p:empty,div:empty,span:empty")
  Array.prototype.forEach.call(empty, el => {
    el.remove()
  })
  return $html.innerHTML.trim().length < 1
}

export function responsifyKatex(containerEl) {
  const elements = document.getElementsByClassName("katex")
  return (() => {
    const result = []
    for (let el of Array.from(elements)) {
      let item
      const kw = el.offsetWidth + el.offsetLeft
      const cw = containerEl.offsetWidth
      if (kw > cw) {
        const percShrink = Math.floor((cw / kw) * 100)
        el.style.fontSize = `${percShrink}%`
        item = el
      }
      result.push(item)
    }
    return result
  })()
}

export const calcFontSizeFromCharacterCount = (html, {
  minSize = 12,
  maxSize = 24,
  minCharCount = 40,
  maxCharCount = 280
} = {}) => {
  // extract text from html: https://stackoverflow.com/questions/28899298/extract-the-text-out-of-html-string-using-javascript/28899585
  const span = document.createElement("span")
  // overflow: hidden is needed to correctly calculate the content height; otherwise, the Quill editor is included in the calculation.
  span.style.overflow = "hidden"
  span.innerHTML = html
  const text = span.textContent || span.innerText

  const count = text.length

  if (count <= minCharCount) return maxSize
  if (count >= maxCharCount) return minSize

  const adjustedCount = count - minCharCount
  const range = maxCharCount - minCharCount
  const fontSizeRange = maxSize - minSize

  const fontSize = Math.round(
    maxSize - adjustedCount / (range / fontSizeRange)
  )

  if (fontSize <= minSize) return minSize
  if (fontSize >= maxSize) return maxSize

  return fontSize
}
