// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let OfferingCollection
export default OfferingCollection = (function() {
  OfferingCollection = class OfferingCollection extends require("lib/static-shim").default(
    require("lib/core/paginated-collection").default
  ) {
    static initClass() {
      this.prototype.model = require("./offering").default
    }

    initialize(models, options) {
      if (options == null) {
        options = {}
      }
      this.resource_id = options.resource
      return super.initialize(models, options)
    }

    url() {
      const params = this.getPaginationParams()
      delete params.resource
      return this.urlRoot(
        `api/private/library/resource/${this.resource_id}/offering/?${$.param(
          params
        )}`
      )
    }
  }
  OfferingCollection.initClass()
  return OfferingCollection
})()
