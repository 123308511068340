import React from "react"
import "./ColorPicker.css"
import color from "react-color/lib/helpers/color"
const { isValidHex } = color
import { CustomPicker } from "react-color"
import { Hue, Alpha, Saturation } from "react-color/lib/components/common"
import ColorSwatches from "./ColorSwatches"
import ColorInput from "./ColorInput"

const CirclePointer = () => <div className="ColorPicker__pointer--circle" />

const OutlinePointer = () => <div className="ColorPicker__pointer--outline" />

class ColorPicker extends React.Component {
  handleChange = data => {
    return this.props.onChange(data)
  }

  handleInput = data => {
    if (data.hex && isValidHex(data.hex)) {
      return this.props.onChange({
        hex: data.hex,
        source: "hex",
      })
    } else if (data.r || data.g || data.b) {
      return this.props.onChange({
        r: data.r || this.props.rgb.r,
        g: data.g || this.props.rgb.g,
        b: data.b || this.props.rgb.b,
        source: "rgb",
      })
    } else if (data.a) {
      if (data.a < 0) {
        data.a = 0
      } else if (data.a > 100) {
        data.a = 100
      }
      return this.props.onChange({
        h: this.props.hsl.h,
        s: this.props.hsl.s,
        l: this.props.hsl.l,
        a: data.a / 100,
        source: "rgb",
      })
    }
  }

  render() {
    return (
      <div className="ColorPicker">
        <div className="ColorPicker__fields">
          {this.props.saturation ? (
            <div className="ColorPicker__saturation">
              <Saturation
                {...Object.assign({}, this.props, {
                  pointer: OutlinePointer,
                  onChange: this.handleChange,
                })}
              />
            </div>
          ) : (
            undefined
          )}
          {this.props.hue ? (
            <div className="ColorPicker__hue">
              <Hue
                {...Object.assign({}, this.props, {
                  pointer: CirclePointer,
                  onChange: this.handleChange,
                })}
              />
            </div>
          ) : (
            undefined
          )}
          {this.props.alpha ? (
            <div className="ColorPicker__alpha">
              <Alpha
                {...Object.assign({}, this.props, {
                  pointer: CirclePointer,
                  onChange: this.handleChange,
                })}
              />
            </div>
          ) : (
            undefined
          )}
        </div>
        <div className="ColorPicker__inputs">
          {this.props.hex ? (
            <div className="ColorPicker__inputs_hex">
              <ColorInput
                type="hex"
                value={this.props.hex.replace("#", "")}
                onChange={hex => this.handleInput({ hex })}
              />
            </div>
          ) : (
            undefined
          )}
          {this.props.rgb ? (
            <div className="ColorPicker__inputs_rgb">
              <ColorInput
                type="r"
                value={this.props.rgb.r}
                onChange={r => this.handleInput({ r })}
              />
              <ColorInput
                type="g"
                value={this.props.rgb.g}
                onChange={g => this.handleInput({ g })}
              />
              <ColorInput
                type="b"
                value={this.props.rgb.b}
                onChange={b => this.handleInput({ b })}
              />
              {this.props.rgba ? (
                <ColorInput
                  type="a"
                  value={Math.round(this.props.rgb.a * 100)}
                  onChange={a => this.handleInput({ a })}
                />
              ) : (
                undefined
              )}
            </div>
          ) : (
            undefined
          )}
        </div>
        {this.props.swatches ? (
          <div className="ColorPicker__swatches">
            <ColorSwatches onClick={hex => this.handleInput({ hex })} />
          </div>
        ) : (
          undefined
        )}
      </div>
    )
  }
}

ColorPicker.displayName = "ColorPicker"

ColorPicker.defaultProps = {
  saturation: true,
  hue: true,
  swatches: true,
  alpha: true,
  hex: true,
  rgb: true,
  rgba: true,
}

export default CustomPicker(ColorPicker)
