import PropTypes from "prop-types"
import React from "react"
import "./FieldWrapper.css"
import classnames from "classnames"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"

// - Handles styling
//   - can be configured for nested or inline display

// - Handles focus
//   - focus state handled internally
//   - provides update callbacks to connect child

// - Handles status
//   - shows error messages, success updates
//   - can display a custom status (e.g. chars remaining)

class FieldWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = { focus: props.autoFocus }
  }

  componentDidMount() {
    return _.delay(() => {
      return this.handleMaybeAutocomplete()
    }, 10)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // reset state if field becomes pristine
    if (nextProps.pristine && nextProps.pristine !== this.props.pristine) {
      return this.setState({
        focus: this.props.autoFocus
      })
    }
  }

  handleMaybeAutocomplete = () => {
    // determine if field mounted w/ autocompleted value
    const value = this.inputRef != null ? this.inputRef.value : undefined
    if (value && !this.props.value) {
      return this.props.onChange(value)
    }
  }

  handleFocus = e => {
    if (typeof this.props.onFocus === "function") {
      this.props.onFocus(this.props.value, e)
    }
    if (!this.state.focus) {
      return this.setState({ focus: true })
    }
  }

  handleBlur = e => {
    if (typeof this.props.onBlur === "function") {
      this.props.onBlur(this.props.value, e)
    }
    if (this.state.focus) {
      return this.setState({ focus: false })
    }
  }

  render() {
    let {
      id,
      label,
      value,
      inline,
      minimal,
      required,
      children,
      inverted,
      topLabel,
      placeholder,
      showLoading,
      showSuccess,
      showTooltip,
      showCustom,
      showStatus,
      showError,
      labelWidth,
      errorMessage,
      className,
      hideLabel,
      hideStatus
    } = this.props
    const { focus } = this.state

    className = classnames(
      "FieldWrapper",
      {
        "FieldWrapper--focus": focus,
        "FieldWrapper--error": showError,
        "FieldWrapper--success": showSuccess,
        "FieldWrapper--inline": inline,
        "FieldWrapper--minimal": minimal,
        "FieldWrapper--inverted": inverted,
        "FieldWrapper--toplabel": topLabel
      },
      className
    )

    const passProps = {
      focus,
      placeholder,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      registerInput: node => {
        return (this.inputRef = node)
      }
    }

    return (
      <div className={className}>
        {label && !hideLabel && !inline ? (
          <label
            htmlFor={id}
            className="FieldWrapper__label"
            style={{ width: labelWidth }}
          >
            {label}
            {showTooltip ? (
              <Tooltip
                className="FieldWrapper__tooltip"
                transition="transition.fadeIn"
                placement="right"
                title={showTooltip}
              >
                <Pathicon icon="info-circle" />
              </Tooltip>
            ) : (
              undefined
            )}
          </label>
        ) : (
          undefined
        )}
        <div className="FieldWrapper__field">
          <div className="FieldWrapper__input">
            {_.isFunction(children) ? children(passProps) : children}
            {!hideStatus && (showLoading || showError || showStatus) ? (
              <div className="FieldWrapper__status">
                <span>
                  {(() => {
                    if (showLoading) {
                      return (
                        <LoadingCircle
                          className="FieldWrapper__loader"
                          inverted={inverted}
                          center={false}
                          size={10}
                        />
                      )
                    } else if (showError) {
                      return errorMessage
                    }
                  })()}
                </span>
                {showStatus ? <span>{showStatus}</span> : undefined}
              </div>
            ) : (
              undefined
            )}
          </div>
          {showCustom ? (
            <div className="FieldWrapper__custom">{showCustom}</div>
          ) : (
            undefined
          )}
        </div>
      </div>
    )
  }
}

FieldWrapper.displayName = "FieldWrapper"

FieldWrapper.propTypes = {
  label: PropTypes.string,
  focus: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.any,
  showError: PropTypes.bool,
  showStatus: PropTypes.any,
  showSuccess: PropTypes.bool,
  // width of left aligned label.
  // overridden on mobile or topLabel.
  labelWidth: PropTypes.number,
  errorMessage: PropTypes.string,
  // deprecated -> simply don't pass `label` prop
  hideLabel: PropTypes.bool,
  // hide status messages like error and loading
  hideStatus: PropTypes.bool
}

FieldWrapper.defaultProps = { labelWidth: 100 }

export default FieldWrapper
