import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import IconButton from "../../button/IconButton"
import BulletSeparation from "../../ui/BulletSeparation"
import { BORDER_GRAY, SECONDARY_GRAY } from "../../utils/colors"
import TagForm from "../form/TagForm"
import { tagPropType, tagsPropType } from "../propTypes"
import Tag from "../Tag"

const TagItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.5em 0;

  /* Hiding controls on hover screens when not hovering of TagItem. */
  &:not(.TagManagerListItem__is-dragging) {
    border-bottom: 1px solid ${BORDER_GRAY};

    @media (hover: hover) {
      &:not(:hover) .TagManagerListItem__controls {
        visibility: hidden;
      }
    }
  }

  .Tag {
    margin: 0 !important;
  }
`

const TagItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const TagMeta = styled(BulletSeparation)`
  margin-top: 0.4em;
  font-size: 0.8em;
  color: ${SECONDARY_GRAY};

  &:empty {
    display: none;
  }
`

const TagItemControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`

const _getTagMetaLabels = (tag, getMetaLabels) => {
  // For list of meta labels.
  const metaLabels = []
  // We'll always include the tag.description if available.
  if (tag.description) metaLabels.push(`"${tag.description}"`)
  // Insert extra meta items returned from getMetaLabels.
  if (getMetaLabels) metaLabels.push(...[].concat(getMetaLabels(tag)))
  // Ensure values are at least truthy.
  return metaLabels.filter(Boolean)
}

const TagManagerListItem = React.forwardRef(
  (
    {
      tags,
      tag,
      tagFormLabels,
      getTagMetaLabels,
      updateTag,
      removeTag,
      draggableProps,
      dragHandleProps,
      draggableSnapshot
    },
    tagItemRef
  ) => {
    const tagFormRef = useRef(null)
    const [isEditing, setIsEditing] = useState(false)
    const metaLabels = _getTagMetaLabels(tag, getTagMetaLabels)

    // Scroll to TagForm when editing.
    useEffect(() => {
      if (isEditing)
        tagFormRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center"
        })
    }, [isEditing])

    return isEditing ? (
      <TagForm
        ref={tagFormRef}
        tags={tags}
        tag={tag}
        labels={tagFormLabels}
        onSubmit={newTag => {
          updateTag({ ...tag, ...newTag }, tag)
          // Exit TagForm after submitting.
          setIsEditing(false)
        }}
        onClose={() => setIsEditing(false)}
      />
    ) : (
      <TagItem
        className={classnames("TagManagerListItem", {
          "TagManagerListItem__is-dragging": draggableSnapshot.isDragging
        })}
        ref={tagItemRef}
        {...draggableProps}
      >
        <TagItemDetails>
          <Tag tag={tag} />
          <TagMeta>
            {metaLabels.map((metaLabel, i) => (
              <span key={i}>{metaLabel}</span>
            ))}
          </TagMeta>
        </TagItemDetails>
        <TagItemControls className="TagManagerListItem__controls">
          {!!updateTag && (
            <IconButton
              onClick={() => setIsEditing(true)}
              className="TagItemControl"
              styleType="tertiary"
              icon="edit"
            />
          )}
          {/* NOTE: using "div" as element for dragHandleProps works instead of using default "button". */}
          {Boolean(Object.keys(dragHandleProps).length) && (
            <IconButton
              {...dragHandleProps}
              el="div"
              className="TagItemControl"
              styleType="tertiary"
              icon="arrow-double-vertical-2"
            />
          )}
          {!!removeTag && (
            <IconButton
              onClick={() => removeTag(tag)}
              className="TagItemControl"
              styleType="tertiary"
              icon="x"
            />
          )}
        </TagItemControls>
      </TagItem>
    )
  }
)

TagManagerListItem.displayName = "TagManagerListItem"

TagManagerListItem.propTypes = {
  tags: tagsPropType,
  tag: tagPropType,
  tagFormLabels: TagForm.propTypes.labels,
  // Must be a function that returns an array of strings.
  getMetaLabels: PropTypes.func,
  updateTag: PropTypes.func,
  removeTag: PropTypes.func,
  draggableProps: PropTypes.object,
  dragHandleProps: PropTypes.object,
  draggableSnapshot: PropTypes.object
}

TagManagerListItem.defaultProps = {
  getMetaLabels: () => {},
  draggableProps: {},
  dragHandleProps: {},
  draggableSnapshot: {}
}

export default TagManagerListItem
