import {
  ChakraProps,
  FormControl,
  FormErrorMessage,
  Input,
  InputProps
} from "@chakra-ui/react"
import { FieldConfig, Field as FormikField, useFormikContext } from "formik"

// Expirimental: the idea is to map a Zod schema to props that can
// be passed to the corresponding field. This might work well if we
// decide to generate forms based on Zod schemas. Essentially, use this
// to generate `{field}Config` props that can override field configs on a form.
export type SchemaFieldProps<
  SchemaT extends object,
  FieldT extends typeof Field<any>
> = Partial<{
  [P in keyof SchemaT as `${string & P}Config`]: Partial<Parameters<FieldT>[0]>
}>

function Field<T extends ChakraProps>(props: FieldConfig & T): JSX.Element {
  const form = useFormikContext<{}>()

  return (
    <FormControl
      isInvalid={
        !!form.errors[props.name] &&
        form.touched[props.name] &&
        form.values[props.name] !== form.initialValues[props.name]
      }
    >
      <FormikField as={Input} id={props.name} variant="filled" {...props} />
      <FormErrorMessage>{form.errors[props.name]}</FormErrorMessage>
    </FormControl>
  )
}

export type InputField = typeof Field<InputProps>
export type InputFieldProps = Parameters<InputField>[0]

export type SchemaInputFieldProps<SchemaT extends object> = SchemaFieldProps<
  SchemaT,
  InputField
>

export const InputField: InputField = (props) => (
  <Field type="text" {...props} as={Input} />
)

export default Field
