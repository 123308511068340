import React from "react"
import Text from "@pathwright/ui/src/components/ui/Text"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Link from "@pathwright/ui/src/components/link/Link"
import Image from "@pathwright/ui/src/components/image/Image"
import BlankImage from "@pathwright/ui/src/components/blank/BlankImage"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import { List, Item, Row, Icon } from "@pathwright/ui/src/components/picker/ui"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { getPrimaryColor } from "@pathwright/ui/src/components/utils/colors"
import SchoolQuery from "../../school/graphql/SchoolQuery"

export const LicensorLibraryPickerBlankSlate = ({
  licensorId,
  onOpenStore
}) => {
  const { t } = useTranslate()

  if (!licensorId) return null

  return (
    <SchoolQuery id={licensorId}>
      {({ school: licensor }) =>
        !!licensor && (
          <BlankSlate
            illustration={<BlankImage image="library" />}
            heading={t("Nothing is in your library")}
            body={t(
              `Looking for something to teach? Get ready-to-teach curriculum from {{ licensorSchoolName }}`,
              {
                licensorSchoolName: licensor.name
              }
            )}
            primaryAction={{
              children: t("Find Curriculum"),
              onClick: () => onOpenStore && onOpenStore(),
              to: `/library/store/${licensor.id}/`
            }}
          />
        )
      }
    </SchoolQuery>
  )
}

const LicensorLibraryPickerPrompt = ({ licensorId, onOpenStore }) => {
  const { t } = useTranslate()

  if (!licensorId) return null

  return (
    <SchoolQuery id={licensorId}>
      {({ school: licensor }) =>
        !!licensor && (
          <List>
            <Item>
              <Link
                onClick={() => onOpenStore && onOpenStore()}
                to={`/library/store/${licensor.id}/`}
              />
              <Row>
                <Icon>
                  <Image src={licensor.image} />
                </Icon>
                <Text.H5 style={{ color: getPrimaryColor(licensor.theme) }}>
                  {t("Get from {{ licensorSchoolName }}", {
                    licensorSchoolName: licensor.name
                  })}
                </Text.H5>
              </Row>
              <Row>
                <Pathicon
                  icon="chevron-right"
                  style={{ color: getPrimaryColor(licensor.theme) }}
                />
              </Row>
            </Item>
          </List>
        )
      }
    </SchoolQuery>
  )
}

LicensorLibraryPickerPrompt.displayName = "LicensorLibraryPickerPrompt"

export default LicensorLibraryPickerPrompt
