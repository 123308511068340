import { Formik, useFormikContext } from "formik"
import merge from "lodash/merge"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import isEqual from "react-fast-compare"
import styled from "styled-components"
import IconButton from "../../button/IconButton"
import SubmitButton from "../../button/SubmitButton"
import ColorPickerToggle from "../../form/form-color/ColorPickerToggle"
import TextInput from "../../form/form-text-input/TextInput"
import TextArea from "../../form/form-textarea/TextArea"
import { getFormError, validate } from "../../form/utils"
import usePreviousEffect from "../../hooks/usePreviousEffect"
import { useTranslate } from "../../lng/withTranslate"
import { tagPropType, tagsPropType } from "../propTypes"
import {
  getTagNameAlreadyExists,
  useDefaultTag,
  useMostRecentColorPickerSwatches
} from "../utils"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: whitesmoke;
  padding: 10px;

  .ColorPickerToggle {
    margin-right: 0.5em;
    width: unset !important;
  }

  .SubmitButton {
    margin-top: 1em;
  }
`

const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .ColorPickerToggle {
    flex-grow: 0;
  }

  .TextInput {
    flex-grow: 1;
  }
`

// handle when form values change
const FormikOnChange = ({ onChange }) => {
  const { values } = useFormikContext()

  usePreviousEffect(
    ([prevValues]) => {
      if (onChange && !isEqual(values, prevValues)) onChange(values)
    },
    [values]
  )
  return null
}

const TagForm = React.forwardRef(
  ({ tag, tags, labels, onSubmit, onChange, onClose }, ref) => {
    const { t } = useTranslate()
    // User isUpdating tag if initial tag.name matches existing tag.
    // The assumption is that the form will never be accessible when
    // creating new tag if the tag.name already exists.
    const isUpdating = useRef(getTagNameAlreadyExists(tag.name, tags)).current

    const [
      colorPickerSwatches,
      updateColorPickerSwatches
    ] = useMostRecentColorPickerSwatches()

    // Supply default color based on the most recently used ColorPicker swatch.

    const validateForm = validate((key, value, values) => {
      switch (key) {
        case "name":
          // name is required
          if (!value) return t("A name is required.")
          // name must not match an already existing tag, but may
          // match the initial tag name provided
          if (getTagNameAlreadyExists(value, tags) && !isUpdating)
            return t(`"${value}" already exists.`)

          if (value.length > 80) return `${value.length}/80`
          break
        case "description":
          if (value && value.length > 140) return `${value.length}/140`
      }
    })

    // Allow for overriding placeholder texts.
    const { name: nameLabel, prompt: promptLabel } = merge(
      {
        name: t("Enter a name"),
        prompt: t("Add a prompt (optional)")
      },
      labels
    )

    const initialValues = useDefaultTag(tag)

    return (
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        // Ensures form is validated after being reinitialized due to props change.
        validateOnMount
        // Controlled tag name.
        enableReinitialize
      >
        {form => (
          <Form
            ref={ref}
            className="TagForm"
            onSubmit={e => e.preventDefault()}
          >
            <FormikOnChange onChange={onChange} />
            <FormRow>
              <IconButton icon="x" onClick={() => onClose()} />
            </FormRow>
            <FormRow>
              <ColorPickerToggle
                name="color"
                value={form.values.color}
                placement="bottom"
                alignment="left"
                format="hex"
                enableAlpha={false}
                swatches={colorPickerSwatches.list}
                onChange={value =>
                  form.setFieldValue("color", value, false /* validate */)
                }
                // onChangeComplete={value => form.setFieldValue("color", value, false /* validate */)}
              />
              <TextInput
                name="name"
                placeholder={nameLabel}
                value={form.values.name}
                errors={getFormError(form, "name")}
                onChange={(value, e) => form.handleChange(e)}
                onBlur={(value, e) => form.handleBlur(e)}
                // Glitchy-looking in the UI :(.
                hideCaution
              />
            </FormRow>
            <TextArea
              name="description"
              placeholder={promptLabel}
              value={form.values.description}
              errors={getFormError(form, "description")}
              onChange={value => form.setFieldValue("description", value)}
              onBlur={(value, e) => form.handleBlur(e)}
              // Glitchy-looking in the UI :(.
              hideCaution
            />
            <SubmitButton
              styleType="primary"
              label={isUpdating ? t("Update") : t("Create")}
              disabled={!form.isValid || (isUpdating && !form.dirty)}
              onMouseDown={e => {
                // HACK Alert: when TagForm is used inside Select Menu, any mousedown
                // event focuses the Select Input, which has unwanted side-effects.
                e.stopPropagation()
              }}
              onSubmit={() => {
                // Comparing with existing rgba values so must convert hex to rgba.
                updateColorPickerSwatches(form.values.color)
                onSubmit(form.values)
              }}
            />
          </Form>
        )}
      </Formik>
    )
  }
)

TagForm.propTypes = {
  tag: tagPropType,
  tags: tagsPropType,
  labels: PropTypes.shape({
    name: PropTypes.string,
    prompt: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired
}

TagForm.displayName = "TagForm"

export default TagForm
