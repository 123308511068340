import {
  LocalBlockTypeType,
  ServerBlockTypeType,
  getBlockType
} from "@pathwright/blocks-core"
import jwtDecode from "jwt-decode"
import PropTypes from "prop-types"
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect
} from "react"
import {
  BlocksConfigContextType,
  useBlocksConfig
} from "../config/BlocksConfigProvider"
import {
  SyncedReducerReturnType,
  useSyncedReducer
} from "../syncer/useSyncedReducer"
import { BlockType, BlocksContentType, BlocksContextType } from "../types"
import * as actionTypes from "./constants"
import {
  ViewerReducerActionType,
  ViewerStateType,
  defaultState,
  getInitialState,
  reducer
} from "./viewerState"

declare global {
  interface Window {
    logContentState: () => void
  }
}

export type SaveViewBlockType = (
  blockId: string,
  change: Record<string, string>,
  trackProgress: boolean
) => void

export type ViewerContextValueType = ViewerStateType & {
  saveBlock: SaveViewBlockType

  // ContentViewerContainer props
  content?: BlocksContentType
  blockTypes?: ServerBlockTypeType[]
  blocksContext?: BlocksContextType
  contentLoading?: boolean
}

type ViewerStateProviderPropsType = {
  children: ReactNode | ((props: ViewerContextValueType) => ReactNode)
  draft?: boolean // This allows student data to be saved on draft blocks
  syncer: (
    action: ViewerReducerActionType,
    state: ViewerStateType,
    dispatch: (action: ViewerReducerActionType) => void
  ) => void
  content: BlocksContentType
  blockTypes: ServerBlockTypeType[]
  blocksContext: BlocksContextType
  contentLoading?: boolean
}

const intitialContextValue: ViewerContextValueType = {
  ...defaultState,
  saveBlock: () => {}
}

const ViewerStateContext =
  createContext<ViewerContextValueType>(intitialContextValue)

export const useViewerState = (): ViewerContextValueType =>
  useContext(ViewerStateContext)

const ViewerStateProvider = ({
  children,
  ...props
}: ViewerStateProviderPropsType) => {
  const {
    // ContentViewerContainer props
    draft,

    // From Syncer
    syncer,
    content,
    blockTypes,
    blocksContext,
    contentLoading
  } = props

  const { onProgress, userID, mode }: BlocksConfigContextType =
    useBlocksConfig()

  const [state, dispatch]: SyncedReducerReturnType<
    ViewerStateType,
    ViewerReducerActionType
  > = useSyncedReducer<
    ViewerStateType,
    ViewerReducerActionType,
    { content: BlocksContentType }
  >(reducer, syncer, { content }, getInitialState)

  const { blocks, completion, error, syncing, lastModified }: ViewerStateType =
    state

  // Debug the Viewer state
  window.logContentState = useCallback(() => {
    const nextState: ViewerStateType = reducer(state, {
      type: actionTypes.GET_STATE
    })
    console.log("Viewer state: ", JSON.stringify(nextState, null, 2))
  }, [state])

  useEffect(() => {
    if (onProgress) onProgress(completion)
  }, [completion])

  useEffect(() => {
    if (mode === "EDIT") {
      // We don't need to worry about user completion state in edit mode
      return
    }

    const contentCompletionToken = content?.completion?.completionToken
    const stateCompletionToken = state.completion?.completionToken
    if (
      contentCompletionToken &&
      stateCompletionToken &&
      contentCompletionToken !== stateCompletionToken
    ) {
      try {
        const { userID: contentUserId } = jwtDecode(contentCompletionToken)
        const { userID: stateUserId } = jwtDecode(stateCompletionToken)
        if (contentUserId !== stateUserId) {
          dispatch({
            type: actionTypes.RESET,
            payload: { content }
          })
        }
      } catch (error) {
        console.log("error detecting content and state mismatch", error)
      }
    }
  }, [content, state])

  const handleSaveBlock: SaveViewBlockType = useCallback(
    async (id, data, trackProgress): Promise<void | undefined> => {
      const block: BlockType | undefined = blocks.find(
        (block) => block.id === id
      )

      if (!userID || !block) {
        console.error(
          "Cannot save blocks data without data and an authenticated user."
        )
        return
        // TODO: This should be an error, but Jest can't handle it
        // throw new Error("Cannot save blocks data without an authenticated user.")
      }

      const blockType: LocalBlockTypeType | undefined = getBlockType(block.type)

      let completion = { ...(block.completion || {}) }

      // Optimistic completion update
      if (typeof blockType?.resolveUserCompletion === "function") {
        completion = await blockType.resolveUserCompletion(
          null,
          data,
          block.layout
        )
      }

      dispatch({
        type: actionTypes.UPDATE_USER_BLOCK,
        payload: {
          id,
          data,
          trackProgress,
          userID,
          draft,
          completion
        }
      })
    },
    [content, blocks, userID, draft, dispatch]
  )

  const provideState: ViewerContextValueType = {
    // Local state
    blocks,
    completion,
    error,
    syncing,
    lastModified,

    // Handlers
    saveBlock: handleSaveBlock,

    // Passed syncer state
    content,
    blockTypes,
    blocksContext,
    contentLoading
  }

  const renderChildren =
    typeof children === "function"
      ? (val: ViewerContextValueType) => children(val)
      : () => children

  return (
    <ViewerStateContext.Provider value={provideState}>
      {renderChildren(provideState)}
    </ViewerStateContext.Provider>
  )
}

ViewerStateProvider.defaultProps = {
  draft: false
}

ViewerStateProvider.propTypes = {
  draft: PropTypes.bool, // This allows student data to be saved on draft blocks
  syncer: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  blockTypes: PropTypes.array.isRequired,
  blocksContext: PropTypes.object.isRequired
}

export default ViewerStateProvider
