import useControlledState from "@pathwright/ui/src/components/hooks/useControlledState"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import USER_QUERY from "../../user/graphql/user-query"
import { flattenEdges, usePaginator } from "../../utils/apollo"
import COMPLETIONS_QUERY from "../graphql/completions-query"
import { COMPLETIONS_FILTERS } from "../utils"
import CompletionsList from "./CompletionsList"

const getFilter = filter => {
  switch (filter) {
    case COMPLETIONS_FILTERS.NEEDS_REVIEW:
      return { needs_review: true }
    case COMPLETIONS_FILTERS.REVIEWED:
      return { is_reviewed: true }
    case COMPLETIONS_FILTERS.COMPLETE:
      return { is_complete: true }
    case COMPLETIONS_FILTERS.INCOMPLETE:
      return { is_complete: false }
    case COMPLETIONS_FILTERS.ALL:
    default:
      return {}
  }
}

export const useCompletions = ({
  userId,
  cohortId,
  pathId,
  sourceId,
  parentId,
  filter
}) => {
  const userQuery = useQuery(USER_QUERY, {
    variables: { id: userId },
    skip: !userId
  })

  const completionsQuery = useQuery(COMPLETIONS_QUERY, {
    variables: {
      user_id: userId,
      cohort_id: cohortId,
      path_id: pathId,
      source_id: sourceId,
      lesson_source_id: parentId,
      // Loading all completions if providing userId or pathId.
      // This allows the CompletionFeedbackPanelHeader to always be
      // able to link directly to the path item on the path.
      // NOTE: pathId could be an ID for a non-user path, but likely will not be.
      first: userId || pathId ? 1042 : 50,
      ...getFilter(filter)
    }
  })

  const loading = userQuery.loading || completionsQuery.loading
  const error = userQuery.error || completionsQuery.error
  const user = get(userQuery, "data.user")
  const total = get(completionsQuery, "data.pathItems.total")
  const items = flattenEdges(get(completionsQuery, "data.items", []))
  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: completionsQuery, // hacky
    path: "pathItems"
  })

  return {
    loading,
    error,
    user,
    total,
    items,
    loadMore,
    hasMore,
    loadingMore
  }
}

export const withCompletionsListContainer = Component => ({
  filter: filterProp,
  onFilter: onFilterProp,
  selectedId: selectedIdProp,
  onSelect,
  userId,
  cohortId,
  pathId,
  sourceId,
  parentId,
  hideUser,
  ...rest
}) => {
  const [filter, setFilter] = useControlledState(
    filterProp || COMPLETIONS_FILTERS.ALL,
    onFilterProp
  )
  const [selectedId, setSelectedId] = useControlledState(selectedIdProp)

  const userQuery = useQuery(USER_QUERY, {
    variables: { id: userId },
    skip: !userId
  })

  const completionsQuery = useQuery(COMPLETIONS_QUERY, {
    variables: {
      user_id: userId,
      cohort_id: cohortId,
      path_id: pathId,
      source_id: sourceId,
      lesson_source_id: parentId,
      // Loading all completions if providing userId or pathId.
      // This allows the CompletionFeedbackPanelHeader to always be
      // able to link directly to the path item on the path.
      // NOTE: pathId could be an ID for a non-user path, but likely will not be.
      first: userId || pathId ? 1042 : 50,
      ...getFilter(filter)
    }
  })

  const loading = userQuery.loading || completionsQuery.loading
  const error = userQuery.error || completionsQuery.error
  const user = get(userQuery, "data.user")
  const total = get(completionsQuery, "data.pathItems.total")
  const pathItems = flattenEdges(get(completionsQuery, "data.pathItems", []))
  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: completionsQuery, // hacky
    path: "pathItems"
  })

  return (
    <Component
      {...rest}
      filter={filter}
      onFilter={setFilter}
      selectedId={selectedId}
      onSelect={completion => {
        setSelectedId(completion ? completion.id : null)
        onSelect && onSelect(completion)
      }}
      loading={loading}
      error={error}
      user={user}
      total={total}
      pathItems={pathItems}
      loadMore={loadMore}
      hasMore={hasMore}
      loadingMore={loadingMore}
      hideUser={!!userId || hideUser === true} // dont show same user for every completion
    />
  )
}

const CompletionsListContainer = withCompletionsListContainer(CompletionsList)

CompletionsListContainer.displayName = "CompletionsListContainer"

export default CompletionsListContainer
