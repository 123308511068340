import Button from "@pathwright/ui/src/components/button/Button"
import React from "react"
import styled from "styled-components"

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 0;

  > span {
    justify-content: flex-start;
  }
`

const TagManagerPrompt = props => (
  <StyledButton styleType="secondary" preIcon="gear" size="large" {...props} />
)

export default TagManagerPrompt
