import Link from "@pathwright/ui/src/components/link/Link"
import classnames from "classnames"
import React, { forwardRef, useMemo } from "react"
import styled from "styled-components"
import FeedItemAvatar from "../inbox/ui/FeedItemAvatar"
import { getFeedItemDateString, getUserInitials } from "../inbox/utils"
import { isSchoolUrl } from "../utils/urls"
import { useNotificationsState } from "./state/notification-state"
import NotificationItemControls from "./NotificationItemControls"

const NotificationFeedItemContainer = styled.li`
  cursor: pointer;
  list-style: none;
  position: relative;
  border-radius: 8px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 1);
  padding: 0px 10px;

  h3 {
    font-weight: normal;
  }

  /* If item is last and there are 3 or more items in the list
    then open the context menu on top, as a better UX. */
  &:last-child:nth-child(n + 3) {
    .Overlay--place-bottom {
      top: 0;
      transform: translateY(-100%);
    }
  }

  &:hover,
  &.selected {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.selected {
    color: rgba(0, 0, 0, 1);
    h3 {
      font-weight: bold;
    }
  }

  &.selected {
    /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
    /* background-color: rgba(255, 255, 255, 0.4); */
    color: black;
  }

  @media (hover: hover) {
    .controls {
      display: none;
    }

    &:hover .controls {
      display: flex;
    }
  }

  .controls {
    position: absolute;
    top: 0px;
    right: 4px;
    bottom: 0px;
    align-items: center;
    z-index: 10;
  }

  a {
    width: 100%;
    font-size: 1em;
    text-decoration: none;
    color: inherit;
    padding: 8px 10px;
    /* padding-left: 30px; */
    /* border-bottom: 1px solid #ccc; */
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .message {
      width: 100%;

      p {
        font-size: 1em;
        margin: 0px;
        font-weight: normal;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      span {
        margin-left: auto;
        margin-right: 10px;
        font-size: 0.9em;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`

const UnreadIndicator = styled.div`
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  /* background-color: rgba(0, 122, 255, 1); */
  background-color: #2e89f4;
`

const NotificationFeedItem = forwardRef(({ item }, ref) => {
  const {
    select,
    selectedItem,
    markRead,
    markUnread,
    remove,
    pin
  } = useNotificationsState()

  let dateDisplay = getFeedItemDateString(item.data.when)

  const handleToggleRead = () => {
    if (!item.data.read) {
      markRead(item)
    } else {
      markUnread(item)
    }
  }

  const handleSelect = () => {
    if (!item.data.read) markRead(item)
    select(item)
  }

  const handleDelete = () => {
    remove(item, true /* ignoreSelected */)
  }

  const handlePin = () => {
    pin(item, !item.data.pinned)
  }

  const isSelected = selectedItem && selectedItem.id === item.id
  const isUnread = item.data.read !== true
  const isPinned = item.data.pinned === true

  let className = isSelected ? "selected" : ""
  if (isPinned) className += " pinned"
  if (isUnread) className += " unread"
  if (!isUnread) className += " read"

  const linkProps = useMemo(
    () =>
      isSchoolUrl(item.data.link)
        ? {
            to: item.meta.url
          }
        : {
            href: item.meta.url,
            target: "_blank"
          },
    [item.data.link, item.meta.url]
  )

  return (
    <NotificationFeedItemContainer
      className={classnames("NotificationFeedItem", className)}
      ref={ref}
      onClick={handleSelect}
    >
      <Link {...linkProps}>
        {isUnread && <UnreadIndicator />}
        {item.data.user && (
          <FeedItemAvatar
            image={item.data.image}
            initials={getUserInitials(item.data.user)}
            icon={item.meta.icon}
            color={item.meta.color}
          />
        )}
        <div className="message">
          <p>
            <b>{item.meta.user}</b> {item.meta.title}
          </p>
          <span>{dateDisplay}</span>
        </div>
      </Link>
      <div className="controls">
        <NotificationItemControls
          item={item}
          onPin={handlePin}
          onToggleRead={handleToggleRead}
          onDelete={handleDelete}
        />
      </div>
    </NotificationFeedItemContainer>
  )
})

export default NotificationFeedItem
