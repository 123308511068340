import Button from "@pathwright/ui/src/components/button/Button"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { COMPLETIONS_FILTERS } from "../utils"

const Container = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const getCompletionsFilerInfo = (filter, translator) => {
  switch (filter) {
    case COMPLETIONS_FILTERS.ALL:
      return {
        title: translator("Everything"),
        blankHeading: translator("No completions found.")
      }
    case COMPLETIONS_FILTERS.NEEDS_REVIEW:
      return {
        title: translator("Pending review"),
        blankHeading: translator("No completions pending review.")
      }
    case COMPLETIONS_FILTERS.REVIEWED:
      return {
        title: translator("Already reviewed"),
        blankHeading: translator("No completions already reviewed.")
      }
    case COMPLETIONS_FILTERS.COMPLETE:
      return {
        title: translator("All completions"),
        blankHeading: translator("No completions found.")
      }

    case COMPLETIONS_FILTERS.INCOMPLETE:
      return {
        title: translator("All incomplete"),
        blankHeading: translator("No completions found.")
      }
    default:
      return {
        title: "",
        blankHeading: ""
      }
  }
}

const CompletionsListHeader = ({ user, filter: selectedFilter, onFilter }) => {
  const { me } = usePathwrightContext()
  const { t } = useTranslate()

  selectedFilter = selectedFilter || COMPLETIONS_FILTERS.NEEDS_REVIEW

  return (
    <Container>
      {user ? (
        me && me.id === user.id ? (
          <Text.H4>Your Completions</Text.H4>
        ) : (
          <Text.H4>{user.first_name}'s Completions</Text.H4>
        )
      ) : (
        <Text.H4>Completions</Text.H4>
      )}
      <Text.Body el="div">
        Show{" "}
        <DropdownTrigger
          renderDropdown={() => (
            <DropdownMenu closeOnClick={true}>
              {Object.values(COMPLETIONS_FILTERS).map(filter => (
                <DropdownItem
                  key={filter}
                  label={getCompletionsFilerInfo(filter, t).title}
                  onClick={() => onFilter(filter)}
                  active={filter === selectedFilter}
                />
              ))}
            </DropdownMenu>
          )}
        >
          <Button styleType="secondary">
            {getCompletionsFilerInfo(selectedFilter, t).title}{" "}
            <Pathicon icon="chevron-down" />
          </Button>
        </DropdownTrigger>
      </Text.Body>
    </Container>
  )
}

CompletionsListHeader.displayName = "CompletionsListHeader"

CompletionsListHeader.propTypes = {
  user: PropTypes.object,
  filter: PropTypes.oneOf(Object.values(COMPLETIONS_FILTERS)),
  onFilter: PropTypes.func.isRequired
}

CompletionsListHeader.defaultProps = {
  filter: COMPLETIONS_FILTERS.NEEDS_REVIEW
}

export default CompletionsListHeader
