// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let DiscussionEmptyView
require("./styles/discussion-empty.css")

export default (DiscussionEmptyView = (function() {
  DiscussionEmptyView = class DiscussionEmptyView extends require("lib/static-shim").default(
    require("lib/core/item-view").default
  ) {
    static initClass() {
      this.prototype.template = require("./templates/discussion-empty.html")
      this.prototype.className = "discussion-empty-view"
    }

    serializeData() {
      const data = super.serializeData()
      return _.extend(data, this.options)
    }
  }
  DiscussionEmptyView.initClass()
  return DiscussionEmptyView
})())
