import Alert from "@pathwright/ui/src/components/alert/Alert"
import Button from "@pathwright/ui/src/components/button/Button"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

const DiscountFormContainer = styled.div`
  form {
    position: relative;
    display: flex;
    align-items: center;

    .TextInput {
      flex: 1;
    }

    .Button {
      position: absolute;
      top: 10px;
      right: 4px;
      border-radius: 4px !important;
    }
  }

  .Alert {
    margin: 5px 0;
    width: 100%;
    max-width: none;
  }
`

function OrderDiscountForm({ applyDiscountCode, searchParamKey }) {
  const { t } = useTranslate()

  const [discountCode, setDiscountCode] = useState(() => {
    // initialize the discountCode value with default pulled from URL search params
    const discountCode = new URLSearchParams(window.location.search).get(
      searchParamKey
    )
    return discountCode || ""
  })
  const [discountResult, setDiscountResult] = useState(null)
  const [discountError, setDiscountError] = useState(null)
  const [discountFormVisible, setDiscountFormVisible] = useState(false)

  async function handleDiscount(discountCode) {
    try {
      setDiscountError(null)
      const discountResult = await applyDiscountCode(discountCode)
      setDiscountResult(discountResult)
      setDiscountFormVisible(false)
    } catch (error) {
      setDiscountError(error)
      // ensure discount form is visibile (especially in the case where a discount is auto queried due to code in search params)
      setDiscountFormVisible(true)
    }
  }

  const submitDiscount = useCallback(
    e => {
      e.preventDefault()
      return handleDiscount(discountCode)
    },
    [discountCode]
  )

  useEffect(() => {
    // on initial mount, let's query for the discount if there is one pulled from the URL search params
    if (discountCode) {
      handleDiscount(discountCode)
    }
  }, [])

  return (
    <DiscountFormContainer>
      <Button
        brand
        styleType="text"
        onClick={() => setDiscountFormVisible(x => !x)}
        style={{ paddingLeft: 0 }}
      >
        {discountFormVisible
          ? t("Cancel")
          : discountResult
          ? t("Try a different code?")
          : t("payments.elements_form.have_a_coupon")}
      </Button>
      {discountFormVisible ? (
        <div>
          <form onSubmit={submitDiscount}>
            <TextInput
              inline
              name="code"
              placeholder="Enter your discount code"
              value={discountCode}
              onChange={setDiscountCode}
            />
            <Button styleType="primary" type="submit" brand={true}>
              {t("Apply Code")}
            </Button>
          </form>
          <Alert error={discountError} />
        </div>
      ) : discountResult ? (
        <Alert
          success={
            typeof discountResult === "string"
              ? discountResult
              : discountResult.discount_percent &&
                discountResult.currency_display_discount_price
              ? `${t("payments.elements_form.discount_applied", {
                  perc: discountResult.discount_percent * 100,
                  newTotal: discountResult.currency_display_discount_price
                })}.`
              : discountResult.discount_perc &&
                discountResult.discounted_total_currency_display
              ? `${t("payments.elements_form.discount_applied", {
                  perc: discountResult.discount_perc * 100,
                  newTotal: discountResult.discounted_total_currency_display
                })}.`
              : discountResult.discountMessage
              ? discountResult.discountMessage
              : null
          }
        />
      ) : null}
    </DiscountFormContainer>
  )
}

OrderDiscountForm.displayName = "OrderDiscountForm"

OrderDiscountForm.defaultProps = {
  searchParamKey: "coupon"
}

export default OrderDiscountForm
