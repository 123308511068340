// import Pathicon from "@pathwright/pathicons"
import { PointerFillIcon, CheckIcon } from "@pathwright/pathicons"
import { useEffect, useState } from "react"
import { useBlocksConfig } from "../config/BlocksConfigProvider"
import {
  BLOCK_INPUT_STATES,
  useBlockCompletion,
  BlockCompletionState
} from "./BlockCompletionProvider"
import { Box } from "@chakra-ui/react"

const SCORING_RED = "#F58870"

const IndicatorWrapper = ({ show, children }: { show: boolean, children: JSX.Element | null }) => {
  return (
    <Box
      position="absolute"
      left={3}
      top={8}
      opacity={show ? 1 : 0}
      transition="opacity 0.2s linear"
    >
      {children}
    </Box>
  )
}

const BlockValidationIndicator = () => {
  const {
    inputState,
    isCompleted,
    score,
    blurred,
    blockRegistered,
    useAutocomplete
  }: BlockCompletionState = useBlockCompletion()

  const { disableInput } = useBlocksConfig()

  const [show, setShow] = useState(false)

  const isValid = inputState === BLOCK_INPUT_STATES.VALID
  const isInvalid = inputState === BLOCK_INPUT_STATES.INVALID
  const isEmpty = inputState === BLOCK_INPUT_STATES.NONE

  const validFeedback = isValid && isCompleted && score !== 0
  const invalidFeedback =
    isInvalid || (blurred && isEmpty) || (blurred && isValid && !isCompleted)

  const renderIndicator = () => {
    if (!blockRegistered) return null

    if (invalidFeedback)
      return (
        <PointerFillIcon
          style={{ color: SCORING_RED, fontSize: "1.2rem" }}
        />
      )

    if (validFeedback && useAutocomplete)
      return (
        <span
          style={{
            fontSize: "0.9rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <CheckIcon
            style={{ color: "gray" }}
          />
          Saved
        </span>
      )

    return null
  }

  const indicator = renderIndicator()

  useEffect(() => {
    if (indicator && !show && blurred) {
      setShow(true)
    }
  }, [isCompleted, score, inputState, blurred])

  const HIDE_DELAY = 3000

  useEffect(() => {
    if (show && !invalidFeedback) {
      setTimeout(() => {
        setShow(false)
      }, HIDE_DELAY)
    }
  }, [show, isCompleted])

  // Input validation doesn't make sense when input is disabled
  if (disableInput) return null

  return <IndicatorWrapper show={show}>{indicator}</IndicatorWrapper>
}

export default BlockValidationIndicator
