import { useEffect } from "react"
import isEqual from "react-fast-compare"
import useLocalStorage from "../../hooks/useLocalStorage"

export const getFormStateStorageKey = value => {
  const objReducer = value => {
    if (typeof value === "object" && !Array.isArray(value)) {
      return Object.entries(value).reduce((acc, [k, v]) => [...acc, k, v], [])
    }
    return value
  }

  value = objReducer(value)

  if (typeof value === "string") {
    value = [value]
  }

  // Defaulting to location.pathname.
  if (!value) {
    value = window.location.pathname.split("/")
  }

  // In case we have an array containing flat objects, lets reduce those.
  value = value.reduce(
    (acc, part) => (part ? [...acc, ...[].concat(objReducer(part))] : acc),
    []
  )

  return ["form-state", ...value].filter(Boolean).join(":")
}

const useFormStateStorage = (initialValues, key) => {
  // Using the window.location.pathname to key the form state ensures uniqueness.
  const formStateStorageKey = getFormStateStorageKey(key)

  // Whether the formStateStorage.initialValues has diverged from the initialValues.
  const getInitialValueDiverged = formStateStorage => {
    return Boolean(
      formStateStorage &&
        formStateStorage.initialValues &&
        !isEqual(initialValues, formStateStorage.initialValues)
    )
  }

  const getValuesUnmodified = formStateStorage => {
    return Boolean(
      formStateStorage &&
        isEqual(formStateStorage.initialValues, formStateStorage.values)
    )
  }

  const getInitialValue = formStateStorage => {
    if (getInitialValueDiverged(formStateStorage)) {
      if (formStateStorage) {
        // We need to default to initialValues. This way, we ensure the user doesn't
        // unknowingly overwrite data.
        formStateStorage.values = initialValues
        formStateStorage.initialValues = initialValues
      }
    }

    // Return current formStateStorage, possibly modified above.
    if (formStateStorage !== undefined) return formStateStorage

    // Return the default formStateStorage
    return {
      // Track current formStateStorage.
      values: initialValues,
      // Track formStateStorage.initialValues which can be compared to initialValues to determine
      // if the initialValues have changed since last form mount. If changed, we can
      // assume data has been altered in the DB and the safest path forward is to
      // discard local formStateStorage.
      initialValues: null
    }
  }

  const [
    formStateStorage,
    setFormStorageState,
    formStateStorageMeta
  ] = useLocalStorage(formStateStorageKey, getInitialValue, { sync: true })

  // If not formInitialStateMatches then we need to discard form state.
  useEffect(() => {
    if (
      getInitialValueDiverged(formStateStorage) ||
      getValuesUnmodified(formStateStorage)
    ) {
      // Discard changes.
      setFormStorageState()
    }
  }, [])

  // Wraps setFormStorageState. If no data is provided, the intent is to discard
  // storage altogether, otherwise update values and ensure an initialValues is set.
  const setFormStateWrapper = values => {
    // Values should be an object, null, or undefined.
    if (values) {
      // Update, via function to ensure we're using the latest formStateStorage.
      setFormStorageState(formStateStorage => {
        // Default to preserving initialValues.
        let nextInitialValues =
          formStateStorage && formStateStorage.initialValues
        // If we're setting formStateStorage.values and there are not yet any formStateStorage.initialValues,
        // then set formStateStorage.initialValues.
        if (!nextInitialValues) nextInitialValues = initialValues

        return {
          values,
          initialValues: nextInitialValues
        }
      })
    } else {
      // If values is undefined, the formStateStorageKey will be removed from localStorage.
      // If values is null, the formStateStorageKey key will be retained, but all data cleared.
      setFormStorageState(values)
    }
  }

  return [formStateStorage, setFormStateWrapper, formStateStorageMeta]
}

export default useFormStateStorage
