import { createRoot } from "react-dom/client"
import LazyRedactorEditor from "@pathwright/ui/src/components/richtext/LazyRedactorEditor"
import { getRootForEl, umountRootForEl } from "../core/react/react-utils"

class RichTextBehavior extends require("lib/static-shim").default(
  Marionette.Behavior
) {
  static initClass() {
    this.prototype.defaults = {
      initEvent: "before:form:init"
    }
  }

  initialize() {
    if (this.options.initEvent) {
      return this.listenTo(this.view, this.options.initEvent, this.initRichText)
    }
  }

  initRichText() {
    const $rts = this.$("textarea.redactor")

    const executeDeferredBinding = () => {
      this.view.triggerMethod("apply:binding")
      this.view.triggerMethod("after:form:init")
    }

    if ($rts.length) {
      $rts.each((i, rt) => {
        let rtConfigType = "full"
        const $rt = $(rt)
        if ($rt.data("rt-config")) {
          rtConfigType = $rt.data("rt-config")
        }
        let root = getRootForEl($rt[0])
        root.render(
          <LazyRedactorEditor
            {...this.options.config}
            textarea={$rt[0]}
            onChange={this.onChangeRichText($rt)}
            onFocus={this.focusRichText($rt, this.view)}
            onInitialized={() => executeDeferredBinding()}
            config={rtConfigType}
          />
        )
      })
    } else {
      executeDeferredBinding()
    }
  }

  onChangeRichText($element) {
    return html => {
      if ($element.attr("data-parsley-required") === "true") {
        new $.parsley.Factory($element[0]).validate()
      }
      return $element.trigger("rt-changed")
    }
  }

  focusRichText($element, view) {
    return function() {
      return view.triggerMethod(
        "focus:rich:text",
        $element.redactor("core.getObject")
      )
    }
  }

  onResetRichText() {
    const $rts = this.$(".redactor")
    return $rts.each(function(i, rt) {
      if ($(rt).data("redactor") != null) {
        return $(rt)
          .redactor("core.getObject")
          .code.set("")
      }
    })
  }

  onDestroy() {
    const $rts = this.$(".redactor")
    return $rts.each(function(i, rt) {
      const $rt = $(rt)
      if ($rt.data("redactor") != null) {
        umountRootForEl($rt[0])
        // ReactDOM.unmountComponentAtNode($rt[0])
        try {
          $rt.redactor("core.destroy")
        } catch (error) {
          console.log(`Error destroying redactor editor: ${error}`)
        }
      }
    })
  }
}
RichTextBehavior.initClass()
export default RichTextBehavior
