import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import React from "react"
import styled from "styled-components"
import ReportPathDivider from "./ReportPathDivider"
import ReportPathGroup from "./ReportPathGroup"
import ReportPathResource from "./ReportPathResource"
import ReportPathStep from "./ReportPathStep"

const Contaier = styled.div`
  padding-bottom: 10px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    padding-left: 20px;
    padding-right: 20px;
  }

  & > li & li {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const StyledLoadMore = styled.div`
  position: relative;
  padding: 20px;
`

const ReportPathList = ({ error, loading, items, hasMore, loadMore }) => {
  return (
    <Contaier>
      {error ? (
        <BlankSlate
          icons={null}
          heading="An unexpected error occurred"
          body={(error && error.message) || error}
          inverted
        />
      ) : loading && !items ? (
        <LoadingCircle />
      ) : (
        <List>
          {items.map(pathItem => (
            <li key={pathItem.id}>
              {pathItem.type === "divider" ? (
                <ReportPathDivider pathItem={pathItem} />
              ) : pathItem.type === "lesson" ? (
                <>
                  <ReportPathGroup pathItem={pathItem} />
                  <ReportPathList items={pathItem.items} />
                </>
              ) : pathItem.type === "resource" ? (
                // TODO: custom resource UI
                <ReportPathResource pathItem={pathItem} />
              ) : pathItem.type === "step" ? (
                <ReportPathStep pathItem={pathItem} />
              ) : null}
            </li>
          ))}
          {hasMore ? (
            loading ? (
              <StyledLoadMore>
                <LoadingCircle />
              </StyledLoadMore>
            ) : (
              <Button
                onClick={loadMore}
                styleType="empty"
                rounded={false}
                stretch
              >
                Load more
              </Button>
            )
          ) : null}
        </List>
      )}
    </Contaier>
  )
}

ReportPathList.displayName = "ReportPathList"

export default ReportPathList
