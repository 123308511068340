import PropTypes from 'prop-types';
import React from "react"
import TextInput from "../form-text-input/TextInput"
import color from "react-color/lib/helpers/color"
const { isValidHex } = color

// Wrapper for TextInput
// Source of truth for color input value
// Necessary to allow invalid values while typing
// Validates before passing up value

class ColorInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      isValid: true,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      return this.setState({
        value: nextProps.value,
        isValid: true,
      })
    }
  }

  handleChange = value => {
    const isValid = this.validate(value)
    this.setState({ value, isValid })
    if (isValid) {
      return this.props.onChange(value)
    }
  }

  validate = value => {
    switch (this.props.type) {
      case "hex":
        return value && value.length > 5 && isValidHex(value)
      case "r" || "g" || "b":
        return value && value >= 0 && value <= 255
      case "a":
        return value && value >= 0 && value <= 100
    }
  }

  render() {
    return (
      <TextInput
        className="ColorInput"
        placholder={this.props.type}
        value={this.state.value}
        onChange={this.handleChange}
        showError={!this.state.isValid}
        errorMessage=""
        required={true}
        inline={true}
      />
    )
  }
}

ColorInput.displayName = "ColorInput"

ColorInput.propTypes = {
  type: PropTypes.oneOf(["hex", "r", "g", "b", "a"]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
}

export default ColorInput
