import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import useIsMountedRef from "../hooks/useIsMountedRef"
import { useObserveSize } from "../observers/ObserveSizeContext"
import { useUIThemeContext } from "../ui/PathwrightUI"
import Button from "./Button"

// animation duration in milliseconds
const TRANSITION_INTERVAL = 250

const opacityKeyframe = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: .65;
  }
  100% {
    opacity: 1;
  }
`

const opacityAnimation = css`
  animation-name: ${opacityKeyframe};
  animation-duration: ${TRANSITION_INTERVAL}ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`

const StyledSwitchButton = styled.div`
  padding: 2px;
  overflow: hidden;
  border-radius: 100px;
  position: relative;
  white-space: nowrap; /* prevent buttons from collapsing */
`

const StyledSwitchButtonBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(153, 153, 153, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 100px;
`

const StyledAnimatedButton = styled(Button)`
  ${p => p.animate && opacityAnimation}
`

const SizedButton = ({ handleRectValue, ...rest }) => {
  const [rectValue, __, setNodeRef] = useObserveSize()

  useEffect(() => {
    handleRectValue(rectValue)
  }, [rectValue])

  return <Button ref={setNodeRef} {...rest} />
}

const SwitchButton = props => {
  const {
    options,
    activeIndex: activeIndexProp,
    iconMode,
    onChange,
    brand,
    size,
    to
  } = props

  const isMountedRef = useIsMountedRef()
  const theme = useUIThemeContext()
  const [activeIndex, setActiveIndex] = useState(activeIndexProp | 0)
  const [animate, setAnimate] = useState(false)
  const [sizes, setSizes] = useState([])

  // keep activeIndex in sync with activeIndexProp if controlled
  useEffect(() => {
    if (activeIndexProp != null && activeIndexProp !== activeIndex) {
      setActiveIndex(activeIndexProp | 0)
    }
  }, [activeIndexProp])

  // handle animation based on if activeIndex has changed
  useEffect(() => {
    setAnimate(true)
    setTimeout(() => {
      // Explicitly check for unmount.
      if (isMountedRef.current !== false) setAnimate(false) // could potentially execute after unmount, but unlikely
    }, TRANSITION_INTERVAL)
  }, [activeIndex])

  const activeRectValue = sizes[activeIndex] || {}

  const buttonProps = {
    brand,
    size,
    to
  }

  // total width of buttons to left of active button
  const leftWidth = sizes
    .filter((size, i) => i < activeIndex)
    .reduce((width, rectValue) => width + rectValue.width, 0)

  const transition = `${TRANSITION_INTERVAL}ms ease-in-out`

  const activeStyles = {
    height: activeRectValue.height,
    width: activeRectValue.width,
    transform: `translateX(${leftWidth}px)`,
    transition: `transform ${transition}, width ${transition}`,
    position: "absolute",
    top: "2px",
    left: "2px",
    bottom: 0
  }

  // Only show the SwitchButton and the AnimatedButton once the dependent
  // height and width values have been set.
  const isReady = !isNaN(activeStyles.height) && !isNaN(activeStyles.width)

  return (
    <StyledSwitchButton
      className="SwitchButton"
      style={!isReady ? { visibility: "hidden" } : {}}
    >
      <StyledSwitchButtonBackground className="SwitchButton__background" />
      {isReady && (
        <StyledAnimatedButton
          {...buttonProps}
          animate={animate}
          style={activeStyles}
          styleType={"primary"}
          el="div"
        />
      )}
      {options.map((option, i) => (
        <SizedButton
          {...buttonProps}
          handleRectValue={rectValue => {
            sizes[i] = rectValue
            setSizes([...sizes])
          }}
          key={option.label || i}
          styleType="primary"
          onClick={() => {
            setActiveIndex(i)
            onChange(i, option)
          }}
          interactive={i !== activeIndex}
          style={{
            transition: `color ${transition}`,
            fontWeight: 600
          }}
          fade
          icon={option.icon}
          label={!iconMode && option.label}
          countIndicatorProps={
            option.indicator && {
              color:
                brand && i === activeIndex ? "#fff" : theme.primaryBrandColor
            }
          }
        />
      ))}
    </StyledSwitchButton>
  )
}

SwitchButton.displayName = "SwitchButton"

SwitchButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      indicator: PropTypes.bool // whether to show indicator
    })
  ),
  activeIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  brand: PropTypes.bool,
  size: Button.propTypes.size,
  iconMode: PropTypes.bool
}

SwitchButton.defaultProps = {
  activeIndex: 0,
  brand: false,
  size: "medium",
  iconMode: false
}

export default SwitchButton
