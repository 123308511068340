// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let SwitchStateModel;
export default (SwitchStateModel = class SwitchStateModel extends require("lib/static-shim").default(Backbone.Model) {

  defaults() {
    return {currentState: null};
  }
  
  initialize() {
    this.setState();
    return this.on("change:state", () => this.setState());
  }

  setState() {
    const state = this.get("state");
    const current = this.get("currentState");
    if (current && (current !== state)) {
      this.set(current, false);
    }
    this.set(state, true);
    return this.set("currentState", state);
  }
});
