import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  PRIMARY_GRAY,
  SECONDARY_GRAY,
  TERTIARY_GRAY
} from "@pathwright/ui/src/components/utils/colors"

import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Link from "@pathwright/ui/src/components/link/Link"
import { getStepUrl } from "../utils/urls"
import ResourceThumbnail from "./ResourceThumbnail"
import { getStepVerbIcon } from "../path/utils"

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  color: ${PRIMARY_GRAY};
  border-radius: 8px;
  background-color: whitesmoke;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  ${props =>
    props.hoverable &&
    `
		&:hover {
			background-color: white;
		}
	`};
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 10px;
  padding-left: 30px;
  width: 100%;
  pointer-events: none;
`

const StyledVerbIcon = styled(Pathicon)`
  margin-right: 5px;
  color: ${SECONDARY_GRAY};
`

const StyledName = styled.h4`
  margin: 0;
  white-space: nowrap;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  span {
    color: ${SECONDARY_GRAY};
    text-transform: capitalize;
  }
`

const StyledActionIcon = styled(Pathicon)`
  margin-left: 10px;
  font-size: 18px;
  color: ${TERTIARY_GRAY};
`

const StepActivity = ({
  step,
  resource,
  group,
  icon,
  style,
  includeLink,
  includeThumbnail,
  isStepActivity,
  isCourseActivity
}) => {
  return resource ? (
    <StyledContainer
      className="StepActivity"
      hoverable={includeLink}
      style={style}
    >
      {includeLink && (
        <StyledLink to={getStepUrl(resource.slug, group.id, step.source_id)} />
      )}
      <StyledWrapper>
        {includeThumbnail && !isStepActivity && !isCourseActivity && (
          <ResourceThumbnail resource={resource} />
        )}
        {step.verb ? (
          <>
            <StyledVerbIcon icon={getStepVerbIcon(step.verb)} />
            <StyledName>
              <span>{step.verb}</span>: {step.name}
            </StyledName>
          </>
        ) : (
          <StyledName>{step.name}</StyledName>
        )}
        <StyledActionIcon icon={icon} />
      </StyledWrapper>
    </StyledContainer>
  ) : null
}

StepActivity.displayName = "StepActivity"

StepActivity.propTypes = {
  includeLink: PropTypes.bool,
  includeThumbnail: PropTypes.bool,
  isStepActivity: PropTypes.bool,
  isCourseActivity: PropTypes.bool
}

StepActivity.defaultProps = {
  includeLink: true,
  includeThumbnail: true
}

export default StepActivity
