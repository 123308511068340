const CodexStore = context => {
  let state = {
    nodes: {},
    subscriptions: {},
    context
  }

  const refs = node => {
    if (!node) return undefined

    // for child nodes
    if (!node.nodes) node.nodes = []

    node.get = key => state.nodes[key]

    node.user = () => node.get(node.userId)

    // this is weird but add a hook for looking up any other node from a node

    // for connections
    node.to = () => node.get(node.toId)
    node.from = () => node.get(node.fromId)
    node.parent = () => node.get(node.parentId)

    return node
  }

  const create = (id, key, type, rootId, data = {}, props = {}) => {
    if (!rootId) rootId = context.account.id
    const userId = context.user.id

    let node = CodexNode(id, key, type, rootId, data, {
      userId,
      created: new Date(),
      updated: new Date(),
      ...props
    })
    state.nodes[key] = node
    return get(node.key)
  }

  const set = (key, data) => {
    let n = get(key)
    if (!n) throw new Error(`Node with key ${key} does not exist`)
    state.nodes[key] = refs({ ...n, data: { ...n.data, ...data } })
    trigger("updated", state.nodes[key])
  }

  const get = key => {
    return refs(state.nodes[key])
  }

  const trigger = (eventKey, node) => {
    const subs = Object.values(state.subscriptions[eventKey] || {})
    subs.forEach(sub => {
      sub.callback(node)
    })
  }

  const subscribe = (eventKey, callback) => {
    if (!state.subscriptions[eventKey]) state.subscriptions[eventKey] = {}
    const id = `${eventKey}-${new Date().getTime()}-${Math.floor(
      Math.random() * 1000
    )}`
    state.subscriptions[eventKey][id] = { id, callback }
    return () => delete state.subscriptions[eventKey][id]
  }

  const log = () => {
    console.log(JSON.stringify(state, null, 2))
  }

  const getState = () => {
    return state
  }

  return { get, set, create, trigger, subscribe, log, getState }
}

const CodexNode = (id, key, type, rootId, data = {}, props = {}) => {
  let node = { id, key, type, rootId, data, ...props }
  return node
}

export default CodexStore
