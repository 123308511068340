import { useEffect } from "react"

// Only updates the handler if inputs change, but disregards change in function ref.
const useEventListener = (key, fn, inputs = []) => {
  const keys = [].concat(key)

  useEffect(() => {
    keys.forEach(key => document.addEventListener(key, fn))
    return () => keys.forEach(key => document.removeEventListener(key, fn))
  }, [...keys, ...inputs])
}

export default useEventListener
