import { ActionStateType, ActionType } from "lib/types"
import { useMemo, useRef, useState } from "react"

/**
 * useActionState
 *
 * Takes an ActionType and returns a hook that can scope the ActionType state
 * to the `onSubmit` handler responsible for executing the action.
 *
 * Use it like:
 *
 * const useActiveActionState = useActionState(action)
 * const action1 = useActiveActionState()
 * const action2 = useActiveActionState()
 *
 * return (
 *    <form onSubmit={action1.onSubmit} />
 *    <form onSubmit={action2.onSubmit} />
 *    <span>{action.loading ? action1.loading ? "Action 1 Loading" : "Action 2 Loading"}</span>
 * )
 *
 * @param action: ActionType
 * @returns useActiveActionState
 */

export const useActionState = ({
  data,
  loading,
  success,
  error,
  onSubmit
}: ActionType) => {
  // Benefiting from the fact that hooks may never be conditionally called
  // and must always be called in the same order, we can track the the currently active
  // action state handler by using the index of the hook responsible for intiating
  // the current submit action.
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const actionStatesCount = useRef<number>(0)

  // hook that pushes state handler into
  const useActiveActionState = (): ActionType => {
    // Update total number of action states while setting the current total
    // as this instance's index. This will allow us to check which action
    // is currently active. This does needlessly update the `actionStatesCount.current`
    // on subsequent renders, but that is only an issue if it results in poor performace. (Could do it in a useMemmo callback).
    const index = useRef<number>(actionStatesCount.current++).current

    // Expand the `onSubmit` handler to update the currently active action state
    // handler when called.
    const submitHandler = useMemo((): ActionType["onSubmit"] => {
      return e => {
        // Update the index of the active action state handler.
        setActiveIndex(index)
        return onSubmit(e)
      }
    }, [onSubmit])

    // Use the provided ActionStateType values as the currently active action state handler's state.
    // Reset all other action state handlers to the default ActionStateType values.
    const actionData = useMemo((): Required<ActionStateType> => {
      if (activeIndex === index) {
        return {
          data,
          loading,
          success: success || false,
          error: error || ""
        }
      } else {
        return {
          data: undefined,
          loading: false,
          success: false,
          error: ""
        }
      }
    }, [data, loading, success, error])

    // Simply combine the `onSubmit` handler with the `actionData`.
    const action = useMemo((): ActionType => {
      return {
        onSubmit: submitHandler,
        ...actionData
      }
    }, [submitHandler, actionData])

    return action
  }

  // Returning the hook which can be used once per usage of the `onSubmit` handler.
  return useActiveActionState
}
