import { graphql } from "@apollo/client/react/hoc"
import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import { WithCSS } from "@pathwright/ui/src/components/utils/styles"
import get from "lodash/get"
import moment from "moment"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import RESOURCE_LICENSES_QUERY from "../graphql/resource-licenses-query"

// TODO: move to utils?
const getLicenseMetaLabel = license => {
  let metaLabel = []

  if (license.seat_count) {
    const isFilled = license.seats_filled >= license.seat_count
    metaLabel.push(
      <span key="seats" style={{ color: isFilled && "#D0011B" }}>
        {license.seats_filled}/{license.seat_count} licensed seats filled
      </span>
    )
  }

  if (license.expiration_dtime) {
    const isExpired = moment(license.expiration_dtime).isBefore()
    if (metaLabel.length) {
      metaLabel.push(" • ")
    }
    metaLabel.push(
      <span key="date" style={{ color: isExpired && "#D0011B" }}>
        {isExpired
          ? "License has expired"
          : "License expires " +
            moment(license.expiration_dtime).format("MMM Do")}
      </span>
    )
  }

  return metaLabel
}

const ManageResourceLicenses = ({ card, loading, resourceLicenses }) => {
  return (
    <Card card={card} title="Your Curriculum Licenses">
      {resourceLicenses ? (
        <WithCSS as="ul" css={`margin: 0, padding: 0, list-style: none;`}>
          {resourceLicenses.map(license =>
            get(license, "group.resource") ? (
              <WithCSS
                as="li"
                key={license.id}
                css={`
                  padding: 10px 25px;
                `}
              >
                <Text.H5>{get(license, "group.resource.name")}</Text.H5>
                <Text.Meta>{getLicenseMetaLabel(license)}</Text.Meta>
              </WithCSS>
            ) : null
          )}
        </WithCSS>
      ) : loading ? (
        <LoadingCircle />
      ) : null}
    </Card>
  )
}

export default graphql(RESOURCE_LICENSES_QUERY, {
  options: () => ({
    variables: { first: 50 }
  }),
  props: ({ data }) => ({
    error: data.error,
    loading: data.loading,
    resourceLicenses: flattenEdges(data.resource_licenses),
    hasMore: get(data, "resource_licenses.pageInfo.hasNextPage"),
    loadMore: createPaginator(data, "resource_licenses")
  })
})(ManageResourceLicenses)
