import useDebounce from "@pathwright/ui/src/components/hooks/useDebounce"
import Fuse from "fuse.js"
import { useEffect, useMemo, useState } from "react"

const useSectionData = (data, config) => {
  const [search, setSearch] = useState("")
  // Track total items in data.
  const items = useMemo(() => (data ? config.getItemsData(data) : []), [data])
  // Track filtered items, a subset of total items.
  const [filteredItems, setFilteredItems] = useState(items)

  const searchTerm = useDebounce(search, 300)

  const searchOptions = { threshold: 0.3, ...config.searchOptions }

  const fuse = useMemo(() => {
    return new Fuse(items, searchOptions)
  }, [items.length, config.section])

  useEffect(() => {
    if (searchTerm && searchTerm.length) {
      const results = fuse.search(searchTerm).map(si => si.item)
      setFilteredItems(results)
    } else {
      setFilteredItems(items)
    }
  }, [items, searchTerm])

  const sections = useMemo(() => {
    return config.getSectionData(filteredItems, data)
  }, [filteredItems])

  return {
    items: filteredItems,
    sections,
    search: setSearch
  }
}
export default useSectionData
