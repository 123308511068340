import { FormEventHandler, useEffect } from "react"
import { useSignUp } from "./api/action"
import SignUpView from "./SignUpView"

type SignUpViewContainerProps = {
  onSuccess?: () => void
}

const SignUpViewContainer = ({ onSuccess }: SignUpViewContainerProps) => {
  const [state, action] = useSignUp()

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await action(formData)
  }

  useEffect(() => {
    if (state.value?.success) onSuccess?.()
  }, [state.value])

  return (
    <SignUpView
      loading={state.loading}
      success={!!state.value}
      error={state.error?.message}
      onSubmit={handleSubmit}
    />
  )
}

export default SignUpViewContainer
