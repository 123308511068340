import { FullscreenError } from "@pathwright/ui/src/components/error/Error"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { getSchoolUrl } from "@pathwright/web/src/modules/utils/urls"
import React from "react"
import { Redirect, Route } from "react-router-dom"

const InvalidRoute = () => {
  const { t } = useTranslate()

  return (
    <Route
      render={nextState => {
        const { location } = nextState

        // /payment/portal/ must be hit directly as the backend redirects to a Stripe portal session
        if (location.pathname === "/payment/portal/") {
          window.location = getSchoolUrl("/payment/portal/")
          return null
        }

        // prevent InvalidRoute component from being shown in a modal
        if (location.state && location.state.modal) {
          return (
            <Redirect
              to={{
                ...location,
                state: {
                  ...location.state,
                  modal: false
                }
              }}
            />
          )
        }

        return (
          <FullscreenError
            errorTitle={t("404 Not Found")}
            errorMessage={
              <>
                <strong>{window.location.href}</strong> is not a valid URL.
              </>
            }
            primaryAction={{
              onClick: () => window.App.getStore("navigation").action.goBack(),
              children: t("Go Back")
            }}
          />
        )
      }}
    />
  )
}

InvalidRoute.displayName = "InvalidRoute"

export default InvalidRoute
