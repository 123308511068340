import "./DockPanelHeader.css"
import React from "react"
import PropTypes from "prop-types"
import IconButton from "../button/IconButton"
import Text from "../ui/Text"
import Link from "../link/Link"

export const DockPanelHeaderTitle = ({ title, link }) =>
  !!title && (
    <Text.H4>
      {link ? (
        <Link to={link} target="_blank">
          {title}
        </Link>
      ) : (
        title
      )}
    </Text.H4>
  )

const DockPanelHeader = props => {
  const {
    title,
    link,
    isDocked,
    isBottom,
    onDock,
    onClose,
    closeRoute,
    allowOrient,
    onOrientation,
    HeaderComponent
  } = props

  const iconStyles = {
    color: "#aaa",
    size: 50
  }

  const rotateDockIcon =
    isBottom && isDocked ? 90 : isBottom ? 270 : isDocked ? null : 180

  const orientIcon = isBottom ? "sidebar-side" : "sidebar-bottom"

  return (
    <header className="DockPanelHeader">
      <IconButton
        className="DockPanelHeader__dock"
        {...iconStyles}
        icon="chevron-left"
        rotate={rotateDockIcon}
        onClick={onDock}
      />
      {allowOrient && (
        <IconButton
          className="DockPanelHeader__orient"
          {...iconStyles}
          icon={orientIcon}
          onClick={onOrientation}
        />
      )}
      <div className="DockPanelHeader__content">
        {HeaderComponent ? (
          <HeaderComponent />
        ) : (
          <DockPanelHeaderTitle title={title} link={link} />
        )}
      </div>
      <IconButton
        className="DockPanelHeader__close"
        {...iconStyles}
        icon="x"
        onClick={onClose}
        to={closeRoute}
      />
    </header>
  )
}

DockPanelHeader.displayName = "DockPanelHeader"

DockPanelHeader.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  isDocked: PropTypes.bool.isRequired,
  isBottom: PropTypes.bool.isRequired,
  onDock: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOrientation: PropTypes.func.isRequired,
  HeaderComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

export default DockPanelHeader
