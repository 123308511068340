import gql from "graphql-tag"
import STRIPE_PRICE_FRAGMENT from "../../stripe/graphql/stripe-price-fragment"
import STRIPE_PRODUCT_FRAGMENT from "../../stripe/graphql/stripe-product-fragment"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import SUBSCRIPTION_FRAGMENT from "./subscription-fragment"
import MENTOR_GROUP_FRAGMENT from "../../mentor-group/graphql/mentor-group-fragment"

export default gql`
  fragment SchoolGroupSubscription on SchoolGroupSubscription {
    id
    price {
      ...StripePrice
      product {
        ...StripeProduct
      }
    }
    user {
      ...User
    }
    subscription {
      ...Subscription
    }
    community_group {
      ...MentorGroup
    }
  }
  ${USER_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
  ${STRIPE_PRICE_FRAGMENT}
  ${STRIPE_PRODUCT_FRAGMENT}
  ${MENTOR_GROUP_FRAGMENT}
`
