import { canAdministrate } from "../user/permissions"
import {
  MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER,
  MENTOR_GROUP_TYPE_GROUP_SUBSCRIPTION
} from "./constants"

// expecting user_membership available on mentorGroup
export const canReceiveWeeklyDigest = mentorGroup => {
  if (!mentorGroup) return false
  // can't manage digest of a group subscription mentor group
  if (mentorGroup.type === MENTOR_GROUP_TYPE_GROUP_SUBSCRIPTION) return false
  // can't manage digest without a membership (currently since the setting is tied to the CG membership)
  if (!mentorGroup.user_membership) return false
  // can't manage digest if membership role is member or below
  if (mentorGroup.user_membership.role <= MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER)
    return false
  return true
}

export const canAddMentorGroup = (pwContext, mentorGroup) => {
  // admins can always add a mentor group
  if (canAdministrate(pwContext)) return true
  if (!mentorGroup) return false
  // user with permissions can add a mentor group
  if (mentorGroup && mentorGroup.permissions.can_add_group) return true
  return false
}
