import "./PasswordInput.css"
import React, { useState } from "react"
import styled from "styled-components"
import TextInput from "./TextInput"
import Button from "../../button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"

const StyledTextInput = styled(TextInput)`
  input {
    padding-right: 75px;
  }
`

const StyledRevealer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  ${p => (p.show ? "display: block;" : "")}
`

const PasswordInput = props => {
  const [reveal, setReveal] = useState(false)
  const { t } = useTranslate()
  const { value, inverted } = props

  return (
    <StyledTextInput
      {...props}
      type={reveal ? "text" : "password"}
      custom={
        <StyledRevealer show={!!value}>
          <Button
            onClick={() => setReveal(!reveal)}
            styleType="secondary"
            inverted={inverted}
            type="button"
            size="small"
          >
            {reveal
              ? t("auth.password_input.hide")
              : t("auth.password_input.reveal")}
          </Button>
        </StyledRevealer>
      }
    />
  )
}

PasswordInput.displayName = "PasswordInput"

export default PasswordInput
