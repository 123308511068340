// Try `npm i --save-dev @types/pathwright__ui` if it exists or add a new declaration (.d.ts) file containing `declare module "@pathwright/ui/src/components/pathicon/AnimatedIcon";`
// @ts-ignore
import AnimatedIcon from "@pathwright/ui/src/components/pathicon/AnimatedIcon"

// @ts-ignore
import { useHistory } from "react-router-dom"

import {
  Box,
  Button,
  HStack,
  LightMode,
  Spinner,
  Text,
  VStack
} from "@chakra-ui/react"
// import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import { MutationResult } from "@apollo/client"
import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import { hasAuthenticationToken } from "@pathwright/app-web/src/lib/utils/auth-token"
import { useEffect, useRef, useState } from "react"
import { useLocalStorage } from "react-use"
import { createEnumParam } from "serialize-query-params"
import { StringParam, useQueryParam, withDefault } from "use-query-params"
import {
  CreateSpaceMutation,
  useCreateSpaceMutation,
  useDeferOrExecuteResourceCopyConfigMutation,
  useUserCreatedSpaceMembershipsQuery,
  useUserSpaceMembershipsQuery
} from "../../api/generated"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import {
  useFlattenedResourceCopyConfigs,
  useValidResourceCopyConfigKeys
} from "../../resource/copy-config/ResourceCopyConfigItems"
import {
  PresetThemeKey,
  formatPresetHeadingFontCss,
  presetThemes
} from "../../theme/presets"
import BGMode from "./BGMode"
import ResourceCopyConfigPin, {
  useCopyQueryParam
} from "./ResourceCopyConfigPin"
import SpaceAuthForm, { OnSelectSpace } from "./SpaceAuthForm"
import SpaceCompletionForm from "./SpaceCompletionForm"
import SpaceCreateForm, { SpaceConfig } from "./SpaceCreateForm"
import SpaceSignupTheme from "./SpaceSignupTheme"

type SpaceSignupStep = (typeof spaceSignupStepSequence)[number]
const spaceSignupStepSequence = ["space", "auth", "complete"] as const
const SpaceSignupStepEnumParam = withDefault(
  createEnumParam([...spaceSignupStepSequence]),
  "space"
)

// Block backwards nav after user reaches "complete" step.
// Maybe we should show an alert that allows the user to go back
// but letting them know they'll have abandoned the progress they've made?
const useBlockedBackwardsNav = (step: SpaceSignupStep) => {
  const history = useHistory()

  useEffect(() => {
    if (step === "complete") {
      // @ts-ignore: `block` fn not typed.
      const unblock = history.block((location, action) => {
        return action !== "POP"
      })

      return unblock
    }
  }, [step])
}

// Disabling all navigation when create space mutation is executing or has failed.
const useBlockedNav = (
  createSpaceMutationState: MutationResult<CreateSpaceMutation>
) => {
  const history = useHistory()
  const shouldBlockNav = !!(
    createSpaceMutationState.loading || createSpaceMutationState.error
  )

  useEffect(() => {
    if (shouldBlockNav) {
      // @ts-ignore: `block` fn not typed.
      const unblock = history.block((location, action) => {
        return false
      })

      return unblock
    }
  }, [shouldBlockNav])
}

type SpaceSignupFlowProps = {
  stripeApiKey: string
}

function SpaceSignupFlow({ stripeApiKey }: SpaceSignupFlowProps) {
  const [currentStep, setCurrentStep] = useQueryParam(
    "step",
    SpaceSignupStepEnumParam
  )
  const [utm_source] = useQueryParam("utm_source", StringParam)
  const [utm_medium] = useQueryParam("utm_medium", StringParam)
  const [utm_campaign] = useQueryParam("utm_campaign", StringParam)

  // Storing the space config in local storage to allow for deferring
  // creating the space until user completes external auth (SSO or Magic Link).
  const [spaceConfig, setSpaceConfig, removeSpaceConfig] =
    useLocalStorage<SpaceConfig>("spaceSignupConfig")
  const [presetTheme, setPresetTheme] = useState<PresetThemeKey>("default")
  const { me } = usePathwrightContext()

  // Only querying for user's space membership if they are authenticiated
  // initially. This is so we can conditionally advance the user to the auth step
  // only when they have memberships.
  const initiallyAuthenticatedRef = useRef(!!me)
  const membershipsQuery = useUserSpaceMembershipsQuery({
    skip: !initiallyAuthenticatedRef.current
  })
  const hasSpaceMembership =
    !!membershipsQuery.data?.me?.memberships?.edges?.length

  const [unvalidatedResourceCopyConfigKeys] = useCopyQueryParam()
  // Validate the unvalidatedResourceCopyConfigKeys from the ?copy query param.
  const validResourceCopyConfigKeys = useValidResourceCopyConfigKeys(
    unvalidatedResourceCopyConfigKeys
  )
  const validResourceCopyConfigKeysCount =
    useFlattenedResourceCopyConfigs(validResourceCopyConfigKeys)?.length || 0

  const [createSpaceMutation, createSpaceMutationState] =
    useCreateSpaceMutation()

  // The space that the user presumably created in another tab.
  const createdSpaceMembershipsQuery = useUserCreatedSpaceMembershipsQuery({
    skip: !createSpaceMutationState.error
  })
  const spaceConfigMembership =
    createdSpaceMembershipsQuery.data?.me?.memberships?.edges?.find((edge) => {
      return edge!.node!.school.subdomain === spaceConfig?.subdomain
    })?.node

  // The created space could have been created via the mutation or from
  // some other tab (after an auth change). Let's attempt to grab either.
  const createdSpace =
    createSpaceMutationState.data?.createSchool || spaceConfigMembership?.school

  useBlockedNav(createSpaceMutationState)
  useBlockedBackwardsNav(currentStep!)

  function handleCompleteSpaceConfig(spaceConfig: SpaceConfig) {
    setSpaceConfig(spaceConfig)
    if (hasAuthenticationToken()) {
      handleCompleteAuth(spaceConfig)
    } else {
      setCurrentStep("auth")
    }
  }

  async function handleCompleteAuth(spaceConfig?: SpaceConfig) {
    if (spaceConfig) {
      const theme = presetThemes[spaceConfig.theme]

      await createSpaceMutation({
        variables: {
          ...spaceConfig,
          utm_source,
          utm_medium,
          utm_campaign,
          // Note: there could be a delay in the validated resource copy keys
          // being available by the time we attempt to craete the space, so
          // we can fall back to the unvalidated resource copy keys.
          copy:
            validResourceCopyConfigKeys || unvalidatedResourceCopyConfigKeys,
          theme: {
            custom_css: formatPresetHeadingFontCss(theme.heading_font),
            background_image: theme.background_image,
            background_overlay:
              "background_overlay" in theme
                ? theme.background_overlay
                : undefined,
            background_type: theme.background_type,
            heading_font: theme.heading_font,
            primary_color: theme.primary_color,
            theme_type: theme.theme_type
          }
        }
      })
    } else {
      setCurrentStep("auth")
    }
  }

  const [
    deferOrExecuteResourceCopyConfigMutation,
    deferOrExecuteResourceCopyConfigMutationState
  ] = useDeferOrExecuteResourceCopyConfigMutation()

  let handleSelectAdminSpace: OnSelectSpace
  // Only assigning the handler when we have valid resource copy config keys.
  // This informs SelectSpace how to handle the user selecting a space.
  if (validResourceCopyConfigKeys) {
    handleSelectAdminSpace = async (space) => {
      // Copy all copy configs (deferred or executed immediately).
      // NOTE: all executions are done in background tasks on the backend,
      // so if we really wanted to wait for the resources to be copied,
      // we'd need to also poll for the success of the bg tasks.
      await Promise.all(
        validResourceCopyConfigKeys.filter(Boolean).map((key) =>
          deferOrExecuteResourceCopyConfigMutation({
            variables: {
              copyCode: key!,
              targetSchoolId: space.id
            }
          })
        )
      )

      // TODO: attempt track bg tasks for the executed configs.
      // Ideally, the pinned configs would show a loading spinner until the related paths are copied
      // and we'd defer navigating to the space until all paths are copied.
      // Ideally, we'd also handle this behavior when creating a space.
      // results.forEach(result => {
      //   const execute = result.data?.deferOrExecuteResourceCopyConfig?.execute as Record<string, string>
      //   if (execute) {
      //     Object.entries(execute).forEach(([configKey, taskId])=> {
      //       addBackgroundTask(taskId, {
      //         data: {
      //           configKey
      //         }
      //       })
      //     })
      //   }
      // })

      // Open space link in current tab.
      window.open(space.sso_link!, "_self")
    }
  }

  // Only clearing space config and advancing to "complete" step
  // once mutation succeeds.
  useEffect(() => {
    if (createSpaceMutationState.data?.createSchool) {
      removeSpaceConfig()
      setCurrentStep("complete")
    }
  }, [createSpaceMutationState.data?.createSchool])

  // Handle default step for initially authenticated user.
  useEffect(() => {
    // Redirect away from "complete" step on initial load.
    if (currentStep === "complete") {
      setCurrentStep(me ? "auth" : "space")
    }
  }, [])

  // When initially authenticated user has space memberships, then advance them to auth step automatically.
  useEffect(() => {
    if (!membershipsQuery.loading && hasSpaceMembership) {
      // Default authenticated user to land on "auth" step.
      setCurrentStep("auth")
    }
  }, [membershipsQuery.loading, hasSpaceMembership])

  // Complete the auth step with the space config.
  // This handles the case where the user has already configured a space
  // and has authenticated via some external method (SSO or Magic Links).
  useEffect(() => {
    if (me && spaceConfig) {
      handleCompleteAuth(spaceConfig)
    }
  }, [initiallyAuthenticatedRef.current])

  function renderStep() {
    if (membershipsQuery.loading) {
      return (
        <VStack
          m={2}
          p={4}
          spacing={4}
          w="100%"
          justifyContent="center"
          flexGrow={1}
        >
          <Spinner />
        </VStack>
      )
    }

    if (createSpaceMutationState.loading) {
      return (
        <VStack
          m={2}
          p={4}
          spacing={4}
          w="100%"
          justifyContent="center"
          flexGrow={1}
        >
          <AnimatedIcon
            icon="sparkles"
            iconSize={40}
            circleSize={100}
            inverted
          />
          <Text>Creating {spaceConfig!.name}...</Text>
        </VStack>
      )
    }

    if (createSpaceMutationState.error && !createdSpace) {
      return (
        <VStack
          m={2}
          p={4}
          spacing={4}
          w="100%"
          justifyContent="center"
          flexGrow={1}
        >
          <Pathicon icon="x" />
          <Text>{createSpaceMutationState.error.message}</Text>
          <Button
            variant="solid"
            size="lg"
            cursor="pointer"
            onClick={() => {
              createSpaceMutationState.reset()
              setCurrentStep("space")
            }}
          >
            Clear
          </Button>
        </VStack>
      )
    }

    // Note: we only reach the "complete" step when a created space exists.
    // Let's not depend on the step state since it is stale for one cycle
    // resulting in poor UX due to a flash after the loading screen hides
    // and before the completion form displays.
    if (createdSpace) {
      return (
        <SpaceCompletionForm
          spaceId={createdSpace.id}
          spaceProfileId={createdSpace.profile!.id!}
          stripeApiKey={stripeApiKey}
        />
      )
    }

    switch (currentStep) {
      case "space":
        return (
          <SpaceCreateForm
            initialValues={spaceConfig}
            onChangeTheme={setPresetTheme}
            onComplete={handleCompleteSpaceConfig}
            onClickSignIn={() => {
              removeSpaceConfig()
              setCurrentStep("auth")
            }}
            onClickSelectSpace={() => {
              removeSpaceConfig()
              setCurrentStep("auth")
            }}
          />
        )
      case "auth":
        return (
          <SpaceAuthForm
            onClickCreateSpace={() => setCurrentStep("space")}
            onSelectSpace={handleSelectAdminSpace}
            onComplete={async () => handleCompleteAuth(spaceConfig)}
            spaceConfig={spaceConfig}
            copyConfigsCount={validResourceCopyConfigKeysCount}
          />
        )
    }
  }

  function renderStepMarkers() {
    // User can not manually progress to complete step nor can they manually regress
    // to the space or auth step once reaching the complete step.
    const allowedSteps =
      // Disabling all step markers when create space mutation is executing or has failed.
      createSpaceMutationState.loading || createSpaceMutationState.error
        ? []
        : currentStep === "complete"
        ? spaceSignupStepSequence.slice(-1)
        : spaceSignupStepSequence.slice(0, -1)

    return (
      <HStack
        p={4}
        maxW="450px"
        minW="min(100vw, 450px)"
        data-testid="space-signup-step-markers"
        spacing={0}
      >
        {spaceSignupStepSequence.map((step) => (
          <Box
            as="button"
            key={step}
            p={2}
            disabled={!allowedSteps.includes(step)}
            cursor="pointer"
            _disabled={{
              cursor: "not-allowed"
            }}
            onClick={() => setCurrentStep(step)}
            flexGrow={1}
          >
            <Box
              h={1}
              borderRadius="2px"
              // Current step gets fully opaque bg.
              bg={`rgba(255,255,255, ${
                step === currentStep
                  ? 1
                  : allowedSteps.includes(step)
                  ? // Allowed step gets slight more visibility than disabled step.
                    0.5
                  : 0.25
              })`}
            />
          </Box>
        ))}
      </HStack>
    )
  }

  return (
    <SpaceSignupTheme presetTheme={presetTheme}>
      <BGMode w="100vw" minH="100vh" pos="relative" zIndex={1}>
        <VStack
          alignItems="center"
          spacing={8}
          w="100vw"
          minH="100vh"
          pos="relative"
          sx={{
            label: {
              fontSize: ".85em"
            },
            // Hide all labels where the following input is not focused.
            ["label.chakra-form__label:not(:has(+ input.focus-visible, + * input.focus-visible))"]:
              {
                opacity: 0
              }
          }}
        >
          {renderStepMarkers()}
          <LightMode>
            <ResourceCopyConfigPin />
          </LightMode>
          <VStack maxW="450px" minW="min(100vw, 450px)" flexGrow={1}>
            {renderStep()}
          </VStack>
        </VStack>
      </BGMode>
    </SpaceSignupTheme>
  )
}

export default SpaceSignupFlow
