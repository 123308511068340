import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { withToggleIntercomVisibility } from "@pathwright/web/src/modules/intercom/IntercomProvider"
import {
  INVITATION_TYPE_OFFERING,
  INVITE_METHOD_EMAIL
} from "invitation/constants"
import { PathwrightFeature } from "lib/core/components/pathwright"
import PathwrightAccountPermissions from "lib/core/components/pathwright/PathwrightAccountPermissions"
import { observer } from "mobx-react"
import inviteStore from "../store/invite-store"
import InviteForm from "./form/InviteForm"
import InviteHeader from "./header/InviteHeader"
import "./Invite.css"
import LicensedSeats from "./licensed-seats/LicensedSeats"
import InviteLink from "./link/InviteLink"

// TODO: lock unactivated schools with PathwrightFeature
const Invite = ({ card, location }) => {
  const {
    initializing,
    method,
    type,
    numsent,
    canInviteMore,
    context
  } = inviteStore

  return (
    <Card className="Invite" card={{ ...card }} navless noaction>
      {initializing ? (
        <LoadingCircle />
      ) : (
        <div className="Invite__content">
          <InviteHeader pathname={location.pathname} card={card} />
          <PathwrightAccountPermissions>
            {({
              can_invite_to_offering,
              can_invite_to_resource,
              can_invite_to_school
            }) => {
              const canInvite =
                type === INVITATION_TYPE_OFFERING
                  ? context.offering.is_curriculum
                    ? can_invite_to_resource
                    : can_invite_to_offering
                  : can_invite_to_school

              // TODO: update BlankSlate with more helpful message.
              if (
                type === INVITATION_TYPE_OFFERING &&
                context.offering.status === 3
              ) {
                return <BlankSlate icon="check" heading="Cohort Completed" />
              }

              return (
                <PathwrightFeature activationLocked={!canInvite}>
                  {card.showChildren ? (
                    !canInviteMore ? (
                      <div style={{ padding: "15px 50px 0" }}>
                        <LicensedSeats />
                      </div>
                    ) : method === INVITE_METHOD_EMAIL ? (
                      <InviteForm
                        pathname={location.pathname}
                        enableSearch={type === INVITATION_TYPE_OFFERING}
                      />
                    ) : (
                      <InviteLink />
                    )
                  ) : (
                    <LoadingCircle />
                  )}
                </PathwrightFeature>
              )
            }}
          </PathwrightAccountPermissions>
        </div>
      )}
    </Card>
  )
}

Invite.displayName = "Invite"

export default withToggleIntercomVisibility(observer(Invite))
