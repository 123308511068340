import Map from "es6-map"
import moment from "moment"
import pluralize from "pluralize"
import React from "react"
import { DISCUSSION_TYPE_FEEDBACK } from "../discussion/constants"
import { GroupLink, ResourceLink, StepLink } from "./ActivityDescription"
import {
  COMBINED_LEARNING_ACTION_GROUP_CLASS,
  COMBINED_LEARNING_ACTION_MEMBERSHIP,
  LEARNING_ACTION_CANCELED_PAUSED_SUBSCRIPTION,
  LEARNING_ACTION_COMPLETED_COURSE,
  LEARNING_ACTION_COMPLETE_STEP,
  LEARNING_ACTION_CREATED_GROUP,
  LEARNING_ACTION_CREATE_DISCUSSION,
  LEARNING_ACTION_CREATE_MEMBERSHIP,
  LEARNING_ACTION_CREATE_RESPONSE,
  LEARNING_ACTION_EXPECTS,
  LEARNING_ACTION_PAID_REGISTRATION,
  LEARNING_ACTION_PAUSED_SUBSCRIPTION,
  LEARNING_ACTION_REGISTRATION,
  LEARNING_ACTION_RESET_STEP,
  LEARNING_ACTION_SKIP_STEP,
  LEARNING_ACTION_SUBSCRIBED,
  LEARNING_ACTION_SUBSCRIPTION_PAID,
  LEARNING_ACTION_UNPAUSED_SUBSCRIPTION,
  LEARNING_ACTION_UNSUBSCRIBED
} from "./constants"

const getActionExpects = action =>
  LEARNING_ACTION_EXPECTS[action.action_type].every(key => !!action[key])

// transformation:
//   [activity, activity] => {day => [activity, activity], day => [activity, activity]}
// terms:
//   day: list of sequential activity from the same calendar day

export const groupActivityByDay = activities => {
  return activities.filter(getActionExpects).reduce((days, activity, index) => {
    const time = moment(activity.action_dtime)
    const localTime = time.format("LT")
    const groupTime = time.calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd, MMM D, YYYY",
      sameElse: "dddd, MMM D, YYYY"
    })
    if (days.has(groupTime)) {
      days.get(groupTime).push({ ...activity, localTime })
    } else {
      days.set(groupTime, [{ ...activity, localTime }])
    }
    return days
  }, new Map())
}

const activitiesAreSameUser = (activity1, activity2) => {
  return getActingUser(activity1).id === getActingUser(activity2).id
}

const activitiesAreSameCourse = (activity1, activity2) => {
  return (
    activity1.resource &&
    activity2.resource &&
    activity1.resource.slug === activity2.resource.slug
  )
}

const activitiesAreNotCourse = (activity1, activity2) => {
  return !activity1.resource && !activity2.resource
}

const activitiesAreSameStepActions = (activity1, activity2) => {
  const sameAction = activity1.action_type === activity2.action_type
  const stepRelated =
    activity1.action_type === LEARNING_ACTION_COMPLETE_STEP ||
    activity1.action_type === LEARNING_ACTION_SKIP_STEP
  // activity1.action_type === LEARNING_ACTION_RESET_STEP
  return sameAction && stepRelated
}

const activitiesAreSameGroupClassAction = (activity1, activity2) => {
  const sameGroup =
    activity1.group &&
    activity2.group &&
    activity1.group.id === activity2.group.id
  const relatedActions =
    (activity1.action_type === LEARNING_ACTION_CREATED_GROUP &&
      activity2.action_type === LEARNING_ACTION_REGISTRATION) ||
    (activity1.action_type === LEARNING_ACTION_REGISTRATION &&
      activity2.action_type === LEARNING_ACTION_CREATED_GROUP)
  return sameGroup && relatedActions
}

const activitiesAreSameMembershipAction = (activity1, activity2) => {
  return (
    (activity1.action_type === LEARNING_ACTION_CREATE_MEMBERSHIP &&
      activity2.action_type === LEARNING_ACTION_SUBSCRIBED) ||
    (activity1.action_type === LEARNING_ACTION_SUBSCRIBED &&
      activity2.action_type === LEARNING_ACTION_CREATE_MEMBERSHIP)
  )
}

export const getActingUser = action => {
  const { user, triggered_by } = action
  return triggered_by || user
}

// transformation:
//   [activity, activity] => [[[activity, activity]], [[activity, activity]]]
// terms:
//   group: list of sequential activity from the same user in the same course
//   entry: a single unique action, or list of repeated actions (i.e. 5 sequential step completions)
//   activity: the action that took place

export const groupActivity = activities => {
  return activities.reduce((groups, activity) => {
    if (groups.length) {
      const prevGroup = groups[groups.length - 1]
      if (prevGroup.length) {
        const prevEntry = prevGroup[prevGroup.length - 1]
        const prevActivity = prevEntry[prevEntry.length - 1]
        // group activity by user and course
        if (
          activitiesAreSameUser(prevActivity, activity) &&
          (activitiesAreSameCourse(prevActivity, activity) ||
            activitiesAreNotCourse(prevActivity, activity))
        ) {
          // group similar or related actions...
          if (activitiesAreSameStepActions(prevActivity, activity)) {
            // group repeated step actions
            prevEntry.push(activity)
          } else if (
            activitiesAreSameGroupClassAction(prevActivity, activity)
          ) {
            // combine group class creation and registration
            prevActivity.action_type = COMBINED_LEARNING_ACTION_GROUP_CLASS
            activity.action_type = COMBINED_LEARNING_ACTION_GROUP_CLASS
            prevEntry.push(activity)
          } else if (
            activitiesAreSameMembershipAction(prevActivity, activity)
          ) {
            // combine subscription and membership
            prevActivity.action_type = COMBINED_LEARNING_ACTION_MEMBERSHIP
            activity.action_type = COMBINED_LEARNING_ACTION_MEMBERSHIP
            prevEntry.push(activity)
          } else {
            // nothing to group
            const newEntry = [activity]
            prevGroup.push(newEntry)
          }
          return groups
        }
      }
    }
    // create new group
    // (either first group, or no grouping could be found above)
    groups.push([[activity]])
    return groups
  }, [])
}

export const getDescriptionForActions = actions => {
  const numActions = actions.length
  const firstAction = actions[0]
  const lastAction = actions[numActions - 1]
  const {
    action_type,
    resource,
    group,
    discussion,
    discussion_response,
    step,
    user
  } = firstAction
  switch (action_type) {
    case LEARNING_ACTION_COMPLETE_STEP:
    case LEARNING_ACTION_SKIP_STEP:
    case LEARNING_ACTION_RESET_STEP:
      const verbs = {
        [LEARNING_ACTION_COMPLETE_STEP]: "completed",
        [LEARNING_ACTION_SKIP_STEP]: "skipped",
        [LEARNING_ACTION_RESET_STEP]: "reset"
      }

      let forUser = ""
      const actingUser = getActingUser(firstAction)
      if (user.id !== actingUser.id) {
        forUser = ` for ${user.display_name}`
      }

      return (
        <span>
          {verbs[action_type]} {numActions > 1 ? numActions : "a"}{" "}
          {pluralize("step", numActions)}
          {numActions > 1 &&
            ` in ${moment(firstAction.action_dtime).to(
              lastAction.action_dtime,
              true
            )}`}
          {forUser} from <GroupLink resource={resource} group={group} />
        </span>
      )
    case LEARNING_ACTION_CREATE_DISCUSSION:
      return discussion.type === DISCUSSION_TYPE_FEEDBACK ? (
        <span>
          started feedback in <GroupLink resource={resource} group={group} />
        </span>
      ) : (
        <span>
          posted a discussion in <GroupLink resource={resource} group={group} />
        </span>
      )
    case LEARNING_ACTION_CREATE_RESPONSE:
      if (discussion.type === DISCUSSION_TYPE_FEEDBACK) {
        return (
          <span>
            gave feedback on{" "}
            <StepLink resource={resource} group={group} step={step} />
          </span>
        )
      } else if (discussion.is_discussion_question) {
        return "replied to a discussion"
      } else {
        const opUser = discussion_response.parent_response
          ? discussion_response.parent_response.user
          : discussion.user
        const opIsYou = window.user && window.user.id === opUser.id
        return `replied to ${opIsYou ? "you" : opUser.display_name}`
      }
    case LEARNING_ACTION_SUBSCRIPTION_PAID:
      return "paid for a subscription"
    case LEARNING_ACTION_PAID_REGISTRATION:
      return "paid for a single registration"
    case LEARNING_ACTION_CREATE_MEMBERSHIP:
      return "created an account"
    case LEARNING_ACTION_SUBSCRIBED:
      return "subscribed"
    case LEARNING_ACTION_UNSUBSCRIBED:
      return "canceled their subscription"
    case LEARNING_ACTION_PAUSED_SUBSCRIPTION:
      return "paused their subscription"
    case LEARNING_ACTION_CANCELED_PAUSED_SUBSCRIPTION:
      return "canceled their paused subscription"
    case LEARNING_ACTION_UNPAUSED_SUBSCRIPTION:
      return "unpaused their subscription"
    case LEARNING_ACTION_COMPLETED_COURSE:
      return (
        <span>
          completed <ResourceLink resource={resource} />
        </span>
      )
    case LEARNING_ACTION_REGISTRATION:
      return (
        <span>
          registered for <ResourceLink resource={resource} />
          {group.name !== resource.name && (
            <span>
              , <GroupLink resource={resource} group={group} />
            </span>
          )}
        </span>
      )
    case LEARNING_ACTION_CREATED_GROUP:
    case COMBINED_LEARNING_ACTION_GROUP_CLASS:
      return (
        <span>
          created a new group
          {group.seats > 0 && ` with ${group.seats} seats`},{" "}
          <GroupLink resource={resource} group={group} />, taking{" "}
          <ResourceLink resource={resource} />
        </span>
      )
    case COMBINED_LEARNING_ACTION_MEMBERSHIP:
      return "created an account and subscribed"
    default:
      return null
  }
}
