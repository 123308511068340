// NOTE: setting debug defautl to true is broken. Have to run:
// App.stores._mutate({debug: true})
// App.stores.initDebug()

const BackboneStore = require("./backbone-store").default

const registeredStores = [
  "analytics",
  "auth",
  "authorAdmin",
  "billing",
  "categoryAdmin",
  "contentAdmin",
  "discussion",
  "groupAdmin",
  "layout",
  "member",
  "message",
  "navigation",
  "note",
  "notification",
  "path",
  "pathAdmin",
  "pathAnalytics",
  "pathwrightAccount",
  "profile",
  "register",
  "resource",
  "resourceAdmin",
  "resourceLicenseAdmin",
  "school",
  "step",
  "stepCompletion",
  "stepDownloadsAdmin",
  "subscribe",
  "support"
]

class AppStore extends require("lib/static-shim").default(BackboneStore) {
  static initClass() {
    this.prototype.actions = {
      debug(debug, storeKeys, debug_theme) {
        if (storeKeys == null) {
          storeKeys = null
        }
        if (debug_theme == null) {
          debug_theme = null
        }
        if (debug_theme == null) {
          debug_theme = this.get("debug_theme")
        }
        this._mutate({ _debug: debug, debug_theme })
        if (!storeKeys) {
          storeKeys = _.keys(this.get("stores"))
        }
        return Array.from(storeKeys).map(key =>
          this.requestStore(key).action._debug(debug, this.get("debug_theme"))
        )
      }
    }

    this.prototype.requests = {
      registeredStoreKeys() {
        return (() => {
          const result = []
          for (let key in this._storeMap) {
            const val = this._storeMap[key]
            result.push(key)
          }
          return result
        })()
      }
    }
  }

  defaults() {
    return {
      stores: {},
      debug: false,
      debug_theme: "against_black"
    }
  }

  initialize() {
    this._storeMap = {}
    return (this._requestedStores = {})
  }

  initDebug() {
    if (_.isArray(this.get("debug"))) {
      return this.action.debug(true, this.get("debug"))
    } else if (this.get("debug")) {
      return this.action.debug(this.get("debug"))
    }
  }

  registerStore(key, StoreKlass) {
    if (this._storeMap[key] == null) {
      this._storeMap[key] = StoreKlass
    }
    return this._storeMap[key]
  }

  destroyStore(lookupKey) {
    if (this.get(lookupKey)) {
      this.get(lookupKey).destroy()
      this._mutate({ [lookupKey]: null })
      delete this.attributes[lookupKey]
      return delete this.get("stores")[lookupKey]
    } else {
      return console.warn(
        `Attempted to destroy non-existing store: ${lookupKey}`
      )
    }
  }

  requestStore(storeKey, initialState, key) {
    if (initialState == null) {
      initialState = {}
    }
    if (key == null) {
      key = ""
    }
    ;`\ 
Lazy loads a store by registered storeKey. If a key is passed in then
a new instance of the store is created with the provided key.\
`
    const lookupKey = `${storeKey}${key}`
    if (!this._requestedStores[lookupKey]) {
      const StoreClass = this._storeMap[storeKey]
      if (StoreClass) {
        const store = new StoreClass()
        if (!_.isEmpty(initialState)) {
          store.updateInitialState(initialState)
        }
        if (!this.get(lookupKey)) {
          // avoid race condition
          this._mutate({ [lookupKey]: store })
          store._key = lookupKey
          store._storeKey = storeKey
          store.trigger("store:registered")

          store._initialize()
          if (this.get("debug") && _.includes(this.get("debug"), store._key)) {
            store.action._debug(true, this.get("debug_theme"))
          }
          this.get("stores")[lookupKey] = store
          this._requestedStores[lookupKey] = true
        }
      } else {
        if (!registeredStores.includes(storeKey)) {
          throw new Error(`No store class with key ${storeKey} registered`)
        } else {
          // Allows for requesting a store but failing silently if store does
          // not yet exist. Mainly helpful for UIs conditionally depending on store
          // data where the store is not guaranteed to be available (due to chunking).

          // Return a default BackboneStore to avoid errors.
          const store = new BackboneStore()
          // Need to set an initial state otherwise .getState() returns undefined.
          store.updateInitialState({ foo: "bar" })
          return store
        }
      }
    } else {
      if (!_.isEmpty(initialState)) {
        this.get(lookupKey).updateInitialState(initialState)
      }
    }

    return this.get(lookupKey)
  }
}
AppStore.initClass()

export default AppStore
