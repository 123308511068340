import Hls from "hls.js"
import React from "react"

// https://video-react.js.org/customize/customize-source/
const HLSSource = props => {
  const { src, video, player, needsPreload, type, preload } = props

  const [attached, setAttached] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [hls, setHls] = React.useState(new Hls({ autoStartLoad: true }))

  React.useEffect(() => {
    const initMedia = async () => {
      // Only load this up on mount if the preload attribute is auto
      await attachMedia(src, video)

      if (needsPreload) {
        loadVideo(src, video)
      }
    }
    initMedia()

    return () => {
      // destroy hls video source
      if (hls) {
        hls.destroy()
      }
    }
  }, [])

  React.useEffect(() => {
    const changeSrc = async () => {
      // The video source was changed (ie through Media Manager)
      if (src) {
        await attachMedia()
        await loadVideo(src, video)
        video.pause()
      }
    }
    changeSrc()
  }, [src])

  React.useEffect(() => {
    // The Player was started (this avoids preloading unnecessarily)
    if (player.hasStarted && !loaded) {
      loadVideo(src, video)
    }
  }, [player, loaded])

  const attachMedia = async () => {
    if (!Hls.isSupported()) return Promise.resolve()

    if (!hls) return Promise.resolve()

    // Prevent memory leaks
    hls.detachMedia()

    return new Promise((resolve, reject) => {
      // "Attaching" allows the hls source to be controlled by <video /> methods
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        setAttached(true)
        resolve()
      })
      hls.attachMedia(video)
    })
  }

  const loadVideo = async (src, video) => {
    if (!Hls.isSupported() || !attached || !hls) return Promise.resolve()

    return new Promise((resolve, reject) => {
      hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        // console.log("manifest loaded, found " + data.levels.length + " quality level");
        setLoaded(true)
        // If parent Player is playing, run the video
        if (player.hasStarted && !video.paused) video.play()
        resolve()
      })
      hls.loadSource(src)
    })
  }

  return (
    <source
      preload={preload || "metadata"}
      src={src}
      type={type || "application/x-mpegURL"}
    />
  )
}

export default HLSSource
