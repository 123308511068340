import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { useEffect, useState } from "react"
import styled from "styled-components"
import "velocity-animate"
import "velocity-animate/velocity.ui"
import { VelocityComponent } from "velocity-react"
import { useIntercomContext } from "./IntercomProvider"
import LauncherImage from "./pathwright-intercom-launcher.png"
// The PathwrightIntercomLauncher is only set up for Pathwright, but
// maybe in the future we could allow other schools to use their own custom launcher?

const StyledLauncherContainer = styled.div`
  transform: scale(0);
  background-color: rgb(220, 59, 57);
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 60px;
  width: 60px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06), 0 2px 32px rgba(0, 0, 0, 0.16);
  z-index: 2147483000;
`

const StyledLauncherItem = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const StyledLauncherImage = styled(StyledLauncherItem)`
  background-image: url(${LauncherImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
`

const StyledLauncherClose = styled(StyledLauncherItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

const PathwrightIntercomLauncher = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { usingPathwrightIntercom } = useIntercomContext()

  const toggleIsOpen = () => {
    if (window.Intercom) {
      if (isOpen) {
        window.Intercom("hide")
      } else {
        window.Intercom("showNewMessage")
      }
    }
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handleToggle = () => {
      window.Intercom("onShow", () => setIsOpen(true))
      window.Intercom("onHide", () => setIsOpen(false))
    }

    if (usingPathwrightIntercom) {
      // Setup initial handlers for onShow and onHide events.
      handleToggle()

      // Bind "boot" handler since when Intercom("shutdown") is executed,
      // any previous onShow and onHide handlers are removed and will need to
      // be bound again after Intercom("boot").
      const handler = e => {
        if (e.detail === "boot") handleToggle()
      }
      document.addEventListener("intercom", handler)
      return () => document.removeEventListener("intercom", handler)
    }
  }, [usingPathwrightIntercom])

  if (!usingPathwrightIntercom) return null

  return (
    <VelocityComponent
      animation={{
        scale: 1,
        rotateZ: isOpen ? "90deg" : "0deg"
      }}
      duration={200}
    >
      <StyledLauncherContainer
        id="pathwright-intercom-launcher"
        onClick={toggleIsOpen}
      >
        <VelocityComponent
          animation={{
            scale: isOpen ? 1 : 0
          }}
          duration={200}
        >
          <StyledLauncherClose>
            <Pathicon icon="x" />
          </StyledLauncherClose>
        </VelocityComponent>
        <VelocityComponent
          animation={{
            scale: isOpen ? 0 : 1
          }}
          duration={200}
        >
          <StyledLauncherImage />
        </VelocityComponent>
      </StyledLauncherContainer>
    </VelocityComponent>
  )
}

PathwrightIntercomLauncher.displayName = "PathwrightIntercomLauncher"

export default PathwrightIntercomLauncher
