import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import styled from "styled-components"
import { Box, Flex } from "@chakra-ui/react"

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  li .Button span {
    display: flex;
    justify-content: space-between;
    white-space: pre-wrap;
    text-align: left;
  }
`

const FacetItem = ({ facet, facetNameField, onClick, isActive }) => {
  const [loading, setLoading] = React.useState(false)
  // hacky!
  const clearLoadingRef = React.useRef(false)

  // clearLoadingRef will determine if loading state should be cleared
  React.useEffect(() => {
    if (clearLoadingRef.current) {
      clearLoadingRef.current = false
      setLoading(false)
    }
  }, [clearLoadingRef.current])

  return (
    <Box as="li" pos="relative" className="Facets__item">
      <Button
        onClick={(e) => {
          setLoading(true)
          onClick(e)
          // now after setLoading has caused a re-render, setup the next render to clear loading state
          clearLoadingRef.current = true
        }}
        styleType={isActive ? "secondary" : "tertiary"}
        stretch={true}
        style={{ padding: "0.5rem 0.5rem", borderRadius: "8px" }}
      >
        {({ backgroundColor }) => (
          <>
            <Flex alignItems="center">
              {isActive && (
                <Box as="span" mr={2}>
                  <Pathicon icon="check" style={{ fontSize: "0.8em" }} />
                </Box>
              )}
              {facet[facetNameField]} {facet.count && `(${facet.count})`}{" "}
            </Flex>
            {loading ? (
              <LoadingCircle color={backgroundColor} center={false} size={15} />
            ) : isActive ? (
              <Pathicon icon="x" style={{ fontSize: "0.8em" }} />
            ) : null}
          </>
        )}
      </Button>
    </Box>
  )
}

const Facets = ({
  facets,
  selectedFacetNames: upstreamSelectedFacetNames,
  onSelectFacetNames,
  facetNameField
}) => {
  const [selectedFacetNames, setSelectedFacetNames] = React.useState(
    upstreamSelectedFacetNames || []
  )

  React.useEffect(() => {
    setSelectedFacetNames(upstreamSelectedFacetNames || [])
  }, [upstreamSelectedFacetNames])

  const handleToggleFacet = (facet) => {
    if (selectedFacetNames.includes(facet[facetNameField])) {
      // remove if facet name is already included
      setSelectedFacetNames(
        selectedFacetNames.filter((name) => name !== facet[facetNameField])
      )
    } else {
      // otherwise, add
      setSelectedFacetNames([...selectedFacetNames, facet[facetNameField]])
    }
  }

  const getIsFacetActive = (facet) =>
    selectedFacetNames.includes(facet[facetNameField])

  React.useEffect(() => {
    onSelectFacetNames && onSelectFacetNames(selectedFacetNames)
  }, [selectedFacetNames])

  if (!facets || !facets.length) return null

  return (
    <List className="Facets">
      {facets.map((facet) => (
        <FacetItem
          key={facet[facetNameField]}
          facet={facet}
          facetNameField={facetNameField}
          onClick={() => handleToggleFacet(facet)}
          isActive={getIsFacetActive(facet)}
        />
      ))}
    </List>
  )
}

Facets.displayName = "Facets"

Facets.propTypes = {
  facetNameField: PropTypes.string.isRequired
}

export default Facets
