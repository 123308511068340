import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import Map from "es6-map"
import { getSchoolUrl } from "@pathwright/web/src/modules/utils/urls"

// TODO: rebuild w/ graphql query. Just prototyping w/ rest api.

const cache = new Map()
function fetchContent({ id, type, stepId }) {
  if (cache.has(id)) {
    return Promise.resolve(cache.get(id))
  }
  const authToken = localStorage.getItem("auth_token")
  const apiUrl = getSchoolUrl(
    `api/private/content/${type}/${id}/?perms=step_${stepId}`
  )
  const headers = {
    "X-Pathwright-API-Version": moment().format("YYYY-MM-DD"),
    "X-Pathwright-Application-Version": 1.0
  }
  if (authToken) headers.Authorization = `Token ${authToken}`
  return fetch(apiUrl, { method: "get", headers })
    .then(res => res.json())
    .then(res => {
      cache.set(id, res)
      return Promise.resolve(res)
    })
}

export default function withContent(ComposedComponent) {
  return class extends Component {
    state = {
      error: null,
      content: null,
      loading: false
    }

    componentDidMount() {
      this.setState({ loading: true })
      fetchContent(this.props)
        .then(content => {
          this.setState(s => ({ content, error: null }))
        })
        .catch(error => {
          this.setState(s => ({ error }))
        })
        .then(() => {
          this.setState(s => ({ loading: false }))
        })
    }

    render() {
      return <ComposedComponent {...this.state} {...this.props} />
    }
  }
}
