import gql from 'graphql-tag'

export default gql`
  mutation($id: Int!, $school_type: SchoolType, $plan_to_sell: Boolean) {
    updateSchoolProfile(
      id: $id
      school_type: $school_type
      plan_to_sell: $plan_to_sell
    ) {
      id
      school_type
      plan_to_sell
    }
  }
`
