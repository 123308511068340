import RedeemSchoolGiftSubscriptionFlow from "@pathwright/web/src/modules/subscription/redeem-school-gift-subscription-flow/RedeemSchoolGiftSubscriptionFlow"
import FallbackComponent from "store/StoreOrLibraryBrowse"
import { createCardRoutes } from "lib/core/routing/card-routing"

export default createCardRoutes(
  "/",
  {
    redeem: {
      component: RedeemSchoolGiftSubscriptionFlow
    }
  },
  FallbackComponent
)
