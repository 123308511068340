import { BLOCKS_MODE } from "@pathwright/blocks-engine"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import React, { Suspense } from "react"

const BlocksEditor = React.lazy(() =>
  import(
    "./BlocksContentEditorContainer"
    /* webpackChunkName: "BlocksContentEditorContainer" */
  )
)

const BlocksViewer = React.lazy(() =>
  import(
    "./BlocksContentViewerContainer"
    /* webpackChunkName: "BlocksContentViewerContainer" */
  )
)

const BlockContentContainer = props => {
  const BlockComponent =
    props.mode?.toLowerCase() === BLOCKS_MODE.EDIT.toLowerCase()
      ? BlocksEditor
      : BlocksViewer

  return (
    <Suspense fallback={<LoadingCircle />}>
      <BlockComponent {...props} />
    </Suspense>
  )
}

export default BlockContentContainer
