import throttle from "lodash/throttle"
import PropTypes from "prop-types"
import React from "react"
import { isMobile } from "@pathwright/media-utils"
import AudioProgress from "./AudioProgress"
import DownloadButton from "./DownloadButton"
import PlayButton from "./PlayButton"
import Playlist from "./Playlist"
import SkipButton from "./SkipButton"
import SpeedSelector from "./SpeedSelector"
import TrackTime from "./TrackTime"
import {
  ControlsCenter,
  ControlsLeft,
  ControlsRight,
  ControlsRow,
  ControlsUI,
  SimpleSkin,
  TrackTitle
} from "./ui"
import Volume from "./Volume"

const initState = ({ src, playlist, playing }) => ({
  playing: playing || false,
  scrubbingProgress: false,
  currentProgress: 0, // Percentage
  currentTime: 0, // Actual time elapsed in audio element
  duration: 0,
  audioElement: null,
  volume: 0.5,
  playbackRate: 1,
  currentSource: src ? src : playlist[0].src,
  metadataLoaded: false
})

const reducer = (state, { type, ...payload }) => ({ ...state, ...payload })

const CustomAudioPlayer = (props) => {
  const {
    src,
    playlist,
    playing,
    enablePlaylist,
    onPlay,
    showControls,
    autoPlay,
    renderPlayButton,
    trackTitle,
    customStyles,
    allowDownload,
    ...audioProps
  } = props

  const [state, setState] = React.useReducer(reducer, props, initState)
  const audioRef = React.useRef(null)

  if (!src && !playlist)
    throw new Error("Please supply an audio source or non-empty playlist")

  React.useEffect(() => {
    if (!audioRef.current) return

    audioRef.current.addEventListener("loadedmetadata", onAudioLoad)

    return () => {
      audioRef.current.removeEventListener("loadedmetadata", onAudioLoad)
      audioRef.current.removeEventListener("timeupdate", onTimeUpdate)
    }
  }, [audioRef.current])

  React.useEffect(() => {
    if (src) {
      setState({ currentSource: src })
      resetAudio()
    }
  }, [src])

  React.useEffect(() => {
    if (playing !== state.playing) {
      handleTogglePlaying({ restart: true })
    }
  }, [playing])

  const initializeAudio = (audioElement) => {
    if (!audioElement || audioRef.current) return
    audioRef.current = audioElement
    // audioRef.current.load()

    const { volume, playbackRate } = audioElement

    setState({
      audioElement,
      volume,
      playbackRate
    })

    audioElement.addEventListener(
      "timeupdate",
      throttle(onTimeUpdate, 250, {
        leading: true,
        trailing: false
      })
    )
  }

  const onAudioLoad = (event) => {
    // We don't know the duration until all metadata is loaded
    setState({
      duration: event.target.duration,
      metadataLoaded: true
    })
  }

  const handleTogglePlaying = (options = {}) => {
    const { restart } = options

    if (!audioRef.current || !state.metadataLoaded) return

    setState({
      playing: !state.playing,
      duration: audioRef.current.duration
    })

    if (restart) {
      audioRef.current.currentTime = 0
    }
    state.playing ? audioRef.current.pause() : playAudio()
  }

  const playAudio = async () => {
    try {
      await audioRef.current.play()
    } catch (err) {
      // console.error("Couldn't play audio: ", err)
    }
  }

  // Update UI after audio time updates
  const onTimeUpdate = (progressEvent) => {
    const { currentTime, duration } = progressEvent.target

    setState({
      currentTime,
      currentProgress: (currentTime / duration) * 100 || 0
    })
  }

  // Set progress bar manually
  const handleSeekProgress = (nextProgress) => {
    if (nextProgress > 100 || nextProgress < 0) return

    const nextTime = audioRef.current.duration * (nextProgress / 100)
    setState({
      currentTime: nextTime
    })
    audioRef.current.currentTime = nextTime
  }

  // Don't play while scrubbing progress bar
  const handleStartScrubProgress = () => {
    setState({
      scrubbingProgress: true
    })

    if (audioRef.current.paused) return

    setState({ playing: false })
    audioRef.current.pause()
  }

  // Start playing again after scrubbing progress bar
  const handleStopScrubProgress = () => {
    if (!state.scrubbingProgress) return

    setState({
      scrubbingProgress: false
    })

    if (!audioRef.current.paused) return

    setState({ playing: true })
    audioRef.current.play()
  }

  const resetAudio = () => {
    if (!audioRef.current) return

    audioRef.current.pause()
    audioRef.current.currentTime = 0

    setState({
      playing: false,
      playbackRate: 1,
      volume: 0.5
    })
  }

  const handleSkip = (type) => () => {
    const { currentTime, duration } = audioRef.current

    let nextTime
    if (type === "prev") {
      const rawTime = currentTime - 15
      nextTime = rawTime >= 0 ? rawTime : 0
    } else {
      const rawTime = currentTime + 15
      nextTime = rawTime <= duration ? rawTime : duration
    }

    handleSeekProgress((nextTime / duration) * 100)

    return nextTime
  }

  const handleSetVolume = (volumeAsPercentage) => {
    const volume = volumeAsPercentage / 100
    audioRef.current.volume = volume
    setState({
      volume
    })
  }

  const handleSetSpeed = (speed) => {
    audioRef.current.playbackRate = speed
    setState({
      playbackRate: speed
    })
  }

  const handleSelectSource = (sourceIndex) => {
    if (!playlist) {
      handleTogglePlaying()
      return
    }
    const currentSource = playlist[sourceIndex]
    setState({
      currentSource: currentSource.src
    })
  }

  const renderControls = () => (
    <ControlsUI customStyles={customStyles}>
      {enablePlaylist && (
        <ControlsRow>
          <Playlist
            sources={[src]}
            current={state.currentSource}
            onSelectSource={handleSelectSource}
          />
        </ControlsRow>
      )}
      <ControlsRow>
        <TrackTitle>{trackTitle || "Current Audio"}</TrackTitle>
      </ControlsRow>
      <ControlsRow rowOnMobile={true}>
        <TrackTime type="start" {...state} />
        <AudioProgress
          progress={state.currentProgress}
          onSeek={handleSeekProgress}
          onStartScrub={handleStartScrubProgress}
          onStopScrub={handleStopScrubProgress}
        />
        <TrackTime type="end" {...state} />
      </ControlsRow>
      <ControlsRow className="control-players">
        {!isMobile() && (
          <ControlsLeft>
            <Volume
              currentVolume={state.volume}
              onSetVolume={handleSetVolume}
            />
          </ControlsLeft>
        )}
        <ControlsCenter>
          <SkipButton onSkip={handleSkip} type="prev" />
          <PlayButton
            onTogglePlaying={handleTogglePlaying}
            playing={state.playing}
          />
          <SkipButton onSkip={handleSkip} type="next" />
        </ControlsCenter>
        <ControlsRight>
          <SpeedSelector
            speedOptions={[1, 1.5, 2, 3, 0.5]}
            currentSpeed={state.playbackRate}
            onSelectSpeed={handleSetSpeed}
          />
          {allowDownload !== false && (
            <DownloadButton source={state.currentSource} />
          )}
        </ControlsRight>
      </ControlsRow>
    </ControlsUI>
  )

  // Eventually can add different skins
  const Skin = SimpleSkin

  return (
    <Skin className="BlocksAudioPlayer">
      <audio
        {...audioProps}
        ref={initializeAudio}
        src={state.currentSource}
        onPlay={onPlay}
        autoPlay={autoPlay || false}
        preload="metadata"
        controls={false}
      />
      {showControls ? (
        renderControls()
      ) : renderPlayButton ? (
        renderPlayButton({
          playing: state.playing,
          onTogglePlaying: handleTogglePlaying
        })
      ) : (
        <PlayButton
          onTogglePlaying={handleTogglePlaying}
          playing={state.playing}
        />
      )}
    </Skin>
  )
}

CustomAudioPlayer.propTypes = {
  src: PropTypes.string,
  playlist: PropTypes.arrayOf(PropTypes.object),
  onPlay: PropTypes.func,
  trackTitle: PropTypes.string,
  enablePlaylist: PropTypes.bool,
  renderPlayButton: PropTypes.func,
  showControls: PropTypes.bool,
  customStyles: PropTypes.object
}

CustomAudioPlayer.defaultProps = {
  showControls: true
}

export default CustomAudioPlayer
