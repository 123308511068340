import React from "react"
import "./CategoryList.css"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import SortableList from "@pathwright/ui/src/components/sortable/SortableList"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"

const CategoryList = ({ categories, baseURL, updateCategoryOrder }) => (
  <ScrollView className="CategoryList" style={{ minHeight: 400 }} fill={true}>
    <SortableList
      list={categories}
      onChange={updateCategoryOrder}
      deferDragHandlingToItem
      renderItem={({ item, isDragging, dragHandleProps }) => {
        return (
          <CardLink
            to={`${baseURL}${item.id}/`}
            label={item.name}
            preview={`${item.resources_count} courses`}
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
          />
        )
      }}
    />
  </ScrollView>
)

CategoryList.displayName = "CategoryList"
export default CategoryList
