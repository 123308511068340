import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

// https://video-react.js.org/customize/customize-component/
const DownloadButton = props => {
  const { player, className, downloadURL } = props
  let currentSrc
  if (!downloadURL) {
    currentSrc = player.currentSrc
  }

  return (
    <a
      className={classNames(className, {
        "video-react-control": true,
        "video-react-button": true
      })}
      href={downloadURL || currentSrc}
      download="video"
      target="_blank"
      tabIndex="0"
    >
      <Pathicon
        icon="download"
        style={{
          color: "white",
          fontSize: "1.4em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          lineHeight: "1.4em",
          height: "100%"
        }}
      />
    </a>
  )
}

DownloadButton.propTypes = {
  player: PropTypes.object,
  className: PropTypes.string
}

export default DownloadButton
