import { useApolloClient, useQuery } from "@apollo/client"
import { HStack, Heading, Spacer, Spinner, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import BlankButton from "@pathwright/ui/src/components/button/BlankButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import gql from "graphql-tag"
import produce from "immer"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"
import { useUpdateResourceCertificateEnabled } from "../gating/DisabledResourceCertificateGate"
import CertificateResource from "./CertificateResource"
import CertificateResourceRemoveConfirm from "./CertificateResourceRemoveConfirm"

export const CERTIFICATE_RESOURCES_QUERY = gql`
  query CertificateResourcesQuery(
    $first: Int!
    $hasCertificate: Boolean
    $search: String
  ) {
    resources(
      first: $first
      has_certificate: $hasCertificate
      search: $search
    ) {
      total
      edges {
        node {
          ...Resource
          certificate_json
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
`

function CertificateResources() {
  const { t } = useTranslate()
  const client = useApolloClient()
  const variables = {
    first: 1042,
    hasCertificate: true
  }
  const query = useQuery(CERTIFICATE_RESOURCES_QUERY, { variables })
  const resources = query.data?.resources?.edges?.map((e) => e.node) || []

  function removeResourceCertificateFromList(resource) {
    const data = client.readQuery({
      query: CERTIFICATE_RESOURCES_QUERY,
      variables
    })

    const nextData = produce(data, (draft) => {
      const index = draft.resources.edges.findIndex(
        (edge) => edge.node.id === resource.id
      )
      draft.resources.edges.splice(index, 1)
    })

    client.writeQuery({
      query: CERTIFICATE_RESOURCES_QUERY,
      variables,
      data: nextData
    })
  }

  const [removeResourceCertificate] = useUpdateResourceCertificateEnabled({
    enable: false,
    onError: query.refetch
  })

  function onRemoveResourceCertificate(resource) {
    // Optimistically remove item from cache.
    removeResourceCertificateFromList(resource)
    return removeResourceCertificate(resource)
  }

  const heading = t("Including Certificate in {{count}} Path:", {
    defaultValue_plural: "Including Certificate in {{count}} Paths:",
    count: resources.length
  })

  return (
    <VStack spacing={4} w="100%">
      {query.loading && !resources.length ? (
        <Spinner size="sm" />
      ) : (
        <Heading as="h4" size="sm">
          {heading}
        </Heading>
      )}
      <Spacer />
      {resources.map((resource) => (
        <CertificateResourceRemoveConfirm
          key={resource.id}
          resource={resource}
          onRemove={onRemoveResourceCertificate}
        >
          {({ confirm }) => (
            <CertificateResource
              resource={resource}
              onRemoveResourceCertificate={confirm}
              canEdit
            />
          )}
        </CertificateResourceRemoveConfirm>
      ))}
      <Spacer />
      <BlankButton to={`${window.location.pathname}add/`}>
        <HStack>
          <Pathicon icon="plus-circle-fill" />{" "}
          <span>Add Certificate to more Paths...</span>
        </HStack>
      </BlankButton>
    </VStack>
  )
}

export default CertificateResources
