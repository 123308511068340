import { pick } from "lodash"
import { Scope, ScopeType } from "../graphql/scopes"
import { BlockDataType } from "../types"
import { ServerBlockTypeType } from "@pathwright/blocks-core"

export const scopeContentData = (
  data: BlockDataType,
  type?: ServerBlockTypeType | string
): BlockDataType => {
  return scopeDataForType(data, type, Scope.CONTENT)
}

export const scopeUserData = (
  data: BlockDataType,
  type?: ServerBlockTypeType | string
): BlockDataType => {
  return scopeDataForType(data, type, Scope.USER)
}

export const scopeDataForType = (
  data: BlockDataType,
  type?: ServerBlockTypeType | string,
  scope?: ScopeType
): BlockDataType => {
  // returns only properties from data that match the given scope
  if (!type || typeof type === "string")
    throw new Error(
      "Block type must be an object with GQL fields data. Hint: use blocksContext.blockTypes ."
    )
  const contentFields = Object.values(type.fields)
    .filter(field => field.scope === scope)
    .map(f => f.name)
  const contentData = pick(data, contentFields)
  return contentData
}
