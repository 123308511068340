import "./RegisterHeader.css"
import PropTypes from "prop-types"
import classnames from "classnames"
import ResourceBackground from "@pathwright/ui/src/components/background/ResourceBackground"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import SchoolIdentity from "@pathwright/web/src/modules/lib/SchoolIdentity"
import Invitation from "./Invitation"

/*
 * PURPOSE:
 * Reusable header in registration flow to provide context (school or course) for registration.
 *
 * PROPS:
 * resource: use to set context to resource; defaults to school context; optional.
 * invitation: shows sender and role that user is registering for; optional.
 * children: use to show extra information under context; optional.
 *
 */

const RegisterHeader = ({
  resource,
  invitation,
  heading,
  children,
  closeTo,
  closeIcon,
  className,
  onClose
}) => {
  const screensize = useScreensizeContext()
  const roundedTop = screensize !== "sm"
  return (
    <View
      className={classnames("RegisterHeader", className)}
      roundedTop={roundedTop}
    >
      {resource ? (
        <ResourceBackground resource={resource} roundedTop={roundedTop} />
      ) : (
        <ThemeBackground roundedTop={roundedTop} />
      )}
      {(closeTo || onClose) && (
        <IconButton
          className="RegisterHeader__close"
          inverted
          icon={closeIcon}
          to={closeTo}
          onClick={e => {
            onClose ? onClose(e) : null
          }}
        />
      )}
      {invitation && <Invitation invitation={invitation} />}
      {resource ? (
        <Text.H3 inverted style={{ position: "relative" }}>
          {heading || resource.name}
        </Text.H3>
      ) : (
        <SchoolIdentity />
      )}
      {children && (
        <Text.Body inverted style={{ position: "relative" }}>
          {children}
        </Text.Body>
      )}
    </View>
  )
}

RegisterHeader.displayName = "RegisterHeader"

RegisterHeader.propTypes = {
  resource: PropTypes.shape({
    background_image_blurred_cdn: PropTypes.string,
    background_overlay: PropTypes.string,
    background_image: PropTypes.string,
    background_type: PropTypes.number
  }),
  invitation: PropTypes.shape({
    sender: PropTypes.object,
    role: PropTypes.number
  }),
  children: PropTypes.any
}

RegisterHeader.defaultProps = {
  closeIcon: "x"
}

export default RegisterHeader
