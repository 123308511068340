import PropTypes from "prop-types"
import React from "react"
import "./TextInput.css"
import classnames from "classnames"
import FieldWrapper from "../form-utils/FieldWrapper"
import InputAutosize from "react-input-autosize"

const TextInput = function(props) {
  let sizeProps
  let {
    id,
    type,
    label,
    value,
    required,
    children,
    minWidth,
    placeholder,
    autoSize,
    autoFocus,
    autoComplete,
    className,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    step
  } = props

  className = classnames(
    "TextInput",
    { "TextInput--autosize": autoSize },
    className
  )

  const Input = autoSize ? InputAutosize : "input"

  if (autoSize) {
    sizeProps = {
      minWidth: minWidth || 30,
      placeholderIsMinWidth: minWidth ? false : true
    }
  } else {
    sizeProps = {}
  }

  return (
    <FieldWrapper {...Object.assign({}, props, { className: className })}>
      {({ focus, onFocus, onBlur, registerInput }) => {
        return (
          <div className="TextInput__input">
            <Input
              {...Object.assign(
                {
                  id: id,
                  type: type,
                  name: label,
                  ref: registerInput,
                  required: required,
                  autoFocus: autoFocus || focus,
                  placeholder: placeholder,
                  value: value != null ? value : "",
                  autoComplete: !autoComplete && "off",
                  onChange: e =>
                    typeof onChange === "function"
                      ? onChange(e.target.value)
                      : undefined,
                  onKeyDown: e =>
                    typeof onKeyDown === "function"
                      ? onKeyDown(e.target.value, e)
                      : undefined,
                  onKeyUp: e =>
                    typeof onKeyUp === "function"
                      ? onKeyUp(e.target.value, e)
                      : undefined,
                  onFocus: onFocus,
                  onBlur: onBlur,
                  step: step
                },
                sizeProps
              )}
            />
          </div>
        )
      }}
    </FieldWrapper>
  )
}

TextInput.displayName = "TextInput"
TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  required: PropTypes.bool,
  focus: PropTypes.bool,
  autoSize: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // Show validation results
  showSuccess: PropTypes.bool,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  // Hook to TextInput events
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

TextInput.defaultProps = {
  type: "text",
  autoComplete: false
}

export default TextInput
