import { useMutation } from "@apollo/client"
import get from "lodash/get"
import SAVE_COMPLETION_FEEDBACK_MUTATION from "../graphql/save-completion-feedback-mutation"
import USER_COMPLETIONS_NEEDING_REVIEW from "../graphql/user-completions-needing-review"

// NOTE: currently no UIs use the USER_COMPLETIONS_NEEDING_REVIEW query.
// We may want to either update the cache or refetch the query used in useCompletions.
export const updateCacheOnReview = (userId, neededReview, getNeedsReview) => (
  cache,
  result
) => {
  const needsReview = getNeedsReview(result)

  let chagneInNeedsReviewCount = 0
  if (neededReview !== needsReview) {
    if (neededReview && !needsReview) chagneInNeedsReviewCount = -1
    if (!neededReview && needsReview) chagneInNeedsReviewCount = 1
  }

  if (userId && chagneInNeedsReviewCount) {
    // ...attempt to decrement any related needs reveiw path item stats
    const query = USER_COMPLETIONS_NEEDING_REVIEW
    const variables = {
      user_id: userId
    }

    try {
      const data = cache.readQuery({ query, variables })
      const nextData = {
        pathItemStats: {
          ...data.pathItemStats,
          path_items_needing_review: Math.max(
            0,
            data.pathItemStats.path_items_needing_review +
              chagneInNeedsReviewCount
          )
        }
      }
      cache.writeQuery({ query, variables, data: nextData })
    } catch (error) {
      console.log("error updating cache", error)
      // noop, query likey doesn't exist in the cache
    }
  }
}

// NOT IN USE
const useSaveCompletionFeedbackMutation = step => {
  // cache whether this completion needed grading
  const neededReview = step.completion.needs_grading
  const userId = get(step, "completion.user.id")

  const [saveCompletionFeedbackMutation, { loading, error }] = useMutation(
    SAVE_COMPLETION_FEEDBACK_MUTATION,
    {
      variables: {
        id: step.id,
        pathId: step.path_id
      },
      update: updateCacheOnReview(userId, neededReview)
    }
  )

  return [saveCompletionFeedbackMutation, { loading, error }]
}

export default useSaveCompletionFeedbackMutation
