import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Button from "../button/Button"

const StyledButton = styled(Button)`
  position: absolute !important;
  top: 0;
  left: 0;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  padding: 0 10px;
`

const CardBack = ({ card, backLabel, cancelLabel, inverted, t }) => {
  let icon = ""
  let label = ""

  if (card.isDirty) {
    label = cancelLabel || t("card.cancel")
  } else if (card.isIndex) {
    icon = "x"
  } else {
    icon = "arrow-left"
    label = backLabel || t("card.back")
    if (card.prevIsDirty) label += ` *`
  }

  return (
    <StyledButton
      className="Card__Back"
      onClick={() => card.onClose()}
      styleType="blank"
      icon={icon}
      label={label}
      inverted={inverted}
      brand
    />
  )
}

CardBack.displayName = "CardBack"

CardBack.propTypes = {
  cancelLabel: PropTypes.string,
  backLabel: PropTypes.string,
  card: PropTypes.object.isRequired
}

export default withTranslate(CardBack)
