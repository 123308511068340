import React from "react"
import styled from "styled-components"
import Screensize, {
  useScreensizeContext
} from "@pathwright/ui/src/components/ui/Screensize"
import Course from "@pathwright/ui/src/components/library/Course"
import { getAuthorLine } from "../../store/browse/grid/BrowseGrid"
import { useResourceDisplayPrice } from "./utils"

const itemsPerRow = Screensize.select({
  xl: 5,
  lg: 4,
  md: 3,
  sm: 2
})

const Grid = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(${itemsPerRow}, 1fr);
  grid-gap: 1em;
  list-style-type: none;

  li {
    /* override default grid item min-height and min-width so that grid item does not overflow based on its content size */
    min-height: 0;
    min-width: 0;
  }
`

const BrowseCourse = ({ course, ...rest }) => {
  const displayPrice = useResourceDisplayPrice(course)

  return <Course {...rest} course={course} displayPrice={displayPrice} />
}

function BrowseCourses({ courses, getCourseTo, getCourseSettingsTo }) {
  const screensize = useScreensizeContext()

  return (
    <Grid screensize={screensize}>
      {courses.map(course => (
        <li key={course.id}>
          <BrowseCourse
            course={course}
            meta={getAuthorLine(course)}
            to={getCourseTo(course)}
            toSettings={getCourseSettingsTo(course)}
          />
        </li>
      ))}
    </Grid>
  )
}

BrowseCourses.displayName = "BrowseCourses"

BrowseCourses.defaultProps = {
  browseTo: () => null,
  settingsTo: () => null
}

export default BrowseCourses
