import { useMutation } from "@apollo/client"
import FormAlert from "@pathwright/ui/src/components/alert/FormAlert"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Divider from "@pathwright/ui/src/components/divider/Divider"
import PasswordInput from "@pathwright/ui/src/components/form/form-text-input/PasswordInput"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import {
  getFormError,
  validate
} from "@pathwright/ui/src/components/form/utils"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import { getIsLocalHost } from "@pathwright/ui/src/components/utils/env"
import { Formik } from "formik"
import get from "lodash/get"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import GET_TOKEN_MUTATION from "./graphql/get-token-mutation"
import { AuthFooter, AuthWrapper, StyledHeader } from "./styles"
import { getRedirectForSSOLogin } from "./utils"

const tPrefix = "auth.sign_in_form"

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  ${props => (props.inverted ? "color: white;" : "")};
`

const SignInForm = ({
  onResetPw,
  renderHeader,
  renderSubmit,
  inviteToken,
  onSignUp,
  onAuthChange: onAuthChangeProp,
  inverted
}) => {
  const { t } = useTranslate()
  const { onAuthChange } = usePathwrightContext()

  const [mutation, mutationState] = useMutation(GET_TOKEN_MUTATION)
  const token = get(mutationState, "data.getToken.token")

  // Upon successful signin, execute auth change callbacks.
  useEffect(() => {
    if (token) {
      onAuthChangeProp && onAuthChangeProp()
      onAuthChange(token)
    }
  }, [token])

  // TODO: implement GQL schema/query
  const sso = get(window, "bootstrappedData.integrations.sso", null)

  const pathwrightLoginEnabled =
    (!!sso && sso.pathwright_login_enabled) || getIsLocalHost()

  const handleSubmit = values =>
    mutation({
      variables: { ...values, invite_token: inviteToken }
    })

  const resetPasswordLink = (
    <StyledLink onClick={onResetPw} inverted={inverted}>
      {t(`${tPrefix}.forgot_password_prompt`)}
    </StyledLink>
  )

  return (
    <Formik
      initialValues={{
        username: "",
        password: ""
      }}
      validate={validate((key, value, values) => {
        if (!value) return t("Required")
      })}
      onSubmit={handleSubmit}
    >
      {form => {
        // Continue in submission state when user has successfully signed in.
        // This avoids an awkward state change on the SubmitButton after success
        // before the SignInForm is unmounted, which it is always intended to be
        // unmouned after success.
        const isSubmitting = Boolean(
          form.isSubmitting || (form.submitCount >= 1 && token)
        )
        // Submission failed when after form submits, the token does not exist.
        const submissionFailed = Boolean(
          !form.isSubmitting && form.submitCount >= 1 && !token
        )

        return (
          <form onSubmit={form.handleSubmit}>
            {renderHeader({
              header: (
                <StyledHeader as="header">
                  <Text.H2>{t(`${tPrefix}.prompt`)}</Text.H2>
                </StyledHeader>
              )
            })}
            {pathwrightLoginEnabled && (
              <AuthWrapper>
                <TextInput
                  name="username"
                  // Cannot use type="email" as standard form submission will prevent submission
                  // when username value does not match a valid email format
                  // type="email"
                  value={form.values.username}
                  onChange={(value, e) => form.handleChange(e)}
                  onBlur={(value, e) => form.handleBlur(e)}
                  // label={`${t(`${tPrefix}.email`)}:`}
                  placeholder={`${t(`${tPrefix}.email_placeholder`)}...`}
                  inverted={inverted}
                  labelWidth={90}
                  flushLabel
                  autoFocus
                  // Only show error if password field has been touched.
                  errors={
                    form.touched.password && getFormError(form, "username")
                  }
                />
                <PasswordInput
                  name="password"
                  type="password"
                  value={form.values.password}
                  onChange={(value, e) => form.handleChange(e)}
                  onBlur={(value, e) => form.handleBlur(e)}
                  // label={`${t(`${tPrefix}.password`)}:`}
                  placeholder={`${t(`${tPrefix}.password_placeholder`)}...`}
                  inverted={inverted}
                  counter={resetPasswordLink}
                  labelWidth={90}
                  flushLabel
                  errors={getFormError(form, "password")}
                />
              </AuthWrapper>
            )}
            {submissionFailed && (
              <FormAlert
                error={t(`${tPrefix}.error_didnt_recognize`)}
                values={form.values}
              />
            )}
            <AuthFooter>
              {pathwrightLoginEnabled &&
                renderSubmit({
                  submitting: isSubmitting,
                  submitFailure: submissionFailed,
                  submit: (
                    <SubmitButton
                      submitting={isSubmitting}
                      submitFailure={submissionFailed}
                      inverted={inverted}
                      styleType="primary"
                      size="large"
                      hideSuccess
                      disabled={!form.dirty || !form.isValid}
                      label={t(`${tPrefix}.prompt`)}
                    />
                  )
                })}
              {sso &&
                sso.providers.map((provider, i) => (
                  <React.Fragment key={i}>
                    {(pathwrightLoginEnabled || i > 0) && (
                      <Divider inverted={inverted}>
                        {t(`${tPrefix}.or`)}
                      </Divider>
                    )}
                    <Button
                      brand
                      styleType="primary"
                      href={getRedirectForSSOLogin(provider.redirect_url)}
                    >
                      {provider.label}
                    </Button>
                  </React.Fragment>
                ))}
              {onSignUp && (
                <Button
                  onClick={e => {
                    e.stopPropagation()
                    onSignUp()
                  }}
                  inverted={inverted}
                  styleType="blank"
                >
                  {t(`${tPrefix}.no_account_prompt`)}{" "}
                  <Pathicon icon="arrow-right" />
                </Button>
              )}
            </AuthFooter>
          </form>
        )
      }}
    </Formik>
  )
}

SignInForm.displayName = "SignInForm"

SignInForm.propTypes = {
  renderHeader: PropTypes.func,
  renderSubmit: PropTypes.func,
  onResetPw: PropTypes.func.isRequired,
  onSignUp: PropTypes.func,
  inverted: PropTypes.bool
}

SignInForm.defaultProps = {
  renderHeader: ({ header }) => header,
  renderSubmit: ({ submit }) => submit
}

export default SignInForm
