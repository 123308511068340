import { ConnectStores } from "lib/core/store"
import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import isFunction from "lodash/isFunction"

const PricingBack = props => {
  const { backURL } = props

  if (!backURL) return null

  const urlProp =
    backURL.includes && isFunction(backURL.includes) && backURL.includes("http")
      ? {
          href: backURL
        }
      : {
          to: backURL
        }

  return (
    <Button {...urlProp} className="PricingBack" styleType="tertiary">
      <Pathicon icon="chevron-left" /> Back
    </Button>
  )
}

PricingBack.displayName = "PricingBack"

const mapStateToProps = (state, ownProps) => {
  const { getBackUrl } = state.billingStore.request
  const backURL = getBackUrl()
  return _.assign(
    {},
    {
      backURL
    },
    ownProps
  )
}

const stores = [{ store: "billing", watch: ["current_step"] }]

export default ConnectStores(PricingBack, stores, {}, mapStateToProps)
