import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import ManageDiscountsFeatureLock from "./ManageDiscountsFeatureLock"
import { getCodes } from "./utils"
import { DiscountsSetSuccess, DiscountsSingleSuccess } from "./DiscountsSuccess"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"

const ManageDiscountsForm = ({ card, title, forms }) => {
  const { tca } = useTranslate()
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  const initialValues = forms.reduce(
    (acc, { Form, initialValues }) => ({
      ...acc,
      ...Form.initialValues,
      ...initialValues
    }),
    {}
  )
  const validate = values =>
    forms.reduce((acc, { Form }) => ({ ...acc, ...Form.validate(values) }), {})

  return (
    <Card card={card} title={title}>
      <CardBlock>
        <ManageDiscountsFeatureLock>
          <Formik
            validateOnMount
            initialValues={initialValues}
            validate={validate}
          >
            {({ values, setValues, isValid, errors }) => (
              <>
                {forms.map(({ Form, props = {} }) => (
                  <Form
                    key={Form.displayName}
                    setValues={formValues =>
                      setValues({ ...values, ...formValues })
                    }
                    {...props}
                  />
                ))}

                {!!error && <Alert error={error} />}

                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <SubmitButton
                    size="large"
                    styleType="primary"
                    brand
                    disabled={!isValid}
                    onSubmit={async () => {
                      try {
                        const result = await getCodes(values)
                        // HACK ALERT: wrapping result in a function as result may itself be a function and useState calls the function immediately!
                        setResult(() => result)
                      } catch (error) {
                        setError(error)
                        // throw for SubmitButton to catch
                        throw error
                      }
                    }}
                  >
                    {tca("create code", {
                      defaultValue_plural: "create codes",
                      count: values.codes === "single" ? 1 : Infinity
                    })}
                  </SubmitButton>
                </div>

                {result ? (
                  typeof result === "string" ? (
                    <DiscountsSingleSuccess
                      code={result} // result is a string
                      onDone={card.onClose}
                    />
                  ) : (
                    <DiscountsSetSuccess
                      downloader={result} // result is a function
                      onDone={card.onClose}
                    />
                  )
                ) : null}
              </>
            )}
          </Formik>
        </ManageDiscountsFeatureLock>
      </CardBlock>
    </Card>
  )
}

ManageDiscountsForm.displayName = "ManageDiscountsForm"

ManageDiscountsForm.propTypes = {
  card: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      Form: PropTypes.func.isRequired,
      initialValues: PropTypes.object,
      props: PropTypes.object
    })
  ).isRequired,
  initialValues: PropTypes.object
}

export default ManageDiscountsForm
