import PropTypes from 'prop-types';
import React from "react"

// Preview any filestack url: https://www.filestack.com/docs/viewer

const FilestackPreview = function(props) {
  const { filestackURL, width, height } = props

  const previewSrc = filestackURL.replace("/file/", "/preview/")

  return <iframe src={previewSrc} style={{ height, width }} frameBorder={0} />
}

FilestackPreview.displayName = "FilestackPreview"

FilestackPreview.propTypes = {
  filestackURL: PropTypes.string.isRequired,
}

FilestackPreview.defaultProps = {
  height: "100%",
  width: "100%",
}

export default FilestackPreview
