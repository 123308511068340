import {
  Box,
  Collapse,
  HStack,
  Image,
  Stack,
  StackProps,
  Text,
  VStack,
  useDisclosure
} from "@chakra-ui/react"
import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import { forwardRef } from "react"
import { ResourceCopyConfigFragment } from "../../api/generated"
import ChakraTooltip from "../../components/ChakraTooltip"
import { flattenResourceCopyConfigs } from "./ResourceCopyConfigItems"

type ResourceCopyConfigItemProps = {
  item: ResourceCopyConfigFragment
  renderInternal?: ResourceCopyConfig
}

export type ResourceCopyConfig = (
  config: ResourceCopyConfigFragment
) => JSX.Element | null

export const ResourceCopyConfigItemStack = forwardRef(function (
  props: StackProps,
  ref
) {
  return (
    <Box
      ref={ref}
      as={HStack}
      bg="white"
      borderRadius="md"
      p={2}
      w="300px"
      boxShadow="md"
      pos="relative"
      {...props}
    />
  )
})

function ParentResourceCopyConfigItem({
  item,
  renderInternal
}: ResourceCopyConfigItemProps) {
  const { isOpen, getButtonProps, getDisclosureProps } = useDisclosure()
  const childItems = flattenResourceCopyConfigs(
    item.children as ResourceCopyConfigFragment[]
  ).reverse()
  const imageWidth = 40
  const imageOffset = 10
  const imageStackWidth = imageWidth + (childItems.length - 1) * imageOffset

  return (
    <>
      <ResourceCopyConfigItemStack
        as={VStack}
        {...getButtonProps()}
        cursor="pointer"
        alignItems="flex-start"
        spacing={0}
      >
        <HStack>
          <HStack
            h="30px"
            w={`${imageStackWidth}px`}
            pos="absolute"
            overflow="hidden"
          >
            {childItems.map((item, i) => (
              <Image
                src={item.resource!.meta.image!.src!}
                h="30px"
                alt={`Resource image for ${item.resource!.meta.title!}`}
                pos="absolute"
                zIndex={i}
                left={`${i * imageOffset}px`}
              />
            ))}
          </HStack>
          <VStack
            pl={`${imageStackWidth}px`}
            ml={2}
            alignItems="flex-start"
            spacing={0}
          >
            <HStack>
              <ChakraTooltip title={item.name!} fitContent>
                <Text noOfLines={1}>{item.name}</Text>
              </ChakraTooltip>
              {renderInternal?.(item)}
            </HStack>
            <HStack fontSize="xs" spacing={1}>
              <Pathicon
                icon="chevron-down"
                transform={`rotate(${isOpen ? "180" : "0"}deg)`}
              />
              <span>
                {childItems.length} Path{childItems.length > 1 ? "s" : ""}
              </span>
            </HStack>
          </VStack>
        </HStack>
      </ResourceCopyConfigItemStack>
      <Collapse {...getDisclosureProps()} in={isOpen} animateOpacity>
        <Stack mt={4}>
          {item.children?.map((item) => (
            <ResourceCopyConfigItem key={item!.id} item={item!} />
          ))}
        </Stack>
      </Collapse>
    </>
  )
}

function ResourceCopyConfigItem({
  item,
  renderInternal
}: ResourceCopyConfigItemProps) {
  return item.resource ? (
    <ResourceCopyConfigItemStack>
      <Image
        src={item.resource.meta.image!.src!}
        height="30px"
        alt={`Resource image for ${item.resource.meta.title!}`}
      />
      <ChakraTooltip title={item.resource.meta.title!} fitContent>
        <Text noOfLines={1}>{item.resource.meta.title}</Text>
      </ChakraTooltip>
      {renderInternal?.(item)}
    </ResourceCopyConfigItemStack>
  ) : (
    <ParentResourceCopyConfigItem item={item} renderInternal={renderInternal} />
  )
}

export default ResourceCopyConfigItem
