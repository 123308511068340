import React from 'react'
import DiscussionActivity from './DiscussionActivity'

const CreateResponseActivity = props => {
  return <DiscussionActivity {...props} />
}

CreateResponseActivity.displayName = 'CreateResponseActivity'

export default CreateResponseActivity
