import React from "react"
import moment from "moment"

import { TrackTimeUI } from "./ui"

export const TrackTime = ({ type, audioElement, duration, currentTime }) => {
  if (!audioElement) return null

  const rawSeconds = type === "start" ? currentTime : duration

  const rawTime = moment.utc(rawSeconds * 1000)
  let time = rawTime.format("HH:mm:ss")

  if (rawTime.hours() === 0) {
    time = rawTime.format("mm:ss")
  }

  // const hrs = Math.floor(rawTime / 60 / 60)
  // const mins = (rawTime / 60) % 60
  // const secs = rawTime % 60

  return <TrackTimeUI>{time}</TrackTimeUI>
}

export default TrackTime
