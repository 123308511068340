import Modal from "@pathwright/ui/src/components/modal/Modal"
import React, { createContext, useContext, useState } from "react"
import styled from "styled-components"

const Container = styled.div`
  background-color: #fff;
  border-radius: 40px;
  max-width: 600px;
  margin: 0px auto;
`

const ModalContext = createContext()

export const ModalContextProvider = ({ children }) => {
  const [modal, setModal] = useState()

  const showModal = (Component, props) => {
    setModal({ Component, props })
  }

  const closeModal = () => {
    setModal()
  }

  return (
    <ModalContext.Provider value={{ modal, showModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export const ModalPortal = () => {
  const { modal, closeModal } = useModal()
  if (modal)
    return (
      <Modal onClose={closeModal}>
        <Container className="ModalContainer">
          <modal.Component {...modal.props} />
        </Container>
      </Modal>
    )
  return null
}

export const useModal = () => {
  return useContext(ModalContext)
}
