import {
  getSignedFilestackURL,
  isFilePickerURL
} from "@pathwright/ui/src/components/filestack/utils"
import React from "react"
// USAGE: via ImageUpload
import "./_Atmosphere.css"

const AtmospherePreviewer = ({ file }) => {
  if ((file != null ? file.url : undefined) && isFilePickerURL(file.url)) {
    file.image =
      getSignedFilestackURL(file.url, { w: 200, h: 150 }, true) || null
  }

  return (
    <div className="AtmospherePreviewer">
      <div
        className="AtmospherePreviewer__bgd"
        style={{
          backgroundImage: `url(${
            file != null ? file.background_image : undefined
          })`
        }}
      />
      <div
        className="AtmospherePreviewer__img"
        style={{
          backgroundImage: `url(${file != null ? file.image : undefined})`
        }}
      />
    </div>
  )
}

AtmospherePreviewer.displayName = "AtmospherePreviewer"

export default AtmospherePreviewer
