// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const User = require("auth/models/user").default
let ChangePassword = require("auth/models/change-password").default
const UpdatePayment = require("form/components/payments/models/update-payment")
  .default
const PaymentHistory = require("account/models/payment-history-collection")
  .default

class ProfileStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.resources = {
      payment_history: {
        collection: PaymentHistory,
        pluralize: false
      }
    }

    this.prototype.storeTriggers = {
      "user:updated"() {
        return this.get("user")
      }
    }

    this.prototype.storeTriggerHandlers = {
      groupAdmin: {
        "offering:deleted"(offering) {}
      },
      auth: {
        "user:signed:in"({ user, session }) {
          return this._mutate({ user })
        },
        "user:signed:out"() {
          return this.reset()
        }
      }
    }

    this.prototype.actions = {
      loadUserSubscription(forceReload) {
        if (forceReload == null) {
          forceReload = false
        }
        return this.getStore("subscribe").action.loadUserSubscription(
          forceReload
        )
      },

      loadPaymentHistory(forceReload) {
        if (forceReload == null) {
          forceReload = false
        }
        return this.resource.payment_history
          .list({}, forceReload)
          .then(result => {
            const payment_history_download_url = result.download_url
            return this._mutate({ payment_history_download_url })
          })
      },

      selectProfileSection(section) {
        return this._mutate({ section })
      },

      saveUser(values) {
        let user = this.get("user")
        const profile = user.get("profile")

        if (values.hasOwnProperty("firstname")) {
          // submitting form data
          user.set({
            first_name: values.firstname,
            last_name: values.lastname,
            email: values.email
          })
          profile.bio = values.bio
          profile.location = values.location
          if (values.avatar.url) {
            profile.avatar = values.avatar.url
          }
        }

        user.set({ profile })

        // Have to set id on options when creating
        // and then editing user without refreshing.
        user.options = { id: user.id }

        this._mutate({ is_saving_user: true })

        const p = new Promise(
          function(resolve, reject) {
            return user
              .save()
              .done(result => {
                user = new User(result)
                this._mutate({ user })
                this.action.setSuccessTimeout("user_success", "Profile saved")
                this.storeTrigger("user:updated")
                return resolve(user)
              })
              .fail(err => {
                this._mutate({ user_error: "Failed to save profile." })
                return reject(err)
              })
              .always(() => {
                return this._mutate({ is_saving_user: false })
              })
          }.bind(this)
        )
        return p
      },

      updatePayment(token) {
        const updatePayment = new UpdatePayment()
        updatePayment.set({ payment_token: token, silent: true })

        this._mutate({ is_saving_update_payment: true })
        const promise = updatePayment
          .save()
          .done(data => {
            return this.action.setSuccessTimeout(
              "update_payment_success",
              "Payment info updated"
            )
          })
          .fail(err => {
            const update_payment_error = JSON.parse(err.responseText).detail
            return this._mutate({ update_payment_error })
          })
          .always(() => {
            this._mutate({ is_saving_update_payment: false })
            return this.action.loadUserSubscription(true)
          })

        return promise
      },

      updatePassword(data) {
        ChangePassword = require("auth/models/change-password").default

        const changePassword = new ChangePassword()
        changePassword.set({
          current_password: data.currentpw.value,
          password1: data.newpw.value,
          password2: data.confirmpw.value
        })

        this._mutate({ password_success: null, password_error: null })
        this._mutate({ is_saving_password: true })
        return new Promise(
          function(resolve, reject) {
            return changePassword
              .save({}, { type: "PUT" })
              .done(result => {
                this.action.setSuccessTimeout(
                  "password_success",
                  "Password changed"
                )
                return resolve(changePassword)
              })
              .fail(err => {
                const password_error = JSON.parse(err.responseText).error
                this._mutate({ password_error })
                return reject(password_error)
              })
              .always(() => {
                return this._mutate({ is_saving_password: false })
              })
          }.bind(this)
        )
      },

      pauseSubscription(pause_months) {
        return this.getStore("subscribe")
          .action.pauseSubscription(pause_months)
          .promise.then(() => {
            const pause_subscription_success =
              "Your subscription has been paused."
            return this._mutate({ pause_subscription_success })
          })
      },

      unPauseSubscription() {
        return this.getStore("subscribe")
          .action.unPauseSubscription()
          .promise.then(() => {
            const unpause_subscription_success =
              "Your subscription has been unpaused."
            return this._mutate({ unpause_subscription_success })
          })
      },

      cancelSubscription() {
        const subscribeStore = this.getStore("subscribe")
        let { subscription } = subscribeStore.getState()
        let msg = subscription.is_paused
          ? "Your subscription will not resume. "
          : "You cancelled your subscription. "

        return subscribeStore.action.cancelSubscription().promise.then(() => {
          ;({ subscription } = subscribeStore.getState())
          if (subscription.subscription != null) {
            msg += `\
You'll keep access to ${App.school.get("name")}
until ${moment(subscription.subscription.cycle_end_dtime).format(
              "MMMM Do YYYY"
            )}
and no additional charges will be made to your card.\
`
          }
          const cancel_subscription_success = msg
          return this._mutate({ cancel_subscription_success: msg })
        })
      },

      setSuccessTimeout(key, message) {
        this._mutate({ key, message })
        return _.delay(() => {
          return this._mutate({
            [key]: null
          })
        }, 1000)
      },

      clearResponses() {
        return this._mutate({
          user_error: null,
          user_success: null,
          password_error: null,
          password_success: null,
          cancel_subscription_success: null,
          pause_subscription_success: null,
          unpause_subscription_success: null,
          update_payment_success: null,
          update_payment_error: null
        })
      }
    }

    this.prototype.requests = {
      canDesignCurriculum() {
        const { school, permissions } = this.getStore("school").getState()
        return (
          school.is_curriculum_enabled &&
          (permissions.user_is_editing || this.request.canCreateResource())
        )
      },

      canCreateResource() {
        const { permissions } = this.getStore("school").getState()
        return (
          permissions.user_is_school_admin || permissions.user_is_school_editor
        )
      }
    }
  }

  defaults() {
    return {
      user: null, // user model
      payment_history: null,
      payment_history_download_url: null,
      section: null, // Which tab is the user looking at?

      user_loading: false,
      payment_history_loading: false,

      is_saving_user: false,
      is_saving_password: false,
      is_saving_update_payment: false,

      user_error: null,
      user_success: null,
      password_error: null,
      password_success: null,
      cancel_subscription_success: null,
      pause_subscription_success: null,
      update_payment_success: null,
      update_payment_error: null
    }
  }

  computeURLForState() {
    const section = this.get("section")
    const url = `/home/${section}/`
    return url
  }

  bootstrapStore() {
    if (!window.bootstrappedData) {
      return
    }
    let user = bootstrappedData.user || null
    user = new User(user)
    return this._mutate({ user })
  }
}
ProfileStore.initClass()

export default window.App.stores.registerStore("profile", ProfileStore)
