import { graphql } from "@apollo/client/react/hoc"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import compose from "lodash/flowRight"
import get from "lodash/get"
import moment from "moment"
import numeral from "numeral"
import PropTypes from "prop-types"
import ACCOUNT_QUERY from "../../account/graphql/account-query"
import {
  SUBSCRIPTION_CHARGE_TYPE_NO_CHARGE,
  SUBSCRIPTION_CHARGE_TYPE_PATHWRIGHT_TEAM,
  SUBSCRIPTION_CHARGE_TYPE_STRIPE
} from "../../subscription/constants"
import { LEARNER_COUNTING_METHOD_ACCESS } from "../constants"
import SCHOOL_BILLING_INVOICE_UPCOMING_QUERY from "../graphql/school-billing-invoice-upcoming-query"
import SCHOOL_BILLING_INVOICES_QUERY from "../graphql/school-billing-invoices-query"
import { InvoiceType } from "../types"
import { getInvoiceBillingDate } from "../utils"

const ManageBilling = ({
  card,
  numInvoices,
  invoice,
  account,
  canManageBilling,
  invoiceLoading
}) => {
  const billingSubscription = get(account, "billing_subscription")
  const curriculumSubscription = get(account, "curriculum_subscription")
  const subscription =
    get(billingSubscription, "subscription") ||
    get(curriculumSubscription, "subscription")

  const isPathwright =
    get(billingSubscription, "charge_type") ===
    SUBSCRIPTION_CHARGE_TYPE_PATHWRIGHT_TEAM

  return !account ? (
    <LoadingCircle />
  ) : (
    <Card card={card} title="Billing">
      {(account.is_activated_via_override && !canManageBilling) ||
      isPathwright ? (
        <BlankSlate
          icon="credit-card"
          heading={
            isPathwright
              ? "Billing is disabled, as your school is activated as a Pathwright Team account."
              : "You have a custom pricing plan."
          }
          primaryAction={{
            children: "Contact us",
            href: "mailto:hello@pathwright.com"
          }}
        />
      ) : (
        <>
          {invoiceLoading ? (
            <CardLink
              icon="toolbox"
              label={
                <div>
                  <LoadingCircle style={{ marginRight: "1rem" }} />
                  Searching...
                </div>
              }
              disabled
              onClick={event => console.log(event)}
              meta={`Please wait while we fetch your latest invoice...`}
              // to={`#`}
            />
          ) : invoice ? (
            // "Child" plans (bill is paid by another subscription)
            invoice.parent_subscription ||
            get(billingSubscription, "charge_type") ===
              SUBSCRIPTION_CHARGE_TYPE_NO_CHARGE ||
            !invoice.cycle_end ? (
              <CardLink
                icon="toolbox"
                label="View your plan"
                meta={`Review your billing plan details and usage`}
                to={`${card.path}status/`}
              />
            ) : (
              <CardLink
                icon="toolbox"
                label="View your plan & next bill estimate"
                meta={`There's $${numeral(invoice.total_amount_billed).format(
                  "0,0.00"
                )} coming due on ${moment(
                  getInvoiceBillingDate(invoice)
                ).format("MMM Do, YYYY")}`}
                to={`${card.path}status/`}
              />
            )
          ) : null}
          {(get(billingSubscription, "charge_type") ===
            SUBSCRIPTION_CHARGE_TYPE_STRIPE ||
            !!curriculumSubscription) && (
            <CardLink
              icon="credit-card"
              label="Change your card on file"
              meta={
                subscription
                  ? `Update your card on file (currently billing to XXXX-XXXX-${subscription.card_last_4})`
                  : ""
              }
              to={`${card.path}payment/`}
            />
          )}
          {numInvoices > 0 && (
            <CardLink
              icon="device"
              label="View billing history"
              preview={numInvoices}
              meta="Review previous bills"
              to={`${card.path}invoices/`}
            />
          )}
          {get(billingSubscription, "plan.learner_counting_method") ===
            LEARNER_COUNTING_METHOD_ACCESS && (
            <CardLink
              icon="gear"
              label="Configure billing settings"
              meta="Control how many members you are billed for."
              to={`${card.path}access/`}
            />
          )}
        </>
      )}
    </Card>
  )
}

ManageBilling.displayName = "ManageBilling"

ManageBilling.propTypes = {
  invoices: PropTypes.arrayOf(InvoiceType),
  invoice: InvoiceType
}

export default compose(
  graphql(ACCOUNT_QUERY, {
    props: ({ data }) => {
      const { error, loading, account } = data
      return {
        error,
        loading,
        account
      }
    }
  }),
  graphql(SCHOOL_BILLING_INVOICE_UPCOMING_QUERY, {
    skip: props => props.hasOwnProperty("numInvoices"),
    props: ({ data }) => ({
      invoice: get(data, "school.upcoming_school_billing_invoice"),
      invoiceLoading: data.loading
    })
  }),
  graphql(SCHOOL_BILLING_INVOICES_QUERY, {
    skip: props => props.hasOwnProperty("numInvoices"),
    props: ({ data }) => ({
      numInvoices: get(data, "school.school_billing_invoices.total", 0)
    })
  })
)(ManageBilling)
