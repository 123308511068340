import React from "react"
import "./SubscriptionInfo.css"
import pluralize from "pluralize"

class SubscriptionInfo extends React.Component {
  getTerm = interval => {
    if (interval < 10) {
      return "monthly"
    } else if (interval === 10) {
      return "yearly"
    } else if (interval === 20) {
      return "lifetime"
    }
  }

  getBillingDate = () => {
    const format = (() => {
      switch (this.getTerm(this.props.plan.interval)) {
        case "monthly":
          return "Do"
        case "yearly":
          return "Do [of] MMMM"
      }
    })()
    return moment(this.props.subscription.cycle_start_dtime).format(format)
  }

  getDay = date => {
    return moment(date).format("Do")
  }

  getStatus = () => {
    if (this.props.is_lifetime) {
      return "lifetime"
    }
    if (
      this.props.is_trial &&
      moment(this.props.subscription.trial_end_dtime) > moment()
    ) {
      if (this.props.subscription.trial_type === 10) {
        return "trialcard"
      } else {
        return "trial"
      }
    }
    if (this.props.is_paused) {
      return "paused"
    }
    if (
      this.props.is_canceled ||
      this.props.subscription.is_canceled_at_cycle_end
    ) {
      return "canceled"
    }
    if (this.props.is_expired) {
      return "expired"
    }
    if (this.props.is_active) {
      return "active"
    }
  }

  renderLifetimeMessage = () => {
    return <p>You have a lifetime subscription to {App.school.get("name")}.</p>
  }

  renderActiveMessage = () => {
    return (
      <p>
        {`\
You\'re subscribed for `}
        {this.props.school_plan.currency_display}/
        {this.getTerm(this.props.plan.interval)}
        {`.
Your card ending in `}
        {this.props.subscription.card_last_4} will be billed on the{" "}
        {this.getBillingDate()} {this.getTerm(this.props.plan.interval)}
        {`.\
`}
      </p>
    )
  }

  renderCanceledMessage = () => {
    return (
      <p>
        {`\
Your subscription has been cancelled,
but you\'ll have access to all content until `}
        {moment(this.props.subscription.cycle_end_dtime).format("MMMM Do YYYY")}
        {`.\
`}
      </p>
    )
  }

  renderPausedMessage = () => {
    return (
      <p>
        {`\
Your subscription to `}
        {App.school.get("name")} is paused until{" "}
        {moment(this.props.subscription.paused_until_dtime).format(
          "MMMM Do YYYY"
        )}
        {`.\
`}
      </p>
    )
  }

  renderTrialCardMessage = () => {
    if (
      !(
        this.props.is_canceled ||
        this.props.subscription.is_canceled_at_cycle_end
      )
    ) {
      return (
        <p>
          {`\
You have free access until `}
          {moment(this.props.subscription.trial_end_dtime).format(
            "MMMM Do YYYY"
          )}
          {`,
after which your card will be charged `}
          {this.props.school_plan.currency_display}/
          {this.getTerm(this.props.plan.interval)}
          {`.\
`}
        </p>
      )
    } else {
      return (
        <p>
          {`\
Your free access ends on `}
          {moment(this.props.subscription.trial_end_dtime).format(
            "MMMM Do YYYY"
          )}
          {`.\
`}
        </p>
      )
    }
  }

  renderTrialMessage = () => {
    return (
      <p>
        {`\
Your free access ends on `}
        {moment(this.props.subscription.trial_end_dtime).format("MMMM Do YYYY")}
        {`.
Activate your subscription now to keep full access to `}
        {App.school.get("name")}
        {`.\
`}
      </p>
    )
  }

  renderNone = () => {
    const plan = this.props.starting_plan
    return (
      <p>
        You are not currently subscribed. Subscribe to access{" "}
        {pluralize("resource", plan.resources_count, true)} for as little as{" "}
        {plan.currency_display}/{this.getTerm(plan.plan.interval)}.
      </p>
    )
  }

  render() {
    return (
      <div className="SubscriptionInfo">
        {this.props.subscription != null ? (
          <div className="SubscriptionInfo__status">
            <img src={this.props.school_plan.image} />
            <h4>{this.props.school_plan.name}</h4>
            {(() => {
              switch (this.getStatus()) {
                case "lifetime":
                  return this.renderLifetimeMessage()
                case "active":
                  return this.renderActiveMessage()
                case "canceled":
                  return this.renderCanceledMessage()
                case "paused":
                  return this.renderPausedMessage()
                case "trialcard":
                  return this.renderTrialCardMessage()
                case "trial":
                  return this.renderTrialMessage()
              }
            })()}
          </div>
        ) : (
          this.renderNone()
        )}
      </div>
    )
  }
}

export default SubscriptionInfo
