import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ShareDropdown from "../shared/ShareDropdown"
import { useSelectPeopleContext } from "./SelectPeopleContext"
import SelectRoleDescriptorsDrawerOption from "./SelectRoleDescriptorsDrawerOption"
import { getRoleLabel } from "./utils"

const SelectItemRole = ({ item }) => {
  const {
    rolePermissions,
    peopleSelection: { queued },
    roleSelection: { selected, pending },
    addToPeopleSelection,
    updateRoleSelection
  } = useSelectPeopleContext()
  const { t, tca } = useTranslate()

  const roleOptions = Object.keys(rolePermissions).reduce(
    (acc, key) => [
      ...acc,
      {
        label: getRoleLabel("verb", key, tca),
        value: key
      }
    ],
    []
  )

  const role = queued.length ? pending[item.meta.key] : selected[item.meta.key]
  const value = roleOptions.find(option => option.value === role)

  return (
    <SelectRoleDescriptorsDrawerOption>
      {({ option, onOpen }) => {
        const handleChange = menuItem => {
          if (menuItem.value) {
            // Automatically add this item to the people selection as a convenience.
            addToPeopleSelection(item)
            updateRoleSelection(item, menuItem.value)
          } else {
            onOpen()
          }
        }

        return (
          <ShareDropdown
            name="role"
            value={value}
            options={[...roleOptions, option]}
            onChange={handleChange}
          />
        )
      }}
    </SelectRoleDescriptorsDrawerOption>
  )
}

export default SelectItemRole
