import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { getSchoolUrl } from "../../utils/urls"
import {
  PAGE_CONTENT_TYPE_BLOCKS,
  PAGE_CONTENT_TYPE_LINK,
  PAGE_CONTENT_TYPE_TEMPLATE,
  PAGE_TYPE_GENERIC
} from "../constants"
import CREATE_PAGE_MUTATION from "../graphql/create-page-mutation"
import PAGE_QUERY from "../graphql/page-query"
import UPDATE_PAGE_MUTATION from "../graphql/update-page-mutation"
const { isRequired } = validators

const isSlug = (value, values) => {
  if (!/^[a-zA-Z0-9-]+$/.test(value)) {
    return "Invalid slug. Must contain only alphanumeric characters and hyphens."
  }
}

const UpdatePageForm = ({ initialValue, onSubmit, school }) => (
  <Form
    initialValue={initialValue}
    onSubmit={formValues =>
      onSubmit({
        ...formValues,
        id: initialValue.id
      })
    }
  >
    <CardBlock>
      <Field
        name="is_live"
        label="Set this page live"
        type="custom"
        // helperText=""
        component={ToggleInput}
        alignRight
        labelWidth={275}
      />
    </CardBlock>
    <CardBlock>
      <Field
        name="name"
        label="Name"
        type="text"
        placeholder="About"
        helperText="The name of the link to this page."
        component={TextInput}
        validators={[isRequired]}
        labelWidth={130}
      />
    </CardBlock>
    <CardBlock>
      <Field
        name="title"
        label="Title"
        type="text"
        placeholder={`Learn more about ${school.name}`}
        helperText="The page <title>."
        component={TextInput}
        labelWidth={130}
      />
    </CardBlock>
    <CardBlock>
      <Field
        name="description"
        label="Description"
        type="textarea"
        placeholder={`${school.name} seeks to...`}
        helperText="The page <meta name='description'>."
        component={TextArea}
        labelWidth={130}
      />
    </CardBlock>
    <CardBlock>
      <Field
        name="slug"
        label="Slug"
        type="text"
        placeholder="about"
        helperText={
          <span>
            The slug used to generate the internal link to this page (i.e.{" "}
            {getSchoolUrl("p/")}
            <strong>{initialValue.slug || "about"}</strong>/
          </span>
        }
        component={TextInput}
        labelWidth={130}
        validators={[isRequired, isSlug]}
      />
    </CardBlock>
    {/* <CardBlock>
      <Field
        name="page_type"
        label="Page Type"
        type="custom"
        component={SelectInput}
        // School must have a terms of use page.
        disabled={initialValue && initialValue.page_type == PAGE_TYPE_TERMS}
        options={[
          {
            label: PAGE_TYPES[PAGE_TYPE_GENERIC],
            value: PAGE_TYPE_GENERIC,
          },
          {
            label: PAGE_TYPES[PAGE_TYPE_HOME],
            value: PAGE_TYPE_HOME,
          },
          {
            label: PAGE_TYPES[PAGE_TYPE_TERMS],
            value: PAGE_TYPE_TERMS,
          },
        ]}
        labelWidth={130}
      />
    </CardBlock> */}
    {/* {!initialValue.external_url && (
      <CardBlock>
        <Field
          name="mode"
          label="Mode"
          type="custom"
          component={SelectInput}
          helperText="How should this page be displayed?"
          optionRenderer={({ label, description }) => (
            <div>
              <strong>{label}:</strong> {description}
            </div>
          )}
          options={Object.keys(PAGE_MODE_MAP).map(k => {
            const { label, description } = PAGE_MODE_MAP[k]
            return {
              value: k,
              description,
              label,
            }
          })}
          labelWidth={130}
        />
      </CardBlock>
    )} */}
    {/* <CardBlock>
      <Field
        name="show_in_nav"
        label="Display link"
        type="custom"
        helperText="Display this link in the menu?"
        component={ToggleInput}
        labelWidth={130}
      />
    </CardBlock> */}
    {initialValue.content_type === PAGE_CONTENT_TYPE_LINK ? (
      <CardLink
        icon="link"
        label="Edit link"
        meta={initialValue.external_url || initialValue.iframe_url}
        to={`${window.location.pathname}content/`}
      />
    ) : initialValue.content_type === PAGE_CONTENT_TYPE_BLOCKS ? (
      <CardLink
        icon="builder"
        label="Edit your page with Blocks"
        meta="Use blocks to build a custom page."
        to={`${window.location.pathname}content/`}
      />
    ) : initialValue.content_type === PAGE_CONTENT_TYPE_TEMPLATE ? (
      <CardLink
        icon="code-square"
        label="Edit your page using HTML"
        meta="Use html to build a custom page."
        to={`${window.location.pathname}content/`}
      />
    ) : (
      <CardLink
        icon="gear"
        label="Manage page content"
        meta="Link to an existing page or build your own."
        to={`${window.location.pathname}content/`}
      />
    )}
    {/* <CardLink
      icon="code-square"
      label="Scripts"
      meta="Add any scripts to be run on page load."
      to={`${window.location.pathname}advanced/`}
    /> */}
    <Submit styleType="primary" component={SubmitButton}>
      Save
    </Submit>
  </Form>
)

const CreatePageForm = ({
  initialValue,
  onSubmit,
  onSubmitSuccess,
  pageType
}) => (
  <Form
    initialValue={initialValue}
    onSubmit={formValues =>
      onSubmit({
        ...formValues,
        page_type: pageType ? pageType : PAGE_TYPE_GENERIC
      })
    }
    onSubmitSuccess={({ data }) =>
      onSubmitSuccess && onSubmitSuccess(data.createPage)
    }
  >
    <CardBlock>
      <Field
        name="name"
        label="Name"
        type="text"
        placeholder="About"
        helperText="The name of the link to this page."
        component={TextInput}
        validators={[isRequired]}
        labelWidth={130}
      />
    </CardBlock>
    <Submit styleType="primary" component={SubmitButton}>
      Create
    </Submit>
  </Form>
)

const ManagePage = ({
  card,
  initialValue,
  create,
  update,
  onSubmitSuccess,
  pageType,
  loading,
  error
}) => {
  const { school } = usePathwrightContext()
  return (
    <Card card={card} title={`${get(initialValue, "name") || "New"} Page`}>
      {loading ? (
        <LoadingCircle />
      ) : error ? (
        <Alert error={error.message} />
      ) : initialValue ? (
        <UpdatePageForm
          initialValue={initialValue}
          onSubmit={update}
          school={school}
        />
      ) : (
        <CreatePageForm
          initialValue={initialValue}
          onSubmit={create}
          onSubmitSuccess={onSubmitSuccess}
          pageType={pageType}
        />
      )}
    </Card>
  )
}

ManagePage.displayName = "ManagePage"

ManagePage.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  // field values
  initialValue: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    show_in_nav: PropTypes.bool,
    page_type: PropTypes.number,
    mode: PropTypes.string,
    external_url: PropTypes.string,
    iframe_url: PropTypes.string,
    template_content: PropTypes.string,
    content_type: PropTypes.number
  })
}

export default compose(
  graphql(PAGE_QUERY, {
    options: ({ id, pageType }) => ({
      variables: {
        id,
        page_type: pageType
      }
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      initialValue: get(data, "school.page")
    })
  }),
  graphql(CREATE_PAGE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      create: changes =>
        mutate({
          variables: changes,
          refetchQueries: ["PageQuery"]
        })
    })
  }),
  graphql(UPDATE_PAGE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      update: changes =>
        mutate({
          variables: {
            ...ownProps.initialValue,
            ...changes,
            page_type: get(changes, "page_type.value")
              ? changes.page_type.value
              : changes.page_type,
            mode: get(changes, "mode.value")
              ? changes.mode.value
              : changes.mode,
            refetchQueries: ["PageQuery"]
          }
        })
    })
  })
)(ManagePage)
