import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ManageGroups from "@pathwright/web/src/modules/group/manage/ManageGroups"
import { ConnectStores } from "lib/core/store"
import path from "path-browserify"
import pluralize from "pluralize"
import "./GroupList.css"

const ResourceGroupListCard = function(props) {
  const { card, completeAction, resource, tc } = props

  return (
    <Card
      completeAction={completeAction}
      card={card}
      title={tc("resource.cards.cohorts.title")}
    >
      {resource && resource.id ? (
        <ManageGroups
          resourceId={resource.id}
          parentGroupIdFilter={{ eq: null }}
        >
          {groups =>
            groups.map(group => (
              <CardLink
                preview={`${group.seats_filled} ${pluralize(
                  "member",
                  group.seats_filled
                )}`}
                to={path.join(window.location.pathname, `/${group.id}/`)}
                label={group.name}
                key={group.id}
              />
            ))
          }
        </ManageGroups>
      ) : (
        <LoadingCircle />
      )}
    </Card>
  )
}

ResourceGroupListCard.displayName = "ResourceGroupListCard"

const mapStateToProps = function(state, ownProps) {
  let completeAction
  const { school, permissions } = state.schoolStore.state
  const { location } = state.navigationStore.state
  let { resource } = state.resourceAdminStore.state
  const curriculum_offering = state.resourceAdminStore.request.getCurriculumOffering()

  const baseURL = location.pathname
  // const resourceLicensedFromBelltower =
  //   resource && resource.licensed_from_school === BELLTOWER_SCHOOL_ID

  const isLicensed = Boolean(
    resource?.licensed_from_school &&
      resource?.licensed_from_school !== school.id
  )

  const hasCreatePermission = Boolean(
    permissions.user_is_school_admin ||
      permissions.user_is_school_editor ||
      (curriculum_offering && curriculum_offering.permissions.can_edit)
  )

  if (hasCreatePermission) {
    if (isLicensed) {
      completeAction = {
        to: `/library/store/${resource?.licensed_from_school}/${resource?.licensed_from_resource}/`,
        children: ownProps.t("resource.cards.cohorts.action")
      }
    } else {
      completeAction = {
        to: path.join(baseURL, "/new/"),
        children: ownProps.t("resource.cards.cohorts.action")
      }
    }
  } else {
    completeAction = null
  }

  const props = _.assign(
    {},
    {
      completeAction,
      resource
    },
    ownProps
  )

  return props
}

const connectStores = [
  { store: "resourceAdmin", watch: ["offerings", "offerings_loading"] },
  { store: "navigation", watch: ["location"] },
  { store: "school", watch: false }
]

export default withTranslate(
  ConnectStores(ResourceGroupListCard, connectStores, {}, mapStateToProps)
)
