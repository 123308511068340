import { MutationResult, MutationTuple } from "@apollo/client"
import { ServerBlockTypeType, getBlockTypeKey } from "@pathwright/blocks-core"
import debounce from "lodash/debounce"
import PropTypes from "prop-types"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import { getBlockType, getStyles } from "../block/utils"
import { useBlocksConfig } from "../config/BlocksConfigProvider"
import { BLOCKS_MODE } from "../config/constants"
import * as editorActionTypes from "../editor/constants"
import BlocksApolloProvider from "../graphql/BlocksApolloProvider"
import {
  useBlocksContentOnly,
  useBlocksContext,
  useBlocksMutations,
  useDiscardDraft,
  usePublish
} from "../graphql/hooks"
import { ReducerActionType } from "../syncer/useSyncedReducer"
import {
  BLOCKS_MODE_TYPE,
  BlockType,
  BlocksContentType,
  BlocksContextType
} from "../types"
import { scopeContentData, scopeUserData } from "../utils/scope"
import * as viewerActionTypes from "../viewer/constants"
import SyncerContext from "./SyncerContext"
import { BatchBlockType, BlockBatchType, initBatch } from "./batch"

type GraphQLSyncerPropsType = {
  children: JSX.Element | JSX.Element[]
  contextKey: string
  offline?: boolean
  onSave?: ({ content }: { content: BlocksContentType }) => void
  template?: string
  userID?: string | number
  mode?: BLOCKS_MODE_TYPE
  initialBlocks?: {
    blocksContext: BlocksContextType
    content: BlocksContentType
  }
  onDataLoaded?: ({
    content,
    blocksContext
  }: {
    content: BlocksContentType
    blocksContext: BlocksContextType
  }) => void
  changeSaveInterval?: number
  onReload?: () => void
  renderLoading: () => JSX.Element
  renderError: (error: any, onReload?: any) => JSX.Element
}

// Handles syncing of blocks to the GQL server
export const GraphQLSyncer = ({
  children,
  ...props
}: GraphQLSyncerPropsType): JSX.Element => {
  const blocksContext: BlocksContextType = useGraphQLBlocksContext()
  const blockTypes: ServerBlockTypeType[] = blocksContext.blockTypes

  const saveBatchRef = useRef<BlockBatchType>({})

  const {
    contextKey,
    offline,
    onSave,
    template,
    userID,
    mode,
    initialBlocks,
    onDataLoaded,
    changeSaveInterval,
    renderLoading,
    renderError
  } = props

  const {
    loading: contentLoading,
    error: blocksGQLError,
    data
  }: {
    loading?: boolean
    error?: any | undefined
    data?: {
      content: BlocksContentType
    }
  } = useBlocksContentOnly({
    variables: {
      mode,
      contextKey,
      template,
      userID,
      upsert: mode === BLOCKS_MODE.EDIT,
      draft: mode === BLOCKS_MODE.EDIT
    },
    skip: offline === true || !!initialBlocks?.content,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  })

  const serverContent = initialBlocks?.content || data?.content || null

  // Set initial blocks content immediately for smoother UX.
  const [content, setContent]: [BlocksContentType | null, any] =
    useState<BlocksContentType | null>(serverContent)

  const contentID = serverContent?.id

  useEffect(() => {
    // Fire an event with all blocks data here for anybody up on high that might want to do stuff
    // with it
    if (!onDataLoaded) return

    if (data && !contentLoading && !blocksGQLError) {
      onDataLoaded({ content: data.content, blocksContext })
    }
  }, [contentLoading])

  useEffect(() => {
    // Don't set state content from server content if we have pending batch items
    if (saveBatchRef.current && Object.keys(saveBatchRef.current).length) return

    if (!serverContent) return

    setContent(serverContent)
    // This 2nd dependency check below ensures that when the save batch has been emptied,
    // there will be a final state content update from the server content
  }, [serverContent, Object.keys(saveBatchRef.current || {}).length])

  useEffect(() => {
    if (!content || !onSave) return

    onSave({ content })
  }, [content])

  const [discardDraft]: MutationTuple<any, any> = useDiscardDraft({
    variables: { contentID, contextKey }
  })

  const [publish]: MutationTuple<any, any> = usePublish({
    variables: { id: contentID, contextKey }
  })

  const blocksMutations: Record<any, MutationTuple<any, any> | any> =
    useBlocksMutations({
      contentID: contentID!,
      blockTypes
    })

  const [moveBlock]: MutationTuple<any, any> = blocksMutations.moveBlock
  const [deleteBlock]: MutationTuple<any, any> = blocksMutations.deleteBlock
  const [pasteBlock]: MutationTuple<any, any> = blocksMutations.pasteBlock

  useEffect(() => {
    const batch: BlockBatchType = initBatch()
    saveBatchRef.current = batch
  }, [])

  const mergeBatchBlock = (block: BlockType): BlockType => {
    const { id } = block

    return saveBatchRef.current[id]
      ? {
          ...block,
          ...saveBatchRef.current[id]
        }
      : block
  }

  // Receives the latest state and reducer action, after the reducer has run the action locally.
  // Like a reducer but doesn't return a new state
  // Syncs to the server and then updates local state.
  const syncToGraphQL = async (
    action: ReducerActionType,
    state: Record<string, any>,
    dispatch: (action: ReducerActionType) => Record<string, any>
  ) => {
    const { type, payload }: ReducerActionType = action

    dispatch({ type: editorActionTypes.START_SYNCING })

    switch (type) {
      case editorActionTypes.ADD_BLOCK:
        try {
          const result = await handleAddBlock({
            ...payload
          })

          dispatch({
            type: editorActionTypes.SYNCED_ADD_BLOCK,
            payload: result!.data.block
          })
        } catch (error) {
          console.error(error)
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case editorActionTypes.PASTE_BLOCK:
        try {
          const { copiedBlockClip } = state
          const { index } = payload

          // Nothing to see here
          if (!copiedBlockClip) break

          const [sourceContentID, sourceBlockID] =
            copiedBlockClip.object_id.split(":")

          const result = await handlePasteBlock({
            sourceContentID,
            sourceBlockID,
            order: index + 1
          })

          dispatch({
            type: editorActionTypes.SYNCED_PASTE_BLOCK,
            payload: { copiedBlock: result.data.pasteBlock }
          })
        } catch (error) {
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case editorActionTypes.UPDATE_BLOCK_DATA:
        try {
          const block = state.blocks.find((b: BlockType) => b.id === payload.id)

          handleUpdateBlock(
            {
              ...block,
              data: {
                ...block.data,
                ...payload.block.data
              },
              style: getStyles(block)
            },
            (result: MutationResult) => {
              dispatch({
                type: editorActionTypes.SYNCED_UPDATE_BLOCK,
                payload: mergeBatchBlock(result.data.block)
              })
            }
          )
        } catch (error) {
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case editorActionTypes.UPDATE_BLOCK_LAYOUT:
        try {
          const block = state.blocks.find((b: BlockType) => b.id === payload.id)

          handleUpdateBlock(
            {
              type: block.type,
              id: block.id,
              data: block.data,
              style: getStyles(block),
              layout: payload.layout,
              order: block.order
            },
            (result: MutationResult) => {
              dispatch({
                type: editorActionTypes.SYNCED_UPDATE_BLOCK,
                payload: mergeBatchBlock(result.data.block)
              })
            }
          )
        } catch (error) {
          console.error(error)
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case editorActionTypes.UPDATE_BLOCK_STYLE:
        try {
          const block = state.blocks.find((b) => b.id === payload.id)

          delete block.style._typename

          handleUpdateBlock(
            {
              type: block.type,
              id: block.id,
              data: block.data,
              style: block.style,
              layout: block.layout,
              order: block.order
            },
            (result: MutationResult) => {
              dispatch({
                type: editorActionTypes.SYNCED_UPDATE_BLOCK,
                payload: mergeBatchBlock(result.data.block)
              })
            }
          )
        } catch (error) {
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case editorActionTypes.MOVE_BLOCK_UP:
      case editorActionTypes.MOVE_BLOCK_DOWN:
        try {
          const block = state.blocks.find((b) => b.id === payload.id)
          if (!block) return

          const result = await handleMoveBlock({
            id: block.id,
            order: block.order
          })
          dispatch({
            type: editorActionTypes.SYNCED_BLOCKS,
            payload: result.data.content
          })
        } catch (error) {
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case editorActionTypes.DELETE_BLOCK:
        // This means there's a pending delete
        if (state.deleteBlockID) return

        // Make sure the block exists in the server content
        const block = content!.blocks.find((b) => b.id === payload.id)
        if (!block) return

        try {
          const result = await handleDeleteBlock({ id: payload.id })

          dispatch({
            type: editorActionTypes.SYNCED_BLOCKS,
            payload: result.data.content
          })
        } catch (error) {
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case editorActionTypes.START_PUBLISH:
        try {
          // Flush any debounced background saves before publishing.
          await backgroundSave.flush()
          const result = await publish()
          dispatch({
            type: editorActionTypes.SYNCED_PUBLISH,
            payload: result.data.content
          })
        } catch (error) {
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case editorActionTypes.START_DISCARD_DRAFT:
        try {
          const result = await discardDraft()
          dispatch({
            type: editorActionTypes.SYNCED_DISCARD_DRAFT,
            payload: result.data.content
          })
        } catch (error) {
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      case viewerActionTypes.UPDATE_USER_BLOCK:
        try {
          const block = state.blocks.find((b) => b.id === payload.id)

          if (!block) {
            console.error(
              "Can't save user block — no block found for id: ",
              payload.id
            )
            return
          }

          handleUpdateUserBlock(
            {
              ...block,
              data: {
                ...block.data,
                ...payload.data
              },
              userID: payload.userID,
              draft: payload.draft,
              trackProgress: payload.trackProgress
            },
            (result: MutationResult) => {
              if (result?.error) {
                console.error(result.error)
                return
              }

              if (result?.data) {
                dispatch({
                  type: viewerActionTypes.SYNCED_USER_BLOCK,
                  payload: mergeBatchBlock(result.data.block)
                })
              } else {
                console.warn("No data returned from viewerActionTypes.UPDATE_USER_BLOCK block mutation", payload)
              }
            }
          )
        } catch (error) {
          dispatch({ type: editorActionTypes.ERROR, payload: error })
          // could also re-dispatch the action for a "retry" on network error
          // dispatch(action)
        }
        break
      default:
        // GQL doesn't have to handle all local actions
        // console.log("Unknown action type in GQL Syncer: ", action)
        dispatch({ type: editorActionTypes.STOP_SYNCING })
        return state
    }
  }

  // const checkForErrors = result => {
  //   if (!result || result?.data) {
  //     return true
  //   }

  //   if (result.graphQLErrors) {
  //     const errorMessage = result.graphQLErrors.length
  //       ? result.graphQLErrors[0].message
  //       : "Oops, it looks like we ran into a problem!"

  //     return true
  //   }

  //   return false
  // }

  const handleMoveBlock = async ({ id, order }) => {
    if (saveBatchRef.current[id]) {
      saveBatchRef.current[id]!.order = order
    }

    return await moveBlock({
      variables: {
        contentID,
        contextKey,
        id,
        order
      }
    })
  }

  const handleDeleteBlock = async ({ id }) => {
    // remove block from saveBatch
    delete saveBatchRef.current[id]

    return await deleteBlock({
      variables: {
        contentID,
        contextKey,
        id
      }
    })
  }

  const handlePasteBlock = async ({
    sourceContentID,
    sourceBlockID,
    order
  }) => {
    return await pasteBlock({
      variables: {
        contextKey,
        sourceContentID,
        sourceBlockID,
        destinationContentID: content!.id,
        order
      }
    })
  }

  const handleAddBlock = async ({
    id,
    type,
    data,
    layout,
    order = undefined,
    style = undefined
  }) => {
    const newBlock = {
      id,
      type,
      data,
      layout,
      order,
      style,
      draft: true
    }

    const result = await _handleSaveBlock({
      ...newBlock
    })
    return result
  }

  const _handleSaveBlock = async ({
    type = "",
    id,
    data = {},
    layout,
    order = undefined,
    style = undefined,
    draft,
    userID = "",
    trackProgress = false,
    scope = undefined
  }: BatchBlockType) => {
    const isUser = scope === "user"
    data = isUser
      ? scopeUserData(data, getBlockType(type, blockTypes))
      : scopeContentData(data, getBlockType(type, blockTypes))

    const typeKey = getBlockTypeKey(type)
    const mutation = isUser
      ? `save${typeKey}BlockUserData`
      : `save${typeKey}Block`

    let variables: {
      contentID: string
      contextKey: string
      id: string
      data: any
      layout?: string
      order?: number
      style?: Record<string, any>
      userID?: string
      draft?: boolean
      noProgress?: boolean
    } = {
      contentID: content!.id,
      contextKey,
      id,
      data
    }

    if (isUser) {
      variables.noProgress = !trackProgress
      variables.draft = draft
    }

    if (layout) variables.layout = layout
    if (order) variables.order = order
    if (style) variables.style = style
    if (userID) variables.userID = userID

    const [mutate] = blocksMutations[mutation]

    if (!offline) {
      const result: MutationResult = await mutate({
        variables
      }).catch((e) => {
        console.error(e)
      })

      // checkForErrors(result)

      return result
    } else {
      console.log("offline mutation attempted: ", mutation, variables)
    }
  }

  // Apply block updates that are waiting in the saveBatch
  const backgroundSave = useCallback(
    debounce(async (callback) => {
      // Accumulate the list of bg save promises.
      const bgSavePromises = Object.keys(saveBatchRef.current).reduce(
        (acc, key) => {
          const batchBlock = saveBatchRef.current[key]
          delete saveBatchRef.current[key]
          // Block could have been removed in a previous sync
          if (batchBlock) {
            // MUTATION
            acc.push(_handleSaveBlock({ ...batchBlock }))
          }
          return acc
        },
        [] as Promise<any>[]
      )

      // For each bg save promise, supply the callback with the result.
      if (callback) {
        bgSavePromises.forEach(async (bgSavePromise) => {
          callback(await bgSavePromise)
        })
      }

      // Finally, wait for all bg save promises to complete.
      return Promise.all(bgSavePromises)
    }, changeSaveInterval),
    [content?.blocks, saveBatchRef.current]
  )

  const handleUpdateBlock = async (
    { type, id, data, layout, order = null, style },
    callback: ((result: MutationResult) => void) | null = null
  ) => {
    const batchBlock: BatchBlockType = {
      type,
      id,
      data,
      layout,
      draft: true
    }

    if (order) batchBlock.order = order
    if (style) batchBlock.style = style

    const key = id
    saveBatchRef.current[key] = batchBlock

    backgroundSave(callback)
  }

  const handleUpdateUserBlock = (
    { type, id, data, userID, layout, trackProgress = false, draft = true },
    callback: ((result: MutationResult) => void) | null = null
  ) => {
    const batchBlock = {
      type,
      id,
      data,
      layout,
      userID,
      trackProgress,
      draft
    }

    const key = id
    saveBatchRef.current[key] = { ...batchBlock, scope: "user" }

    backgroundSave(callback)
  }

  if (blocksGQLError && renderError) {
    return renderError(blocksGQLError)
  }

  if (((contentLoading && !content) || !content) && renderLoading) {
    return renderLoading()
  }

  return (
    <SyncerContext.Provider
      value={{
        content,
        contentLoading,
        blocksContext,
        blockTypes,
        syncer: syncToGraphQL
      }}
    >
      {children}
    </SyncerContext.Provider>
  )
}

GraphQLSyncer.propTypes = {
  contextKey: PropTypes.string.isRequired,
  offline: PropTypes.bool,
  onSave: PropTypes.func,
  template: PropTypes.string,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.string,
  initialBlocks: PropTypes.shape({
    blocksContext: PropTypes.object,
    content: PropTypes.object
  }),
  onDataLoaded: PropTypes.func,
  changeSaveInterval: PropTypes.number,
  onReload: PropTypes.func,
  renderLoading: PropTypes.func,
  renderError: PropTypes.func
}

GraphQLSyncer.defaultProps = {
  renderLoading: () => null,
  renderError: (error: any, onReload?: () => void) => null
}

export const GraphQLBlocksContext = createContext<BlocksContextType>({
  accountID: "3",
  mediaStoragePath: "",
  userID: "1",
  blockTypes: []
})

export const useGraphQLBlocksContext = (): BlocksContextType =>
  useContext(GraphQLBlocksContext)

// Provides static context server data need for blocks (ex: types)
export const GraphQLBlocksContextProvider = ({ children, ...props }) => {
  const { onReload, initialBlocks } = props

  // const { t } = useTranslate()

  const {
    loading,
    error,
    data = {}
  } = useBlocksContext({
    skip: !!initialBlocks?.blocksContext
  })

  const blocksContext = initialBlocks?.blocksContext || data?.blocksContext

  // if (loading) return <DefaultLoadingWithTimeout />
  if (loading) return props.renderLoading()

  if (error) {
    return props.renderError(error.message || error, onReload)
    // return <ErrorOverlay error={error.message || error} onReload={onReload} />
  }

  if (!blocksContext || !blocksContext.blockTypes) {
    return props.renderError("404: can't find Blocks")
    // return <ErrorOverlay error={t("blocks.errors.404")} />
  }

  return (
    <GraphQLBlocksContext.Provider value={blocksContext}>
      {children}
    </GraphQLBlocksContext.Provider>
  )
}

GraphQLBlocksContextProvider.propTypes = {
  onReload: PropTypes.func,
  renderLoading: PropTypes.func,
  renderError: PropTypes.func
}

GraphQLBlocksContextProvider.defaultProps = {
  renderLoading: () => null,
  renderError: (error, onReload) => null
}

const GraphQLSyncerContainer = ({
  children,
  graphQLEndpoint,
  accountID,
  renderLoading
}: {
  children: JSX.Element
  graphQLEndpoint?: string
  accountID?: string
  renderLoading?: () => JSX.Element
}): JSX.Element => {
  const {
    contextKey,
    offline,
    onSave,
    template = undefined,
    userID = undefined,
    mode,
    initialBlocks,
    onDataLoaded,
    changeSaveInterval,
    onReload
  } = useBlocksConfig()

  if (initialBlocks) {
    return (
      <GraphQLBlocksContextProvider
        onReload={onReload}
        initialBlocks={initialBlocks}
        renderLoading={renderLoading}
      >
        <GraphQLSyncer
          contextKey={contextKey}
          offline={offline}
          onSave={onSave}
          template={template}
          userID={userID}
          mode={mode}
          onDataLoaded={onDataLoaded}
          changeSaveInterval={changeSaveInterval}
          initialBlocks={initialBlocks}
          renderLoading={renderLoading}
        >
          {children}
        </GraphQLSyncer>
      </GraphQLBlocksContextProvider>
    )
  }

  return (
    <BlocksApolloProvider endpoint={graphQLEndpoint} accountID={accountID}>
      <GraphQLBlocksContextProvider
        onReload={onReload}
        renderLoading={renderLoading}
      >
        <GraphQLSyncer
          contextKey={contextKey}
          offline={offline}
          onSave={onSave}
          template={template}
          userID={userID}
          mode={mode}
          onDataLoaded={onDataLoaded}
          changeSaveInterval={changeSaveInterval}
          renderLoading={renderLoading}
        >
          {children}
        </GraphQLSyncer>
      </GraphQLBlocksContextProvider>
    </BlocksApolloProvider>
  )
}

GraphQLSyncerContainer.propTypes = {
  children: PropTypes.node,
  graphQLEndpoint: PropTypes.string,
  accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default GraphQLSyncerContainer
