import Button from "@pathwright/ui/src/components/button/Button"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { getPlatformUrl } from "../utils/urls"

const SupportPathwright = props => {
  const { t, Trans } = props

  const tPrefix = "footer.support_pathwright"

  return (
    <div style={{ whiteSpace: "pre-wrap" }}>
      <header>
        <Text.H1>{t(`${tPrefix}.title`)}</Text.H1>
      </header>

      <View className="Support__content" borderTop paddingTop paddingBottom>
        <ul>
          <li>
            <Pathicon icon="check-circle" />
            <span>
              {t(`${tPrefix}.read_faqs`)}{" "}
              <span style={{ display: "inline-block" }}>
                <Button
                  brand
                  styleType="text"
                  target="_blank"
                  href="https://help.pathwright.com/"
                >
                  FAQs
                </Button>
              </span>
            </span>
          </li>
          <li>
            <Pathicon icon="check-circle" />
            <span>
              {/* prettier-ignore */}
              {/* Trans formatting integral to translation json! */}
              <Trans
                parent="span"
                i18nKey={`${tPrefix}.take_course`}
                components={{
                  1: (
                    <Button
                      brand
                      styleType="text"
                      target="_blank"
                      href={getPlatformUrl()}
                    />
                  ),
                  2: (
                    <Button
                      brand
                      styleType="text"
                      target="_blank"
                      href={getPlatformUrl(
                        "/library/intro-to-pathwright-20-2/about/"
                      )}
                    />
                  )
                }}
              >
                Take a Course: Visit <button>Pathwright Paths</button> to take
                <span style={{ display: "inline-block" }}>
                  <button>Intro to Pathwright</button>
                </span>{" "}
                and learn how to design and build courses.
              </Trans>
            </span>
          </li>
          {!!window.Intercom && (
            <li>
              <Pathicon icon="check-circle" />
              <span>
                {/* prettier-ignore */}
                {/* Trans formatting integral to translation json! */}
                <Trans parent="span" i18nKey={`${tPrefix}.contact_us`}>
                  Contact us:{" "}
                  <span style={{ display: "inline-block" }}>
                    <Button
                      brand
                      styleType="text"
                      onClick={() => window.Intercom("show")}
                    >
                      Send a message
                    </Button>
                  </span>
                </Trans>
              </span>
            </li>
          )}
        </ul>
      </View>
    </div>
  )
}

export default withTranslate(SupportPathwright)
