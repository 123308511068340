import React from "react"
import PropTypes from "prop-types"
import { ConnectStores } from "lib/core/store"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"

import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Card from "@pathwright/ui/src/components/card/Card"

class GroupArchiveCard extends React.Component {
  state = { deleted: false }

  handleArchive = () => {
    const { group, deleteGroup, preventModalClose, toContinue } = this.props
    const groupName = group.name

    preventModalClose()
    return deleteGroup(group != null ? group.id : undefined).promise.then(
      () => {
        // TODO: remove cohort from CohortSwitcher cache
        // TODO: change these to remove the fragment from cache?
        // teach tab
        window.apolloClientStoreBridge.refetchTeachingResources()
        // design tab
        window.apolloClientStoreBridge.refetchResources({})
        // learn tab
        window.apolloClientStoreBridge.refetchRegistrations({})
        // library
        window.apolloClientStoreBridge.refetchLibraryResources()

        return this.setState({
          deleted: true,
          // After group is deleted, the props.toContinue url may change
          toContinue,
          groupName
        })
      }
    )
  }

  render() {
    const {
      card,
      group,
      offering,
      loading,
      cardProps,
      toContinue,
      continueLabel
    } = this.props
    const { deleted, groupName } = this.state

    return (
      <Card {...cardProps} card={card} hideClose={deleted}>
        {this.props.group ? (
          <BlankSlate
            icons={null}
            heading={this.props.t("cohort.cards.archive.blank_slate.heading")}
            body={this.props.t("cohort.cards.archive.blank_slate.body")}
            primaryAction={{
              children: this.props.t("cohort.cards.archive.blank_slate.button"),
              dangerous: true,
              onClick: this.handleArchive
            }}
            secondaryAction={{
              children: this.props.t("cohort.cards.archive.blank_slate.cancel"),
              onClick: card.onClose
            }}
          />
        ) : loading ? (
          <LoadingCircle />
        ) : deleted ? (
          <BlankSlate
            icons={null}
            heading={`"${groupName}" has been deleted`}
            primaryAction={{
              children: continueLabel,
              to: this.state.toContinue
            }}
          />
        ) : null}
      </Card>
    )
  }
}

GroupArchiveCard.displayName = "GroupArchiveCard"
GroupArchiveCard.contextTypes = {
  me: PropTypes.object.isRequired
}

const mapStateToProps = function(state, ownProps) {
  const {
    group,
    is_loading_group,
    is_deleting_group
  } = state.groupAdminStore.state
  const { resource, offering, offering_loading } = state.resourceStore.state
  const { preventModalClose } = state.navigationStore.action
  const { getHomeLocation } = state.navigationStore.request
  const { modal_launched_from_url } = state.navigationStore.state
  const { deleteGroup } = state.groupAdminStore.action

  const cardProps = {
    title: group ? `Delete ${group.name}` : "",
    showStatus: is_loading_group || is_deleting_group,
    statusMessage: (() => {
      if (is_loading_group) {
        return "Loading..."
      } else if (is_deleting_group) {
        return "Deleting..."
      }
    })()
  }

  const loading = is_loading_group || offering_loading
  let displayType = "Course"
  if (resource) {
    if (resource.resource_type === 30) displayType = "Collection"
    if (resource.resource_type === 20) displayType = "Lesson"
  }

  // TODO: in some cases it may be best to navigate back to /manage/resource/{resource_id}/group/
  // to show all groups (might only be if school has curriculum enabled and that card is actually open)
  let toContinue = null
  let curriculumUrl = null
  try {
    // get curriclum url
    const resourceUrl = state.resourceStore.request.resourceUrl()
    const curriculumOffering = state.resourceStore.request.curriculumOffering()
    curriculumUrl = resourceUrl.replace(/\/\d+\//, `/${curriculumOffering.id}/`)
    toContinue = curriculumUrl
  } catch (error) {
    toContinue = resource ? getHomeLocation() : modal_launched_from_url
  }

  const continueLabel =
    curriculumUrl || resource
      ? `Continue to ${resource ? displayType : "Home"}`
      : "Close"

  return _.assign(
    {},
    {
      // state
      group,
      loading,
      cardProps,
      continueLabel,
      // actions
      deleteGroup,
      preventModalClose,
      // routes
      toContinue
    },
    ownProps
  )
}

const connectStores = [
  { store: "navigation", watch: false },
  { store: "resource", watch: ["offering", "offering_loading"] },
  {
    store: "groupAdmin",
    watch: ["is_loading_group", "is_deleting_group", "group"]
  }
]

export default withTranslate(
  ConnectStores(GroupArchiveCard, connectStores, {}, mapStateToProps)
)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
