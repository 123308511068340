import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import ThemeBackground from "../background/ThemeBackground"
import Button, { buttonPropType } from "../button/Button"
import SelectButton from "../button/SelectButton"
import SubmitButton from "../button/SubmitButton"
import AnimatedIcon from "../pathicon/AnimatedIcon"
import AnimatedIcons from "../pathicon/AnimatedIcons"
import Pathicon from "../pathicon/Pathicon"
import Text from "../ui/Text"
import { PRIMARY_GRAY, PRIMARY_WHITE } from "../utils/colors"
import BlankImage from "./BlankImage"

const StyledContainer = styled.div`
  position: relative;
  text-align: center;
  margin: 0px auto;
  max-width: 500px;
  padding: 40px 20px;

  a:not(.Button) {
    text-decoration: underline;
  }
`

const StyledContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  white-space: initial;

  > *:not(:last-child) {
    margin-bottom: 15px;
  }
`

const StyledIcon = styled(Pathicon)`
  font-size: 24px;
  color: ${props => (props.inverted ? PRIMARY_WHITE : PRIMARY_GRAY)};
`

const StyledActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ${p =>
    p.stack
      ? `
    flex-direction: column;
    align-items: center;

    /* Uniforms the widths of all SubmitButtons */
    .SubmitStatusButton,
    .SubmitStatusButton .Button.primary {
      width: 100%;
    }
    
    > * + * {
      margin-top: 10px;
    }
  `
      : `
    flex-direction: row;
    align-items: baseline;
    
    > * + * {
      margin-left: 10px;
    }
  `}
`

const StyledSecondaryActionContainer = styled.span`
  display: flex;
  white-space: pre;
`

const BlankSlate = ({
  icon,
  icons,
  animateIcon,
  heading,
  body,
  meta,
  footer,
  children,
  image,
  illustration,
  stack,
  theme,
  inverted,
  className,
  primaryAction,
  primaryActions,
  secondaryAction,
  secondaryActions,
  preActionContent
}) => {
  // Assuming BlankSlate will only ever receive a singular or plural
  // of either the primary or secondary options, but not both.
  const actions = {
    primary: [].concat(primaryAction, primaryActions).filter(Boolean),
    secondary: [].concat(secondaryAction, secondaryActions).filter(Boolean)
  }

  return (
    <StyledContainer className={classnames("BlankSlate", className)}>
      {theme && <ThemeBackground rounded />}
      <StyledContent>
        {illustration ? (
          illustration
        ) : image ? (
          <BlankImage image={image} inverted={inverted} />
        ) : icon ? (
          animateIcon ? (
            <AnimatedIcon
              icon={icon}
              iconSize={30}
              circleSize={50}
              inverted={inverted}
            />
          ) : (
            <StyledIcon icon={icon} inverted={inverted} />
          )
        ) : icons ? (
          <AnimatedIcons icons={icons} inverted={inverted} />
        ) : null}
        {heading && <Text.H4 inverted={inverted}>{heading}</Text.H4>}
        {body && <Text.Body inverted={inverted}>{body}</Text.Body>}
        {preActionContent}
        {!!actions.primary.length && (
          <StyledActionContainer stack={stack}>
            {actions.primary.map((primaryAction, index) => (
              <React.Fragment key={index}>
                {primaryAction.options ? (
                  <SelectButton {...primaryAction} />
                ) : (
                  <SubmitButton
                    size="large"
                    styleType="primary"
                    {...primaryAction}
                    target={primaryAction.href ? "_blank" : null}
                    inverted={inverted}
                  />
                )}
              </React.Fragment>
            ))}
            {!!actions.secondary.length &&
              actions.secondary.map((secondaryAction, index) => (
                <StyledSecondaryActionContainer key={index}>
                  {!!secondaryAction.pre && (
                    <React.Fragment>{secondaryAction.pre} </React.Fragment>
                  )}
                  <Button
                    styleType="text"
                    {...secondaryAction}
                    inverted={inverted}
                  />
                </StyledSecondaryActionContainer>
              ))}
          </StyledActionContainer>
        )}
        {meta && <Text.Meta inverted={inverted}>{meta}</Text.Meta>}
        {((!!footer || !!children) && footer) || children}
      </StyledContent>
    </StyledContainer>
  )
}

BlankSlate.defaultProps = {
  stack: false,
  theme: false,
  inverted: false,
  icons: [
    "chat-bubble-plus",
    "avatar-circle",
    "image",
    "avatar-circle",
    "chat-bubble-check",
    "avatar-circle"
  ]
}

BlankSlate.displayName = "BlankSlate"

const primaryActionPropType = PropTypes.shape({
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  styleType: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSubmitFailure: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitProcessing: PropTypes.func,
  // for SelectButton
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node
  ]),
  // for SelectButton
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node
      ]).isRequired,
      action: PropTypes.func.isRequired,
      selected: PropTypes.bool
    })
  )
})

BlankSlate.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  preActionContent: PropTypes.node,
  icon: PropTypes.string,
  icons: PropTypes.array,
  stack: PropTypes.bool,
  theme: PropTypes.bool,
  inverted: PropTypes.bool,
  animateIcon: PropTypes.bool,
  illustration: PropTypes.node,
  primaryAction: primaryActionPropType,
  primaryActions: PropTypes.arrayOf(primaryActionPropType),
  secondaryAction: buttonPropType,
  secondaryActions: PropTypes.arrayOf(buttonPropType)
}

BlankSlate.defaultProps = {
  icons: null
}

export default BlankSlate
