import React from "react"
import moment from "moment"
import styled from "styled-components"
import { isItemReviewable } from "../../utils"
import {
  GRADING_TYPE_AUTOMATIC,
  GRADING_TYPE_MANUAL
} from "../../../path/constants"

const ReviewStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ReviewStatus = ({ item }) => {
  const {
    data: { completion }
  } = item

  const { checked_by, checked_date, grading_type, needs_grading } = completion
  const reviewable = isItemReviewable(item)

  if (!reviewable) return null

  let status = ""
  if (needs_grading) {
    status = "pending review"
  } else if (grading_type === GRADING_TYPE_MANUAL) {
    if (checked_by) {
      status = `reviewed by ${checked_by.display_name}`
    }
  } else if (grading_type === GRADING_TYPE_AUTOMATIC) {
    status = `auto-reviewed`
  }

  let when = checked_date ? moment(checked_date).fromNow() : null

  return (
    <ReviewStatusContainer className="Completion--Review">
      {status}
      {when ? ` ${when}` : ""}
    </ReviewStatusContainer>
  )
}

export default ReviewStatus
