import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { camelCase } from "change-case"
import get from "lodash/get"
import REPORT_QUERY from "./report-query"

const useReportUrls = options => {
  const reportQuery = useQuery(REPORT_QUERY, options)

  // get the report urls object based on report key args;
  // the plural form of the key will be use if multiple reports are found
  const reportUrls = Object.keys(options.variables).reduce((urls, key) => {
    const reportData = get(reportQuery, `data.report.${key}`, [])

    if (reportData.length <= 1) {
      return {
        ...urls,
        [`${camelCase(key)}ReportUrl`]: reportData[0]
      }
    }

    return {
      ...urls,
      [`${camelCase(key)}ReportUrls`]: reportData
    }
  }, {})

  return reportUrls
}

export default useReportUrls
