import React, { Suspense } from "react"

const LazyBlankImage = React.lazy(() =>
  import("./BlankImageExpensive" /* webpackChunkName: "BlankImage" */)
)

const SuspendedLazyBlankImage = props => (
  <Suspense fallback={null}>
    <LazyBlankImage {...props} />
  </Suspense>
)

export default SuspendedLazyBlankImage
