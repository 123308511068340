import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import React from "react"
import styled from "styled-components"

const FeedItemAvatarIconContainer = styled.div`
  position: relative;
  margin-right: 10px;
  display: flex;
  .Pathicon {
    position: absolute;
    top: 0px;
    right: -6px;
    background-color: ${props => props.color};

    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    svg {
      z-index: 1;
      color: #fff;
      width: 10px;
      height: 10px;
    }
  }
`

const FeedItemAvatar = ({ icon, color, initials, image }) => {
  return (
    <FeedItemAvatarIconContainer color={color}>
      <Avatar image={image} initials={initials} size={"38px"} />
      {icon && <Pathicon icon={icon} size={"18px"} />}
    </FeedItemAvatarIconContainer>
  )
}

export default FeedItemAvatar
