import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import OptionsButton from "../button/OptionsButton"
import DropdownItem from "./DropdownItem"
import DropdownMenu from "./DropdownMenu"
import DropdownTrigger from "./DropdownTrigger"

const renderDropdown = (menu = {}, items = []) => (
  <DropdownMenu {...menu}>
    {items.map((item, i) => (
      <DropdownItem key={i} {...item} />
    ))}
  </DropdownMenu>
)

const Dropdown = ({
  menu,
  items,
  action,
  className,
  children,
  ...passProps
}) => {
  return (
    <DropdownTrigger
      className={classnames("Dropdown", className)}
      renderDropdown={() => renderDropdown(menu, items)}
      {...passProps}
    >
      {children || <OptionsButton styleType="secondary" {...action} />}
    </DropdownTrigger>
  )
}

Dropdown.displayName = "Dropdown"

Dropdown.propTypes = {
  menu: DropdownMenu.propTypes,
  items: PropTypes.arrayOf(PropTypes.shape(DropdownItem.propTypes)).isRequired,
  action: OptionsButton.propTypes,
  ...DropdownTrigger.propTypes
}

export default Dropdown
