import { tagPropType } from "@pathwright/ui/src/components/tag/propTypes"
import PropTypes from "prop-types"
import React from "react"
import { tagAttachmentContextPropType } from "../../tag/propTypes"
import TagSelectorWithTagManager from "../../tag/TagSelectorWithTagManager"
import useTagPermissions from "../../tag/useTagPermissions"
import useTags from "../../tag/useTags"
import DiscussionTagManager from "../topics/DiscussionTagManager"
import useDiscussionTagContextLabels from "../topics/useDiscussionTagContextLabels"

const DiscussionFormTags = ({ context, selectedTags, onChange }) => {
  const tagsContext = {
    ...context,
    // We'll dynamically supply the discussion_context_id after the discussion
    // has been created. Passing discussion_context_id in context now, even though
    // null, informs selector UIs of context level.
    discussion_context_id: null
  }

  const tagPermissions = useTagPermissions({ context: tagsContext })
  // NOTE: only pass the selectedTags directly to the TagSelector as selectedTags
  // can contain tags that both exist and do not yet exist. Otherwise, non-existant
  // tags will be filtered out by useTags.
  const { tags } = useTags({
    context: tagsContext,
    // But, for useTags to not bump tags down (all > filtered > selected), we need to
    // supply an empty selectedTags.
    // TODO: determine if this behavior can be replaced by checking context maybe?
    selectedTags: []
  })

  // Only show the TagSelector if user can create tags or
  // if there are tags.filtered available to select.
  if (!tagPermissions.canAdd && !tags.filtered.length) return null

  return (
    <TagSelectorWithTagManager
      context={tagsContext}
      tags={{
        ...tags,
        selected: selectedTags
      }}
      onChange={selectedTags => onChange(selectedTags)}
      useContextLabels={useDiscussionTagContextLabels}
      inverted
      styles={{
        container: {
          minWidth: "200px",
          maxWidth: "275px",
          borderRadius: "100px"
        },
        control: {
          borderRadius: "100px"
        },
        placeholder: {
          fontWeight: "bold"
        }
      }}
      CustomTagManager={DiscussionTagManager}
    />
  )
}

DiscussionFormTags.displayName = "DiscussionFormTags"

DiscussionFormTags.propTypes = {
  context: tagAttachmentContextPropType,
  selectedTags: PropTypes.arrayOf(tagPropType).isRequired,
  onChange: PropTypes.func.isRequired
}

export default DiscussionFormTags
