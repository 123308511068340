import React from "react"
import "./SelectInput.css"
import "react-select/dist/react-select.css"
import classnames from "classnames"
import Select from "react-select"
import FieldWrapper from "../form-utils/FieldWrapper"

// Wraps https://github.com/JedWatson/react-select

const SelectInput = function(props) {
  let { label, value, className, onChange } = props

  className = classnames("SelectInput", className)

  return (
    <FieldWrapper {...Object.assign({}, props, { className: className })}>
      {({ placeholder, onFocus, onBlur }) => {
        return (
          <Select
            {...Object.assign({}, props, {
              name: label,
              value: value,
              placeholder: placeholder,
              onChange: onChange,
              onFocus: onFocus,
              onBlur: onBlur,
            })}
          />
        )
      }}
    </FieldWrapper>
  )
}

SelectInput.displayName = "SelectInput"
SelectInput.defaultProps = {
  searchable: false,
  clearable: false,
  multi: false,
}

export default SelectInput
