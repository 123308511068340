import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import {
  PRIMARY_GRAY,
  WARNING_ORANGE
} from "@pathwright/ui/src/components/utils/colors"
import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import ErrorMessage from "../error/ErrorMessage"

const PasteButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${p => (p.pasting ? "0.55rem 2.95rem" : "0.2rem 1.2rem")};
  border-radius: 100px;
  color: #828282;
  background-color: #efefef;

  .IconButton {
    background-color: transparent;
    color: #828282;
  }

  .IconButton:hover {
    background-color: transparent;
    color: ${PRIMARY_GRAY};
  }

  ${p =>
    p.mode === "dark"
      ? `
    color: #fff;
    background-color: ${PRIMARY_GRAY};

    .IconButton,
    .IconButton:hover {
      color: #fff;
    }
  `
      : ``}
`

const StyledDivider = styled.div`
  display: inline-block;
  height: 100%;
`

const PasteButton = ({
  clip,
  onPaste,
  onCancel,
  style = {},
  styleMode = "light",
  getTooltipLabel,
  tooltipProps = {},
  pasting: propsPasting = false
}) => {
  const { t } = useTranslate()

  const [pasting, setPasting] = useState(propsPasting)
  const [error, setError] = useState(null)

  // Expecting a promise returned from onPaste. Handling the error,
  // if any, and always setting paste to false.
  const handlePaste = async e => {
    e.stopPropagation()
    setPasting(true)
    try {
      await onPaste(clip)
    } catch (error) {
      setError(error)
    } finally {
      setPasting(false)
    }
  }

  useEffect(() => {
    let error = null

    if (clip) {
      // Check for errors
      if (isEmpty(clip) || !clip.object_label || !clip.object_id) {
        error = "The copied clip is not valid."
      } else if (`${clip.object_id}`.split(":")[0] === "undefined") {
        error = "The copied source clip is no longer available."
      }
    } else {
      setPasting(false)
    }

    setError(error)
    if (error) setPasting(false)
  }, [clip])

  const tooltipLabel = React.useMemo(() => {
    if (!clip) return ""

    const defaultTooltip = t(`Paste {{clipLabel}}`, {
      // Just in case a "Paste " has been added to the label
      clipLabel: clip.object_label.replace("Paste ", "")
    })

    return getTooltipLabel ? getTooltipLabel(clip) : defaultTooltip
  }, [clip])

  // Don't show the button if there's nothing to paste
  if (!clip || !clip.object_label) return null

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style
      }}
      className="PasteButton"
      onClick={e => e.stopPropagation()}
    >
      <PasteButtonWrapper
        className="PasteButton__inner-wrapper"
        pasting={pasting || propsPasting}
        mode={styleMode}
      >
        {!pasting && !propsPasting ? (
          <React.Fragment>
            <Tooltip
              {...tooltipProps}
              title={error ? <ErrorMessage error={error} /> : tooltipLabel}
            >
              <IconButton
                onClick={handlePaste}
                icon="paste"
                styleType="primary"
                style={{
                  marginRight: "0.4rem",
                  color: !!error ? WARNING_ORANGE : ""
                }}
                disabled={!!error}
              />
            </Tooltip>
            <StyledDivider>|</StyledDivider>
            <Tooltip title={"Cancel paste"}>
              <IconButton
                onClick={event => {
                  event.stopPropagation()
                  onCancel()
                }}
                icon="x"
                styleType="secondary"
                style={{ marginLeft: "0.4rem" }}
              />
            </Tooltip>
          </React.Fragment>
        ) : (
          <LoadingCircle size={18} center={false} />
        )}
      </PasteButtonWrapper>
    </div>
  )
}

export const ClipShape = PropTypes.shape({
  object_label: PropTypes.string.isRequired,
  object_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  object_type: PropTypes.string.isRequired,
  data: PropTypes.object
})

PasteButton.propTypes = {
  onPaste: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  clip: ClipShape,
  style: PropTypes.object,
  styleMode: PropTypes.oneOf(["light", "dark"]),
  getTooltipLabel: PropTypes.func,
  tooltipProps: PropTypes.object
}

PasteButton.defaultProps = {
  styleMode: "light"
}

export default PasteButton
