import React from "react"
import styled from "styled-components"
import IconButton from "../button/IconButton"
import Portable from "../portable/Portable"
import Fullscreen, { FULLSCREEN_LAYER_OVERLAY } from "./Fullscreen"

const Container = styled.div`
  position: relative;
`

const FullscreenPortalContainer = styled.div`
  height: 100vh;

  > * {
    min-height: 100%;
  }
`

const Toggle = styled(IconButton)`
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
  border-radius: 50px !important;
  z-index: 99999 !important;
`

const iconProps = {
  styleType: "primary",
  color: "black",
  size: 30
}

const PortableFullscreen = ({
  children,
  fullscreenProps,
  inverted,
  showIcons,
  ...props
}) => (
  <Portable renderPortal={children}>
    {({ porting, setPorting, outPortal }) =>
      porting ? (
        <Fullscreen
          background={null}
          layer={FULLSCREEN_LAYER_OVERLAY}
          {...fullscreenProps}
        >
          <FullscreenPortalContainer>{outPortal}</FullscreenPortalContainer>
          {showIcons && (
            <Toggle
              {...iconProps}
              className="Fullscreen__minimize"
              inverted={inverted}
              onClick={() => setPorting(false)}
              icon="minimize"
            />
          )}
        </Fullscreen>
      ) : showIcons ? (
        <Container {...props}>
          {outPortal}
          <Toggle
            {...iconProps}
            className="Fullscreen__maximize"
            inverted={inverted}
            onClick={() => setPorting(true)}
            icon="maximize"
          />
        </Container>
      ) : (
        outPortal
      )
    }
  </Portable>
)

PortableFullscreen.displayName = "PortableFullscreen"

PortableFullscreen.defaultProps = {
  fullscreenProps: {},
  inverted: true,
  showIcons: true,
  enableFullscreen: true
}

export default PortableFullscreen
