import {
  objectifyRgba,
  stringifyRgba
} from "@pathwright/ui/src/components/utils/colors"
import { ConnectStores } from "lib/core/store"
import ResourceThemeForm from "./ResourceThemeForm"

const mapStateToProps = function(state, ownProps) {
  const { resource } = state.resourceAdminStore.state
  const { saveResource } = state.resourceAdminStore.action

  const fieldValues = {
    background: {
      url: resource != null ? resource.background_image : undefined,
      image: resource != null ? resource.background_image : undefined
    },
    overlay: objectifyRgba(
      resource != null ? resource.background_overlay : undefined
    ),
    blur:
      (resource != null ? resource.background_type : undefined) === 5
        ? true
        : false
  }

  const onSubmit = function(fieldsData, resetForm) {
    const resourceId = resource != null ? resource.id : undefined
    const resourceData = {
      background_image: fieldsData.background.value.url,
      background_type: fieldsData.blur.value ? 5 : 1,
      background_overlay: stringifyRgba(fieldsData.overlay.value)
    }
    return saveResource(resourceData).promise.then(() =>
      window.apolloClientStoreBridge.refetchResource({
        id: resourceId
      })
    )
  }

  return _.assign(
    {},
    {
      fieldValues,
      resourceID: resource != null ? resource.id : undefined,
      onSubmit
    },
    ownProps
  )
}

const stores = [{ store: "resourceAdmin", watch: ["resource"] }]

const ResourceThemeFormContainer = ConnectStores(
  ResourceThemeForm,
  stores,
  {},
  mapStateToProps
)

ResourceThemeFormContainer.displayName = "ResourceThemeFormContainer"

export default ResourceThemeFormContainer
