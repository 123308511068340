import './InviteLookupInput.css'
import { TextArea } from 'lib/core/components/forms'
import { emailCount } from './utils'

class InviteLookupInput extends React.Component {

	constructor(props) {
		super(props)
		this.handleKeyDown = this.handleKeyDown.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleFocus = this.handleFocus.bind(this)
		this.handleBlur = this.handleBlur.bind(this)
	}

	handleKeyDown(value, e) {
		if (e.keyCode === 38 ||
				e.keyCode === 40 ||
				e.keyCode === 13) {
			e.preventDefault()
			e.stopPropagation()
			switch (e.keyCode) {
				case 38: return this.props.onUpArrow()
				case 40: return this.props.onDownArrow()
				case 13: return this.props.onEnter()
			}
		}
	}

	handleChange(value) {
		this.props.onChange(value)
		if (value.length >= 3 && value.indexOf(",") < 0) {
			this.props.onSearch(value)
		}
	}

	// make room for mobile keyboard
	// scroll window (the height of the header)
	// NOTE: native behavior is to scroll window 50%
	handleFocus(value, e) {
		window.scrollTo(0, 105)
		this.props.onFocus(e)
	}

	handleBlur(value, e) {
		window.scrollTo(0, 0)
		// this.props.onBlur(e)
	}

	render() {
		return (
			<TextArea
				className="InviteLookupInput"
				placeholder={this.props.placeholder}
				value={this.props.value}
				onKeyDown={this.handleKeyDown}
				onChange={this.handleChange}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
				autoFocus={true}
				inline={true}
				minRows={1}
				maxRows={5}
			/>
		)
	}

}

InviteLookupInput.displayName = "InviteLookupInput"

InviteLookupInput.defaultProps = {
	placeholder: "Enter name or emails..."
}

export default InviteLookupInput