import React from "react"
import { ConnectStores } from "lib/core/store/"
import AuthorForm from "./AuthorForm"

const mapStateToProps = function(state, ownProps) {
  const { author, author_selected_for_deletion } = state.authorAdminStore.state
  const { saveAuthor, deleteAuthor } = state.authorAdminStore.action

  const canDelete = (author != null ? author.id : undefined) ? true : false
  const isDeleting = author_selected_for_deletion
  const author_img =
    (__guard__(author != null ? author.avatar : undefined, x => x.length) &&
      (author != null ? author.avatar : undefined)) ||
    (author != null ? author.image : undefined)

  const salutation = (function() {
    const s = author != null ? author.salutation : undefined
    if (s) {
      return { value: s, label: s }
    }
  })()

  const fieldValues = {
    avatar: {
      url: author_img,
      // image field is a hack to preview img
      // since it's not a filepicker url
      image: author_img,
    },
    first_name: author != null ? author.first_name : undefined,
    last_name: author != null ? author.last_name : undefined,
    salutation,
    title: author != null ? author.title : undefined,
    bio: author != null ? author.bio : undefined,
  }

  const onSubmit = function(fieldsData, resetForm) {
    const authorData = {
      first_name: fieldsData.first_name.value,
      last_name: fieldsData.last_name.value,
      salutation:
        (fieldsData.salutation.value != null
          ? fieldsData.salutation.value.value
          : undefined) || "",
      title: fieldsData.title.value || "",
      bio: fieldsData.bio.value || "",
    }
    // Don't save old field value to filepicker field
    if (
      (fieldsData.avatar.value != null
        ? fieldsData.avatar.value.url
        : undefined) !== author_img
    ) {
      authorData.avatar = fieldsData.avatar.value.url
    }
    return saveAuthor(authorData).promise.then(function() {
      window.apolloClientStoreBridge.refetchAuthors({
        school_id: window.App.school.id,
        first: 50,
      })
      return ownProps.closeCard()
    })
  }

  const handleDelete = function(id) {
    let finalize
    return deleteAuthor(id, (finalize = true)).promise.then(function() {
      window.apolloClientStoreBridge.refetchAuthors({
        school_id: window.App.school.id,
        first: 50,
      })
      return ownProps.closeCard()
    })
  }

  const selectAuthorForDeletion = function(id) {
    let finalize
    return deleteAuthor(id, (finalize = false))
  }

  const unselectAuthorForDeletion = function(id) {
    let cancel, finalize
    return deleteAuthor(id, (finalize = false), (cancel = true))
  }

  return _.assign(
    {},
    {
      author,
      fieldValues,
      onSubmit,
      canDelete,
      isDeleting,
      handleDelete,
      selectAuthorForDeletion,
      unselectAuthorForDeletion,
    },
    ownProps
  )
}

const stores = [
  { store: "authorAdmin", watch: ["author", "author_selected_for_deletion"] },
]

export default ConnectStores(AuthorForm, stores, {}, mapStateToProps)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
