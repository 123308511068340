import React, { useContext } from "react"
import PropTypes from "prop-types"
import {
  formatHex,
  PRIMARY_WHITE_BACKGROUND,
  SECONDARY_WHITE_BACKGROUND,
  TERTIARY_WHITE_BACKGROUND,
  BORDER_GRAY,
  SHADOW_GRAY,
  OVERLAY_GRAY
} from "../utils/colors"

/*
<Provider theme={{}}>
  <Provider emphasis="primary">
    <Consumer>
      {styles => <div {...styles}>Primary</div>}
    </Consumer>
  </Provider>
  <Provider emphasis="secondary">
    <Consumer>
      {styles => <div {...styles}>Secondary</div>}
    </Consumer>
  </Provider>
</Provider>
*/

const EMPHASIS_PRIMARY = "primary"
const EMPHASIS_SECONDARY = "secondary"
const EMPHASIS_TERTIARY = "tertiary"

// In order from light to dark in tone
const EMPHASISES = [EMPHASIS_PRIMARY, EMPHASIS_SECONDARY, EMPHASIS_TERTIARY]

// negative contrast prefers darker emphasis if possible
// positive contrast prefers lighter emphasis if possible
const getContrastedEmphasis = (emphasis, contrast) => {
  const index = EMPHASISES.indexOf(emphasis)
  const denominator = EMPHASISES.length - 1
  // are we moving up or down in the array?
  const dir = contrast < 0 ? 1 : -1
  // roughly get relative contrast index to current emphasis indes
  let contrastIndex = Math.floor(denominator * Math.abs(contrast)) * dir + index

  // confine contrastIndex to available indices
  contrastIndex = Math.max(0, Math.min(denominator, contrastIndex))

  // No contrast, need to find closest contrasting emphasis
  if (index === contrastIndex) {
    if (contrast < 0) {
      contrastIndex = index - 1
    } else if (contrast > 0) {
      contrastIndex = index + 1
    } else {
      contrastIndex = index
    }
  }

  const contrastEmphasis = EMPHASISES[contrastIndex]
  // The emphasis that best fits the requested contrast
  return contrastEmphasis
}
export const PathwrightUIContext = React.createContext()

// use to provide theme and nearest background for subtree
export const Provider = ({ children, theme, emphasis }) => {
  const parentTheme = useUIThemeContext()

  const pathwrightUITheme = {
    ...(Provider.defaultTheme || {}),
    ...(parentTheme || {}),
    ...(theme || {})
  }

  let nearestBackgroundColor
  if (emphasis === "primary")
    nearestBackgroundColor = pathwrightUITheme.primaryBackgroundColor
  if (emphasis === "secondary")
    nearestBackgroundColor = pathwrightUITheme.secondaryBackgroundColor
  if (emphasis === "tertiary")
    nearestBackgroundColor = pathwrightUITheme.tertiaryBackgroundColor

  return (
    <PathwrightUIContext.Provider
      value={{
        ...pathwrightUITheme,
        nearestBackgroundColor,
        primaryBrandColor: formatHex(pathwrightUITheme.primaryBrandColor),
        emphasis
      }}
    >
      {children}
    </PathwrightUIContext.Provider>
  )
}

Provider.displayName = "UIProvider"

Provider.defaultTheme = {
  primaryBackgroundColor: PRIMARY_WHITE_BACKGROUND,
  secondaryBackgroundColor: SECONDARY_WHITE_BACKGROUND,
  tertiaryBackgroundColor: TERTIARY_WHITE_BACKGROUND,
  cardBorderRadius: "10px",
  cardBorderColor: BORDER_GRAY,
  cardBoxShadow: `0 0 10px 0 ${SHADOW_GRAY}`,
  primaryBrandColor: "#0d71fb",
  backgroundImage:
    "https://pathwright.imgix.net/https%3A%2F%2Fcdn.filestackcontent.com%2Fapi%2Ffile%2Fw9keAePsTSixWYsi9pAp%3Fpolicy%3DeyJjYWxsIjogWyJyZWFkIiwgInN0YXQiLCAiY29udmVydCJdLCAiZXhwaXJ5IjogNDYyMDM3NzAzMX0%253D%26signature%3D888b9ea3eb997a4d59215bfbe2983c636df3c7da0ff8c6f85811ff74c8982e34?balph=40&blend=666666&blur=500&bm=screen&fit=max&fm=jpg&high=-80&ixlib=python-1.1.0&q=100&w=1000&s=dcae6a3f5c9f1a69dc0f526ac7521507",
  backgroundOverlay: OVERLAY_GRAY,
  backgroundDots: false
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  emphasis: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  theme: PropTypes.shape({
    cardBoxShadow: PropTypes.string,
    cardBorderColor: PropTypes.string,
    cardBorderRadius: PropTypes.string,
    primaryBackgroundColor: PropTypes.string,
    secondaryBackgroundColor: PropTypes.string,
    tertiaryBackgroundColor: PropTypes.string,
    primaryBrandColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundOverlay: PropTypes.string,
    logo: PropTypes.string,
    icon: PropTypes.string
  })
}

Provider.defaultProps = {
  emphasis: "primary"
}

// use to get ui theme and nearest background styles
// Consumes the upstream emphasis and supplies that as the default emphasis to the Provider
// when the Provider is to provided a contrasted emphasis
export const Consumer = React.memo(function UIConsumer({ children, contrast }) {
  return (
    <PathwrightUIContext.Consumer>
      {({ emphasis: emphasisProp, ...passProps }) => (
        <Provider
          {...passProps}
          emphasis={
            contrast
              ? getContrastedEmphasis(emphasisProp, contrast)
              : emphasisProp
          }
        >
          <PathwrightUIContext.Consumer>
            {value => {
              if (!value) {
                return children({})
              }

              const styles = {
                ...value,
                boxShadow: value.cardBoxShadow,
                borderColor: value.cardBorderColor,
                borderRadius: value.cardBorderRadius,
                backgroundColor: value.nearestBackgroundColor
              }

              return children(styles)
            }}
          </PathwrightUIContext.Consumer>
        </Provider>
      )}
    </PathwrightUIContext.Consumer>
  )
})

export const useUIThemeContext = () => {
  return useContext(PathwrightUIContext)
}

export default { Provider, Consumer }
