import { withTranslate } from "@pathwright/ui/src/components/"
import Button from "@pathwright/ui/src/components/button/Button"
import Text from "@pathwright/ui/src/components/ui/Text"
import { isAbsoluteUrl } from "@pathwright/ui/src/components/link/utils"
import View from "@pathwright/ui/src/components/ui/View"
import I18nextProvider from "@pathwright/web/src/modules/lng/I18nextProvider"
import { PathwrightClientContextProvider } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import socialUtils from "global/social/utils"
import ConnectStores from "lib/core/store/components/ConnectStores"
import * as urls from "lib/core/utils/urls"
import { getStoreLabel, getStoreUrl } from "../../../store/utils"
import "./Footer.css"

// For legacy Django footer
if (window.App)
  window.App.bootSchoolFooter = (el) =>
    ReactDOM.render(
      <PathwrightClientContextProvider
        schoolId={bootstrappedData.school.id}
        integrations={bootstrappedData.integrations}
        onAuthChange={(token) => {}}
      >
        <I18nextProvider>
          <FooterContainer />
        </I18nextProvider>
      </PathwrightClientContextProvider>,
      el
    )

const getUtmParamString = (school, location) => {
  if (!school) return ""

  const utmParams = encodeURI(
    `utm_source=${school.name}-${school.id}&utm_medium=Powered by Pathwright&utm_campaign=${location}`
  )

  return utmParams
}

const Footer = function (props) {
  const {
    school,
    school_social,
    pages,
    privacy_policy,
    terms_of_service,
    terms_override_link,
    terms_agreement_prompt_override,
    t,
    location = "Library"
  } = props

  return (
    <View.Secondary className="Footer">
      <div className="Footer__wrapper">
        <section>
          <div className="Footer__left-column">
            <div className="Footer__school">
              <h3 className="Footer__school_name">{school.name}</h3>
              {school.short_description && (
                <Text.Meta
                  style={{ marginBottom: "1rem" }}
                  className="Footer__school_description"
                >
                  {school.short_description}
                </Text.Meta>
              )}
              <Button styleType="secondary" to={urls.modalRoute("/help/")}>
                {t("footer.need_help")}
              </Button>
            </div>
            <div className="Footer__meta">
              <ul>
                <li>
                  © {moment().format("YYYY")} {school.name}
                </li>
                <li className="Footer__meta_separator">•</li>
                <li>
                  {terms_agreement_prompt_override ? (
                    <Button
                      styleType="inline"
                      href={terms_override_link}
                      target="_blank"
                    >
                      {t("footer.terms_of_use")}
                    </Button>
                  ) : (
                    <Button
                      styleType="inline"
                      href="https://www.pathwright.com/terms-of-use/"
                      target="_blank"
                    >
                      {t("footer.terms_of_use")}
                    </Button>
                  )}
                </li>
                <li className="Footer__meta_separator">•</li>
                <li>
                  <Button
                    styleType="inline"
                    href="https://www.pathwright.com/privacy-policy/"
                    target="_blank"
                  >
                    {t("footer.privacy_policy")}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="Footer__right-column">
            <div className="Footer__pages">
              <div className="Footer__pages_account">
                <h5>{t("footer.links.account")}</h5>
                <ul>
                  <li>
                    <Button
                      styleType="inline"
                      truncate
                      to={"/auth/sign-in?next=/library/"}
                    >
                      {t("footer.links.sign_in")}
                    </Button>
                  </li>
                  <li>
                    <Button styleType="inline" truncate to={getStoreUrl()}>
                      {t(
                        `library.store_labels.${getStoreLabel().toLowerCase()}`
                      )}
                    </Button>
                  </li>
                  <li>
                    <Button styleType="inline" truncate to="/sitemap/">
                      {t("footer.sitemap")}
                    </Button>
                  </li>
                </ul>
              </div>
              <div className="Footer__pages_more">
                <h5>{t("footer.links.more")}</h5>
                <ul>
                  {pages.map((page, i) => {
                    const { url = "" } = page
                    const urlAttr = {
                      [isAbsoluteUrl(url) ? "href" : "to"]: url
                    }

                    return (
                      <li key={i}>
                        <Button
                          styleType="inline"
                          target={isAbsoluteUrl(url) ? "_blank" : ""}
                          truncate
                          {...urlAttr}
                        >
                          {page.name}
                        </Button>
                      </li>
                    )
                  })}
                  {school_social.map((social, i) => {
                    return (
                      <li key={i}>
                        <Button
                          styleType="inline"
                          truncate
                          href={social.url}
                          target="_blank"
                        >
                          {social.name}
                        </Button>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="Footer__powered-by">
              {t("footer.powered_by")}{" "}
              <Button
                styleType="secondary"
                href={`http://pathwright.com?${getUtmParamString(
                  school,
                  location
                )}`}
                target="_blank"
              >
                Pathwright
              </Button>
            </div>
          </div>
        </section>
      </div>
    </View.Secondary>
  )
}

Footer.displayName = "Footer"

const mapStateToProps = function (state, ownProps) {
  const { school, security } = state.schoolStore.state
  const { terms_agreement_prompt_override, terms_of_service, privacy_policy } =
    security
  const { getPages } = state.schoolStore.request

  const pages = getPages()
  const school_social = socialUtils.getEnabledNetworksForSchool()

  const terms_override_link = terms_agreement_prompt_override
    ? $(terms_agreement_prompt_override).find("#terms-agreement a").attr("href")
    : null

  return _.assign(
    {},
    {
      school,
      pages,
      school_social,
      privacy_policy,
      terms_of_service,
      terms_override_link,
      terms_agreement_prompt_override
    },
    ownProps
  )
}

const connectToStores = [{ store: "school", watch: false }]

const FooterContainer = ConnectStores(
  Footer,
  connectToStores,
  {},
  mapStateToProps
)
export default withTranslate(FooterContainer)
