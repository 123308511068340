import "./Checkbox.css"
import React from "react"
import classnames from "classnames"

export const Checkbox = ({
  className,
  id,
  name,
  style,
  value,
  checked,
  children,
  onChange,
  disabled,
  readOnly
}) => {
  return (
    <label
      className={classnames("Checkbox", className || "")}
      style={style}
      htmlFor={id || name}
    >
      <input
        type="checkbox"
        name={name || id}
        id={id || name || null}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
      {/* For alternative input UI, TODO: merge blocks TaskListItem styles in for consistency? */}
      <span className="checkbox_label" />
      {!!children && <span>{children}</span>}
    </label>
  )
}

Checkbox.displayName = "Checkbox"

Checkbox.defaultProps = {
  disabled: false
}

export default Checkbox
