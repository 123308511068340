// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
class BaseModel extends require("lib/static-shim").default(Backbone.Model) {
  static initClass() {
    this.prototype.errors = []
    this.prototype.calculated = {}
  }

  constructor(attrs, options) {
    // DECAFFEINATE FIX
    super(...arguments)
    this.options = options
    this._snaps = []

    this._loaded = false
    this.listenToOnce(this, "sync", function() {
      this.trigger("loaded", this)
      return (this._loaded = true)
    })
  }

  isLoaded() {
    return this._loaded
  }

  snapshot() {
    const snap = _.clone(this.attributes)
    this._snaps.push(snap)
    return snap
  }

  rollback(silent) {
    if (silent == null) {
      silent = false
    }
    if (this._snaps.length) {
      const snap = this._snaps.pop()
      return this.set(snap, { silent })
    }
  }

  urlRoot(url) {
    return Pathwright.getAPIUrl(url)
  }

  getViewContext(withGlobal) {
    // returns the data needed to render templates
    if (withGlobal == null) {
      withGlobal = false
    }
    let data = this.toJSON()
    data.cid = this.cid
    if (withGlobal) {
      data = $.extend(Pathwright.getGlobalViewContext(), data)
    }
    return data
  }

  validate(attrs, options) {}

  isValid() {
    // Validate should not return anything if
    // the model is valid
    return this.validate(this.attributes, this.options) == null
  }

  merge(data) {
    const mergeData = _.pick(
      this.attributes,
      (() => {
        const result = []
        for (let k in data) {
          const v = data[k]
          result.push(k)
        }
        return result
      })()
    )
    return this.set(_.defaults(mergeData, data))
  }

  index() {
    if (this.collection != null) {
      return this.collection.indexOf(this)
    }
  }

  next() {
    if (this.collection != null) {
      const i = this.index() + 1
      if (i <= this.collection.length) {
        return this.collection.at(i)
      }
    }
    return null
  }

  prev() {
    if (this.collection != null) {
      const i = this.index() - 1
      if (i >= 0) {
        return this.collection.at(i)
      }
    }
    return null
  }

  fetch() {
    const whenFetched = super.fetch()
    if (this.whenFetched == null) {
      this.whenFetched = whenFetched
    }
    return whenFetched
  }

  serialize() {
    // Returns a plain object version of this models data (including any sub models/collections)
    var _deepSerialize = function(obj) {
      for (let key in obj) {
        const val = obj[key]
        if (_.isFunction(val != null ? val.serialize : undefined)) {
          obj[key] = _deepSerialize(val.serialize())
        } else if (_.isFunction(val != null ? val.toJSON : undefined)) {
          obj[key] = _deepSerialize(val.toJSON())
        }
      }
      return obj
    }
    return _deepSerialize(this.toJSON())
  }
}
BaseModel.initClass()

// Override url function to force closing '/'
BaseModel.oldURL = BaseModel.prototype.url
BaseModel.prototype.url = function() {
  let base = BaseModel.oldURL.call(this)
  if (base.length) {
    if (base.slice(-1) !== "/") {
      base = `${base}/`
    }
  }
  return base
}

export default BaseModel
