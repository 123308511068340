import {
  useContext,
  createContext,
  ReactElement
} from "react"
import { ReducerActionType } from "./useSyncedReducer"
import { Media, MediaTag } from "@pathwright/media-utils"

export type SyncerProps = {
  children: ReactElement
  contextKey: string
  userID: string | number
  mediaStoragePath?: string
}

export type SyncerContextType = {
  media: Media[]
  mediaLoading?: boolean
  accountTags: MediaTag[]
  gqlError?: any
  tagsLoading?: boolean
  syncer: (
    action: ReducerActionType,
    state: Record<string, any>,
    dispatch: any
  ) => void
}

const defaultSyncState: SyncerContextType = {
  media: [],
  accountTags: [],
  tagsLoading: false,
  mediaLoading: false,
  syncer: () => {}
}

const MediaSyncerContext = createContext<SyncerContextType>(defaultSyncState)
export const useSyncer = () => useContext<SyncerContextType>(MediaSyncerContext)

export default MediaSyncerContext
