import React, { Suspense } from "react"
import LoadingBlock from "../loading/LoadingBlock"

const RedactorEditor = React.lazy(() =>
  import("./RedactorEditor" /* webpackChunkName: "RedactorEditor" */)
)

const LazyRedactorEditor = props => (
  <Suspense fallback={<LoadingBlock />}>
    <RedactorEditor {...props} />
  </Suspense>
)

LazyRedactorEditor.redactorConfig = RedactorEditor.redactorConfig

export default LazyRedactorEditor
