export enum MediaType {
  FILE = "FILE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  LINK = "LINK",
  ANY = "ANY",
  DOCUMENT = "DOCUMENT",
  ARCHIVE = "ARCHIVE"
}

export type MediaInput = {
  id?: string
  createdByID?: number | string
  accountID?: number | string
  name?: string
  type: MediaType
  mimetype: string
  createdDate?: number
  url: string
  description?: string
  downloadURL?: string
  tags?: string[]
  externalID?: string | null
  initialMetaAdded?: boolean
  muxVideoID?: string | null
  fileSize?: number
  encodingStatus?: string
}

export interface MediaEncoding {
  status?: string
  jobID?: string
  startTime?: string
  endTime?: string
  pollingURL?: string
  uploadURL?: string
  __typename?: "MediaEncoding"
}

export interface MediaSize {
  width?: number
  height?: number
  dpi?: number
  fileSize?: number
  __typename?: "MediaSize"
}

export interface ImageDisplay {
  large?: string
  medium?: string
  small?: string
  thumb?: string
  original?: string
}

export interface MediaPlayback {
  length?: number
  startTime?: number
  endTime?: number
}

export type Media = {
  id: string
  createdByID: number | string
  accountID: string | number
  name?: string
  type: MediaType
  mimetype: string
  createdDate: number
  url: string
  description?: string
  downloadURL?: string
  tags?: string[]
  externalID?: string
  initialMetaAdded?: boolean
  muxVideoID?: string
  thumb?: ImageDisplay
  size?: MediaSize
  playback?: MediaPlayback
  encoding?: MediaEncoding
  lastUsedDateTime?: number

  // Legacy Algolia
  _highlightResult?: string
  __typename?: string
  objectID?: string
  __position?: string

  // Legacy data fields
  contentType?: string
}


export type MediaMetaInput = {
	width?: number
	height?: number
	dpi?: number
	fileSize?: number
	length?: number
	startTime?: number
	endTime?: number
	createdDate?: number
	largeImage?: string
	mediumImage?: string
	smallImage?: string
	thumbImage?: string
	originalImage?: string
	encodingStatus?: string
	encodingJobID?: string
	encodingStartTime?: string
	encodingEndTime?: string
	pollingURL?: string
	encodedURL?: string
	downloadURL?: string
	mimetype?: string
}

export type PathwrightMediaMeta = {
  userID: number | string
  accountID: number | string
  mediaType: MediaType
}

export type MediaTag = {
  name: string
  count?: number
}

export type VideoUsageResult = {
	accountID: number | string
	videoCount: number
	minutesStreamed: number
	minutesStored: number
}

type NodeMeta = {
  id: string
  key: string
  type: string
  icon?: string
  url?: string
  title?: string
  subtitle?: string
  description?: string
  keywords?: string[]
  _search?: string
}

export type CodexMedia = {
  meta: NodeMeta
  mimetype: string
  createdDate: number
  downloadURL?: string
  externalID?: string
  initialMetaAdded?: boolean
  muxVideoID?: string
  size?: MediaSize
  playback?: MediaPlayback
  encoding?: MediaEncoding
  lastUsedDateTime?: number
  accountID: string
  createdByID: string
  fractional_order?: number

}
