import gql from "graphql-tag"
import SCHOOL_BILLING_INVOICE_FRAGMENT from "./school-billing-invoice-fragment"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  query SchoolBillingInvoiceQuery($id: Int!) {
    school {
      ...School
      school_billing_invoice(id: $id) {
        ...SchoolBillingInvoice
        payment
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${SCHOOL_BILLING_INVOICE_FRAGMENT}
`
