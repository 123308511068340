import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Button from "../../button/Button"

const StyledSwitch = styled(Button)`
  width: 40px;
  height: 20px;
  display: inline-block;
  margin: 1px 0;
  float: right;
  -webkit-user-select: none;
  position: relative;
`

const StyledSwitchToggle = styled.div`
  top: 2px;
  left: ${p => (p.active ? 22 : 2)}px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  position: absolute;
  transition: left 0.1s ease-out;
`

const StyledCheckbox = styled.input`
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
`

const Switch = ({
  name,
  label,
  active,
  inverted,
  onChange,
  onClick,
  brand,
  disabled
}) => {
  return (
    <label htmlFor={name || label}>
      <StyledSwitch
        el="div"
        className="Switch"
        styleType="primary"
        inverted={inverted}
        color={active ? null : "#ccc"}
        brand={brand}
        disabled={disabled}
        rounded
      >
        {({ foregroundColor }) => (
          <React.Fragment>
            <StyledCheckbox
              id={name || label}
              name={name || label}
              type="checkbox"
              checked={active}
              onChange={e => onChange && onChange(!active, e)}
              onClick={onClick}
              disabled={disabled}
            />
            <StyledSwitchToggle
              active={active}
              style={{ backgroundColor: foregroundColor }}
            />
          </React.Fragment>
        )}
      </StyledSwitch>
    </label>
  )
}

Switch.displayName = "Switch"

Switch.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  brand: PropTypes.bool,
  onChange: PropTypes.func
}

Switch.defaultProps = {
  brand: true
}

export default Switch
