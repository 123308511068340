import { Checkbox, VStack } from "@chakra-ui/react"
import { Field, Form, Formik } from "formik"
import { useEffect } from "react"
import { toFormikValidate } from "zod-formik-adapter"
import { ActionType } from "../../../lib/types"
import { useSpaceContext } from "../../space/api/context"
import { useAuthContext } from "../api/context"
import AuthFormAlert from "../components/AuthFormAlert"
import AuthFormButton from "../components/AuthFormButton"
import SignedInContainer from "../signed-in/components/SignedInContainer"
import { joinSpaceSchema } from "./api/action"
import { getShouldPromptUserToJoinSpace } from "./api/loader"

const validate = toFormikValidate(joinSpaceSchema)

export const useShouldPromptUserToJoinSpace = (): boolean => {
  const authContext = useAuthContext()
  const spaceContext = useSpaceContext()
  const shouldPromptUserToJoinSpace = getShouldPromptUserToJoinSpace({
    authContext,
    spaceContext
  })
  return shouldPromptUserToJoinSpace
}

export type JoinFormProps = ActionType & {
  // If no `onComplete` prop is passed, the user will only see a "Continue"
  // button if we should prompt the user to join the Space.
  onComplete?: () => void | (() => Promise<void>)
}

const JoinForm = ({
  success,
  loading,
  error,
  onSubmit,
  onComplete
}: JoinFormProps) => {
  const { consentDescription, inviteToken } = useAuthContext()
  const shouldPromptUserToJoinSpace = useShouldPromptUserToJoinSpace()
  const shouldPromptUser = Boolean(shouldPromptUserToJoinSpace || onComplete)
  const handleSubmit: JoinFormProps["onSubmit"] = (e) => {
    e.preventDefault()
    if (shouldPromptUserToJoinSpace) {
      onSubmit(e)
    } else {
      onComplete?.()
    }
  }

  useEffect(() => {
    if (success) onComplete?.()
  }, [success])

  return (
    <Formik
      initialValues={{
        consent: false,
        inviteToken: inviteToken || ""
      }}
      validate={validate}
      validateOnMount
      onSubmit={() => {}} /* noop */
    >
      {(form) => (
        <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <AuthFormAlert error={error} />
            <SignedInContainer size="lg" />
            {shouldPromptUserToJoinSpace && !!inviteToken && (
              <Field name="inviteToken" readOnly hidden />
            )}
            {shouldPromptUserToJoinSpace && !!consentDescription && (
              <Field
                as={Checkbox}
                name="consent"
                isChecked={form.values.consent}
                // Preventing submitting form when hitting Enter on checkbox.
                // NOTE: oddly must specify `isChecked` prop in this case.
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    e.preventDefault()
                    form.setFieldValue("consent", !form.values.consent, true)
                  }
                }}
                minW="100%"
              >
                {consentDescription}
              </Field>
            )}
            {shouldPromptUser && (
              <AuthFormButton loading={loading}>Continue</AuthFormButton>
            )}
          </VStack>
        </Form>
      )}
    </Formik>
  )
}

export default JoinForm
