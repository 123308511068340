import { Box, Flex, IconButton } from "@chakra-ui/react"
import Pathicon from "@pathwright/app-web/src/components/pathicon/Pathicon"
import {
  decodeDelimitedArray,
  encodeDelimitedArray,
  useQueryParam
} from "use-query-params"
import { ResourceCopyConfigFragment } from "../../api/generated"
import ResourceCopyConfigItems, {
  parseCopyConfigKey
} from "../../resource/copy-config/ResourceCopyConfigItems"

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
const CommaArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ","),

  decode: (
    arrayStr: string | (string | null)[] | null | undefined
  ): string[] | undefined => {
    // Decoding, ensuring we have a string[] or undefined return type.
    if (arrayStr) {
      return decodeDelimitedArray(arrayStr, ",")?.reduce((acc, str) => {
        if (typeof str === "string") {
          acc.push(str)
        }
        return acc
      }, [] as string[])
    }
  }
}

export const useCopyQueryParam = () => {
  return useQueryParam("copy", CommaArrayParam)
}

function ResourceCopyConfigPin() {
  const [resourceCopyConfigKeys, setResourceCopyConfigKeys] =
    useCopyQueryParam()

  // Remove pinned config from query params, and in turn, from state.
  function handleRemove(config: ResourceCopyConfigFragment) {
    const keys = resourceCopyConfigKeys?.filter(
      (key) => parseCopyConfigKey(key) !== config.lookup_key
    )
    setResourceCopyConfigKeys(keys)
  }

  return resourceCopyConfigKeys?.length ? (
    <Box
      // Inlining on smaller screens.
      pos={["sticky", "sticky", "sticky", "fixed"]}
      top={8}
      right={8}
      zIndex={10}
    >
      <ResourceCopyConfigItems
        resourceCopyConfigKeys={resourceCopyConfigKeys}
        renderInternal={(config) =>
          config.children ? (
            <Flex ml="auto">
              <IconButton
                aria-label="remove path"
                p={0}
                boxSize="1.6em"
                minW={0}
                icon={<Pathicon icon="x" pos="absolute" size="1em" />}
                onClick={() => handleRemove(config)}
              />
            </Flex>
          ) : null
        }
      />
    </Box>
  ) : null
}

export default ResourceCopyConfigPin
