export const isAnnotationComplete = data => {
  const { answer, requiredCount } = data

  if (!answer || !answer.selectedAnnotations) {
    return false
  }

  if (!requiredCount && answer.selectedAnnotations.length) {
    return true
  }

  return answer.selectedAnnotations.length >= parseInt(requiredCount || 0)
}

export default {
  type: "AnnotatedAssessment",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/annotated-assessment-block-new",
  menu: {
    category: "task",
    order: 3
  },
  layouts: {
    annotated_assessment: {
      key: "annotated_assessment",
      label: "Annotate",
      data: {
        question: "What to pack?",
        description:
          "Mark all the supplies you'd want to take on the trip and why you'd use them.",
        src: "https://cdn.filestackcontent.com/oHwq77cmSxmN0LFY7Bh1",
        altText: "Camping Gear"
      }
    }
  },
  resolveUserCompletion: (context, data, layout) => {
    const progress = isAnnotationComplete(data) ? 1 : 0
    return Promise.resolve({
      progress,
      score: progress || null
    })
  },
  schema: `
    type AnnotationOption {
      html: String
      xPos: Float!
      yPos: Float!
      isCorrectAnswer: Boolean
    }

    type AnnotationAnswer {
      selectedAnnotations: [AnnotationOption]
      hasSubmitted: Boolean
      isCorrect: Boolean
      submittedDate: DateTime
    }

    type AnnotatedAssessment implements Block {
      id: ID!
      question: String!
      description: String
      src: String! @media
      altText: String
      caption: ImageCaption
      requiredCount: Int
      required: Boolean
      options: [AnnotationOption]
      answer: AnnotationAnswer @scope(type: USER)
    }
  `
}
