import { useMutation, useQuery } from "@apollo/client"
import { getExternalAuthMethods } from "@pathwright/app-web/src/views/auth/utils/authMethods"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import View from "@pathwright/ui/src/components/ui/View"
import { Formik } from "formik"
import gql from "graphql-tag"
import produce from "immer"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import PathwrightSignInFeatureBlock from "./PathwrightSignInFeatureBlock"

const SPACE_AUTH_SETTINGS_QUERY = gql`
  query SpaceAuthSettings {
    space {
      id
      pathwright_login_enabled
      allow_public_signup
      always_prompt_for_password
      authMethods {
        id
        enabled
        meta {
          id
          key
          type
          url
          title
          icon
        }
      }
    }
  }
`

const UPDATE_SPACE_AUTH_SETTINGS = gql`
  mutation (
    $pathwrightLoginEnabled: Boolean!
    $allowPublicSignup: Boolean!
    $alwaysPromptForPassword: Boolean!
  ) {
    updatePathwrightAuth(
      pathwright_login_enabled: $pathwrightLoginEnabled
      allow_public_signup: $allowPublicSignup
      always_prompt_for_password: $alwaysPromptForPassword
    ) {
      id
      pathwright_login_enabled
      allow_public_signup
      always_prompt_for_password
    }
  }
`

export const useSpaceAuthSettings = () => {
  const spaceAuthSettingsQuery = useQuery(SPACE_AUTH_SETTINGS_QUERY, {
    fetchPolicy: "cache-and-network"
  })

  return spaceAuthSettingsQuery
}

const useSpaceAuthSettingMutation = () => {
  const [spaceAuthSettingMutation] = useMutation(UPDATE_SPACE_AUTH_SETTINGS, {
    // Sync space data with school fragment.
    update: (cache, result) => {
      const fragment = {
        fragment: SCHOOL_FRAGMENT,
        fragmentName: "School",
        id: "School:" + result?.data?.updatePathwrightAuth?.id
      }
      const schoolData = cache.readFragment(fragment)
      const nextFragment = produce(schoolData, (draft) => {
        draft.pathwright_login_enabled =
          result?.data?.updatePathwrightAuth?.pathwright_login_enabled
      })
      cache.writeFragment({
        ...fragment,
        data: nextFragment
      })
    }
  })

  return spaceAuthSettingMutation
}

const ManagePathwrightSignInFeature = ({ card }) => {
  const spaceAuthSettingsQuery = useSpaceAuthSettings()
  const spaceAuthSettingMutation = useSpaceAuthSettingMutation()

  const initialValues = {
    pathwrightLoginEnabled:
      spaceAuthSettingsQuery?.data?.space?.pathwright_login_enabled,
    allowPublicSignup: spaceAuthSettingsQuery?.data?.space?.allow_public_signup,
    alwaysPromptForPassword:
      spaceAuthSettingsQuery?.data?.space?.always_prompt_for_password
  }

  const externalAuthMethods = getExternalAuthMethods(
    spaceAuthSettingsQuery?.data?.space?.authMethods || []
  )
  // You can only disable Pathwright Sign-in when there are other enabled
  // external auth methods.
  const shouldDisablePathwrightSignInput =
    !externalAuthMethods.length && initialValues.pathwrightLoginEnabled

  return (
    <Card card={card} emphasis="secondary">
      {spaceAuthSettingsQuery.loading && !spaceAuthSettingsQuery.data ? (
        <LoadingCircle />
      ) : (
        <CardBlock paddingTop={false} paddingBottom={false}>
          <PathwrightSignInFeatureBlock to={null} />
          <Formik
            initialValues={initialValues}
            // enableReinitialize allows the form to reset to new state after submission
            enableReinitialize
          >
            {(form) => {
              const { values, setFieldValue } = form
              return (
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ minWidth: "100%" }}
                >
                  <div /* wrapper for View separate prop to work */>
                    <View separate paddingTop paddingBottom>
                      <ToggleInput
                        labelWidth={300}
                        label="Enable Pathwright Sign-In"
                        helperText="To disable Pathwright Sign-In, enable at least one other Sign-In option."
                        value={values.pathwrightLoginEnabled}
                        onChange={(value) => {
                          setFieldValue("pathwrightLoginEnabled", value, false)
                          spaceAuthSettingMutation({
                            variables: {
                              ...initialValues,
                              ...form.values,
                              pathwrightLoginEnabled: value,
                              alwaysPromptForPassword: value
                                ? values.alwaysPromptForPassword
                                : false
                            }
                          })
                        }}
                        alignRight
                        style={{
                          marginBottom: "12px"
                        }}
                        disabled={shouldDisablePathwrightSignInput}
                      />
                      <ToggleInput
                        labelWidth={300}
                        label="Prompt for password"
                        helperText="Always ask for a password on new account & sign in forms."
                        value={values.alwaysPromptForPassword}
                        onChange={(value) => {
                          setFieldValue("alwaysPromptForPassword", value, false)
                          spaceAuthSettingMutation({
                            variables: {
                              ...initialValues,
                              ...form.values,
                              alwaysPromptForPassword: value
                            }
                          })
                        }}
                        alignRight
                        disabled={!initialValues.pathwrightLoginEnabled}
                      />
                    </View>
                  </div>
                </form>
              )
            }}
          </Formik>
        </CardBlock>
      )}
    </Card>
  )
}

ManagePathwrightSignInFeature.displayName = "ManagePathwrightSignInFeature"

export default ManagePathwrightSignInFeature
