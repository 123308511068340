// TODO: packaged and volume schemes

import getStripeProductMinQuantity from "./getStripeProductMinQuantity"

export default function getStripePriceTotalAmount(stripePrice, seatsQty) {
  seatsQty = getStripeProductMinQuantity(stripePrice.product, seatsQty)

  if (stripePrice.billing_scheme === "tiered") {
    if (stripePrice.tiers_mode === "graduated") {
      let sortedTiers = stripePrice.tiers.slice().reverse()
      let totalAmount = 0
      // Add up unit amounts
      let unitCount = 1
      while (unitCount <= seatsQty) {
        let unitTier = sortedTiers[0]
        sortedTiers.forEach(tier => {
          if (unitCount <= tier.up_to) unitTier = tier
        })
        totalAmount += unitTier.unit_amount
        unitCount++
      }
      // Add up flat amounts
      sortedTiers.forEach(tier => {
        if (seatsQty >= tier.up_to && tier.flat_amount) {
          totalAmount += tier.flat_amount
        }
      })
      return totalAmount
    } else if (stripePrice.tiers_mode === "volume") {
      let sortedTiers = stripePrice.tiers.slice().reverse()
      let totalAmount = 0
      // Find correct tier for seats qty
      let tier = sortedTiers[0]
      sortedTiers.forEach(x => {
        if (seatsQty <= x.up_to) {
          tier = x
        }
      })
      // Add up unit amounts
      totalAmount = tier.unit_amount * seatsQty
      // Add up flat amount
      if (tier.flat_amount) {
        totalAmount += tier.flat_amount
      }
      return totalAmount
    }
  } else {
    return stripePrice.unit_amount_decimal * seatsQty
  }
}
